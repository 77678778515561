import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useAtom } from 'jotai'
import _ from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { RequiredAssignmentMenuItem } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { getFlag } from 'sierra-client/config/global-config'
import * as format from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useContentKindPermissions, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { jsonWithZodSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { canvasColumn, stringsColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { Row } from 'sierra-client/lib/tabular/datatype/internal/row'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  LiveSessionMenuItem,
  SelectLiveSession,
} from 'sierra-client/views/manage/courses/components/live-session-components'
import {
  LiveSessionAssignedFilter,
  LiveSessionUserGroupFilter,
} from 'sierra-client/views/manage/courses/components/live-session-filters'
import { CourseActionModalControl } from 'sierra-client/views/manage/courses/course-action-modals'
import {
  LSFilter,
  liveSessionFilterSchema,
} from 'sierra-client/views/manage/courses/utils/live-session-utils'

import { formatLiveSessionTimeAsSchedule } from 'sierra-client/views/manage/event-groups/event-utils'
import { useGroups } from 'sierra-client/views/manage/groups/use-groups'
import { AssignedViaPill } from 'sierra-client/views/manage/users/components/content-assignment-components'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import { queryKeyTabularCourseLiveSessionAssignments } from 'sierra-client/views/manage/utils/query-keys'
import {
  ListContentEnrolledUsersResponse,
  ListCourseEnrolledUsersRequest,
  LiveSessionRow,
} from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { scheduledOrNull } from 'sierra-domain/content/session'
import {
  XRealtimeAdminCoursesListEnrolledUsers,
  XRealtimeAdminCoursesListLiveSessions,
} from 'sierra-domain/routes'
import { ItemOf, assertNever, isDefined } from 'sierra-domain/utils'
import { Button, IconButton, Spacer, Text, View, scrollViewStyles } from 'sierra-ui/primitives'
import styled from 'styled-components'

type CourseLiveSessionAssignmentTableProps = {
  courseId: CourseId
  enrollmentCount: number
  onDone?: () => void
  liveSessions: LiveSessionRow[]
  openEnrollUsers: () => void
  modal: CourseActionModalControl
}

type CourseLiveSessionAssignmentData = ItemOf<ListContentEnrolledUsersResponse['data']>

type ContentLiveSessionAssignmentTableDefinition = TableDefinitionOf<
  CourseLiveSessionAssignmentData,
  [
    { type: 'users'; ref: 'user' },
    { type: 'canvas'; ref: 'live-session' },
    { type: 'strings'; ref: 'progress' },
    { type: 'canvas'; ref: 'programs' },
  ]
>

type ContentLiveSessionAssignmentTableData = TableDataFromDefinition<
  CourseLiveSessionAssignmentData,
  ContentLiveSessionAssignmentTableDefinition
>

type ContentLiveSessionAssignmentTableMeta = { cursor: UserId | undefined }

const AssignedSessionsCell: React.FC<{ assignedSessions: LiveSessionRow[] }> = ({ assignedSessions }) => {
  if (assignedSessions.length === 0) return null

  return (
    <View direction='column'>
      {assignedSessions.map(session => {
        const formatted = formatLiveSessionTimeAsSchedule(scheduledOrNull(session.data))
        if (formatted === undefined) return null
        return (
          <Text color='foreground/primary' key={session.liveSessionId} size='small'>
            {formatted}
          </Text>
        )
      })}
    </View>
  )
}

const tableDefinition = (
  t: TranslationLookup,
  liveSessions: LiveSessionRow[]
): ContentLiveSessionAssignmentTableDefinition => ({
  columns: [
    usersColumn({
      getData: r => ({
        email: r.userInfo.baseUserInfo.email,
        active: r.userInfo.baseUserInfo.status === 'active',
        status: r.userInfo.baseUserInfo.status,
        id: r.userInfo.baseUserInfo.userId,
        avatar: getAvatarImage(r.userInfo.baseUserInfo.userId, r.userInfo.baseUserInfo.avatar),
        avatarColor: r.userInfo.baseUserInfo.avatarColor,
        firstName: r.userInfo.baseUserInfo.firstName,
        lastName: r.userInfo.baseUserInfo.lastName,
        isRequiredAssignment: r.isRequiredAssignment,
      }),
      header: translatedLabel('table.name'),
      ref: 'user',
    }),
    canvasColumn({
      ref: 'live-session',
      header: translatedLabel('learner-home.live-session'),
      getData: r => {
        const assignedSessions = r.liveSessions
          .map(assigned => liveSessions.find(available => available.liveSessionId === assigned.liveSessionId))
          .filter(isDefined)
        return {
          view: <AssignedSessionsCell assignedSessions={assignedSessions} />,
          meta: {
            exports: () => r.liveSessions[0]?.startTime,
            sorts: () => r.liveSessions[0]?.startTime ?? '',
          },
        }
      },
    }),
    stringsColumn({
      ref: 'progress',
      header: translatedLabel('admin.analytics.table.progress'),
      getData: r =>
        r.progress === undefined || r.progress === 0
          ? t('manage.users.status.not-started')
          : t('manage.users.status.n-completed', { n: format.percentage(r.progress) }),
    }),
    canvasColumn({
      ref: 'programs',
      header: translatedLabel('table.assigned-via'),
      getData: r => {
        const assignedAt = r.assignedAt?.toISOString() ?? ''
        return {
          view: (
            <AssignedViaPill
              assignmentData={{
                assignedAt,
                availableAt: assignedAt,
                type: r.programs.length === 0 ? 'individual' : 'program',
                programs: r.programs,
                isRequiredAssignment: r.isRequiredAssignment,
              }}
            />
          ),
          meta: {
            exports: () => r.programs.map(p => p.name).join(', '),
            sorts: () => r.programs[0]?.name ?? '',
          },
        }
      },
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.userInfo.baseUserInfo.userId,
  },
})

const parseAssignmentFilter = (value: LSFilter['assignedToLiveSession']): boolean | undefined => {
  if (value === 'assigned') return true
  if (value === 'not-assigned') return false
  return undefined
}

const status2ProgressType = (status?: LSFilter['status']): ListCourseEnrolledUsersRequest['progressType'] => {
  switch (status) {
    case 'passed':
      return 'completed'
    case 'started':
      return 'started'
    case 'not-started':
      return 'not-started'
    case undefined:
      return undefined
    default:
      assertNever(status)
  }
}

const useCourseLiveSessionAssignmentTableAPI = ({
  courseId,
  enrollmentCount,
  liveSessions,
  modal,
  filter,
  onDone,
}: Pick<
  CourseLiveSessionAssignmentTableProps,
  'courseId' | 'enrollmentCount' | 'liveSessions' | 'modal' | 'onDone'
> & {
  filter: LSFilter
}): UseTableAPI<ContentLiveSessionAssignmentTableData, ContentLiveSessionAssignmentTableMeta> => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const orgPermissions = useOrganizationPermissions()
  const coursePermissions = useContentKindPermissions(courseId)
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canEditAssignments = coursePermissions.has('EDIT_ASSIGNMENTS')
  const { setUsersIsRequiredContent } = useDueDate()

  const { data: upcomingLiveSessions } = useQuery({
    queryKey: ['upcoming-live-sessions', courseId],
    queryFn: () =>
      typedPost(XRealtimeAdminCoursesListLiveSessions, {
        flexibleContentId: courseId,
        skipPastSessions: true,
      }),
  })

  const loader = createDataLoader<
    CourseLiveSessionAssignmentData,
    ContentLiveSessionAssignmentTableMeta,
    ContentLiveSessionAssignmentTableData
  >({
    fetchInit: async ({ predicate, control }) => {
      const response = await typedPost(XRealtimeAdminCoursesListEnrolledUsers, {
        courseId,
        assignedToLiveSession: parseAssignmentFilter(filter.assignedToLiveSession),
        progressType: status2ProgressType(filter.status),
        commonFilters: {
          maxResults: control.limit,
          sortBy: { direction: 'asc', type: 'name' },
          query: predicate.query,
          groupIds: [...filter.programs, ...filter.userGroups],
        },
      })

      return {
        data: response.data,
        meta: {
          cursor: response.data[response.data.length - 1]?.userInfo.baseUserInfo.userId,
        },
        done: !response.hasMore,
        totalCount: enrollmentCount,
      }
    },
    fetchMore: async ({ meta, predicate, control }) => {
      const response = await typedPost(XRealtimeAdminCoursesListEnrolledUsers, {
        courseId,
        assignedToLiveSession: parseAssignmentFilter(filter.assignedToLiveSession),
        progressType: status2ProgressType(filter.status),
        commonFilters: {
          maxResults: control.limit,
          lastUserId: meta.cursor,

          query: predicate.query,
          groupIds: [...filter.programs, ...filter.userGroups],
        },
      })

      return {
        data: response.data,
        meta: {
          cursor: response.data[response.data.length - 1]?.userInfo.baseUserInfo.userId,
        },
        done: !response.hasMore,
        totalCount: enrollmentCount,
      }
    },
    transformResults(data) {
      return [definition2Data(tableDefinition(t, liveSessions), data)]
    },
  })

  return useTableAPI({
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const userId = row.rawData.userInfo.baseUserInfo.userId
            const isRequiredAssignment = row.rawData.isRequiredAssignment
            const assignedSessionIds = row.rawData.liveSessions.map(ls => ls.liveSessionId)
            const availableSessions = (upcomingLiveSessions ?? []).filter(
              ls => !assignedSessionIds.includes(ls.liveSessionId)
            )
            const assignedSessions = assignedSessionIds
              .map(assignedSessionId => liveSessions.find(ls => ls.liveSessionId === assignedSessionId))
              .filter(isDefined)
            const isIndividualAssigned = row.rawData.programs.length === 0 && row.rawData.paths.length === 0

            const disableAssignmentPriorityWithReason = !isIndividualAssigned
              ? 'Unable to change the assignment priority because this user is not directly assigned to the content, but rather assigned through a live course.'
              : undefined //TODO(required-content)

            return {
              menuItems: [
                {
                  type: 'label',
                  label: t('manage.view-details'),
                  id: 'view-details',
                  onClick: () =>
                    getGlobalRouter().navigate({
                      to: `/manage/users/${userId}`,
                    }),
                  icon: 'user',
                },
                {
                  type: 'label',
                  label: t('manage.complete-progress'),
                  id: 'complete-content',
                  onClick: () =>
                    modal.open({
                      modal: 'complete-content',
                      contentType: 'course',
                      contentId: courseId,
                      targetUserId: userId,
                      onDone: () => {
                        void queryClient.invalidateQueries({
                          queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
                        })
                      },
                    }),
                  icon: 'checkmark--outline',
                  disabled: row.rawData.progress === 1,
                  hidden: !canSetContentCompletion,
                },
                {
                  type: 'label',
                  label: t('manage.reset-progress'),
                  id: 'reset-content',
                  onClick: () =>
                    modal.open({
                      modal: 'reset-course',
                      contentType: 'course',
                      contentId: courseId,
                      targetUserId: userId,
                      onDone: () => {
                        void queryClient.invalidateQueries({
                          queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
                        })
                      },
                    }),
                  icon: 'checkmark--outline',
                  disabled: row.rawData.progress === 0,
                  hidden: !canSetContentCompletion,
                },
                {
                  type: 'canvas',
                  id: 'set-is-required',
                  hidden: getFlag('required-assignments') === false,
                  disabled: disableAssignmentPriorityWithReason !== undefined,
                  render() {
                    return (
                      <RequiredAssignmentMenuItem
                        disabledWithReason={disableAssignmentPriorityWithReason}
                        assignmentPriority={isRequiredAssignment ? 'required' : 'normal'}
                        setAssignmentPriority={async val => {
                          await setUsersIsRequiredContent(
                            [userId],
                            [
                              {
                                content: { id: courseId, type: 'course' },
                                isRequired: val === 'required',
                              },
                            ]
                          )
                          void queryClient.invalidateQueries({
                            queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
                          })
                        }}
                      />
                    )
                  },
                },
                {
                  type: 'nested',
                  id: 'assign-live-session',
                  label: t('manage.live-session.assign-session'),
                  icon: 'user--add',
                  disabled: availableSessions.length === 0,
                  hidden: !canEditAssignments,
                  menuItems: availableSessions.map((ls, i) => ({
                    type: 'canvas',
                    id: `live-session-${i}`,
                    render() {
                      return (
                        <LiveSessionMenuItem
                          liveSession={ls}
                          targetUser={userId}
                          onDone={() => {
                            void queryClient.invalidateQueries({
                              queryKey: ['upcoming-live-sessions', courseId],
                            })
                            void queryClient.invalidateQueries({
                              queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
                            })
                            onDone?.()
                          }}
                          action='assign'
                        />
                      )
                    },
                  })),
                },
                {
                  type: 'nested',
                  id: 'user--remove',
                  label: t('manage.live-session.unassign-session'),
                  icon: 'user--remove',
                  disabled: assignedSessions.length === 0,
                  // this assumes that assignment permission on the course applies to its sessions as well
                  hidden: !canEditAssignments,
                  menuItems: assignedSessions.map((ls, i) => ({
                    type: 'canvas',
                    id: `live-session-${i}`,
                    render() {
                      return (
                        <LiveSessionMenuItem
                          liveSession={ls}
                          targetUser={userId}
                          onDone={() => {
                            void queryClient.invalidateQueries({
                              queryKey: ['upcoming-live-sessions', courseId],
                            })
                            void queryClient.invalidateQueries({
                              queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
                            })
                            onDone?.()
                          }}
                          action='unassign'
                        />
                      )
                    },
                  })),
                },
                {
                  type: 'label',
                  label: t('dictionary.unassign'),
                  id: 'unassign',
                  onClick: () => {
                    modal.open({
                      modal: 'unassign-users',
                      ids: [userId],
                    })
                  },
                  icon: 'user--remove',
                  color: 'destructive/background',
                  hidden: !canEditAssignments,
                  disabled:
                    row.rawData.programs.length !== 0 ||
                    row.rawData.paths.length !== 0 ||
                    row.rawData.liveSessions.length > 0 ||
                    row.rawData.assignedAt === undefined,
                },
              ],
            }
          },
        }),
      ],
    },
    dataLoader: React.useMemo(
      () => ({
        loader,
        options: { queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter) },
      }),
      [courseId, loader, filter]
    ),
    options: {
      limit: 750,
    },
  })
}

type SearchAndFilterProps = {
  tableAPI: UseTableAPI<ContentLiveSessionAssignmentTableData, ContentLiveSessionAssignmentTableMeta>
  setFilter: (filter: React.SetStateAction<LSFilter>) => void
  filter: LSFilter
}

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({ tableAPI, setFilter, filter }) => {
  const { t } = useTranslation()

  const [searchTermDebounce, searchTerm, setSearchTerm] = useDebouncedAndLiveState<string>('')
  const { groupsData: programsData } = useGroups('program')
  const { groupsData } = useGroups('user')

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: searchTermDebounce })
  }, [searchTermDebounce, tableAPI.api.action, tableAPI.api.action.setQuery])

  return (
    <View justifyContent='space-between' wrap='wrap' gap='16 10'>
      <RoundedSearchBar
        value={searchTerm}
        onChange={searchTerm => {
          setSearchTerm(searchTerm)
        }}
        placeholder={t('manage.search.users')}
      />
      <View>
        <LiveSessionUserGroupFilter
          title={t('dictionary.group-plural')}
          selectedGroupIds={filter.userGroups}
          setGroupIds={groupIds => {
            setFilter(s => ({ ...s, userGroups: groupIds }))
          }}
          groupsData={groupsData}
        />

        <LiveSessionUserGroupFilter
          title={t('dictionary.program-plural')}
          selectedGroupIds={filter.programs}
          setGroupIds={programIds => {
            setFilter(s => ({ ...s, programs: programIds }))
          }}
          groupsData={programsData}
        />
        <LiveSessionAssignedFilter
          value={filter.assignedToLiveSession}
          onChange={assignmentStatus => setFilter(f => ({ ...f, assignedToLiveSession: assignmentStatus }))}
        />
        <UserContentProgressFilter
          value={filter.status}
          onChange={contentStatus => setFilter(f => ({ ...f, status: contentStatus }))}
        />
        <IconButton
          tooltip={t('dashboard.search.clear-hover')}
          iconId='close'
          onClick={() => {
            setFilter({
              userGroups: [],
              programs: [],
              assignedToLiveSession: undefined,
              status: undefined,
            })
            setSearchTerm('')
          }}
        />
      </View>
    </View>
  )
}

type ToolbarProps = {
  tableAPI: UseTableAPI<ContentLiveSessionAssignmentTableData, ContentLiveSessionAssignmentTableMeta>
  liveSessions: LiveSessionRow[]
  contentId: string
  onLiveSessionAssign: () => void
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, liveSessions, contentId, onLiveSessionAssign }) => {
  const { api, dataLoader } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)
  const [tableData] = useAtom(api.atoms.tableData)
  const coursePermissions = useContentKindPermissions(contentId)
  const selectedIds = selection.type === 'manual' ? Array.from(selection.rows) : []

  const assignedLsPerUser = tableData
    .flatMap(td => td.rows.map(r => r.rawData))
    .filter(({ userInfo }) => selectedIds.includes(userInfo.baseUserInfo.userId))
    .map(({ liveSessions }) => liveSessions.map(ls => ls.liveSessionId))

  // Gets union and intersection of the assigned session to filter on the select session menu
  const liveSessionsAssignedToAllUser = _.intersection(...assignedLsPerUser)
  const liveSessionsAssignedToAnyUser = _.union(...assignedLsPerUser)

  const canEditAssignments = coursePermissions.has('EDIT_METADATA')

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type !== 'none' ? (
          <>
            <ActionButton
              onClick={() => exportTableData({ api, fileType: 'csv', dataLoader, t })}
              color='blueBright'
            >
              {csvButtonText}
            </ActionButton>
            {canEditAssignments && selection.type === 'manual' && (
              <>
                <SelectLiveSession
                  liveSessions={liveSessions}
                  targetUsers={selectedIds as UserId[] /** todo: remove cast */}
                  onDone={onLiveSessionAssign}
                  currentLiveSessionIds={liveSessionsAssignedToAllUser}
                  action='assign'
                  renderTrigger={({ disabled }) => {
                    if (disabled) return null
                    return (
                      <ActionButton color='blueBright'>
                        {t('manage.live-session.assign-session')}
                      </ActionButton>
                    )
                  }}
                />
                <SelectLiveSession
                  liveSessions={liveSessions}
                  targetUsers={selectedIds as UserId[] /** todo: remove cast */}
                  currentLiveSessionIds={liveSessionsAssignedToAnyUser}
                  onDone={onLiveSessionAssign}
                  action='unassign'
                  renderTrigger={({ disabled }) => {
                    if (disabled) return null
                    return (
                      <ActionButton color='redBright'>
                        {t('manage.live-session.unassign-session')}
                      </ActionButton>
                    )
                  }}
                />
              </>
            )}
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 1rem;
  max-height: calc(100vh - 21.875rem);
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

type FooterProps = {
  canEditAssignments: boolean
  manageLearners: () => void
  fetchCsvData: () => Promise<Record<string, string>[]>
}

const Footer: React.FC<FooterProps> = ({ canEditAssignments, manageLearners, fetchCsvData: fetchCsv }) => {
  const { t } = useTranslation()

  return (
    <View marginBottom='32' marginTop='32' justifyContent={canEditAssignments ? 'space-between' : 'flex-end'}>
      {canEditAssignments === true && (
        <Button onClick={manageLearners}>{t('admin.organization.paths.enroll')}</Button>
      )}
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.organization.users.users')} />
    </View>
  )
}

export const CourseLiveSessionAssignmentTable: React.FC<CourseLiveSessionAssignmentTableProps> = ({
  courseId,
  enrollmentCount,
  liveSessions,
  openEnrollUsers,
  modal,
  onDone,
}) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useQueryState<LSFilter>(
    jsonWithZodSerializer(liveSessionFilterSchema),
    {
      programs: [],
      userGroups: [],
      assignedToLiveSession: undefined,
      status: undefined,
    },
    'filter'
  )

  const tableAPI = useCourseLiveSessionAssignmentTableAPI({
    courseId,
    enrollmentCount,
    liveSessions,
    modal,
    filter,
    onDone,
  })
  const coursePermissions = useContentKindPermissions(courseId)
  const queryClient = useQueryClient()

  const callbacks = useMemo(
    () => ({
      onRow: (row: Row<ContentLiveSessionAssignmentTableData>) => {
        void getGlobalRouter().navigate({ to: `/manage/users/${row.rawData.userInfo.baseUserInfo.userId}` })
      },
    }),
    []
  )

  const fetchCsvData = async (): Promise<Record<string, string>[]> => {
    const { data } = await typedPost(XRealtimeAdminCoursesListEnrolledUsers, {
      courseId,
      commonFilters: {
        maxResults: enrollmentCount,
      },
    })
    return data.map(r => ({
      userId: r.userInfo.baseUserInfo.userId,
      email: r.userInfo.baseUserInfo.email,
      firstName: r.userInfo.baseUserInfo.firstName ?? '',
      lastName: r.userInfo.baseUserInfo.lastName ?? '',
      progress: r.progress?.toFixed(3) ?? '0',
      assignedAt: r.assignedAt !== undefined ? new Date(r.assignedAt).toISOString() : '',
      assignedSessions: r.liveSessions.map(session => session.startTime).join(', '),
    }))
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={callbacks}>
      <Text size='large' bold>
        {t('table.learners')}
      </Text>
      <Spacer size='xsmall' />
      <SearchAndFilter tableAPI={tableAPI} setFilter={setFilter} filter={filter} />
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        liveSessions={liveSessions}
        contentId={courseId}
        onLiveSessionAssign={() => {
          void queryClient.invalidateQueries({
            queryKey: queryKeyTabularCourseLiveSessionAssignments(courseId, filter),
          })
          onDone?.()
        }}
      />
      <Table />
      <Footer
        canEditAssignments={coursePermissions.has('EDIT_METADATA')}
        fetchCsvData={fetchCsvData}
        manageLearners={openEnrollUsers}
      />
    </TabularProviderFromTableAPI>
  )
}
