import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ProgramAttribute,
  ProgramSortAttributeInput,
  ProgramsTableQuery,
  ProgramsTableQueryVariables,
} from 'sierra-client/api/graphql/gql/graphql'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import * as CDef from 'sierra-client/lib/tabular/column-definitions'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { isNonEmptyArray, isNullable } from 'sierra-domain/utils'

const programsQuery = graphql(`
  query ProgramsTable($query: String, $limit: Int, $cursor: String, $sortBy: [ProgramSortAttributeInput!]) {
    managedPrograms(query: $query, limit: $limit, cursor: $cursor, sortBy: $sortBy) {
      cursor
      totalCount
      data {
        id
        name
        enrollmentCount
        createdAt
        stepsCount
        creator {
          displayName
        }
        progress {
          progress
        }
      }
    }
  }
`)

const fetchPrograms = async (params: ProgramsTableQueryVariables): Promise<ProgramsTableQuery> =>
  graphQuery(programsQuery, params)

type Programs = ProgramsTableQuery['managedPrograms']['data'][number]
type ProgramsTableDefinition = TableDefinitionOf<
  Programs,
  [
    { type: 'links'; ref: 'NAME' },
    { type: 'numbers'; ref: 'learners' },
    { type: 'numbers'; ref: 'assignments' },
    { type: 'numbers'; ref: 'progress' },
    { type: 'strings'; ref: 'creator' },
    { type: 'dateTimes'; ref: 'CREATED_AT' },
  ]
>
export type ProgramsTableData = TableDataFromDefinition<Programs, ProgramsTableDefinition>

const tableDefinition: ProgramsTableDefinition = {
  nested: {},
  rows: { getId: p => p.id },
  columns: [
    CDef.linksColumn({
      ref: 'NAME',
      header: translatedLabel('table.name'),
      hints: ['bold'],
      sortable: true,
      getData: p => ({ url: `/manage/programs/${p.id}`, label: p.name }),
    }),
    CDef.numbersColumn({
      ref: 'learners',
      header: translatedLabel('table.learners'),
      sortable: false,
      getData: p => p.enrollmentCount,
    }),
    CDef.numbersColumn({
      ref: 'assignments',
      header: translatedLabel('table.enrollments'),
      sortable: false,
      getData: p => p.stepsCount,
    }),
    CDef.numbersColumn({
      ref: 'progress',
      header: translatedLabel('admin.analytics.table.progress'),
      hints: ['percentage'],
      sortable: false,
      getData: p => p.progress.progress,
    }),
    CDef.stringsColumn({
      ref: 'creator',
      header: translatedLabel('table.creator'),
      sortable: false,
      getData: p => p.creator?.displayName ?? '',
    }),
    CDef.dateTimesColumn({
      ref: 'CREATED_AT',
      header: translatedLabel('table.createdAt'),
      sortable: true,
      getData: p => ({ date: new Date(p.createdAt) }),
    }),
  ],
}

export const programsDataLoader = (): DL.DataLoaderStateMachine<
  ProgramsTableData,
  { cursor: string | null | undefined }
> =>
  DL.createDataLoader({
    async fetchInit({ control, predicate, modifier }) {
      const res = await fetchPrograms({
        limit: control.limit,
        query: predicate.query,
        sortBy: isNonEmptyArray(modifier.sorting)
          ? modifier.sorting.map(
              sort =>
                ({
                  // todo: fix cast
                  key: sort.column as ProgramAttribute,
                  order: toGQLSortOrder(sort.direction),
                }) satisfies ProgramSortAttributeInput
            )
          : undefined,
      })

      return {
        meta: { cursor: res.managedPrograms.cursor },
        data: res.managedPrograms.data,
        totalCount: res.managedPrograms.totalCount,
        done: isNullable(res.managedPrograms.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const res = await fetchPrograms({
        cursor: meta.cursor,
        limit: control.limit,
      })

      return {
        meta: { cursor: res.managedPrograms.cursor },
        data: res.managedPrograms.data,
        totalCount: res.managedPrograms.totalCount,
        done: isNullable(res.managedPrograms.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(tableDefinition, data)]
    },
  })
