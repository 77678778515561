import { useCallback, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { CourseSummary } from 'sierra-domain/api/admin'
import { CourseId } from 'sierra-domain/api/nano-id'
import { XRealtimeAdminCoursesListCourses } from 'sierra-domain/routes'
import { isNonEmptyArray } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'

export type UseCourseHook = {
  courses: CourseSummary[]
  isLoading: boolean
  updateCourses: (courseIds?: CourseId[]) => Promise<void>
  fetchCsvData: () => Record<string, unknown>[]
}

export const mapCourseToCsv = (course: CourseSummary): Record<string, unknown> => {
  return {
    courseId: course.courseId,
    title: course.title,
    enrollmentCount: course.enrollmentCount,
    durationSeconds: course.timeTotal?.toFixed(0) ?? '',
    rating: course.rating?.toFixed(1) ?? '',
  }
}

function sortArrayByOrder(A: CourseSummary[], B: string[]): CourseSummary[] {
  // Create a map from value to index for array B
  const orderMap: { [key: string]: number } = {}
  B.forEach((value, index) => {
    orderMap[value] = index
  })

  // Sort array A based on the order defined in array B
  A.sort((a, b) => (orderMap[a.courseId] ?? -1) - (orderMap[b.courseId] ?? -1))

  return A
}

export const useManageCourses = (courseIds?: CourseId[]): UseCourseHook => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState(false)
  const [courses, setCourses] = useState<CourseSummary[]>([])

  const updateCourses = useCallback<UseCourseHook['updateCourses']>(
    async courseIds => {
      if (courseIds !== undefined && courseIds.length === 0) {
        setCourses([])
        return
      }

      setIsLoading(true)
      const response = await postWithUserErrorException(XRealtimeAdminCoursesListCourses, {
        requestedCourseIds: courseIds,
      })
      setCourses(response.courses)
      setIsLoading(false)
    },
    [postWithUserErrorException]
  )

  // Re-fetch when list of course ids changes.
  // We convert it to a set first because we want useOnChanged to ignore the order here.
  // (For instance, we don't want this to run when re-ordering courses in a path)
  useOnChanged(
    (_, currIds) => {
      void updateCourses(currIds !== undefined ? Array.from(currIds) : undefined)
    },
    courseIds !== undefined ? new Set(courseIds) : undefined
  )

  const fetchCsvData = useCallback(() => {
    return courses.map(mapCourseToCsv)
  }, [courses])

  // Sort the courses based on the order of courseIds
  const sortedCourses = useMemo(() => {
    if (isNonEmptyArray(courseIds)) {
      return sortArrayByOrder(courses, courseIds)
    } else {
      return courses
    }
  }, [courseIds, courses])

  return { courses: sortedCourses, isLoading, fetchCsvData, updateCourses }
}
