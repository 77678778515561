import { z } from 'zod'

export const NumberAttribute = z.object({
  type: z.literal('number'),
})

export type NumberAttribute = z.infer<typeof NumberAttribute>

export const TextAttribute = z.object({
  type: z.literal('text'),
})

export type TextAttribute = z.infer<typeof TextAttribute>

export const DateAttribute = z.object({
  type: z.literal('date'),
})

export type DateAttribute = z.infer<typeof DateAttribute>

export const SelectItem = z.object({
  title: z.string().min(1),
})
export const SelectAttribute = z.object({
  type: z.literal('select'),
  options: z.array(SelectItem).min(2),
})

export type SelectAttribute = z.infer<typeof SelectAttribute>

export const ContentAttribute = z
  .object({
    name: z.string().min(2),
    description: z.string().min(2),
  })
  .and(z.discriminatedUnion('type', [SelectAttribute, DateAttribute, TextAttribute, NumberAttribute]))

export type ContentAttribute = z.infer<typeof ContentAttribute>

export type ContentAttributeType = ContentAttribute['type']
