import type { ButtonProps } from 'sierra-ui/primitives'
export type RenderHint =
  | 'avatar'
  | 'bold'
  | 'column-toggle'
  | 'content-with-duration'
  | 'danger'
  | 'duration'
  | 'indent'
  | 'muted'
  | 'nested-row-left'
  | 'nested-row-right'
  | 'open-in-new-tab'
  | 'percentage'
  | 'remove-row'
  | 'sticky-left'
  | 'sticky-right'
  | 'sticky'
  | 'underline-user-on-hover'
  | 'user-avatar'
  | 'user-with-program-status'
  | 'with-nested-row-toggle'
  | ButtonRenderHint

type ButtonRenderHint = `button.${ButtonProps['variant']}`

export const buttonRenderHint2ButtonVariant = (bh: ButtonRenderHint): ButtonProps['variant'] => {
  const h = bh.replace('button.', '') as ButtonProps['variant']
  return h
}

export const renderHints2ButtonRenderHints = (hs: RenderHint[]): ButtonRenderHint[] => {
  return hs.filter(h => h.startsWith('button.')) as ButtonRenderHint[]
}

export const findButtonVariant = (hs: RenderHint[]): ButtonProps['variant'] | undefined => {
  const bh = renderHints2ButtonRenderHints(hs)[0]
  if (bh) {
    return buttonRenderHint2ButtonVariant(bh)
  }
  return undefined
}
