import { AnimatePresence, motion } from 'framer-motion'
import { Atom, atom, useAtomValue } from 'jotai'
import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as C from 'sierra-client/lib/tabular/components/cells'
import { CheckBoxContainer, InfoIcon } from 'sierra-client/lib/tabular/components/cells'
import * as U from 'sierra-client/lib/tabular/components/util'
import { useSelectOnClick } from 'sierra-client/lib/tabular/components/util'
import { Cell as CellType } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header as HeaderType } from 'sierra-client/lib/tabular/datatype/internal/header'
import * as R from 'sierra-client/lib/tabular/datatype/internal/row'
import { labelToString } from 'sierra-client/lib/tabular/datatype/label'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import {
  SortDirectionIcon,
  SortIndicator,
  Td,
  Th,
  Tr,
  toggleSorting,
} from 'sierra-client/lib/tabular/provider/components/common'
import { useVirtualizedRow } from 'sierra-client/lib/tabular/provider/components/use-virtualized-row'
import { ColumnSelector, Selections } from 'sierra-client/views/manage/users/utils/column-selector'
import { iife } from 'sierra-domain/utils'
import { Checkbox, Skeleton, View } from 'sierra-ui/primitives'
import styled, { css } from 'styled-components'

const HeaderSelect: React.FC = () => {
  const { api, selection: sel, pages } = useTabularContext()
  const pag = useAtomValue(api.atoms.pagination)
  const { t } = useTranslation()

  const checked = iife(() => {
    switch (sel.type) {
      case 'all':
        return sel.excluded.size > 0 ? 'indeterminate' : true
      case 'manual': {
        const noneSelected = sel.rows.size === 0
        if (noneSelected) return false
        const allSelected = sel.rows.size === pag.loaded
        if (allSelected) return true
        return 'indeterminate'
      }
      case 'none':
        return false
    }
  })

  const onChecked = useCallback((): void => {
    switch (sel.type) {
      case 'all':
        api.action.setSelection({ type: 'none' })
        break
      case 'manual': {
        if (sel.rows.size === pag.loaded) {
          api.action.setSelection({ type: 'none' })
        } else {
          const allRowRefs = pages.flatMap(page => page.map(r => r.ref))
          api.action.setSelection({ type: 'manual', rows: new Set(allRowRefs) })
        }
        break
      }
      case 'none': {
        const allRowRefs = pages.flatMap(page => page.map(r => r.ref))
        api.action.setSelection({ type: 'manual', rows: new Set(allRowRefs) })
        break
      }
    }
  }, [api.action, pag.loaded, pages, sel])

  const ariaLabel =
    sel.type === 'all' ? t('tabular.selection.deselect-all.button') : t('tabular.selection.select-all.button')

  return (
    <CheckBoxContainer
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <Checkbox aria-label={ariaLabel} checked={checked} onCheckedChange={onChecked} />
    </CheckBoxContainer>
  )
}

const StyledHeader = styled.span<{ clickable?: boolean }>`
  display: inline-flex;

  ${p =>
    p.clickable === true &&
    css`
      cursor: pointer;
    `}
  &:hover ${SortIndicator} {
    opacity: 0.25;
    transition: opacity 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
  }
`

const Cell: React.FC<{ cell: CellType }> = ({ cell }) => {
  const { api } = useTabularContext()
  return <C.RenderCell cell={cell} api={api} />
}

const CellWrap: React.FC<{ cell: CellType }> = ({ cell }) => {
  // TODO: can we remove using atom here?
  const enabled = useAtomValue(cell.enabled)
  const { api } = useTabularContext()
  const onClick = useSelectOnClick(cell, api)

  if (!enabled) {
    return null
  }

  if (cell.type === 'select') {
    return (
      <Td key={cell.pos.column} onClick={onClick} $width='3rem' hints={cell.hints}>
        <Cell cell={cell} />
      </Td>
    )
  }
  return (
    <Td key={cell.pos.column} hints={cell.hints}>
      <Cell cell={cell} />
    </Td>
  )
}

const Cells: React.FC<{ cells: Array<CellType> }> = ({ cells }) => {
  return (
    <>
      {cells.map(cell => (
        <CellWrap key={cell.pos.row + cell.pos.column} cell={cell} />
      ))}
    </>
  )
}

const TableColumnSelector = (): JSX.Element => {
  const { api, headers } = useTabularContext()
  const { t } = useTranslation()

  const headersToSelect: Selections = React.useMemo(() => {
    return [
      {
        groupName: 'Columns',
        items: headers.flatMap(h =>
          h.type === 'label' ? [{ id: h.ref, label: labelToString(h.label, t) }] : []
        ),
      },
    ]
  }, [headers, t])

  const selectedAtom = React.useMemo(
    () => atom(get => new Set(headers.filter(h => get(h.enabled)).map(h => h.ref))),
    [headers]
  )

  const selected = useAtomValue(selectedAtom)

  return (
    <View justifyContent='flex-end'>
      <ColumnSelector
        selected={selected}
        onUpdate={React.useCallback(
          cols =>
            api.action.hideColumns({
              columns: new Set(headers.map(h => h.ref).filter(ref => !cols.has(ref))),
            }),
          [api.action, headers]
        )}
        selections={headersToSelect}
      />
    </View>
  )
}

export const Header = ({ header }: { header: HeaderType }): JSX.Element => {
  const { api, sorting } = useTabularContext()
  const { t } = useTranslation()
  const sortable = useAtomValue(header.sortable)
  const sort = sorting.find(s => s.column === header.ref)?.direction

  if (header.hints.includes('column-toggle')) {
    return <TableColumnSelector />
  }

  switch (header.type) {
    case 'label':
      return (
        <StyledHeader
          onClick={() => {
            if (sortable) toggleSorting(api, header)
          }}
          clickable={sortable}
        >
          {labelToString(header.label, t)}
          <SortDirectionIcon sortable={sortable} direction={sort} />
          {header.tooltip !== undefined && <InfoIcon title={labelToString(header.tooltip, t)} />}
        </StyledHeader>
      )
    case 'select':
      return <HeaderSelect />
    case 'cell':
      return <Cell cell={header.cell} />
    default:
      return <></>
  }
}

type StickyDirProps = { direction?: 'left' | 'right' }
const StickyHeaderTh = styled(Th)<StickyDirProps>`
  position: sticky;
  ${p => (p.direction === 'left' ? 'left: 0;' : p.direction === 'right' ? 'right: 0;' : '')}
  z-index: 11;
`

const HeaderWrap: React.FC<{ header: HeaderType }> = ({ header }) => {
  const enabled = useAtomValue(header.enabled)
  if (!enabled) {
    return null
  }

  return header.hints.includes('sticky') ? (
    <StickyHeaderTh
      key={header.ref}
      direction={
        header.hints.includes('sticky-right')
          ? 'right'
          : header.hints.includes('sticky-left')
            ? 'left'
            : undefined
      }
    >
      <Header header={header} />
    </StickyHeaderTh>
  ) : (
    <Th key={header.ref} stickyRight={header.hints.includes('sticky-right')}>
      <Header header={header} />
    </Th>
  )
}

export const TableHeader: React.FC = () => {
  const { headers } = useTabularContext()
  return (
    <thead>
      <Tr>
        {headers.map(header => (
          <HeaderWrap key={header.ref} header={header} />
        ))}
      </Tr>
    </thead>
  )
}

const NestedRows = React.forwardRef<
  HTMLTableRowElement,
  {
    parentRow: R.Row & { type: 'Row.Nested' }
    children: R.Row[]
    expandedAtom: Atom<boolean>
  }
>(({ parentRow, children, expandedAtom }, ref): JSX.Element => {
  const { callbacks } = useTabularContext()
  const expanded = useAtomValue(expandedAtom)
  const onClick = callbacks?.onRow !== undefined ? () => callbacks.onRow?.(parentRow) : undefined

  return (
    <>
      <Tr ref={ref} onClick={onClick} expanded={expanded}>
        <Cells cells={parentRow.parent} />
      </Tr>
      {expanded &&
        children.map(row => (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <RowWrap row={row} key={row.ref} nested={true} />
        ))}
    </>
  )
})

export const RowWrap = React.forwardRef<HTMLTableRowElement, { row: R.Row; nested?: boolean }>(
  ({ row, nested }, ref) => {
    const { callbacks } = useTabularContext()
    const onClick = callbacks?.onRow !== undefined ? () => callbacks.onRow?.(row) : undefined

    switch (row.type) {
      case 'Row.Flat':
        return (
          <U.CellRenderContext.Provider value={{ hasChildrenRows: false }}>
            <Tr
              ref={ref}
              key={row.ref}
              onClick={onClick}
              {...(Boolean(nested) ? { 'data-nested': true } : {})}
            >
              <Cells cells={row.cells} />
            </Tr>
          </U.CellRenderContext.Provider>
        )
      case 'Row.Nested':
        return (
          <U.CellRenderContext.Provider value={{ hasChildrenRows: row.children.length > 0 }}>
            <NestedRows key={row.ref} parentRow={row} expandedAtom={row.expanded}>
              {row.children}
            </NestedRows>
          </U.CellRenderContext.Provider>
        )
    }
  }
)

type RenderVirtualizedRowsAtomProps = {
  containerRef: HTMLDivElement | null
  enableAllSelection?: boolean
}

const AnimatedTBody = styled(motion.tbody).attrs({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.25,
  },
})``

const RowSkeletons: React.FC<{
  live?: boolean
  initial?: number
  max?: number
  interval?: number
}> = ({ live = true, initial = 7, max = 20, interval = 1200 }) => {
  const [state, setState] = React.useState(initial)

  React.useEffect(() => {
    let id: NodeJS.Timeout | null = null

    function loop(): void {
      setState(previous => {
        const next = previous + 1

        if (next < max) {
          // Spread the interval randomly 10%
          id = setTimeout(loop, interval * (Math.random() * 0.2 + 0.9))
        }

        return next
      })
    }

    if (live) {
      id = setTimeout(loop, interval)
    }

    return () => {
      if (id !== null) {
        clearTimeout(id)
      }
    }
  }, [live, max, interval])

  return (
    <>
      {Array(state)
        .fill(0)
        .map((_, i) => (
          <motion.tr key={i} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }}>
            <td colSpan={999} style={{ padding: '0 32px' }}>
              <View grow paddingTop='32'>
                <Skeleton $width='100%' $height={20} $radius={8} />
              </View>
            </td>
          </motion.tr>
        ))}
    </>
  )
}

export const TableBody: React.FC<RenderVirtualizedRowsAtomProps> = ({ containerRef }) => {
  const { pages, dataloaderState } = useTabularContext()
  const rows = useMemo(() => pages.flat(), [pages])

  const { padding, visibleRows } = useVirtualizedRow({
    containerRef,
    count: rows.length,
    overscan: 20,
    originalRows: rows,
    estimateSize: () => 100, // This should be the highest row height, but the virtualizer will adapt to the actual height
  })

  return (
    <AnimatePresence mode='popLayout'>
      {dataloaderState === 'init' ? (
        <tbody key='loading'>
          <RowSkeletons />
        </tbody>
      ) : (
        <AnimatedTBody key='content'>
          {padding.top > 0 && (
            <tr>
              <td style={{ height: padding.top }} />
            </tr>
          )}
          {visibleRows.map(row => (
            <RowWrap key={row.ref} row={row} />
          ))}
          {padding.bottom > 0 && (
            <tr>
              <td style={{ height: padding.bottom }} />
            </tr>
          )}
        </AnimatedTBody>
      )}
    </AnimatePresence>
  )
}
