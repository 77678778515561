import { useCallback, useEffect, useRef, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import {
  LiveSessionDetailsResponse,
  LiveSessionListRecordingsResponse,
  LiveSessionSetAttendanceRequest,
} from 'sierra-domain/api/admin'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsUnassignFromLiveSession,
  XRealtimeAdminLiveSessionsDeleteRecording,
  XRealtimeAdminLiveSessionsListRecordings,
  XRealtimeAdminLiveSessionsLiveSessionDetail,
  XRealtimeAdminLiveSessionsSetUsersAttendance,
} from 'sierra-domain/routes'

export type UseLiveSessionDetailsData = {
  liveSession?: LiveSessionDetailsResponse
  isLoading: boolean
  liveSessionRecordings: LiveSessionListRecordingsResponse & { isLoading: boolean }
  fetchLiveSession: (id: LiveSessionId) => Promise<void>
  unassignUsers: (id: LiveSessionId, userIds: UserId[]) => Promise<void>
  setAttendance: (req: LiveSessionSetAttendanceRequest) => Promise<void>
  fetchRecordings: (id: LiveSessionId) => Promise<void>
  removeRecording: (id: string) => Promise<void>
}

export const useLiveSessionDetails = (liveSessionId?: LiveSessionId): UseLiveSessionDetailsData => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setLoading] = useState(false)
  const [liveSession, setLiveSession] = useState<LiveSessionDetailsResponse | undefined>(undefined)

  const [liveSessionRecordings, setLiveSessionRecordings] = useState<
    UseLiveSessionDetailsData['liveSessionRecordings']
  >({
    hasMore: false,
    data: [],
    isLoading: false,
  })
  const unmounted = useRef(false)

  const fetchLiveSession = useCallback<UseLiveSessionDetailsData['fetchLiveSession']>(
    async id => {
      setLoading(true)
      const result = await postWithUserErrorException(XRealtimeAdminLiveSessionsLiveSessionDetail, {
        liveSessionId: id,
      })
      if (unmounted.current) return
      setLiveSession(result)
      setLoading(false)
    },
    [postWithUserErrorException]
  )

  const unassignUsers = useCallback<UseLiveSessionDetailsData['unassignUsers']>(
    async (id, userIds) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromLiveSession, {
        liveSessionId: id,
        userIds,
      })
    },
    [postWithUserErrorException]
  )

  const removeRecording = useCallback<UseLiveSessionDetailsData['removeRecording']>(
    async (recordingId: string) => {
      await postWithUserErrorException(XRealtimeAdminLiveSessionsDeleteRecording, {
        recordingId,
      })
    },
    [postWithUserErrorException]
  )

  const setAttendance = useCallback<UseLiveSessionDetailsData['setAttendance']>(
    async req => {
      await postWithUserErrorException(XRealtimeAdminLiveSessionsSetUsersAttendance, req)
    },
    [postWithUserErrorException]
  )

  const fetchRecordings = useCallback<UseLiveSessionDetailsData['fetchRecordings']>(
    async id => {
      setLiveSessionRecordings(current => ({ ...current, isLoading: true }))
      const response = await postWithUserErrorException(XRealtimeAdminLiveSessionsListRecordings, {
        liveSessionId: id,
        maxResults: undefined,
      })
      setLiveSessionRecordings({
        isLoading: false,
        data: response.data,
        hasMore: false,
      })
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    if (liveSessionId === undefined) return
    unmounted.current = false
    void fetchLiveSession(liveSessionId)
    return () => {
      unmounted.current = true
    }
  }, [fetchLiveSession, liveSessionId])

  useEffect(() => {
    if (liveSessionId === undefined) return
    void fetchRecordings(liveSessionId)
  }, [fetchRecordings, liveSessionId])

  return {
    isLoading,
    liveSession,
    liveSessionRecordings,
    fetchLiveSession,
    fetchRecordings,
    unassignUsers,
    removeRecording,
    setAttendance,
  }
}
