import { useSearch } from '@tanstack/react-router'
import { AnimatePresence, motion } from 'framer-motion'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getFileContentImage } from 'sierra-client/api/content'
import { SidebarButton } from 'sierra-client/components/common/sidebar-button'
import { ContentPreview } from 'sierra-client/components/courses/preview'
import { ExpandableSideBarButton } from 'sierra-client/components/templates/components/expandable-side-bar-button'
import { SearchInput } from 'sierra-client/components/templates/components/search-input'
import { TemplateCard } from 'sierra-client/components/templates/components/template-card'
import { TemplateMiniature } from 'sierra-client/components/templates/components/template-miniature'
import { TemplatesContainer } from 'sierra-client/components/templates/components/templates-container'
import {
  PrimaryTemplateFilter,
  SecondaryTemplateFilter,
  TemplateFilter,
  TemplateTabCategory,
} from 'sierra-client/components/templates/template-filter'
import { useTemplateCategoryTexts } from 'sierra-client/components/templates/use-template-category-texts'
import { UseTemplates } from 'sierra-client/components/templates/use-templates'
import { flexibleContentTemplateFromTemplate } from 'sierra-client/components/templates/utils'
import { getFlag } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { teamspaceContentCreatedLogger } from 'sierra-client/features/teamspace/logger'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { createFlexibleContentFromTemplate } from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { Template } from 'sierra-client/state/templates/types'
import { FlexibleContentTemplate } from 'sierra-domain/api/author-v2'
import { CourseId, NanoId12 } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { FlexibleContentRecord } from 'sierra-domain/collaboration/serialization/types'
import { XRealtimeAdminPathsCreatePath, XRealtimeAuthorTemplatePreview } from 'sierra-domain/routes'
import { iife, isDefined } from 'sierra-domain/utils'
import { Icon, Modal } from 'sierra-ui/components'
import { Button, Heading, IconButton, Text, View } from 'sierra-ui/primitives'
import { spacing } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import { token } from 'sierra-ui/theming/tokens/tokens'
import styled from 'styled-components'

const TextOption = styled(Text)`
  font-weight: ${fonts.weight.medium};
  padding: ${spacing['4']};
  cursor: pointer;

  &:hover {
    color: #000;
  }
`

const RootContainer = styled(View)`
  position: relative;
  height: 85vh;
  width: 85vw;

  display: flex;
  align-items: flex-start;
`

const SidebarContainer = styled(View)`
  display: flex;
  padding: 40px 3rem;
  height: 100%;
  flex-shrink: 0;
  width: 328px;
  border-right: 1px solid ${token('border/strong')};
  justify-content: space-between;

  @media (max-width: 1500px) {
    padding: 40px 1.5rem;
  }
`

const SidebarTop = styled.div`
  flex: 0 0 fit-content;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-block-end: 12px;
`

const SidebarScroll = styled(View)`
  flex: 1 1 auto;
  margin-bottom: 32px;
  overflow: hidden;
  scrollbar-gutter: stable;

  padding-right: 48px;
  margin-right: -48px;

  &:hover {
    overflow-y: auto;
  }
`

const SidebarBottom = styled.div`
  flex: 0 0 fit-content;
`

const StyledTemplatesContainer = styled(TemplatesContainer)`
  margin-left: -12px;
  gap: 8px;
`

const ScrollWrapper = styled.div`
  width: 100%;
  padding-right: 2rem;
  padding-left: 1rem;
  max-height: 100%;
  height: 100%;
  overflow-x: visible;
  overflow-y: hidden;

  padding-bottom: 48px;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`

const CloseIconContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

const SlideContainer = styled(motion.div).attrs({
  transition: {
    duration: 0.2,
    ease: [0.25, 0.1, 0.25, 1],
  },
  initial: { left: '100%' },
  animate: { left: '24px', boxShadow: '-24px 0px 0px 0px #DDD' },
  exit: { left: '100%' },
})`
  position: absolute;
  background-color: white;
  width: calc(100% - 24px);
  top: 0;
  bottom: 0;
  right: 0;
`

type TemplatePickerModalProps = {
  open: boolean
  onClose: () => void | Promise<void>
  templates: UseTemplates
  teamspaceId?: NanoId12
  folderId?: NanoId12
}

export const TemplatePickerModal: React.FC<TemplatePickerModalProps> = ({
  open,
  onClose,
  templates,
  teamspaceId,
  folderId,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { postWithUserErrorException } = usePost()
  const { getLabel, getHeading } = useTemplateCategoryTexts()
  const orgPermissions = useOrganizationPermissions()

  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>()

  const [previewRecord, setPreviewRecord] = useState<FlexibleContentRecord>()
  const previewOpen = selectedTemplate !== undefined && previewRecord !== undefined
  const closePreview = (): void => {
    setPreviewRecord(undefined)
    setSelectedTemplate(undefined)
  }

  const loadPreview = useCallback(
    async (template: FlexibleContentTemplate): Promise<void> => {
      await postWithUserErrorException(XRealtimeAuthorTemplatePreview, { template }).then(({ record }) =>
        setPreviewRecord(record)
      )
    },
    [postWithUserErrorException]
  )

  useEffect(() => {
    if (selectedTemplate !== undefined)
      void loadPreview(flexibleContentTemplateFromTemplate(selectedTemplate))
  }, [selectedTemplate, loadPreview])

  const canCreatePath = orgPermissions.has('CREATE_PATH') && getFlag('allow-path-creation')

  const [openTemplateFilters, setOpenTemplateFilters] = useState({ live: false, selfPaced: false })

  const scrollWrapperRef = useRef<HTMLDivElement>(null)
  const scrollToTop = (): void => {
    if (scrollWrapperRef.current !== null) scrollWrapperRef.current.scrollTo(0, 0)
  }

  const [loading, setLoading] = useState(false)

  const templatesTab = useSearch({
    strict: false,
    select: search => search['templates'],
  })

  const [templateFilter, setTemplateFilterState] = useState<TemplateFilter>(() => {
    switch (templatesTab) {
      case 'recommended':
        return { primary: TemplateTabCategory.Recommended }
      case 'organization':
        return { primary: TemplateTabCategory.AllTeamTemplates }
      case 'community':
        return { primary: TemplateTabCategory.Community }
      case 'live':
        return { primary: TemplateTabCategory.Live, secondary: TemplateTabCategory.All }
      case 'self-paced':
        return { primary: TemplateTabCategory.SelfPaced, secondary: TemplateTabCategory.All }
      case 'search':
        return { primary: TemplateTabCategory.Search, secondary: TemplateTabCategory.All }
      default:
        return {
          primary: TemplateTabCategory.Recommended,
        }
    }
  })

  const setSearchParams = useCallback((filter: PrimaryTemplateFilter) => {
    const template = iife(() => {
      switch (filter) {
        case TemplateTabCategory.Recommended:
          return 'recommended'
        case TemplateTabCategory.AllTeamTemplates:
          return 'organization'
        case TemplateTabCategory.Community:
          return 'community'
        case TemplateTabCategory.Live:
          return 'live'
        case TemplateTabCategory.SelfPaced:
          return 'self-paced'
        case TemplateTabCategory.Search:
          return 'search'
        default:
          return 'recommended'
      }
    })

    void getGlobalRouter().navigate({
      search: { templates: template },
    })
  }, [])

  const [searchTerm, setSearchTerm] = useState<string>('')
  const setTemplateFilter = useCallback(
    (filter: TemplateFilter): void => {
      if (!_.isEqual(filter, templateFilter)) {
        if (filter.primary !== TemplateTabCategory.Search) setSearchTerm('')
        setTemplateFilterState(filter)
        scrollToTop()
      }
    },
    [templateFilter]
  )

  const handleClose = useCallback(async (): Promise<void> => {
    setSelectedTemplate(undefined)
    setPreviewRecord(undefined)
    setLoading(false)
    await getGlobalRouter().navigate({ search: undefined })
    await onClose()
  }, [onClose])

  useEffect(() => {
    if (searchTerm.length > 0) setTemplateFilter({ primary: TemplateTabCategory.Search })
  }, [searchTerm.length, setTemplateFilter])

  const createNewLive = async (): Promise<void> => {
    if (!loading) {
      setLoading(true)
      await getGlobalRouter().navigate({ search: undefined })
      await getGlobalRouter().navigate({ to: '/create/l/new', search: { teamspaceId, folderId } })
      await handleClose()

      if (teamspaceId !== undefined) {
        void dispatch(
          teamspaceContentCreatedLogger({
            teamspaceId,
            templateName: 'live',
          })
        )
      }
    }
  }

  const createNewSelfPaced = async (): Promise<void> => {
    if (!loading) {
      setLoading(true)
      await getGlobalRouter().navigate({ to: '/create/s/new', search: { teamspaceId, folderId } })
      await handleClose()

      if (teamspaceId !== undefined) {
        void dispatch(
          teamspaceContentCreatedLogger({
            teamspaceId,
            templateName: 'self-paced',
          })
        )
      }
    }
  }

  const createNewPath = async (): Promise<void> => {
    if (!loading) {
      setLoading(true)
      const { pathId } = await postWithUserErrorException(XRealtimeAdminPathsCreatePath, {
        title: '',
      })

      void dispatch(Logging.path.pathCreated({ pathId, pathTitle: '' }))

      await getGlobalRouter().navigate({
        to: `/manage/paths/$pathId`,
        params: { pathId },
        search: { isNewPath: true },
      })
      await handleClose()
    }
  }

  const createFromTemplate = useCallback(
    async (template: FlexibleContentTemplate): Promise<void> => {
      if (!loading) {
        setLoading(true)
        await Promise.all([
          dispatch(
            createFlexibleContentFromTemplate({ flexibleContentTemplate: template, teamspaceId, folderId })
          ),
          handleClose(),
        ])
      }
    },
    [dispatch, folderId, handleClose, loading, teamspaceId]
  )

  const newTextOptions = {
    live: (
      <View key='new-live'>
        <Icon iconId='play--circle--filled' size='size-16' color='grey30' />
        <TextOption size='small' color='grey30' onClick={createNewLive}>
          {t('create.home.new-live')}
        </TextOption>
      </View>
    ),
    selfPaced: (
      <View key='new-self-paced'>
        <Icon iconId='bookmark--filled' size='size-16' color='grey30' />
        <TextOption size='small' color='grey30' onClick={createNewSelfPaced}>
          {t('create.home.new-course')}
        </TextOption>
      </View>
    ),
    path: teamspaceId ? null : (
      <View key='new-path' id='tour-templates-newpath'>
        <Icon iconId='path' size='size-16' color='grey30' />
        <TextOption size='small' color='grey30' onClick={createNewPath}>
          {t('create.home.new-path')}
        </TextOption>
      </View>
    ),
  }

  const newFromTemplateCards = {
    live: (
      <TemplateCard
        key='blank-live'
        iconId='play--circle--filled'
        iconBackgroundColor='redVivid'
        label={t('create.home.new-live')}
        description={t('template-picker.new-live')}
        onClick={createNewLive}
      />
    ),
    selfPaced: (
      <TemplateCard
        key='blank-course'
        iconId='bookmark--filled'
        iconBackgroundColor='blueBright'
        label={t('create.home.new-course')}
        description={t('template-picker.new-document')}
        onClick={createNewSelfPaced}
      />
    ),
    path: teamspaceId ? null : (
      <TemplateCard
        key='new-path'
        iconId='path'
        iconBackgroundColor='purpleBright'
        label={t('create.home.new-path')}
        description={t('create.home.combine-content')}
        onClick={createNewPath}
      />
    ),
    newReflection: (
      <TemplateCard
        key='new-reflection'
        iconId='edit'
        iconBackgroundColor='orangeBright'
        label={t('dictionary.reflection')}
        description={t('template-picker.open-a-discussion')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-reflection' })}
      />
    ),
    newPoll: (
      <TemplateCard
        key='new-poll'
        iconId='poll'
        iconBackgroundColor='purpleDark'
        label={t('dictionary.poll')}
        description={t('template-picker.get-opinions-quickly')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-poll' })}
      />
    ),
    newSlidingScale: (
      <TemplateCard
        key='new-sliding-scale'
        iconId='data-scale'
        iconBackgroundColor='blueLight'
        label={t('dictionary.sliding-scale')}
        description={t('template-picker.get-opinions-quickly')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-sliding-scale' })}
      />
    ),
    newVideo: (
      <TemplateCard
        key='new-video'
        iconId='play--outline'
        imagePath={getFileContentImage('images/template-video-image.png', {
          bucket: 'sierra-static',
          optimize: true,
          width: 200,
        })}
        label={t('dictionary.video')}
        description={t('template-picker.start-with-one-card')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-video' })}
      />
    ),
    newEmbed: (
      <TemplateCard
        key='new-embed'
        iconId='code'
        iconColor='grey70'
        iconBackgroundColor='grey5'
        label={t('dictionary.embed')}
        description={t('template-picker.embed-any-page')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-embed' })}
      />
    ),
    newPage: (
      <TemplateCard
        key='new-page'
        iconId='book'
        iconColor='grey70'
        iconBackgroundColor='grey5'
        label={t('dictionary.page')}
        description={t('template-picker.create-a-one-pager')}
        onClick={() => createFromTemplate({ type: 'sana', templateName: 'new-page' })}
      />
    ),
  }
  const getTemplates = (): React.ReactNode[] => {
    const matchingTemplates = iife(() => {
      switch (templateFilter.primary) {
        // SEARCH:
        case TemplateTabCategory.Search:
          return [...templates.sanaLive, ...templates.sanaSelfPaced, ...templates.partnerAll]

        // RECOMMENDED:
        case TemplateTabCategory.Recommended:
          return templates.recommended

        // ALL TEAM TEMPLATES:
        case TemplateTabCategory.AllTeamTemplates:
          return templates.partnerAll

        // LIVE:
        case TemplateTabCategory.Live: {
          switch (templateFilter.secondary) {
            case TemplateTabCategory.All:
              return [...templates.partnerLive, ...templates.sanaLive]
            case TemplateTabCategory.LiveTeamTemplates:
              return templates.partnerLive
            case TemplateTabCategory.Onboarding:
              return templates.sanaLive.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Onboarding)
              )
            case TemplateTabCategory.LeadershipDevelopment:
              return templates.sanaLive.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.LeadershipDevelopment)
              )
            case TemplateTabCategory.SalesEnablement:
              return templates.sanaLive.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.SalesEnablement)
              )
            case TemplateTabCategory.Compliance:
              return templates.sanaLive.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Compliance)
              )
            case TemplateTabCategory.Workshops:
              return templates.sanaLive.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Workshops)
              )
            default:
              return []
          }
        }

        // SELF-PACED:
        case TemplateTabCategory.SelfPaced: {
          switch (templateFilter.secondary) {
            case TemplateTabCategory.All:
              return [...templates.partnerSelfPaced, ...templates.sanaSelfPaced]
            case TemplateTabCategory.SelfPacedTeamTemplates:
              return templates.partnerSelfPaced
            case TemplateTabCategory.LeadershipDevelopment:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.LeadershipDevelopment)
              )
            case TemplateTabCategory.SalesEnablement:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.SalesEnablement)
              )
            case TemplateTabCategory.Compliance:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Compliance)
              )
            case TemplateTabCategory.Onboarding:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Onboarding)
              )
            case TemplateTabCategory.Workshops:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.Workshops)
              )
            case TemplateTabCategory.ReadyMadeCourses:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.ReadyMadeCourses)
              )
            case TemplateTabCategory.SanaOnboarding:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.SanaOnboarding)
              )
            case TemplateTabCategory.ProductManagement:
              return templates.sanaSelfPaced.filter(({ categories }) =>
                categories.includes(TemplateTabCategory.ProductManagement)
              )
            default:
              return []
          }
        }

        //COMMUNITY
        case TemplateTabCategory.Community:
          return templates.community

        default:
          return []
      }
    })

    return matchingTemplates
      .filter(t => {
        const titleLowerCase = t.label.toLowerCase()
        const descriptionLowerCase = t.description?.toLowerCase() ?? ''
        const searchTermLowerCase = searchTerm.toLowerCase()

        return (
          titleLowerCase.includes(searchTermLowerCase) || descriptionLowerCase.includes(searchTermLowerCase)
        )
      })
      .map(t => (
        <TemplateMiniature
          key={t.id}
          template={t}
          onClick={() => setSelectedTemplate(t)}
          onButtonClick={async () => {
            await createFromTemplate(flexibleContentTemplateFromTemplate(t))
          }}
        />
      ))
  }

  const liveSubCategories: SecondaryTemplateFilter[] = [
    TemplateTabCategory.All,
    TemplateTabCategory.LiveTeamTemplates,
    TemplateTabCategory.Onboarding,
    // TemplateTabCategory.LeadershipDevelopment,
    // TemplateTabCategory.SalesEnablement,
    // TemplateTabCategory.Compliance,
    TemplateTabCategory.Workshops,
  ]

  const selfpacedSubCategories: SecondaryTemplateFilter[] = [
    TemplateTabCategory.All,
    TemplateTabCategory.SelfPacedTeamTemplates,
    TemplateTabCategory.Onboarding,
    TemplateTabCategory.LeadershipDevelopment,
    TemplateTabCategory.SalesEnablement,
    TemplateTabCategory.Compliance,
    TemplateTabCategory.ReadyMadeCourses,
    TemplateTabCategory.ProductManagement,
    TemplateTabCategory.SanaOnboarding,
  ]

  const assetContext: AssetContext = useMemo(
    () =>
      selectedTemplate
        ? selectedTemplate.templateType === 'sana'
          ? { type: 'courseTemplate' as const, templateId: selectedTemplate.id }
          : { type: 'course' as const, courseId: CourseId.parse(selectedTemplate.id) }
        : { type: 'unknown' },
    [selectedTemplate]
  )

  return (
    <Modal
      disableScrollbarGutter
      id='tour-templates-create'
      open={open}
      size='fit-content'
      onClose={() => {
        setTemplateFilter({ primary: TemplateTabCategory.Recommended })
        void handleClose()
      }}
    >
      <RootContainer
        cursor={previewOpen ? 'pointer' : undefined}
        onClick={() => {
          if (previewOpen) closePreview()
        }}
      >
        <SidebarContainer direction='column'>
          <SidebarTop>
            <Heading bold size='h4'>
              {t('create.home.create-new')}
            </Heading>
            <SearchInput
              term={searchTerm}
              onChange={term => {
                setSearchTerm(term)
                setSearchParams(TemplateTabCategory.Search)
              }}
            />
          </SidebarTop>

          <SidebarScroll overflow='scroll' gap='xxsmall' direction='column'>
            <SidebarButton
              selected={templateFilter.primary === TemplateTabCategory.Recommended}
              onClick={() => {
                setTemplateFilter({ primary: TemplateTabCategory.Recommended })
                setSearchParams(TemplateTabCategory.Recommended)
              }}
            >
              {t('dictionary.recommended')}
            </SidebarButton>

            <SidebarButton
              id='tour-templates-myorgtemp'
              selected={templateFilter.primary === TemplateTabCategory.AllTeamTemplates}
              onClick={() => {
                setTemplateFilter({ primary: TemplateTabCategory.AllTeamTemplates })
                setSearchParams(TemplateTabCategory.AllTeamTemplates)
              }}
            >
              {t('create.home.team-templates')}
            </SidebarButton>

            <SidebarButton
              id='tour-templates-communitytemp'
              selected={templateFilter.primary === TemplateTabCategory.Community}
              onClick={() => {
                setTemplateFilter({ primary: TemplateTabCategory.Community })
                setSearchParams(TemplateTabCategory.Community)
              }}
            >
              {t('create.home.community-templates')}
            </SidebarButton>

            <ExpandableSideBarButton
              id='tour-templates-livetemp'
              expanded={openTemplateFilters.live}
              primaryLabel={getLabel(TemplateTabCategory.Live)}
              primaryCategory={TemplateTabCategory.Live}
              templateFilter={templateFilter}
              subButtons={liveSubCategories.map(it => ({
                label: getLabel(it),
                category: it,
              }))}
              setTemplateFilter={filter => {
                setTemplateFilter(filter)
                setSearchParams(filter.primary)
              }}
              toggle={() => setOpenTemplateFilters(prev => ({ ...prev, live: !prev.live }))}
            />

            <ExpandableSideBarButton
              id='tour-templates-coursetemp'
              expanded={openTemplateFilters.selfPaced}
              primaryLabel={getLabel(TemplateTabCategory.SelfPaced)}
              primaryCategory={TemplateTabCategory.SelfPaced}
              templateFilter={templateFilter}
              subButtons={selfpacedSubCategories.map(it => ({
                label: getLabel(it),
                category: it,
              }))}
              setTemplateFilter={filter => {
                setTemplateFilter(filter)
                setSearchParams(filter.primary)
              }}
              toggle={() => setOpenTemplateFilters(prev => ({ ...prev, selfPaced: !prev.selfPaced }))}
            />
          </SidebarScroll>

          <SidebarBottom>
            {[
              newTextOptions.live,
              newTextOptions.selfPaced,
              canCreatePath ? newTextOptions.path : undefined,
            ].filter(isDefined)}
          </SidebarBottom>
        </SidebarContainer>

        <ScrollWrapper ref={scrollWrapperRef}>
          {templateFilter.primary === TemplateTabCategory.Recommended && (
            <StyledTemplatesContainer heading={t('template-picker.get-started')}>
              {[
                newFromTemplateCards.live,
                newFromTemplateCards.selfPaced,
                newFromTemplateCards.newPoll,
                newFromTemplateCards.newReflection,
                newFromTemplateCards.newEmbed,
                newFromTemplateCards.newVideo,
                newFromTemplateCards.newPage,
                canCreatePath ? newFromTemplateCards.path : undefined,
              ].filter(isDefined)}
            </StyledTemplatesContainer>
          )}
          <TemplatesContainer heading={getHeading(templateFilter)}>{getTemplates()}</TemplatesContainer>
        </ScrollWrapper>
        <CloseIconContainer>
          <IconButton
            iconId='close'
            variant='transparent'
            aria-label={t('dictionary.close')}
            onClick={handleClose}
          />
        </CloseIconContainer>
      </RootContainer>
      <AnimatePresence>
        {previewOpen && (
          <SlideContainer>
            <ContentPreview
              title={selectedTemplate.label}
              description={selectedTemplate.description}
              type={{
                contentType: selectedTemplate.contentType,
                label: getHeading(templateFilter),
              }}
              onBackClick={closePreview}
              record={previewRecord}
              actionButtons={
                <Button
                  onClick={async () => {
                    await createFromTemplate(flexibleContentTemplateFromTemplate(selectedTemplate))
                  }}
                >
                  {t('create.home.use-template')}
                </Button>
              }
              assetContext={assetContext}
            />
          </SlideContainer>
        )}
      </AnimatePresence>
    </Modal>
  )
}
