import { combineReducers } from '@reduxjs/toolkit'
import { authorCourseSettingsSlice } from 'sierra-client/state/author-course-settings/slice'
import { cardProgressSlice } from 'sierra-client/state/card-progress/slice'
import { chatSlice } from 'sierra-client/state/chat/slice'
import { collaborationSlice } from 'sierra-client/state/collaboration/slice'
import { commentingSlice } from 'sierra-client/state/commenting/slice'
import {
  contentSlice,
  courseSlice,
  linkCourseSlice,
  linkedinCourseSlice,
  pathsSlice,
  programSlice,
  resultsSlice,
  scormCourseSlice,
} from 'sierra-client/state/content/slices'
import { flexibleContentSlice } from 'sierra-client/state/flexible-content/slice'
import { liveSessionFacilitatorsSlice } from 'sierra-client/state/live-session-facilitators/slice'
import { liveSessionSlice } from 'sierra-client/state/live-session/slice'
import { liveSlice } from 'sierra-client/state/live/slice'
import { loggingSlice } from 'sierra-client/state/logging/slice'
import { notificationsSlice } from 'sierra-client/state/notifications/slice'
import { pdfAnnotationSlice } from 'sierra-client/state/pdf-annotation/slice'
import { selfPacedSlice } from 'sierra-client/state/self-paced/slice'
import { templatesSlice } from 'sierra-client/state/templates/slice'
import { userSlice } from 'sierra-client/state/user/user-slice'

export const reducer = combineReducers({
  user: userSlice.reducer,
  notifications: notificationsSlice.reducer,
  courses: courseSlice.reducer,
  partnerCourses: linkedinCourseSlice.reducer,
  scormCourses: scormCourseSlice.reducer,
  linkCourses: linkCourseSlice.reducer,
  authorCourseSettings: authorCourseSettingsSlice.reducer,
  content: contentSlice.reducer,
  results: resultsSlice.reducer,
  selfPaced: selfPacedSlice.reducer,
  logging: loggingSlice.reducer,
  paths: pathsSlice.reducer,
  program: programSlice.reducer,
  liveSessionFacilitators: liveSessionFacilitatorsSlice.reducer,
  liveSession: liveSessionSlice.reducer,
  chat: chatSlice.reducer,
  flexibleContent: flexibleContentSlice.reducer,
  collaboration: collaborationSlice.reducer,
  cardProgress: cardProgressSlice.reducer,
  pdfAnnotation: pdfAnnotationSlice.reducer,
  commenting: commentingSlice.reducer,
  live: liveSlice.reducer,
  templates: templatesSlice.reducer,
})
