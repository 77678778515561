import {
  AnimatePresence,
  motion,
  MotionValue,
  useMotionValueEvent,
  useSpring,
  useTransform,
} from 'framer-motion'
import _ from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useThrottledState } from 'sierra-client/hooks/use-throttled-state'
import { useSelector } from 'sierra-client/state/hooks'
import { SlidingScaleResponseData } from 'sierra-client/state/interactive-card-data/sliding-scale-card'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useFileContext } from 'sierra-client/views/flexible-content/file-context'
import { useSlidingScaleData } from 'sierra-client/views/v3-author/sliding-scale-card/data-layer'
import { color } from 'sierra-ui/color'
import { Icon, RoundAvatar, Tooltip } from 'sierra-ui/components'
import { GrabIcon } from 'sierra-ui/components/icon'
import styled, { css } from 'styled-components'

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  touch-action: none;
`

const Segment = styled.div<{ $isDragging: boolean; $isInteractive: boolean }>`
  flex: 1;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.1).toString()};
  height: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 16px;
  ${p =>
    p.$isDragging &&
    css`
      transform: scaleY(2);
    `}

  ${p =>
    p.$isInteractive &&
    css`
      &:hover {
        cursor: ${p.$isDragging ? 'ew-resize' : 'pointer'};
      }
    `}
`

const SliderInner = styled.div`
  width: 100%;
  padding: 8px 0;
  display: flex;
  gap: 2px;

  &:hover {
    ${Segment} {
      transform: scaleY(2);
    }
  }
`

const Thumb = styled(motion.div)<{
  $isDragging: boolean
  $isRemoteThumb: boolean
  $isInteractive: boolean
}>`
  z-index: 10;
  position: absolute;
  border-radius: 50%;
  user-select: none;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transform: translateX(-50%) scale(0.7);
  outline-offset: 8px;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

  ${p =>
    p.$isRemoteThumb
      ? css`
          pointer-events: none;
        `
      : css`
          border: 4px solid #ffffff;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08);
        `};

  ${p =>
    p.$isInteractive &&
    css`
      cursor: ew-resize;
    `};
  ${p =>
    p.$isDragging &&
    css`
      transform: translateX(-50%) scale(0.9);
    `};

  & > div {
    border: none;
  }
`

const StyledRoundAvatar = styled(RoundAvatar)<{ $positionSet: boolean }>`
  pointer-events: none;
  z-index: 10;
  border-width: 4px;
  ${p =>
    !p.$positionSet &&
    css`
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        content: ' ';
      }
    `}
`

const BottomInstructionsContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  margin-top: 50px;
  text-align: center;
`

const SlideInstructionsContainer = styled.div`
  display: flex;
  width: 99px;
  height: 36px;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.05).toString()};
  border-radius: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  color: ${p => p.theme.color.grey50};
`

type Props = {
  className?: string
  instructions?: JSX.Element | undefined
}

const OpaqueIcon = styled(Icon)`
  opacity: 0.4;
`

const BottomInstructions: React.FC = () => {
  return (
    <BottomInstructionsContainer
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
    >
      <SlideInstructionsContainer>
        <OpaqueIcon iconId='arrow--left' />
        <motion.div
          animate={{ x: [0, -4, 4, 0] }}
          transition={{
            repeat: Infinity,
            repeatType: 'loop',
            duration: 1,
            repeatDelay: 3,
            ease: [0.82, 0.26, 0, 1.27],
          }}
        >
          {GrabIcon}
        </motion.div>
        <OpaqueIcon iconId='arrow--right' />
      </SlideInstructionsContainer>
    </BottomInstructionsContainer>
  )
}

export const CreateSlider: React.FC<{ instructions?: JSX.Element | undefined }> = ({ instructions }) => {
  return (
    <SliderContainer contentEditable={false}>
      <>
        <BottomInstructions />
      </>
      {instructions !== undefined && instructions}
      <SliderInner>
        <Segment $isDragging={false} $isInteractive={false} />
      </SliderInner>
    </SliderContainer>
  )
}

function smoothRise(x: number): number {
  const e = Math.E

  return 1 - Math.pow(e, -(x / 10))
}

function normalKernel(x: number): number {
  const e = Math.E
  const pi = 3.14

  return Math.pow(e, -(x * x) / 2) / Math.sqrt(2 * pi)
}

function kernelDensityEstimate(granularity: number, samples: number[]): number[] {
  const inputArray = _.range(0, granularity - 1).map(x => x / granularity)
  // Array.from({ length: 10 }, (_, x) => x / granularity)

  const n = samples.length
  const bandwidth_h = 0.02

  const estimate = inputArray.map(x => {
    return (
      samples.reduce((previousValue, x_i) => {
        return previousValue + normalKernel((x - x_i) / bandwidth_h)
      }, 0) *
      (1 / (n * bandwidth_h))
    )
  })

  return estimate
}

const ViolinChartContainer = styled.div`
  height: fit-content;
  width: 100%;
  position: absolute;
  pointer-events: none;
`

const ViolinChart: FC<{
  responses: SlidingScaleResponseData[]
  currentUserPosition: MotionValue<number>
  hasBackgroundImage: boolean
}> = ({ responses, currentUserPosition, hasBackgroundImage }) => {
  const [userPosition, setUserPosition] = useThrottledState(currentUserPosition.get(), 16)

  useMotionValueEvent(currentUserPosition, 'change', latest => {
    setUserPosition(Math.round(latest))
  })

  const currentUserUUID = useSelector(selectUser)?.uuid

  const preparedResponses = responses
    .map(response => (response.uuid === currentUserUUID ? { ...response, position: userPosition } : response))
    .map(response => response.position / 100)

  const violin = [0, ...kernelDensityEstimate(1000, preparedResponses), 0]
    .map((value, index) => {
      if (index < 50) return smoothRise(index) * value
      return value
    })
    .reverse()
    .map((value, index) => {
      if (index < 50) return smoothRise(index) * value
      return value
    })
    .reverse()

  const upsideDownPoints = violin.map((point, index) => [(index * 200) / 1000, point * 5 + 70])
  const rightSideUpPoints = violin.map((point, index) => [(index * 200) / 1000, -point * 5 + 70])

  return (
    <>
      <ViolinChartContainer>
        <motion.svg
          key={'topContainer'}
          style={{ display: 'block' }}
          viewBox='0 0 200 140'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.polyline
            initial={{ points: upsideDownPoints.toString() }}
            transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
            animate={{
              points: [null, upsideDownPoints.toString()],
            }}
            stroke='none'
            fill='currentColor'
            opacity={hasBackgroundImage ? 0.25 : 0.08}
            key={'top'}
          />
        </motion.svg>
      </ViolinChartContainer>
      <ViolinChartContainer>
        <motion.svg
          key={'topContainer'}
          style={{ display: 'block' }}
          viewBox='0 0 200 140'
          xmlns='http://www.w3.org/2000/svg'
        >
          <motion.polyline
            animate={{
              points: [null, rightSideUpPoints.toString()],
            }}
            transition={{ duration: 0.25, ease: [0.25, 0.1, 0.25, 1] }}
            initial={{
              points: rightSideUpPoints.toString(),
            }}
            stroke='none'
            fill='currentColor'
            opacity={hasBackgroundImage ? 0.25 : 0.08}
            key={'top'}
          />
        </motion.svg>
      </ViolinChartContainer>
    </>
  )
}

export const Slider: React.FC<Props> = ({ className, instructions }) => {
  const me = useSelector(selectUser)
  const { slidingScaleData, userPosition, setPosition: setMyPosition } = useSlidingScaleData()
  const { file } = useFileContext()
  const hasBackgroundImage = file.backgroundImage !== undefined

  const leftPercentMotionValue = useSpring(userPosition ?? 50, {
    stiffness: 500,
    damping: 40,
    mass: 1,
  })

  const leftPercentSpring = useTransform(leftPercentMotionValue, v => `${v}%`)

  const responseUserIds = useMemo(
    () =>
      slidingScaleData?.responses.flatMap(response => {
        const uuid = response.uuid
        return uuid !== undefined && uuid !== me?.uuid ? [uuid] : []
      }) ?? [],
    [slidingScaleData, me]
  )
  const users = useUsersLegacy(responseUserIds)

  const container = useRef<HTMLDivElement | null>(null)
  const [isDragging, setIsDragging] = useState(false)
  const readOnly = setMyPosition === undefined

  const onChange = useCallback(
    (pos: number): void => {
      setMyPosition?.(pos)
    },
    [setMyPosition]
  )

  const handleSetDragging = (isDrag: boolean): void => {
    if (readOnly) return
    setIsDragging(isDrag)
  }

  // If remote position changes set the local position to whatever the remote is
  useEffect(() => {
    if (userPosition !== undefined) leftPercentMotionValue.set(userPosition)
  }, [leftPercentMotionValue, userPosition])

  const numberOfResponses = slidingScaleData?.responses.length ?? 0

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (readOnly) return
      e.preventDefault()
      e.stopPropagation()
      const { ownerDocument } = e.currentTarget

      setIsDragging(true)

      const onPointerMove = (e: PointerEvent): void => {
        if (!container.current) return
        const { width: containerWidth, left: containerLeft } = container.current.getBoundingClientRect()
        const index = _.clamp(Math.round(((e.clientX - containerLeft) * 100) / containerWidth), 0, 100)
        leftPercentMotionValue.set(index)
      }

      const onPointerUp = (e: PointerEvent): void => {
        setIsDragging(false)
        ownerDocument.removeEventListener('pointermove', onPointerMove)
        ownerDocument.removeEventListener('pointerup', onPointerUp)

        if (!container.current) return
        const { width: containerWidth, left: containerLeft } = container.current.getBoundingClientRect()
        const index = _.clamp(Math.round(((e.clientX - containerLeft) * 100) / containerWidth), 0, 100)
        leftPercentMotionValue.set(index)
        onChange(index)
      }

      ownerDocument.addEventListener('pointermove', onPointerMove)
      ownerDocument.addEventListener('pointerup', onPointerUp)
      onPointerMove(e.nativeEvent)
    },
    [leftPercentMotionValue, onChange, readOnly]
  )

  return (
    <SliderContainer contentEditable={false} className={className} ref={container}>
      {instructions !== undefined && instructions}
      {userPosition === undefined && !readOnly && <BottomInstructions />}
      {numberOfResponses > 6 && (
        <>
          <ViolinChart
            responses={slidingScaleData?.responses ?? []}
            currentUserPosition={leftPercentMotionValue}
            hasBackgroundImage={hasBackgroundImage}
          />
        </>
      )}
      <SliderInner role='slider' onPointerDown={handlePointerDown}>
        <Segment $isDragging={isDragging} $isInteractive={!readOnly} />
      </SliderInner>
      <AnimatePresence>
        {slidingScaleData &&
          slidingScaleData.responses.map(response => {
            const currentUser = users.find(user => user?.uuid === response.uuid)
            if (currentUser === undefined) return

            return (
              <Thumb
                aria-label='user slider marker'
                key={response.uuid}
                $isDragging={false}
                $isRemoteThumb={true}
                $isInteractive={false}
                initial={false}
                animate={{
                  left: `${response.position}%`,
                }}
                transition={{
                  type: 'tween',
                  ease: [0.165, 0.84, 0.44, 1],
                  duration: 0.8,
                }}
              >
                <Tooltip title={currentUser.firstName}>
                  <RoundAvatar
                    size='medium'
                    firstName={currentUser.firstName}
                    lastName={currentUser.lastName}
                    src={getAvatarImage(currentUser.uuid, currentUser.avatar)}
                    color={currentUser.avatarColor}
                  />
                </Tooltip>
              </Thumb>
            )
          })}
        {
          // If the card is readOnly, we only want to show the user's thumb if they have already placed it
          !(readOnly && userPosition === undefined) && (
            <Thumb
              aria-label='your slider marker'
              $isDragging={isDragging}
              $isRemoteThumb={false}
              $isInteractive={!readOnly}
              tabIndex={0}
              initial={false}
              style={{
                left: leftPercentSpring,
              }}
              onFocus={() => handleSetDragging(true)}
              onBlur={() => handleSetDragging(false)}
              onPointerDown={handlePointerDown}
            >
              {me !== undefined && (
                <StyledRoundAvatar
                  $positionSet={userPosition !== undefined}
                  size='large'
                  firstName={me.firstName}
                  lastName={me.lastName}
                  src={getAvatarImage(me.uuid, me.avatar)}
                  color={me.avatarColor}
                  withOutline
                />
              )}
            </Thumb>
          )
        }
      </AnimatePresence>
    </SliderContainer>
  )
}
