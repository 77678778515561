import { getFlag } from 'sierra-client/config/global-config'
import { DynamicT, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { Filter } from 'sierra-client/lib/filter'
import * as CDef from 'sierra-client/lib/tabular/column-definitions'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  AnyColumnDefinition,
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { FilterUsersResponse } from 'sierra-domain/filter/request'
import { XRealtimeAdminUsersCountFilterUsers2, XRealtimeAdminUsersFilterUsers2 } from 'sierra-domain/routes'

type FilterUsers = FilterUsersResponse['users'][number]
type UserTableDefinition = TableDefinitionOf<
  FilterUsers,
  [
    { type: 'users'; ref: 'user' },
    { type: 'numbers'; ref: 'assignments' },
    { type: 'numbers'; ref: 'progress' },
    { type: 'strings'; ref: 'programs' },
    { type: 'strings'; ref: 'role' },
    { type: 'strings'; ref: 'groups' },
    { type: 'dateTimes'; ref: 'lastActiveAt' },
    ...AnyColumnDefinition<FilterUsers>[],
  ]
>
export type UserTableData = TableDataFromDefinition<FilterUsers, UserTableDefinition>

type QueryString = string | undefined
type NextKey = string | undefined

const fetchFilterUsers = ({
  cursor,
  query,
  filter,
  limit = 100,
}: {
  cursor?: NextKey
  query?: QueryString
  filter?: Filter
  limit?: number
}): Promise<FilterUsersResponse> =>
  typedPost(XRealtimeAdminUsersFilterUsers2, {
    filter,
    query,
    next: cursor,
    limit,
  })

const fetchFilterUsersCount = ({
  query,
  filter,
}: {
  query?: QueryString
  filter?: Filter
}): Promise<number> =>
  typedPost(XRealtimeAdminUsersCountFilterUsers2, {
    filter,
    query,
  }).then(r => r.count)

const usersToTableDefinition = (
  metadata: FilterUsersResponse,
  t: TranslationLookup,
  dynamicT: DynamicT
): UserTableDefinition => ({
  nested: {},
  rows: {
    getId: u => u.userInfo.userId,
  },
  columns: [
    CDef.usersColumn({
      ref: 'user',
      header: translatedLabel('table.name'),
      sortable: false,
      getData: u => ({
        firstName: u.userInfo.firstName,
        lastName: u.userInfo.lastName,
        status: u.userInfo.status,
        email: u.userInfo.email,
        avatarColor: u.userInfo.avatarColor,
        avatar: getAvatarImage(u.userInfo.userId, u.userInfo.avatar),
        id: u.userInfo.userId,
        isRequiredAssignment: undefined,
      }),
    }),
    CDef.numbersColumn({
      ref: 'assignments',
      header: translatedLabel('dictionary.assignments'),
      sortable: false,
      getData: u => u.assignments,
    }),
    CDef.numbersColumn({
      ref: 'progress',
      header: translatedLabel('admin.analytics.table.progress'),
      hints: ['percentage'],
      sortable: false,
      tooltip: translatedLabel('manage.users.tooltip.total-completion'),
      getData: u => u.progress,
    }),
    CDef.stringsColumn({
      ref: 'programs',
      header: translatedLabel('dictionary.program-plural'),
      sortable: false,
      getData: u => u.programs.map(g => g.programName).join(', '),
    }),
    CDef.stringsColumn({
      ref: 'role',
      header: translatedLabel('table.role'),
      sortable: false,
      getData: u => {
        const accessLevel = metadata.accessLevels[u.userInfo.userId]
        return accessLevel === undefined ? '' : t(`admin.access-levels.${accessLevel}`)
      },
    }),
    CDef.stringsColumn({
      ref: 'groups',
      header: translatedLabel('dictionary.group-plural'),
      sortable: false,
      enabled: false,
      getData: u => u.groups.map(g => g.groupName).join(', '),
    }),
    CDef.dateTimesColumn({
      ref: 'lastActiveAt',
      header: translatedLabel('admin.analytics.table.last-active-at'),
      sortable: false,
      enabled: false,
      getData: u =>
        u.lastActiveAt !== undefined ? { date: new Date(u.lastActiveAt), format: 'time-ago' } : undefined,
    }),
    ...(getFlag('manage/remove-list-users-risk') !== true
      ? [
          CDef.stringsColumn<FilterUsers, string>({
            ref: 'risk',
            header: translatedLabel('dictionary.status'),
            sortable: false,
            getData: u =>
              u.progress === 1.0
                ? t('manage.status.completed')
                : u.riskScore === 0.0
                  ? t('manage.status.on-track')
                  : u.riskScore === 0.5
                    ? t('manage.users.insights.at-risk-title')
                    : t('manage.status.needs-attention'),
          }),
        ]
      : []),
    // undefined means feature is disabled
    ...(metadata.accessRoles !== undefined
      ? [
          CDef.stringsColumn<FilterUsers, string>({
            ref: 'accessRole',
            header: translatedLabel('manage.users.user-settings.access-role-title'),
            sortable: false,
            getData: u => {
              if (metadata.accessRoles === undefined) {
                return ''
              }
              const accessRole = metadata.accessRoles.userRoles[u.userInfo.userId]
              if (accessRole === undefined) {
                return ''
              }
              const roleLabel = metadata.accessRoles.roleLabels[accessRole]
              return roleLabel === undefined
                ? accessRole
                : dynamicT(roleLabel.translationKey, undefined, roleLabel.defaultLabel)
            },
          }),
        ]
      : []),
    ...metadata.customUserAttributesMetadata.map(({ key, label }) =>
      CDef.stringsColumn<FilterUsers, string>({
        ref: key,
        header: { type: 'untranslated', label },
        hints: [],
        sortable: false,
        enabled: false,
        getData: u => u.customUserAttributes[key],
      })
    ),
  ],
})

export const usersDataLoader = (
  t: TranslationLookup,
  dynamicT: DynamicT
): DL.DataLoaderStateMachine<
  UserTableData,
  {
    users: FilterUsersResponse['users']
    accessLevels: FilterUsersResponse['accessLevels']
    accessRoles: FilterUsersResponse['accessRoles']
    cursor: string | undefined
    customUserAttributesMetadata: FilterUsersResponse['customUserAttributesMetadata']
  }
> =>
  DL.createDataLoader({
    async fetchInit({ control, predicate }) {
      const [res, count] = await Promise.all([
        fetchFilterUsers({
          filter: predicate.filter,
          query: predicate.query,
          limit: control.limit,
        }),
        fetchFilterUsersCount({
          filter: predicate.filter,
          query: predicate.query,
        }),
      ])

      return {
        meta: {
          users: res.users,
          accessLevels: res.accessLevels,
          accessRoles: res.accessRoles,
          cursor: res.next,
          customUserAttributesMetadata: res.customUserAttributesMetadata,
        },
        data: res.users,
        totalCount: count,
        done: res.next === undefined,
      }
    },

    async fetchMore({ control, predicate, meta, pagination }) {
      const res = await fetchFilterUsers({
        filter: predicate.filter,
        query: predicate.query,
        cursor: meta.cursor,
        limit: control.limit,
      })

      return {
        meta: {
          users: res.users,
          accessLevels: res.accessLevels,
          accessRoles: res.accessRoles,
          cursor: res.next,
          customUserAttributesMetadata: res.customUserAttributesMetadata,
        },
        data: res.users,
        // todo: should we refetch the count?
        totalCount: pagination.total,
        done: res.next === undefined,
      }
    },

    transformResults(data, meta) {
      return [definition2Data(usersToTableDefinition(meta, t, dynamicT), data)]
    },
  })
