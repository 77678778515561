import { useEffect, useState } from 'react'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { fetchPartnerTemplates } from 'sierra-client/state/templates/actions'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { XRealtimeAuthorCreateTemplate } from 'sierra-domain/routes'
import { FormElement } from 'sierra-ui/components'
import { Button, Heading, InputPrimitive, Switch, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import styled from 'styled-components'
import { z } from 'zod'

const StyledHeading = styled(Heading)`
  margin-bottom: 1.5rem;
`

type CreateTemplateModalProps = {
  open: boolean
  onClose: () => void
  title: string
  description?: string
  image?: z.infer<typeof ImageUnion>
}

export const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({
  open,
  onClose,
  title: titleProp,
  description: descriptionProp = '',
  image: imageProp,
}) => {
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  const { createContentId, contentType } = useCreatePageContext()
  const notification = useNotif()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [visibleToAllAuthors, setVisibleToAllAuthors] = useState(false)

  const canMakeTemplatePublic = useHasOrganizationPermission('EDIT_TEMPLATE_PUBLIC')

  const [description, setDescription] = useState(descriptionProp)
  useEffect(() => {
    setDescription(descriptionProp)
  }, [descriptionProp, setDescription])

  const [title, setTitle] = useState(titleProp)
  useEffect(() => {
    setTitle(titleProp)
  }, [titleProp, setTitle])

  const [coverImage, setCoverImage] = useState(imageProp)
  useEffect(() => {
    setCoverImage(imageProp)
  }, [imageProp, setCoverImage])

  const exportTemplate = async (): Promise<void> => {
    const { templateId } = await postWithUserErrorException(XRealtimeAuthorCreateTemplate, {
      fromFlexibleContentId: createContentId,
      title: title.trim().length > 0 ? title.trim() : t('admin.author.no-title'),
      description: description,
      image: coverImage,
      templateSettings: {
        visibleToAllAuthors,
      },
    })

    const subPath = ScopedCreateContentId.urlSubPath(contentType)

    const url = `/create/${subPath}/${templateId}`

    notification.push({
      body: '✓  Template published to library',
      type: 'custom',
      level: 'success',
      url,
    })
    onClose()
  }

  // Upload new cover images in the context of the course that we will create a template from
  const assetContext = { type: 'course' as const, courseId: createContentId }

  return (
    <Modal open={open} onClose={onClose} size='medium'>
      <form
        onSubmit={async e => {
          e.preventDefault()
          if (!loading) {
            setLoading(true)
            await exportTemplate()
            void dispatch(fetchPartnerTemplates())
          }
          setTimeout(() => setLoading(false), 1000)
        }}
      >
        <View direction='column'>
          <StyledHeading color='foreground/primary' bold size='h3'>
            Save as template
          </StyledHeading>

          <FormElement label={t('dictionary.name')}>
            <InputPrimitive
              id='name'
              placeholder={t('author.course.description-placeholder')}
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </FormElement>

          <FormElement label={t('dictionary.description')}>
            <TextAreaPrimitive
              id='description'
              rows={4}
              placeholder={t('author.course.create-template-description-placeholder')}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </FormElement>

          <View marginBottom='small' direction='column' justifyContent='center'>
            <Text color='foreground/primary' size='small' bold>
              {t('author.course.custom-cover-image')}
            </Text>
            <ImageUpload value={coverImage} onChange={setCoverImage} assetContext={assetContext} />
          </View>

          {canMakeTemplatePublic === true && (
            <View marginBottom='small'>
              <Switch
                checked={visibleToAllAuthors}
                onChange={checked => setVisibleToAllAuthors(checked)}
                ariaLabel='Make public to entire organization'
              />
              <Text color='foreground/primary' size='small'>
                Make public to entire organization
              </Text>
            </View>
          )}

          <View>
            <Button type='submit' loading={loading}>
              Save and publish
            </Button>
            <Button variant='secondary' onClick={onClose}>
              Cancel
            </Button>
          </View>
        </View>
      </form>
    </Modal>
  )
}
