import { MEDIA_LIBRARY_BUCKET } from 'sierra-client/api/content'
import { AvatarFragmentFragment, AvatarColor as GqlAvatarColor } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { getAvatarImage, getAvatarMediaLibraryImage } from 'sierra-client/utils/avatar-img'
import { Avatar } from 'sierra-domain/api/manage'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { AvatarColor } from 'sierra-domain/user/avatar-color'
import { AvatarUrl } from 'sierra-domain/user/avatar-url'
import { assertNever } from 'sierra-domain/utils'
import { AvatarProps, RoundedAvatarSize } from 'sierra-ui/components/avatars/round-avatar'

export const gqlAvatarColors: Record<GqlAvatarColor, AvatarColor> = {
  BLUE_BRIGHT: 'blueBright',
  BLUE_VIVID: 'blueVivid',
  GREEN_BRIGHT: 'greenBright',
  ORANGE_BRIGHT: 'orangeBright',
  PINK_BRIGHT: 'pinkBright',
  PURPLE_VIVID: 'purpleVivid',
  RED_VIVID: 'redVivid',
}

export function convertGQLAvatar(avatarFragmentValue: AvatarFragmentFragment): Avatar {
  const avatar = avatarFragmentValue

  switch (avatar.__typename) {
    case 'ColorAvatar':
      return {
        type: 'color',
        color: gqlAvatarColors[avatar.color],
        initials: avatar.initials,
      }
    case 'ImageAvatar':
      return {
        type: 'image',
        image: convertGQLImage(avatar.image),
      }
    default:
      assertNever(avatar)
  }
}

// These helper functions are here because the Avatar type isn't widely used yet.
function getAvatarUrlFromImage(userId: string, image: ImageUnion): AvatarUrl | undefined {
  switch (image.type) {
    case 'file':
      return getAvatarImage(userId, image.file)
    case 'unsplash':
    case 'url':
      return undefined
    case 'media-library-image':
      return getAvatarMediaLibraryImage(image, MEDIA_LIBRARY_BUCKET)
    default:
      assertNever(image)
  }
}

export function getAvatarUrl(userId: string | undefined, avatar: Avatar | undefined): AvatarUrl | undefined {
  if (userId === undefined) return undefined
  if (avatar === undefined) return undefined

  switch (avatar.type) {
    case 'image':
      return getAvatarUrlFromImage(userId, avatar.image)
    case 'color':
      return undefined
    default:
      assertNever(avatar)
  }
}

export function getAvatarColor(avatar: Avatar | undefined): AvatarColor | undefined {
  if (avatar === undefined) return undefined

  switch (avatar.type) {
    case 'image':
      return undefined
    case 'color':
      return avatar.color
    default:
      assertNever(avatar)
  }
}

export const getAvatarInitialsForColor = (avatar: Avatar | undefined): string => {
  const initials = avatar?.type === 'color' ? avatar.initials : ''

  return initials
}

export function convertGQLAvatarToAvatarProps(
  userId: string,
  avatarFragmentValue: AvatarFragmentFragment,
  options?: {
    firstName?: string
    lastName?: string
    size: RoundedAvatarSize
  }
): AvatarProps {
  const avatar = convertGQLAvatar(avatarFragmentValue)
  const avatarInitials = getAvatarInitialsForColor(avatar)

  return {
    size: options?.size ?? 'medium',
    firstName: options?.firstName ?? avatarInitials[0],
    lastName: options?.lastName ?? avatarInitials[1],
    color: getAvatarColor(avatar),
    src: getAvatarUrl(userId, avatar),
  }
}
