import { ContentAttribute } from 'sierra-client/views/manage/content-attributes/types'

export const MOCK_DATA: Array<ContentAttribute> = [
  {
    name: 'Attribute1',
    description: 'Attribute1 description',
    type: 'number',
  },
  {
    name: 'Attribute2',
    description: 'Attribute2 description',
    type: 'text',
  },
  {
    name: 'Attribute3',
    description: 'Attribute3 description',
    type: 'date',
  },
  {
    name: 'Attribute4',
    description: 'Attribute4 description',
    type: 'select',
    options: [{ title: 'option1' }, { title: 'option2' }],
  },
]
