import { ChipInputWithAutocomplete } from 'sierra-client/components/common/chip-input-with-autocomplete'
import { Pill } from 'sierra-client/components/common/pill'
import { Tag } from 'sierra-domain/content/tag'
import { createMUIAutocompleteFilterOptions } from 'sierra-ui/mui'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

type EmptyOption = { element: JSX.Element }

function isSkill(skill: Tag | EmptyOption): skill is Tag {
  return !('element' in skill)
}

const StyledAutocomplete = styled(ChipInputWithAutocomplete)`
  flex: 1;
` as typeof ChipInputWithAutocomplete

const defaultFilterOptions = createMUIAutocompleteFilterOptions<Tag | EmptyOption>()

type TagsPickerProps = {
  id: string
  selected: Tag[]
  options: Tag[]
  inputValue: string
  inputPlaceholder: string
  onInputChange: (value: string) => void
  onChange: (selected: Tag[]) => void
  noResultElement?: () => JSX.Element
  limitTags?: number
}

const StyledPill = styled(Pill)`
  border-color: ${token('border/default')};
  background-color: ${token('surface/soft')};
  color: ${token('foreground/primary')};
`

export const TagsPicker: React.FC<TagsPickerProps> = ({
  id,
  selected,
  options,
  onChange,
  noResultElement,
  inputValue,
  onInputChange,
  inputPlaceholder = undefined,
  limitTags = 4,
}) => {
  return (
    <StyledAutocomplete<Tag | EmptyOption, true, true, false>
      id={id}
      options={options}
      getOptionLabel={option => (isSkill(option) ? option.data.name : '')}
      value={selected}
      onChange={(_, values) => onChange(values.filter(isSkill))}
      inputPlaceholder={inputPlaceholder}
      inputValue={inputValue}
      openOnFocus={true}
      multiple
      onInputChange={(event, value) => {
        onInputChange(value)
      }}
      filterOptions={(options, state) => {
        const results = defaultFilterOptions(options, state)

        if (results.length === 0 && noResultElement !== undefined) {
          return [
            {
              element: noResultElement(),
            },
          ]
        }

        return results
      }}
      closeIcon={<></>}
      renderOption={(option, state) => {
        // if 'type' is in emptyOrSkill, means it's empty
        if ('element' in option) return option.element
        return (
          <StyledPill key={option.id} variant='outlined' active={state.selected}>
            {option.data.name}
          </StyledPill>
        )
      }}
      renderTags={(value, getTagProps) => {
        return value.filter(isSkill).map((skill, index) => {
          const tagProps = getTagProps({ index }) as { onDelete: () => void }
          return (
            <Pill key={skill.id} variant='outlined' active={false} onClick={tagProps.onDelete}>
              {skill.data.name}
            </Pill>
          )
        })
      }}
      limitTags={limitTags}
      compactOptions
    />
  )
}
