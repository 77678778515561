import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentClassification, ContentFilter } from 'sierra-client/views/manage/content/utils/content-utils'
import { LabelMenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

type FilterProps<T extends keyof ContentFilter> = {
  value: ContentFilter[T]
  onChange: (value: ContentFilter[T]) => void
}

const IGNORE_FILTER = 'any' as const

const MinWidth = styled.div`
  min-width: 160px;
`

export const ContentTypeFilter: React.FC<FilterProps<'contentClassification'>> = ({
  value,
  onChange,
  ...selectProps
}) => {
  const { t } = useTranslation()
  const items: LabelMenuItem<ContentClassification | typeof IGNORE_FILTER>[] = [
    {
      type: 'label',
      id: IGNORE_FILTER,
      label: t('manage.content.filters.any-type'),
    },
    {
      type: 'label',
      id: 'path',
      label: t('dictionary.path-singular'),
    },
    {
      type: 'label',
      id: 'external',
      label: t('dictionary.external'),
    },
    {
      type: 'label',
      id: 'course',
      label: t('dictionary.course-singular'),
    },
    {
      type: 'label',
      id: 'live',
      label: t('dictionary.live'),
    },
  ]

  const selectedItem = items.find(item => item.id === value) ?? items.find(item => item.id === IGNORE_FILTER)

  return (
    <MinWidth>
      <SingleSelectDropdown
        {...selectProps}
        selectedItem={selectedItem}
        menuItems={items}
        onSelect={item => {
          const v = item.id
          if (v === IGNORE_FILTER) {
            onChange(undefined)
            return
          }
          onChange(v)
        }}
      />
    </MinWidth>
  )
}
