import React, { useCallback, useState } from 'react'
import { useInvalidateUserCache } from 'sierra-client/api/hooks/use-user'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToUserAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { getFlag } from 'sierra-client/config/global-config'
import { Auth } from 'sierra-client/core/auth'
import { UserSingleton } from 'sierra-client/core/user'
import { useHasOrganizationPermission, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslationForBaseRoute } from 'sierra-client/hooks/use-translation'
import { DomainRep, Equals, Or, andAll, createFilter, stringValue } from 'sierra-client/lib/filter'
import { booleanSerializer, useQueryState } from 'sierra-client/lib/querystate/use-query-state'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { UserTableData } from 'sierra-client/lib/tabular/dataloader/users'
import { getRowDataForRowRefs } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectIsImpersonated } from 'sierra-client/state/user/user-selector'
import { InviteUsersPanel } from 'sierra-client/views/manage/components/user-attributes/flows/invite-users/panels/invite-users-panel'
import { UserFilterHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/user-filter-heatmap'
import { EditCreateUserGroupModal } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal'
import {
  ManageUsersTabular,
  ManageUsersTabularActions,
  useManageUsersTableAPI,
} from 'sierra-client/views/manage/users/manage-users-tabular'
import {
  deleteUsers,
  setAllUsersDisabled,
  useFilterUsersDomainReps,
} from 'sierra-client/views/manage/users/use-filter-users'
import { useTracking } from 'sierra-client/views/manage/users/utils/use-tracking'
import { BulkActionFilter } from 'sierra-domain/api/bulk-action'
import { UserId } from 'sierra-domain/api/uuid'
import {
  XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDatesV2,
  XRealtimeAdminAssignmentsSetProgramMemberships,
  XRealtimeAdminGroupsCreateUserGroup,
  XRealtimeAuthCreateImpersonationSession,
  XRealtimeUserResetState,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { View } from 'sierra-ui/primitives'

const defaultFilter = andAll([
  andAll([
    createFilter({ type: 'user.type' }, Equals, Or([stringValue('standard')])),
    createFilter({ type: 'user.status' }, Equals, Or([stringValue('active'), stringValue('pending')])),
  ]),
])

const getUserIds = (api: TableAPI<UserTableData>, refs: string[]): UserId[] => {
  const data = getRowDataForRowRefs(api.query.tableData().tableData, refs)

  return refs.flatMap(ref => {
    const userId = data[ref]?.user.data?.id

    return userId !== undefined ? [userId] : []
  })
}

const getUserNames = (api: TableAPI<UserTableData>, refs: string[]): string[] => {
  const data = getRowDataForRowRefs(api.query.tableData().tableData, refs)

  return refs.flatMap(ref => {
    const userData = data[ref]?.user.data
    if (userData === undefined) return []
    // do we always have something here?
    return `${userData.firstName} ${userData.lastName}`
  })
}

const ManageUsersInner: React.FC<{ domainReps: DomainRep[] }> = ({ domainReps }) => {
  const { t } = useTranslationForBaseRoute()
  const tracking = useTracking()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const dispatch = useDispatch()
  const isImpersonated = useSelector(selectIsImpersonated)

  const orgPermissions = useOrganizationPermissions()
  const canCreateSmartGroups = orgPermissions.has('CREATE_SMART_GROUP')
  const canInviteViaEmail = orgPermissions.has('INVITE_USER_VIA_EMAIL')
  const canInviteViaGroupLink = orgPermissions.has('INVITE_USER_VIA_GROUP_LINK')
  const canAssignContent = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')
  const canImpersonateUser = orgPermissions.has('IMPERSONATE_USER') && !isImpersonated
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const generateFiltersEnabled = getFlag('manage/generate-filters')

  const [showHeatmap, setShowHeatmap] = useState<{ show: boolean; query: string | undefined }>({
    show: false,
    query: undefined,
  })
  const [createSmartGroup, setCreateSmartGroup] = useState(false)
  const canDelete = useHasOrganizationPermission('DELETE_ADMINISTERED_USER')

  // Active action/modal
  const [action, setAction] = useState<
    | undefined
    | {
        action: 'resetProgress'
        userUuid: UserId
        callback: () => Promise<unknown>
      }
    | {
        action: 'impersonate'
        userUuid: UserId
        userName: string
        callback: () => Promise<unknown>
      }
    | {
        action: 'assign-programs' | 'delete'
        userUuids: UserId[]
        callback: () => Promise<unknown>
      }
    | {
        // allow for bulk action
        action: 'deactivate' | 'activate' | 'assign-content'
        bulkAction: BulkActionFilter
        callback: () => Promise<unknown>
      }
  >(undefined)

  // We want this modal's state to be synced to the URL, so we don't use the `action` state.
  const [inviteUsersOpen, setInviteUsersOpen] = useQueryState(booleanSerializer, false, 'invite-users-tab')

  // Auxiliar function
  const resetProgress = useCallback(
    async (userUuid: string): Promise<null> => {
      await postWithUserErrorException(XRealtimeUserResetState, { userId: userUuid })
      return null
    },
    [postWithUserErrorException]
  )

  const invalidate = useInvalidateUserCache()

  const actions = React.useMemo(
    (): ManageUsersTabularActions => ({
      meta: {
        canDelete,
        canResetProgress,
        canAssignContent,
        canImpersonateUser,
      },
      onShowHeatmap: api => () => {
        setShowHeatmap({ show: true, query: api.query.query().query })
      },
      onCreateSmartGroup: () => () => {
        setCreateSmartGroup(true)
      },
      onInviteUser: () => () => {
        setInviteUsersOpen(true)
      },
      onViewUserDetails: () => ref => {
        void getGlobalRouter().navigate({ to: `/manage/users/${ref}` })
      },
      onResetLearnerProgress: api => ref => {
        const userId = getUserIds(api, [ref])[0]
        if (userId === undefined) return

        setAction({
          action: 'resetProgress',
          userUuid: userId,
          callback: () => api.action.refresh(),
        })
      },
      onImpersonate: api => ref => {
        const userId = getUserIds(api, [ref])[0]
        if (userId === undefined) return
        const userName = getUserNames(api, [ref])[0] ?? ''

        setAction({
          action: 'impersonate',
          userUuid: userId,
          userName,
          callback: async () => {
            await api.action.refresh()
            api.action.setSelection({ type: 'none' })
          },
        })
      },
      onDeleteUsers: api => refs => {
        setAction({
          action: 'delete',
          userUuids: getUserIds(api, refs),
          callback: async () => {
            await api.action.refresh()
            api.action.setSelection({ type: 'none' })
          },
        })
      },
      onAddContentToUsers: api => refs => {
        setAction({
          action: 'assign-content',
          bulkAction: { type: 'including', including: getUserIds(api, refs) },
          callback: () => api.action.refresh(),
        })
      },
      onAddContentToAllUsers: api => {
        const selection = api.query.selected().selection
        const excluded = selection.type === 'all' ? getUserIds(api, [...selection.excluded]) : []
        setAction({
          action: 'assign-content',
          bulkAction: {
            type: 'all',
            excluding: excluded,
            filter: api.query.filter().filter,
            query: api.query.query().query,
          },
          callback: () => api.action.refresh(),
        })
      },
      onBulkAddProgram: api => refs => {
        setAction({
          action: 'assign-programs',
          userUuids: getUserIds(api, refs),
          callback: () => api.action.refresh(),
        })
      },
      onActivateUsers: api => refs => {
        setAction({
          action: 'activate',
          bulkAction: {
            type: 'including',
            including: getUserIds(api, refs),
          },
          callback: () => api.action.refresh(),
        })
      },
      onPauseUsers: api => refs => {
        setAction({
          action: 'deactivate',
          bulkAction: {
            type: 'including',
            including: getUserIds(api, refs),
          },
          callback: () => api.action.refresh(),
        })
      },
      onActivateAllUsers: api => {
        const userId = UserSingleton.getInstance().getUser()?.uuid
        if (isDefined(userId)) {
          const selection = api.query.selected().selection
          const excluded = selection.type === 'all' ? getUserIds(api, [...selection.excluded]) : []
          setAction({
            action: 'activate',
            bulkAction: {
              type: 'all',
              excluding: [userId, ...excluded],
              filter: api.query.filter().filter,
              query: api.query.query().query,
            },
            callback: () => api.action.refresh(),
          })
        }
      },
      onPauseAllUsers: api => {
        const userId = UserSingleton.getInstance().getUser()?.uuid
        if (isDefined(userId)) {
          const selection = api.query.selected().selection
          const excluded = selection.type === 'all' ? getUserIds(api, [...selection.excluded]) : []
          setAction({
            action: 'deactivate',
            bulkAction: {
              type: 'all',
              excluding: [userId, ...excluded],
              filter: api.query.filter().filter,
              query: api.query.query().query,
            },
            callback: () => api.action.refresh(),
          })
        }
      },
    }),
    [canDelete, canResetProgress, canAssignContent, canImpersonateUser, setAction, setInviteUsersOpen]
  )

  const tableAPI = useManageUsersTableAPI(
    actions,
    React.useMemo(
      () => ({
        filter: {
          domainReps,
          initialFilter: defaultFilter,
          domain: generateFiltersEnabled ? { type: 'user.filter' } : undefined,
        },
        // The query on the backend side is optimized for the limit size, and when the limit is above 50, we
        // disable nested loop. If this value is changed, make sure to also change the corresponding value in
        // AssignmentOperation.kt.
        limit: 30,
      }),
      [domainReps, generateFiltersEnabled]
    )
  )

  const createSmartGroupCall = useCallback(
    async (name: string, description: string): Promise<void> => {
      setCreateSmartGroup(false)

      const { groupId } = await postWithUserErrorException(XRealtimeAdminGroupsCreateUserGroup, {
        type: 'user-filter',
        name: name,
        description: description,
        filter: tableAPI.api.query.filter().filter,
      })

      tracking.smart.create({ id: groupId, name, description })

      void getGlobalRouter().navigate({ to: `/manage/user-groups/${groupId}` })
    },
    [postWithUserErrorException, tableAPI.api.query, tracking.smart]
  )

  return (
    <>
      <PageTitle title={t('dictionary.user-plural')} />

      <ManageUsersTabular
        actions={actions}
        tableAPI={tableAPI}
        canCreateSmartGroups={canCreateSmartGroups}
        canInviteUsers={canInviteViaEmail || canInviteViaGroupLink}
        canAssignContent={canAssignContent}
      />
      {/* Modals */}
      {action?.action === 'assign-content' && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'user',
            panes: 'course-and-path',
            activePane: 'course',
            showDueDates: true,
            onSave: async selections => {
              const assignments = parseModalToUserAssignment(selections)

              await postWithUserErrorException(XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDatesV2, {
                bulkActionFilter: action.bulkAction,
                assignments: assignments,
              })
              await action.callback()
              notifications.push({ type: 'assigned' })
              selections.forEach(selection => {
                void dispatch(
                  assignmentPriorityLogger({
                    contentType: selection.type,
                    assignmentPriority: selection.assignmentPriority,
                    hasDueDate: selection.dueDate !== undefined,
                    contentId: selection.id,
                    userId: 'bulk',
                  })
                )
              })
              setAction(undefined)
            },
          }}
          subjects={action.bulkAction.type === 'all' ? [] : action.bulkAction.including}
          title={t('manage.groups.assign-content')}
          onClose={() => setAction(undefined)}
        />
      )}
      {action?.action === 'assign-programs' && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'user',
            panes: 'program',
            activePane: 'program',
            onSave: async selections => {
              await postWithUserErrorException(XRealtimeAdminAssignmentsSetProgramMemberships, {
                userIds: action.userUuids,
                programIds: selections.map(item => item.id),
                userIdsWithRequiredAssignments: [],
                programIdsWithRequiredAssignments: selections.flatMap(item =>
                  item.assignmentPriority === 'required' ? [item.id] : []
                ),
              })
              await action.callback()
              notifications.push({
                type: 'custom',
                level: 'success',
                body: t('notifications.added-to-program'),
              })

              action.userUuids.forEach(userUuid => {
                selections.forEach(selection => {
                  void dispatch(
                    assignmentPriorityLogger({
                      contentType: 'program',
                      assignmentPriority: selection.assignmentPriority,
                      hasDueDate: selection.dueDate !== undefined,
                      contentId: selection.id,
                      userId: userUuid,
                    })
                  )
                })
              })
              setAction(undefined)
            },
          }}
          subjects={action.userUuids}
          title={t('admin.organization.users.add-to-program')}
          onClose={() => setAction(undefined)}
        />
      )}
      <InviteUsersPanel
        isOpen={inviteUsersOpen}
        canAssignContent={canAssignContent}
        canInviteViaEmail={canInviteViaEmail}
        canInviteViaGroupLink={canInviteViaGroupLink}
        closeDirectly={() => setInviteUsersOpen(false)}
        onSave={() => {
          void tableAPI.api.action.refresh()
        }}
      />
      <ActionModal
        open={action?.action === 'resetProgress'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.action !== 'resetProgress') return

          await resetProgress(action.userUuid)
          setAction(undefined)
          notifications.push({ type: 'user-progress-reset' })
        }}
        primaryActionLabel={t('admin.organization.users.reset-progress')}
        deleteAction
      />
      <ActionModal
        open={action?.action === 'impersonate'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.action !== 'impersonate') return

          await postWithUserErrorException(XRealtimeAuthCreateImpersonationSession, {
            userId: action.userUuid,
          })
          await Auth.getInstance().synchronize()

          await invalidate()

          await action.callback()
          setAction(undefined)
        }}
        primaryActionLabel={t('admin.organization.users.impersonate')}
        title={t('admin.organization.users.impersonate-caution')}
      >
        <>
          {t('admin.organization.users.impersonate-caution-message', {
            name: (action?.action === 'impersonate' && action.userName) || '',
          })}
        </>
      </ActionModal>
      <ActionModal
        open={action?.action === 'delete'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.action !== 'delete') return

          await deleteUsers(postWithUserErrorException, action.userUuids)
          await action.callback()
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('manage.users.n-users-deleted-message', { count: action.userUuids.length }),
          })
          setAction(undefined)
        }}
        primaryActionLabel={t('admin.delete')}
        deleteAction
      >
        {action?.action === 'delete' &&
          t('manage.users.n-users-delete-message', { count: action.userUuids.length })}
      </ActionModal>
      <ActionModal
        open={action?.action === 'activate'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          const total = tableAPI.api.query.pagination().pagination.total
          if (action?.action !== 'activate') return

          await setAllUsersDisabled(postWithUserErrorException, {
            bulkActionFilter: action.bulkAction,
            disable: false,
          })

          await action.callback()
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('manage.users.n-users-activated-message', {
              count: action.bulkAction.type === 'all' ? total : action.bulkAction.including.length,
            }),
          })
          setAction(undefined)
        }}
        primaryActionLabel={t('admin.organization.learners.unfreeze')}
        deleteAction
      >
        {action?.action === 'activate' &&
          t('manage.users.n-users-activate-message', {
            count:
              action.bulkAction.type === 'all'
                ? tableAPI.api.query.pagination().pagination.total
                : action.bulkAction.including.length,
          })}
      </ActionModal>
      <ActionModal
        open={action?.action === 'deactivate'}
        onClose={() => setAction(undefined)}
        primaryAction={async (): Promise<void> => {
          if (action?.action !== 'deactivate') return
          const total = tableAPI.api.query.pagination().pagination.total

          const userId = UserSingleton.getInstance().getUser()?.uuid
          if (isDefined(userId)) {
            await setAllUsersDisabled(postWithUserErrorException, {
              bulkActionFilter: action.bulkAction,
              disable: true,
            })
          }

          await action.callback()
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('manage.users.n-users-deactivated-message', {
              count: action.bulkAction.type === 'all' ? total : action.bulkAction.including.length,
            }),
          })
          setAction(undefined)
        }}
        primaryActionLabel={t('admin.organization.learners.freeze')}
        deleteAction
      >
        {action?.action === 'deactivate' &&
          t('manage.users.n-users-deactivate-message', {
            count:
              action.bulkAction.type === 'all'
                ? tableAPI.api.query.pagination().pagination.total
                : action.bulkAction.including.length,
          })}
      </ActionModal>
      {canCreateSmartGroups && (
        <EditCreateUserGroupModal
          mode='create'
          type='user-filter' // TODO(seb): Fix type
          open={createSmartGroup}
          filter={tableAPI.api.query.filter().filter}
          readOnlyFilter={true}
          domainReps={domainReps}
          onCancel={() => setCreateSmartGroup(false)}
          onCreate={draft => createSmartGroupCall(draft.name, draft.description)}
        />
      )}
      {showHeatmap.show && (
        <UserFilterHeatmap
          domainReps={domainReps}
          onClose={() => setShowHeatmap({ show: false, query: undefined })}
          query={showHeatmap.query}
          filter={tableAPI.api.query.filter().filter}
        />
      )}
    </>
  )
}

export const ManageUsers: React.FC = () => {
  const domainReps = useFilterUsersDomainReps()
  return domainReps === undefined ? <View /> : <ManageUsersInner domainReps={domainReps} />
}
