import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { AssignmentWithDueDate, UserAssignmentWithDueDate } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { iife } from 'sierra-domain/utils'

export const parseModalToAssignment = (
  userIds: UserId[],
  selections: AssignModalSelection[]
): AssignmentWithDueDate[] =>
  selections.flatMap(selection => {
    const contentType = selection.type

    if (contentType === 'program' || contentType === 'user' || contentType === 'user-group') return []
    if (selection.dueDate?.type === 'relative') return []

    const typeFixedDD = selection.dueDate

    const isRequired = iife(() => {
      if (selection.assignmentPriority === 'required') return true
      if (selection.assignmentPriority === 'default') {
        return selection.isDefaultRequiredAssignment === true
      }
      return false
    })

    return userIds.map(id => ({
      content: {
        id: selection.id,
        type: contentType,
      },
      assignee: {
        id,
        type: 'user',
        dueDate: typeFixedDD?.date,
        autoAssign: selection.liveAssignmentType === 'auto-assign',
        selfEnroll: selection.liveAssignmentType === 'self-enroll',
      },
      isRequired: isRequired,
    }))
  })

export const parseModalToUserAssignment = (selections: AssignModalSelection[]): UserAssignmentWithDueDate[] =>
  selections.flatMap(selection => {
    const contentType = selection.type

    if (contentType === 'program' || contentType === 'user' || contentType === 'user-group') return []
    if (selection.dueDate?.type === 'relative') return []

    const isRequired = iife(() => {
      if (selection.assignmentPriority === 'required') return true
      if (selection.assignmentPriority === 'default') {
        return selection.isDefaultRequiredAssignment === true
      }
      return false
    })

    return [
      {
        content: {
          id: selection.id,
          type: contentType,
        },
        dueDate: selection.dueDate?.date,
        autoAssign: selection.liveAssignmentType === 'auto-assign',
        selfEnroll: selection.liveAssignmentType === 'self-enroll',
        isRequired: isRequired,
      } satisfies UserAssignmentWithDueDate,
    ]
  })

export type ModalAssignableContent =
  | {
      id: string
      isDefaultRequiredAssignment: boolean
      type: 'course' | 'path'
    }
  | {
      id: string
      isDefaultRequiredAssignment: undefined
      type: 'live-session'
    }

export const parseModalToContentAssignment = (
  content: ModalAssignableContent[],
  selections: AssignModalSelection[]
): AssignmentWithDueDate[] =>
  selections.flatMap(selection => {
    const assignee = ((): AssignmentWithDueDate['assignee'] | undefined => {
      if (
        selection.type === 'course' ||
        selection.type === 'path' ||
        selection.type === 'live-session' ||
        selection.type === 'program'
      )
        return undefined

      if (selection.dueDate?.type === 'relative') return undefined
      return {
        ...(selection.type === 'user'
          ? { type: 'user', id: selection.id }
          : { type: selection.type, id: selection.id }),
        dueDate: selection.dueDate?.date,
        autoAssign: selection.liveAssignmentType === 'auto-assign',
        selfEnroll: selection.liveAssignmentType === 'self-enroll',
      }
    })()

    return assignee === undefined
      ? []
      : content.map(({ id, type, isDefaultRequiredAssignment }) => ({
          content: {
            id,
            type,
          },
          assignee,
          isRequired:
            selection.assignmentPriority === 'required' ||
            (selection.assignmentPriority === 'default' && isDefaultRequiredAssignment === true),
        }))
  })

export const getGroupTypeFromSubjectType = (subjectType: 'user-group' | 'program'): 'program' | 'user' =>
  subjectType === 'user-group' ? 'user' : 'program'
