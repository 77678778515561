import { postWithUserErrorCode } from 'sierra-client/state/api'
import { store } from 'sierra-client/state/store'
import { generateInteractiveCardSse } from 'sierra-client/views/flexible-content/generate-from-file-modal/generate-course-sse'
import { OutputControls, PdfPageChunk } from 'sierra-domain/api/author-v2'
import { isRight } from 'sierra-domain/either'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { XRealtimeAuthorGenerateQuestionFromText } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import {
  createHeading,
  createPollCard,
  createPollCardAlternative,
  createPollCardAlternativeContainer,
  createQuestionCard,
  createQuestionCardMultipleChoiceAlternative,
  createQuestionCardMultipleChoiceBody,
  createQuestionMultipleChoiceAlternativeExplanation,
  createQuestionMultipleChoiceAlternativeOption,
  createReflectionCard,
  createTitleCard,
} from 'sierra-domain/v3-author/create-blocks'
import { z } from 'zod'

export async function generateTitle(
  chunks: PdfPageChunk[],
  outputControls: OutputControls | undefined
): Promise<SlateDocument> {
  const pagesContent = chunks.map(chunk => chunk.text).join('\n')

  let text: string | null = null
  try {
    const result = await generateInteractiveCardSse({
      outputControls,
      messages: [
        {
          role: 'system',
          content: [{ type: 'text', text: `Write a short title based on the content. Omit all formatting.` }],
        },
        {
          role: 'user',
          content: [{ type: 'text', text: pagesContent.slice(0, 15000) }],
        },
      ],
      onError: e => console.error(e),
    })
    text = result.map(it => it.data.text).join('')
  } catch (e) {
    console.error(e)
    text = ''
  }

  return [
    createTitleCard({
      children: [
        createHeading({
          level: 5,
          children: [
            {
              text: text.replace(/^"/, '').replace(/"$/, ''),
              bold: true,
            },
          ],
        }),
      ],
    }),
  ]
}

const GeneratedPollResponse = z.object({
  question: z.string(),
  alternatives: z.string().array().min(2),
})

const pollCardPrompt = `
  Write an open poll question based on the content, focus on opinion questions rather than fact checking. Include between 2 to 4 alternatives.
  Use this format:
  {"question": "<str>", "alternatives": ["<str>"]}
  `.trim()

export async function generatePollCard(
  chunks: PdfPageChunk[],
  setSlateDocument: (newSlateDocument: SlateDocument) => void,
  outputControls: OutputControls | undefined,
  abortController?: AbortController
): Promise<void> {
  let pollCardContent = ''
  const content = chunks.map(it => it.text).join('\n')

  try {
    await generateInteractiveCardSse({
      outputControls,
      messages: [
        {
          role: 'system',
          content: [{ type: 'text', text: pollCardPrompt }],
        },
        {
          role: 'user',
          content: [{ type: 'text', text: content }],
        },
      ],
      onError: e => console.error(e),
      onMessage: event => {
        const text = event.data.text
        pollCardContent += text
      },
      signal: abortController?.signal,
    })

    const generatedPollResponse = GeneratedPollResponse.parse(JSON.parse(pollCardContent))
    const alternatives = generatedPollResponse.alternatives.map(it =>
      createPollCardAlternative({ children: [{ text: it }] })
    )

    setSlateDocument([
      createPollCard({
        children: [
          createHeading({ level: 3, children: [{ text: generatedPollResponse.question }] }),
          createPollCardAlternativeContainer({ children: alternatives }),
        ],
      }),
    ])
  } catch (e) {
    console.error(e)
  }
}

export async function generateReflectionCard(
  chunks: PdfPageChunk[],
  setSlateDocument: (newSlateDocument: SlateDocument) => void,
  outputControls: OutputControls | undefined,
  abortController?: AbortController
): Promise<void> {
  let reflectionCardTitle = ''
  const content = chunks.map(it => it.text).join('\n')

  try {
    await generateInteractiveCardSse({
      outputControls,
      messages: [
        {
          role: 'system',
          content: [{ type: 'text', text: 'Write a reflective question based on the content' }],
        },
        {
          role: 'user',
          content: [{ type: 'text', text: content }],
        },
      ],
      onError: e => console.error(e),
      onMessage: event => {
        const text = event.data.text
        reflectionCardTitle += text
      },
      signal: abortController?.signal,
    })

    setSlateDocument([
      createReflectionCard({
        children: [createHeading({ level: 3, children: [{ text: reflectionCardTitle }] })],
      }),
    ])
  } catch (e) {
    console.error(e)
  }
}

export async function generateQuestionCard(chunks: PdfPageChunk[]): Promise<SlateDocument> {
  const content = chunks.map(it => it.text).join('\n')
  const response = await postWithUserErrorCode(
    XRealtimeAuthorGenerateQuestionFromText,
    { text: content },
    store.dispatch
  )

  if (!isRight(response)) return []
  const { question } = response.right

  if (!isDefined(question)) return []

  return [
    createQuestionCard({
      children: [
        createHeading({ level: 3, children: [{ text: question.text, bold: true }] }),
        createQuestionCardMultipleChoiceBody({
          children: question.alternatives.map(it =>
            createQuestionCardMultipleChoiceAlternative({
              children: [
                createQuestionMultipleChoiceAlternativeOption({ children: [{ text: it.text }] }),
                createQuestionMultipleChoiceAlternativeExplanation(),
              ],
              id: nanoid12(),
              status: it.type === 'partially-correct' ? 'correct' : it.type,
            })
          ),
        }),
      ],
    }),
  ]
}
