import { DateTime } from 'luxon'
import React, { useMemo, useRef } from 'react'
import { downloadBlob } from 'sierra-client/api'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { canvasColumn } from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { LiveSessionListRecordingsResponse, LiveSessionRecording } from 'sierra-domain/api/admin'
import { ItemOf } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { scrollViewStyles, Text, View } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import styled from 'styled-components'

type LiveSessionRecordingsData = ItemOf<LiveSessionListRecordingsResponse['data']>

type LiveSessionRecordingsTableDefinition = TableDefinitionOf<
  LiveSessionRecordingsData,
  [{ type: 'canvas'; ref: 'preview' }]
>

type LiveSessionRecordingsTableData = TableDataFromDefinition<
  LiveSessionRecordingsData,
  LiveSessionRecordingsTableDefinition
>

type LiveSessionRecordingsTableMeta = {
  subset: LiveSessionRecordingsTableData
}

const VideoPreview = styled.div`
  background-color: ${palette.primitives.black};
  width: 88px;
  height: 64px;
  border-radius: ${p => p.theme.borderRadius['size-8']};
  display: flex;
  align-items: center;
  justify-content: center;
`

const title = (recording: LiveSessionRecordingsData): string => {
  const obj = DateTime.fromISO(recording.createdAt)
  const date = obj.toFormat('yyyy-MM-dd')
  const time = obj.toFormat('HH:mm:ss')
  return `Recording ${date} at ${time}`
}

const subtitle = (recording: LiveSessionRecordingsData): string => {
  return DateTime.fromISO(recording.createdAt).toFormat('LLL dd, HH:mm')
}

const VideoCell: React.FC<{ row: LiveSessionRecordingsData }> = ({ row }) => {
  return (
    <View direction='row' gap='24'>
      <VideoPreview>
        <Icon iconId='play--filled' color='white' />
      </VideoPreview>
      <View direction='column' gap='2'>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' bold>
          {title(row)}
        </Text>
        <Text color='foreground/muted' size='small'>
          {subtitle(row)}
        </Text>
      </View>
    </View>
  )
}

const tableDefinition = (): LiveSessionRecordingsTableDefinition => ({
  columns: [
    canvasColumn({
      ref: 'preview',
      header: translatedLabel('dictionary.recordings'),
      getData: r => ({
        meta: {
          exports: () => r.downloadUrl,
          sorts: () => r.createdAt,
        },
        view: <VideoCell row={r} />,
      }),
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.id,
  },
})

const useLiveSessionRecordingsTable = ({
  data,
  onRemoveRecording,
  sessionIsClosed,
  sessionId,
  downloadRecording,
}: {
  data: LiveSessionRecordingsData[]
  onRemoveRecording: (recordingId: string) => void
  downloadRecording: (url: string) => Promise<void>
  sessionIsClosed: boolean
  sessionId: string
}): UseTableAPI<LiveSessionRecordingsTableData, LiveSessionRecordingsTableMeta> => {
  const { t } = useTranslation()
  const notif = useNotif()
  const loader = staticDataLoader<LiveSessionRecordingsTableData>(definition2Data(tableDefinition(), data))
  return useTableAPI({
    dataLoader: { loader },
    virtualColumns: {
      left: [],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-recap',
                  label: t('learner-home.view-recap'),
                  hidden: !sessionIsClosed,
                  onClick: () => {
                    void getGlobalRouter().navigate({
                      to: `/r/${sessionId}`,
                    })
                  },
                },
                {
                  type: 'label',
                  disabled: !Boolean(row.rawData.hlsUrl),
                  id: 'copy-link',
                  label: t('share.copy-link'),
                  onClick: () => {
                    void window.navigator.clipboard.writeText(`${window.location.origin}/r/${sessionId}`)
                    notif.push({
                      type: 'custom',
                      level: 'success',
                      body: 'Copied recap url to clipboard',
                    })
                  },
                },
                {
                  type: 'label',
                  id: 'download',
                  label: t('manage.reports.download'),
                  onClick: () => {
                    if (row.rawData.downloadUrl !== undefined) {
                      void downloadRecording(row.rawData.downloadUrl)
                    } else {
                      notif.push({
                        type: 'custom',
                        level: 'warning',
                        body: 'Something went wrong. Please try again',
                      })
                    }
                  },
                },
                {
                  type: 'label',
                  id: 'delete',
                  label: t('content.delete-button'),
                  onClick: () => onRemoveRecording(row.rawData.id),
                  color: 'destructive/background',
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 1rem;
  max-height: 27rem;
  width: 100%;

  & thead {
    display: none;
  }
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

export const LiveSessionRecordingsTable: React.FC<{
  data: LiveSessionRecordingsData[]
  onRemoveRecording: (recordingId: string) => void
  onRecordingClicked: (recording: LiveSessionRecording) => void
  sessionIsClosed: boolean
  sessionId: string
}> = ({ data, onRecordingClicked, onRemoveRecording, sessionIsClosed, sessionId }) => {
  const { t } = useTranslation()
  const downloadRecording = async (url: string): Promise<void> => {
    const response = await fetch(url, { method: 'GET' })
    const blob = new Blob([await response.blob()])

    downloadBlob(blob, 'recording.mp4')
  }
  const tableAPI = useLiveSessionRecordingsTable({
    data,
    onRemoveRecording,
    sessionIsClosed,
    sessionId,
    downloadRecording,
  })

  const callbacks = useMemo<TableCallbacks<LiveSessionRecordingsTableData>>(
    () => ({
      onRow: ({ rawData }) => {
        onRecordingClicked(rawData)
      },
    }),
    [onRecordingClicked]
  )

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={callbacks}>
      <Text bold size='large'>
        {t('dictionary.recordings')}
      </Text>
      <Table />
    </TabularProviderFromTableAPI>
  )
}
