import { atom } from 'jotai'
import { selectAtom } from 'jotai/utils'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { Cell, CellIconMenuAction } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { Selection } from 'sierra-client/lib/tabular/datatype/selection'
import { RowDataOfTableData, RowOfTableData, TableData } from 'sierra-client/lib/tabular/datatype/tabledata'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { Position, RowRef } from 'sierra-client/lib/tabular/types'
import { selectedAtom } from 'sierra-client/lib/tabular/utils/atom-helpers'
import { VirtualColumn } from 'sierra-client/lib/tabular/virtual-columns'
import { assertNever, isDefined, isNotDefined } from 'sierra-domain/utils'

export const defaultMenuActionVirtualColumn = <TD extends TableData>(input?: {
  getProps?: (props: {
    api: TableAPI<TD>
    pos: Position
    td: TD
    row: RowOfTableData<TD>
  }) => CellIconMenuAction['props'] | 'no-actions'
  options?: { columnToggle?: boolean }
}): VirtualColumn<TD> => {
  const options = input?.options ?? {
    columnToggle: true,
  }

  const getProps = input?.getProps

  return {
    ref: 'menu-actions',
    header: () =>
      ({
        type: 'action',
        ref: 'menu-actions',
        enabled: atom(() => true),
        sortable: atom(() => false),
        hints: (['sticky', 'sticky-right'] as RenderHint[]).concat(
          Boolean(options.columnToggle) ? ['column-toggle'] : []
        ),
      }) satisfies Header,
    cell: ({ pos, api, td, row }): Cell<TD> => {
      const props = getProps?.({ api, pos, td, row })
      return isNotDefined(props) || props === 'no-actions'
        ? {
            type: 'empty',
            pos,
            hints: ['sticky', 'sticky-right'],
            enabled: atom(() => true),
            selected: selectedAtom(pos, api.atoms.selection),
            hideDecorator: true,
          }
        : {
            type: 'iconMenuAction',
            props: {
              ...props,
            },
            pos,
            hints: ['sticky', 'sticky-right'],
            enabled: atom(() => true),
            selected: selectedAtom(pos, api.atoms.selection),
          }
    },
  }
}

export const defaultSelectVirtualColumn = <TD extends TableData>(): VirtualColumn<TD> => ({
  ref: 'selection',
  header: api =>
    ({
      type: 'select',
      ref: 'selection',
      enabled: atom(() => true),
      sortable: atom(() => false),
      selected: selectAtom(api.atoms.selection, sel => sel.type === 'all'),
      hints: ['sticky', 'sticky-left'],
    }) satisfies Header,
  cell: ({ pos, api }) =>
    ({
      type: 'select',
      pos,
      hints: ['sticky', 'sticky-left'],
      enabled: atom(() => true),
      selected: selectedAtom(pos, api.atoms.selection),
    }) satisfies Cell,
})

// Returns a function that ignores invocations if it is currently at `max` pending invocations.
export const maxConcurrently = <A extends unknown[]>(
  max: number,
  g: (...args: A) => Promise<void>
): ((...args: A) => Promise<void>) => {
  let count = 0
  return async (...args: A) => {
    if (count >= max) return
    count++
    try {
      await g(...args)
    } finally {
      count--
    }
  }
}

export const getRow = <TD extends TableData>(tds: TD[], ref: RowRef): RowOfTableData<TD> | undefined => {
  const rowData = tds.flatMap(td => [td, ...Object.values(td.nested)]).flatMap(td => td.rows)
  return rowData.find(r => r.id === ref)
}

export const getRowDatas = <TD extends TableData>(
  tds: TD[],
  ref: RowRef
): RowDataOfTableData<TD> | undefined => getRow(tds, ref)?.data

export const getRowData = <TD extends TableData>(td: TD, ref: RowRef): RowDataOfTableData<TD> | undefined => {
  return getRowDatas([td], ref)
}

export const getRowDataForRowRefs = <TD extends TableData>(
  tds: TD[],
  refs: RowRef[]
): Record<RowRef, RowDataOfTableData<TD>> => {
  const res: Record<RowRef, RowDataOfTableData<TD>> = {}

  for (const ref of refs) {
    const rowData = getRowDatas(tds, ref)
    if (isDefined(rowData)) {
      res[ref] = rowData
    }
  }

  return res
}

export const getRowDataFromTableAPI = <TD extends TableData>(
  tableAPI: TableAPI<TD>,
  ref: RowRef
): RowDataOfTableData<TD> | undefined => {
  const tds = tableAPI.query.tableData().tableData
  return getRowDatas(tds, ref)
}

/**
 * @deprecated
 * DO NOT USE
 * Reason why this is deprecated is that "all" is not possible to compute in the client side
 * "all" refers to `all data` in the backend thus not possible to know on the client side
 * */
export const temporaryComputeSelectedRows = (tableData: TableData[], sel: Selection): RowRef[] => {
  switch (sel.type) {
    case 'manual':
      return [...sel.rows]
    case 'all':
      // return tableData.flatMap(td => td.rows.map(r => r.id))
      throw new Error(
        'Not possible to compute selected rows for `all` as it refers to all data in the backend. If you need to select all, do a fetch to the BE'
      )
    case 'none':
      return tableData.flatMap(td => td.rows.map(r => r.id))
    default:
      return assertNever(sel)
  }
}
