import { Identity } from 'sierra-client/components/common/identities-selector'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { Avatar } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import styled from 'styled-components'

export const collaboratorsToIdentities = (collaborators: Collaborator[]): Identity[] => {
  return collaborators.map(collaborator => {
    const avatar: Avatar = isDefined(collaborator.avatar)
      ? {
          type: 'image',
          image: {
            type: 'url',
            url: getAvatarImage(collaborator.uuid, collaborator.avatar) ?? '',
          },
        }
      : {
          type: 'color',
          initials: (collaborator.firstName?.[0] ?? '') + (collaborator.lastName?.[0] ?? ''),
          color: collaborator.avatarColor ?? 'pinkBright',
        }
    return {
      id: collaborator.uuid,
      identity: { type: 'user', id: collaborator.uuid },
      name: collaborator.firstName + ' ' + collaborator.lastName,
      email: collaborator.email,
      avatar: avatar,
    }
  })
}

export const IdentitiesToCollaborators = (identities: Identity[]): Collaborator[] => {
  return identities.map(identity => {
    const [firstName, ...lastName] = identity.name.split(' ')
    return {
      uuid: identity.id as UserId,
      avatar:
        identity.avatar.type === 'image'
          ? identity.avatar.image.type === 'url'
            ? identity.avatar.image.url
            : undefined
          : undefined,
      avatarColor: 'blueBright',
      email: identity.email,
      firstName: firstName ?? '',
      lastName: lastName.join(' '),
    }
  })
}

export const DropdownContainer = styled.div`
  flex-shrink: 0;
`
