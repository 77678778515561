import _ from 'lodash'
import React, { useEffect, useMemo, useRef } from 'react'
import { CSVLink } from 'react-csv'
import { percentage } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { Row } from 'sierra-client/lib/tabular/datatype/internal/row'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { CourseGroupActionModalControl } from 'sierra-client/views/manage/courses/course-group-action-modals'
import {
  CourseGroupUserProgressTableData,
  CourseGroupUserProgressTableMeta,
  useCourseGroupUserProgressTableAPI,
} from 'sierra-client/views/manage/courses/use-course-group-user-progress-table'
import { CourseGroupUserProgressRow } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { Button, scrollViewStyles, Spacer, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type ContentUserCsv = {
  userId: string
  email: string
  firstName: string
  lastName: string
  completion: string
  assignedAt: string
  startedAt: string
  assignedVia: string
  activeEdition: string
}

const parseEnrolledUserToCsv = (user: CourseGroupUserProgressRow): ContentUserCsv => ({
  userId: user.userId,
  email: user.email ?? '',
  firstName: user.firstName ?? '',
  lastName: user.lastName ?? '',
  completion: percentage(user.completionRate),
  assignedAt: user.createdAt?.toISOString() ?? '',
  startedAt: user.startedAt?.toISOString() ?? '',
  assignedVia:
    user.programs.length === 0 ? 'Individual' : _.compact(user.programs.map(g => g.name)).join(', '),
  activeEdition:
    ((user.completionRate ?? 0) !== 0 || !user.assignedViaCourseGroup
      ? user.editionTitleUsedForProgress
      : '') ?? '',
})

type SearchProps = {
  tableAPI: UseTableAPI<CourseGroupUserProgressTableData, CourseGroupUserProgressTableMeta>
}

const Search: React.FC<SearchProps> = ({ tableAPI }) => {
  const { t } = useTranslation()

  const [debouncedQuery, liveQuery, setQuery] = useDebouncedAndLiveState(tableAPI.api.query.query().query)

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: debouncedQuery ?? '' })
  }, [debouncedQuery, tableAPI.api.action])

  return (
    <>
      <RoundedSearchBar value={liveQuery} placeholder={t('manage.search.users')} onChange={setQuery} />
      <Spacer size='xsmall' />
    </>
  )
}

type ToolbarProps = {
  openUnassignUsers: (userIds: UserId[]) => void
  mapCsv: (userIds: string[]) => ContentUserCsv[]
}
const Toolbar: React.FC<ToolbarProps> = ({ openUnassignUsers, mapCsv }) => {
  const { selection, api } = useTabularContext()
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.users'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            <ActionButton
              onClick={() => {
                openUnassignUsers(Array.from(selection.rows).map(r => UserId.parse(r)))
              }}
              color='redBright'
            >
              {t('dictionary.unassign')}
            </ActionButton>
            <ActionButton
              onClick={() => {
                api.action.setSelection({ type: 'none' })
              }}
              color='black'
            >
              {t('tabular.selection.clear-all.button')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={true}
    />
  )
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 10rem;
  max-height: calc(100vh - 21.875rem);
  height: 100%;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

type FooterProps = {
  canEditAssignments: boolean
  csvData: ContentUserCsv[]
  openEnrollUsers: () => void
}

const Footer: React.FC<FooterProps> = ({ canEditAssignments, csvData, openEnrollUsers }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent={canEditAssignments ? 'space-between' : 'flex-end'}>
      {canEditAssignments === true && (
        <Button onClick={openEnrollUsers}>{t('manage.manage-learners')}</Button>
      )}
      <ExportCSVIconButton fetchCsvData={() => csvData} filename={t('admin.analytics.users')} />
    </View>
  )
}

type Props = {
  refetch: () => void
  data: CourseGroupUserProgressRow[]
  courseGroupId: CourseId
  modal: CourseGroupActionModalControl
  isDefaultRequiredAssignment: boolean
}

export const CourseGroupUserProgressTable: React.FC<Props> = ({
  refetch,
  data,
  modal,
  courseGroupId,
  isDefaultRequiredAssignment,
}) => {
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)

  const callbacks = useMemo(
    () => ({
      onRow: (row: Row<CourseGroupUserProgressTableData>) => {
        void getGlobalRouter().navigate({ to: `/manage/users/${row.rawData.userId}` })
      },
    }),
    []
  )

  const openUnassignUsers = (userIds: UserId[]): void => {
    modal.open({ modal: 'unassign-users', userIds })
  }
  const openEnrollUsers = (): void => {
    modal.open({ modal: 'enroll-to-group', courseId: courseGroupId, isDefaultRequiredAssignment })
  }

  const tableAPI = useCourseGroupUserProgressTableAPI({
    refetch,
    data,
    courseGroupId,
    modal,
  })

  const mapCsv = (userIds: string[]): ContentUserCsv[] => {
    return data.filter(r => userIds.includes(r.userId)).map(parseEnrolledUserToCsv)
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={callbacks}>
      <Search tableAPI={tableAPI} />
      <Toolbar openUnassignUsers={openUnassignUsers} mapCsv={mapCsv} />
      <Table />
      <Footer
        canEditAssignments={courseGroupPermissions.has('EDIT_ASSIGNMENTS')}
        csvData={mapCsv(data.map(({ userId }) => userId))}
        openEnrollUsers={openEnrollUsers}
      />
    </TabularProviderFromTableAPI>
  )
}
