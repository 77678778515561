import { MicrosoftCalendarAccessResult } from 'sierra-client/api/graphql/gql/graphql'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assertNever } from 'sierra-domain/utils'
import { Text } from 'sierra-ui/primitives'

export const MicrosoftCalendarError: React.FC<{
  accessResult: Exclude<MicrosoftCalendarAccessResult, 'HasAccess'>
}> = ({ accessResult }) => {
  const { t } = useTranslation()

  switch (accessResult) {
    case 'Error':
      return (
        <Text size='small' color='destructive/background'>
          {t('admin.author.calendar-integration.microsoft-permission-error')}
        </Text>
      )
    case 'InsufficientAccess':
      return (
        <Text size='small' color={'destructive/background'}>
          {t('admin.author.calendar-integration.microsoft-insufficient-access')}
        </Text>
      )
    default:
      assertNever(accessResult)
  }
}
