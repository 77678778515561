import { createEntityAdapter } from '@reduxjs/toolkit'
import {
  CourseEntity,
  LinkCourseEntity,
  LinkedInCourseEntity,
  PathEntity,
  ProgramEntity,
  ScormCourseEntity,
} from 'sierra-client/state/content/types'

export const pathsAdapter = createEntityAdapter<PathEntity>()

export const programAdapter = createEntityAdapter<ProgramEntity>()
export const coursesAdapter = createEntityAdapter<CourseEntity>()
export const linkedinCoursesAdapter = createEntityAdapter<LinkedInCourseEntity>()
export const linkCoursesAdapter = createEntityAdapter<LinkCourseEntity>()
export const scormCoursesAdapter = createEntityAdapter<ScormCourseEntity>()
