import { atom, PrimitiveAtom, useAtomValue } from 'jotai'
import React, { useContext, useEffect, useMemo } from 'react'
import { ApplicationNotifications } from 'sierra-client/components/common/notifications'
import { AppThemeTokenProvider } from 'sierra-client/config/token-provider'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { CreatePageYdocState, useCreatePageYdoc } from 'sierra-client/hooks/use-create-page-ydoc'
import { useRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { ViewAndCommentOnlyNotification } from 'sierra-client/hooks/use-view-and-comment-only-notification'
import { accessDeniedRedirect, contentNotFoundRedirect } from 'sierra-client/router/navigation'
import { FCC } from 'sierra-client/types'
import { CreateContentObserver } from 'sierra-client/views/flexible-content/create-content-observer'
import { CreateToastProvider } from 'sierra-client/views/flexible-content/create-page-toast'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { SyncCreateAwareness } from 'sierra-client/views/flexible-content/hooks'
import {
  NavigateToDefaultFile,
  useFallbackFileId,
} from 'sierra-client/views/flexible-content/navigate-to-default-file'
import { useEditorContextValueSafe } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { ChatIdentifier } from 'sierra-domain/api/chat'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ScopedChatId, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { createOperationState } from 'sierra-domain/editor/operations/operation'
import { CreateOperationState } from 'sierra-domain/editor/operations/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { assertIsNonNullable, isDefined } from 'sierra-domain/utils'

export type EditOrResponsesState = {
  type: 'responses' | 'edit'
  contentId: CreateContentId
  liveSessionId: LiveSessionId | undefined
}

type CreatePageContext = {
  createContentId: CreateContentId
  scopedCreateContentId: ScopedCreateContentId
  contentType: ReturnType<typeof ScopedCreateContentId.contentType>
  chatId: ScopedChatId
  chatIdentifier: ChatIdentifier
  operationState: CreateOperationState
  editOrResponsesStateAtom: PrimitiveAtom<EditOrResponsesState>
}

const CreatePageContext = React.createContext<CreatePageContext | undefined>(undefined)

type CreatePageNodeIdContext = { nodeId: FileId | undefined }
const NodeIdContext = React.createContext<CreatePageNodeIdContext | undefined>(undefined)

type CreatePageYDocContext = CreatePageYdocState
const YDocContext = React.createContext<CreatePageYdocState | undefined>(undefined)

const contextMissingError = 'Must be wrapped in a <CreatePageCombinedContext'

function assertContextExists<Context>(context: Context | undefined): Context {
  assertIsNonNullable(context, contextMissingError)
  return context
}

export function useCreatePageContextSafe(): CreatePageContext | undefined {
  return useContext(CreatePageContext)
}

export const useIsLiveCourseOrInLive = (): boolean => {
  const editorContext = useEditorContextValueSafe()
  return (
    useCreatePageContextSafe()?.contentType === 'live' ||
    (editorContext !== undefined && editorContext.mode === 'live')
  )
}

export function useCreatePageContext(): CreatePageContext {
  return assertContextExists(useCreatePageContextSafe())
}

export function useIsCreatePageContextLoading(): boolean {
  return !isDefined(useCreatePageContextSafe())
}

export function useCreatePageNodeIdContextSafe(): CreatePageNodeIdContext | undefined {
  return useContext(NodeIdContext)
}

export function useCreatePageNodeIdContext(): CreatePageNodeIdContext {
  return assertContextExists(useCreatePageNodeIdContextSafe())
}

export function useCreatePageYDocContextSafe(): CreatePageYDocContext | undefined {
  return useContext(YDocContext)
}

export function useCreatePageYDocContext(): CreatePageYDocContext {
  return assertContextExists(useCreatePageYDocContextSafe())
}

export function useSlashMenuCreatePageContext():
  | { createContentId: CreatePageContext['createContentId']; nodeId: CreatePageNodeIdContext['nodeId'] }
  | undefined {
  const createContentId = useCreatePageContextSafe()?.createContentId
  const nodeId = useContext(NodeIdContext)

  return useMemo(
    () => (isDefined(createContentId) && isDefined(nodeId) ? { ...nodeId, createContentId } : undefined),
    [createContentId, nodeId]
  )
}

export function useEditOrResponsesStateSafe(): EditOrResponsesState | undefined {
  const createPageContextAtom = useCreatePageContextSafe()?.editOrResponsesStateAtom

  const liveSessionIdAtom = useMemo(() => {
    if (createPageContextAtom === undefined) return atom(undefined)
    else return createPageContextAtom
  }, [createPageContextAtom])

  return useAtomValue(liveSessionIdAtom)
}

type ResolvedYDocContext = {
  scopedCreateContentId: ScopedCreateContentId
  context: CreatePageYDocContext | undefined
}

function ResolveYDocContext({
  scopedCreateContentId,
  onContextResolved,
}: {
  scopedCreateContentId: ScopedCreateContentId
  onContextResolved: (_: ResolvedYDocContext) => void
}): null {
  const yDocContext = useCreatePageYdoc(scopedCreateContentId)
  useEffect(() => {
    onContextResolved({ scopedCreateContentId, context: yDocContext })
    return () => {
      onContextResolved({ scopedCreateContentId, context: undefined })
    }
  }, [onContextResolved, scopedCreateContentId, yDocContext])

  return null
}

export const CreatePageCombinedContext: FCC = ({ children }) => {
  const ids = useRouterEditorIds()

  const { scopedContentId: scopedCreateContentId, scopedChatId: chatId } = ids ?? {}

  const chatIdentifier: ChatIdentifier | undefined = useMemo(
    () =>
      ids !== undefined
        ? {
            type: 'course',
            courseId: ids.contentId,
          }
        : undefined,
    [ids]
  )

  const [resolvedYDocContext, setResolvedYDocContext] = React.useState<ResolvedYDocContext | undefined>(
    undefined
  )

  const yDocContext =
    isDefined(resolvedYDocContext) && resolvedYDocContext.scopedCreateContentId === scopedCreateContentId
      ? resolvedYDocContext.context
      : undefined

  const routerNodeId = ids?.nodeId
  const fallbackFileId = useFallbackFileId(yDocContext?.yDoc, routerNodeId)
  const nodeId = routerNodeId ?? fallbackFileId

  const yDoc = yDocContext?.yDoc
  const operationState = useMemo(() => (isDefined(yDoc) ? createOperationState(yDoc) : undefined), [yDoc])

  const editOrResponsesStateAtom = useMemo(
    () =>
      scopedCreateContentId !== undefined
        ? atom<EditOrResponsesState>({
            type: 'edit',
            contentId: ScopedCreateContentId.extractId(scopedCreateContentId),
            liveSessionId: undefined,
          })
        : undefined,
    [scopedCreateContentId]
  )

  const createPageContext: CreatePageContext | undefined = useMemo(() => {
    if (
      !isDefined(scopedCreateContentId) ||
      !isDefined(chatId) ||
      !isDefined(operationState) ||
      !isDefined(chatIdentifier) ||
      !isDefined(editOrResponsesStateAtom)
    )
      return undefined

    const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)
    const createContentId = ScopedCreateContentId.extractId(scopedCreateContentId)

    return {
      contentType,
      createContentId,
      operationState,
      chatId,
      chatIdentifier,
      scopedCreateContentId,
      editOrResponsesStateAtom,
    }
  }, [scopedCreateContentId, chatId, operationState, chatIdentifier, editOrResponsesStateAtom])

  const createPageNodeIdContext = useMemo(() => ({ nodeId }), [nodeId])

  return (
    <AppThemeTokenProvider>
      <FocusEditorContext>
        <CreateToastProvider>
          <CreatePageContext.Provider value={createPageContext}>
            <NodeIdContext.Provider value={createPageNodeIdContext}>
              <YDocContext.Provider value={yDocContext}>
                {isDefined(ids) && (
                  <RequireCoursePermission
                    contentId={ids.contentId}
                    onAccessDenied={accessDeniedRedirect}
                    onContentNotFound={contentNotFoundRedirect}
                  >
                    <ResolveYDocContext
                      // Make sure this context is remounted when the scopedCreateContentId changes
                      // to prevent race conditions when creating the yDoc context
                      key={scopedCreateContentId}
                      scopedCreateContentId={ids.scopedContentId}
                      onContextResolved={setResolvedYDocContext}
                    />
                  </RequireCoursePermission>
                )}

                {isDefined(yDocContext) && isDefined(ids) && (
                  <>
                    <SyncCreateAwareness
                      yDoc={yDocContext.yDoc}
                      awareness={yDocContext.awareness}
                      nodeId={nodeId}
                      scopedCreateContentId={ids.scopedContentId}
                    />
                    <ApplicationNotifications />
                    <CreateContentObserver
                      jsonDataYMap={yDocContext.jsonDataYMap}
                      createContentId={ids.contentId}
                    />
                    <ViewAndCommentOnlyNotification permission={yDocContext.permission} />
                    <NavigateToDefaultFile
                      yDoc={yDocContext.yDoc}
                      scopedCreateContentId={ids.scopedContentId}
                      fallbackFileId={fallbackFileId}
                      routerNodeId={routerNodeId}
                    />
                  </>
                )}

                {children}
              </YDocContext.Provider>
            </NodeIdContext.Provider>
          </CreatePageContext.Provider>
        </CreateToastProvider>
      </FocusEditorContext>
    </AppThemeTokenProvider>
  )
}
