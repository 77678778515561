import { EnhancedStore } from '@reduxjs/toolkit'
import storage from 'local-storage-fallback'
import log from 'loglevel'
import { RootState } from 'sierra-client/state/types'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { User } from 'sierra-domain/user'
import { validate } from 'sierra-domain/zod-extensions/validate'
import { v4 as uuid } from 'uuid'
import { z } from 'zod'

const Logging = z.object({
  anonymousId: z.string(),
})

type Logging = z.infer<typeof Logging>

export const getLoggingFromStorage = (): Logging | undefined => {
  const logging = storage.getItem('logging')

  if (logging !== null) {
    try {
      return validate(Logging)(JSON.parse(logging))
    } catch (e) {
      log.warn(e)
    }
  }
}

export const setLoggingToStorage = (logging: Logging): void => {
  try {
    storage.setItem('logging', JSON.stringify(logging))
  } catch (error) {
    log.warn(error)
  }
}

export const createLogging = (): Logging => {
  const logging = getLoggingFromStorage()

  if (logging) {
    return logging
  } else {
    const newLogging: Logging = { anonymousId: uuid() }
    setLoggingToStorage(newLogging)
    return newLogging
  }
}

export class UserSingleton {
  private static instance: UserSingleton | undefined

  constructor(
    private store: EnhancedStore<RootState>,
    private logging: Logging
  ) {}

  getUser = (): User | undefined => selectUser(this.store.getState())

  getAnonymousId = (): string => this.logging.anonymousId

  static initialize(store: EnhancedStore<RootState>, logging: Logging): void {
    UserSingleton.instance = new UserSingleton(store, logging)
  }

  static getInstance(): UserSingleton {
    if (UserSingleton.instance !== undefined) {
      return UserSingleton.instance
    } else {
      throw Error('Instance not initialized')
    }
  }
}
