import latinize from 'latinize'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Virtuoso } from 'react-virtuoso'
import { useAssignUsers } from 'sierra-client/components/common/modals/multi-assign-modal/hooks'
import { ItemList, ItemRow } from 'sierra-client/components/common/modals/multi-assign-modal/items'
import {
  CourseItem,
  ItemMap,
  ItemType,
  LiveSessionItem,
  PathItem,
  ProgramItem,
  SubjectType,
  UserGroupItem,
} from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { usePost } from 'sierra-client/hooks/use-post'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { InfiniteScrollMessage } from 'sierra-client/views/manage/components/common'
import { useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { useLiveSessions } from 'sierra-client/views/manage/live-session/use-live-sessions'
import { usePaths } from 'sierra-client/views/manage/paths/use-paths'
import { CourseId } from 'sierra-domain/api/nano-id'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { XRealtimeAdminGroupsListGroups, XRealtimeAdminGroupsListUserGroups } from 'sierra-domain/routes'
import styled from 'styled-components'

const VirtualList = styled(Virtuoso)`
  height: 100%;
  overflow-y: hidden !important;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto !important;
  }
`
const Padded = styled.div`
  padding: 0 1.5rem;
`

function filterBySearch<Item>(availableItems: Item[], query: string, accessor: (i: Item) => string): Item[] {
  const normalizeString = _.memoize((input: string) => _.split(latinize(input).toLowerCase(), ' '))
  const normalizedQuery = normalizeString(query)
  return availableItems.filter(item => {
    return normalizedQuery.every(term => {
      return normalizeString(accessor(item)).some(token => token.startsWith(term))
    })
  })
}

function Loader(): JSX.Element {
  const { t } = useTranslation()

  return <InfiniteScrollMessage text={t('dictionary.loading')} showSanaLogo />
}

export type ListProps<T extends ItemType> = {
  subjectType: SubjectType
  subjects: string[]
  selectedIds: Set<string>
  searchQuery: string
  assignedIds: string[]
  handleToggle: (item: ItemMap[T]) => void
}
type ListWrapperProps<T extends ItemType> = Pick<ListProps<T>, 'selectedIds' | 'handleToggle'> & {
  items: ItemMap[T][]
  isLoading: boolean
  noResultTranslationKey: TranslationKey
}

const ListWrapper = <T extends ItemType>({
  items,
  selectedIds,
  handleToggle,
  isLoading,
  noResultTranslationKey,
}: ListWrapperProps<T>): JSX.Element => {
  const { t } = useTranslation()
  if (isLoading) return <Loader />

  return (
    <VirtualList
      totalCount={items.length}
      itemContent={index => {
        const item = items[index]
        if (!item) return null
        return (
          <Padded>
            <ItemRow
              // className={style.className}
              isSelected={selectedIds.has(item.id)}
              item={item}
              onClick={() => handleToggle(item)}
            />
          </Padded>
        )
      }}
      components={{
        EmptyPlaceholder: () => <InfiniteScrollMessage text={t(noResultTranslationKey)} />,
      }}
    />
  )
}

export const CourseList: React.FC<ListProps<'course'>> = ({
  selectedIds,
  assignedIds,
  handleToggle,
  searchQuery,
}) => {
  const { courses, isLoading } = useManageCourses()

  const sortedCourses = useMemo(() => {
    const courseTitles = _.fromPairs(courses.map(x => [x.courseId, x.title]))

    return _.orderBy(courses, [
      // Sort courses based on their titles. Sort editions next to their parent course group.
      course =>
        course.courseGroupId !== undefined ? courseTitles[course.courseGroupId] ?? '' : course.title,
      // Sort by course group ids so that course groups are sorted together with their editions.
      // This should handle the case where titles aren't unique.
      course => course.courseGroupId ?? course.courseId,
      // Put course groups before their editions.
      course => (course.isCourseEdition ? 1 : 0),
      // Sort editions by their title.
      course => course.title,
    ])
  }, [courses])

  const assetResolver = useAssetResolver({ size: 'course' })

  const courseItems: CourseItem[] = useMemo(() => {
    // Filters using search
    const filteredSearch = filterBySearch(sortedCourses, searchQuery, course => course.title)

    const mappedCourses: CourseItem[] = filteredSearch.map(course => ({
      type: 'course',
      id: course.courseId,
      title: course.title,
      image: assetResolver(course.image, { type: 'course', courseId: CourseId.parse(course.courseId) }),
      kind: course.kind,
      disabled: Boolean(assignedIds.includes(course.courseId)),
      isCourseEdition: course.isCourseEdition,
      isDefaultRequiredAssignment: course.isDefaultRequiredAssignment,
    }))

    return mappedCourses
  }, [sortedCourses, searchQuery, assignedIds, assetResolver])

  return (
    <ListWrapper<'course'>
      items={courseItems}
      handleToggle={handleToggle}
      selectedIds={selectedIds}
      isLoading={isLoading}
      noResultTranslationKey='manage.courses.no-results'
    />
  )
}

export const PathList: React.FC<ListProps<'path'>> = ({
  assignedIds,
  selectedIds,
  handleToggle,
  searchQuery,
}) => {
  const { paths, isLoading } = usePaths()

  // Sorts once so it doesn't have to sort many times
  const sortedPaths = useMemo(() => {
    return paths.sort((path1, path2) =>
      path1.data.title < path2.data.title ? -1 : path1.data.title > path2.data.title ? 1 : 0
    )
  }, [paths])

  const pathItems: PathItem[] = useMemo(() => {
    const filteredPaths = filterBySearch(sortedPaths, searchQuery, path => path.data.title)

    const mappedPaths: PathItem[] = filteredPaths.map(path => ({
      type: 'path',
      id: path.pathId,
      title: path.data.title,
      images: path.courses.map(course => {
        return {
          assetContext: { type: 'course', courseId: CourseId.parse(course.courseId) },
          image: course.image,
        }
      }),
      disabled: Boolean(assignedIds.includes(path.pathId)),
      isDefaultRequiredAssignment: path.isDefaultRequiredAssignment,
    }))
    return mappedPaths
  }, [sortedPaths, assignedIds, searchQuery])

  return (
    <ListWrapper<'path'>
      items={pathItems}
      handleToggle={handleToggle}
      selectedIds={selectedIds}
      isLoading={isLoading}
      noResultTranslationKey='manage.paths.no-results'
    />
  )
}

export const UserGroupList: React.FC<ListProps<'user-group'>> = ({
  subjectType,
  subjects,
  selectedIds,
  handleToggle,
  searchQuery,
}) => {
  const [fetching, setFetching] = useState(true)
  const [items, setItems] = useState<UserGroupItem[]>([])
  const { postWithUserErrorException } = usePost()

  const fetchSubjectAssignments = useCallback(async (): Promise<{ groupId: string }[][]> => {
    const { data: groups } = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
      type: 'user',
      groupIds: undefined,
    })

    // groups for each user
    if (subjectType === 'user') {
      const userToGroups = _.chain(groups)
        .flatMap(group => group.learnerIds.map(userId => ({ groupId: group.groupInfo.groupId, userId })))
        .groupBy(it => it.userId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(userId => userToGroups[userId] ?? [])
    }

    if (subjectType === 'course') {
      const courseToGroups = _.chain(groups)
        .flatMap(group => group.courseIds.map(courseId => ({ groupId: group.groupInfo.groupId, courseId })))
        .groupBy(it => it.courseId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(courseId => courseToGroups[courseId] ?? [])
    }

    if (subjectType === 'path') {
      const pathToGroups = _.chain(groups)
        .flatMap(group => group.pathIds.map(pathId => ({ groupId: group.groupInfo.groupId, pathId })))
        .groupBy(it => it.pathId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(pathId => pathToGroups[pathId] ?? [])
    }

    return []
  }, [postWithUserErrorException, subjectType, subjects])

  const fetchGroups = useCallback(async (): Promise<void> => {
    setFetching(true)

    const { data: availableGroups } = await postWithUserErrorException(XRealtimeAdminGroupsListUserGroups, {})

    const filteredGroups = filterBySearch(availableGroups, searchQuery, group => group.name ?? '')
    const subjectAssignments = await fetchSubjectAssignments()
    const commonAssignments = _.intersectionBy(...subjectAssignments, assignment => assignment.groupId)

    const mappedGroups: UserGroupItem[] = filteredGroups.map(group => ({
      type: 'user-group',
      id: group.id,
      title: group.name ?? 'N/A',
      disabled: Boolean(commonAssignments.find(assignment => assignment.groupId === group.id)),
      userCount: group.numLearners,
    }))

    setFetching(false)
    setItems(mappedGroups)
  }, [postWithUserErrorException, fetchSubjectAssignments, setItems, searchQuery])

  useEffect(() => {
    void fetchGroups()
  }, [fetchGroups])

  return (
    <ListWrapper<'user-group'>
      items={items}
      handleToggle={handleToggle}
      selectedIds={selectedIds}
      isLoading={fetching}
      noResultTranslationKey='manage.groups.no-results'
    />
  )
}

export const ProgramList: React.FC<ListProps<'program'>> = ({
  subjectType,
  subjects,
  selectedIds,
  handleToggle,
  searchQuery,
}) => {
  const [fetching, setFetching] = useState(true)
  const [items, setItems] = useState<ProgramItem[]>([])
  const { postWithUserErrorException } = usePost()

  const fetchSubjectAssignments = useCallback(async (): Promise<{ groupId: string }[][]> => {
    const { data: groups } = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
      type: 'program',
      groupIds: undefined,
    })

    // groups for each user
    if (subjectType === 'user') {
      const userToGroups = _.chain(groups)
        .flatMap(group => group.learnerIds.map(userId => ({ groupId: group.groupInfo.groupId, userId })))
        .groupBy(it => it.userId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(userId => userToGroups[userId] ?? [])
    }

    if (subjectType === 'course') {
      const courseToGroups = _.chain(groups)
        .flatMap(group => group.courseIds.map(courseId => ({ groupId: group.groupInfo.groupId, courseId })))
        .groupBy(it => it.courseId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(courseId => courseToGroups[courseId] ?? [])
    }

    if (subjectType === 'path') {
      const pathToGroups = _.chain(groups)
        .flatMap(group => group.pathIds.map(pathId => ({ groupId: group.groupInfo.groupId, pathId })))
        .groupBy(it => it.pathId)
        .mapValues(assignments => assignments.map(({ groupId }) => ({ groupId })))
        .value()

      return subjects.map(pathId => pathToGroups[pathId] ?? [])
    }

    return []
  }, [postWithUserErrorException, subjectType, subjects])

  const fetchGroups = useCallback(async (): Promise<void> => {
    setFetching(true)

    const { data: availableGroups } = await postWithUserErrorException(XRealtimeAdminGroupsListGroups, {
      type: 'program',
      groupIds: undefined,
    })

    const filteredGroups = filterBySearch(
      availableGroups,
      searchQuery,
      group => group.groupInfo.groupName ?? ''
    )
    const subjectAssignments = await fetchSubjectAssignments()
    const commonAssignments = _.intersectionBy(...subjectAssignments, assignment => assignment.groupId)

    const mappedPrograms: ProgramItem[] = filteredGroups.map(group => ({
      type: 'program',
      id: group.groupInfo.groupId,
      title: group.groupInfo.groupName ?? 'N/A',
      disabled: Boolean(commonAssignments.find(assignment => assignment.groupId === group.groupInfo.groupId)),
      userCount: group.learnerIds.length,
    }))

    setFetching(false)
    setItems(mappedPrograms)
  }, [postWithUserErrorException, fetchSubjectAssignments, setItems, searchQuery])

  useEffect(() => {
    void fetchGroups()
  }, [fetchGroups])

  return (
    <ListWrapper<'program'>
      items={items}
      handleToggle={handleToggle}
      selectedIds={selectedIds}
      isLoading={fetching}
      noResultTranslationKey='manage.groups.no-results'
    />
  )
}

export const UserList: React.FC<ListProps<'user'>> = ({
  subjectType,
  subjects,
  selectedIds,
  handleToggle,
  searchQuery,
}) => {
  const { t } = useTranslation()

  const [inViewRef, shouldLoadMore] = useInView({ threshold: 0 })

  const { hasMore, isLoading, listItems, availableUsers, setFilter } = useAssignUsers({
    subjectType,
    subjects,
    searchQuery,
  })

  useEffect(() => {
    if (!isLoading && hasMore && shouldLoadMore) {
      const lastUser = availableUsers[availableUsers.length - 1]

      if (lastUser) {
        setFilter(filter => ({ ...filter, lastUserId: lastUser.userInfo.userId }))
      }
    }
  }, [availableUsers, hasMore, isLoading, setFilter, shouldLoadMore])

  return (
    <ItemList ref={inViewRef}>
      {listItems.map(item => (
        <Padded key={item.id}>
          <ItemRow isSelected={selectedIds.has(item.id)} item={item} onClick={() => handleToggle(item)} />
        </Padded>
      ))}
      {hasMore || isLoading ? (
        <InfiniteScrollMessage
          ref={inViewRef}
          text={t('manage.users.table-loading')}
          showSanaLogo
          padding='small'
        />
      ) : (
        <InfiniteScrollMessage
          text={availableUsers.length > 0 ? t('manage.users.table-end') : t('manage.users.no-results')}
          padding='small'
        />
      )}
    </ItemList>
  )
}

export const LiveSessionList: React.FC<ListProps<'live-session'>> = ({
  assignedIds,
  selectedIds,
  handleToggle,
  searchQuery,
}) => {
  const { isLoading, liveSessions } = useLiveSessions()
  const liveSessionItems: LiveSessionItem[] = useMemo(() => {
    // Filters using search
    const filteredSearch = filterBySearch(liveSessions, searchQuery, ls => ls.liveSession.data.title)

    const mapped: LiveSessionItem[] = filteredSearch.map(ls => {
      const { liveSession } = ls
      const { data } = liveSession

      const assignmentsText = _.compact([ls.assignmentsCount, data.maxNumberOfUsers]).join(' / ')
      const isFull =
        data.maxNumberOfUsers === undefined ? false : ls.assignmentsCount >= data.maxNumberOfUsers
      const isAssigned = assignedIds.includes(liveSession.liveSessionId)
      const disabled = isAssigned || isFull
      const locationValue = data.location?.type === 'physical' ? data.location.value : undefined
      const disabledReason: LiveSessionItem['disabledReason'] = isAssigned
        ? 'assigned'
        : isFull
          ? 'full'
          : undefined

      return {
        type: 'live-session',
        id: liveSession.liveSessionId,
        title: data.title,
        disabledReason,
        disabled,
        startTime: scheduledOrNull(data)?.startTime,
        endTime: scheduledOrNull(data)?.endTime,
        locationValue,
        assignmentsText,
      }
    })
    return mapped
  }, [liveSessions, searchQuery, assignedIds])

  return (
    <ListWrapper<'live-session'>
      items={liveSessionItems}
      handleToggle={handleToggle}
      selectedIds={selectedIds}
      isLoading={isLoading}
      noResultTranslationKey='manage.sessions.no-results'
    />
  )
}
