import React from 'react'
import { HeaderButtonGroupWrapper, HeaderGroupButton } from 'sierra-client/components/liveV2/header-buttons'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSlateDocument } from 'sierra-client/state/flexible-content/use-slate-document'
import {
  FileBackgroundPicker,
  getFileImage,
} from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import {
  useCreatePageContext,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { TextAlignmentPicker } from 'sierra-client/views/flexible-content/editor/toolbar/text-alignment-picker'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { WaitForEditor } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-polaris-editor'
import { Debug } from 'sierra-client/views/learner/components/debug'
import {
  useDefaultImageFitOption,
  useImageFitOptions,
} from 'sierra-client/views/v3-author/bullet-card/image-fit-options'
import { updateNodeWithId } from 'sierra-client/views/v3-author/command'
import { isElementType } from 'sierra-client/views/v3-author/queries'
import type { AssetContext } from 'sierra-domain/asset-context'
import { apply } from 'sierra-domain/editor/operations/operation'
import { Entity } from 'sierra-domain/entity'
import { ImageFit } from 'sierra-domain/flexible-content/image-fit'
import { File } from 'sierra-domain/flexible-content/types'
import { TitleCard } from 'sierra-domain/v3-author'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import { Editor } from 'slate'

function useTitleCard(fileId: File['id']): Omit<Entity<TitleCard>, 'children'> | undefined {
  const { yDoc } = useCreatePageYDocContext()
  const document = useSlateDocument(yDoc, fileId)
  const titleCard = document.find(isElementType('title-card'))

  if (titleCard === undefined) return undefined

  const { children, ...rest } = titleCard
  return rest
}

type TitleCardToolbarProps = {
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  assetContext: AssetContext
}

const TitleCardToolbarInner: React.FC<TitleCardToolbarProps & { editor: Editor }> = ({
  file,
  previewThemes,
  editor,
  assetContext,
}) => {
  const { dynamicT } = useTranslation()
  const { operationState } = useCreatePageContext()
  const imageFitOptions = useImageFitOptions()
  const defaultImageFitOption = useDefaultImageFitOption()
  const titleCard = useTitleCard(file.id)
  if (titleCard === undefined) return <Debug>No title card found</Debug>

  const data = file.data

  const imageFitOption =
    (data.type === 'slate-card' ? imageFitOptions.find(option => option.id === data.imageFit) : undefined) ??
    defaultImageFitOption

  const updateTextAlignment = (variant: TitleCard['variant']): void => {
    updateNodeWithId<Entity<TitleCard>>(editor, titleCard.id, { variant })
  }

  const image = getFileImage(file)
  const currentTextAlignment = titleCard.variant ?? ('left-aligned' as const)

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />

      {image !== undefined && (
        <SingleSelectDropdown
          selectedItem={defaultImageFitOption}
          menuItems={imageFitOptions}
          onSelect={option => {
            const imageFit = ImageFit.parse(option.id)

            apply(operationState, {
              type: 'update-files',
              fileIds: [file.id],
              update: file => {
                if (file.data.type !== 'slate-card') return null
                file.data.imageFit = imageFit
              },
            })
          }}
          renderTrigger={open => (
            <HeaderGroupButton>
              <View gap='2' alignItems='center'>
                <Text color='currentColor' bold>
                  {dynamicT(imageFitOption.label ?? '')}
                </Text>
                <Icon color='grey40' iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />
              </View>
            </HeaderGroupButton>
          )}
        />
      )}

      <FileBackgroundPicker file={file} assetContext={assetContext} />
      <TextAlignmentPicker onUpdateAlignment={updateTextAlignment} currentAlignment={currentTextAlignment} />
    </HeaderButtonGroupWrapper>
  )
}

export const TitleCardToolbar: React.FC<TitleCardToolbarProps> = props => (
  <WaitForEditor>{editor => <TitleCardToolbarInner editor={editor} {...props} />}</WaitForEditor>
)
