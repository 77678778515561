import { useCallback, useEffect, useMemo, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { PathCollaborator, PathWithExtra } from 'sierra-domain/api/admin'
import { PathId } from 'sierra-domain/api/nano-id'
import {
  XRealtimeAdminPathsListPathCollaborators,
  XRealtimeAdminPathsListPaths,
  XRealtimeAdminPathsListPathsLatest,
  XRealtimeAdminPathsListPotentialPathCollaborators,
  XRealtimeAdminPathsUpdatePathCollaborators,
} from 'sierra-domain/routes'

export type UsePathHook = {
  paths: Array<PathWithExtra>
  isLoading: boolean
  fetchCsvData: () => Record<string, unknown>[]
  updatePaths: () => Promise<void>
}

const mapPathToCsv = (path: PathWithExtra): Record<string, unknown> => {
  return {
    title: path.data.title,
    description: path.data.description,
    coursesCount: path.data.content.length,
    skillsCount: path.tags.length,
  }
}

export const usePaths = (latest = false): UsePathHook => {
  const { postWithUserErrorException } = usePost()

  const [isLoading, setIsLoading] = useState(false)
  const [paths, setPaths] = useState<Array<PathWithExtra>>([])

  const fetchCsvData = useCallback(() => {
    return paths.map(mapPathToCsv)
  }, [paths])

  const updatePaths = useCallback(async (): Promise<void> => {
    setIsLoading(true)
    const response = latest
      ? await postWithUserErrorException(XRealtimeAdminPathsListPathsLatest, {})
      : await postWithUserErrorException(XRealtimeAdminPathsListPaths, {})
    setPaths(response.paths)
    setIsLoading(false)
  }, [postWithUserErrorException, latest])

  useEffect(() => {
    void updatePaths()
  }, [updatePaths])

  return { paths, isLoading, fetchCsvData, updatePaths }
}

export type UsePathCollaborators = {
  collaborators: PathCollaborator[]
  fetchCollaborators: (pathId: PathId) => Promise<void>
  updateCollaborators: (params: { pathId: PathId; add: string[]; remove: string[] }) => Promise<void>
}
export const usePathCollaborators = (): UsePathCollaborators => {
  const { postWithUserErrorException } = usePost()
  const [collaborators, setCollaborators] = useState<PathCollaborator[]>([])

  // TODO: Should this be a part of the path-detail endpoint response?
  //  Argument against: we may want to update it separately when it changes in the settings modal
  const fetchCollaborators = useCallback<UsePathCollaborators['fetchCollaborators']>(
    async pathId => {
      const response = await postWithUserErrorException(XRealtimeAdminPathsListPathCollaborators, {
        pathId,
      })

      setCollaborators(response.collaborators)
    },
    [postWithUserErrorException]
  )

  const updateCollaborators = useCallback<UsePathCollaborators['updateCollaborators']>(
    async ({ pathId, add, remove }) => {
      await postWithUserErrorException(XRealtimeAdminPathsUpdatePathCollaborators, {
        pathId,
        add,
        remove,
      })

      // Re-fetch collaborators on update
      //  (maybe the update-path-collaborators should always return the updated list?)
      await fetchCollaborators(pathId)
    },
    [postWithUserErrorException, fetchCollaborators]
  )

  return useMemo(
    () => ({
      collaborators,
      fetchCollaborators,
      updateCollaborators,
    }),
    [collaborators, fetchCollaborators, updateCollaborators]
  )
}

type UsePotentialPathCollaborators = {
  isLoading: boolean
  potentialCollaborators: PathCollaborator[]
  fetchPotentialCollaborators: (pathId: PathId) => Promise<void>
}
export const usePotentialPathCollaborators = (): UsePotentialPathCollaborators => {
  const { postWithUserErrorException } = usePost()
  const [isLoading, setIsLoading] = useState(false)
  const [potentialCollaborators, setPotentialCollaborators] = useState<PathCollaborator[]>([])

  // TODO: This will be paginated in the future.
  const fetchPotentialCollaborators = useCallback<UsePathCollaborators['fetchCollaborators']>(
    async pathId => {
      setIsLoading(true)

      try {
        const response = await postWithUserErrorException(XRealtimeAdminPathsListPotentialPathCollaborators, {
          pathId,
        })

        setPotentialCollaborators(response.collaborators)
      } finally {
        setIsLoading(false)
      }
    },
    [postWithUserErrorException]
  )

  return useMemo(
    () => ({
      isLoading,
      potentialCollaborators,
      fetchPotentialCollaborators,
    }),
    [isLoading, potentialCollaborators, fetchPotentialCollaborators]
  )
}
