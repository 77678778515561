import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { FileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { FileTree } from 'sierra-client/views/flexible-content/render-file-tree'
import { AssetContext } from 'sierra-domain/asset-context'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File, FlexibleContentJsonData, Folder } from 'sierra-domain/flexible-content/types'
import { Icon, TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const FileView = styled(View)`
  border-radius: ${p => p.theme.borderRadius.regular};
`

const FileIconWrapper = styled.div`
  flex-shrink: 0;
`

const RenderFile: React.FC<{
  file: File
  fileId: FileId
  goToFile: (fileId: FileId) => void
  slateDocumentMap: SlateDocumentMap
  assetContext: AssetContext
}> = ({ file, fileId, goToFile, slateDocumentMap, assetContext }) => {
  const ref = useRef<HTMLDivElement>(null)
  const isCurrentFile = fileId === file.id

  useEffect(() => {
    if (isCurrentFile) ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [isCurrentFile])

  const getFileTitle = useGetFileTitle(slateDocumentMap)

  return (
    <FileView
      ref={ref}
      padding='4'
      background={isCurrentFile ? 'blueSelected' : null}
      margin='2 none'
      onClick={() => goToFile(file.id)}
      cursor={'pointer'}
    >
      <FileIconWrapper>
        <FileIcon file={file} assetContext={assetContext} />
      </FileIconWrapper>
      <View grow overflow='hidden'>
        <TruncatedText lines={1} size='small'>
          {getFileTitle(file)}
        </TruncatedText>
      </View>
    </FileView>
  )
}

const RenderFolder: React.FC<React.PropsWithChildren<{ folder: Folder }>> = ({ children, folder }) => {
  const [open, { toggle: toggleOpen }] = useToggle(true)

  return (
    <View direction='column' gap='xxsmall' paddingTop='xxsmall'>
      <View cursor='pointer' onClick={toggleOpen}>
        <View alignItems='center' animated animate={{ rotate: open ? 0 : -90 }}>
          <Icon iconId='chevron--down' size='size-16' color='grey70' />
        </View>

        <TruncatedText lines={1} bold size='small'>
          {folder.title}
        </TruncatedText>
      </View>
      <AnimatePresence>
        {open && (
          <View
            direction='column'
            paddingBottom='xxsmall'
            paddingLeft='xsmall'
            gap='none'
            animated
            initial={{ opacity: 0, y: -8 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -8 }}
            transition={{ type: 'tween', duration: 150 / 1000 }}
          >
            {children}
          </View>
        )}
      </AnimatePresence>
    </View>
  )
}

export const FileExplorer: React.FC<{
  flexibleContent: FlexibleContentJsonData
  fileId: FileId
  goToFile: (fileId: FileId) => void
  slateDocumentMap: SlateDocumentMap
  assetContext: AssetContext
}> = ({ flexibleContent, fileId, goToFile, slateDocumentMap, assetContext }) => {
  return (
    <FileTree
      content={flexibleContent}
      rootAsFolder={false}
      renderFile={file => (
        <RenderFile
          slateDocumentMap={slateDocumentMap}
          file={file}
          fileId={fileId}
          goToFile={goToFile}
          assetContext={assetContext}
        />
      )}
      renderFolder={(folder, children) => <RenderFolder folder={folder}>{children}</RenderFolder>}
    />
  )
}
