import { useAtom } from 'jotai'
import React, { useEffect, useRef } from 'react'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { stringsColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { useTableAPI, UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { getAttendanceTranslationKey } from 'sierra-client/views/manage/live-session/live-session-utils'
import { liveSessionUsersQueryKey } from 'sierra-client/views/manage/live-session/util/query-keys'
import { userBaseToCsv } from 'sierra-client/views/manage/users/user-utils'
import { LiveSessionListEnrolledUsersResponse, LiveSessionUserInfo } from 'sierra-domain/api/admin'
import { LiveSessionAttendanceStatus } from 'sierra-domain/api/manage'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminLiveSessionsListEnrolledUsers } from 'sierra-domain/routes'
import { ItemOf } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { Button, scrollViewStyles, Spacer, Text, View } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

type LiveSessionAttendeesData = ItemOf<LiveSessionListEnrolledUsersResponse['data']>

type LiveSessionAttendeesTableDefinition = TableDefinitionOf<
  LiveSessionAttendeesData,
  [
    { type: 'users'; ref: 'user' },
    { type: 'strings'; ref: 'attendance' },
    { type: 'strings'; ref: 'assignedAt' },
  ]
>

type LiveSessionAssignmentTableData = TableDataFromDefinition<
  LiveSessionAttendeesData,
  LiveSessionAttendeesTableDefinition
>

const tableDefinition = (t: TranslationLookup): LiveSessionAttendeesTableDefinition => ({
  columns: [
    usersColumn({
      getData: r => ({
        email: r.userInfo.email,
        status: r.userInfo.status,
        id: r.userInfo.userId,
        avatar: getAvatarImage(r.userInfo.userId, r.userInfo.avatar),
        avatarColor: r.userInfo.avatarColor,
        firstName: r.userInfo.firstName,
        lastName: r.userInfo.lastName,
        isRequiredAssignment: undefined,
      }),
      header: translatedLabel('table.name'),
      ref: 'user',
    }),
    stringsColumn({
      getData: r => t(getAttendanceTranslationKey(r.attendance)),
      ref: 'attendance',
      header: translatedLabel('table.attendance'),
    }),
    stringsColumn({
      getData: r => (r.assignedAt !== undefined ? new Date(r.assignedAt).toDateString() : ''),
      ref: 'assignedAt',
      header: translatedLabel('admin.groups.assigned-at'),
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.userInfo.userId,
  },
})

const getAttendanceMenuItems = (
  t: TranslationLookup,
  onSetAttendance: (attendance: LiveSessionAttendanceStatus) => Promise<void>
): MenuItem[] => [
  {
    type: 'label',
    label: t(getAttendanceTranslationKey('present')),
    id: 'set-attendance--present',
    icon: 'checkmark--outline',
    onClick: () => {
      void onSetAttendance('present')
    },
  },
  {
    type: 'label',
    label: t(getAttendanceTranslationKey('absent')),
    id: 'set-attendance--absent',
    icon: 'close--circle',
    onClick: () => {
      void onSetAttendance('absent')
    },
  },
]

type LiveSessionAssignmentTableMeta = {
  lastUserId?: UserId
}

const useLiveSessionUsersTable = ({
  contentId,
  onSetAttendance,
  assignmentCount,
  canMarkAttendance,
  facilitatorIds,
  onRemoveUsers,
}: Pick<
  LiveSessionUsersTableProps,
  | 'contentId'
  | 'onSetAttendance'
  | 'assignmentCount'
  | 'canMarkAttendance'
  | 'facilitatorIds'
  | 'onRemoveUsers'
>): UseTableAPI<LiveSessionAssignmentTableData, LiveSessionAssignmentTableMeta> => {
  const { t } = useTranslation()

  const loader = createDataLoader<
    LiveSessionAttendeesData,
    LiveSessionAssignmentTableMeta,
    LiveSessionAssignmentTableData
  >({
    fetchInit: async ({ control, predicate }) => {
      const { data, hasMore } = await typedPost(XRealtimeAdminLiveSessionsListEnrolledUsers, {
        liveSessionId: contentId,
        commonFilters: {
          query: predicate.query,
          maxResults: control.limit,
        },
      })

      return {
        data,
        meta: {
          lastUserId: data[data.length - 1]?.userInfo.userId,
          hasMore,
        },
        done: !hasMore,
        totalCount: assignmentCount,
      }
    },
    fetchMore: async ({ meta, predicate }) => {
      const { data, hasMore } = await typedPost(XRealtimeAdminLiveSessionsListEnrolledUsers, {
        liveSessionId: contentId,
        commonFilters: {
          query: predicate.query,
          lastUserId: meta.lastUserId,
          maxResults: 30,
        },
      })
      return {
        data,
        meta: {
          lastUserId: data[data.length - 1]?.userInfo.userId,
        },
        done: !hasMore,
        totalCount: assignmentCount,
      }
    },
    transformResults(data) {
      return [definition2Data(tableDefinition(t), data)]
    },
  })

  return useTableAPI({
    dataLoader: { loader, options: { queryKey: liveSessionUsersQueryKey(contentId) } },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const userId = row.rawData.userInfo.userId
            const isAdministered = row.rawData.isAdministered
            const isAssigned = row.rawData.assignedAt !== undefined
            const unAssignable = isAssigned && isAdministered && !facilitatorIds.includes(userId)

            const hasMenuActions = isAdministered || canMarkAttendance || unAssignable
            if (!hasMenuActions) return 'no-actions'
            return {
              menuItems: [
                {
                  type: 'label',
                  sortable: true,
                  label: t('manage.view-details'),
                  id: 'view-details',
                  icon: 'user',
                  hidden: !isAdministered,
                  onClick: () =>
                    getGlobalRouter().navigate({
                      to: `/manage/users/${userId}`,
                    }),
                },
                {
                  type: 'nested',
                  label: t('table.attendance'),
                  id: 'set-attendance',
                  icon: 'checkmark--outline',
                  hidden: !canMarkAttendance,
                  disabled: row.rawData.attendance === 'present',
                  menuItems: getAttendanceMenuItems(t, attendance => onSetAttendance([userId], attendance)),
                },
                {
                  type: 'label',
                  label: t('dictionary.unassign'),
                  id: 'unassign',
                  icon: 'user--remove',
                  hidden: !unAssignable,
                  onClick: () => {
                    onRemoveUsers([userId])
                  },
                },
              ],
            }
          },
        }),
      ],
    },
    options: {
      limit: 30,
    },
  })
}
const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 1rem;
  max-height: 27rem;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

const userToCsv = (userAssignment: LiveSessionUserInfo): Record<string, string> => ({
  ...userBaseToCsv(userAssignment.userInfo),
  attendance: userAssignment.attendance,
  assignedAt: userAssignment.assignedAt ?? '',
})

const Search: React.FC<{
  tableAPI: UseTableAPI<LiveSessionAssignmentTableData, LiveSessionAssignmentTableMeta>
}> = ({ tableAPI }) => {
  const { t } = useTranslation()
  const [debouncedQuery, liveQuery, setLiveQuery] = useDebouncedAndLiveState(tableAPI.api.query.query().query)

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: debouncedQuery ?? '' })
  }, [debouncedQuery, tableAPI.api.action])

  return <RoundedSearchBar placeholder={t('manage.search.users')} value={liveQuery} onChange={setLiveQuery} />
}

type ToolbarProps = {
  tableAPI: UseTableAPI<LiveSessionAssignmentTableData, LiveSessionAssignmentTableMeta>
} & Pick<LiveSessionUsersTableProps, 'onSetAttendance' | 'onRemoveUsers' | 'canEditAssignments'>

const Toolbar: React.FC<ToolbarProps> = ({
  tableAPI,
  onSetAttendance,
  onRemoveUsers,
  canEditAssignments,
}) => {
  const { api, dataLoader } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)
  const selectedUserIds = (selection.type === 'manual' ? Array.from(selection.rows) : []) as UserId[]

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <MenuDropdownPrimitive
              renderTrigger={() => <ActionButton color='blueBright'>{t('table.attendance')}</ActionButton>}
              onSelect={({ id }) => {
                switch (id) {
                  case 'set-attendance--present':
                    void onSetAttendance(selectedUserIds, 'present')
                    break
                  case 'set-attendance--absent':
                    void onSetAttendance(selectedUserIds, 'absent')
                    break
                }
              }}
              menuItems={getAttendanceMenuItems(t, attendance =>
                onSetAttendance(selectedUserIds, attendance)
              )}
            />
            <ActionButton
              onClick={() =>
                exportTableData({
                  api,
                  fileType: 'csv',
                  dataLoader,
                  t,
                  withName: t('admin.organization.users.users'),
                })
              }
              color='blueBright'
            >
              {csvButtonText}
            </ActionButton>
            {canEditAssignments && (
              <ActionButton
                color='redBright'
                onClick={() => {
                  //TODO: invalidate queries
                  onRemoveUsers(selectedUserIds)
                }}
              >
                {t('admin.remove')}
              </ActionButton>
            )}
            <ActionButton
              color='redBright'
              onClick={() => tableAPI.api.action.setSelection({ type: 'none' })}
            >
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

type FooterProps = {
  canEditAssignments: boolean
  openEnrollUsers: () => void
  fetchCsvData: () => Promise<Record<string, string>[]>
}

const Footer: React.FC<FooterProps> = ({ canEditAssignments, openEnrollUsers, fetchCsvData }) => {
  const { t } = useTranslation()

  return (
    <View marginBottom='32' marginTop='32' justifyContent={canEditAssignments ? 'space-between' : 'flex-end'}>
      {canEditAssignments === true && (
        <Button onClick={openEnrollUsers}>{t('manage.manage-learners')}</Button>
      )}
      <ExportCSVIconButton fetchCsvData={fetchCsvData} filename={t('admin.organization.users.users')} />
    </View>
  )
}

type LiveSessionUsersTableProps = {
  assignmentCount: number
  contentId: LiveSessionId
  facilitatorIds: UserId[]
  canEditAssignments: boolean
  canMarkAttendance: boolean
  maxNumberOfUsers?: number
  openEnrollUsers: () => void
  onRemoveUsers: (userIds: UserId[]) => void
  onSetAttendance: (userIds: UserId[], attendance: LiveSessionAttendanceStatus) => Promise<void>
}

export const LiveSessionUsersTable: React.FC<LiveSessionUsersTableProps> = ({
  contentId,
  onSetAttendance,
  onRemoveUsers,
  facilitatorIds,
  canMarkAttendance,
  openEnrollUsers,
  canEditAssignments,
  assignmentCount,
}) => {
  const { t } = useTranslation()
  const tableAPI = useLiveSessionUsersTable({
    contentId,
    onSetAttendance,
    assignmentCount,
    onRemoveUsers,
    facilitatorIds,
    canMarkAttendance,
  })

  const fetchCsvData = async (): Promise<Record<string, string>[]> => {
    const { data } = await typedPost(XRealtimeAdminLiveSessionsListEnrolledUsers, {
      liveSessionId: contentId,
      commonFilters: {
        query: tableAPI.api.query.query().query,
      },
    })
    return data.map(userToCsv)
  }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Text size='large' bold>
        {t('table.assigned')}
      </Text>
      <Spacer size='xsmall' />
      <Search tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        onSetAttendance={onSetAttendance}
        onRemoveUsers={onRemoveUsers}
        canEditAssignments={canEditAssignments}
      />
      <Table />
      <Footer
        canEditAssignments={canEditAssignments}
        openEnrollUsers={openEnrollUsers}
        fetchCsvData={fetchCsvData}
      />
    </TabularProviderFromTableAPI>
  )
}
