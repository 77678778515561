import { DateTime } from 'luxon'
import { FC, useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { XRealtimeListTimezones } from 'sierra-domain/routes'
import { MenuItem } from 'sierra-ui/components'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown/single-select-dropdown'

type TimezonePickerProps = {
  timezoneId: string | undefined
  onChange: (timezoneId: string | undefined) => void
  disabled?: boolean
}

export const TimezonePicker: FC<TimezonePickerProps> = ({ disabled, timezoneId, onChange }) => {
  const { t } = useTranslation()

  const timezonesQuery = useCachedQuery(XRealtimeListTimezones, {}, { staleTime: Infinity })
  const availableTimezones = useMemo(() => timezonesQuery.data?.timezones ?? [], [timezonesQuery.data])

  const options = useMemo((): MenuItem[] => {
    const zones: MenuItem[] = availableTimezones.map(zone => ({
      type: 'label' as const,
      id: zone.id,
      label: zone.name,
    }))

    // Sort the current timezone at the top of list
    const zone = DateTime.local().zoneName // eslint-disable-line no-restricted-syntax
    const currentTimezoneIndex = zones.findIndex(o => o.id === zone)

    if (currentTimezoneIndex !== -1) {
      const [currentTimezoneOption] = zones.splice(currentTimezoneIndex, 1)

      if (currentTimezoneOption) {
        zones.unshift(currentTimezoneOption)
      }
    }

    return zones
  }, [availableTimezones])

  const onSelect = (item: MenuItem): void => {
    onChange(item.id)
  }

  const selected = options.find(o => o.id === timezoneId)

  return (
    <SingleSelectDropdown
      disabled={disabled}
      placeholder={t('components.timezone-picker.placeholder')}
      selectedItem={selected}
      menuItems={options}
      onSelect={onSelect}
      withSearch={true}
      maxHeight={300}
    />
  )
}
