import { useState } from 'react'
import {
  useAddTeamspaceMembersMutation,
  useRemoveTeamspaceMemberMutation,
  useTransferTeamspaceOwnershipMutation,
  useUpdateTeamspaceMemberMutation,
} from 'sierra-client/api/hooks/use-teamspace'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AddCollaboratorsForm } from 'sierra-client/features/teamspace/components/collaborators-selector'
import { userToIdentityWithmetadata } from 'sierra-client/features/teamspace/helpers/identity'
import { isTeamspaceRoleAbove } from 'sierra-client/features/teamspace/helpers/roles'
import { teamspaceAddedNewMembersLogger } from 'sierra-client/features/teamspace/logger'
import { useHasTeamspacePermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey, TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import { removeSearchParams } from 'sierra-client/router/search-params'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { IdentityRef, IdentityWithMetadata } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import {
  Teamspace,
  TeamspaceIdentityWithRole,
  TeamspaceMemberIdentity,
  TeamspaceRole,
  TeamspaceRoleWithOwner,
  TeamspaceUserMember,
} from 'sierra-domain/api/teamspace'
import { UserId } from 'sierra-domain/api/uuid'
import { LightUser } from 'sierra-domain/user'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { MenuItem, RoundAvatar } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const teamspaceRoleToTranslationKey: Record<TeamspaceRoleWithOwner, TranslationKey> = {
  viewer: 'author.role-viewer',
  commenter: 'author.role-commenter',
  editor: 'author.role-editor',
  owner: 'dictionary.owner',
}

const translateTeamspaceRole = (role: TeamspaceRoleWithOwner, t: TranslationLookup): string =>
  t(teamspaceRoleToTranslationKey[role])

const EditTeamspaceMembersTabContainer = styled(View)`
  height: 100%;
`
const MemberContainer = styled(View).attrs({
  padding: '4',
  justifyContent: 'space-between',
})`
  border-bottom: 1px solid ${token('border/default')};
`

const RoleLabel = styled(Text)`
  padding-right: 16px;
`

const DeleteMemberModal: React.FC<{
  open: boolean
  onClose: () => void
  teamspaceId: NanoId12
  identity: IdentityRef | undefined
}> = ({ open, onClose, teamspaceId, identity }) => {
  const { t } = useTranslation()
  const removeTeamspaceMemberMutation = useRemoveTeamspaceMemberMutation()
  const currentUser = useSelector(selectUser)

  const title =
    identity !== undefined && identity.type === 'userGroup'
      ? t('teamspace.edit-modal.remove-group-modal.title')
      : t('teamspace.edit-modal.remove-user-modal.title')

  const body =
    identity !== undefined && identity.type === 'userGroup'
      ? t('teamspace.edit-modal.remove-group-modal.body')
      : t('teamspace.edit-modal.remove-user-modal.body')

  const action =
    identity !== undefined && identity.type === 'userGroup'
      ? t('teamspace.edit-modal.remove-group-modal.action')
      : t('teamspace.edit-modal.remove-user-modal.action')

  const deleteMember = (): void => {
    if (identity === undefined) return

    removeTeamspaceMemberMutation.mutate(
      { teamspaceId, identity },
      {
        onSuccess: () => {
          if (currentUser?.uuid === identity.id) {
            void getGlobalRouter().navigate({ to: '/' })
          }
        },
      }
    )
  }

  return (
    <ActionModal
      open={open}
      deleteAction
      title={title}
      onClose={onClose}
      primaryAction={() => {
        deleteMember()
      }}
      primaryActionLabel={action}
    >
      {body}
    </ActionModal>
  )
}

const ConfirmPermissionChangeModal: React.FC<{
  open: boolean
  onClose: () => void
  onContinue: () => void
}> = ({ open, onClose, onContinue }) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      open={open}
      title={t('teamspace.edit-modal.change-role-modal.title')}
      onClose={onClose}
      primaryAction={() => {
        void onContinue()
      }}
      primaryActionLabel={t('dictionary.continue')}
    >
      {t('teamspace.edit-modal.change-role-modal.body')}
    </ActionModal>
  )
}

const TeamspaceGroupRoleDropdown: React.FC<{
  onSelect: (item: MenuItem<TeamspaceRole | 'remove'>) => void
  group: TeamspaceMemberIdentity
}> = ({ onSelect, group }) => {
  const { t } = useTranslation()
  const items: MenuItem<TeamspaceRole | 'remove'>[] = [
    {
      type: 'label',
      label: t('author.role-editor'),
      id: 'editor',
      selected: group.role === 'editor',
    },
    {
      type: 'label',
      label: t('author.role-commenter'),
      id: 'commenter',
      selected: group.role === 'commenter',
    },
    {
      type: 'label',
      label: t('author.role-viewer'),
      id: 'viewer',
      selected: group.role === 'viewer',
    },
    {
      type: 'label',
      color: 'destructive/background',
      label: t('teamspace.edit-modal.remove-from-teamspace'),
      id: 'remove',
    },
  ]
  const selectedItem = items.find(item => item.id === group.role)
  return (
    <SingleSelectDropdown<TeamspaceRole | 'remove'>
      variant='ghost'
      selectedItem={selectedItem}
      menuItems={items}
      onSelect={onSelect}
    />
  )
}

const TeamspaceUserRoleDropdown: React.FC<{
  onSelect: (item: MenuItem<TeamspaceRoleWithOwner | 'remove'>) => void
  user: TeamspaceUserMember
  isUserOwner: boolean
}> = ({ onSelect, user, isUserOwner }) => {
  const { t } = useTranslation()
  const potentialRolesMenuItems: MenuItem<TeamspaceRoleWithOwner | 'remove'>[] = (
    [
      {
        type: 'label',
        label: t('author.role-editor'),
        id: 'editor',
        selected: user.role === 'editor',
      },
      {
        type: 'label',
        label: t('author.role-commenter'),
        id: 'commenter',
        selected: user.role === 'commenter',
      },
      {
        type: 'label',
        label: t('author.role-viewer'),
        id: 'viewer',
        selected: user.role === 'viewer',
      },
      {
        type: 'label',
        label: t('author.owner-transfer.transfer-ownership'),
        id: 'owner',
      },
    ] as const
  ).filter(it => user.potentialRoles?.includes(it.id) ?? false)

  const items: MenuItem<TeamspaceRoleWithOwner | 'remove'>[] = [
    ...potentialRolesMenuItems,
    {
      color: 'destructive/background',
      type: 'label',
      hidden: isUserOwner,
      label: t('teamspace.edit-modal.remove-from-teamspace'),
      id: 'remove',
    },
  ]
  const selectedItem = items.find(item => item.id === user.role)
  return (
    <SingleSelectDropdown<TeamspaceRoleWithOwner | 'remove'>
      variant='ghost'
      selectedItem={selectedItem}
      menuItems={items}
      onSelect={onSelect}
    />
  )
}

const MembersTab: React.FC<{
  teamspace: Teamspace
  mode: 'edit' | 'read-only'
  requestAccessUser?: LightUser
}> = ({ teamspace, mode, requestAccessUser }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const memberIds = teamspace.userMembers.map(member => member.userId)
  const groups = teamspace.groupMembers
  const users = useUsersLegacy(memberIds).filter(isDefined)

  const sortedUsers = [...users].sort((a, b) => {
    const firstNameComparison = a.firstName.localeCompare(b.firstName)

    if (firstNameComparison !== 0) {
      return firstNameComparison
    }

    return a.lastName.localeCompare(b.lastName)
  })

  const sortedGroups = [...groups].sort((a, b) => {
    return a.name.localeCompare(b.name)
  })

  const [deleteCandidate, setDeleteCandidate] = useState<IdentityRef | undefined>(undefined)
  const currentUser = useSelector(selectUser)
  const [changePermissionCandidate, setChangePermissionCandidate] = useState<
    TeamspaceIdentityWithRole | undefined
  >(undefined)

  const addMemberMutation = useAddTeamspaceMembersMutation()
  const updateMemberMutation = useUpdateTeamspaceMemberMutation()
  const transferOwnershipMutation = useTransferTeamspaceOwnershipMutation()

  const requestAccessIdentity: IdentityWithMetadata | undefined =
    requestAccessUser === undefined ? undefined : userToIdentityWithmetadata(requestAccessUser)

  const addMembers = (identities: TeamspaceIdentityWithRole[]): void => {
    if (identities.length < 1) return

    addMemberMutation.mutate(
      { identities, teamspaceId: teamspace.id },
      {
        onSuccess: () => {
          void dispatch(
            teamspaceAddedNewMembersLogger({
              teamspaceId: teamspace.id,
              usersAdded: identities.length,
            })
          )

          removeSearchParams(['teamspace-request-access'])
        },
      }
    )
  }

  const updateMember = (member: TeamspaceIdentityWithRole): void => {
    updateMemberMutation.mutate({ teamspaceId: teamspace.id, member })
  }

  const transferTeamspaceOwnership = (userId: UserId): void => {
    transferOwnershipMutation.mutate({
      userId,
      teamspaceId: teamspace.id,
    })
  }

  const isUserOwner = (userId: string): boolean => {
    return teamspace.userMembers.some(member => member.userId === userId && member.role === 'owner')
  }

  return (
    <EditTeamspaceMembersTabContainer
      direction='column'
      grow
      justifyContent='flex-start'
      paddingTop='24'
      gap='none'
    >
      {mode === 'edit' && (
        <AddCollaboratorsForm
          teamspace={teamspace}
          defaultIdentities={requestAccessIdentity ? [requestAccessIdentity] : []}
          onSubmit={collaborators => {
            addMembers(
              collaborators.map(collaborator => ({
                identity: collaborator.identity,
                role: collaborator.role,
              }))
            )
          }}
        />
      )}
      {sortedGroups.map(group => {
        const identity = group.identity
        return (
          <MemberContainer key={identity.id}>
            <View padding='12'>
              <div className='avatar'>
                {
                  <RoundAvatar
                    size='small'
                    color={group.avatar.type === 'color' ? group.avatar.color : undefined}
                    firstName={group.name}
                  />
                }
              </div>
              <Text bold>{group.name}</Text>
            </View>
            <View>
              {mode === 'read-only' ? (
                <RoleLabel bold color='foreground/muted'>
                  {translateTeamspaceRole(group.role, t)}
                </RoleLabel>
              ) : (
                <TeamspaceGroupRoleDropdown
                  onSelect={item => {
                    switch (item.id) {
                      case 'remove':
                        setDeleteCandidate(identity)
                        break
                      case 'commenter':
                      case 'viewer':
                      case 'editor':
                        updateMember({
                          identity,
                          role: item.id,
                        })
                        break
                      default:
                        assertNever(item.id)
                    }
                  }}
                  group={group}
                />
              )}
            </View>
          </MemberContainer>
        )
      })}
      {sortedUsers.map(user => {
        const identity: IdentityRef = { type: 'user' as const, id: user.uuid }
        const userMember = teamspace.userMembers.find(it => it.userId === user.uuid)!

        return (
          <MemberContainer key={user.uuid}>
            <View padding='8'>
              <div className='avatar'>
                <RoundAvatar
                  size='small'
                  firstName={user.firstName}
                  lastName={user.lastName}
                  src={getAvatarImage(user.uuid, user.avatar)}
                  color={user.avatarColor}
                />
              </div>

              <View direction='column' gap='none'>
                <View gap='4'>
                  <Text bold>
                    {user.firstName} {user.lastName}
                  </Text>
                  {currentUser?.uuid === user.uuid && (
                    <Text bold color='foreground/muted'>
                      {t('teamspace.edit-modal.you')}
                    </Text>
                  )}
                </View>
                <Text color='foreground/secondary'>{user.email}</Text>
              </View>
            </View>
            <View>
              {mode === 'read-only' || userMember.role === 'owner' ? (
                <RoleLabel bold color='grey40'>
                  {translateTeamspaceRole(userMember.role, t)}
                </RoleLabel>
              ) : (
                <TeamspaceUserRoleDropdown
                  isUserOwner={isUserOwner(user.uuid)}
                  user={userMember}
                  onSelect={item => {
                    switch (item.id) {
                      case 'remove':
                        setDeleteCandidate(identity)
                        break
                      case 'owner':
                        transferTeamspaceOwnership(identity.id)
                        break
                      case 'commenter':
                      case 'viewer':
                      case 'editor': {
                        const newRole = item.id
                        if (userMember.role === newRole) return
                        if (
                          currentUser?.uuid === user.uuid &&
                          userMember.role !== 'owner' &&
                          isTeamspaceRoleAbove(userMember.role, newRole)
                        ) {
                          setChangePermissionCandidate({
                            identity,
                            role: newRole,
                          })
                          return
                        }
                        updateMember({
                          identity,
                          role: newRole,
                        })
                        break
                      }
                      default:
                        assertNever(item.id)
                    }
                  }}
                />
              )}
            </View>
          </MemberContainer>
        )
      })}
      <DeleteMemberModal
        open={deleteCandidate !== undefined}
        onClose={() => setDeleteCandidate(undefined)}
        teamspaceId={teamspace.id}
        identity={deleteCandidate}
      />
      <ConfirmPermissionChangeModal
        open={changePermissionCandidate !== undefined}
        onClose={() => setChangePermissionCandidate(undefined)}
        onContinue={() => {
          if (changePermissionCandidate !== undefined) {
            updateMember(changePermissionCandidate)
          }
        }}
      />
    </EditTeamspaceMembersTabContainer>
  )
}

export const EditTeamspaceMembersTab: React.FC<{
  teamspace: Teamspace
  mode: 'edit' | 'read-only'
  requestAccessUser?: LightUser
}> = ({ teamspace, mode, requestAccessUser }) => {
  const hasListMembersPermission = useHasTeamspacePermission(teamspace.id, 'LIST_MEMBERS')

  if (!hasListMembersPermission) return null
  return <MembersTab mode={mode} requestAccessUser={requestAccessUser} teamspace={teamspace} />
}
