import { config } from 'sierra-client/config/global-config'
import { useAvailableIntegrationActions } from 'sierra-client/hooks/use-available-integration-actions'

export const useCanCreateCalendarIntegrationEvent = (): boolean => {
  const calendarEventProvider = config.organization.settings.calendarIntegrationSettings
  const availableIntegrations = useAvailableIntegrationActions()

  switch (calendarEventProvider.type) {
    case 'sana':
      return true
    case 'microsoft': {
      return availableIntegrations.microsoft.createCalendarEvent
    }
    case 'google':
      return false
  }
}
