import React from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useManageXApi } from 'sierra-client/views/manage/api/use-manage-x-api'
import { XApiTable } from 'sierra-client/views/manage/api/x-api-table'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'

export const ManageXApi: React.FC = () => {
  const { t } = useTranslation()

  const { xApiServers } = useManageXApi()

  const createApiClient = async (): Promise<void> => {
    await getGlobalRouter().navigate({
      to: '/manage/x-api/$xApiServerId',
      params: { xApiServerId: 'new' },
      replace: true,
    })
  }

  return (
    <SmallTableWrapper title={t('manage.x-api.title')} subtitle={t('manage.x-api.subtitle')}>
      <XApiTable data={xApiServers} createApiClient={createApiClient} />
    </SmallTableWrapper>
  )
}
