import { graphql } from 'sierra-client/api/graphql/gql'
import { ProgramEnrollmentsQuery } from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import {
  dateTimesColumn,
  enrolledBysColumn,
  programVersionNumbersColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { DataLoaderStateMachine, createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { capitalizedLabel, translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { UserProgramVersion } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { assertNever, getUserName, isNonNullable, isNullable, lowercase } from 'sierra-domain/utils'

type Data = ProgramEnrollmentsQuery['programEnrollments']['data'][number]

type ProgramUsersTableDefinition = TableDefinitionOf<
  Data,
  [
    { type: 'users'; ref: 'user' },
    { type: 'dateTimes'; ref: 'enrolled' },
    { type: 'dateTimes'; ref: 'start-date' },
    { type: 'enrolledBys'; ref: 'enrolled-by' },
    { type: 'programVersionNumbers'; ref: 'version' },
  ]
>
export type ProgramUsersTableData = TableDataFromDefinition<Data, ProgramUsersTableDefinition>

const programEnrollmentsQuery = graphql(`
  query ProgramEnrollments($programId: ProgramId!, $limit: Int, $cursor: String, $query: String) {
    programEnrollments(id: $programId, limit: $limit, cursor: $cursor, query: $query) {
      totalCount
      cursor
      data {
        user {
          id
          firstName
          lastName
          email
          status
          avatar {
            ...AvatarFragment
          }
        }
        createdAt
        availableAt
        isRequiredAssignment
        enrolledBy {
          ...EnrolledBy
        }
        progress {
          progress
        }
        programVersion {
          version
          updatedAt
          isUserInLastVersion
        }
      }
    }
  }
`)

const tableDefinition = (
  onViewProgramVersion: (programVersion: UserProgramVersion, userId: UserId, name: string) => void
): ProgramUsersTableDefinition => ({
  nested: {},
  rows: { getId: u => u.user.id },
  columns: [
    usersColumn({
      ref: 'user',
      sortable: false,
      header: translatedLabel('table.name'),
      hints: ['user-with-program-status'],
      getData: u => {
        const user = u.user
        const avatar = convertGQLAvatar(user.avatar)

        return {
          firstName: user.firstName ?? '-',
          lastName: user.lastName ?? '-',
          avatarColor: getAvatarColor(avatar),
          avatar: getAvatarUrl(user.id, avatar),
          email: user.email,
          status: lowercase(user.status) satisfies UserRep['status'],
          id: user.id,
          isRequiredAssignment: u.isRequiredAssignment,
        }
      },
    }),
    dateTimesColumn({
      ref: 'enrolled',
      header: translatedLabel('manage.programs.enrollment-rule.enrollment-date'),
      sortable: false,
      getData: u => ({ date: new Date(u.createdAt) }),
    }),
    dateTimesColumn({
      ref: 'start-date',
      header: translatedLabel('manage.programs.enrollment-rule.start-date'),
      sortable: false,
      getData: u => ({ date: new Date(u.availableAt) }),
    }),
    enrolledBysColumn({
      ref: 'enrolled-by',
      header: translatedLabel('manage.programs.enrollment-rule.enrolled-by'),
      sortable: false,
      getData: ({ enrolledBy }) => {
        if (isNonNullable(enrolledBy)) {
          switch (enrolledBy.__typename) {
            case 'EnrolledByAdmin':
              return {
                type: 'admin',
                firstName: enrolledBy.user?.firstName ?? '-',
                lastName: enrolledBy.user?.lastName ?? '-',
              }
            case 'EnrolledBySelf':
              return { type: 'self' }
            case 'EnrolledByRule':
              return { type: 'enrollment-rule', name: enrolledBy.rule?.name ?? '-' }
            default:
              assertNever(enrolledBy)
          }
        } else return { type: 'unknown' }
      },
    }),
    programVersionNumbersColumn({
      ref: 'version',
      header: capitalizedLabel(translatedLabel('dictionary.progress')),
      hints: ['percentage'],
      sortable: false,
      getData: u => ({
        onClick: () => {
          onViewProgramVersion(u.programVersion, u.user.id, getUserName(u.user) ?? '')
        },
        ...u.programVersion,
        progress: u.progress.progress,
      }),
    }),
  ],
})

type ProgramMembersDataLoaderParams = {
  programId: string
  onViewProgramVersion: (programVersion: UserProgramVersion, userId: UserId, name: string) => void
}
export const programMembersDataLoader = ({
  programId,
  onViewProgramVersion,
}: ProgramMembersDataLoaderParams): DataLoaderStateMachine<
  ProgramUsersTableData,
  { cursor: string | null | undefined }
> =>
  createDataLoader({
    async fetchInit({ control, predicate }) {
      const r = await graphQuery(programEnrollmentsQuery, {
        programId,
        limit: control.limit,
        query: predicate.query,
      })

      return {
        meta: { cursor: r.programEnrollments.cursor },
        data: r.programEnrollments.data,
        totalCount: r.programEnrollments.totalCount,
        done: isNullable(r.programEnrollments.cursor),
      }
    },

    async fetchMore({ control, meta }) {
      const r = await graphQuery(programEnrollmentsQuery, {
        programId,
        limit: control.limit,
        cursor: meta.cursor,
      })

      return {
        meta: { cursor: r.programEnrollments.cursor },
        data: r.programEnrollments.data,
        totalCount: r.programEnrollments.totalCount,
        done: isNullable(r.programEnrollments.cursor),
      }
    },

    transformResults(data) {
      return [definition2Data(tableDefinition(onViewProgramVersion), data)]
    },
  })
