import fuzzysort from 'fuzzysort'
import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React from 'react'
import { ContentTableRowSkeleton } from 'sierra-client/components/common/content-table-row'
import { getFlag } from 'sierra-client/config/global-config'
import { appMainContainerAtom } from 'sierra-client/features/global-sidebar'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  jsonWithZodSerializer,
  stringWithZodSerializer,
  useQueryState,
} from 'sierra-client/lib/querystate/use-query-state'
import { useIsTablet } from 'sierra-client/state/browser/selectors'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  FilteringContainer,
  FiltersContainer,
  SearchBarContainer,
  VerticalSeparator,
} from 'sierra-client/views/workspace/components'
import { ListVirtualizer } from 'sierra-client/views/workspace/components/list-virtualizer'
import { NoContentPlaceholder } from 'sierra-client/views/workspace/components/no-content-container'
import { LearnContentTableRow } from 'sierra-client/views/workspace/learn/learn-content-table-row'
import { ContentKind, LearnerContent } from 'sierra-domain/api/learn'
import { assertNever, hasKey, iife } from 'sierra-domain/utils'
import { resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { MenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'
import z from 'zod'

const isSessionInPast = (content: LearnerContent): boolean => {
  if (content.type === 'live-session') {
    return new Date(content.endDate) < new Date()
  }
  return false
}

const Assignment = z.enum(['all-content', 'assigned-to-you', 'high-prio-assignment', 'favorites', 'archived'])
type Assignment = z.infer<typeof Assignment>
const AssignmentSerializer = stringWithZodSerializer(Assignment)

const ContentType = z.enum(['any-type', 'course', 'path', 'live', 'external'])
type ContentType = z.infer<typeof ContentType>
const ContentTypeSerializer = stringWithZodSerializer(ContentType)

const Progress = z.enum(['any-progress', 'has-started', 'not-started', 'completed'])
type Progress = z.infer<typeof Progress>
const ProgressSerializer = stringWithZodSerializer(Progress)

const Provider = z.enum(['any-provider', 'sana', 'linkedin', 'other'])
type Provider = z.infer<typeof Provider>
const ProviderSerializer = stringWithZodSerializer(Provider)

const TagsSerializer = jsonWithZodSerializer(z.string().array())

type LearnerContentFilter = {
  assignment: Assignment
  contentType: ContentType
  progress: Progress
  provider: Provider
  tagIds: string[]
}

const filterRow = (filter: LearnerContentFilter, row: LearnerContent): boolean => {
  const courseTypes: ContentKind[] = ['native:self-paced']
  const externalTypes: ContentKind[] = ['linkedin', 'scorm', 'link']

  const contentTypeMatch: boolean = iife(() => {
    switch (filter.contentType) {
      case 'any-type':
        return true
      case 'course':
        return courseTypes.includes(row.type)
      case 'external':
        return externalTypes.includes(row.type)
      case 'path':
        return row.type === 'path'
      case 'live':
        return row.type === 'live-session'
      default:
        assertNever(filter.contentType)
    }
  })

  const sanaTypes: ContentKind[] = ['native:self-paced', 'path', 'native:live', 'live-session']
  const otherTypes: ContentKind[] = ['link', 'scorm']

  const providerMatch: boolean = iife(() => {
    switch (filter.provider) {
      case 'any-provider':
        return true
      case 'sana':
        return sanaTypes.includes(row.type)
      case 'linkedin':
        return row.type === 'linkedin'
      case 'other':
        return otherTypes.includes(row.type)
      default:
        assertNever(filter.provider)
    }
  })

  const currentProgress = iife(() => {
    if (row.type === 'live-session') {
      return isSessionInPast(row) ? 'completed' : undefined
    }

    if (row.progress === 0) return 'not-started'
    if (row.progress === 1) return 'completed'

    return 'has-started'
  })

  const progressMatch = filter.progress === 'any-progress' || currentProgress === filter.progress

  const archivedMatch = iife((): boolean => {
    switch (filter.assignment) {
      case 'archived':
        return row.isArchived
      case 'all-content':
      case 'assigned-to-you':
      case 'favorites':
      case 'high-prio-assignment':
        return !row.isArchived
      default:
        assertNever(filter.assignment)
    }
  })

  const contentReasonMatch = iife(() => {
    switch (filter.assignment) {
      case 'all-content':
        return true
      case 'assigned-to-you':
        return row.assignedAt !== undefined
      case 'high-prio-assignment':
        return row.isRequiredAssignment === true
      case 'favorites':
        return row.isFavorite
      case 'archived':
        return row.isArchived
      default:
        assertNever(filter.assignment)
    }
  })

  const tagMatch = filter.tagIds.length === 0 || _.intersection(row.tags, filter.tagIds).length > 0

  return contentTypeMatch && providerMatch && progressMatch && tagMatch && contentReasonMatch && archivedMatch
}

const SortFunctionId = z.enum([
  'relevance',
  'time-to-complete-asc',
  'due-date-asc',
  'due-date-desc',
  'alphabetically-asc',
  'alphabetically-desc',
])
type SortFunctionId = z.infer<typeof SortFunctionId>
const SortFunctionSerializer = stringWithZodSerializer(SortFunctionId)

const hasFutureSession = (row: LearnerContent): boolean => {
  if (row.type === 'live-session') {
    return row.endedAt === undefined && new Date(row.endDate) >= new Date()
  }
  return false
}

const isIncompleteWithDuedate = (row: LearnerContent): boolean => {
  return row.dueDate !== undefined && row.progress !== 1
}

const getDueDateForSorting = (row: LearnerContent): Date | undefined => {
  if (row.dueDate !== undefined) {
    return new Date(row.dueDate)
  }
  return undefined
}

const getSessionForSorting = (row: LearnerContent): Date | undefined => {
  if (row.type === 'live-session') {
    return new Date(row.startDate)
  }
  return undefined
}

const getTitle = (row: LearnerContent): string => row.title
const getProgress = (row: LearnerContent): number | undefined => row.progress

const sortAlphabetically = (xs: LearnerContent[]): LearnerContent[] =>
  xs.sort((a, b) => a.title.localeCompare(b.title))

// todo(damjan): refactor this
const sortByRelevance = (xs: LearnerContent[], isRequiredAssignmentsEnabled: boolean): LearnerContent[] => {
  // Grab all the content that are required assignmentsFirst
  const [requiredAssignments, rest0] = _.partition(
    xs,
    x => x.isRequiredAssignment === true && isRequiredAssignmentsEnabled
  )

  // Grab all the content that has hard failed exercises first
  const [hardFailedExercises, rest1] = _.partition(
    rest0,
    x => hasKey(x, 'exercises') && x.exercises.some(e => e.grade === 'failed-with-no-retries')
  )
  const [withFutureSession, rest2] = _.partition(rest1, hasFutureSession)
  const [incompleteWithDueDate, rest3] = _.partition(rest2, isIncompleteWithDuedate)
  const [pastLiveSessions, rest4] = _.partition(rest3, isSessionInPast)
  const [inProgress, rest5] = _.partition(rest4, x => x.progress !== 0 && x.progress !== 1)
  // TODO: Favorited
  const [completed, rest6] = _.partition(rest5, x => x.progress === 1)

  return _.flatten([
    _.orderBy(requiredAssignments, [getTitle], ['desc']),
    _.orderBy(withFutureSession, [getSessionForSorting, getTitle], ['asc', 'asc']),
    _.orderBy(incompleteWithDueDate, [getDueDateForSorting, getTitle], ['asc', 'asc']),
    _.orderBy(inProgress, [getProgress, getTitle], ['desc', 'asc']),
    sortAlphabetically(rest6),
    sortAlphabetically(hardFailedExercises),
    sortAlphabetically(completed),
    _.orderBy(pastLiveSessions, [getSessionForSorting, getTitle], ['desc', 'asc']),
  ])
}

const sortFunctions: Record<
  SortFunctionId,
  (xs: LearnerContent[], isRequiredAssignmentsEnabled: boolean) => LearnerContent[]
> = {
  'relevance': sortByRelevance,
  'time-to-complete-asc': xs =>
    _.orderBy(
      xs,
      [x => ('timeEstimate' in x ? 0 : 1), x => ('timeEstimate' in x ? x.timeEstimate : 0), x => x.title],
      ['asc', 'asc', 'asc']
    ),
  // TODO if no dueDate, sort by recent?
  'due-date-asc': xs =>
    _.orderBy(
      xs,
      [x => ('dueDate' in x ? 0 : 1), x => ('dueDate' in x ? x.dueDate : 0), x => x.title],
      ['asc', 'asc', 'asc']
    ),
  'due-date-desc': xs =>
    _.orderBy(
      xs,
      [x => ('dueDate' in x ? 0 : 1), x => ('dueDate' in x ? x.dueDate : 0), x => x.title],
      ['asc', 'desc', 'asc']
    ),
  'alphabetically-asc': xs => _.orderBy(xs, x => x.title, 'asc'),
  'alphabetically-desc': xs => _.orderBy(xs, x => x.title, 'desc'),
}

const Line = styled.div`
  flex: 1;
  border-bottom: 1px solid ${p => resolveTokenOrColor('border/default', p.theme)};
`
export const LearnContentTable: React.FC<{
  content: LearnerContent[]
  isLoading: boolean
}> = ({ content, isLoading }) => {
  const { t } = useTranslation()
  const [debouncedSearchTerm, liveSearchTerm, setSearchTerm] = useDebouncedAndLiveState('', { wait: 500 })
  const mainContainerElement = useAtomValue(appMainContainerAtom)
  const isRequiredAssignmentsEnabled = getFlag('required-assignments')

  const [assignment, setAssignment] = useQueryState(AssignmentSerializer, 'all-content', 'a')
  const [contentType, setContentType] = useQueryState(ContentTypeSerializer, 'any-type', 'c')
  const [provider, setProvider] = useQueryState(ProviderSerializer, 'any-provider', 'provider')
  const [progress, setProgress] = useQueryState(ProgressSerializer, 'any-progress', 'progress')
  const [tagIds] = useQueryState(TagsSerializer, [], 'tags')

  const [sortFunctionId, setSortFunctionId] = useQueryState(SortFunctionSerializer, 'relevance', 'sort')

  const isLargerThanTablet = !useIsTablet()
  const isAllDefaultFilters =
    assignment === 'all-content' &&
    contentType === 'any-type' &&
    progress === 'any-progress' &&
    provider === 'any-provider' &&
    sortFunctionId === 'relevance'

  const filteredContent = React.useMemo(() => {
    const learnerContentFilter = {
      assignment,
      contentType,
      progress,
      provider,
      tagIds,
    }

    const filteredContent = content.filter(c => filterRow(learnerContentFilter, c))
    const sorted = sortFunctions[sortFunctionId](filteredContent, isRequiredAssignmentsEnabled)

    return fuzzysort
      .go(debouncedSearchTerm, sorted, {
        all: true,
        limit: debouncedSearchTerm.length > 0 ? 20 : undefined,
        key: 'title',
      })
      .map(({ obj }) => obj)
  }, [
    sortFunctionId,
    content,
    debouncedSearchTerm,
    assignment,
    contentType,
    progress,
    provider,
    tagIds,
    isRequiredAssignmentsEnabled,
  ])

  const contentWithLoading = iife(() => {
    if (isLoading) {
      return (
        <>
          <ContentTableRowSkeleton opacity={1} />
          <ContentTableRowSkeleton opacity={0.7} />
          <ContentTableRowSkeleton opacity={0.4} />
          <ContentTableRowSkeleton opacity={0.2} />
        </>
      )
    }

    if (content.length === 0) {
      return <NoContentPlaceholder />
    }

    const ESTIMATED_ROW_HEIGHT = 66
    const requiredContent = filteredContent.filter(row => row.isRequiredAssignment)
    return (
      <>
        {isRequiredAssignmentsEnabled && isAllDefaultFilters && requiredContent.length > 0 ? (
          <View direction='column' gap='4'>
            <Text bold color='foreground/muted'>
              {t('assignments.required')}
            </Text>

            <Line />
            <ListVirtualizer
              scrollElement={mainContainerElement}
              items={requiredContent}
              estimateSize={ESTIMATED_ROW_HEIGHT}
              renderItem={(row, index) => (
                <LearnContentTableRow
                  key={row.id}
                  row={row}
                  isLastChildInList={index === requiredContent.length - 1}
                />
              )}
            />
            <Text bold color='foreground/muted'>
              {t('home.other-assignments')}
            </Text>
            <Line />
            <ListVirtualizer
              scrollElement={mainContainerElement}
              items={filteredContent.filter(row => !row.isRequiredAssignment)}
              estimateSize={ESTIMATED_ROW_HEIGHT}
              renderItem={row => <LearnContentTableRow key={row.id} row={row} />}
            />
          </View>
        ) : (
          <ListVirtualizer
            scrollElement={mainContainerElement}
            items={filteredContent}
            estimateSize={ESTIMATED_ROW_HEIGHT}
            renderItem={row => <LearnContentTableRow key={row.id} row={row} />}
          />
        )}
      </>
    )
  })

  const assignmentandArchivedItems: MenuItem<LearnerContentFilter['assignment'] | 'separator'>[] = [
    {
      type: 'label',
      id: 'all-content',
      label: t('filter.assignment.all-content'),
    },
    {
      type: 'label',
      id: 'assigned-to-you',
      label: t('filter.assignment.assigned-to-you'),
    },
    {
      type: 'label',
      id: 'high-prio-assignment',
      label: t('filter.assignment.required-assignment'),
      hidden: isRequiredAssignmentsEnabled === false,
    },
    {
      type: 'label',
      id: 'favorites',
      label: t('filter.assignment.favorites'),
    },
    {
      type: 'separator',
      id: 'separator',
    },
    {
      type: 'label',
      id: 'archived',
      label: t('workspace.learn.archived'),
    },
  ]

  const progressItems: MenuItem<LearnerContentFilter['progress']>[] = [
    {
      type: 'label',
      id: 'any-progress',
      label: t('filter.progress.any-progress'),
    },
    {
      type: 'label',
      id: 'has-started',
      label: t('filter.progress.started'),
    },
    {
      type: 'label',
      id: 'not-started',
      label: t('filter.progress.not-started'),
    },
    {
      type: 'label',
      id: 'completed',
      label: t('dictionary.completed'),
    },
  ]

  const contentTypeItems: MenuItem<LearnerContentFilter['contentType']>[] = [
    {
      type: 'label',
      id: 'any-type',

      label: t('filter.content.any-type'),
    },
    { type: 'label', id: 'live', label: t('dictionary.live') },
    {
      type: 'label',
      id: 'course',
      label: t('dictionary.course-singular'),
    },
    {
      type: 'label',
      id: 'path',
      label: t('dictionary.path-singular'),
    },
    {
      type: 'label',
      id: 'external',
      label: t('dictionary.external'),
    },
  ]

  const providerItems: MenuItem<LearnerContentFilter['provider']>[] = [
    {
      type: 'label',
      id: 'any-provider',
      label: t('filter.provider.any-provider'),
    },
    { type: 'label', id: 'sana', label: t('filter.your-organization') },
    { type: 'label', id: 'linkedin', label: 'LinkedIn Learning' },
    { type: 'label', id: 'other', label: t('filter.other') },
  ]

  const sortItems: MenuItem<keyof typeof sortFunctions>[] = [
    {
      type: 'label',
      id: 'relevance',
      label: t('sort.relevance'),
    },
    {
      type: 'label',
      id: 'time-to-complete-asc',
      label: t('sort.time-left'),
    },
    {
      type: 'label',
      id: 'due-date-asc',
      label: t('sort.due-date.newest-first'),
    },
    {
      type: 'label',
      id: 'due-date-desc',
      label: t('sort.due-date.oldest-first'),
    },
    {
      type: 'label',
      id: 'alphabetically-asc',
      label: t('sort.alphabetically.a-z'),
    },
    {
      type: 'label',
      id: 'alphabetically-desc',
      label: t('sort.alphabetically.z-a'),
    },
  ]

  return (
    <View role='region' aria-label={t('table.search-filter.label')} direction='column' gap='12'>
      {isLargerThanTablet && (
        <FilteringContainer>
          <SearchBarContainer>
            <RoundedSearchBar
              maxWidth='100%'
              value={liveSearchTerm}
              onChange={term => {
                setSearchTerm(term)
              }}
              placeholder={`${t('dictionary.search')}...`}
            />
          </SearchBarContainer>
          <FiltersContainer>
            <SingleSelectDropdown<LearnerContentFilter['assignment'] | 'separator'>
              selectedItem={assignmentandArchivedItems.find(it => it.id === assignment)}
              menuItems={assignmentandArchivedItems}
              onSelect={item => {
                if (item.type !== 'separator' && item.id !== 'separator') {
                  setAssignment(item.id)
                }
              }}
              bold
              truncateTrigger={false}
            />
            <SingleSelectDropdown<LearnerContentFilter['progress']>
              selectedItem={progressItems.find(it => it.id === progress)}
              menuItems={progressItems}
              onSelect={item => setProgress(item.id)}
              bold
              truncateTrigger={false}
            />
            <SingleSelectDropdown<LearnerContentFilter['contentType']>
              selectedItem={contentTypeItems.find(it => it.id === contentType)}
              menuItems={contentTypeItems}
              onSelect={item => setContentType(item.id)}
              bold
              truncateTrigger={false}
            />
            <SingleSelectDropdown<LearnerContentFilter['provider']>
              selectedItem={providerItems.find(it => it.id === provider)}
              menuItems={providerItems}
              onSelect={item => setProvider(item.id)}
              bold
              truncateTrigger={false}
            />

            <VerticalSeparator />

            <SingleSelectDropdown<keyof typeof sortFunctions>
              selectedItem={sortItems.find(it => it.id === sortFunctionId)}
              menuItems={sortItems}
              onSelect={item => setSortFunctionId(item.id)}
              bold
              truncateTrigger={false}
            />
          </FiltersContainer>
        </FilteringContainer>
      )}

      <View direction='column' gap='none' role='list'>
        {contentWithLoading}
      </View>
    </View>
  )
}
