import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { postWithUserErrorException } from 'sierra-client/state/api'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { FlexibleContentCollaborator } from 'sierra-client/state/flexible-content/types'
import { RootState } from 'sierra-client/state/types'
import { LiveSessionWithParticipants } from 'sierra-domain/api/admin'
import { CreateContentId, LiveContentId, LiveRoomId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { FolderId } from 'sierra-domain/flexible-content/identifiers'
import {
  XRealtimeAuthorLiveSessionsListLiveSessions,
  XRealtimeAuthorLiveSessionsUpsertLiveSession,
} from 'sierra-domain/routes'

type ThunkAPI = { state: RootState }

export const fetchLiveSessionsForFlexibleContent = createAsyncThunk<
  {
    liveContentId: LiveContentId
    liveSessions: LiveSessionWithParticipants[]
  },
  {
    liveContentId: LiveContentId
  },
  ThunkAPI
>('flexible-content/fetch-folder-sessions', async ({ liveContentId }, { dispatch }) => {
  const { liveSessions } = await postWithUserErrorException(
    XRealtimeAuthorLiveSessionsListLiveSessions,
    { flexibleContentId: liveContentId },
    dispatch
  )

  return { liveContentId, liveSessions: _.sortBy(liveSessions, 'data.startTime') }
})

export const fetchLiveSessions = createAsyncThunk<void, { liveContentId: LiveContentId }, ThunkAPI>(
  'flexible-content/fetch-live-sessions',
  ({ liveContentId }, { dispatch, getState }) => {
    const content = selectFlexibleContent(getState(), liveContentId)

    if (content === undefined) {
      throw Error('Flexible content not initiated')
    }

    void dispatch(fetchLiveSessionsForFlexibleContent({ liveContentId }))
  }
)

type InstantLiveInput = {
  title: string
  flexibleContentId: LiveContentId
  folderId: FolderId
  facilitatorIds: UserId[]
}
export const launchInstantLive = createAsyncThunk<
  { liveSessionId: LiveSessionId; liveRoomId?: LiveRoomId },
  InstantLiveInput,
  ThunkAPI
>(
  'flexible-content/launch-instant-live',
  async ({ flexibleContentId, facilitatorIds, title }, { dispatch }) => {
    const startTime = new Date().toISOString()
    const endTime = new Date(Date.now() + 3600 * 1000).toISOString()
    const allowGuestAccess = false
    const learnerLedSession = false
    const enableRecap = true
    const location = undefined
    const hideFromSelfEnrollment = false
    const transcribeSession = false
    const disallowEarlyJoin = false
    const allDay = false
    const hideTranscription = false

    console.debug('[launch instant live]')

    const { liveSessionId, liveRoomId } = await postWithUserErrorException(
      XRealtimeAuthorLiveSessionsUpsertLiveSession,
      {
        liveSessionId: undefined,
        participantIds: facilitatorIds,
        data: {
          type: 'scheduled',
          title,
          facilitatorIds,
          flexibleContentId,
          allowGuestAccess,
          learnerLedSession,
          location,
          enableRecap,
          hideFromSelfEnrollment,
          hideTranscription,
          transcribeSession,
          disallowEarlyJoin,
          allDay,
          startTime,
          endTime,
          calendarIntegrationSetting: { type: 'sana' }, // TODO(rsvp): revisit this
          videoCallSetting: { type: 'sana' },
        },
        liveSessionType: 'instant',
      },
      dispatch
    )

    await dispatch(fetchLiveSessions({ liveContentId: flexibleContentId }))

    return { liveSessionId, liveRoomId }
  }
)

type InstantNowInput = {
  title: string
  flexibleContentId: LiveContentId
  folderId: FolderId
  facilitatorIds: UserId[]
}
export const launchInstantNow = createAsyncThunk<
  { liveSessionId: LiveSessionId; liveRoomId?: LiveRoomId },
  InstantNowInput,
  ThunkAPI
>(
  'flexible-content/launch-instant-now',
  async ({ flexibleContentId, facilitatorIds, title }, { dispatch }) => {
    const allowGuestAccess = false
    const learnerLedSession = false
    const enableRecap = true
    const hideFromSelfEnrollment = false
    const transcribeSession = false
    const disallowEarlyJoin = false
    const hideTranscription = false
    const allDay = false

    console.debug('[launch instant live]')

    const { liveSessionId, liveRoomId } = await postWithUserErrorException(
      XRealtimeAuthorLiveSessionsUpsertLiveSession,
      {
        liveSessionId: undefined,
        participantIds: facilitatorIds,
        data: {
          type: 'not-scheduled',
          title,
          facilitatorIds,
          flexibleContentId,
          allowGuestAccess,
          learnerLedSession,
          enableRecap,
          hideFromSelfEnrollment,
          hideTranscription,
          transcribeSession,
          disallowEarlyJoin,
          allDay,
          videoCallSetting: { type: 'none' },
        },
        liveSessionType: 'instant',
      },
      dispatch
    )

    await dispatch(fetchLiveSessions({ liveContentId: flexibleContentId }))

    return { liveSessionId, liveRoomId }
  }
)

export const collaboratorsUpdated = createAction<{
  collaborators: FlexibleContentCollaborator[]
  createContentId: CreateContentId
}>('flexible-content/collaborators-updated')
