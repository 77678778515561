import { useSearch } from '@tanstack/react-router'
import { Atom, useAtomValue, useSetAtom } from 'jotai/index'
import React, { useCallback, useMemo } from 'react'
import { CenteredLoadingContainer } from 'sierra-client/editor/version-history/absolute-centered-spinner'
import { copyHistoryFileIntoEditor } from 'sierra-client/editor/version-history/utils/duplicate-file'
import {
  CardIcon,
  collapsableSidebarStateAtom,
  collapseIconVariants,
  FolderIcon,
  NestedItemList,
  sidebarHasNestedMenuOpenAtom,
} from 'sierra-client/features/collapsable-sidebar'
import { useCreatePageYdoc } from 'sierra-client/hooks/use-create-page-ydoc'
import { useLastDefinedValue } from 'sierra-client/hooks/use-last-defined-value'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { FileContainer } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-file'
import { FolderTitleRow } from 'sierra-client/views/flexible-content/editor/content-sidebar/browse-folder'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { safeGetNode } from 'sierra-domain/editor/operations/y-utilts'
import { FileId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { isDefined } from 'sierra-domain/utils'
import { History, ReplayUpdatesContext, wasFileChanged } from 'sierra-domain/version-history/types'
import { EditableText, Icon, MenuItem } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const StyledEditableText = styled(EditableText)<{ $isCurrentItem: boolean }>`
  color: ${p => token(p.$isCurrentItem ? 'foreground/primary' : 'foreground/secondary')};
`

const MenuContainer = styled.div`
  opacity: 0;
`

const SidebarFileMenu: React.FC<{
  onDuplicateClick: () => void
  container: HTMLElement | null
}> = ({ onDuplicateClick, container }) => {
  const { t } = useTranslation()

  const menuItems: MenuItem<'duplicate'>[] = useMemo(
    () => [
      {
        id: 'duplicate',
        type: 'label',
        label: t('version-history.header-duplicate-button'),
        onClick: onDuplicateClick,
      },
    ],
    [onDuplicateClick, t]
  )

  const [isOpen, setIsOpen] = React.useState(false)
  const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)

  return (
    <MenuContainer>
      <IconMenu
        size='small'
        iconId='overflow-menu--vertical'
        menuItems={menuItems}
        variant='transparent'
        container={container}
        isOpen={isOpen}
        onOpenChange={newIsOpen => {
          setSidebarHasNestedMenuOpen(newIsOpen)
          setIsOpen(newIsOpen)
        }}
        onSelect={() => {}}
      />
    </MenuContainer>
  )
}
const OpaqueIcon = styled(Icon).attrs({ color: 'foreground/primary' })`
  opacity: 0.4;
  right: 9px;
  position: absolute;
  pointer-events: none;
`

const StyledFileContainer = styled(FileContainer)`
  cursor: pointer;

  &:hover,
  &:focus-within {
    ${MenuContainer} {
      opacity: 1;
    }
  }

  &:hover,
  &:focus-within {
    ${OpaqueIcon} {
      opacity: 0;
    }
  }
`

const SidebarFile: React.FC<{
  file: File
  state: ReplayUpdatesContext & { type: 'loaded' }
  mode: ContentType
  contentId: CreateContentId
  copyHistoryFile?: (fileId: FileId) => void
  isCurrentItem: boolean
}> = ({ file, state, mode, contentId, copyHistoryFile, isCurrentItem }) => {
  const currentYDoc = state.currentYDoc
  const getFileTitle = useGetFileTitle(currentYDoc)
  const sidebarState = useAtomValue(collapsableSidebarStateAtom)

  const fileWasChanged = useMemo(() => {
    return wasFileChanged(state.updateDiff, file.id)
  }, [file.id, state.updateDiff])

  const subPath = ScopedCreateContentId.urlSubPath(mode)
  const search = useSearch({ strict: false })
  const handleOnClick = useStableFunction((): void => {
    const href = `/history/${subPath}/${contentId}/${file.id}`
    const router = getGlobalRouter()
    void router.navigate({
      to: href,
      search,
    })
  })

  const onDuplicateClick = useCallback(() => {
    copyHistoryFile?.(file.id)
  }, [copyHistoryFile, file.id])

  const [container, setContainer] = React.useState<HTMLElement | null>(null)

  return (
    <StyledFileContainer
      $isHighlighted={fileWasChanged}
      $isCurrentItem={isCurrentItem}
      onClick={handleOnClick}
      ref={setContainer}
    >
      <CardIcon isCurrentItem={isCurrentItem} />

      <View overflow='hidden' grow>
        <StyledEditableText
          bold
          $isCurrentItem={isCurrentItem}
          contentEditable={false}
          setContentEditable={() => {}}
          value={getFileTitle(file)}
          onRename={() => {}}
        />
      </View>
      {file.data.type !== 'notepad' && (
        <View animated variants={collapseIconVariants} animate={sidebarState}>
          {fileWasChanged && <OpaqueIcon iconId='edit' />}
          {isDefined(copyHistoryFile) && (
            <SidebarFileMenu onDuplicateClick={onDuplicateClick} container={container} />
          )}
        </View>
      )}
    </StyledFileContainer>
  )
}

const SidebarModule: FCC<{
  folder: Folder
  currentCardId: NodeId | undefined
}> = ({ children, folder, currentCardId }) => {
  const isCurrentCardInFolder = isDefined(currentCardId) && folder.nodeIds.includes(currentCardId)

  return (
    <>
      {folder.id !== 'folder:root' && (
        <FolderTitleRow>
          <FolderIcon
            isCompleted={false}
            numberOfContent={folder.nodeIds.length}
            isCurrentItem={isCurrentCardInFolder}
          />
          <StyledEditableText
            $isCurrentItem={isCurrentCardInFolder}
            contentEditable={false}
            setContentEditable={() => {}}
            value={folder.title}
            onRename={() => {}}
          />
        </FolderTitleRow>
      )}

      {children}
    </>
  )
}

const VersionHistoryLeftSidebarInner: React.FC<{
  nodeId: NodeId
  context: ReplayUpdatesContext & { type: 'loaded' }
  selectedNode: NodeId | undefined
  mode: ContentType
  scopedCreateContentId: ScopedCreateContentId
  history: History
  createPageYDoc: ReturnType<typeof useCreatePageYdoc>
}> = ({ nodeId, history, createPageYDoc, scopedCreateContentId, context, ...props }) => {
  const currentYDoc = context.currentYDoc
  const userId = useSelector(selectUserId)

  const node = useMemo(() => safeGetNode(currentYDoc, nodeId), [currentYDoc, nodeId])

  const copyHistoryFile = useStableFunction((fileId: FileId): void => {
    const contentType = ScopedCreateContentId.contentType(scopedCreateContentId)
    if (createPageYDoc !== undefined && userId !== undefined) {
      copyHistoryFileIntoEditor({
        editorYdoc: createPageYDoc.yDoc,
        historyYdoc: context.currentYDoc,
        historyFileId: fileId,
        isSelfpacedContent: contentType === 'self-paced',
        userId,
        scopedCreateContentId,
      })
    }
  })
  const contentId = ScopedCreateContentId.extractId(scopedCreateContentId)

  if (node === undefined) return <p>Node {nodeId} not found</p>
  if (node.type === 'folder') {
    return (
      <SidebarModule folder={node} currentCardId={props.selectedNode}>
        {node.id !== 'folder:root' ? (
          <NestedItemList open>
            {node.nodeIds.map(id => (
              <VersionHistoryLeftSidebarInner
                createPageYDoc={createPageYDoc}
                scopedCreateContentId={scopedCreateContentId}
                context={context}
                history={history}
                key={id}
                {...props}
                nodeId={id}
              />
            ))}
          </NestedItemList>
        ) : (
          node.nodeIds.map(id => (
            <VersionHistoryLeftSidebarInner
              createPageYDoc={createPageYDoc}
              scopedCreateContentId={scopedCreateContentId}
              context={context}
              history={history}
              key={id}
              {...props}
              nodeId={id}
            />
          ))
        )}
      </SidebarModule>
    )
  } else if (node.type === 'file') {
    return (
      <SidebarFile
        file={node}
        copyHistoryFile={copyHistoryFile}
        contentId={contentId}
        mode={props.mode}
        state={context}
        isCurrentItem={node.id === props.selectedNode}
      />
    )
  } else {
    return (
      <p>
        Node type {node.type} not supported. Id: ${nodeId}{' '}
      </p>
    )
  }
}

const RelativeContainer = styled.div`
  position: relative;
`

export const VersionHistoryLeftSidebar: React.FC<{
  nodeId: NodeId
  contextAtom: Atom<ReplayUpdatesContext>
  selectedNode: NodeId | undefined
  mode: ContentType
  scopedCreateContentId: ScopedCreateContentId
  history: History
  createPageYDoc: ReturnType<typeof useCreatePageYdoc>
}> = props => {
  const context = useAtomValue(props.contextAtom)
  const lastLoadedContext = useLastDefinedValue<(ReplayUpdatesContext & { type: 'loaded' }) | undefined>(
    context.type === 'loaded' ? context : undefined
  )

  return (
    <RelativeContainer>
      {lastLoadedContext !== undefined && (
        <VersionHistoryLeftSidebarInner context={lastLoadedContext} {...props} />
      )}

      {context.type === 'loading' && <CenteredLoadingContainer />}
    </RelativeContainer>
  )
}
