import { useMemo } from 'react'
import { Logging } from 'sierra-client/core/logging'
import { useDispatch } from 'sierra-client/state/hooks'

export type ProgramTracking = {
  program: {
    step: {
      save: (id: string, type: string, metadata: object) => void
      cancelSave: () => void
      edit: (id: string, type: string, metadata: object) => void
      cancelEdit: (id: string) => void
      dragDrop: (id: string, type: string) => void
      setStaggeredAssignmentTiming: (id: string, type: string, schedule: object) => void
      removed: (id: string, type: string) => void
    }
    section: {
      add: (parameters: { sectionIndex: number }) => void
      remove: (parameters: { sectionIndex: number }) => void
      addStep: (parameters: { sectionIndex: number; collapsed: boolean }) => void
      removeStep: (parameters: { sectionIndex: number }) => void
      ungroup: (parameters: { sectionIndex: number }) => void
      moveSection: (parameters: { sectionIndex: number }) => void
      toggleCollapseSection: (parameters: { sectionIndex: number; collapsed: boolean }) => void
    }
    created: (id: string) => void
    duplicated: (sourceProgramId: string, duplicatedProgramId: string) => void
    deleted: (id: string) => void
  }
  email: {
    sendTestEmail: (templateId?: string) => void
  }
  enrollment: {
    rule: {
      created: (id: string) => void
      deleted: (id: string) => void
      activate: (id: string, active: boolean) => void
    }
  }
  reminder: {
    send: (programId: string, count: number) => void
    tableActionOpen: (id: string) => void
    rowActionOpen: (id: string) => void
    learnMoreClicked: (id: string) => void
    inputClicked: (id: string) => void
  }
}
export const useTracking = (): ProgramTracking => {
  const dispatch = useDispatch()

  return useMemo(
    () => ({
      program: {
        step: {
          save: (id, type, metadata) => dispatch(Logging.program.save({ id, type, metadata })),
          cancelSave: () => dispatch(Logging.program.cancel()),
          edit: (id, type, metadata) => dispatch(Logging.program.modify({ id, type, metadata })),
          cancelEdit: id => dispatch(Logging.program.cancelModifying({ id })),
          dragDrop: (id, type) => dispatch(Logging.program.dragDrop({ id, type })),
          setStaggeredAssignmentTiming: (id, type, schedule) =>
            dispatch(Logging.program.setStaggeredAssignmentTiming({ id, type, schedule })),
          removed: (id, type) => dispatch(Logging.program.removedStep({ id, type })),
        },
        section: {
          add: ({ sectionIndex }) => dispatch(Logging.program.addSection({ sectionIndex })),
          remove: ({ sectionIndex }) => dispatch(Logging.program.removeSection({ sectionIndex })),
          addStep: ({ sectionIndex, collapsed }) =>
            dispatch(Logging.program.addStepToSection({ sectionIndex, collapsed })),
          removeStep: ({ sectionIndex }) => dispatch(Logging.program.removeStepFromSection({ sectionIndex })),
          ungroup: ({ sectionIndex }) => dispatch(Logging.program.unGroupSection({ sectionIndex })),
          moveSection: ({ sectionIndex }) => dispatch(Logging.program.moveSection({ sectionIndex })),
          toggleCollapseSection: ({ sectionIndex, collapsed }) =>
            dispatch(Logging.program.collapseSection({ sectionIndex, collapsed })),
        },
        created: id => dispatch(Logging.program.created({ id })),
        duplicated: (sourceProgramId, duplicatedProgramId) =>
          dispatch(Logging.program.duplicated({ sourceProgramId, duplicatedProgramId })),
        deleted: id => dispatch(Logging.program.deleted({ id })),
      },
      email: {
        sendTestEmail: templateId => dispatch(Logging.email.sendTestEmail({ templateId })),
      },
      enrollment: {
        rule: {
          created: id => dispatch(Logging.enrollment.ruleCreated({ id })),
          deleted: id => dispatch(Logging.enrollment.ruleDeleted({ id })),
          activate: (id, active) => dispatch(Logging.enrollment.ruleActivate({ id, active })),
        },
      },
      reminder: {
        send: (programId, count) => dispatch(Logging.email.sendReminderEmail({ programId, count })),
        tableActionOpen: id => dispatch(Logging.program.reminderTableActionClicked({ id })),
        rowActionOpen: id => dispatch(Logging.program.reminderRowActionClicked({ id })),
        learnMoreClicked: id => dispatch(Logging.program.reminderLearnMoreClicked({ id })),
        inputClicked: id => dispatch(Logging.program.reminderInputClicked({ id })),
      },
    }),
    [dispatch]
  )
}
