import { createFileRoute } from '@tanstack/react-router'

import { useEffect } from 'react'
import { prefetchCurrentUser } from 'sierra-client/api/hooks/use-user'
import { editorAccessed } from 'sierra-client/core/logging/authoring/logger'
import { RequireCoursePermission } from 'sierra-client/core/require-course-permission'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { GlobalNestedBottomComponent, GlobalNestedSidebar } from 'sierra-client/features/global-sidebar'
import { prefetchPermissions } from 'sierra-client/hooks/use-permissions'
import { useRequiredRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { CardPopperManager } from 'sierra-client/routes/create/s/$.route'
import { prefetchCachedQuery } from 'sierra-client/state/api'
import { fetchCourseSettings } from 'sierra-client/state/author-course-settings/actions'
import { useDispatch } from 'sierra-client/state/hooks'
import { store } from 'sierra-client/state/store'
import { CreatePageCombinedContext } from 'sierra-client/views/flexible-content/create-page-context'
import {
  CreatePageGlobalBottomComponent,
  CreatePageGlobalSidebar,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-global-sidebar'
import { CreatePageFull } from 'sierra-client/views/v3-author/create-page-full'
import { CreateContentId, LiveContentId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAuthorLiveSessionsListLiveSessions } from 'sierra-domain/routes'
import { isNotDefined } from 'sierra-domain/utils'
import { z } from 'zod'

function PageWithPermission({ contentId }: { contentId: CreateContentId }): JSX.Element {
  const dispatch = useDispatch()
  const windowLocationHref = new URL(window.location.href)
  const contentLink = `${windowLocationHref.origin}/create/l/${contentId}`

  // Segment logging for editor accessed
  useEffect(() => {
    void dispatch(editorAccessed({ contentId, contentType: 'live', contentLink }))
  }, [contentLink, contentId, dispatch])

  return <CreatePageFull />
}

function Page(): JSX.Element {
  const { contentId } = useRequiredRouterEditorIds()
  return (
    <CreatePageCombinedContext>
      <RequireCoursePermission contentId={contentId} showSpinner>
        <PageWithPermission contentId={contentId} />
      </RequireCoursePermission>

      <CardPopperManager>
        <GlobalNestedSidebar key={contentId} renderSidebar={() => <CreatePageGlobalSidebar />} />
        <GlobalNestedBottomComponent renderBottomContent={() => <CreatePageGlobalBottomComponent />} />
      </CardPopperManager>
    </CreatePageCombinedContext>
  )
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/create/l/$')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    'chat_message': z.string().optional().catch(undefined),
    'request-access': UserId.optional().catch(undefined),
  }),
  loader: ({ context, params }) => {
    if (context.userId === undefined) return

    const [potentialLiveContentId] = (params._splat ?? '').split('/')
    if (isNotDefined(potentialLiveContentId)) return

    const liveContentId = LiveContentId.parse(potentialLiveContentId)
    void store.dispatch(fetchCourseSettings({ courseId: liveContentId }))

    void prefetchPermissions('content-kind', { id: liveContentId })

    void prefetchCachedQuery(XRealtimeAuthorLiveSessionsListLiveSessions, {
      flexibleContentId: liveContentId,
    })

    // The session modal asserts that this response is already cached. We can either remove the
    // assertion or make sure to prefetch it here.
    void prefetchCurrentUser()
  },
})
