import { Duration } from 'luxon'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ContentKind,
  ContentSortAttributeInput,
  ContentTableQuery,
  ContentTableQueryVariables,
  ContentType,
  SortableContentAttribute,
} from 'sierra-client/api/graphql/gql/graphql'
import {
  convertGQLAvatar,
  getAvatarColor,
  getAvatarUrl,
} from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { toGQLSortOrder } from 'sierra-client/api/graphql/util/convert-sort-order'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { DistributionPill } from 'sierra-client/features/multi-tenancy'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  canvasColumn,
  contentsColumn,
  dateTimesColumn,
  numbersColumn,
  stringsColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import * as DL from 'sierra-client/lib/tabular/control/dataloader'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { getContentClassificationData } from 'sierra-client/views/manage/content/utils/content-utils'
import { CourseKind } from 'sierra-domain/api/common'
import { filterEmptyFilters } from 'sierra-domain/filter/transport'
import { assertNever, isNullable, lowercase } from 'sierra-domain/utils'

const contentTable = graphql(`
  query ContentTable(
    $query: String
    $limit: Int
    $cursor: String
    $filter: ContentFilter
    $sortBy: [ContentSortAttributeInput!]
  ) {
    content(
      query: $query
      limit: $limit
      cursor: $cursor
      filter: $filter
      sortBy: $sortBy
      contentTypes: [COURSE, PATH]
    ) {
      cursor
      totalCount
      data {
        ...ExpensiveContentFragment
        ... on NativeCourseGroup {
          courseEditions {
            edition {
              ...ExpensiveContentFragment
            }
          }
          privateCourseEditionsCount
        }
        ... on ScormCourseGroup {
          courseEditions {
            edition {
              ...ExpensiveContentFragment
            }
          }
        }
        ... on Course {
          readOnly
          isDefaultRequiredAssignment
          publishState {
            org {
              name
              squareLogoUrl
              isClusterParent
              domain
            }
            lastPublishedAt
            visibility
          }
        }
      }
    }
  }
`)

type QueryData = ContentTableQuery['content']['data']
type QueryDataRowType = QueryData[number]

const fetchContent = async (params: ContentTableQueryVariables): Promise<ContentTableQuery> =>
  graphQuery(contentTable, params)

export const convertGQLContentEntityType = (type: ContentType): 'course' | 'path' | 'program' => {
  switch (type) {
    case 'COURSE':
      return 'course'
    case 'PATH':
      return 'path'
    case 'PROGRAM':
      return 'program'
    default:
      assertNever(type)
  }
}

export const convertGQLContentKind = (kind: ContentKind): CourseKind | undefined => {
  switch (kind) {
    case 'LINK':
      return 'link'
    case 'LINKEDIN':
      return 'linkedin'
    case 'NATIVE_COURSE_GROUP':
      return 'native:course-group'
    case 'NATIVE_LIVE':
      return 'native:live'
    case 'NATIVE_SELF_PACED':
      return 'native:self-paced'
    case 'SCORM':
      return 'scorm'
    case 'SCORM_COURSE_GROUP':
      return 'scorm:course-group'
    case 'NATIVE_EVENT_GROUP':
      return 'native:event-group'
    default:
      return undefined
  }
}

const contentTypeSortKey = 'CONTENT_TYPE' satisfies SortableContentAttribute
const addedAtSortKey = 'ADDED' satisfies SortableContentAttribute

export type ContentTableDefinition = TableDefinitionOf<
  QueryDataRowType,
  [
    {
      type: 'content'
      // Note: We override this string when handling sorting in the dataloader.
      ref: 'content'
    },
    { type: 'canvas'; ref: 'distribution' },
    { type: 'strings'; ref: typeof contentTypeSortKey },
    { type: 'dateTimes'; ref: typeof addedAtSortKey },
    { type: 'numbers'; ref: 'learners' },
    { type: 'numbers'; ref: 'duration' },
    { type: 'users'; ref: 'owner' },
  ]
>

export type ContentTableData = TableDataFromDefinition<QueryDataRowType, ContentTableDefinition>

const contentToTableData = (
  contentData: QueryData,
  parentId: string | null,
  t: TranslationLookup,
  isClusterParent: boolean
): ContentTableData => {
  const content = []
  const nested: { [key: string]: ContentTableData } = {}

  for (const item of contentData) {
    const c = item
    content.push(c)
    if ('courseEditions' in item) {
      nested[c.contentId] = contentToTableData(
        item.courseEditions.map(e => e.edition) as QueryData,
        c.contentId,
        t,
        isClusterParent
      )
    }
  }

  const definition: ContentTableDefinition = {
    nested: nested,
    rows: { getId: c => c.contentId },
    columns: [
      contentsColumn({
        // NOTE: The remote sort key is "TITLE", not "content".
        // It would make the frontend code too weird if we accessed the
        // content data with row.TITLE, so we will handle this as a special case in
        // the content data loader. We should fix the sorting configuration in the
        // table library (among many other things).
        ref: 'content',
        sortable: true,
        header: translatedLabel('table.name'),
        hints: ['with-nested-row-toggle'],
        getData: c => ({
          id: c.contentId,
          image: convertGQLImage(c.image),
          contentType: convertGQLContentEntityType(c.contentType),
          courseKind: 'courseKind' in c ? convertGQLContentKind(c.courseKind) : undefined,
          title: c.title,
          learnersCount: c.assignmentCount,
          createdAt: 'publishedAt' in c ? c.publishedAt ?? c.createdAt : c.createdAt,
          duration: Duration.fromISO(c.duration).as('seconds'),
          tags: c.tags.map(s => s.id),
          isFeatured: c.featured,
          isCourseEdition: parentId !== null,
          isPublicVisibility:
            c.__typename === 'Program'
              ? false
              : c.__typename === 'Path'
                ? c.isPathVisible
                : c.courseVisibility === 'VISIBLE',
          courseGroupId: parentId ?? undefined,
          readOnly: c.readOnly,
          variant: 'compact',
          isDefaultRequiredAssignment:
            'isDefaultRequiredAssignment' in c ? c.isDefaultRequiredAssignment : false,
        }),
      }),
      canvasColumn({
        ref: 'distribution',
        enabled: isClusterParent,
        sortable: false,
        header: { type: 'translated', key: 'multi-tenancy.content-table.distribution-column.title' },
        getData: c => {
          const id = c.__typename === 'NativeSelfPacedCourse' ? c.courseId : null
          const state = c.__typename === 'NativeSelfPacedCourse' ? c.publishState : null

          return {
            view: isClusterParent ? <DistributionPill courseId={id} publishStates={state} /> : <span />,
            meta: { sorts: () => c.title, exports: () => c.title },
          }
        },
      }),
      stringsColumn({
        ref: contentTypeSortKey,
        sortable: true,
        header: translatedLabel('content-table.course-type'),
        getData: c =>
          t(
            getContentClassificationData({
              contentType: convertGQLContentEntityType(c.contentType),
              courseKind: 'courseKind' in c ? convertGQLContentKind(c.courseKind) : undefined,
              isCourseEdition: parentId !== null,
            }).translationKey
          ),
      }),
      dateTimesColumn({
        ref: addedAtSortKey,
        sortable: true,
        header: translatedLabel('table.added'),
        getData: c => ({ date: new Date(c.createdAt), format: 'time-ago' }),
      }),
      numbersColumn({
        ref: 'learners',
        header: translatedLabel('dictionary.assignments'),
        getData: c => c.assignmentCount,
      }),
      numbersColumn({
        ref: 'duration',
        header: translatedLabel('table.duration'),
        hints: ['duration'],
        getData: c => ('duration' in c ? Duration.fromISO(c.duration).as('seconds') : 0),
      }),
      usersColumn({
        ref: 'owner',
        header: translatedLabel('table.owner'),
        enabled: false,
        sortable: false,
        getData: u => {
          const owner = u.owner
          if (owner === null || owner === undefined) {
            return undefined
          }
          const avatar = convertGQLAvatar(owner.avatar)
          return {
            firstName: owner.firstName ?? '-',
            lastName: owner.lastName ?? '-',
            status: lowercase(owner.status) satisfies UserRep['status'],
            email: owner.email,
            avatar: getAvatarUrl(owner.id, avatar),
            avatarColor: getAvatarColor(avatar),
            id: owner.id,
            isRequiredAssignment: undefined,
          }
        },
      }),
    ],
  }

  return definition2Data(definition, content)
}

export const contentDataLoader = (
  t: TranslationLookup,
  isClusterParent: boolean
): DL.DataLoaderStateMachine<ContentTableData, { cursor: string | null | undefined }> =>
  DL.createDataLoader({
    async fetchInit({ modifier, predicate, control }) {
      const sortBy = modifier.sorting?.map(
        sort =>
          ({
            // todo: fix cast
            key: sort.column === 'content' ? 'TITLE' : (sort.column as SortableContentAttribute),
            order: toGQLSortOrder(sort.direction),
          }) satisfies ContentSortAttributeInput
      )

      const res = await fetchContent({
        sortBy: sortBy ?? [],
        query: predicate.query,
        limit: control.limit,
        filter:
          predicate.filter !== undefined ? JSON.stringify(filterEmptyFilters(predicate.filter)) : undefined,
      })

      return {
        meta: { cursor: res.content.cursor },
        data: res.content.data,
        totalCount: res.content.totalCount,
        done: isNullable(res.content.cursor),
      }
    },

    async fetchMore({ meta, control }) {
      const res = await fetchContent({
        cursor: meta.cursor,
        limit: control.limit,
      })

      return {
        meta: { cursor: res.content.cursor },
        data: res.content.data,
        totalCount: res.content.totalCount,
        done: isNullable(res.content.cursor),
      }
    },

    transformResults(data) {
      return [contentToTableData(data, null, t, isClusterParent)]
    },
  })
