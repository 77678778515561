import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'

import React, { useMemo } from 'react'
import { requireLoggedIn } from 'sierra-client/core/require-logged-in'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { LoadingSpinner } from 'sierra-ui/primitives'
import { FCC } from 'sierra-ui/types'
import styled, { createGlobalStyle } from 'styled-components'
import { z } from 'zod'

const GlobalStyle = createGlobalStyle`
  #app {
    height: 100%;
    width: 100%;
  }
  body {
    overflow: hidden;
  }
`

const FullPage = styled.div`
  height: 100%;
  width: 100%;
`

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
`

function usePdfHref(elementUrlId: string, assetContext: AssetContext): string | undefined {
  const pdfUrlWithoutExtension = useResolveAsset({
    assetContext,
    image: { type: 'file', file: elementUrlId },
    assetType: 'pdf',
    size: 'default',
  })
  const pdfUrlWithExtension = pdfUrlWithoutExtension + '.pdf'

  const pdfUrlQuery = useQuery({
    queryKey: ['pdf', pdfUrlWithoutExtension],
    queryFn: async () => {
      // With cloudinary, we need to load pdfs with the .pdf extension. However,
      // cloudinary is not able to resolve this URL the first time it is requested in some cases,
      // unless we first request the URL without the extension.
      // For most PDFs, it is actually sufficient to load the URL entirely without the
      // extension, but the content-type returned by cloudinary is not always correct in that case.
      try {
        const response = await fetch(pdfUrlWithExtension)
        if (response.status !== 200) {
          throw new Error(`Failed to resolve pdf with extension`)
        }
        return pdfUrlWithExtension
      } catch (e) {
        await fetch(pdfUrlWithoutExtension)
        await fetch(pdfUrlWithExtension)
        return pdfUrlWithExtension
      }
    },
  })

  if (pdfUrlQuery.error) {
    throw pdfUrlQuery.error
  }

  return pdfUrlQuery.data
}

const PDFReader: FCC<{
  elementUrlId: string
  assetContext: AssetContext
}> = ({ elementUrlId, assetContext }) => {
  const pdfHref = usePdfHref(elementUrlId, assetContext)

  return (
    <FullPage>
      {pdfHref === undefined ? (
        <Centered>
          <LoadingSpinner />
          <Debug>
            {
              // eslint-disable-next-line react/jsx-no-literals
              'Loading PDF URL'
            }
          </Debug>
        </Centered>
      ) : (
        <Iframe src={pdfHref} />
      )}
    </FullPage>
  )
}

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const PageComponent: React.FC<{
  courseId: CreateContentId
  elementUrlId: string
}> = ({ courseId, elementUrlId }) => {
  const permission = useContentKindPermissions(courseId)
  /**
   * Verifying that the user has permission to access the course here is done to minimize
   * the risk of accidentally sharing a link to the PDF, not to fully enforce access to the PDF.
   * Access is still possible by directly accessing the PDF URL, or downloading the PDF
   * and sharing it with others, as is the case with any other asset.
   */
  const assetContext = useMemo(() => ({ type: 'course' as const, courseId }), [courseId])

  if (permission.status === 'loading') {
    return (
      <Centered>
        <LoadingSpinner />
        <Debug>
          {
            // eslint-disable-next-line react/jsx-no-literals
            'Loading PDF URL'
          }
        </Debug>
      </Centered>
    )
  } else if (permission.has('LEARN') || permission.has('VIEW_DRAFT')) {
    return <PDFReader elementUrlId={elementUrlId} assetContext={assetContext} />
  } else {
    return <div></div>
  }
}

function Page(): JSX.Element {
  const { courseId, elementUrlId } = Route.useSearch()

  return (
    <>
      <GlobalStyle />
      <PageComponent courseId={courseId} elementUrlId={elementUrlId} />
    </>
  )
}

const RouteComponent = requireLoggedIn(Page)

export const Route = createFileRoute('/pdf/$')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    courseId: CreateContentId,
    elementUrlId: z.string(),
  }),
})
