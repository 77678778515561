import { useMutation } from '@tanstack/react-query'
import { t as todoT } from 'i18next'
import React from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { GoogleCalendarLogo } from 'sierra-client/components/common/logos/google-calendar-logo'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { OutlookLogo } from 'sierra-client/components/common/logos/outlook-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { getDisabledReason, useDisableInput } from 'sierra-client/components/disabling'
import { GoogleMeetOauth } from 'sierra-client/components/sana-now-integration-oauth/google-meet-oauth'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import { ZoomOauth } from 'sierra-client/components/sana-now-integration-oauth/zoom-oauth'
import { config, getFlag } from 'sierra-client/config/global-config'
import {
  useAuthenticatedUserIntegrationsQuery,
  useInvalidateAuthenticatedUserIntegrationsQuery,
} from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { useAvailableIntegrationActions } from 'sierra-client/hooks/use-available-integration-actions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { NotificationHeader } from 'sierra-client/views/user-settings/components/notification-input'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Button, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

function rsvpTodoT(s: string, o?: Record<string, unknown>): string {
  return todoT(s, o)
}

const Divider = styled.div`
  width: 100%;
  background-color: ${token('border/strong')};
  height: 1px;
`

const revokeTeamsIntegrationMutation = graphql(`
  mutation RevokeTeamsIntegration {
    revokeMicrosoftTeamsToken {
      __typename
    }
  }
`)

const revokeMeetIntegrationMutation = graphql(`
  mutation RevokeMeetIntegration {
    revokeGoogleMeetToken {
      __typename
    }
  }
`)

const revokeZoomIntegrationMutation = graphql(`
  mutation RevokeZoomIntegration {
    revokeZoomToken {
      __typename
    }
  }
`)

const ApplicationIcon = styled.div`
  width: 64px;
  height: 64px;
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  border: 0.8px solid ${token('border/strong')};
  background-color: ${token('surface/default')};
  flex-shrink: 0;
`

const IntegrationRow: React.FC<{
  logo: JSX.Element
  title: string
  description: string
  button: JSX.Element
}> = ({ logo, title, description, button }) => (
  <View grow>
    <ApplicationIcon>{logo}</ApplicationIcon>
    <Spacer size='4' />
    <View gap='2' direction='column' grow>
      <Text size='small' bold>
        {title}
      </Text>
      <Text size='small' color='foreground/muted'>
        {description}
      </Text>
    </View>
    <View style={{ flexShrink: 0 }}>{button}</View>
  </View>
)

const IntegrationWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <View
    padding='24'
    justifyContent='space-between'
    gap='16'
    radius='size-20'
    direction='column'
    background='surface/soft'
  >
    {children}
  </View>
)

const NoPointerButton = styled(Button).attrs({
  variant: 'transparent',
  // icon: 'checkmark',
  customDecorator: <Icon iconId='checkbox--checkmark--filled' color='greenBright' />,
})`
  pointer-events: none;
`

const ConnectIntegrationButton: React.FC<{
  clientId: string
  type: 'microsoft' | 'google' | 'zoom'
  isAuthenticated: boolean
  isLoading: boolean
  scope: string[]
}> = ({ type, clientId, isAuthenticated, isLoading, scope }) => {
  const { t } = useTranslation()
  const invalidate = useInvalidateAuthenticatedUserIntegrationsQuery()
  const oauthRef = React.useRef<ReactSimpleOauth2LoginRef | null>(null)
  const disableInputContext = useDisableInput()

  return (
    <>
      {isAuthenticated ? (
        <NoPointerButton>{t('search.integrations.connected')}</NoPointerButton>
      ) : (
        <>
          <Button
            variant='secondary'
            loading={isLoading}
            onClick={() => {
              oauthRef.current?.onBtnClick()
            }}
            disabledWithReason={getDisabledReason(disableInputContext)}
          >
            {t('settings.live-meeting-tools-button--connect')}
          </Button>

          {iife(() => {
            switch (type) {
              case 'microsoft':
                return (
                  <MicrosoftTeamsOauth
                    ref={oauthRef}
                    clientId={clientId}
                    onSuccess={invalidate}
                    scope={scope}
                  />
                )
              case 'google':
                return (
                  <GoogleMeetOauth ref={oauthRef} clientId={clientId} onSuccess={invalidate} scope={scope} />
                )
              case 'zoom':
                return <ZoomOauth ref={oauthRef} clientId={clientId} onSuccess={invalidate} scope={scope} />
              default:
                assertNever(type)
            }
          })}
        </>
      )}
    </>
  )
}

type IntegrationProps = {
  isEnabled: boolean
  isAuthenticated: boolean
  scope: string[]
}

const GoogleIntegrationView: React.FC<{
  clientId: string
  gcal: IntegrationProps
  meet: IntegrationProps
}> = ({ clientId, meet, gcal }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeGoogleIntegration = useMutation({
    mutationFn: () => graphQuery(revokeMeetIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })
  const disableInputContext = useDisableInput()

  return (
    <IntegrationWrapper>
      {meet.isEnabled && (
        <IntegrationRow
          logo={<MeetLogo width={32} height={32} />}
          title={t('dictionary.google-meet')}
          description={t('settings.live-meeting-tools-provider-subtitle--meet')}
          button={
            <ConnectIntegrationButton
              type='google'
              isAuthenticated={meet.isAuthenticated}
              isLoading={revokeGoogleIntegration.isPending}
              clientId={clientId}
              scope={meet.scope}
            />
          }
        />
      )}

      {gcal.isEnabled && (
        <IntegrationRow
          logo={<GoogleCalendarLogo width={32} height={32} />}
          title={rsvpTodoT('Google Calendar')}
          description={rsvpTodoT('Enable syncing of calendar events from Sana to your Google Calendar.')}
          button={
            <ConnectIntegrationButton
              type='google'
              isAuthenticated={gcal.isAuthenticated}
              isLoading={revokeGoogleIntegration.isPending}
              clientId={clientId}
              scope={gcal.scope}
            />
          }
        />
      )}

      {(meet.isAuthenticated || gcal.isAuthenticated) && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeGoogleIntegration.isPending}
              onClick={() => {
                revokeGoogleIntegration.mutate()
              }}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('dictionary.remove')}
            </Button>
          </View>
        </>
      )}
    </IntegrationWrapper>
  )
}

const MicrosoftIntegrationView: React.FC<{
  clientId: string
  outlook: IntegrationProps
  teams: IntegrationProps
}> = ({ clientId, outlook, teams }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeTeamsIntegration = useMutation({
    mutationFn: () => graphQuery(revokeTeamsIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })
  const disableInputContext = useDisableInput()

  return (
    <IntegrationWrapper>
      {teams.isEnabled && (
        <IntegrationRow
          logo={<TeamsLogo width={32} height={32} />}
          title={t('dictionary.microsoft-teams')}
          description={t('settings.live-meeting-tools-provider-subtitle--teams')}
          button={
            <ConnectIntegrationButton
              type='microsoft'
              isAuthenticated={teams.isAuthenticated}
              isLoading={revokeTeamsIntegration.isPending}
              clientId={clientId}
              scope={teams.scope}
            />
          }
        />
      )}

      {outlook.isEnabled && (
        <IntegrationRow
          logo={<OutlookLogo width={32} height={32} />}
          title={rsvpTodoT('Microsoft Outlook')}
          description={rsvpTodoT('Enable syncing of calendar events from Sana to your Microsoft Calendar.')}
          button={
            <ConnectIntegrationButton
              type='microsoft'
              isAuthenticated={outlook.isAuthenticated}
              isLoading={revokeTeamsIntegration.isPending}
              clientId={clientId}
              scope={outlook.scope}
            />
          }
        />
      )}

      {(teams.isAuthenticated || outlook.isAuthenticated) && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeTeamsIntegration.isPending}
              onClick={() => {
                revokeTeamsIntegration.mutate()
              }}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('dictionary.remove')}
            </Button>
          </View>
        </>
      )}
    </IntegrationWrapper>
  )
}

const ZoomIntegrationView: React.FC<{
  clientId: string
  isAuthenticated: boolean
  scope: string[]
}> = ({ clientId, isAuthenticated, scope }) => {
  const { t } = useTranslation()
  const invalidateAuthenticatedIntegrations = useInvalidateAuthenticatedUserIntegrationsQuery()
  const revokeZoomIntegration = useMutation({
    mutationFn: () => graphQuery(revokeZoomIntegrationMutation, {}),
    onSuccess: () => {
      void invalidateAuthenticatedIntegrations()
    },
  })
  const disableInputContext = useDisableInput()

  return (
    <IntegrationWrapper>
      <IntegrationRow
        logo={<ZoomLogo width={32} height={32} />}
        title={'Zoom'}
        description={t('settings.live-meeting-tools-provider-subtitle--zoom')}
        button={
          <ConnectIntegrationButton
            type='zoom'
            isAuthenticated={isAuthenticated}
            isLoading={revokeZoomIntegration.isPending}
            clientId={clientId}
            scope={scope}
          />
        }
      />

      {isAuthenticated && (
        <>
          <Divider />
          <View grow direction='row' justifyContent='flex-end' alignSelf='flex-end'>
            <Button
              variant='destructive'
              loading={revokeZoomIntegration.isPending}
              onClick={() => {
                revokeZoomIntegration.mutate()
              }}
              disabledWithReason={getDisabledReason(disableInputContext)}
            >
              {t('dictionary.remove')}
            </Button>
          </View>
        </>
      )}
    </IntegrationWrapper>
  )
}

export const UserIntegrationSettingsForm: React.FC = () => {
  const { t } = useTranslation()
  const { data, isSuccess } = useAuthenticatedUserIntegrationsQuery()
  const availableIntegrations = useAvailableIntegrationActions()

  const meetingToolsSettings = config.organization.settings.meetingToolsSettings
  const meetEnabled = meetingToolsSettings.meetSettings.enabled
  const teamsEnabled = meetingToolsSettings.teamsSettings.enabled
  const zoomEnabled = meetingToolsSettings.zoomSettings.enabled

  const calendarIntegrationSettings = config.organization.settings.calendarIntegrationSettings
  const outlookEnabled = getFlag('rsvp') && calendarIntegrationSettings.type === 'microsoft'
  const gcalEnabled = getFlag('rsvp') && calendarIntegrationSettings.type === 'google'

  if (!isSuccess) return <LoadingSpinner />

  return (
    <View direction='column' alignItems='stretch' gap='16' paddingBottom='32'>
      <NotificationHeader label={t('settings.live-meeting-tools-title')} />
      {isDefined(data.viewer.integrations.google.clientId) && (meetEnabled || gcalEnabled) && (
        <GoogleIntegrationView
          clientId={data.viewer.integrations.google.clientId}
          meet={{
            isEnabled: meetEnabled,
            isAuthenticated: availableIntegrations.google.createMeetingUrl,
            scope: data.viewer.integrations.google.createMeetingUrlScope.map(scope => scope.value),
          }}
          gcal={{
            isEnabled: gcalEnabled,
            isAuthenticated: availableIntegrations.google.createCalendarEvent,
            scope: data.viewer.integrations.google.createGoogleCalendarEventScope.map(scope => scope.value),
          }}
        />
      )}

      {isDefined(data.viewer.integrations.microsoft.clientId) && (outlookEnabled || teamsEnabled) && (
        <MicrosoftIntegrationView
          clientId={data.viewer.integrations.microsoft.clientId}
          teams={{
            isEnabled: teamsEnabled,
            isAuthenticated: availableIntegrations.microsoft.createMeetingUrl,
            scope: data.viewer.integrations.microsoft.createMeetingUrlScope.map(scope => scope.value),
          }}
          outlook={{
            isEnabled: outlookEnabled,
            isAuthenticated: availableIntegrations.microsoft.createMeetingUrl,
            scope: data.viewer.integrations.microsoft.createMicrosoftCalendarEventScope.map(
              scope => scope.value
            ),
          }}
        />
      )}

      {isDefined(data.viewer.integrations.zoom.clientId) && zoomEnabled && (
        <ZoomIntegrationView
          clientId={data.viewer.integrations.zoom.clientId}
          isAuthenticated={availableIntegrations.zoom.createMeetingUrl}
          scope={data.viewer.integrations.zoom.createMeetingUrlScope.map(scope => scope.value)}
        />
      )}
    </View>
  )
}
