import _ from 'lodash'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { TabBar, TabContent, TabContentInner, Tabs } from 'sierra-client/components/common/layout/tabs'
import {
  useContentSubjectAssignments,
  UseContentSubjectAssignmentsProps,
} from 'sierra-client/components/common/modals/multi-assign-modal/hooks'
import { CloseIcon } from 'sierra-client/components/common/modals/multi-assign-modal/icons'
import {
  ItemContent,
  ItemList,
  RequiredAssignmentProps,
  SelectedItem,
} from 'sierra-client/components/common/modals/multi-assign-modal/items'
import {
  CourseList,
  ListProps,
  LiveSessionList,
  PathList,
  ProgramList,
  UserGroupList,
  UserList,
} from 'sierra-client/components/common/modals/multi-assign-modal/lists'
import {
  AssignModalSelection,
  ItemType,
  ItemUnion,
  LiveContentAssignmentType,
  SubjectType,
} from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getFlag } from 'sierra-client/config/global-config'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { DueDateDialog } from 'sierra-client/views/manage/components/due-date'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { AssignmentPriority, DueDateGroup } from 'sierra-domain/api/manage'
import { assertNever } from 'sierra-domain/utils'
import { MenuItem, Modal } from 'sierra-ui/components'
import { Button, Spacer, Text } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { DesignToken } from 'sierra-ui/theming/tokens/types'
import { useDeepEqualityMemo } from 'sierra-ui/utils/use-deep-equality-memo'
import styled from 'styled-components'

const ModalContent = styled.div`
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;

  min-width: 65.875rem;
  width: 100%;
  max-width: 65.875rem;

  display: grid;
  grid-template-columns: 1fr 1fr;

  height: calc(90vh - 2rem);
  max-height: calc(90vh - 2rem);
`

const ModalColumn = styled.div<{ backgroundColor?: DesignToken }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(90vh - 2rem);

  background-color: ${p => (p.backgroundColor ? token(p.backgroundColor) : token('surface/default'))};

  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  margin: 0 1.5rem;
  padding: 1.5rem 0;
  z-index: 3;
`

const AssignHeader = styled(ModalHeader)`
  background-color: ${token('surface/default')};
  border-bottom: 1px solid ${token('border/default')};
`

const SelectedHeader = styled(ModalHeader)`
  border-bottom: 1px solid ${token('border/strong')};
`

const SelectedFooter = styled.div<{ $withDropdown?: boolean }>`
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  padding: 1rem 0;
  border-top: 1px solid ${token('border/strong')};

  flex-direction: row;
  justify-content: space-between;
`

const ModalHeading = styled(Text).attrs({
  bold: true,
  center: true,
  size: 'regular',
})``

const Separator = styled.span`
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid ${token('border/default')};
`

const TabContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  flex: auto;

  ${TabBar} {
    position: sticky;
    bottom: 0;
    background-color: ${token('surface/default')};
    z-index: 3;
    border-top: 1px solid ${token('border/default')};
    /* Hack to get positioning of top border correct */
    border-left: 2rem solid ${token('surface/default')};
    border-right: 2rem solid ${token('surface/default')};
  }

  ${TabContent}, ${TabContentInner} {
    height: 100%;
  }
`

export const AssignmentTypeInput: React.FC<{
  subjectType: SubjectType
  autoAssignmentAvailable: boolean
  value: LiveContentAssignmentType
  setValue: (x: LiveContentAssignmentType) => void
}> = ({ subjectType, value, setValue, autoAssignmentAvailable }) => {
  const { t } = useTranslation()

  // TODO: Unify with checkbox and handle user/group differences better.
  const dropdownCopy = useMemo<Record<LiveContentAssignmentType, string>>(
    () => ({
      'self-enroll':
        subjectType === 'user-group'
          ? t('manage.assign-modal.live-assignment-type-label.self-enroll-group')
          : t('manage.assign-modal.live-assignment-type-label.self-enroll-user'),
      'auto-assign': t('manage.assign-modal.live-assignment-type-label.auto-assign'),
      'manual': t('manage.assign-modal.live-assignment-type-label.manual'),
    }),
    [t, subjectType]
  )

  const dropdownItems: MenuItem<LiveContentAssignmentType>[] = useMemo(
    () =>
      _.compact(['manual', 'self-enroll', autoAssignmentAvailable && 'auto-assign']).map(assignmentType => ({
        type: 'label',
        id: assignmentType,
        label: dropdownCopy[assignmentType],
      })),
    [dropdownCopy, autoAssignmentAvailable]
  )

  return (
    <SingleSelectDropdown<LiveContentAssignmentType>
      variant='ghost'
      bold
      grow={false}
      selectedItem={dropdownItems.find(it => it.id === value)}
      menuItems={dropdownItems}
      onSelect={item => setValue(item.id)}
    />
  )
}

const SectionTitle = styled(Text).attrs({
  size: 'small',
  color: 'grey50',
})``

const SelectedSection = styled.div`
  padding-top: 2rem;

  margin: 0 1.5rem;

  &:not(:first-child) {
    border-top: 1px solid ${token('border/strong')};
  }
`

const SelectedItemList = styled(ItemList)`
  border-color: ${token('border/strong')};
`

type PaneConfig<T extends ItemType> = {
  id: ItemType
  title: TranslationKey
  render: (props: ListProps<T>) => JSX.Element
}

type PaneConfigurations = { [K in ItemType]: PaneConfig<K> }

const availablePaneConfigurations: PaneConfigurations = {
  'course': {
    id: 'course',
    title: 'dictionary.course-plural',
    render(props) {
      return <CourseList key='course' {...props} />
    },
  },
  'path': {
    id: 'path',
    title: 'dictionary.path-plural',
    render(props) {
      return <PathList key='path' {...props} />
    },
  },
  'user-group': {
    id: 'user-group',
    title: 'dictionary.group-plural',
    render(props) {
      return <UserGroupList key='user-group' {...props} />
    },
  },
  'program': {
    id: 'program',
    title: 'dictionary.program-plural',
    render(props) {
      return <ProgramList key='program' {...props} />
    },
  },
  'user': {
    id: 'user',
    title: 'dictionary.user-plural',
    render(props) {
      return <UserList key='user' {...props} />
    },
  },
  'live-session': {
    id: 'live-session',
    title: 'content.sessions',
    render(props) {
      return <LiveSessionList key='live-session' {...props} />
    },
  },
}

const itemTypeToTranslationKey: Record<ItemType, TranslationKey> = {
  'course': 'dictionary.course-plural',
  'path': 'dictionary.path-plural',
  'user': 'dictionary.user-plural',
  'user-group': 'dictionary.group-plural',
  'program': 'dictionary.program-plural',
  'live-session': 'content.sessions',
}

// We would like to have more control over which combinations of subjects and panes are allowed.
// As a start, we will restrict ourselves to the following combinations that currently exist in the codebase.
type ModalConfig =
  // | {
  //     // Note: This behaves as subjectType 'course', but it's used for a combination of courses and paths in the product.
  //     subjectType: 'course-or-path'
  //     panes: 'user-and-user-group'
  //     subjectsSupportAssignmentSettings: boolean
  //     autoAssignmentAvailable: boolean
  //   }
  | {
      subjectType: 'course'
      panes: 'user-and-user-group'
      subjectsSupportAssignmentSettings: boolean
      autoAssignmentAvailable: boolean
      showDueDates?: boolean
    }
  | {
      subjectType: 'path'
      panes: 'user-and-user-group'
      subjectsSupportAssignmentSettings: boolean
      autoAssignmentAvailable: boolean
      showDueDates?: boolean
    }
  | {
      subjectType: 'path'
      panes: 'course'
    }
  | {
      subjectType: 'live-session'
      panes: 'user-and-user-group'
    }
  | {
      subjectType: 'user-group'
      panes: 'user'
    }
  | {
      subjectType: 'calendar-event'
      panes: 'user-and-user-group'
    }
  | {
      subjectType: 'program'
      panes: 'user'
    }
  | {
      subjectType: 'user'
      panes: 'course-and-path'
      showDueDates?: boolean
    }
  | {
      subjectType: 'user'
      panes: 'course-and-path-and-live-session'
      showDueDates?: boolean
    }
  | {
      subjectType: 'user'
      panes: 'program'
    }
  | { subjectType: 'user'; panes: 'user-group' }

const paneTypeToPanes = {
  'user-and-user-group': ['user', 'user-group'],
  'user': ['user'],
  'user-group': ['user-group'],
  'course': ['course'],
  'course-and-path': ['course', 'path'],
  'course-and-path-and-live-session': ['course', 'path', 'live-session'],
  'program': ['program'],
} as const satisfies Record<ModalConfig['panes'], ItemType[]>

type PaneType<T extends ModalConfig['panes']> = (typeof paneTypeToPanes)[T][number]

type WithActivePane<T extends ModalConfig> = T extends ModalConfig
  ? T & {
      activePane: PaneType<T['panes']>
      onSave: (selections: (AssignModalSelection & { type: PaneType<T['panes']> })[]) => void | Promise<void>
    }
  : never

export type AssignModalProps = {
  config: WithActivePane<ModalConfig>
  subjects: string[] | string
  title: string
  isOpen: boolean
  onClose: () => void
}

/**
 * Given a config, should we show due dates for the given item type?
 *
 * Currently it is only supported when assigning courses/paths to users/user groups.
 */
const getShouldShowDueDates = (config: ModalConfig, itemType: ItemType): boolean => {
  // Not all configurations support due dates. Additionally, those that support it can disable it.
  if (!('showDueDates' in config && config.showDueDates === true)) return false

  switch (config.subjectType) {
    case 'course':
    case 'path':
      switch (itemType) {
        case 'user-group':
        case 'user':
          return true
        case 'course':
        case 'path':
        case 'live-session':
        case 'program':
          return false
        default:
          assertNever(itemType)
      }
      break
    case 'user': {
      switch (itemType) {
        case 'course':
        case 'path':
          return true
        case 'live-session':
        case 'user-group':
        case 'program':
        case 'user':
          return false
        default:
          assertNever(itemType)
      }
      break
    }
    default:
      assertNever(config)
  }
}

const getIsRequiredProps = ({
  config,
  itemType,
  assignmentPriority,
  setAssignmentPriority,
}: {
  config: ModalConfig
  itemType: ItemType
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
}): RequiredAssignmentProps | undefined => {
  if (!getFlag('required-assignments')) return undefined

  switch (config.subjectType) {
    case 'user':
    case 'user-group':
      switch (itemType) {
        case 'course':
        case 'path':
          return {
            type: 'required-assignment-with-default',
            assignmentPriority,
            setAssignmentPriority,
          }
        case 'program':
          return {
            type: 'required-assignment-without-default',
            assignmentPriority,
            setAssignmentPriority,
          }
        case 'user-group':
        case 'live-session':
        case 'user':
          return undefined
        default:
          assertNever(itemType)
      }
      break
    case 'program':
      return itemType === 'user'
        ? { type: 'required-assignment-without-default', assignmentPriority, setAssignmentPriority }
        : undefined
    case 'course':
    case 'path':
      switch (itemType) {
        case 'user':
        case 'user-group':
          return {
            type: 'required-assignment-with-default',
            assignmentPriority,
            setAssignmentPriority,
          }
        case 'course':
        case 'path':
        case 'program':
        case 'live-session':
          return undefined
        default:
          assertNever(itemType)
      }
      break
    case 'live-session':
    case 'calendar-event':
      return undefined
    default:
      assertNever(config)
  }
}

type SelectionState = {
  [Item in ItemUnion as Item['type']]: Item[]
}

const EMPTY_SELECTION_STATE: SelectionState = {
  'live-session': [],
  'user-group': [],
  'course': [],
  'path': [],
  'program': [],
  'user': [],
}

export const AssignModal = ({ subjects, config, title, isOpen, onClose }: AssignModalProps): JSX.Element => {
  const { t } = useTranslation()

  const subjectType = config.subjectType
  const panes = paneTypeToPanes[config.panes]
  const activePane = config.activePane

  const subjectsSupportAssignmentSettings =
    'subjectsSupportAssignmentSettings' in config ? config.subjectsSupportAssignmentSettings : false

  const autoAssignmentAvailable = 'autoAssignmentAvailable' in config ? config.autoAssignmentAvailable : false

  // Makes sure it's an array because that is how it's used
  const _memoSubjects = useMemo(() => (_.isArray(subjects) ? subjects : [subjects]), [subjects])
  const memoSubjects = useDeepEqualityMemo(_memoSubjects)

  const [tab, setTab] = useState(activePane)
  const [selected, setSelected] = useState<SelectionState>(EMPTY_SELECTION_STATE)

  const allSelected = useMemo(() => _.chain(selected).values().flatten().value(), [selected])
  const [dueDates, setDueDates] = useState<{ id: string; type: ItemType; dueDate: DueDateGroup }[]>([])

  const notif = useNotif()

  const [assignmentPriority, setAssignmentPriority] = useState<
    {
      id: string
      priority: AssignmentPriority
    }[]
  >([])

  const [showDueDateDialog, setShowDueDateDialog] = useState<ItemUnion | undefined>()
  const [debouncedQuery, query, setQuery] = useDebouncedAndLiveState('')
  const canSetContentDueDates = useHasOrganizationPermission('SET_CONTENT_DUE_DATES')

  const [liveAssignmentType, setLiveAssignmentType] = useState<LiveContentAssignmentType>('manual')

  const settingAssignmentTypeAvailable = useMemo(() => {
    switch (subjectType) {
      case 'user':
      case 'program':
        return allSelected.some(
          x =>
            // We can't check if the path contains live courses, so we allow this setting for all paths.
            x.type === 'path' ||
            (x.type === 'course' && x.kind === 'native:live') ||
            (x.type === 'course' && x.kind === 'native:event-group')
        )
      case 'course':
      case 'path':
        return subjectsSupportAssignmentSettings
      case 'live-session':
      case 'user-group':
      case 'calendar-event':
        return false
      default:
        assertNever(subjectType)
    }
  }, [subjectsSupportAssignmentSettings, subjectType, allSelected])

  const { alreadyAssigned } = useContentSubjectAssignments({
    subjects: memoSubjects,
    subjectType,
    // todo: fix cast :/
  } as UseContentSubjectAssignmentsProps)

  const contentRef = useRef<HTMLDivElement>(null)
  const dueDateById = useMemo(
    () =>
      _.chain(dueDates)
        .keyBy('id')
        .mapValues(item => item.dueDate)
        .value(),
    [dueDates]
  )

  const selectedIds: Set<string> = useMemo(() => new Set(allSelected.map(item => item.id)), [allSelected])

  const handleSelect = useCallback(
    (item: ItemUnion): void => {
      if (selectedIds.has(item.id)) return // Should not be reachable
      if (item.disabled === true) return // Should not be reachable

      setSelected(currSelected => ({
        ...currSelected,
        [item.type]: currSelected[item.type].concat(item),
      }))
    },
    [selectedIds]
  )

  const handleRemove = useCallback((item: ItemUnion): void => {
    setSelected(currentSelected => ({
      ...currentSelected,
      [item.type]: currentSelected[item.type].filter(it => it.id !== item.id),
    }))

    setDueDates(dds => dds.filter(dd => dd.id !== item.id))
  }, [])

  const handleToggle = useCallback(
    (item: ItemUnion): void => {
      if (selectedIds.has(item.id)) {
        handleRemove(item)
      } else {
        handleSelect(item)
      }
    },
    [selectedIds, handleRemove, handleSelect]
  )

  const _setAssignmentPriority = (item: ItemUnion, value: AssignmentPriority): void => {
    //Error if this function is called but the flag is not set
    if (!getFlag('required-assignments')) {
      notif.push({
        type: 'custom',
        level: 'error',
        body: 'Tried to set required assignments, but the feature is not enabled',
      })
      return
    }

    setAssignmentPriority(current => {
      const existing = current.find(it => it.id === item.id)
      if (existing) {
        return current.map(it => (it.id === item.id ? { ...it, priority: value } : it))
      } else {
        return [...current, { id: item.id, priority: value }]
      }
    })
  }

  const handleSave = (): void => {
    // const allSelectedIds = allSelected.map(s => s.id)
    // const dueDatesSelection = dueDates.filter(d => allSelectedIds.includes(d.id)) // Makes sure we only send due dates for what is actually selected
    const dueDateById = _.chain(dueDates)
      .keyBy('id')
      .mapValues(i => i.dueDate)
      .value()

    function mapAssignment<T extends ItemUnion>(item: T): AssignModalSelection & { type: T['type'] } {
      const includeAssignmentType =
        settingAssignmentTypeAvailable &&
        (item.type === 'user' ||
          item.type === 'user-group' ||
          item.type === 'program' ||
          (item.type === 'course' && item.kind === 'native:live') ||
          (item.type === 'course' && item.kind === 'native:event-group') ||
          // We don't have enough information about the path here.
          item.type === 'path')

      const sharedMeta = {
        dueDate: dueDateById[item.id],
        liveAssignmentType: includeAssignmentType ? liveAssignmentType : undefined,
        assignmentPriority: assignmentPriority.find(it => it.id === item.id)?.priority ?? 'default',
      }

      // These branches are all identical, but we need them for now to keep TypeScript happy
      switch (item.type) {
        case 'course':
          return {
            type: item.type,
            id: item.id,
            isDefaultRequiredAssignment: item.isDefaultRequiredAssignment,
            ...sharedMeta,
          }
        case 'user-group':
          return { type: item.type, id: item.id, ...sharedMeta }
        case 'program':
          return { type: item.type, id: item.id, ...sharedMeta }
        case 'live-session':
          return { type: item.type, id: item.id, isDefaultRequiredAssignment: undefined, ...sharedMeta }
        case 'path':
          return {
            type: item.type,
            id: item.id,
            isDefaultRequiredAssignment: item.isDefaultRequiredAssignment,
            ...sharedMeta,
          }
        case 'user':
          return { type: item.type, id: item.id, ...sharedMeta }
        default:
          assertNever(item)
      }
    }

    const userAssignments = selected.user.map(mapAssignment)
    const userGroupAssignments = selected['user-group'].map(mapAssignment)
    const courseAssignments = selected.course.map(mapAssignment)
    const pathAssignments = selected.path.map(mapAssignment)
    const liveSessionAssignments = selected['live-session'].map(mapAssignment)
    const programAssignments = selected.program.map(mapAssignment)

    switch (config.panes) {
      case 'user-and-user-group':
        void config.onSave([...userAssignments, ...userGroupAssignments])
        break
      case 'course':
        void config.onSave(courseAssignments)
        break
      case 'user':
        void config.onSave(userAssignments)
        break
      case 'course-and-path':
        void config.onSave([...courseAssignments, ...pathAssignments])
        break
      case 'course-and-path-and-live-session':
        void config.onSave([...courseAssignments, ...pathAssignments, ...liveSessionAssignments])
        break
      case 'program':
        void config.onSave(programAssignments)
        break
      case 'user-group':
        void config.onSave(userGroupAssignments)
        break
      default:
        assertNever(config)
    }
  }

  const handleClose = (): void => {
    setSelected(EMPTY_SELECTION_STATE)
    onClose()
  }

  const dueDateDialogType: 'user' | 'group' =
    subjectType === 'user' || showDueDateDialog?.type === 'user' ? 'user' : 'group'
  const paneConfigurations = panes.map(pane => availablePaneConfigurations[pane])

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} size={{ width: 1054, height: 904 }}>
        <ModalContent ref={contentRef}>
          <ModalColumn>
            <AssignHeader>
              <ModalHeading>{title}</ModalHeading>
              <Spacer axis='vertical' size='small' />
              <Separator />
              <Spacer axis='vertical' size='small' />
              <RoundedSearchBar
                value={query}
                onChange={value => setQuery(value)}
                placeholder={t('dictionary.search')}
              />
            </AssignHeader>
            {isOpen && (
              <TabContainer>
                {paneConfigurations.length === 1 ? (
                  paneConfigurations.map(pane => {
                    return pane.render({
                      subjectType,
                      subjects: memoSubjects,
                      selectedIds,
                      handleToggle,
                      searchQuery: debouncedQuery,
                      assignedIds: alreadyAssigned[pane.id],
                    })
                  })
                ) : (
                  <Tabs
                    current={tab}
                    onClick={setTab}
                    small
                    keepMounted
                    tabs={panes
                      .map(paneId => availablePaneConfigurations[paneId])
                      .map(pane => ({
                        id: pane.id,
                        title: t(pane.title),
                        content: pane.render({
                          subjectType,
                          subjects: memoSubjects,
                          selectedIds,
                          handleToggle,
                          searchQuery: debouncedQuery,
                          assignedIds: alreadyAssigned[pane.id],
                        }),
                      }))}
                  />
                )}
              </TabContainer>
            )}
          </ModalColumn>
          <ModalColumn backgroundColor='surface/soft'>
            <SelectedHeader>
              <ModalHeading>{t('modal.selected')}</ModalHeading>
            </SelectedHeader>
            <SelectedItemList>
              {_.entries(selected)
                .filter(entry => entry[1].length > 0)
                .map(([type, group]) => (
                  <SelectedSection key={`section-${type}`}>
                    <SectionTitle>{t(itemTypeToTranslationKey[type as keyof typeof selected])}</SectionTitle>
                    {group.map(item => (
                      <SelectedItem key={item.id} onClick={() => handleRemove(item)}>
                        <ItemContent
                          item={item}
                          onDueDateClick={
                            canSetContentDueDates && getShouldShowDueDates(config, item.type)
                              ? i => setShowDueDateDialog(i)
                              : undefined
                          }
                          dueDate={dueDateById[item.id]}
                          isRequiredProps={getIsRequiredProps({
                            config,
                            itemType: item.type,
                            assignmentPriority:
                              assignmentPriority.find(it => it.id === item.id)?.priority ?? 'default',
                            setAssignmentPriority: (value: AssignmentPriority) =>
                              _setAssignmentPriority(item, value),
                          })}
                        />
                      </SelectedItem>
                    ))}
                  </SelectedSection>
                ))}
            </SelectedItemList>
            <SelectedFooter>
              {settingAssignmentTypeAvailable && (
                <AssignmentTypeInput
                  subjectType={subjectType}
                  autoAssignmentAvailable={
                    selected.course.some(it => it.kind === 'native:live') || autoAssignmentAvailable === true
                  }
                  value={liveAssignmentType}
                  setValue={setLiveAssignmentType}
                />
              )}
              <Button onClick={handleSave} disabled={allSelected.length === 0}>
                {t('dictionary.assign')}
              </Button>
            </SelectedFooter>
          </ModalColumn>
          <CloseIcon onClick={handleClose} />
        </ModalContent>
      </Modal>
      <DueDateDialog
        open={showDueDateDialog !== undefined}
        value={showDueDateDialog !== undefined ? dueDateById[showDueDateDialog.id] : undefined}
        type={dueDateDialogType}
        onClose={() => setShowDueDateDialog(undefined)}
        onChange={dueDate => {
          if (showDueDateDialog === undefined) return
          setDueDates(dd => {
            const without = dd.filter(i => i.id !== showDueDateDialog.id)
            if (dueDate === undefined) return without
            return without.concat({
              id: showDueDateDialog.id,
              type: showDueDateDialog.type,
              dueDate,
            })
          })
          setShowDueDateDialog(undefined)
        }}
      />
    </>
  )
}
