import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Text, View } from 'sierra-ui/primitives'

export const HomeworkTableEmptyState: React.FC = () => {
  const { t } = useTranslation()
  return (
    <View justifyContent='center' padding='large'>
      <Text size='small' color='grey40'>
        {t('manage.homework.table.empty')}
      </Text>
    </View>
  )
}
