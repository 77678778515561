import { UseQueryResult } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { getFlag } from 'sierra-client/config/global-config'
import {
  CachedQueryOptions,
  InvalidateQueries,
  prefetchCachedQuery,
  useCachedQuery,
  useTypedInvalidateQueries,
} from 'sierra-client/state/api'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUserMe } from 'sierra-domain/routes'
import { User } from 'sierra-domain/user'

const gcTime = Infinity
const staleTime = Infinity

export const prefetchCurrentUser = (): Promise<void> => {
  return prefetchCachedQuery(XRealtimeUserMe, {}, { gcTime, staleTime })
}

export const useCurrentUser = <TSelectData = User>(
  options: CachedQueryOptions<User, TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(XRealtimeUserMe, {}, { retry: false, gcTime, staleTime, ...options })
}

export const useCurrentUserId = (): UseQueryResult<UserId, unknown> => {
  return useCurrentUser({ select: data => data.uuid })
}

export const usePreferredTimezone = (): UseQueryResult<User['preferredTimezone']> => {
  const userTimezoneEnabled = getFlag('user-preferred-timezone')

  return useCurrentUser({
    select: data => {
      if (!userTimezoneEnabled) {
        // eslint-disable-next-line no-restricted-syntax
        return DateTime.local().zoneName
      }

      return data.preferredTimezone
    },
  })
}

export const useInvalidateUserCache = (): InvalidateQueries => {
  const invalidate = useTypedInvalidateQueries([XRealtimeUserMe])

  return invalidate
}
