import { useQueryClient } from '@tanstack/react-query'
import { useBlocker, useSearch } from '@tanstack/react-router'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IconListItem } from 'sierra-client/components/common/icon-list'
import { SanaImage } from 'sierra-client/components/common/image'
import { RouterLink } from 'sierra-client/components/common/link'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { TagsBasic } from 'sierra-client/components/common/tags-basic'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { getFlag } from 'sierra-client/config/global-config'
import { useTimeFormatter } from 'sierra-client/core/format'
import { useOrganizationPermissions, usePathPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { removeSearchParams } from 'sierra-client/router/search-params'
import { useDispatch } from 'sierra-client/state/hooks'
import { FlexContainer } from 'sierra-client/views/learner/components/overview/common'
import { EllipsedText } from 'sierra-client/views/learner/components/overview/ellipsed-text'
import {
  BetweenContainer,
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { ContentEnrollmentSection } from 'sierra-client/views/manage/components/content-sections'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { OverviewItem } from 'sierra-client/views/manage/components/overview-item'
import { useManageCourses } from 'sierra-client/views/manage/courses/use-manage-courses'
import { useThrottledCallback } from 'sierra-client/views/manage/hooks/use-throttled-callback'
import { CoursesTable } from 'sierra-client/views/manage/paths/components/courses-table'
import { DeletePathModal, DuplicatePathModal } from 'sierra-client/views/manage/paths/modals'
import { PathSettingsModal } from 'sierra-client/views/manage/paths/path-settings/modal'
import { DEFAULT_PATH_DATA, usePathDetails } from 'sierra-client/views/manage/paths/use-path-details'
import { usePathTranslations } from 'sierra-client/views/manage/paths/use-path-translations'
import { queryKeyTabularContentUsers } from 'sierra-client/views/manage/utils/query-keys'
import { Separator } from 'sierra-client/views/showcase/common'
import { PathData } from 'sierra-domain/api/admin'
import { DueDateAbsolute, DueDateGroup, DueDateSource } from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever } from 'sierra-domain/utils'
import { MenuButton, MenuItem } from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Title = styled(Heading).attrs({
  size: 'h4',
  bold: true,
})``

type PathPageAction =
  | { modal: 'enrollment' }
  | { modal: 'delete' | 'duplicate' | 'courses' }
  | {
      modal: 'due-date-group'
      groupId: string
      currentDueDate?: DueDateGroup
    }
  | {
      modal: 'due-date-user'
      userId: UserId
      currentDueDate?: DueDateAbsolute
      origin?: DueDateSource
    }
  | { modal: 'path-settings' }

type SetPathPageAction = React.Dispatch<React.SetStateAction<PathPageAction | undefined>>

const PathSettingsButton: React.FC<{
  canEditMetadata: boolean
  canCreatePath: boolean
  canDeletePath: boolean
  setAction: SetPathPageAction
}> = ({ canEditMetadata, canCreatePath, canDeletePath, setAction }) => {
  const { t } = useTranslation()

  const primaryProps = canEditMetadata
    ? {
        onPrimaryClick: () => setAction({ modal: 'path-settings' }),
        menuLabel: t('dictionary.more-options'),
      }
    : undefined

  const menuItems: MenuItem<'duplicate' | 'delete'>[] = [
    {
      id: 'duplicate',
      type: 'label',
      label: t('dictionary.duplicate'),
      disabled: !canCreatePath,
    },
    {
      id: 'delete',
      type: 'label',
      label: t('admin.delete'),
      disabled: !canDeletePath,
      color: 'destructive/background',
    },
  ]

  if (primaryProps === undefined && menuItems.every(x => x.disabled === true)) return null

  return (
    <MenuButton
      variant='secondary'
      {...(primaryProps ?? { onPrimaryClick: undefined })}
      menuItems={menuItems}
      onSelect={item => {
        switch (item.id) {
          case 'duplicate':
            setAction({ modal: 'duplicate' })
            return
          case 'delete':
            setAction({ modal: 'delete' })
            return
          default:
            assertNever(item.id)
        }
      }}
    >
      {t('settings.settings')}
    </MenuButton>
  )
}

export const ManagePathDetails: React.FC<{ pathId: PathId }> = ({ pathId }) => {
  const { t } = useTranslation()
  const { timeFormatter } = useTimeFormatter()
  const notifications = useNotif()
  const { setUsersDueDate, assignWithDueDate, setUsersIsRequiredContent } = useDueDate()
  const [queryStringChecked, setQueryStringChecked] = useState(false)
  const pathPermissions = usePathPermissions(pathId)
  const orgPermissions = useOrganizationPermissions()
  const queryClient = useQueryClient()

  // Data from API
  const {
    path,
    fetchPath,
    enrollments,
    pathGroupAssignments,
    isLoading,
    deletePath,
    updatePath,
    duplicatePath,
    fetchUserAssignments,
    fetchGroupAssignments,
    fetchEnrollmentCount,
    unassignFromPath,
    collaborators,
    updateCollaborators,
  } = usePathDetails(pathId)

  const {
    localizationData,
    fetchPathTranslations,
    savePathTranslations,
    isLoading: isTranslationsLoading,
  } = usePathTranslations(pathId)

  const canCreatePath = orgPermissions.has('CREATE_PATH') && getFlag('allow-path-creation')

  const canEditMetadata = pathPermissions.has('EDIT_METADATA')
  const canEditContent = pathPermissions.has('EDIT_CONTENT')
  const canEditVisibleEverywhere = pathPermissions.has('EDIT_VISIBLE_EVERYWHERE')
  const canDeletePath = pathPermissions.has('DELETE')
  const canEditCollaborators = pathPermissions.has('EDIT_COLLABORATORS')
  const canEditAssignments = pathPermissions.has('EDIT_ASSIGNMENTS')
  const dispatch = useDispatch()
  // Local state management
  const [pathDataForm, setPathDataForm] = useState<PathData>(DEFAULT_PATH_DATA)
  const [action, setAction] = useState<PathPageAction | undefined>(undefined)

  const courseIds = useMemo(() => pathDataForm.content.map(({ id }) => id), [pathDataForm.content])
  const { courses } = useManageCourses(courseIds)

  const [unassignAction, setUnassignAction] = useState<{
    modal: 'unassign-users'
    ids: UserId[]
  }>()

  const onRemoveUsers = useCallback((ids: UserId[]) => {
    setUnassignAction({ modal: 'unassign-users', ids })
  }, [])

  const onSetUserDueDate = useCallback(
    (userId: UserId, currentDueDate?: DueDateAbsolute, origin?: DueDateSource) => {
      setAction({ modal: 'due-date-user', userId, currentDueDate, origin })
    },
    []
  )

  const onChangeRequiredAssignments = useCallback(
    async (userIds: UserId[], newIsRequired: boolean) => {
      await setUsersIsRequiredContent(userIds, [
        {
          isRequired: newIsRequired,
          content: { id: pathId, type: 'path' },
        },
      ])
      void queryClient.invalidateQueries({ queryKey: queryKeyTabularContentUsers(pathId) })
    },
    [pathId, queryClient, setUsersIsRequiredContent]
  )

  const blocker = useBlocker({ condition: queryStringChecked && pathDataForm.title.length === 0 })

  const currentPathIdRef = useRef<string>()
  const isNewPath = useSearch({ strict: false, select: params => params.isNewPath === true })

  useEffect(() => {
    // We want to open the settings modal if navigating here by creating a new path.
    if (isNewPath) {
      void removeSearchParams(['isNewPath'])
      setAction({ modal: 'path-settings' })
    }

    setQueryStringChecked(true)
  }, [isNewPath])

  // Initialize form values
  // Resets form data when pathId changes
  useEffect(() => {
    if (path === undefined || pathId === currentPathIdRef.current) return
    setPathDataForm(path)
    currentPathIdRef.current = pathId
  }, [pathId, path])

  // Throttled save
  // Can be triggered with the function, or by modifying pathDataForm
  // TODO(damjan) we don't really need this since courses are rarely changed and other changes are
  //  done in batches with the modal. Still, with `leading: true` we avoid most of the downsides.
  const throttledSave = useThrottledCallback<{ original?: PathData; current: PathData; id: PathId }>({
    intervalMs: 250,
    leading: true,
    data: { original: path, current: pathDataForm, id: pathId },
    memorizedCallback: async ({ original, current, id }) => {
      // Note: Only the first instance of this function is used. Any bound variables
      // used in the function body will not be updated. It's OK in this case since
      // `updatePath` has a stable identity.
      if (current.title.length === 0) return
      if (_.isEqual(original, current)) return
      await updatePath(id, current)
    },
  })

  const updatePathData = (newPathData: Omit<PathData, 'content'>): void => {
    const mergedPathData = {
      ...newPathData,
      content: pathDataForm.content,
    }

    setPathDataForm(mergedPathData)
  }

  const handleContentChange = (courseIds: CourseId[]): void => {
    if (!canEditContent) return

    setPathDataForm({
      ...pathDataForm,
      content: courseIds.map(id => ({ id, type: 'course' })),
    })
  }

  const totalTime = useMemo(() => _.sumBy(courses, course => course.timeTotal ?? 0), [courses])

  const inheritedSkills = useMemo(() => _.uniq(courses.flatMap(c => c.tags)), [courses])

  const handleDeletePath = async (): Promise<void> => {
    throttledSave.cancel()
    await deletePath(pathId)
    await getGlobalRouter().navigate({ to: '/manage/content' })
  }

  const handleAddCoursesSave = (selections: AssignModalSelection[]): void => {
    if (path === undefined) return

    const newCourseIds = [
      ...path.content.map(c => c.id),
      ...selections.filter(item => item.type === 'course').map(item => item.id),
    ]

    handleContentChange(newCourseIds)
    setAction(undefined)
  }

  const assetContext: AssetContext = useMemo(() => ({ type: 'path' as const, pathId: pathId }), [pathId])
  const imageSrc = useResolveAsset({
    image: path?.image,
    assetContext,
    size: 'default',
  })

  if (path === undefined || isLoading || isTranslationsLoading) return null

  return (
    <>
      <PageTitle title={path.title} />

      <ColumnLayout>
        <DetailsHeader
          backlink={{
            href: '/manage/content',
            label: 'manage.backlinks--content',
          }}
        />

        <ManageDetailContainer>
          <ManageDetailGrid>
            <ManageDetailSidebar>
              <SanaImage src={imageSrc} ratio='16:9' rounded />
              <Spacer size='small' />
              {path.title !== '' ? (
                <Title>{path.title}</Title>
              ) : (
                <Title color='foreground/muted'>{t('dictionary.no-title')}</Title>
              )}
              <Spacer size='xxsmall' />
              <View>
                <RouterLink href={`/path/${pathId}`}>
                  <Button variant='secondary'>{t('admin.view')}</Button>
                </RouterLink>
                <PathSettingsButton
                  canEditMetadata={canEditMetadata}
                  canCreatePath={canCreatePath}
                  canDeletePath={canDeletePath}
                  setAction={setAction}
                />
              </View>

              <>
                <Separator />
                <Text size='regular' bold>
                  {t('admin.organization.description')}
                </Text>
                <Spacer size='xxsmall' />
                {path.description !== undefined && path.description !== '' ? (
                  <EllipsedText color='foreground/primary' text={path.description} />
                ) : (
                  <Text size='regular' color='foreground/muted'>
                    {t('dictionary.no-description')}
                  </Text>
                )}
              </>
              <Separator />
              <Text size='regular' bold>
                {t('admin.organization.paths.inherited-skills')}
              </Text>
              <Spacer size='xxsmall' />
              {inheritedSkills.length > 0 ? (
                <TagsBasic
                  tagIds={inheritedSkills}
                  onClick={id => void getGlobalRouter().navigate({ to: `/manage/tags/${id}` })}
                />
              ) : (
                <Text color='foreground/muted' size='small'>
                  {t('manage.paths.no-skills')}
                </Text>
              )}
            </ManageDetailSidebar>
            <ManageDetailContent>
              <Text size='large' bold>
                {t('content.overview')}
              </Text>
              <Spacer size='xsmall' />
              <FlexContainer>
                <OverviewItem
                  data={enrollments !== undefined ? enrollments : 0}
                  label={t('entity.enrollment', { count: enrollments !== undefined ? enrollments : 0 })}
                />
              </FlexContainer>
              <Spacer size='xxlarge' />
              <BetweenContainer>
                <Text size='large' bold>
                  {t('admin.organization.tab.courses')}
                </Text>
                {totalTime > 0 && <IconListItem iconId='time' text={timeFormatter(totalTime) ?? ''} />}
              </BetweenContainer>
              <Spacer size='xsmall' />
              <CoursesTable
                courses={courses}
                openCourseModal={() => setAction({ modal: 'courses' })}
                onChange={updatedCourses => handleContentChange(updatedCourses.map(x => x.courseId))}
                readOnly={!canEditContent}
              />
              <Spacer size='xxlarge' />
              <ContentEnrollmentSection
                assignedCount={enrollments ?? 0}
                assignedPrograms={pathGroupAssignments}
                contentId={pathId}
                openEnrollUsers={() => setAction({ modal: 'enrollment' })}
                onRemoveUser={onRemoveUsers}
                onSetUserDueDate={onSetUserDueDate}
                contentType='path'
                canEditAssignments={canEditAssignments}
                onChangeRequiredAssignments={onChangeRequiredAssignments}
              />
            </ManageDetailContent>
          </ManageDetailGrid>
        </ManageDetailContainer>
        <ManageDetailContainer>
          <Spacer size='xlarge' />

          {action?.modal === 'courses' && (
            <AssignModal
              isOpen
              config={{
                subjectType: 'path',
                panes: 'course',
                activePane: 'course',
                onSave: handleAddCoursesSave,
              }}
              subjects={pathId}
              title={t('manage.groups.assign-content')}
              onClose={() => setAction(undefined)}
            />
          )}
          {action?.modal === 'enrollment' && (
            <AssignModal
              isOpen
              config={{
                subjectType: 'path',
                panes: 'user-and-user-group',
                activePane: 'user',
                showDueDates: true,
                subjectsSupportAssignmentSettings: _.some(
                  courses,
                  course => course.kind === 'native:live' || course.kind === 'native:event-group'
                ),
                autoAssignmentAvailable: _.some(courses, course => course.kind === 'native:live'),
                onSave: async selections => {
                  const result = await assignWithDueDate(
                    parseModalToContentAssignment(
                      [
                        {
                          id: pathId,
                          type: 'path',
                          isDefaultRequiredAssignment: path.settings.defaultRequiredAssignments,
                        },
                      ],
                      selections
                    )
                  )
                  if (result?.error !== undefined) return

                  selections.forEach(selection => {
                    void dispatch(
                      assignmentPriorityLogger({
                        contentType: 'path',
                        assignmentPriority: selection.assignmentPriority,
                        hasDueDate: selection.dueDate !== undefined,
                        contentId: pathId,
                        userId: selection.id,
                      })
                    )
                  })

                  notifications.push({ type: 'assigned' })
                  setAction(undefined)
                  void Promise.all([
                    fetchEnrollmentCount(pathId),
                    fetchUserAssignments(pathId, undefined, { reset: true }),
                    fetchGroupAssignments(pathId),
                  ])
                },
              }}
              subjects={pathId}
              title={t('manage.groups.assign-content')}
              onClose={() => setAction(undefined)}
            />
          )}
          <DeletePathModal
            isOpen={action?.modal === 'delete'}
            onClose={() => setAction(undefined)}
            onDelete={async () => {
              await handleDeletePath()
            }}
          />
          <DuplicatePathModal
            isOpen={action?.modal === 'duplicate'}
            onClose={() => setAction(undefined)}
            onDuplicate={async () => {
              throttledSave.flush()
              const duplicatedPathId = await duplicatePath(pathId)
              await getGlobalRouter().navigate({ to: `/manage/paths/${duplicatedPathId}` })
            }}
          />
          <ActionModal
            open={unassignAction?.modal === 'unassign-users'}
            isLoading={isLoading}
            onClose={() => setUnassignAction(undefined)}
            primaryAction={async (): Promise<void> => {
              if (unassignAction?.modal !== 'unassign-users') return
              await unassignFromPath(pathId, { userIds: unassignAction.ids })
              setUnassignAction(undefined)
              notifications.push({
                type: 'custom',
                level: 'success',
                body: t('notifications.done'),
              })
            }}
            primaryActionLabel={t('dictionary.unassign')}
            title={t('manage.unassign-users.title', { count: unassignAction?.ids.length })}
            deleteAction
          >
            {t('manage.unassign-users.message', { count: unassignAction?.ids.length })}
          </ActionModal>
          <ActionModal
            open={blocker.status === 'blocked'}
            title={t('manage.empty-path')}
            primaryActionLabel={t('manage.delete-path')}
            onClose={blocker.reset}
            primaryAction={async () => {
              blocker.proceed()
              await handleDeletePath()
              notifications.push({ type: 'path-deleted' })
            }}
            deleteAction
          >
            {t('manage.path-name-or-delete')}
          </ActionModal>

          <DueDateDialog
            open={action?.modal === 'due-date-user'}
            onClose={() => setAction(undefined)}
            value={action?.modal === 'due-date-user' ? action.currentDueDate : undefined}
            type='user'
            forceDisableRemove={action?.modal === 'due-date-user' ? action.origin !== 'direct' : undefined}
            onChange={async value => {
              if (action?.modal !== 'due-date-user' || value?.type === 'relative') return

              await setUsersDueDate(
                [action.userId],
                [{ dueDate: value?.date, content: { type: 'path', id: pathId } }]
              )
              void queryClient.invalidateQueries({ queryKey: queryKeyTabularContentUsers(pathId) })

              void fetchUserAssignments(pathId, undefined, { reset: true })
              setAction(undefined)
            }}
          />
          {action?.modal === 'path-settings' && (
            <PathSettingsModal
              pathId={pathId}
              fetchPath={fetchPath}
              localizationData={localizationData}
              fetchPathTranslations={fetchPathTranslations}
              updatePathData={updatePathData}
              savePathTranslations={savePathTranslations}
              onSave={() => {}}
              onClose={() => setAction(undefined)}
              pathDataForm={pathDataForm}
              collaborators={collaborators}
              updateCollaborators={updateCollaborators}
              canEditCollaborators={canEditCollaborators}
              canEditPathVisibility={canEditVisibleEverywhere}
            />
          )}
        </ManageDetailContainer>
      </ColumnLayout>
    </>
  )
}
