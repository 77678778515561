import { DateTime } from 'luxon'
import { apiQueryOptions, ApiQueryOptions } from 'sierra-client/state/api'
import { UserTranscriptResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeUsersUserTranscript } from 'sierra-domain/routes'

function selectWithSortedExternalTrainings(data: UserTranscriptResponse): UserTranscriptResponse {
  return {
    ...data,
    externalTrainings: [...data.externalTrainings].sort(
      (a, b) => DateTime.fromSQL(b.completionDate).valueOf() - DateTime.fromSQL(a.completionDate).valueOf()
    ),
  }
}

export const getUserTranscriptQuery = ({
  userId,
}: {
  userId: UserId
}): ApiQueryOptions<typeof XRealtimeUsersUserTranscript> => {
  return apiQueryOptions(
    XRealtimeUsersUserTranscript,
    { userId },
    {
      staleTime: 10 * 1000, // 10 seconds
      select: selectWithSortedExternalTrainings,
    }
  )
}
