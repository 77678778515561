import { useIsDarkTheme } from 'sierra-client/components/common/color-theme-preference'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GroupProgramDetail } from 'sierra-client/views/manage/components/layout/common/group-program-detail'
import { CoverImageOverlay } from 'sierra-client/views/manage/programs/components/cover-image-in-modal'
import { ProgramId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Button, Spacer, View } from 'sierra-ui/primitives'
import { DarkTokenProvider, LightTokenProvider } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const { Title, Description, LastLine, Left, Actions, CreatedAt } = GroupProgramDetail

type WrapperProps = {
  backgroundImage?: string | undefined
}

const Wrapper = styled(GroupProgramDetail.Wrapper)<WrapperProps>`
  position: relative;
  overflow: hidden;
  ${p =>
    p.onClick !== undefined &&
    css`
      cursor: pointer;
    `}
  ${p =>
    p.backgroundImage !== undefined &&
    css`
      background: center / cover no-repeat url(${p.backgroundImage});
    `}
`

const Inner = styled(View).attrs({ direction: 'column' })`
  position: relative;
`

export const ManageProgramHeader: React.FC<{
  programId: ProgramId
  title: string | undefined
  dateString: string | undefined
  image?: ImageUnion
  onEdit: (() => void) | undefined
}> = ({ programId, title, dateString, image, onEdit }) => {
  const { t } = useTranslation()
  const hasImage = image !== undefined
  const darkmode = useIsDarkTheme()

  const resolvedAssetUrl = useResolveAsset({
    image,
    assetContext: { type: 'program', programId },
    size: 'default',
  })

  const imageUrl = hasImage ? resolvedAssetUrl : undefined

  const TokenContext = darkmode ? DarkTokenProvider : hasImage ? DarkTokenProvider : LightTokenProvider

  return (
    <TokenContext>
      <Wrapper background='surface/soft' backgroundImage={imageUrl} onClick={onEdit}>
        {hasImage && <CoverImageOverlay />}
        <Inner>
          <Spacer size='144' />
          <Title>{title}</Title>
          <LastLine>
            <Left>
              <Description>
                <CreatedAt dateString={dateString} />
              </Description>
            </Left>
            <Actions>
              {onEdit !== undefined && (
                <Button icon='settings' variant='ghost' onClick={onEdit}>
                  {t('dictionary.settings')}
                </Button>
              )}
            </Actions>
          </LastLine>
        </Inner>
      </Wrapper>
    </TokenContext>
  )
}
