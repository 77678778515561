import React from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { EventGroupPageListItem_ItemFragment } from 'sierra-client/api/graphql/gql/graphql'
import { SanaImage } from 'sierra-client/components/common/image'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CalendarEventEnrollmentRequestButton,
  CalendarEventInfo,
} from 'sierra-client/views/learner/event-group/calendar-event-enrollment-request-button'
import { getListItemEnrollmentStatus } from 'sierra-client/views/learner/event-group/get-list-item-enrollment-status'
import {
  formatEventSchedule,
  gqlEventLocationString,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { isNonNullable } from 'sierra-domain/utils'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ListItemImageContainer = styled.div`
  flex-basis: 80px;
  flex-shrink: 0;
`

const EventDescriptionContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`

const DescriptionText = styled(Text)`
  white-space: pre-wrap;
`

export const eventGroupPageListItemfragment = graphql(`
  fragment EventGroupPageListItem_item on CalendarEvent {
    id
    schedule {
      ...CalendarEventScheduleFragment
    }
    location {
      ...CalendarEventLocationFragment
    }
    userAssignment {
      __typename
    }
    description
    title
    seatsRemaining
    approverSetting
    userEnrollmentRequest {
      requestId
      createdAt
      requestStatus
      requester {
        id
        displayName
        avatar {
          ...AvatarFragment
        }
      }
    }
  }
`)

export const EventGroupPageListItem: React.FC<{
  item: EventGroupPageListItem_ItemFragment
  eventGroupTitle: string
  image?: ImageUnion
  assetContext: AssetContext
  itemChanged: () => void
  selfEnrollEnabled: boolean
}> = props => {
  const { t } = useTranslation()
  const item = props.item
  const noSeatsLeft = isNonNullable(item.seatsRemaining) && item.seatsRemaining <= 0
  const eventSchedule = gqlEventScheduleToEventSchedule(item.schedule)

  const requiresApproval = isNonNullable(item.approverSetting)
  const currentRequestStatus = isNonNullable(item.userEnrollmentRequest)
    ? item.userEnrollmentRequest.requestStatus
    : undefined
  const selfEnrollAvailable = props.selfEnrollEnabled && !noSeatsLeft

  const enrollmentStatus = getListItemEnrollmentStatus({
    isEnrolled: item.userAssignment?.__typename === 'CalendarEventAssignment',
    currentRequestStatus,
    requiresApproval,
    selfEnrollAvailable,
  })

  const resolvedImageUrl = useResolveAsset({
    image: props.image,
    assetContext: props.assetContext,
    size: 'course-sm',
  })

  const calendarEventInfo: CalendarEventInfo = {
    left: item.title,
    imageUrl: resolvedImageUrl,
    right: formatEventSchedule(eventSchedule),
  }

  if (!isNonNullable(eventSchedule)) {
    console.warn('Failed to get event schedule from event, this should not happen')
    return null
  }

  return (
    <View alignItems='flex-start' gap='none'>
      <ListItemImageContainer>
        <SanaImage src={resolvedImageUrl} ratio='16:9' rounded radius='size-8' />
      </ListItemImageContainer>
      <Spacer size='14' />
      <EventDescriptionContainer>
        <View>
          <Text bold>
            {formatEventSchedule(eventSchedule, { month: 'long' })}
            {item.title !== props.eventGroupTitle && `, ${item.title}`}
          </Text>
        </View>
        <Spacer size='4' />
        <Text color='foreground/muted'>{gqlEventLocationString(item.location)}</Text>
        {isNonNullable(item.description) && item.description.length > 0 && (
          <>
            <Spacer size='4' />
            <DescriptionText color='foreground/muted'>
              <FreeTextEditor content={item.description} useHtml editable={false} />
            </DescriptionText>
          </>
        )}
      </EventDescriptionContainer>

      <View marginLeft='auto' gap='none'>
        {isNonNullable(item.seatsRemaining) &&
          (item.seatsRemaining > 0 ? (
            <Text color='foreground/muted'>
              {t('course-detail.free-spots', { count: item.seatsRemaining })}
            </Text>
          ) : (
            <Text color='foreground/muted'>{t('course-detail.session-full')}</Text>
          ))}
        <Spacer size='12' />
        <CalendarEventEnrollmentRequestButton
          calendarEventId={item.id}
          calendarEventInfo={calendarEventInfo}
          enrollmentStatus={enrollmentStatus}
          itemChanged={props.itemChanged}
        />
      </View>
    </View>
  )
}
