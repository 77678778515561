import { getColumnBuilder } from 'sierra-client/lib/tabular/column-definitions/util'

export const addStepContentsColumn = getColumnBuilder('addStepContent')
export const assignedViaColumn = getColumnBuilder('assignedVia')
export const canvasColumn = getColumnBuilder('canvas')
export const certificateIssuedByColumn = getColumnBuilder('certificateIssuedBy')
export const certificatesColumn = getColumnBuilder('certificates')
export const contentsColumn = getColumnBuilder('content')
export const dateTimesColumn = getColumnBuilder('dateTimes')
export const detailedContentsColumn = getColumnBuilder('detailedContents')
export const enrolledBysColumn = getColumnBuilder('enrolledBys')
export const eventGroupsColumn = getColumnBuilder('eventGroups')
export const expiryTimesColumn = getColumnBuilder('expiryTimes')
export const homeworksColumn = getColumnBuilder('homeworks')
export const issuedCertificatesColumn = getColumnBuilder('issuedCertificates')
export const issuesRevokedColumn = getColumnBuilder('issuesRevoked')
export const linksColumn = getColumnBuilder('links')
export const liveSessionColumn = getColumnBuilder('liveSession')
export const numbersColumn = getColumnBuilder('numbers')
export const pillsColumn = getColumnBuilder('pills')
export const programVersionNumbersColumn = getColumnBuilder('programVersionNumbers')
export const progressColumn = getColumnBuilder('progress')
export const singleSelectColumn = getColumnBuilder('singleSelect')
export const skillContentsColumn = getColumnBuilder('skillContent')
export const stringsColumn = getColumnBuilder('strings')
export const assignedProgramColumn = getColumnBuilder('assignedProgram')
export const titlesAndSubtitles = getColumnBuilder('titlesAndSubtitles')
export const usersColumn = getColumnBuilder('users')
export const userStackColumn = getColumnBuilder('userStacks')
