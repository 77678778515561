import { useRef } from 'react'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TableWrapper = styled(View)`
  max-height: 27rem;
  overflow-y: auto;
`

export const UserDetailsTabularTable: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}
