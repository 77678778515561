import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { UseLiveSessionDetailsData } from 'sierra-client/views/manage/live-session/use-live-session-details'
import { liveSessionUsersQueryKey } from 'sierra-client/views/manage/live-session/util/query-keys'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'

export type LiveSessionModalActions =
  | { modal: 'enroll-users' }
  | { modal: 'unassign-users'; ids: UserId[] }
  | { modal: 'remove-recording'; id: string }

export type LiveSessionActionModalControl = {
  open: (modalState: LiveSessionModalActions | undefined) => void
  close: () => void
  state: LiveSessionModalActions | undefined
}

export const useLiveSessionModalActions = (): LiveSessionActionModalControl => {
  const [action, setAction] = useState<LiveSessionModalActions | undefined>()

  return {
    open: setAction,
    close: () => setAction(undefined),
    state: action,
  }
}

type Props = {
  control: LiveSessionActionModalControl
  liveSessionId: LiveSessionId
  liveSessionDetails: UseLiveSessionDetailsData
}

export const LiveSessionModalActions: React.FC<Props> = ({
  control: { state, close },
  liveSessionId,
  liveSessionDetails,
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { unassignUsers, removeRecording, fetchLiveSession, fetchRecordings } = liveSessionDetails
  const { assignWithDueDate } = useDueDate()
  const notif = useNotif()

  switch (state?.modal) {
    case 'unassign-users':
      return (
        <ActionModal
          open
          onClose={() => close()}
          primaryAction={async (): Promise<void> => {
            await unassignUsers(liveSessionId, state.ids)
            notif.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
            void queryClient.invalidateQueries({ queryKey: liveSessionUsersQueryKey(liveSessionId) })
          }}
          primaryActionLabel={t('dictionary.unassign')}
          title={t('manage.unassign-users.title', {
            count: state.ids.length,
          })}
          deleteAction
        >
          {t('manage.unassign-users.message', {
            count: state.ids.length,
          })}
        </ActionModal>
      )
    case 'remove-recording':
      return (
        <ActionModal
          open
          onClose={() => close()}
          primaryAction={async (): Promise<void> => {
            await removeRecording(state.id)
            void fetchRecordings(liveSessionId)
            notif.push({
              type: 'custom',
              level: 'success',
              body: t('notifications.done'),
            })
          }}
          primaryActionLabel={t('content.delete-button')}
          title='Delete recording'
          deleteAction
        >
          {t('manage.live-session.delete-recording.prompt')}
        </ActionModal>
      )
    case 'enroll-users':
      return (
        <AssignModal
          isOpen
          config={{
            subjectType: 'live-session',
            panes: 'user-and-user-group',
            activePane: 'user',
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment(
                  [{ id: liveSessionId, type: 'live-session', isDefaultRequiredAssignment: undefined }],
                  selections
                )
              )
              if (result?.error !== undefined) return

              close()
              void fetchLiveSession(liveSessionId)
              void queryClient.invalidateQueries({ queryKey: liveSessionUsersQueryKey(liveSessionId) })
            },
          }}
          subjects={liveSessionId}
          title={t('dictionary.enrollments')}
          onClose={() => close()}
        />
      )
    default:
      return null
  }
}
