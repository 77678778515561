import React, { forwardRef } from 'react'
import LogoSVG from 'sierra-client/assets/images/logo.svg'
import styled from 'styled-components'

interface WrapProps {
  $width?: number
  $height?: number
}

const Wrap = styled.div<WrapProps>`
  display: flex;
  align-items: center;

  & svg {
    display: block;
    width: ${props => props.$width ?? 32}px;
    height: ${props => props.$height ?? 32}px;
    color: ${p => p.theme.text.default};
  }
`

interface SanaLogoProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: number
  height?: number
}

export const SanaLogo = forwardRef<HTMLDivElement, SanaLogoProps>(
  ({ width, height, ...props }, ref): JSX.Element => (
    <Wrap {...props} ref={ref} $width={width} $height={height}>
      <LogoSVG />
    </Wrap>
  )
)
