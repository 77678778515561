import React, { forwardRef, useMemo } from 'react'
import { useCurrentUser } from 'sierra-client/api/hooks/use-user'
import { OutlookLogo } from 'sierra-client/components/common/logos/outlook-logo'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import { ReactSimpleOauth2LoginRef } from 'sierra-client/components/sana-now-integration-oauth/shared'
import { CalendarIntegrationAuthentication } from 'sierra-client/features/event-integrations'
import { useAuthenticatedUserIntegrationsQuery } from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { useUser } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { CalendarIntegrationSetting } from 'sierra-domain/content/session'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, MenuItem, RoundAvatar } from 'sierra-ui/components'
import { AvatarProps } from 'sierra-ui/components/avatars/round-avatar'
import { Pill, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'
import { useCanCreateCalendarIntegrationEvent } from './use-can-create-calendar-integration-event'

type CalendarIntegrationPickerProps = {
  calendarIntegrationSetting: CalendarIntegrationSetting
  disabled: boolean
  onChange: (calendarIntegrationSetting: CalendarIntegrationSetting) => void
  isValid: boolean
}

const Inline = styled(Text).attrs({ as: 'span', color: 'foreground/secondary' })`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`

type Sender = AvatarProps & { email?: string }

const useLoadSender = (userId?: UserId): Sender | undefined => {
  const user = useUser(userId)

  const userShape: (AvatarProps & { email?: string }) | undefined = useMemo(() => {
    if (user?.status === 'loaded') {
      return {
        firstName: user.firstName,
        lastName: user.lastName,
        color: user.avatarColor,
        src: user.avatar,
        email: user.email,
      }
    }
    return undefined
  }, [user])

  return userShape
}

const useIntegrationOwnerUserId = (
  calendarIntegrationSetting: CalendarIntegrationSetting
): UserId | undefined =>
  useMemo(() => {
    switch (calendarIntegrationSetting.type) {
      case 'microsoft':
        return calendarIntegrationSetting.integrationOwnerSanaUserId
      case 'sana':
      case 'google':
        return undefined
      default:
        assertNever(calendarIntegrationSetting)
    }
  }, [calendarIntegrationSetting])

const StyledPill = styled(Pill).attrs({ variant: 'ghost' })<{
  cursor: 'pointer' | 'default'
}>`
  cursor: ${p => p.cursor};
`

const SenderPill: React.FC<{ sender: Sender }> = ({ sender }) => (
  // We need to send the email related to the token, otherwise we risk displaying the wrong email
  // <Tooltip title={sender.email}>
  <StyledPill cursor='default'>
    <View gap='8'>
      <RoundAvatar size='minuscule' {...sender} />
      <Text bold>
        {sender.firstName} {sender.lastName}
      </Text>
    </View>
  </StyledPill>
  // </Tooltip>
)

const SelectionTrigger = forwardRef<
  HTMLDivElement,
  { open: boolean; disabled: boolean; children: React.ReactNode }
>(({ children, open, disabled, ...props }, ref) => (
  <StyledPill ref={ref} cursor={disabled ? 'default' : 'pointer'} {...props}>
    {children}
    {!disabled && <Icon iconId={open ? 'chevron--up--small' : 'chevron--down--small'} />}
  </StyledPill>
))

const IntegrationSelector: React.FC<CalendarIntegrationPickerProps> = ({
  calendarIntegrationSetting,
  disabled,
  onChange,
}) => {
  const menuItems = [
    {
      type: 'canvas',
      id: 'sana',
      render: ({ onItemClick }) => (
        <View onClick={onItemClick}>
          <SanaLogo width={14} height={14} />
          <Text bold>Sana</Text>
        </View>
      ),
    },
    {
      type: 'canvas',
      id: 'microsoft',
      render: ({ onItemClick }) => (
        <View onClick={onItemClick}>
          <OutlookLogo width={14} height={14} />
          <Text bold>Microsoft Outlook</Text>
        </View>
      ),
    },
    // TOOD(rsvp): Uncomment once available
    // {
    //   type: 'canvas',
    //   id: 'google',
    //   render: ({ onItemClick }) => (
    //     <View onClick={onItemClick}>
    //       <GoogleCalendarLogo width={14} height={14} />
    //       <Text bold>Google Agenda</Text>
    //     </View>
    //   ),
    // },
  ] as const satisfies MenuItem<CalendarIntegrationSetting['type']>[]

  return (
    <SingleSelectDropdown
      disabled={disabled}
      menuItems={menuItems}
      renderTrigger={open => (
        <SelectionTrigger open={open} disabled={disabled}>
          {menuItems.find(item => item.id === calendarIntegrationSetting.type)?.render({})}
        </SelectionTrigger>
      )}
      onSelect={item => {
        switch (item.id) {
          case 'microsoft':
            onChange({ type: 'microsoft' })
            break
          // TOOD(rsvp): Uncomment once available
          // case 'google':
          //   onChange({ type: 'google' })
          //   break
          case 'sana':
            onChange({ type: 'sana' })
            break
        }
      }}
      selectedItem={menuItems.find(item => item.id === calendarIntegrationSetting.type)}
    />
  )
}

export const CalendarIntegrationPicker: React.FC<CalendarIntegrationPickerProps> = props => {
  const { calendarIntegrationSetting } = props
  const microsoftOauthRef = React.createRef<ReactSimpleOauth2LoginRef>()
  const currentUser = useCurrentUser()
  const integrationOwnerUserId = useIntegrationOwnerUserId(calendarIntegrationSetting)
  const sender = useLoadSender(integrationOwnerUserId ?? currentUser.data?.uuid)

  const isLoadingOrIsSanaIntegration = calendarIntegrationSetting.type === 'sana' || sender === undefined

  const authenticatedIntegrationsQuery = useAuthenticatedUserIntegrationsQuery()
  const canCreateEvent = useCanCreateCalendarIntegrationEvent()

  return (
    <>
      <Inline>
        {isLoadingOrIsSanaIntegration ? (
          <Trans
            i18nKey='calendar-integrations.invites-sent'
            components={{
              service: <IntegrationSelector {...props} />,
            }}
          />
        ) : (
          <Trans
            i18nKey='calendar-integrations.invites-sent-via'
            components={{
              service: <IntegrationSelector {...props} />,
              email: <SenderPill sender={sender} />,
            }}
          />
        )}
      </Inline>
      {iife(() => {
        if (canCreateEvent || authenticatedIntegrationsQuery.data === undefined) {
          return null
        }

        switch (calendarIntegrationSetting.type) {
          case 'microsoft': {
            const { clientId, createMicrosoftCalendarEventScope } =
              authenticatedIntegrationsQuery.data.viewer.integrations.microsoft
            return (
              <>
                <Spacer size='8' />
                <MicrosoftTeamsOauth
                  ref={microsoftOauthRef}
                  clientId={clientId}
                  onSuccess={() => authenticatedIntegrationsQuery.refetch()}
                  scope={createMicrosoftCalendarEventScope.map(it => it.value)}
                />
                <CalendarIntegrationAuthentication
                  calendarIntegrationSetting='microsoft'
                  onClick={() => microsoftOauthRef.current?.onBtnClick()}
                />
              </>
            )
          }
          case 'google':
          case 'sana':
            return null
          default:
            assertNever(calendarIntegrationSetting)
        }
      })}
    </>
  )
}
