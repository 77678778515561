import { useState } from 'react'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { UserGroupsTable } from 'sierra-client/views/manage/user-groups/components/user-groups-table'
import { useUserGroups } from 'sierra-client/views/manage/user-groups/use-user-groups'
import { EditCreateUserGroupModal } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal'
import { useTracking } from 'sierra-client/views/manage/users/utils/use-tracking'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { XRealtimeAdminGroupsCreateUserGroup } from 'sierra-domain/routes'

export const ManageUserGroups = (): JSX.Element | null => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const { postWithUserErrorException } = usePost()

  const [createModalOpen, setCreateModalOpen] = useState(false)

  // Actions
  const createUserGroup = async ({
    name,
    description,
  }: {
    name: string
    description: string
  }): Promise<void> => {
    const { groupId } = await postWithUserErrorException(XRealtimeAdminGroupsCreateUserGroup, {
      type: 'user-manual',
      name,
      description,
    })

    tracking.static.create({ id: groupId, name, description })
    void getGlobalRouter().navigate({ to: `/manage/user-groups/${groupId}` })
  }

  const { userGroupsData, isLoading } = useUserGroups()

  return (
    <>
      <PageTitle title={t('dictionary.group-plural')} />

      <EditCreateUserGroupModal
        open={createModalOpen}
        mode='create'
        type='user-manual'
        onCancel={() => setCreateModalOpen(false)}
        onCreate={async draft => {
          await createUserGroup({ name: draft.name, description: draft.description })
          setCreateModalOpen(false)
        }}
      />
      <ColumnLayout>
        <PageHeader title='admin.analytics.groups' withoutMargin />

        {!isLoading && (
          <UserGroupsTable
            userGroups={userGroupsData}
            totalCount={userGroupsData.length}
            onCreateNew={() => setCreateModalOpen(true)}
          />
        )}
      </ColumnLayout>
    </>
  )
}
