import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { AccountSettings } from 'sierra-domain/api/user'
import { User } from 'sierra-domain/user'

export type UserSettingsFormErrors = {
  [K in keyof AccountSettings]: Partial<Record<keyof AccountSettings[K], I18nArgs | undefined>>
}

export type AccountSettingsFormConfig = {
  disableName: boolean
  disableEmail: boolean
}

export type HandleAccountSettingsFormChange = <
  RootFieldName extends keyof AccountSettings,
  FieldName extends keyof AccountSettings[RootFieldName],
  FieldValue extends AccountSettings[RootFieldName][FieldName],
>(
  rootField: RootFieldName,
  field: FieldName,
  value: FieldValue
) => void

export const getDefaultAccountSettings = (user?: User): AccountSettings => ({
  userAccountSettings: {
    avatar: user?.avatar,
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    language: user?.language ?? '',
    tags: user?.tags ?? [],
    preferredTimezone: user?.preferredTimezone,
  },
  notificationSettings: user?.notificationSettings ?? {
    contentAssigned: {},
    sessionAssigned: {},
    sessionUnassigned: {},
    calendarEventSelfEnrolled: {},
    calendarEventUpdated: {},
    calendarEventEnrollmentRequestApproved: {},
    calendarEventEnrollmentRequestRejected: {},
    calendarEventEnrollmentRequestsPendingReview: {},
    upcomingDueDate: {},
    alreadyOverdue: {},
    upcomingSession: {},
    mentionedInComment: {},
    threadRespondedTo: {},
    homeworksAvailableForReview: {},
    recapReady: {},
    collaborationRequestAccess: {},
    teamspaceRequestAccess: {},
    certificateIssuedToUser: {},
    issuedCertificateExpiring: {},
    issuedCertificatesExpiringForIssuer: {},
    sharedDashboard: {},
    addedAsCollaborator: {},
    addedAsCommenter: {},
    receivedReview: {},
  },
})

export const validateAccountSettingsForm = (
  values: AccountSettings,
  formSettings: AccountSettingsFormConfig
): { errors: UserSettingsFormErrors; hasErrors: boolean } => {
  const errors: UserSettingsFormErrors = {
    userAccountSettings: {},
    notificationSettings: {},
  }

  if (formSettings.disableName !== true && !values.userAccountSettings.firstName) {
    errors.userAccountSettings.firstName = ['settings.errors.first-name']
  }

  if (formSettings.disableName !== true && !values.userAccountSettings.lastName) {
    errors.userAccountSettings.lastName = ['settings.errors.last-name']
  }

  const hasErrors =
    Object.keys(errors.userAccountSettings).length + Object.keys(errors.notificationSettings).length > 0
  return { errors, hasErrors }
}
