import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { useIsFurtherAuthenticationRequired } from 'sierra-client/config/use-is-further-authentication-required'
import { usePost } from 'sierra-client/hooks/use-post'
import { useRouterSelfPacedIds } from 'sierra-client/hooks/use-router-ids'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { selectPublishedAt } from 'sierra-client/state/card-progress/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { AssessmentsManager } from 'sierra-client/views/flexible-content/assessments-manager'
import { SelfPacedFilesProvider } from 'sierra-client/views/self-paced/files-provider'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { FlexibleContentRecord } from 'sierra-domain/collaboration/serialization/types'
import { XRealtimeContentQueryCourse } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'

export type SelfPacedContentRecord = FlexibleContentRecord

const ReactSelfPacedBigContext = React.createContext<SelfPacedContentRecord | undefined>(undefined)

export const useSelfPacedBigContext = (): SelfPacedContentRecord => {
  const context = useContext(ReactSelfPacedBigContext)
  if (context === undefined)
    throw new Error('SelfPacedContext was undefined. This hook can only be used on the self-paced page')

  return context
}

export const useSelfPacedBigContextSafe = (): SelfPacedContentRecord | undefined => {
  return useContext(ReactSelfPacedBigContext)
}

function useSelfPacedContentRecord(
  courseId: SelfPacedContentId | undefined
): SelfPacedContentRecord | undefined {
  const [contextMap, setContextMap] = useState<Record<SelfPacedContentId, FlexibleContentRecord>>({})
  const { postWithUserErrorException } = usePost()

  const loadCourseMutation = useMutation({
    mutationFn: async (courseId: SelfPacedContentId) => {
      const result = await postWithUserErrorException(XRealtimeContentQueryCourse, { courseId })
      if (result.type === 'native:self-paced') {
        setContextMap({ [courseId]: result.record })
      }
    },
  }).mutate

  useEffect(() => {
    if (!isDefined(courseId)) return

    loadCourseMutation(courseId)

    return () => {
      setContextMap(prev => _.omit(prev, courseId))
    }
  }, [courseId, loadCourseMutation, postWithUserErrorException])

  const publishedAt = useSelector(state =>
    courseId !== undefined ? selectPublishedAt(state, courseId) : undefined
  )

  const notif = useNotif()
  const { t } = useTranslation()
  useOnChanged((oldPublishedAt, newPublishedAt) => {
    if (courseId === undefined || oldPublishedAt === undefined) {
      return
    }

    if (oldPublishedAt !== newPublishedAt) {
      notif.push({
        type: 'custom',
        level: 'info',
        body: t('self-paced-page.refreshing-content'),
      })
      loadCourseMutation(courseId)
    }
  }, publishedAt)

  return isDefined(courseId) ? contextMap[courseId] : undefined
}

export const SelfPacedUniversalContext: FCC = ({ children }) => {
  const ids = useRouterSelfPacedIds()
  const isAuthenticated = !useIsFurtherAuthenticationRequired()

  const flexibleContentId = isAuthenticated ? ids?.flexibleContentId : undefined
  const context = useSelfPacedContentRecord(flexibleContentId)

  return (
    <ReactSelfPacedBigContext.Provider value={context}>
      <SelfPacedFilesProvider>
        <AssessmentsManager>{children}</AssessmentsManager>
      </SelfPacedFilesProvider>
    </ReactSelfPacedBigContext.Provider>
  )
}
