import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { getFlag } from 'sierra-client/config/global-config'
import { percentage, useLocalizedFormatters } from 'sierra-client/core/format'
import { ExternalTrainigs } from 'sierra-client/features/external-trainings'
import { useOrganizationCanSendEmails } from 'sierra-client/hooks/use-organization-can-send-emails'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { useIssuedCertificatesForUser } from 'sierra-client/views/manage/certificates/use-issued-certificates-for-user'
import { ManageDetailContainer } from 'sierra-client/views/manage/components/common'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  BorderCard,
  CardHeading1,
  CardHeading2,
  CardLabel,
  FadedLabel,
  StatusBorderCard,
} from 'sierra-client/views/manage/components/overview-cards'
import { PillSection } from 'sierra-client/views/manage/components/user-attributes/flows/components/pill-section'
import { useUserAttributeSettingsLoader } from 'sierra-client/views/manage/components/user-attributes/flows/user-attribute-settings/hooks/use-user-attribute-settings-loader'
import { AccessLevelIndicator } from 'sierra-client/views/manage/users/components/access-level-indicator'
import { AccessRoleIndicator } from 'sierra-client/views/manage/users/components/access-role-indicator'
import {
  ProgramGroupsSection,
  UserContentSection,
  UserGroupsSection,
  UserLiveSessionSection,
  UserSelfStartSection,
} from 'sierra-client/views/manage/users/components/sections'
import { UserIssuedCertificatesCarousel } from 'sierra-client/views/manage/users/components/user-issued-certificates-carousel'
import {
  UserSettingMenuItem,
  useUserSettingsMenuItems,
} from 'sierra-client/views/manage/users/components/user-menu-items'
import {
  UserModalActions,
  UserModalActionsProps,
} from 'sierra-client/views/manage/users/components/user-modal-actions'
import { useInitializeGeneralSettings } from 'sierra-client/views/manage/users/components/user-settings-panel/general-tab/use-initialize-general-settings'
import { UserSettingsPanel } from 'sierra-client/views/manage/users/components/user-settings-panel/panel'
import {
  UseUserDetailData,
  useUserDetailData,
} from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-detail-data'
import { useUserDetails } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-details'
import { openTranscriptViewer } from 'sierra-client/views/v3-author/export-pdf/export-user-transcript'
import { LinkLabel } from 'sierra-client/views/workspace/learn/link-label'
import { isOwner } from 'sierra-domain/access-level'
import { UserDetailResponse } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { asNonNullable, assertNever, getUserName } from 'sierra-domain/utils'
import {
  Icon,
  IconId,
  MenuButton,
  RoundAvatar,
  Tabs,
  Tooltip,
  getAvatarPropsFromBaseUserInfo,
} from 'sierra-ui/components'
import { Button, Heading, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { ConditionalWrapper } from 'sierra-ui/utils'
import styled from 'styled-components'

const ColumnContainer = styled(View).attrs({ direction: 'column' })``

const RoleAndAttributesContainer = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})``

const InsightsContainer = styled(View).attrs({ direction: 'column' })`
  display: flex;
`

const CertificatesContainer = styled.div`
  position: relative;
  width: 100%;
`

const InsightsTitle = styled(Text).attrs({ bold: true })`
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
`

const StatisticsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  height: 100%;
  /*max-height: 25rem;*/
  width: 100%;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    gap: 1rem;
  }
`

type UserStatus = 'all-done' | 'on-track' | 'at-risk' | 'needs-attention'

const UserStatusCard: React.FC<{ userData: UseUserDetailData['userData']; scrollToContent: () => void }> = ({
  userData,
  scrollToContent,
}) => {
  const { t } = useTranslation()

  if (!userData) return null

  const { riskCounts } = userData

  const dueSoonCount = riskCounts['due-soon'] ?? 0
  const overdueCount = riskCounts['overdue'] ?? 0
  const unscheduledCount = riskCounts['not-scheduled'] ?? 0

  const userStatus: UserStatus =
    overdueCount > 0
      ? 'needs-attention'
      : unscheduledCount > 0 || dueSoonCount > 0
        ? 'at-risk'
        : userData.progress === 1.0
          ? 'all-done'
          : 'on-track'

  const cardContent = (() => {
    switch (userStatus) {
      case 'on-track':
        return (
          <>
            <CardHeading1>{t('manage.status.on-track')}</CardHeading1>
            <CardHeading2>{t('manage.users.insights.on-track-description')}</CardHeading2>
            <Spacer axis='vertical' size='xsmall' />
            <FadedLabel>{t('manage.no-actions-needed')}</FadedLabel>
          </>
        )
      case 'all-done':
        return (
          <>
            <CardHeading1>{t('manage.users.insights.all-done-title')}</CardHeading1>
            <CardHeading2>{t('manage.users.insights.all-done-description')}</CardHeading2>
            <Spacer axis='vertical' size='xsmall' />
            <FadedLabel>{t('manage.no-actions-needed')}</FadedLabel>
          </>
        )
      case 'at-risk':
        return (
          <>
            <CardHeading1>{t('manage.users.insights.at-risk-title')}</CardHeading1>
            <CardHeading2>{t('manage.users.insights.at-risk-description')}</CardHeading2>
            <Spacer axis='vertical' size='xsmall' />
            <LinkLabel onClick={scrollToContent}>{t('dictionary.view')}</LinkLabel>
          </>
        )
      case 'needs-attention':
        return (
          <>
            <CardHeading1>{t('manage.users.insights.needs-attention-title')}</CardHeading1>
            {overdueCount > 0 && (
              <CardHeading2>
                {t('manage.insights.n-assignments-overdue', { count: overdueCount })}
              </CardHeading2>
            )}
            {unscheduledCount > 0 && (
              <CardHeading2>
                {t('manage.insights.n-unscheduled-sessions', { count: unscheduledCount })}
              </CardHeading2>
            )}
            <Spacer axis='vertical' size='xsmall' />
            <LinkLabel onClick={scrollToContent}>{t('dictionary.view')}</LinkLabel>
          </>
        )
      default:
        assertNever(userStatus)
    }
  })()

  return (
    <StatusBorderCard status={userStatus}>
      <CardLabel>{t('manage.insights.title')}</CardLabel>
      <Spacer axis='vertical' size='xxsmall' />
      <View direction='column' gap='none'>
        {cardContent}
      </View>
    </StatusBorderCard>
  )
}

const AssignmentsCard: React.FC<{
  userData: UseUserDetailData['userData']
  scrollToContent: () => void
}> = ({ userData, scrollToContent }) => {
  const { t } = useTranslation()

  if (!userData) return null

  return (
    <BorderCard>
      <CardLabel>{t('manage.insights.assignments')}</CardLabel>
      <Spacer axis='vertical' size='xxsmall' />
      <View direction='column' gap='none'>
        <Heading size='h4' bold color='foreground/muted'>
          {t('manage.insights.n-assignments', {
            count: userData.totalCoursesCount + userData.totalPathsCount,
          })}
        </Heading>
        {userData.totalCoursesSelfStartCount > 0 && (
          <Heading size='h4' bold color='foreground/muted'>
            {t('manage.insights.n-self-starts', {
              count: userData.totalCoursesSelfStartCount,
            })}
          </Heading>
        )}
        <Heading size='h4' bold>
          {t('manage.programs.n-percentage-progress', {
            percentage: percentage(userData.progress, 0, false),
          })}
        </Heading>
        <Spacer axis='vertical' size='xsmall' />
        <LinkLabel onClick={scrollToContent}>{t('dictionary.view')}</LinkLabel>
      </View>
    </BorderCard>
  )
}

type ManageUserDetailsProps = {
  userId: UserId
}

const MiddleBar = styled(View).attrs({
  paddingTop: '16',
  paddingBottom: '16',
  gap: '32',
  justifyContent: 'space-between',
})`
  border-top: 1px solid ${token('border/default')};
  border-bottom: 1px solid ${token('border/default')};
`

const IconText: React.FC<{ iconId: IconId; label: string; tooltip?: string; link?: string }> = ({
  iconId,
  label,
  tooltip,
  link,
}) => (
  <Tooltip title={tooltip}>
    <View direction='row' justifyContent='center' alignItems='center' gap='6'>
      <Icon iconId={iconId} size='size-14' color='foreground/muted' />
      <ConditionalWrapper
        condition={link !== undefined}
        renderWrapper={children => <RouterLink href={asNonNullable(link)}>{children}</RouterLink>}
      >
        <Text color='foreground/muted'>{label}</Text>
      </ConditionalWrapper>
    </View>
  </Tooltip>
)

const LinkText = styled(Text)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const Count = styled(View).attrs({
  radius: 'size-28',
  color: 'foreground/primary',
  paddingLeft: '6',
  paddingRight: '6',
})`
  background: ${token('surface/soft')};
`

const TabWithCount: React.FC<{ label: string; count?: number }> = ({ label, count }) => {
  return (
    <View gap='4'>
      <Text>{label}</Text>
      {count !== undefined && (
        <Count>
          <Text size='micro' bold>
            {count}
          </Text>
        </Count>
      )}
    </View>
  )
}

type TabCounts = {
  visible: number
  total: number
}

const ContentRedactedNotice: React.FC<{ tabCounts: TabCounts }> = ({ tabCounts }) => {
  const { t } = useTranslation()

  // Don't show notice if no content is redacted
  if (tabCounts.visible === tabCounts.total) return null

  return (
    <>
      <Spacer size='8' />
      <Text color='foreground/muted'>{t('manage.user.redaction-notice')}</Text>
    </>
  )
}

// manage.user.redaction-notice

const UserDetailsTabSection: React.FC<{
  userId: UserId
  userData: UserDetailResponse
  userContentSectionRef: React.RefObject<HTMLDivElement>
  setUserAction: React.Dispatch<React.SetStateAction<UserModalActionsProps['action']>>
  refetch: () => void
}> = ({ userId, userData, userContentSectionRef, setUserAction, refetch }) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState('assigned')

  const assignedToName = _.compact([userData.firstName, userData.lastName, userData.email])[0] ?? ''

  const assignedTabCounts: TabCounts = {
    visible: userData.courses.length + userData.paths.length,
    total: userData.totalCoursesCount + userData.totalPathsCount,
  }

  const selfStartedTabCounts: TabCounts = {
    visible: userData.coursesSelfStart.length,
    total: userData.totalCoursesSelfStartCount,
  }

  const liveSessionTabCounts: TabCounts = {
    visible: userData.liveSessions.length,
    total: userData.totalLiveSessionsCount,
  }

  const tabItems = [
    {
      id: 'assigned',
      label: (
        <TabWithCount
          label={t('manage.user.assinged-to-name', { name: assignedToName })}
          count={assignedTabCounts.total}
        />
      ),
      content: (
        <>
          <div ref={userContentSectionRef} />
          <ContentRedactedNotice tabCounts={assignedTabCounts} />
          <UserContentSection
            courses={userData.courses}
            paths={userData.paths}
            liveSessions={userData.liveSessions}
            setUserAction={setUserAction}
            userId={userId}
            userType={userData.type}
            refetch={refetch}
          />
        </>
      ),
    },
    {
      id: 'self-started',
      label: (
        <TabWithCount
          label={t('manage.user.self-started-by-name', { name: assignedToName })}
          count={selfStartedTabCounts.total}
        />
      ),
      content: (
        <>
          <div ref={userContentSectionRef} />
          <ContentRedactedNotice tabCounts={selfStartedTabCounts} />
          <UserSelfStartSection coursesSelfStart={userData.coursesSelfStart} />
        </>
      ),
    },
    {
      id: 'programs',
      label: (
        <TabWithCount
          label={t('admin.organization.tab.programs')}
          count={userData.programMemberships.length}
        />
      ),
      content: (
        <ProgramGroupsSection
          programMemberships={userData.programMemberships}
          setUserAction={setUserAction}
          userId={userId}
          refetch={refetch}
        />
      ),
    },
    {
      id: 'groups',
      label: (
        <TabWithCount
          label={t('admin.organization.tab.groups')}
          count={userData.userGroupMemberships.length}
        />
      ),
      content: (
        <UserGroupsSection
          userGroupMemberships={userData.userGroupMemberships}
          setUserAction={setUserAction}
        />
      ),
    },
    {
      id: 'sessions',
      label: <TabWithCount label={t('content.sessions')} count={liveSessionTabCounts.total} />,
      content: (
        <>
          <ContentRedactedNotice tabCounts={liveSessionTabCounts} />
          <UserLiveSessionSection
            liveSessions={userData.liveSessions}
            currentUserId={userId}
            setUserAction={setUserAction}
          />
        </>
      ),
    },
  ]

  return <Tabs value={tab} onChange={setTab} items={tabItems} />
}

export const ManageUserDetails: React.FC<ManageUserDetailsProps> = ({ userId }) => {
  // Utils
  const { t } = useTranslation()
  const { formatTimestamp } = useLocalizedFormatters()
  const me = useSelector(selectUser)
  const notifications = useNotif()
  const userContentSectionRef = React.useRef<HTMLDivElement | null>(null) // For scrolling

  //Data
  const learnerTranscriptEnabled = getFlag('learner-transcript')
  const isExternalTrainingsEnabled = getFlag('external-user-trainings')
  const { resendInvite } = useUserDetails()
  const { userData, refetch } = useUserDetailData(userId)
  const queryResult = useIssuedCertificatesForUser(userId)
  const issuedCertificates = queryResult.data?.user?.issuedCertificates ?? []
  const orgPermissions = useOrganizationPermissions()
  const userAttributeLoadingStatus = useUserAttributeSettingsLoader({
    userId,
    hasEditPermission: orgPermissions.has('EDIT_USER'),
  })
  const isLoadingInitialGeneralSettings = useInitializeGeneralSettings(userId)

  //State
  const [action, setAction] = useState<UserModalActionsProps['action']>({ modal: undefined })

  const sandbox = getFlag('sandbox')
  const organizationCanSendEmails = useOrganizationCanSendEmails()

  const resendInviteOrOpenConfirmation = useCallback(async () => {
    // We allow owners to force send the invite-email, but we prompt a confirmation modal
    if (me && isOwner(me.accessRole, me.accessLevel) && sandbox) {
      setAction({ modal: 'sandbox-owner-confirm-resend-invite' })
      return
    }

    // Show a message if the organization can't send emails
    if (!organizationCanSendEmails) {
      setAction({ modal: 'sandbox-email-sending-disabled' })
      return
    }

    // If not an owner, just tries to send the email - can fail
    const forceEmail = false
    const success = await resendInvite(userId, forceEmail)
    notifications.push({ type: success ? 'resend-invite' : 'resend-invite-not-sent' })
  }, [sandbox, me, resendInvite, userId, notifications, organizationCanSendEmails])

  const userSettingsMenuItems = useUserSettingsMenuItems({
    setUserAction: setAction,
    targetUserData: userData,
    targetUserId: userId,
    resendInviteOrOpenConfirmation,
    canEdit: orgPermissions.has('EDIT_USER'),
    refetch: refetch,
  })

  if (
    userData === undefined ||
    me === undefined ||
    userAttributeLoadingStatus !== 'loaded' ||
    isLoadingInitialGeneralSettings
  )
    return null

  const fullName = getUserName(userData)

  return (
    <>
      <PageTitle title={fullName} />

      <ColumnLayout>
        <DetailsHeader backlink={{ href: '/manage/users', label: 'manage.backlinks--users' }} />

        <ManageDetailContainer>
          <ColumnContainer>
            <View direction='column' gap='none'>
              <RoundAvatar
                {...getAvatarPropsFromBaseUserInfo(userData)}
                src={getAvatarImage(userId, userData.avatar)}
              />
              <Spacer size={'xsmall'} />
              <Heading size='h5' bold>
                {fullName}
              </Heading>
              <View>
                <Text size='small' color='foreground/muted'>
                  {userData.email}
                </Text>
                <View gap='6'>
                  <Icon size='size-12' iconId={'radio-button--dot'} />
                  {userData.accessRole !== undefined ? (
                    <AccessRoleIndicator
                      accessRole={userData.accessRole}
                      onClick={() => setAction({ modal: 'user-settings' })}
                    />
                  ) : (
                    <AccessLevelIndicator
                      accessLevel={userData.accessLevel}
                      onClick={() => setAction({ modal: 'user-settings' })}
                    />
                  )}
                </View>
              </View>
            </View>
            <Spacer size='medium' />
            <RoleAndAttributesContainer>
              <MenuButton
                variant='secondary'
                menuItems={userSettingsMenuItems}
                onSelect={item => {
                  const actionMenuItem = item as UserSettingMenuItem //TODO: Fix better way of setting selected menu item types
                  actionMenuItem.action()
                }}
              >
                {t('dictionary.settings')}
              </MenuButton>
              <View grow />
              <View direction='column' alignItems='flex-start'>
                {userData.userAttributes.length > 0 && <Text bold>{t('user-attribute.section-title')}</Text>}
                <View direction='row'>
                  {userData.userAttributes.length > 0 && (
                    <PillSection
                      attributes={userData.userAttributes}
                      onOpenModal={() => setAction({ modal: 'user-settings' })}
                    />
                  )}
                </View>
              </View>
              <UserSettingsPanel
                userId={userId}
                refetch={refetch}
                canEdit={orgPermissions.has('EDIT_USER')}
                isOpen={action.modal === 'user-settings'}
                closeDirectly={() => setAction({ modal: undefined })}
              />
            </RoleAndAttributesContainer>
            <Spacer size={'xxsmall'} />
          </ColumnContainer>
          <MiddleBar>
            <View gap='32'>
              <View gap='16'>
                {userData.createdAt && (
                  <IconText
                    iconId='email'
                    label={formatTimestamp(userData.createdAt)}
                    tooltip={t('manage.users.invited-at-tooltip', {
                      name: userData.firstName,
                      timestamp: formatTimestamp(userData.createdAt),
                    })}
                  />
                )}
                {userData.status === 'pending' && (
                  <LinkText onClick={resendInviteOrOpenConfirmation}>{t('manage.resend-invite')}</LinkText>
                )}
              </View>
              {userData.status === 'active' && userData.completedAt !== undefined && (
                <IconText
                  iconId='user--done'
                  label={formatTimestamp(userData.completedAt)}
                  tooltip={t('manage.users.active-since-tooltip', {
                    name: userData.firstName,
                    timestamp: formatTimestamp(userData.completedAt),
                  })}
                />
              )}

              {userData.emailVerificationOk === false && (
                <IconText
                  iconId='user--done'
                  label={t('manage.users.email-not-verified.label')}
                  tooltip={t('manage.users.email-not-verified.tooltip')}
                />
              )}

              {userData.status === 'deactivated' && userData.disabledAt !== undefined && (
                <IconText
                  iconId='user--remove'
                  label={formatTimestamp(userData.disabledAt)}
                  tooltip={t('manage.users.deactivated-since-tooltip', {
                    name: userData.firstName,
                    timestamp: formatTimestamp(userData.disabledAt),
                  })}
                />
              )}
              {userData.manager !== undefined && (
                <IconText
                  iconId='user--group'
                  label={getUserName(userData.manager) ?? ''}
                  link={`/manage/users/${userData.manager.id}`}
                  tooltip={t('manage.users.manager-tooltip', {
                    manager: getUserName(userData.manager) ?? '',
                    user: userData.firstName,
                  })}
                />
              )}
            </View>
            {isExternalTrainingsEnabled ? (
              <ExternalTrainigs userId={userId} />
            ) : (
              <>
                {learnerTranscriptEnabled && (
                  <Button variant='secondary' onClick={() => openTranscriptViewer(userId)}>
                    {t('manage.user.get-transcript')}
                  </Button>
                )}
              </>
            )}
          </MiddleBar>
          <Spacer size='large' />
          <InsightsContainer>
            <InsightsTitle>{t('dictionary.insights')}</InsightsTitle>
            <View grow={true}>
              <StatisticsGrid>
                <UserStatusCard
                  userData={userData}
                  scrollToContent={() =>
                    userContentSectionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
                  }
                />
                <AssignmentsCard
                  userData={userData}
                  scrollToContent={() =>
                    userContentSectionRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
                  }
                />
              </StatisticsGrid>
            </View>
          </InsightsContainer>
          <Spacer size='large' />
          <CertificatesContainer>
            {issuedCertificates.length > 0 && (
              <UserIssuedCertificatesCarousel issuedCertificates={issuedCertificates} />
            )}
          </CertificatesContainer>
          <Spacer size='large' />
          <UserDetailsTabSection
            userId={userId}
            userData={userData}
            userContentSectionRef={userContentSectionRef}
            setUserAction={setAction}
            refetch={refetch}
          />
          <Spacer size='xlarge' />
        </ManageDetailContainer>
        <Spacer size='xlarge' />
        <UserModalActions
          targetUserId={userId}
          action={action}
          onClose={() => setAction({ modal: undefined })}
          onDone={() => {
            setAction({ modal: undefined })
            void refetch()
          }}
        />
      </ColumnLayout>
    </>
  )
}
