import { getCreateEntityHref } from 'sierra-client/components/recommendations/utils'
import { useTemplatePicker } from 'sierra-client/components/templates'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { LearnEntity } from 'sierra-domain/api/entities'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { isDefined } from 'sierra-domain/utils'

type CardAction = { href: string } | { onClick: () => void }

const getLearnEntityHref = (entity: LearnEntity): string => {
  return detailsUrl(convertLearnEntityToLinkable(entity))
}

export function resolveEntity(card: Recommendation['card']): ['learn' | 'create', string] | undefined {
  switch (card.type) {
    case 'popular':
    case 'assigned':
    case 'discover':
    case 'recently-assigned':
    case 'upcoming-due-date':
    case 'overdue':
    case 'self-enroll-to-session':
    case 'in-progress':
    case 'featured':
    case 'upcoming-session':
    case 'ongoing-session':
      return ['learn', getLearnEntityHref(card.entity)]
    case 'recently-edited':
    case 'continue-editing':
    case 'added-as-collaborator':
      return ['create', getCreateEntityHref(card.entity)]
    default:
      return undefined
  }
}

export function resolveEntityHref(card: Recommendation['card']): string | undefined {
  const entity = resolveEntity(card)
  if (entity === undefined) return undefined
  return entity[1]
}

export function useCardAction(card: Recommendation['card']): CardAction | undefined {
  const { templatePickerOn } = useTemplatePicker()

  if (card.type === 'create-suggestion')
    return { onClick: () => templatePickerOn({ teamspaceState: undefined }) }
  const href = resolveEntityHref(card)
  if (isDefined(href)) return { href }
  else return undefined
}
