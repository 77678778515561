import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import {
  useGetLearnerProgram,
  useGetProgram,
} from 'sierra-client/views/learner/program/use-get-learner-program'
import { EmailStepShape } from 'sierra-client/views/learner/program/utils/types'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { ResettableContentType } from 'sierra-domain/api/user'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'

export type ProgramData = ReturnType<typeof useGetProgram>
export type ProgramInfo = NonNullable<NonNullable<ProgramData['data']>['program']>
export type ProgramInfoStepType = NonNullable<ProgramInfo>['steps'][number]

export type LearnerProgramData = ReturnType<typeof useGetLearnerProgram>
export type EnrollmentData = NonNullable<LearnerProgramData['data']>['me']['enrollment']
export type EnrollmentStepType = NonNullable<EnrollmentData>['steps'][number]

export function getContentIdFromStep<TStep extends EnrollmentStepType>(
  step: TStep
): TStep extends EmailStepShape ? null : NanoId12
export function getContentIdFromStep(step: EnrollmentStepType): NanoId12 | null {
  switch (step.__typename) {
    case 'UserProgramCourseStep':
      return NanoId12.parse(step.courseId)
    case 'UserProgramPathStep':
      return NanoId12.parse(step.pathId)
    case 'UserProgramEmailStep': {
      return null
    }
    default:
      assertNever(step)
  }
}

export function getResettableContentTypeFromStep<TStep extends EnrollmentStepType>(
  step: TStep
): TStep extends EmailStepShape ? null : ResettableContentType
export function getResettableContentTypeFromStep(step: EnrollmentStepType): ResettableContentType | null {
  switch (step.__typename) {
    case 'UserProgramEmailStep': {
      return null
    }
    case 'UserProgramPathStep':
    case 'UserProgramCourseStep': {
      return iife(() => {
        switch (step.content.__typename) {
          case 'LinkCourse':
          case 'LinkedInCourse':
          case 'NativeLiveCourse':
          case 'NativeEventGroup':
          case 'NativeSelfPacedCourse':
          case 'ScormCourse':
            return 'course'
          case 'Path':
            return 'path'
          case 'NativeCourseGroup':
          case 'ScormCourseGroup':
            return 'course-group'
          case 'Program':
            throw new Error("Programs can't be a step")
          default:
            assertNever(step.content)
        }
      })
    }
    default:
      assertNever(step)
  }
}

export const getStepImage = (step: EnrollmentStepType): ImageUnion | null => {
  const image = iife(() => {
    switch (step.__typename) {
      case 'UserProgramEmailStep': {
        return step.image
      }
      case 'UserProgramPathStep':
      case 'UserProgramCourseStep': {
        return step.content.image
      }
      default:
        assertNever(step)
    }
  })

  return isDefined(image) ? convertGQLImage(image) : null
}

export const getStepAssetContext = (step: EnrollmentStepType): AssetContext => {
  switch (step.__typename) {
    case 'UserProgramCourseStep':
      return { type: 'course', courseId: step.courseId }
    case 'UserProgramEmailStep':
      return { type: 'unknown' }
    case 'UserProgramPathStep':
      return { type: 'path', pathId: step.pathId }
    default:
      assertNever(step)
  }
}

export function getStepTitle(step: EnrollmentStepType): string {
  switch (step.__typename) {
    case 'UserProgramEmailStep': {
      return step.title
    }
    case 'UserProgramCourseStep':
    case 'UserProgramPathStep': {
      return step.content.title
    }
  }
}

export function isEmailEnrollmentStep(
  step: EnrollmentStepType
): step is Extract<EnrollmentStepType, EmailStepShape> {
  return step.__typename === 'UserProgramEmailStep'
}
