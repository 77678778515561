import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Text, View } from 'sierra-ui/primitives'

export const NumberOfSubmissionsCell: React.FC<{ count: number; total: number }> = ({ count, total }) => {
  const { t } = useTranslation()
  return (
    <View>
      <Text size='small'>{count}</Text>
      <Text size='small' color='grey35'>
        {t('homeworks-table__submissions--submitted-vs-total-separator')}
      </Text>
      <Text size='small'>{total}</Text>
    </View>
  )
}
