import { useQueries } from '@tanstack/react-query'
import * as bats from '@yornaath/batshit'
import { useMemo } from 'react'
import { typedPostWithRetry } from 'sierra-client/state/api'
import { UserResult } from 'sierra-client/state/users/types'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeContentListUsers } from 'sierra-domain/routes'
import { LightUser } from 'sierra-domain/user'
import { asNonNullable } from 'sierra-domain/utils'

const QUERY_KEY_PREFIX = 'use-users'

const batcher = bats.create({
  fetcher: async (userIds: UserId[]): Promise<LightUser[]> => {
    const response = await typedPostWithRetry(XRealtimeContentListUsers, {
      requestedUserIds: userIds,
    })

    return response.data.map(
      ({ userInfo: { firstName = '', lastName = '', avatar, avatarColor, userId: uuid, email } }) => ({
        firstName,
        lastName,
        avatar,
        avatarColor,
        uuid,
        email,
      })
    )
  },
  resolver: (users, uuid) => users.find(u => u.uuid === uuid) ?? null,
  scheduler: bats.windowScheduler(10),
})

export function isUserLoaded(
  userResult: UserResult
): userResult is Extract<UserResult, { status: 'loaded' }> {
  return userResult.status === 'loaded'
}

export function useUsers(userIds: readonly UserId[]): UserResult[] {
  const users = useQueries({
    queries: userIds.map(
      userId =>
        ({
          queryKey: [QUERY_KEY_PREFIX, userId],
          queryFn: () => batcher.fetch(userId),
          staleTime: 60 * 1000,
        }) as const
    ),
    combine: results =>
      results.map((result, i): UserResult => {
        const uuid = asNonNullable(userIds[i])

        if (result.data === null) {
          return { status: 'not-found', uuid }
        } else if (result.data === undefined) {
          return { status: 'loading', uuid }
        } else {
          return {
            status: 'loaded',
            ...result.data,
          }
        }
      }),
  })

  return users
}

export function useUser(userId?: UserId): UserResult | undefined {
  const ids = useMemo(() => (userId !== undefined ? [userId] : []), [userId])
  const users = useUsers(ids)
  return users[0]
}

/**
 * @deprecated - use useUsers instead
 */
export function useUsersLegacy(userIds: readonly UserId[]): (LightUser | undefined)[] {
  const results = useUsers(userIds)
  return useMemo(() => results.map(user => (user.status === 'loaded' ? user : undefined)), [results])
}

/**
 * @deprecated - use useUser instead
 */
export function useUserLegacy(userId?: UserId): LightUser | undefined {
  const user = useUser(userId)
  if (user?.status === 'loaded') return user
  else return undefined
}
