import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { TranscriptionUpdatedData } from 'sierra-client/realtime-data/channels'
import { typedPost } from 'sierra-client/state/api'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { getLiveChannelName } from 'sierra-domain/live/get-live-channel-name'
import { XRealtimeStrategyLiveSessionGetTranscription } from 'sierra-domain/routes'

const CACHE_KEY = 'live_transcription' as const

export const useLiveTranscription = (
  liveSessionId: LiveSessionId
): {
  transcriptions: TranscriptionUpdatedData['transcription']['transcriptions'] | undefined
  refetch: () => void
  isLoading: boolean
  isError: boolean
} => {
  const tenantId = config.organization.tenantId
  const channelName = getLiveChannelName(tenantId, liveSessionId)

  const queryResult = useQuery({
    queryKey: [CACHE_KEY, liveSessionId, channelName],
    queryFn: async () => {
      const result = await typedPost(XRealtimeStrategyLiveSessionGetTranscription, {
        liveSessionId,
        channelName,
      })
      return result
    },
    refetchInterval: 5 * 1000,
  })
  return useMemo(() => {
    const transcriptions = queryResult.data?.transcription?.transcriptions

    return {
      transcriptions: transcriptions ? _.reverse(_.sortBy(transcriptions, it => it.startTime)) : undefined,
      refetch: queryResult.refetch,
      isLoading: queryResult.isLoading,
      isError: queryResult.isError,
    }
  }, [queryResult])
}
