import { Link } from 'sierra-client/components/common/link'
import { LightText } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { ListExercisesResponse } from 'sierra-domain/homework'
import { Icon, Tooltip, TruncatedText } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const TitleText = styled(Text)`
  min-width: fit-content;
`

export const ContextCell: React.FC<{
  course: ListExercisesResponse['data'][0]['courseData']
  path: ListExercisesResponse['data'][0]['path']
}> = ({ course, path }) => {
  return (
    <View
      direction='row'
      gap='4'
      onClick={event => {
        event.stopPropagation()
      }}
    >
      <Link next href={`/s/${course.courseId}/file:${course.cardId}`}>
        <Tooltip title='View in context'>
          <View gap='4'>
            {path && (
              <>
                <TitleText as={LightText} size='small'>
                  {path.title}
                </TitleText>
                <Icon iconId='chevron--right--small' color='grey25' />
              </>
            )}
            <TruncatedText size='small' lines={1}>
              {course.courseTitle}
            </TruncatedText>
          </View>
        </Tooltip>
      </Link>
    </View>
  )
}
