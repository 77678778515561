import { useAtom, useAtomValue } from 'jotai'
import { CSVLink } from 'react-csv'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { detailedContentsColumn, progressColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { ContentTypeFilter } from 'sierra-client/views/manage/content/components/content-filter'
import { getHrefForContentDetails } from 'sierra-client/views/manage/content/utils/content-utils'
import { UserContentProgressFilter } from 'sierra-client/views/manage/users/components/user-content-filter'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import {
  UserContentFilter,
  UserContentRow,
  userContentRowToCsv,
  useUserAssignedContent,
} from 'sierra-client/views/manage/users/utils/use-user-assigned-content'
import { UserDetailResponse } from 'sierra-domain/api/manage'
import { Spacer, View } from 'sierra-ui/primitives'

type SelfStartContentData = UserContentRow

type SelfStartContentTableDefinition = TableDefinitionOf<
  SelfStartContentData,
  [{ type: 'detailedContents'; ref: 'content' }, { type: 'progress'; ref: 'progress' }]
>

type SelfStartTableData = TableDataFromDefinition<SelfStartContentData, SelfStartContentTableDefinition>

const tableDefinition = (): SelfStartContentTableDefinition => {
  return {
    columns: [
      detailedContentsColumn({
        sortable: true,
        ref: 'content',
        getData: row => ({
          ...row,
          variant: 'detailed' as const,
          isExpandable: Boolean(row.subComponentData),
        }),
        header: translatedLabel('table.name'),
      }),
      progressColumn({
        sortable: true,
        ref: 'progress',
        header: translatedLabel('admin.analytics.table.progress'),

        getData: row => ({
          progress: row.progress,
          courseKind: row.courseKind,
          isSelfStarted: false,
          liveSessions: row.liveSessions,
        }),
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.id,
    },
  }
}

const searchKey: StaticLoaderSearchKeyBy<SelfStartTableData> = (tableData, row) =>
  tableData.rows[row]?.data.content.data?.title ?? ''

type SelfStartTableMeta = StaticDataLoaderMeta<SelfStartTableData>

const useSelfStartContentTable = ({
  data,
}: {
  data: SelfStartContentData[]
}): UseTableAPI<SelfStartTableData, SelfStartTableMeta> => {
  const { t } = useTranslation()
  const loader = staticDataLoader(definition2Data(tableDefinition(), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const content = row.rawData

            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  hidden: false,
                  label: t('manage.view-course-details'),
                  onClick: () => getGlobalRouter().navigate({ to: getHrefForContentDetails(content) }),
                  icon: 'course',
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

type ToolbarProps = {
  tableAPI: UseTableAPI<SelfStartTableData, SelfStartTableMeta>
  mapCsv: (ids: string[]) => Record<string, string>[]
  canEditAssignments: boolean
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, mapCsv }) => {
  const { api } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.content.n-contents',
        filterKey: 'manage.program-members.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.courses'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const SearchAndFilter: React.FC<{
  filter: UserContentFilter
  setFilter: (state: React.SetStateAction<UserContentFilter>) => void
  tableAPI: UseTableAPI<SelfStartTableData, SelfStartTableMeta>
}> = ({ filter, setFilter, tableAPI }) => {
  const { t } = useTranslation()
  const query = useAtomValue(tableAPI.api.atoms.query)

  return (
    <View>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('manage.search.content')}
      />
      <UserContentProgressFilter
        value={filter.progress}
        onChange={value => setFilter(f => ({ ...f, progress: value }))}
      />
      <ContentTypeFilter
        value={filter.contentClassification}
        onChange={value => setFilter(f => ({ ...f, contentClassification: value }))}
      />
    </View>
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.courses')} />
    </View>
  )
}

export type UserSelfStartTableProps = {
  coursesSelfStart: UserDetailResponse['coursesSelfStart']
}

export const UserSelfStartTable: React.FC<UserSelfStartTableProps> = ({ coursesSelfStart }) => {
  const { filter, setFilter, filteredItems } = useUserAssignedContent({
    coursesSelfStart,
  })

  const orgPermissions = useOrganizationPermissions()
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')

  const tableAPI = useSelfStartContentTable({ data: filteredItems })

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Spacer size='xsmall' />
      <View justifyContent='space-between'>
        <SearchAndFilter filter={filter} setFilter={setFilter} tableAPI={tableAPI} />
      </View>
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        mapCsv={(ids: string[]) =>
          filteredItems.filter(({ id }) => ids.includes(id)).map(userContentRowToCsv)
        }
        canEditAssignments={canEditAssignments}
      />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(filteredItems.map(userContentRowToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
