import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Control, useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ContentAttribute,
  ContentAttributeType,
  SelectAttribute,
} from 'sierra-client/views/manage/content-attributes/types'
import { FormInput, FormSingleSelectDropdown, FormTextArea } from 'sierra-client/views/manage/shared/form'
import { iife } from 'sierra-domain/utils'
import { ClosePanelButton, Icon, Panel } from 'sierra-ui/components'
import { Button, Heading, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'
import { z } from 'zod'

const VisibilityIcon = styled(Icon).attrs({ iconId: 'view' })`
  padding: 12px;
  border-radius: 10px;
  border: 1px solid;
  border-color: ${token('border/default')};
  color: ${token('foreground/primary')};
`

// TODO(Huy, Fanny): translations
const VisibilityView: React.FC<{ onClick: (visible: boolean) => void }> = ({ onClick }) => {
  return (
    <View justifyContent='space-between'>
      <View gap='12'>
        <VisibilityIcon />
        <View direction='column' gap='none'>
          <Text size='small' bold>
            Visible to learners
          </Text>
          <Text size='small' bold color='foreground/secondary'>
            The attribute is visible to learners
          </Text>
        </View>
      </View>
      <Switch size='big' onChange={onClick} checked={false} text='' />
    </View>
  )
}

const AddButton = styled(Button)`
  background-color: ${token('form/background/2')};
  color: ${token('foreground/secondary')};
`
const FormOptions: React.FC<{
  control: Control<ContentAttribute & SelectAttribute, any>
}> = ({ control }) => {
  const { t } = useTranslation()
  const optionsHandler = useFieldArray({ name: 'options', control: control })

  useEffect(() => {
    if (optionsHandler.fields.length === 0) {
      optionsHandler.append({ title: '' })
    }
  })

  return (
    <View grow direction='column' gap='14'>
      <Text bold>{t('dictionary.options')}</Text>
      {optionsHandler.fields.map((field, index) => {
        return (
          <View key={field.id}>
            <FormInput control={control} name={`options.${index}.title`} />
          </View>
        )
      })}
      <AddButton icon='add' type='button' grow onClick={() => optionsHandler.append({ title: '' })}>
        {t('dictionary.add')}
      </AddButton>
    </View>
  )
}

const HorizontalDivider = styled.hr`
  background-color: ${token('border/default')};
  height: 1px;
  width: 100%;
  margin: 0px;
`

const schema = ContentAttribute
type Schema = z.infer<typeof schema>
export const CreateAttributeSidePanel: React.FC<{
  onClose: () => void
  isOpen: boolean
  onSubmit: (_: Schema) => void
}> = ({ onClose, isOpen, onSubmit }) => {
  const { t } = useTranslation()
  const handler = useForm<Schema>({
    resolver: zodResolver(schema),
    mode: 'onSubmit',
    // TODO: what should be default
    values: {
      name: '',
      description: '',
      type: 'select',
      options: [{ title: '' }],
    },
  })

  // Reset form on open
  useOnChanged((p, n) => {
    if (p === false && n === true) {
      handler.reset()
    }
  }, isOpen)

  const type = useWatch({ control: handler.control, name: 'type' })

  const errors = handler.formState.errors

  return (
    <Panel
      size={{ width: 650 }}
      animation='slide-right'
      padding='32 40'
      onClose={onClose}
      open={isOpen}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={onClose} ariaLabel={t('dictionary.close')} />

      <View paddingBottom='32'>
        <Heading size='h5'>{t('manage.content-attributes.cards.new.text')}</Heading>
      </View>
      <form onSubmit={handler.handleSubmit(onSubmit)}>
        <View gap='32' direction='column'>
          <View alignItems='flex-start'>
            <View grow direction='column'>
              <FormInput label={t('dictionary.name')} control={handler.control} name='name' />
            </View>
            <View>
              <FormSingleSelectDropdown
                label={t('dictionary.type')}
                control={handler.control}
                name='type'
                // TODO: fix these types
                menuItems={[
                  {
                    label: 'Text',
                    id: 'text',
                    type: 'label',
                    selected: type === 'text',
                  },
                  { label: 'Number', id: 'number', type: 'label', selected: type === 'number' },
                  { label: 'Date', id: 'date', type: 'label', selected: type === 'date' },
                  { label: 'Select', id: 'select', type: 'label', selected: type === 'select' },
                ]}
              />
            </View>
          </View>

          <FormTextArea label={t('dictionary.description')} control={handler.control} name='description' />
          {iife(() => {
            // Need to have a discriminated function here to get the right type
            const getHandlerOf = <T extends ContentAttributeType>(
              _t: T
            ): UseFormReturn<ContentAttribute & { type: T }, unknown> => {
              return handler as unknown as UseFormReturn<ContentAttribute & { type: T }, unknown>
            }

            if (type === 'select') {
              const h = getHandlerOf('select')
              return <FormOptions control={h.control} />
            }
            // TODO: add user etc
          })}
          <HorizontalDivider />
          <VisibilityView onClick={() => {}} />
          {JSON.stringify(errors)}
          <Button type='submit'>{t('dictionary.save')}</Button>
        </View>
      </form>
    </Panel>
  )
}
