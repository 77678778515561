import React, { useEffect, useState } from 'react'
import { IconList, IconListItem } from 'sierra-client/components/common/icon-list'
import { RouterLink } from 'sierra-client/components/common/link'
import { TagsBasic } from 'sierra-client/components/common/tags-basic'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { percentage, useGetFormattedTime, useLocalizedFormatters } from 'sierra-client/core/format'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import { selectors as settingsSelectors } from 'sierra-client/state/author-course-settings/slice'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { SmartCardsGenerator } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/smart-cards-generator'
import { EmptyBody, EmptySection } from 'sierra-client/views/manage/components/common'
import { OverviewItem } from 'sierra-client/views/manage/components/overview-item'
import {
  CourseFeedbackTable,
  CourseFeedbackTableProps,
} from 'sierra-client/views/manage/courses/components/course-feedback-table'
import { UseCourseDetailsData } from 'sierra-client/views/manage/courses/use-manage-course-details'
import { Separator } from 'sierra-client/views/showcase/common'
import { Card, SmartCards } from 'sierra-client/views/showcase/sections/lil-stack'

import { CourseKind } from 'sierra-domain/api/common'
import { CourseDetailResponse } from 'sierra-domain/api/manage'
import { CourseId, TagId } from 'sierra-domain/api/nano-id'
import { assertNever, iife } from 'sierra-domain/utils'
import { Icon, MenuButton } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Spacer, Switch, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export const LastEditedSection: React.FC<{
  publishedAt: Date | undefined
  lastEditedAt: Date | undefined
}> = ({ publishedAt, lastEditedAt }) => {
  const { t } = useTranslation()
  const { formatTimeAgo } = useLocalizedFormatters()

  const showEdited =
    lastEditedAt !== undefined &&
    publishedAt !== undefined &&
    publishedAt.getTime() !== lastEditedAt.getTime()

  return (
    <View direction='column' gap='4'>
      {publishedAt !== undefined && (
        <Text size='small'>{t('manage.published-at', { date: formatTimeAgo(publishedAt) })}</Text>
      )}
      {lastEditedAt !== undefined && showEdited && (
        <Text size='small' color='grey50'>
          {t('manage.last-edited', { date: formatTimeAgo(lastEditedAt) })}
        </Text>
      )}
    </View>
  )
}

export const ActionsSection: React.FC<{
  courseId: CourseId
  courseKind: CourseKind
  canEdit: boolean
  canEditMetadata: boolean
  canDelete: boolean
  onClickDelete: () => void
}> = ({ courseId, courseKind, canEdit, canEditMetadata, canDelete, onClickDelete }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const showEdit = iife(() => {
    switch (courseKind) {
      case 'native:self-paced':
      case 'native:live':
        return canEdit
      case 'linkedin':
      case 'link':
      case 'scorm':
      case 'native:course-group':
      case 'scorm:course-group':
      case 'native:event-group':
        return false
      default:
        assertNever(courseKind)
    }
  })

  const viewHref = iife(() => {
    switch (courseKind) {
      case 'linkedin':
      case 'link':
      case 'scorm':
        return `/course/${courseId}`
      case 'native:self-paced':
        return `/s/${courseId}`
      case 'native:course-group':
      case 'scorm:course-group':
        return `/course/${courseId}`
      case 'native:event-group':
        return `/event/${courseId}`
      case 'native:live':
        return undefined
      default:
        assertNever(courseKind)
    }
  })

  const editHref = iife(() => {
    switch (courseKind) {
      case 'native:live':
        return `/create/l/${courseId}`
      case 'native:self-paced':
        return `/create/s/${courseId}`
      case 'linkedin':
      case 'link':
      case 'scorm':
      case 'native:course-group':
      case 'scorm:course-group':
      case 'native:event-group':
        return undefined
      default:
        assertNever(courseKind)
    }
  })

  const viewButton = (
    <Button variant='secondary' disabled={viewHref === undefined}>
      {t('admin.view')}
    </Button>
  )

  return (
    <View wrap='wrap'>
      {viewHref !== undefined ? <RouterLink href={viewHref}>{viewButton}</RouterLink> : viewButton}
      {showEdit && editHref !== undefined && (
        <RouterLink href={editHref}>
          <Button variant='secondary'>{t('content.edit')}</Button>
        </RouterLink>
      )}
      {canEditMetadata && (
        <MenuButton
          variant='secondary'
          menuItems={[
            {
              id: 'delete-course',
              type: 'label',
              color: 'destructive/background',
              disabled: !canDelete,
              label: t('admin.delete'),
            },
          ]}
          onSelect={item => {
            switch (item.id) {
              case 'delete-course':
                onClickDelete()
                return
              default:
                assertNever(item.id)
            }
          }}
          menuLabel={t('dictionary.more-options')}
          onPrimaryClick={async () => {
            await dispatch(settingsActions.fetch({ courseId }))
            void dispatch(settingsActions.setView('course-settings'))
          }}
        >
          {t('settings.settings')}
        </MenuButton>
      )}
    </View>
  )
}

export const QuickSettingsSection: React.FC<{
  courseId: CourseId
  courseKind: CourseKind
  isVisible: boolean
  saveVisibility: UseCourseDetailsData['saveVisibility']
}> = ({ courseId, isVisible, saveVisibility }) => {
  const { t } = useTranslation()
  const permissions = useContentKindPermissions(courseId)

  if (!permissions.has('EDIT_VISIBLE_EVERYWHERE')) return null

  return (
    <View direction='column'>
      <Separator />
      <Text size='regular' bold>
        {t('admin.settings.visibility')}
      </Text>
      <Spacer size='4' />
      <View direction='row'>
        <Switch
          checked={isVisible}
          onChange={async (checked: boolean): Promise<void> => {
            await saveVisibility(courseId, checked)
          }}
          ariaLabel={t('admin.settings.visibility-label')}
        />
        <Text size='small' bold>
          {t('admin.settings.visibility-label')}
        </Text>
      </View>
      <Text size='small' color='grey35'>
        {t('manage.courses.visibility-byline')}
      </Text>
    </View>
  )
}

export const SmartReviewSection: React.FC<{
  courseId: CourseId
  courseKind: CourseKind
}> = ({ courseId }) => {
  const permissions = useContentKindPermissions(courseId)

  const dispatch = useDispatch()

  const originalSettings = useSelector(settingsSelectors.selectOriginalSettings)

  const [smartCards, setSmartCards] = useState<Card[] | undefined>(undefined)

  useEffect(() => {
    if (originalSettings !== 'loading')
      setSmartCards(
        originalSettings.nativeCourseSettings?.smartCards?.map(card => ({
          id: card.id,
          title: card.title,
          description: card.description,
          image: card.image,
          theme: card.theme?.name,
          sourceCourseId: card.sourceCourseId,
        }))
      )
  }, [originalSettings])

  useEffect(() => {
    void dispatch(settingsActions.fetch({ courseId }))
  }, [courseId, dispatch])

  if (!permissions.has('EDIT_VISIBLE_EVERYWHERE')) return null

  return (
    <>
      <View direction='column'>
        <Separator />
        <SmartCards />
        <SmartCardsGenerator
          selfPacedContentId={courseId}
          smartCards={smartCards}
          setSmartCards={setSmartCards}
          layout='manage'
          assetContext={{ type: 'course', courseId }}
        />
      </View>
    </>
  )
}

export const DurationSection: React.FC<{
  timeTotal: number
}> = ({ timeTotal }) => {
  const time = useGetFormattedTime(timeTotal, false)

  if (timeTotal === 0) {
    return <></>
  }

  return (
    <IconList>
      <IconListItem iconId='time' text={time} color='foreground/primary' />
    </IconList>
  )
}

const PathTitleLabel = styled(Text).attrs({
  as: 'span',
  size: 'small',
  bold: true,
})`
  overflow: hidden;
  text-overflow: ellipsis;
`

const PathLinkContainer = styled.a`
  display: flex;
  align-items: center;
  max-width: 100%;
  transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
`

const PathThumbnail = styled(Thumbnail)`
  --size: 1.5rem;
  width: var(--size);
  min-width: var(--size);
  height: var(--size);
  min-height: var(--size);
  border-radius: 0.5rem;

  margin-right: 6px;
`

const NoHeight = styled.div``

// Description, tags, paths, etc.
export const OtherCourseDetailsSection: React.FC<{
  description: string | undefined
  tags: TagId[]
  includedInPaths: CourseDetailResponse['includedInPaths']
}> = ({ description, tags, includedInPaths }) => {
  const { t } = useTranslation()

  const assetResolver = useAssetResolver({ size: 'course-sm' })

  return (
    <>
      {description !== undefined && description.trim().length > 0 && (
        <>
          <Separator />
          <Text size='regular' bold>
            {t('admin.organization.description')}
          </Text>
          <Spacer size='xxsmall' />
          <NoHeight>
            <FreeTextEditor
              inputId='event-group-description'
              content={description}
              useHtml
              editable={false}
            />
          </NoHeight>
        </>
      )}
      {tags.length > 0 && (
        <>
          <Separator />
          <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular' bold>
            {t('admin.organization.tags.title')}
          </Text>
          <Spacer size='xxsmall' />
          <TagsBasic tagIds={tags} onClick={id => getGlobalRouter().navigate({ to: `/manage/tags/${id}` })} />
        </>
      )}
      {includedInPaths.length > 0 && (
        <>
          <Separator />
          <Text size='regular' bold>
            {t('admin.course.included-in-paths-title')}
          </Text>
          <Spacer size='xxsmall' />
          <View direction='column' alignItems='flex-start'>
            {includedInPaths.map(({ id, title, image }) => (
              <RouterLink key={id} href={`/manage/paths/${id}`}>
                <PathLinkContainer>
                  <PathThumbnail image={assetResolver(image, { type: 'path', pathId: id })} />
                  {title === '' ? (
                    <PathTitleLabel italic>{t('dictionary.no-title')}</PathTitleLabel>
                  ) : (
                    <PathTitleLabel title={title}>{title}</PathTitleLabel>
                  )}
                </PathLinkContainer>
              </RouterLink>
            ))}
          </View>
        </>
      )}
    </>
  )
}

export const OverviewSection: React.FC<{
  courseKind: CourseKind
  completionRate: number | undefined
  rating: number | undefined
  ratingCount: number | undefined
  directAssignmentsCount: number
  selfStartedUsersCount: number
}> = ({ courseKind, completionRate, rating, ratingCount, directAssignmentsCount, selfStartedUsersCount }) => {
  const { t } = useTranslation()
  if (courseKind === 'native:live') return null
  return (
    <View direction='column' gap='xsmall'>
      <Text size='large' bold>
        {t('content.overview')}
      </Text>
      <View alignItems='center' gap='xlarge'>
        <OverviewItem
          data={directAssignmentsCount}
          label={t('entity.enrollment', { count: directAssignmentsCount })}
        />
        <OverviewItem
          data={selfStartedUsersCount}
          label={t('entity.self-start', { count: selfStartedUsersCount })}
        />
        <OverviewItem
          data={rating !== undefined ? rating.toFixed(1) : '-'}
          label={t('table.rating')}
          disabled={rating === undefined}
          tooltip={
            ratingCount !== undefined
              ? t('manage.courses.based-on-n-ratings', { count: ratingCount })
              : undefined
          }
        />
        <OverviewItem
          data={completionRate !== undefined ? percentage(completionRate) : '-'}
          label={t('table.progress')}
          disabled={completionRate === undefined}
        />
      </View>
    </View>
  )
}

export const FeedbackSection: React.FC<CourseFeedbackTableProps> = ({ data }) => {
  const { t } = useTranslation()
  if (data.length === 0) {
    return (
      <View direction='column' gap='medium'>
        <Text size='large' bold>
          {t('dictionary.feedback')}
        </Text>
        <EmptySection>
          <Icon iconId='generate--paragraph' size='size-14' color='foreground/primary' />
          <Spacer size='xsmall' />
          <Text size='regular' bold>
            {t('manage.course.feedback.empty-title')}
          </Text>
          <Spacer size='4' />
          <EmptyBody size='small' align='center'>
            {t('manage.course.feedback.empty-message')}
          </EmptyBody>
          <Spacer size='small' />
        </EmptySection>
      </View>
    )
  }

  return <CourseFeedbackTable data={data} />
}
