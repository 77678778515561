import storage from 'local-storage-fallback'
import { FC, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { config } from 'sierra-client/config/global-config'
import { Meta } from 'sierra-client/error/log-meta'
import { useDevelopmentSnackbar } from 'sierra-client/hooks/use-debug-notif'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { logger } from 'sierra-client/logger/logger'
import { getGlobalRouter } from 'sierra-client/router'
import { FCC } from 'sierra-client/types'
import { WhoopsPage } from 'sierra-client/views/whoops-page'
import z from 'zod'

function debug(log: string, id: string): void {
  logger.debug(`[StrategicErrorBoundary] ${log}`, {
    tags: {
      errorBoundary: id,
    },
  })
}

function parseJSONSafe(raw: string): unknown {
  try {
    return JSON.parse(raw)
  } catch (error) {
    return null
  }
}

const Strategy = z.enum(['remount', 'reload', 'redirect'])

const Attempt = z.object({
  strategy: Strategy,
  timestamp: z.number(),
})

type Attempt = z.infer<typeof Attempt>
type Strategy = z.infer<typeof Strategy>

class SessionStorage {
  private key: string
  private ttl: number

  constructor({ id, ttl = 3000 }: { id: string; ttl?: number }) {
    this.key = `__sana-recovery-${id}`
    this.ttl = ttl
  }

  read(): Strategy | null {
    const raw = storage.getItem(this.key) ?? ''
    const parsed = Attempt.safeParse(parseJSONSafe(raw))

    if (parsed.success === false) {
      return null
    }

    const now = Date.now()
    const { strategy, timestamp } = parsed.data
    const isExpired = now - timestamp > this.ttl

    if (isExpired) {
      this.delete()
      return null
    }

    return strategy
  }

  write(strategy: Strategy): void {
    const attempt: Attempt = { strategy, timestamp: Date.now() }
    const serialized = JSON.stringify(attempt)

    storage.setItem(this.key, serialized)
  }

  delete(): void {
    storage.removeItem(this.key)
  }

  supported(): boolean {
    return typeof window !== 'undefined' && typeof storage !== 'undefined'
  }
}

/**
 * TODO(anton): investigate if this would be viable for environments without session storage:
 *
 * Storage based on URL query parameters:
 * class URLStorage {
 *   ...
 * }
 */

type RecoveryUtilities = {
  remountSubtree: FallbackProps['resetErrorBoundary']
  reloadPage: () => void
}
export type ErrorBoundaryFallbackComponentProps = Pick<FallbackProps, 'error'> &
  RecoveryUtilities & { sentryEventId: string | undefined }
export type ErrorBoundaryFallbackComponent = FC<ErrorBoundaryFallbackComponentProps>

const strategyImplementations: Record<Strategy, { execute: (utilities: RecoveryUtilities) => void }> = {
  remount: {
    execute({ remountSubtree }) {
      remountSubtree()
    },
  },
  redirect: {
    // TODO: Parametrize this, to make it usable from /create/:document/:file to /create/:document
    execute() {
      void getGlobalRouter().navigate({ to: '/' })
    },
  },
  reload: {
    execute({ reloadPage }) {
      // TODO: This 'disable' is after adding the eslint rule. disabling to avoid breaking change
      // eslint-disable-next-line no-unused-expressions
      reloadPage
    },
  },
}

const InternalErrorHandler: FC<
  FallbackProps & { id: string; strategies: Strategy[]; Fallback: ErrorBoundaryFallbackComponent }
> = ({ resetErrorBoundary, error, id, strategies, Fallback }) => {
  const [fallback, setFallback] = useToggle()
  const utils: RecoveryUtilities = useMemo(
    () => ({
      remountSubtree: resetErrorBoundary,
      reloadPage: () => {
        window.location.reload()
      },
    }),
    [resetErrorBoundary]
  )

  const [sentryEventId, setSentryEventId] = useState<string | undefined>()

  const { reportInDev } = useDevelopmentSnackbar()

  useEffect(() => {
    if (fallback) {
      return
    }

    try {
      // TODO: Use storage.supported() to pick an appropriate storage implementation
      const storage = new SessionStorage({
        id,
      })

      if (!storage.supported()) {
        setFallback.on()
        return
      }

      const previousStrategy = storage.read()
      const nextStrategy = strategies.find((availableStrategy, index) => {
        if (index === 0 && previousStrategy === null) return true
        if (index > 0 && strategies[index - 1] === previousStrategy) return true
        return false
      })

      if (nextStrategy === undefined) {
        const message = 'no more strategies left, rendering fallback'
        debug(message, id)
        reportInDev(`${message}, error boundary: ${id}`, { variant: 'error' })
        setFallback.on()
        return
      } else {
        const message = `trying to recover with '${nextStrategy}' strategy`
        debug(message, id)
        reportInDev(`${message}, error boundary: ${id}`, {
          variant: 'warning',
        })
        storage.write(nextStrategy)

        strategyImplementations[nextStrategy].execute(utils)
      }
    } catch (error) {
      // Something in the boundary itself broke. Render the fallback.
      const eventId = logger.captureError(error, {
        errorBoundary: id,
      })
      setSentryEventId(eventId)
      setFallback.on()
    }
  }, [reportInDev, error, fallback, id, resetErrorBoundary, setFallback, strategies, utils])

  return !fallback ? null : <Fallback sentryEventId={sentryEventId} error={error} {...utils} />
}

export const StrategicErrorBoundary: FCC<{
  id: string
  strategies?: Strategy[]
  Fallback?: ErrorBoundaryFallbackComponent
  resetKeys?: unknown[]
  beforeSend?: (error: Error) => Meta
}> = ({ id, children, strategies = ['remount', 'reload'], Fallback = WhoopsPage, resetKeys, beforeSend }) => {
  const [sentryEventId, setSentryEventId] = useState<string | undefined>()

  return (
    <ErrorBoundary
      onError={error => {
        // ensure we don't override errorBoundary tag accidentally
        const { tags, ...rest } = beforeSend?.(error) ?? {}

        const eventId = logger.captureError(error, {
          ...rest,
          tags: {
            errorBoundary: id,
            ...tags,
          },
        })

        setSentryEventId(eventId)
      }}
      fallbackRender={props => (
        <InternalErrorHandler
          {...props}
          id={id}
          strategies={config.scorm.isScorm ? [] : strategies}
          Fallback={fallbackProps => <Fallback {...fallbackProps} sentryEventId={sentryEventId} />}
        />
      )}
      resetKeys={resetKeys}
    >
      {children}
    </ErrorBoundary>
  )
}
