import { keepPreviousData } from '@tanstack/react-query'
import React, { useCallback, useMemo, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ContentByIdsQuery, EverythingSearchAutoCompleteQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLImage } from 'sierra-client/api/graphql/util/convert-gql-image'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { Context } from 'sierra-client/lib/filter/components/common'
import {
  ValueAnchor,
  addPredValue,
  fromPredicate,
  removePredValue,
} from 'sierra-client/lib/filter/components/predicate-utils'
import { useInternalSearch } from 'sierra-client/views/global-assistant/hooks/use-internal-search'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { ContentType, SanaApiResult } from 'sierra-domain/api/search'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Predicate } from 'sierra-domain/filter/datatype/pred'
import { setPred } from 'sierra-domain/filter/operations'
import { assertNever, isDefined } from 'sierra-domain/utils'
import { Autocomplete, DefaultStyledAutocompleteOption, FormElement } from 'sierra-ui/components'
import { ListContainer, SelectedPill } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { IconButton, Text } from 'sierra-ui/primitives'

const contentByIdsQuery = graphql(`
  query ContentByIds($ids: [InputContentId!]!) {
    content: contentWithIds(ids: $ids) {
      contentId
      __typename
      ... on Course {
        courseId
      }
      ... on Path {
        pathId
      }
      ... on Program {
        programId: id
      }
      title
      image {
        ...ImageFragment
      }
    }
  }
`)

const useContentFromPredicate = (predicate: Predicate): ContentByIdsQuery['content'] => {
  const contentIds = fromPredicate(predicate)
    .map(value => {
      switch (value.type) {
        case 'value.content-id':
          return value.value
        case 'value.course-id':
          return `course:${value.value}`
        case 'value.path-id':
          return `path:${value.value}`
        case 'value.program-id':
          return `program:${value.value}`
        default:
          return undefined
      }
    })
    .filter(isDefined)

  const response = useGraphQuery(
    {
      document: contentByIdsQuery,
      queryOptions: {
        enabled: contentIds.length !== 0,
        staleTime: 60 * 1000,
        placeholderData: keepPreviousData,
      },
    },
    { ids: contentIds }
  )

  if (contentIds.length === 0) {
    return []
  }

  return response.data?.content ?? []
}

const useLabelsForContentPredicate = (predicate: Predicate): string[] => {
  const content = useContentFromPredicate(predicate)
  const labels = content.map(it => it.title)

  return labels
}

export const ContentAutocompleteLabel = React.forwardRef<HTMLDivElement, { predicate: Predicate }>(
  ({ predicate, ...rest }, ref) => {
    const labels = useLabelsForContentPredicate(predicate)
    const label = labels.join(', ')

    return <ValueAnchor {...rest} ref={ref} label={label} data-testid='content-autocomplete-anchor' />
  }
)

type ValueContentId =
  | {
      type: 'value.program-id'
      value: string
    }
  | {
      type: 'value.path-id'
      value: string
    }
  | {
      type: 'value.course-id'
      value: string
    }
  | {
      type: 'value.content-id'
      value: string
    }

const valueContentId = (content: EverythingSearchAutoCompleteQuery['content']['data'][0]): ValueContentId => {
  switch (content.__typename) {
    case 'Program':
      return { type: 'value.program-id', value: content.programId }
    case 'Path':
      return { type: 'value.path-id', value: content.pathId }
    default:
      return { type: 'value.course-id', value: content.courseId }
  }
}

type AutoCompleteContent = {
  id: string
  value: ValueContentId
  title: string
  image: ImageUnion | undefined
}

type AutoCompleteContentProps = {
  ctx: Context
  predicate: Predicate
  contentTypes: ContentType[]
  formLabel: TranslationKey
}

const resolveValue = (extraArgs: SanaApiResult['extraArgs']): AutoCompleteContent['value'] | undefined => {
  switch (extraArgs.type) {
    case 'sana-course':
      return { type: 'value.course-id', value: extraArgs.id } as const
    case 'sana-path':
      return { type: 'value.path-id', value: extraArgs.id } as const
    case 'sana-program':
      return { type: 'value.program-id', value: extraArgs.id } as const
    case 'sana-live-session':
    case 'sana-card':
    case 'sana-video-section':
    case 'sana-recording-section':
      return undefined
    default:
      assertNever(extraArgs)
  }
}

const resolveImage = (extraArgs: SanaApiResult['extraArgs']): ImageUnion | undefined => {
  switch (extraArgs.type) {
    case 'sana-course':
      return extraArgs.image
    case 'sana-path':
      return extraArgs.image
    case 'sana-program':
      return extraArgs.image

    case 'sana-live-session':
    case 'sana-card':
    case 'sana-video-section':
    case 'sana-recording-section':
      return undefined
    default:
      assertNever(extraArgs)
  }
}

export const ContentAutoComplete: React.FC<AutoCompleteContentProps> = ({
  ctx,
  predicate,
  contentTypes,
  formLabel,
}) => {
  const content = useContentFromPredicate(predicate)
  const [query, setQuery] = useState('')
  const { t } = useTranslation()

  const selectedContent = content
    .map(it => {
      return {
        id: it.contentId,
        value: valueContentId(it),
        title: it.title,
        image: convertGQLImage(it.image),
      }
    })
    .filter(isDefined)

  const onSelect = useCallback(
    (content: AutoCompleteContent) => {
      ctx.update(f => setPred(f, addPredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const onUnselect = useCallback(
    (content: AutoCompleteContent) => {
      ctx.update(f => setPred(f, removePredValue(predicate, content.value)))
    },
    [ctx, predicate]
  )

  const searchResult = useInternalSearch({
    query,
    contentTypes,
  })

  const assetContextForItem = (item: AutoCompleteContent): AssetContext => {
    switch (item.value.type) {
      case 'value.course-id':
        return { type: 'course', courseId: CourseId.parse(item.value.value) }
      case 'value.program-id':
        return { type: 'program', programId: ProgramId.parse(item.value.value) }
      case 'value.path-id':
        return { type: 'path', pathId: PathId.parse(item.value.value) }
      case 'value.content-id':
        return { type: 'unknown' }
      default:
        assertNever(item.value)
    }
  }

  const assetResolver = useAssetResolver({ size: 'default' })
  const matchingItems = useMemo(
    () =>
      searchResult.data?.rankedResults
        .filter(it => it.type === 'sana')
        .filter(it => {
          if ('id' in it.extraArgs) {
            const ignoreIds = selectedContent.map(it => it.value.value)
            return ignoreIds.includes(it.extraArgs.id) === false
          } else {
            return false
          }
        })
        .flatMap(it => {
          const value = resolveValue(it.extraArgs)
          if (value === undefined) return []

          return [
            {
              id: it.id,
              value: value,
              title: it.title ?? '-',
              image: resolveImage(it.extraArgs),
            },
          ]
        }) ?? [],
    [searchResult.data?.rankedResults, selectedContent]
  )

  return (
    <FormElement label={t(formLabel)}>
      <Autocomplete
        placeholder={t('menu.search.placeholder')}
        autofocus
        query={query}
        onQueryChange={setQuery}
        selectedItems={selectedContent}
        matchingItems={matchingItems}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(item, { onUnselect, ...props }) => {
          const assetContext = assetContextForItem(item)
          const imgSrc = assetResolver(item.image, assetContext)
          return (
            <SelectedPill key={item.id} {...props}>
              <Thumbnail image={imgSrc} height={1} width={1.375} radius={0.25} />
              <Text color='foreground/primary' bold>
                {item.title}
              </Text>
              <IconButton iconId='close' onClick={onUnselect} size='small' variant='transparent' />
            </SelectedPill>
          )
        }}
        renderMatchingItemList={({ getItemProps }) => (
          <ListContainer>
            {matchingItems.map((item, index) => {
              const assetContext = assetContextForItem(item)
              const imgSrc = assetResolver(item.image, assetContext)
              return (
                <DefaultStyledAutocompleteOption key={item.id} {...getItemProps(item, index)}>
                  <Thumbnail image={imgSrc} height={1} width={1.375} radius={0.25} />
                  <Text color='foreground/primary'>{item.title}</Text>
                </DefaultStyledAutocompleteOption>
              )
            })}
          </ListContainer>
        )}
      />
    </FormElement>
  )
}
