import { useCallback, useEffect, useState } from 'react'
import { config } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GetSlackConnectionResponse, SlackConnection } from 'sierra-domain/api/manage'
import { XRealtimeSlackConnection, XRealtimeSlackRemoveConnection } from 'sierra-domain/routes'
import { Button } from 'sierra-ui/primitives'

export const useSlackIntegrationInfo = (): {
  connection: SlackConnection | undefined
  removeConnection: () => void
} => {
  const { postWithUserErrorException } = usePost()
  const [info, setInfo] = useState<GetSlackConnectionResponse | undefined>(undefined)

  useEffect(() => {
    void postWithUserErrorException(XRealtimeSlackConnection, {}).then(setInfo)
  }, [postWithUserErrorException])

  const removeConnection = useCallback(async () => {
    setInfo(undefined)
    await postWithUserErrorException(XRealtimeSlackRemoveConnection, {})
  }, [postWithUserErrorException])

  return {
    connection: info?.connection,
    removeConnection,
  }
}

export const AddToSlackButton = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Button
      variant='secondary'
      onClick={() => {
        // TODO(uk) Move this to application config!
        const clientId = '92257043540.3615578229124' // Sana app
        // const clientId = '92257043540.7560336122838' // Sana test app
        const host = window.location.host
        const redirectHost =
          host.endsWith('.localhost.sana.dev') || host.endsWith('.ngrok-free.app') ? host : 'sana.ai'
        const redirectUrl = `https://${redirectHost}/x-realtime/slack/oauth-redirect`
        window.location.href = `https://slack.com/oauth/v2/authorize?scope=users:read,chat:write,users:read,users:read.email&user_scope=&redirect_uri=${encodeURIComponent(
          redirectUrl
        )}&client_id=${clientId}&state=${encodeURIComponent(config.organization.uuid)}`
      }}
    >
      {t('manage.settings.slack.add-connection')}
    </Button>
  )
}
