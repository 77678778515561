import { AnimatePresence, motion } from 'framer-motion'
import {
  CountPill,
  Notice,
  SelectedIdentityPill,
} from 'sierra-client/components/common/identities-selector/atoms'
import { getIdentityBrand } from 'sierra-client/components/common/identities-selector/identities-utils'
import {
  FetchIdentities,
  IdentitiesSelectorProps,
  Identity,
} from 'sierra-client/components/common/identities-selector/types'
import { useIdentitiesSelectorDataLayer } from 'sierra-client/components/common/identities-selector/use-identities-selector'
import { VirtualizedIdentities } from 'sierra-client/components/common/identities-selector/virtualized-identities'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Autocomplete, Icon, RoundAvatar, UserDisplay } from 'sierra-ui/components'
import { SelectedPillRemoveButton } from 'sierra-ui/components/autocomplete/reference-implementation/atoms'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export const IdentitiesSelector: React.FC<IdentitiesSelectorProps> = ({
  onSelect,
  onUnselect,
  selectedIdentities,
  fetchIdentities,
  placeholder,
  disabled,
  fetchOptions,
  trailingVisual,
  selectorId = 'unnamed',
  grow = false,
}) => {
  const { t } = useTranslation()
  const selected = useDeepEqualityMemo(selectedIdentities)
  const { query, setQuery, searchQuery, unavailableUsers } = useIdentitiesSelectorDataLayer({
    selected,
    onSelect,
    fetchIdentities,
    overrideCacheKey: fetchOptions?.cacheKey,
    staleTime: fetchOptions?.staleTime,
    cacheTime: fetchOptions?.cacheTime,
    disabled,
    selectorId,
  })

  const data = searchQuery.isFetching || searchQuery.data === undefined ? [] : searchQuery.data

  return (
    <View grow={grow} direction='column' gap='none'>
      <Autocomplete
        disabled={disabled}
        query={query}
        onQueryChange={setQuery}
        placeholder={placeholder ? t(placeholder) : undefined}
        matchingItems={data}
        selectedItems={selected}
        onSelect={onSelect}
        onUnselect={onUnselect}
        renderSelectedItem={(identity, { onUnselect, ...props }) => {
          const { color, image } = getIdentityBrand(identity)
          return (
            <SelectedIdentityPill key={identity.id} {...props}>
              <View grow direction='row' gap='6'>
                <UserDisplay
                  primaryText={identity.name}
                  avatar={{ firstName: identity.name, color, src: image, size: 'minuscule' }}
                />
                {identity.identity.type === 'userGroup' && <CountPill>{identity.label}</CountPill>}
              </View>
              <SelectedPillRemoveButton
                disabled={disabled}
                aria-label={t('dictionary.remove')}
                onClick={onUnselect}
              />
            </SelectedIdentityPill>
          )
        }}
        renderMatchingItemList={({ getItemProps }) => (
          <VirtualizedIdentities getItemProps={getItemProps} matchingItems={data} />
        )}
        trailingVisual={trailingVisual}
      />

      <AnimatePresence>
        {unavailableUsers.length > 0 && (
          <Notice>
            <View alignItems='flex-start'>
              <View paddingTop='2'>
                <Icon iconId='warning' color='currentColor' size='size-16' />
              </View>

              <View direction='column'>
                <Text bold color='currentColor'>
                  {t('manage.certificates.users-not-found')}
                </Text>
                <Text color='currentColor'>
                  <motion.ul
                    initial={{
                      opacity: 0,
                    }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{
                      opacity: 0,
                    }}
                  >
                    {unavailableUsers.map(email => (
                      <li key={email}>{email}</li>
                    ))}
                  </motion.ul>
                </Text>
              </View>
            </View>
          </Notice>
        )}
      </AnimatePresence>
    </View>
  )
}

const SelectedItemContainer = styled(View)`
  justify-content: space-between;
  padding: 0 8px;
  border: 1px solid ${token('border/strong')};
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
`
const emptySelected: Identity[] = []

export const IdentitiesSingleSelector: React.FC<{
  onSelect: (id: Identity) => void
  onUnselect: (id: Identity) => void
  value?: Identity
  disabled?: boolean
  fetchIdentities: FetchIdentities
  placeholder?: string
  fetchOptions?: { staleTime?: number; cacheKey?: string; cacheTime?: number }
  selectorId?: string
}> = ({
  placeholder,
  disabled,
  value,
  onSelect,
  onUnselect,
  fetchIdentities,
  fetchOptions,
  selectorId = 'unnamed',
}) => {
  const {
    query,
    setQuery,
    searchQuery: matching,
  } = useIdentitiesSelectorDataLayer({
    // as you can only choose one, single select will give you all values
    selected: emptySelected,
    onSelect,
    fetchIdentities,
    overrideCacheKey: fetchOptions?.cacheKey,
    staleTime: fetchOptions?.staleTime,
    cacheTime: fetchOptions?.cacheTime,
    selectorId,
  })

  if (value !== undefined) {
    const { color, image } = getIdentityBrand(value)

    return (
      <SelectedItemContainer grow onClick={() => onUnselect(value)}>
        <View>
          <RoundAvatar size='small' firstName={value.name} color={color} src={image} />
          <Text bold>{value.name}</Text>
        </View>
        <Icon iconId='chevron--down--small' size='size-16' />
      </SelectedItemContainer>
    )
  } else {
    return (
      <Autocomplete
        disabled={disabled}
        placeholder={placeholder}
        query={query}
        onQueryChange={setQuery}
        matchingItems={matching.data ?? []}
        onSelect={onSelect}
        renderMatchingItemList={({ getItemProps }) => (
          <VirtualizedIdentities getItemProps={getItemProps} matchingItems={matching.data ?? []} />
        )}
      />
    )
  }
}
