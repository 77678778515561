import { useTranslation } from 'sierra-client/hooks/use-translation'
import { FilePreview } from 'sierra-client/views/manage/homeworks/homework-file-preview'
import { LightText } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { HomeworkDetailsResponse } from 'sierra-domain/homework'
import { Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const SubmissionWrapper = styled(View)`
  cursor: pointer;
  width: 96px;
  height: 60px;
  min-width: 96px;
  min-height: 60px;
  border-radius: ${p => p.theme.borderRadius['size-6']};

  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: center;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const SubmissionCell: React.FC<{
  submissionFileInfo: HomeworkDetailsResponse['data'][number]['submissionData']
  onClick: () => void
}> = ({ submissionFileInfo, onClick }) => {
  const { t } = useTranslation()

  if (submissionFileInfo === undefined) {
    return (
      <View>
        <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='small' as={LightText}>
          {t('homework.no-submission')}
        </Text>
      </View>
    )
  }

  return (
    <View gap='16' onClick={onClick}>
      <Tooltip title={t('dictionary.view')}>
        <SubmissionWrapper>
          {'fileId' in submissionFileInfo ? (
            <FilePreview
              fileInfo={submissionFileInfo}
              renderIconForMimeType={{ 'application/pdf': true, 'video/mp4': true }}
            />
          ) : null}
        </SubmissionWrapper>
      </Tooltip>
    </View>
  )
}
