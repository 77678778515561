import { BaseColumn } from 'sierra-client/lib/tabular/datatype/column'
import { CellWithData } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { TableData } from 'sierra-client/lib/tabular/datatype/tabledata'

export type TableRowsData<Data extends TableData = TableData> = Array<Row.Row<Data>>

export const columnToCell: Record<BaseColumn['type'], CellWithData['type']> = {
  addStepContent: 'addStepContent',
  assignedVia: 'assignedVia',
  booleans: 'boolean',
  canvas: 'canvas',
  card: 'card',
  certificateIssuedBy: 'certificateIssuedBy',
  certificates: 'certificate',
  content: 'content',
  dateTimes: 'datetime',
  detailedContents: 'detailedContents',
  duration: 'duration',
  enrolledBys: 'enrolledBy',
  eventGroups: 'eventGroup',
  expiryTimes: 'expiryTime',
  groups: 'group',
  homeworks: 'homework',
  issuedCertificates: 'issuedCertificate',
  issuesRevoked: 'issuedRevoked',
  links: 'link',
  liveSession: 'liveSession',
  numbers: 'number',
  pills: 'pill',
  programVersionNumbers: 'programVersionNumber',
  progress: 'progress',
  singleSelect: 'singleSelect',
  skillContent: 'skillContent',
  strings: 'string',
  titlesAndSubtitles: 'titleAndSubtitle',
  users: 'user',
  userStacks: 'userStack',
  assignedProgram: 'assignedProgram',
}
