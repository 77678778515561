import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useMemo, useRef } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { GoogleMeetOauth } from 'sierra-client/components/sana-now-integration-oauth/google-meet-oauth'
import { MicrosoftTeamsOauth } from 'sierra-client/components/sana-now-integration-oauth/microsoft-teams-oauth'
import { ZoomOauth } from 'sierra-client/components/sana-now-integration-oauth/zoom-oauth'
import { config } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { MeetingToolAuthentication } from 'sierra-client/features/event-integrations'
import { useAuthenticatedUserIntegrationsQuery } from 'sierra-client/hooks/use-authenticated-user-integrations-query'
import { useAvailableIntegrationActions } from 'sierra-client/hooks/use-available-integration-actions'
import { useResetBooleanAfterDelay } from 'sierra-client/hooks/use-reset-boolean-after-delay'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  getOrInferVideoCallProviderFromVideoCallSetting,
  inferVideoCallProviderFromUrl,
} from 'sierra-client/lib/sana-now/get-video-call-provider-from-video-call-setting'
import { useDispatch } from 'sierra-client/state/hooks'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { useSettingsPageEnabled } from 'sierra-client/views/manage/permissions/use-settings-page-enabled'
import { VideoCallSetting } from 'sierra-domain/content/session'
import { assert, assertNever, iife, isDefined, isNonNullable } from 'sierra-domain/utils'
import { FormElement, Icon, MenuItem } from 'sierra-ui/components'
import { Button, IconButton, InputPrimitive, LoadingSpinner, Spacer, Text, View } from 'sierra-ui/primitives'
import { DefaultDropdownTrigger, SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'
import { ReactSimpleOauth2LoginRef } from './video-call-provider-buttons/shared'

const IconContainer = styled.div<{ padLeft?: boolean; padRight?: boolean }>`
  display: grid;
  place-items: center;
  ${p =>
    p.padLeft === true &&
    css`
      padding-left: 12px;
    `}
  ${p =>
    p.padRight === true &&
    css`
      padding-right: 12px;
    `}
`
const StyledButton = styled(Button)`
  background: rgba(34, 172, 0, 0.1);
  width: 100%;
  height: 36px;
  border-radius: 10px;
  color: ${token('success/background')};
`

const ListItemIconWrapper = styled(View)``

const UrlInput: React.FC<{
  videoCallSetting: VideoCallSetting
  onChange: (url: string) => void
  disabled: boolean
}> = ({ videoCallSetting, onChange, disabled }) => {
  const { t } = useTranslation()
  const [input, setInput] = React.useState(videoCallSetting.type === 'url' ? videoCallSetting.url : '')

  const { isEnabled: recentlyClicked, setTrue: setRecentlyClicked } = useResetBooleanAfterDelay({
    delay: 5000,
  })

  if (videoCallSetting.type === 'sana') return null

  const handleCopyLink = (): void => {
    if (videoCallSetting.type === 'url') {
      void window.navigator.clipboard.writeText(videoCallSetting.url)
    }
    setRecentlyClicked()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newUrl = e.target.value.trim()
    setInput(newUrl)

    if (newUrl === '') {
      onChange('')
    } else if (newUrl.startsWith('https://')) {
      onChange(newUrl)
    } else {
      onChange(`https://${newUrl}`)
    }
  }

  const clearUrl = (): void => {
    onChange('')
    setInput('')
  }

  return (
    <View>
      <InputPrimitive
        type='text'
        value={input}
        disabled={disabled}
        leadingVisual={
          <IconContainer padLeft>
            {iife(() => {
              switch (inferVideoCallProviderFromUrl(input)) {
                case 'google-meet':
                  return <MeetLogo width={16} height={16} />
                case 'microsoft-teams':
                  return <TeamsLogo width={16} height={16} />
                case 'zoom':
                  return <ZoomLogo width={16} height={16} />
                default:
                  return <Icon color='foreground/muted' iconId='video-call' />
              }
            })}
          </IconContainer>
        }
        onChange={handleOnChange}
        placeholder={t('admin.author.sessions.paste-your-meeting-link-placeholder')}
      />
      {videoCallSetting.type === 'url' && videoCallSetting.url.length > 0 && (
        <>
          <IconButton
            variant='secondary'
            iconId={recentlyClicked ? 'checkmark' : 'clipboard'}
            tooltip={t('share.copy-link')}
            onClick={handleCopyLink}
          />
          <IconButton
            variant='transparent'
            iconId='trash-can'
            tooltip={t('dictionary.clear')}
            onClick={clearUrl}
          />
        </>
      )}
    </View>
  )
}

type MeetingIntegrationIdType = VideoCallSetting['type']

type MenuItemType =
  | MenuItem<MeetingIntegrationIdType | 'separator' | 'go-to-settings'>
  | {
      type: 'canvas'
      id: MeetingIntegrationIdType
      render: ({
        asTrigger,
        onItemClick,
      }: {
        asTrigger?: boolean | undefined
        onItemClick?: (() => void) | undefined
      }) => JSX.Element
    }

const useMenuItems = ({ selectedItemId }: { selectedItemId: MeetingIntegrationIdType }): MenuItemType[] => {
  const { t } = useTranslation()
  const meetingToolsSettings = config.organization.settings.meetingToolsSettings
  const hasAccessToChangeSettings = useSettingsPageEnabled() === true

  const meetEnabled = meetingToolsSettings.meetSettings.enabled
  const teamsEnabled = meetingToolsSettings.teamsSettings.enabled
  const zoomEnabled = meetingToolsSettings.zoomSettings.enabled
  const sanaEnabled = meetingToolsSettings.sanaSettings.enabled

  return useMemo(() => {
    const items: MenuItemType[] = []

    // We want to nudge user to go to settings so they enable a third party tool
    const anyThirdPartyToolEnabled = meetEnabled || teamsEnabled || zoomEnabled
    if (hasAccessToChangeSettings && !anyThirdPartyToolEnabled) {
      items.push({
        type: 'canvas',
        id: 'go-to-settings',
        label: 'Configure meeting tools',
        render: () => {
          return (
            <View gap='8'>
              <ListItemIconWrapper>
                <Icon iconId='video-call' size='size-16' />
              </ListItemIconWrapper>{' '}
              <Text>{'Configure meeting tools'}</Text>
            </View>
          )
        },
      })
      items.push({
        type: 'separator',
        id: 'separator',
      })
    }

    if (meetEnabled) {
      items.push({
        type: 'canvas',
        label: t('dictionary.google-meet'),
        id: 'google-meet',
        render: ({
          asTrigger,
        }: {
          asTrigger?: boolean | undefined
          onItemClick?: (() => void) | undefined
        }) => {
          return (
            <View gap='8'>
              <ListItemIconWrapper>
                <MeetLogo width={16} />
              </ListItemIconWrapper>
              <Text bold={asTrigger !== true && selectedItemId === 'google-meet'}>
                {t('dictionary.google-meet')}
              </Text>
            </View>
          )
        },
      })
    }

    if (teamsEnabled) {
      items.push({
        type: 'canvas',
        id: 'microsoft-teams',
        label: t('dictionary.microsoft-teams'),
        render: ({
          asTrigger,
        }: {
          asTrigger?: boolean | undefined
          onItemClick?: (() => void) | undefined
        }) => {
          return (
            <View gap='8'>
              <ListItemIconWrapper>
                <TeamsLogo width={16} />
              </ListItemIconWrapper>
              <Text bold={asTrigger !== true && selectedItemId === 'microsoft-teams'}>
                {t('dictionary.microsoft-teams')}
              </Text>
            </View>
          )
        },
      })
    }

    if (zoomEnabled) {
      items.push({
        type: 'canvas',
        id: 'zoom',
        label: t('dictionary.zoom'),
        render: ({
          asTrigger,
        }: {
          asTrigger?: boolean | undefined
          onItemClick?: (() => void) | undefined
        }) => {
          return (
            <View gap='8'>
              <ListItemIconWrapper>
                <ZoomLogo width={16} />
              </ListItemIconWrapper>
              <Text bold={asTrigger !== true && selectedItemId === 'zoom'}>{t('dictionary.zoom')}</Text>
            </View>
          )
        },
      })
    }

    if (sanaEnabled) {
      items.push({
        type: 'canvas',
        id: 'sana',
        label: t('dictionary.sana'),
        render: ({
          asTrigger,
        }: {
          asTrigger?: boolean | undefined
          onItemClick?: (() => void) | undefined
        }) => {
          return (
            <View gap='8'>
              <ListItemIconWrapper>
                <SanaLogo width={16} height={16} />
              </ListItemIconWrapper>
              <Text bold={asTrigger !== true && selectedItemId === 'sana'}>{t('dictionary.sana')}</Text>
            </View>
          )
        },
      })
    }

    if (items.length > 0) {
      items.push({
        type: 'separator',
        id: 'separator',
      })
    }

    items.push({
      type: 'canvas',
      id: 'url',
      label: t('author.schedule-sana-now.own-link'),
      render: ({
        asTrigger,
      }: {
        asTrigger?: boolean | undefined
        onItemClick?: (() => void) | undefined
      }) => {
        return (
          <View gap='8'>
            <Text bold={asTrigger !== true && selectedItemId === 'url'}>
              {t('author.schedule-sana-now.own-link')}
            </Text>
          </View>
        )
      },
    })

    items.push({
      type: 'canvas',
      id: 'none',
      label: t('author.schedule-sana-now.no-link'),
      render: ({
        asTrigger,
      }: {
        asTrigger?: boolean | undefined
        onItemClick?: (() => void) | undefined
      }) => {
        return (
          <View gap='8'>
            <Text bold={asTrigger !== true && selectedItemId === 'none'}>
              {t('author.schedule-sana-now.no-link')}
            </Text>
          </View>
        )
      },
    })

    return [...items]
  }, [hasAccessToChangeSettings, meetEnabled, sanaEnabled, selectedItemId, t, teamsEnabled, zoomEnabled])
}

const TriggerContainer = styled.div`
  position: relative;
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  right: 29px;
  top: 4px;
`

const CopyLinkButton = styled(IconButton).attrs({
  variant: 'transparent',
  size: 'small',
})`
  position: absolute;
  right: 32px;
  top: 7px;
`

export const MeetingIntegrationPicker: React.FC<{
  onChange: (newVideoCallSetting: VideoCallSetting) => void
  videoCallSetting: VideoCallSetting
  defaultVideoCallSettingChoice?: VideoCallSetting['type']
  isValid: boolean
  isLoading: boolean
  disabled?: boolean
  onAuthStatusChange: (isAuthenticated: boolean) => void
  onLoadingChange: (isLoading: boolean) => void
}> = ({
  onChange,
  videoCallSetting,
  defaultVideoCallSettingChoice,
  isValid,
  isLoading,
  disabled,
  onAuthStatusChange,
  onLoadingChange,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const authenticatedQuery = useAuthenticatedUserIntegrationsQuery()

  const availableIntegrations = useAvailableIntegrationActions()

  const teamsClientId = authenticatedQuery.data?.viewer.integrations.microsoft.clientId
  const zoomClientId = authenticatedQuery.data?.viewer.integrations.zoom.clientId
  const meetClientId = authenticatedQuery.data?.viewer.integrations.google.clientId

  const msTeamsAuth = availableIntegrations.microsoft.createMeetingUrl
  const zoomAuth = availableIntegrations.zoom.createMeetingUrl
  const googleMeetAuth = availableIntegrations.google.createMeetingUrl

  const [chosenMeetingTool, setChosenMeetingTool] = React.useState<MeetingIntegrationIdType>(
    videoCallSetting.type
  )

  const { isEnabled: showSuccess, setTrue: setShowSuccess } = useResetBooleanAfterDelay({ delay: 10_000 })
  const { isEnabled: recentlyClickedCopy, setTrue: setRecentlyClickedCopy } = useResetBooleanAfterDelay()

  const handleCopyProviderClick = (): void => {
    if ('url' in videoCallSetting) {
      void window.navigator.clipboard.writeText(videoCallSetting.url)
      setRecentlyClickedCopy()
    }
  }

  const chosenMeetingToolIsAuthenticated = useMemo(() => {
    switch (chosenMeetingTool) {
      case 'sana':
      case 'none':
      case 'url':
        return true
      case 'google-meet':
        return googleMeetAuth
      case 'zoom':
        return zoomAuth
      case 'microsoft-teams':
        return msTeamsAuth
      default:
        assertNever(chosenMeetingTool)
    }
  }, [chosenMeetingTool, zoomAuth, googleMeetAuth, msTeamsAuth])

  React.useEffect(() => {
    onAuthStatusChange(chosenMeetingToolIsAuthenticated)
  }, [chosenMeetingToolIsAuthenticated, onAuthStatusChange])

  const msTeamsOauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)
  const zoomOauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)
  const meetOauthRef = useRef<ReactSimpleOauth2LoginRef | null>(null)

  const onLinkGenerated = (videoCallSetting: VideoCallSetting): void => {
    void dispatch(
      Logging.liveSession.sanaNowMeetingLinkGenerated({
        meetingService: getOrInferVideoCallProviderFromVideoCallSetting(videoCallSetting),
      })
    )

    onChange(videoCallSetting)
  }

  const createZoomMeetingLinkMutation = useMutation({
    mutationFn: () =>
      graphQuery(
        graphql(`
          mutation createZoomUrl {
            createZoomUrl {
              url
            }
          }
        `)
      ),
    onSuccess: res => {
      if (isNonNullable(res.createZoomUrl?.url)) {
        onLinkGenerated({
          type: 'zoom',
          url: res.createZoomUrl.url,
        })
      } else {
        zoomOauthRef.current?.onBtnClick()
      }
    },
    onMutate: () => {
      onLoadingChange(true)
    },
    onSettled: () => {
      onLoadingChange(false)
    },
  })

  const createMsTeamsMeetingLinkMutation = useMutation({
    mutationFn: () =>
      graphQuery(
        graphql(`
          mutation createMicrosoftTeamsUrl {
            createMicrosoftTeamsUrl {
              url
            }
          }
        `)
      ),
    onSuccess: res => {
      if (isNonNullable(res.createMicrosoftTeamsUrl?.url)) {
        onLinkGenerated({
          type: 'microsoft-teams',
          url: res.createMicrosoftTeamsUrl.url,
        })
      } else {
        msTeamsOauthRef.current?.onBtnClick()
      }
    },
    onMutate: () => {
      onLoadingChange(true)
    },
    onSettled: () => {
      onLoadingChange(false)
    },
  })

  const createMeetMeetingLinkMutation = useMutation({
    mutationFn: () =>
      graphQuery(
        graphql(`
          mutation createGoogleMeetUrl {
            createGoogleMeetUrl {
              url
            }
          }
        `)
      ),
    onSuccess: res => {
      if (isNonNullable(res.createGoogleMeetUrl?.url)) {
        onLinkGenerated({
          type: 'google-meet',
          url: res.createGoogleMeetUrl.url,
        })
      } else {
        meetOauthRef.current?.onBtnClick()
      }
    },
    onMutate: () => {
      onLoadingChange(true)
    },
    onSettled: () => {
      onLoadingChange(false)
    },
  })

  const handleSelect = React.useCallback(
    (itemId: MenuItemType['id']): void => {
      assert(itemId !== 'separator', 'Separator should not be selectable')

      if (itemId === 'go-to-settings') {
        // using the global router to navigate to settings causes some bug with the pannel
        // I hack around this by by doing a hard navigation
        window.open('/settings/general', '_self')
        return
      }

      setChosenMeetingTool(itemId)

      switch (itemId) {
        case 'sana':
          onChange({ type: 'sana' })
          break
        case 'none':
          onChange({ type: 'none' })
          break
        case 'url':
          onChange({ type: 'url', url: '' })
          break
        case 'google-meet':
          if (googleMeetAuth) {
            createMeetMeetingLinkMutation.mutate()
          }
          break
        case 'zoom':
          if (zoomAuth) {
            createZoomMeetingLinkMutation.mutate()
          }
          break
        case 'microsoft-teams':
          if (msTeamsAuth) {
            createMsTeamsMeetingLinkMutation.mutate()
          }
          break
        default:
          assertNever(itemId)
      }
    },
    [
      onChange,
      googleMeetAuth,
      zoomAuth,
      msTeamsAuth,
      createMeetMeetingLinkMutation,
      createZoomMeetingLinkMutation,
      createMsTeamsMeetingLinkMutation,
    ]
  )

  useEffect(() => {
    if (defaultVideoCallSettingChoice === undefined) return

    if (
      (defaultVideoCallSettingChoice === 'google-meet' && !googleMeetAuth) ||
      (defaultVideoCallSettingChoice === 'zoom' && !zoomAuth) ||
      (defaultVideoCallSettingChoice === 'microsoft-teams' && !msTeamsAuth)
    ) {
      setChosenMeetingTool('none')
    } else {
      handleSelect(defaultVideoCallSettingChoice)
    }

    // This effect runs once to trigger generation of meeting link based on saved
    // videoCallSetting, we don't want to run it again.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAuthRequest = (): void => {
    switch (chosenMeetingTool) {
      case 'zoom':
        zoomOauthRef.current?.onBtnClick()
        break
      case 'google-meet':
        meetOauthRef.current?.onBtnClick()
        break
      case 'microsoft-teams':
        msTeamsOauthRef.current?.onBtnClick()
        break
      case 'sana':
      case 'none':
      case 'url':
        break
      default:
        assertNever(chosenMeetingTool)
    }
  }

  const menuItems = useMenuItems({ selectedItemId: chosenMeetingTool })
  const chosenMeetingToolMenuItem = menuItems.find(item => {
    if (item.id === 'none' || item.id === 'separator' || item.id === 'go-to-settings') {
      return false
    }

    return item.id === chosenMeetingTool
  })

  return (
    <FormElement grow={false} label={t('dictionary.meeting-tool')}>
      <TriggerContainer>
        <SingleSelectDropdown
          menuItems={menuItems}
          onSelect={item => handleSelect(item.id)}
          renderTrigger={() => (
            <DefaultDropdownTrigger grow>
              <View gap='none'>
                {iife(() => {
                  if (chosenMeetingToolMenuItem === undefined) {
                    return (
                      <Text color='foreground/muted'>
                        {t('author.schedule-sana-now.select-a-meeting-tool')}
                      </Text>
                    )
                  }

                  if ('render' in chosenMeetingToolMenuItem) {
                    return chosenMeetingToolMenuItem.render({ asTrigger: true })
                  }

                  return <Text>{chosenMeetingToolMenuItem.label}</Text>
                })}
              </View>
            </DefaultDropdownTrigger>
          )}
          selectedItem={chosenMeetingToolMenuItem}
        />
        {iife(() => {
          if (isLoading) {
            return <StyledLoadingSpinner padding='none' color='grey25' />
          }

          const googleMeetAndAuth = chosenMeetingTool === 'google-meet' && googleMeetAuth
          const zoomAndAuth = chosenMeetingTool === 'zoom' && zoomAuth
          const msTeamsAndAuth = chosenMeetingTool === 'microsoft-teams' && msTeamsAuth

          if (googleMeetAndAuth || zoomAndAuth || msTeamsAndAuth) {
            return (
              <CopyLinkButton
                iconId={recentlyClickedCopy ? 'checkmark' : 'clipboard'}
                onClick={handleCopyProviderClick}
              />
            )
          }
        })}
      </TriggerContainer>
      {isDefined(chosenMeetingToolMenuItem) &&
        chosenMeetingToolMenuItem.id !== 'separator' &&
        chosenMeetingToolMenuItem.id !== 'go-to-settings' &&
        chosenMeetingToolIsAuthenticated === false && (
          <MeetingToolAuthentication meetingTool={chosenMeetingToolMenuItem.id} onClick={onAuthRequest} />
        )}
      {showSuccess && (
        <StyledButton icon='checkbox--checkmark--filled'>
          {t('author.schedule-sana-now.access-allowed')}
        </StyledButton>
      )}
      {chosenMeetingTool === 'url' && (
        <View direction='column'>
          <UrlInput
            videoCallSetting={videoCallSetting}
            onChange={url => onChange({ type: 'url', url })}
            disabled={disabled === true}
          />
          {!isValid && (
            <>
              <Text size='micro' color='destructive/background'>
                {t('sana-now.edit-video-call-setting-modal.invalid-url-error')}
              </Text>
            </>
          )}
          <Spacer size='8' />
          <Text color='foreground/muted' size='micro'>
            {t('admin.author.sessions.sana-now-explanation')}
          </Text>
        </View>
      )}
      {isDefined(teamsClientId) && authenticatedQuery.isSuccess && (
        <MicrosoftTeamsOauth
          ref={msTeamsOauthRef}
          clientId={teamsClientId}
          onSuccess={() => {
            createMsTeamsMeetingLinkMutation.mutate()
            void authenticatedQuery.refetch()
            setShowSuccess()
          }}
          scope={authenticatedQuery.data.viewer.integrations.microsoft.createMeetingUrlScope.map(
            s => s.value
          )}
        />
      )}
      {isDefined(zoomClientId) && authenticatedQuery.isSuccess && (
        <ZoomOauth
          ref={zoomOauthRef}
          clientId={zoomClientId}
          onSuccess={() => {
            createZoomMeetingLinkMutation.mutate()
            void authenticatedQuery.refetch()
            setShowSuccess()
          }}
          scope={authenticatedQuery.data.viewer.integrations.zoom.createMeetingUrlScope.map(s => s.value)}
        />
      )}
      {isDefined(meetClientId) && authenticatedQuery.isSuccess && (
        <GoogleMeetOauth
          ref={meetOauthRef}
          clientId={meetClientId}
          onSuccess={() => {
            createMeetMeetingLinkMutation.mutate()
            void authenticatedQuery.refetch()
            setShowSuccess()
          }}
          scope={authenticatedQuery.data.viewer.integrations.google.createMeetingUrlScope.map(s => s.value)}
        />
      )}
      <Debug>
        <Text size='micro' color='foreground/muted'>
          <strong>Debug:&nbsp;</strong>
          {JSON.stringify(videoCallSetting, null, 2)}
        </Text>
      </Debug>
    </FormElement>
  )
}
