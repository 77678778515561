import { useEffect, useMemo } from 'react'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { fetchPartnerTemplates } from 'sierra-client/state/templates/actions'
import { selectPartnerTemplates, selectSanaTemplates } from 'sierra-client/state/templates/selectors'
import { Template } from 'sierra-client/state/templates/types'

let fetchedPartnerTemplates = false

export type UseTemplates = {
  sanaLive: Template[]
  sanaSelfPaced: Template[]
  partnerAll: Template[]
  partnerLive: Template[]
  partnerSelfPaced: Template[]
  recommended: Template[]
  community: Template[]
}

export const useTemplates = (): UseTemplates => {
  const dispatch = useDispatch()
  const sanaTemplates = useSelector(selectSanaTemplates)
  const partnerTemplates = useSelector(selectPartnerTemplates)
  const isCreateAccessible = useIsCreateAccessible()

  useEffect(() => {
    if (isCreateAccessible && !fetchedPartnerTemplates) {
      fetchedPartnerTemplates = true
      void dispatch(fetchPartnerTemplates())
    }
  }, [dispatch, isCreateAccessible])

  const filteredPartnerTemplates = useMemo(
    () => (Array.isArray(partnerTemplates) ? partnerTemplates : []),
    [partnerTemplates]
  )

  return useMemo(() => {
    const sanaLiveTemplates: Template[] = sanaTemplates.filter(t => t.contentType === 'native:live')

    const sanaSelfPacedTemplates: Template[] = sanaTemplates.filter(
      t => t.contentType === 'native:self-paced'
    )

    const recommendedLiveTemplates = ['discover-sana-live', 'standup', 'team-retro']
    const recommendedSelfPacedTemplates = [
      'sana-short-course-template',
      'new-employee-onboarding',
      'gdpr-compliance',
    ]
    const recommended = [
      ...filteredPartnerTemplates.slice(0, 3),
      ...sanaLiveTemplates.filter(t => recommendedLiveTemplates.includes(t.id)),
      ...sanaSelfPacedTemplates.filter(t => recommendedSelfPacedTemplates.includes(t.id)),
    ]

    const communityLiveTemplates = [
      'challenging-promotion-conversations',
      'first-90-days',
      'team-meeting',
      'career-conversation',
      'company-all-hands',
      'team-brainstorming',
      'company-values-session',
      'personalized-leadership',
      'team-stand-up',
      'great-1-1',
      'team-retro',
    ]
    const communitySelfPacedTemplates = [
      'how-to-turn-constructive-feedback-into-personal-growth',
      'hacking-public-speaking',
      'how-to-make-the-best-out-of-interactivity-in-sana',
      'sana-course-template',
      'menopause-in-the-workplace',
      'unlocking-ai-2',
      'leadership-for-change',
      'letting-go-and-welcoming',
      'inviting-stillness',
      'checking-in-with-yourself',
    ]
    const community = [
      ...sanaLiveTemplates.filter(t => communityLiveTemplates.includes(t.id)),
      ...sanaSelfPacedTemplates.filter(t => communitySelfPacedTemplates.includes(t.id)),
    ]

    return {
      sanaLive: sanaLiveTemplates,
      sanaSelfPaced: sanaSelfPacedTemplates,
      partnerAll: filteredPartnerTemplates,
      partnerLive: filteredPartnerTemplates.filter(t => t.contentType === 'native:live'),
      partnerSelfPaced: filteredPartnerTemplates.filter(t => t.contentType === 'native:self-paced'),
      recommended,
      community,
    }
  }, [filteredPartnerTemplates, sanaTemplates])
}
