import React from 'react'
import { useInView } from 'react-intersection-observer'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import { AddButtonSelector } from 'sierra-client/lib/tabular/components/cells'
import { Header as HeaderType } from 'sierra-client/lib/tabular/datatype/internal/header'
import { Sorting } from 'sierra-client/lib/tabular/datatype/sorting'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { maxConcurrently } from 'sierra-client/lib/tabular/utils'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { AvatarContainer, Icon } from 'sierra-ui/components'
import { LoadingSpinner, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

export const SortIndicator = styled.div`
  display: flex;
  opacity: 0;
  transition: opacity 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
  margin-left: 0.25rem;
`
const SortDirectionContainer = styled.div`
  display: flex;
`

export const SortDirectionIcon: React.FC<{ sortable: boolean; direction?: 'ascending' | 'descending' }> = ({
  sortable,
  direction,
}) => (
  <SortDirectionContainer>
    {sortable &&
      iife(() => {
        switch (direction) {
          case 'ascending':
            return (
              <>
                {' '}
                <Icon iconId='chevron--up--small' color='foreground/primary' size='size-16' />
              </>
            )
          case 'descending':
            return (
              <>
                {' '}
                <Icon iconId='chevron--down--small' color='foreground/primary' size='size-16' />
              </>
            )
          case undefined:
            return (
              <SortIndicator>
                <Icon iconId='chevron--down--small' color='foreground/primary' size='size-16' />
              </SortIndicator>
            )
          default:
            assertNever(direction)
        }
      })}
  </SortDirectionContainer>
)

// Sort order cycle:
// (none) -> descending -> ascending -> (none)

const getNewSort = ({
  prevSortDirection,
  headerRef,
}: {
  prevSortDirection: Sorting['direction'] | undefined
  headerRef: HeaderType['ref']
}): Sorting | undefined => {
  switch (prevSortDirection) {
    case undefined:
      return { column: headerRef, direction: 'ascending' }
    case 'ascending':
      return { column: headerRef, direction: 'descending' }
    case 'descending':
      return undefined
    default:
      assertNever(prevSortDirection)
  }
}
const getSortingChanges = ({
  sorting,
  headerRef,
}: {
  sorting: Sorting[]
  headerRef: HeaderType['ref']
}): Sorting[] => {
  const prevSort = sorting.find(s => s.column === headerRef)
  const newSort = getNewSort({ prevSortDirection: prevSort?.direction, headerRef })

  // Note: Sort column is moved to the end when it changes.
  // Does that make sense? Seems a bit weird.
  return [
    ...sorting.filter(s => s.column !== headerRef),
    ...(newSort ? [newSort] : []), // Add the new sort if it exists
  ]
}

export const toggleSorting = (api: Omit<TableAPI, 'atoms'>, header: HeaderType): void => {
  const { sorting } = api.query.sorting()
  const columns = api.query.columns().columns

  const changes = getSortingChanges({ sorting, headerRef: header.ref })

  // This seems to return the columns in the same order as in the table definition, is that correct?
  const sorted: Sorting[] = columns.flatMap(col => {
    const change = changes.findLast(s => s.column === col.ref)
    if (change) {
      return [change]
    } else {
      return []
    }
  })

  api.action.setSorting({ sorting: sorted })
}

const stickyBase = css`
  position: sticky;
  z-index: 9;
`
const stickyRight = css`
  right: 0;
`

const stickyLeft = css`
  left: 0;
`

const getHintStyles = (hints: RenderHint[]): FlattenSimpleInterpolation[] => {
  return hints
    .map(hint => {
      switch (hint) {
        case 'sticky':
          return stickyBase
        case 'sticky-right':
          return stickyRight
        case 'sticky-left':
          return stickyLeft
        default:
          return null
      }
    })
    .filter(isDefined)
}

export const Td = styled.td<{ $width?: string | number; hints?: RenderHint[] }>`
  position: relative;
  ${fonts.body.small}
  padding: 1rem;
  vertical-align: middle;
  background-color: ${token('surface/default')};
  color: ${token('foreground/secondary')};
  border: none;
  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
  max-width: 270px;
  min-width: 32px;
  width: ${p => p.$width ?? 'fit-content'};

  ${p => (p.hints ? getHintStyles(p.hints) : '')};
`

export const Th = styled.th<{ width?: string | number; stickyRight?: boolean }>`
  position: sticky;
  white-space: nowrap;
  top: 0;
  inset-block-start: 0;
  z-index: 10;
  ${fonts.body.small}
  padding: 0.5rem 1rem;
  vertical-align: middle;
  font-weight: ${fonts.weight.bold};
  background-color: ${token('surface/default')};
  color: ${token('foreground/primary')};
  text-align: start;
  box-shadow: inset 0 -1px 0 ${token('border/default')};
  min-width: 6rem;

  & {
    ${p => (p.stickyRight === true ? getHintStyles(['sticky-right']) : '')};
    ${p => (p.stickyRight === true ? 'text-align: right;' : '')};
  }
`

const hoverCss = css`
  ${Td} {
    background-color: ${token('surface/default').shift(0.02)};
  }
`
export const Tr = styled.tr<{ expanded?: boolean }>`
  transition: all 0.1s cubic-bezier(0.25, 0.5, 0.25, 1);
  border-bottom: 1px solid ${p => (p.expanded === true ? 'transparent' : token('border/default')(p))};

  ${p =>
    p.onClick !== undefined &&
    css`
      cursor: pointer;
    `}

  &:first-child:last-child {
    border-bottom: 1px transparent;
  }

  /* Hide border bottom for all nested rows except last */
  &[data-nested]:has(+ [data-nested]) {
    border-bottom: transparent;
  }

  ${Td}, ${Th} {
    &:first-child {
      min-width: 3rem;
    }
  }

  ${AddButtonSelector}:not(.selected) {
    opacity: 0;
  }

  &:hover {
    ${hoverCss}
    ${AvatarContainer} {
      pointer-events: none;
    }

    ${AddButtonSelector} {
      opacity: 1;
    }
  }
`

const SpinnerContainer = styled(View)`
  display: inline-block;
  width: 100%;
`

export const InViewportLoadMore: React.FC = () => {
  const { ref, inView } = useInView({ threshold: 0 })
  const { api, dataloaderState: loaderState } = useTabularContext()

  const loadMore = React.useMemo(() => maxConcurrently(1, () => api.action.loadMore()), [api.action])

  React.useEffect(() => {
    if (inView) {
      void loadMore()
    }
  }, [loadMore, inView])

  if (loaderState !== 'more') return <></>

  return (
    <SpinnerContainer justifyContent='center' alignItems='center' ref={ref}>
      <LoadingSpinner size='large' padding='none' />
    </SpinnerContainer>
  )
}
