import { FC, ReactNode, useCallback, useEffect } from 'react'
import { DistributionSettings } from 'sierra-client/features/multi-tenancy'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCoursePermissionSettings } from 'sierra-client/views/flexible-content/editor/course-permission-settings-context'
import {
  InitialPublishForm,
  InitialPublishFormProps,
} from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/initial-publishing/initial-publishing-form'
import { PublishModalState } from 'sierra-client/views/flexible-content/editor/topbar/publish-modal/types'
import { assertNever, iife } from 'sierra-domain/utils'
import { CloseModalButton, Modal } from 'sierra-ui/components'
import { token } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  --publish_modal-padding: 24px;
  --publish_modal-padding--scroll: 12px;
  --publish_modal-top: 0;

  position: absolute;
  top: var(--publish_modal-top);
  right: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  border: 1px solid ${token('border/default')};
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.08);

  /* Set the transform origin for the Modal pop animation. */
  transform-origin: 80% 0;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    --publish_modal-padding: 32px;
    --publish_modal-padding--scroll: 18px;
    --publish_modal-top: 54px;

    right: 10px;
    left: auto;
    width: 618px;
  }
`

type PublishModalProps = {
  state: PublishModalState
  onStateChange: (s: PublishModalState) => void
  trigger: ReactNode
} & Omit<InitialPublishFormProps, 'onOpenChange' | 'onModalClose'>

export const PublishModal: FC<PublishModalProps> = ({ state, trigger, onStateChange, ...contentProps }) => {
  const { t } = useTranslation()
  const { fetchInitialCoursePermissionSettings, coursePermissionSettings } = useCoursePermissionSettings()

  const handleClose = useCallback(() => {
    onStateChange('closed')
  }, [onStateChange])

  const onOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        onStateChange('closed')
      }
    },
    [onStateChange]
  )

  useEffect(() => {
    if (coursePermissionSettings.status === 'idle' || state !== 'closed') {
      void fetchInitialCoursePermissionSettings(contentProps.selfPacedContentId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <StyledModal
      open={state !== 'closed'}
      trigger={trigger}
      onOpenChange={onOpenChange}
      overlayVariant='light'
      animation='pop'
      disableScrollbarGutter
    >
      <CloseModalButton onClick={handleClose} ariaLabel={t('dictionary.close')} />

      {iife(() => {
        switch (state) {
          case 'reminder': {
            return <InitialPublishForm onModalClose={handleClose} {...contentProps} />
          }
          case 'distribution-settings': {
            return (
              <DistributionSettings onModalClose={handleClose} courseId={contentProps.selfPacedContentId} />
            )
          }
          case 'closed': {
            return null
          }
          default: {
            return assertNever(state)
          }
        }
      })}
    </StyledModal>
  )
}
