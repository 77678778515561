import { useSetAtom } from 'jotai'
import _ from 'lodash'
import React, { useCallback, useContext, useRef, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { generativeFeatureUsed } from 'sierra-client/core/logging/authoring/logger'
import { MiniIconColorCss } from 'sierra-client/editor/blocks/paragraph/mini-menu/mini-icon-color-css'
import { usePost } from 'sierra-client/hooks/use-post'
import { useSseWithErrorNotification } from 'sierra-client/hooks/use-sse'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UnsplashImage, fetchImages } from 'sierra-client/hooks/use-unsplash-images'
import { generationAtom } from 'sierra-client/state/generation'
import { useDispatch } from 'sierra-client/state/hooks'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { isInElement, removeNodeWithId, replaceNodeWithId } from 'sierra-client/views/v3-author/command'
import { useEditorContextValue } from 'sierra-client/views/v3-author/editor-context/editor-context'
import { textBeforeNodeWithId } from 'sierra-client/views/v3-author/slash-menu/slash-menu-question-utils'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import {
  XRealtimeAuthorGenerateImageBasedOnText,
  XRealtimeAuthorResolveImageUrl,
  XRealtimeAuthorTextToKeywords,
} from 'sierra-domain/routes'
import { SSEXRealtimeAuthorGenerateParagraph } from 'sierra-domain/routes-sse'
import { Image as ImageBlock } from 'sierra-domain/v3-author'
import { createImage, createPlaceholder } from 'sierra-domain/v3-author/create-blocks'
import { color } from 'sierra-ui/color'
import { MenuItem } from 'sierra-ui/components'
import { IconButton } from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { Editor, Range, Transforms, pointRef } from 'slate'
import { useSlateStatic } from 'slate-react'
import styled, { DefaultTheme, ThemeContext } from 'styled-components'

const AssistantButton = styled(IconButton).attrs({ size: 'small' })<{ $themeContext: DefaultTheme }>`
  background: transparent;
  transition: all 100ms ease-in-out;

  > * {
    ${MiniIconColorCss}
  }

  &:hover {
    background-color: ${p => color(p.theme.home.textColor).opacity(0).toString()};
    border: none;
  }
`

const SmartComposeContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  pointer-events: auto;
`

export const MiniSeparator = styled.span`
  width: 1px;
  height: 1rem;
  background-color: ${p => color(p.theme.home.textColor).opacity(0.1).toString()};
`

export const MiniMenuAssistantButton: React.FC = () => {
  const editorContextValue = useEditorContextValue()
  const themeContext = useContext(ThemeContext)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const editor = useSlateStatic()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const { t } = useTranslation()

  const { subscribeSse } = useSseWithErrorNotification()
  const dispatch = useDispatch()
  const { createContentId, contentType } = useCreatePageContext()
  const setGenerationState = useSetAtom(generationAtom)

  const generatePreviewAutomatically =
    editorContextValue.supportedSlashMenuEntryIds.filter(id => id === 'generate-text-100').length !== 0 &&
    !isInElement(editor, 'question-card')

  async function generateText(type: 'continuation' | 'completion' | 'summary'): Promise<void> {
    const { selection } = editor
    if (selection === null) return

    if (!Range.isCollapsed(selection)) return

    const textBeforeCursor = editor.string(editor.range([], selection.focus.path))
    const instruction = textBeforeCursor

    const initialFocus = editor.selection?.focus
    if (initialFocus === undefined) return
    const insertionPointRef = pointRef(editor, initialFocus, { affinity: 'forward' })

    setGenerationState({ generationPointRef: insertionPointRef })

    try {
      await subscribeSse(SSEXRealtimeAuthorGenerateParagraph, { prompt: instruction, type: type }, event => {
        const pointToInsertAt = insertionPointRef.current

        // This isn't ideal, but will be reworked when we replace these actions in the editor.
        if (pointToInsertAt === null) return

        Transforms.insertText(editor, event.data.text, { at: pointToInsertAt })
      })

      // Segment logging for generative features used
      void dispatch(
        generativeFeatureUsed({
          contentId: createContentId,
          contentType,
          generativeFeature: `generate-${type}-mini-menu`,
        })
      )
    } catch (e) {
      // do nothing
    } finally {
      insertionPointRef.unref()
      setGenerationState(undefined)
    }

    return
  }

  const handleGenerateSummarize = async (): Promise<void> => {
    notifications.push({
      type: 'custom',
      level: 'info',
      body: t('assistant.summarize'),
    })
    await generateText('summary')
  }

  const toSanaImage = (fileId: string): ImageBlock['image'] => ({
    type: 'file',
    file: fileId,
  })

  const createFileId = useCallback(
    async (url: string): Promise<string> => {
      const { fileId } = await postWithUserErrorException(XRealtimeAuthorResolveImageUrl, {
        url: url,
        type: 'course',
        courseId: createContentId,
      })
      return fileId
    },
    [postWithUserErrorException, createContentId]
  )

  const [addUnsplashImage] = useState(() =>
    _.debounce(
      async (): Promise<void> => {
        notifications.push({
          type: 'custom',
          level: 'info',
          body: t('assistant.adding-image'),
        })
        if (!Editor.isEditor(editor)) return

        const { selection } = editor
        if (selection === null) return

        const newId = nanoid12()
        Transforms.insertNodes(editor, createPlaceholder({ id: newId }), {
          at: selection,
        })

        const context = textBeforeNodeWithId(editor, newId).trim()

        const { keywords } = await postWithUserErrorException(XRealtimeAuthorTextToKeywords, {
          text: context,
        })
        const query: string | undefined = keywords[0]

        if (query === undefined) return removeNodeWithId(editor, newId)

        const results = await fetchImages(query)

        const image = _.shuffle(_.take(results, 20))[0]

        const toUnsplashImage = (image: UnsplashImage): ImageUnion => ({
          type: 'unsplash',
          url: image.urls.raw,
          width: image.width,
          height: image.height,
          user: {
            name: image.user.name,
            username: image.user.username,
          },
        })

        if (image !== undefined) {
          const unsplashImage = toUnsplashImage(image)
          replaceNodeWithId(
            editor,
            newId,
            createImage({ image: unsplashImage, variant: 'narrow', altText: query }),
            false
          )
          // Segment logging for generative features used
          void dispatch(
            generativeFeatureUsed({
              contentId: createContentId,
              contentType,
              generativeFeature: 'generate-unplash-image-mini-menu',
            })
          )
        } else {
          removeNodeWithId(editor, newId)
        }
      },
      0,
      { maxWait: 0 }
    )
  )

  const [addImage] = useState(() =>
    _.debounce(
      async (): Promise<void> => {
        notifications.push({
          type: 'custom',
          level: 'info',
          body: t('assistant.generating-image'),
        })
        if (!Editor.isEditor(editor)) return

        const { selection } = editor
        if (selection === null) return

        const newId = nanoid12()
        Transforms.insertNodes(editor, createPlaceholder({ id: newId, blockType: 'image' }), {
          at: selection,
        })

        const context = textBeforeNodeWithId(editor, newId).trim()

        const { url } = await postWithUserErrorException(XRealtimeAuthorGenerateImageBasedOnText, {
          prompt: context,
        })

        if (url !== undefined) {
          const generatedImage = toSanaImage(await createFileId(url))
          replaceNodeWithId(editor, newId, createImage({ image: generatedImage, variant: 'narrow' }), false)
          // Segment logging for generative features used
          void dispatch(
            generativeFeatureUsed({
              contentId: createContentId,
              contentType,
              generativeFeature: 'generate-image-mini-menu',
            })
          )
        } else {
          removeNodeWithId(editor, newId)
        }
      },
      0,
      { maxWait: 0 }
    )
  )

  const items: MenuItem[] = [
    {
      id: 'continue-writing',
      type: 'label',
      icon: 'edit',
      label: t('assistant.continue-writing'),
      onClick: async () => {
        notifications.push({
          type: 'custom',
          level: 'info',
          body: t('assistant.continue-writing'),
        })

        await generateText('continuation')
      },
    },
    {
      id: 'Summarize',
      type: 'label',
      label: t('assistant.summarize'),
      icon: 'text--align--left',
      onClick: handleGenerateSummarize,
    },
    {
      id: 'add-image',
      type: 'label',
      label: t('assistant.add-image'),
      icon: 'unsplash',
      onClick: addUnsplashImage,
    },
    {
      id: 'generate-image',
      type: 'label',
      label: t('author.slate.generate-image'),
      icon: 'image',
      onClick: addImage,
    },
  ]

  return (
    <>
      {generatePreviewAutomatically && (
        <>
          <SmartComposeContainer id='tour-create-pagecard-assistant'>
            <MenuDropdownPrimitive
              renderTrigger={() => (
                <AssistantButton
                  tooltip={t('dictionary.assistant')}
                  ref={anchorRef}
                  size='small'
                  iconId='glitter'
                  $themeContext={themeContext}
                />
              )}
              menuItems={items.filter(it =>
                editorContextValue.supportedSlashMenuEntryIds.filter(id => id === 'image').length === 0
                  ? it.id !== 'add-image' && it.id !== 'generate-image'
                  : it
              )}
              onSelect={item => 'onClick' in item && item.onClick?.()}
            />
          </SmartComposeContainer>
        </>
      )}
    </>
  )
}
