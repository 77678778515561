import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { getGlobalRouter } from 'sierra-client/router'
import { ApiClientsTable } from 'sierra-client/views/manage/api/api-clients-table'
import { useManageApiClient } from 'sierra-client/views/manage/api/use-manage-client-api'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import styled from 'styled-components'

const APILink = styled.a`
  text-decoration: underline;
  transition: opacity 150ms cubic-bezier(0.25, 0.1, 0.25, 1);

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
`

export const ManageApiClient = (): JSX.Element | null => {
  const { t } = useTranslation()

  const { apiClients } = useManageApiClient()

  const createApiClient = async (): Promise<void> => {
    await getGlobalRouter().navigate({
      to: '/settings/api/$apiClientId',
      params: { apiClientId: 'new' },
      replace: true,
    })
  }

  return (
    <SmallTableWrapper
      title={t('manage.api.title')}
      subtitle={
        <Trans
          i18nKey={'manage.api.subtitle-with-link' satisfies TranslationKey}
          components={{
            apilink: <APILink href='https://sana.ai/api-docs/' target='_blank' rel='noopener noreferrer' />,
          }}
        />
      }
    >
      <ApiClientsTable data={apiClients} createApiClient={createApiClient} />
    </SmallTableWrapper>
  )
}
