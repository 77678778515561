import _ from 'lodash'
import React, { useMemo } from 'react'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { UserId } from 'sierra-domain/api/uuid'
import { ColorName } from 'sierra-ui/color/types'
import { Text, TextSize } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

export type SquareAvatarSize = 'small' | 'medium' | 'large'

const circleSize: Record<SquareAvatarSize, string> = {
  small: '1rem',
  medium: '1.5rem',
  large: '2rem',
}

export const desktopSquareAvatarSize: Record<SquareAvatarSize, string> = {
  small: '2.5rem',
  medium: '5rem',
  large: '7.5rem',
}

export const mobileSquareAvatarSize: Record<SquareAvatarSize, string> = {
  small: '2.5rem',
  medium: '5rem',
  large: '4.5rem',
}

const SquareAvatarWrapper = styled.div<{ $size: SquareAvatarSize }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background-color: ${palette.primitives.black};

  width: ${p => desktopSquareAvatarSize[p.$size]};
  height: ${p => desktopSquareAvatarSize[p.$size]};
  @media screen and (max-width: ${v2_breakpoint.phone}) {
    width: ${p => mobileSquareAvatarSize[p.$size]};
    height: ${p => mobileSquareAvatarSize[p.$size]};
  }
`

const SquareAvatarCircle = styled.div<{ $color: ColorName; $size: SquareAvatarSize }>`
  width: ${p => circleSize[p.$size]};
  height: ${p => circleSize[p.$size]};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  background-color: ${p => p.theme.color[p.$color]};

  /**
    Safari hack for border radius with overflow hidden
    This will tell the browser this element needs to create a new stacking context
    https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari 
    */
  isolation: isolate;
`

const SquareAvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const SquareInitials = styled(Text)`
  line-height: 1;
`

const ParticipantName = styled(Text)`
  position: absolute;
  bottom: 8px;
  left: 0;
  right: 0;
  text-align: center;
  text-shadow:
    0px 1px 1px rgba(0, 0, 0, 0.35),
    0px 2px 6px rgba(0, 0, 0, 0.24);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${palette.primitives.white};
  padding-left: 10px;
  padding-right: 8px;
`

type SquareAvatarProps = {
  size?: SquareAvatarSize
  uuid?: UserId
  firstName?: string
  lastName?: string
  color?: ColorName
  className?: string
  image?: string | null
  /** Shows first name when size is 'medium' or 'large */
  showName?: boolean
}

export const SquareAvatar = React.forwardRef<HTMLDivElement, SquareAvatarProps>(
  (
    { size = 'small', firstName, lastName, color = 'pinkBright', image, className, showName = false, uuid },
    ref
  ) => {
    const src = !_.isNil(image) && !_.isNil(uuid) && getAvatarImage(uuid, image)

    const fontSize = useMemo((): TextSize => {
      switch (size) {
        case 'large':
          return 'small'
        case 'medium':
          return 'micro'
        default:
        case 'small':
          return 'technical'
      }
    }, [size])

    return (
      <SquareAvatarWrapper className={className} $size={size} ref={ref}>
        <SquareAvatarCircle $color={src !== false ? 'transparent' : color} $size={size}>
          {src === false ? (
            <SquareInitials size={fontSize} color='white'>
              {firstName !== undefined ? firstName.slice(0, 1).toUpperCase() : ''}
              {lastName !== undefined && size === 'large' ? lastName.slice(0, 1).toUpperCase() : ''}
            </SquareInitials>
          ) : (
            <SquareAvatarImg src={src} />
          )}
        </SquareAvatarCircle>
        {showName && firstName !== undefined && (size === 'large' || size === 'medium') && (
          <ParticipantName size='micro' color='white' bold>
            {firstName}
          </ParticipantName>
        )}
      </SquareAvatarWrapper>
    )
  }
)
