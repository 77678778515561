import { useMutation } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { ChangeEvent, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ApproverSetting,
  CourseVisibility,
  CreateCalendarEventInput,
  CreateCalendarEventsMutation,
  CreateEventGroupMutation,
  MicrosoftCalendarAccessResult,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { config, getFlag } from 'sierra-client/config/global-config'
import { Logging } from 'sierra-client/core/logging'
import { MicrosoftCalendarError } from 'sierra-client/features/calendar-integrations'
import { canAccessMicrosoftCalendarMutation } from 'sierra-client/hooks/can-access-microsoft-calendar-mutation'
import { useAvailableIntegrationActions } from 'sierra-client/hooks/use-available-integration-actions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { useMeAsIdentity } from 'sierra-client/views/manage/certificates/issue-certificate-panel/shared/by-proxy-selector'
import { CalendarEventBottomOptions } from 'sierra-client/views/manage/event-groups/components/calendar-event-bottom-options'
import { EventList } from 'sierra-client/views/manage/event-groups/components/events-list'
import {
  TextButton,
  oneHourFromNowFloored,
  twoHoursFromNowFloored,
} from 'sierra-client/views/manage/event-groups/components/shared'
import {
  eventScheduleToGqlInput,
  eventScheduleValid,
  gqlEventScheduleToEventSchedule,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CreateCalendarEventData } from 'sierra-client/views/manage/event-groups/types'
import { ImageUpload } from 'sierra-client/views/manage/paths/components/image-upload'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { FormElement, MenuItem } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Heading, IconButton, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const OuterContainer = styled(View)`
  position: relative;
  min-height: 100%;
`

const TopContainer = styled(View)`
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${token('border/default')};
`

const BottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${token('surface/default')};
  padding: ${spacing['medium']};
  padding-top: 0;
`

const EventIconContainer = styled(View)`
  position: absolute;
  right: -8px;
  top: -8px;
  gap: 0;
`

const CloseModalIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;
`

const StyledIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })`
  color: ${token('foreground/muted')};

  &:hover {
    background: unset;
    color: ${token('foreground/primary')};
  }
`

type CreateEventGroupPanelProps = {
  canEditVisibleEverywhere: boolean
  canEditAssignments: boolean
  afterSubmit?: () => void
  timezone: string
}

const createEventGroupMutation = graphql(`
  mutation createEventGroup($input: CreateEventGroupInput!) {
    createEventGroup(input: $input) {
      eventGroupId
    }
  }
`)

const createCalendarEventsMutation = graphql(`
  mutation createCalendarEvents($input: [CreateCalendarEventInput!]!) {
    createCalendarEvents(input: $input) {
      id
      title
      approverSetting
      selfReportAttendance
      participantLimit

      schedule {
        ...CalendarEventScheduleFragment
      }

      assignedUsers {
        __typename
      }
    }
  }
`)

type CreateEventGroupAndEventsResult =
  | {
      type: 'success'
      createEventGroupResult: CreateEventGroupMutation['createEventGroup']
      createCalendarEventsResult: CreateCalendarEventsMutation['createCalendarEvents']
    }
  | {
      type: 'error-cannot-access-microsoft-calendar'
      access: Exclude<MicrosoftCalendarAccessResult, 'HasAccess'>
    }

export const CreateEventGroupPanel = withPanel<CreateEventGroupPanelProps>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  ({ onClose, canEditVisibleEverywhere, canEditAssignments, timezone }) => {
    const { t } = useTranslation()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [image, setImage] = useState<ImageUnion>()
    const [showDescriptionAndImage, setShowDescriptionAndImage] = useState(false)
    const [showDescriptionAndImageClose, setShowDescriptionAndImageClose] = useState(false)
    const [visibility, setVisibility] = useState<CourseVisibility>('VISIBLE_FOR_ADMINS')
    const [showMoreOptions, setShowMoreOptions] = useState(false)
    const [participantLimit, setParticipantLimit] = useState<number | undefined>()
    const [approverSetting, setApproverSetting] = useState<ApproverSetting | undefined>()
    const [specificReviewerIdentities, setSpecificReviewerIdentities] = useState<IdentityWithMetadata[]>([])
    const [selfReportAttendance, setSelfReportAttendance] = useState(true)
    const userIdentity = useMeAsIdentity()
    const dispatch = useDispatch()

    const [events, setEvents] = useState<CreateCalendarEventData[]>(() => [
      {
        // NOTE! Ignored by the backend
        id: nanoid12() as CalendarEventId,
        schedule: {
          type: 'with-time',
          timeZone: timezone,
          startTime: oneHourFromNowFloored(),
          endTime: twoHoursFromNowFloored(),
        },
        location: undefined,
        participantIdentities: [],
        facilitatorIdentities: isDefined(userIdentity) ? [userIdentity] : [],
        specificReviewerIdentities: [],
        calendarIntegrationSetting: config.organization.settings.calendarIntegrationSettings,
      },
    ])

    const hasEventWithInvalidTime = events.some(event => !eventScheduleValid(event.schedule))

    const availableIntegrationActions = useAvailableIntegrationActions()
    const isCalendarIntegrationAuthenticated = iife(() => {
      return events.some(event => {
        switch (event.calendarIntegrationSetting.type) {
          case 'microsoft':
            return availableIntegrationActions.microsoft.createCalendarEvent
          case 'google':
          case 'sana':
            return true
          default:
            assertNever(event.calendarIntegrationSetting)
        }
      })
    })

    const createEventGroupAndEventsMutation = useMutation({
      mutationFn: async (): Promise<CreateEventGroupAndEventsResult> => {
        if (getFlag('rsvp') && events.some(event => event.calendarIntegrationSetting.type === 'microsoft')) {
          // validate calendar access

          const microsoftCalendarAccess = (await graphQuery(canAccessMicrosoftCalendarMutation))
            .canAccessMicrosoftCalendar

          if (microsoftCalendarAccess !== 'HasAccess') {
            // If we can't create the integration event, we should not create the event
            return { type: 'error-cannot-access-microsoft-calendar', access: microsoftCalendarAccess }
          }
        }

        const { createEventGroup } = await graphQuery(createEventGroupMutation, {
          input: {
            description,
            title,
            image: JSON.stringify(image),
            visibility,
          },
        })

        const { createCalendarEvents } = await graphQuery(createCalendarEventsMutation, {
          input: events.map(
            event =>
              ({
                title: title,
                schedule: eventScheduleToGqlInput(event.schedule),
                location: JSON.stringify(event.location),
                eventGroupId: createEventGroup.eventGroupId,
                participantLimit,
                selfReportAttendance,
                approverSetting,
                participantUserIds: event.participantIdentities.flatMap(id =>
                  id.identity.type === 'user' ? [id.identity.id] : []
                ),
                participantUserGroupIds: event.participantIdentities.flatMap(id =>
                  id.identity.type === 'userGroup' ? [id.identity.id] : []
                ),
                facilitatorUserIds: event.facilitatorIdentities.flatMap(id =>
                  id.identity.type === 'user' ? [id.identity.id] : []
                ),
                specificReviewerUserIds: specificReviewerIdentities.flatMap(id =>
                  id.identity.type === 'user' ? [id.identity.id] : []
                ),
                calendarIntegrationSetting: iife(() => {
                  switch (event.calendarIntegrationSetting.type) {
                    case 'sana':
                      return 'SANA'
                    case 'microsoft':
                      return 'MICROSOFT'
                    case 'google':
                      return 'GOOGLE'
                  }
                }),
              }) satisfies CreateCalendarEventInput
          ),
        })

        return {
          type: 'success',
          createEventGroupResult: createEventGroup,
          createCalendarEventsResult: createCalendarEvents,
        }
      },
      onSuccess: data => {
        if (data.type === 'success') {
          void dispatch(
            Logging.eventGroup.eventGroupCreated({
              eventGroupId: data.createEventGroupResult.eventGroupId,
              nrOfSessions: events.length,
            })
          )

          data.createCalendarEventsResult.forEach(event => {
            const schedule = gqlEventScheduleToEventSchedule(event.schedule)

            const differingTimeZones =
              schedule.type === 'with-time' && schedule.timeZone !== DateTime.local().zoneName

            void dispatch(
              Logging.eventGroup.calendarEventCreated({
                calendarEventId: event.id,
                participantCount: event.assignedUsers.length,
                approverSetting: event.approverSetting ?? undefined,
                participantLimit: event.participantLimit ?? undefined,
                selfReportAttendance: event.selfReportAttendance,
                allDayEvent: schedule.type === 'all-day',
                eventInDifferentTimeZoneFromCreator: differingTimeZones,
              })
            )
          })

          void getGlobalRouter().navigate({
            to: `/manage/in-person-events/${data.createEventGroupResult.eventGroupId}`,
          })
        }
      },
    })

    const visibilityMenuItems: MenuItem<CourseVisibility>[] = [
      {
        type: 'label',
        id: 'VISIBLE',
        label: t('event-groups.visible-to-everyone'),
      },
      {
        type: 'label',
        id: 'VISIBLE_FOR_ADMINS',
        label: t('event-groups.visible-in-manage'),
      },
    ]

    return (
      <OuterContainer direction='column'>
        <CloseModalIconContainer>
          <StyledIconButton iconId='close' onClick={onClose} />
        </CloseModalIconContainer>
        <TopContainer
          gap='none'
          padding='none medium'
          paddingTop='small'
          justifyContent='flex-start'
          direction='column'
        >
          <Heading bold size='h5'>
            {t('manage.events.new-event')}
          </Heading>

          <Spacer size='24' />

          <FormElement grow={false} label={t('dictionary.title')}>
            <InputPrimitive
              placeholder={t('manage.events.event-name')}
              id='event-group-title'
              value={title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </FormElement>

          {showDescriptionAndImage ? (
            <>
              <Spacer size='16' />
              <View
                alignItems='stretch'
                position='relative'
                direction='column'
                gap='none'
                grow={false}
                onMouseEnter={() => setShowDescriptionAndImageClose(true)}
                onMouseLeave={() => setShowDescriptionAndImageClose(false)}
              >
                <FormElement label={t('dictionary.description')}>
                  <FreeTextEditor
                    inputId='event-group-description'
                    content={description}
                    editable
                    useHtml
                    textOptionsEnabled={false}
                    listOptionsEnabled={false}
                    alignmentOptionsEnabled={false}
                    strikethroughEnabled={false}
                    underlineEnabled={false}
                    onChange={e => {
                      setDescription(e)
                    }}
                    menuTranslations={{
                      list: t('dictionary.list'),
                      alignment: t('create.toolbar.text-alignment'),
                      text: t('dictionary.text'),
                      heading: t('font.heading'),
                    }}
                  />
                </FormElement>
                {showDescriptionAndImageClose && (
                  <EventIconContainer>
                    <StyledIconButton
                      iconId='trash-can'
                      onClick={() => {
                        setShowDescriptionAndImage(false)
                        setDescription('')
                        setImage(undefined)
                      }}
                    />
                  </EventIconContainer>
                )}

                <Spacer size='16' />

                <FormElement grow label={t('manage.paths.cover-image')}>
                  <ImageUpload
                    value={image}
                    onChange={newImage => setImage(newImage)}
                    assetContext={{ type: 'organization' }}
                  />
                </FormElement>
              </View>
            </>
          ) : (
            <>
              <Spacer size='4' />
              <TextButton onClick={() => setShowDescriptionAndImage(true)}>
                {t('event-groups.add-description-and-image')}
              </TextButton>
            </>
          )}

          <Spacer size='16' />

          <EventList events={events} setEvents={setEvents} canEditAssignments={canEditAssignments} />
          {canEditVisibleEverywhere && (
            <>
              <Spacer size='16' />
              <View>
                <View direction='column' grow gap='none'>
                  <Text bold>{t('dictionary.visibility')}</Text>
                  <Text color='foreground/muted'>{t('event-groups.who-can-access')}</Text>
                </View>
                <View grow={false}>
                  <SingleSelectDropdown<CourseVisibility>
                    bold
                    selectedItem={visibilityMenuItems.find(it => it.id === visibility)}
                    onSelect={item => {
                      setVisibility(item.id)
                    }}
                    menuItems={visibilityMenuItems}
                  />
                </View>
              </View>
            </>
          )}
        </TopContainer>

        <BottomContainer>
          {showMoreOptions && (
            <>
              <Divider />

              <Spacer size={'40'} />
              <CalendarEventBottomOptions
                specificReviewerIdentities={specificReviewerIdentities}
                setSpecificReviewerIdentities={setSpecificReviewerIdentities}
                participantLimit={participantLimit}
                setParticipantLimit={setParticipantLimit}
                selfReportAttendance={selfReportAttendance}
                setSelfReportAttendance={setSelfReportAttendance}
                approverSetting={approverSetting}
                setApproverSetting={setApproverSetting}
              />
              <Spacer size={'48'} />
            </>
          )}

          {iife(() => {
            if (!getFlag('rsvp')) {
              return null
            }

            // This error is only relevant for the Microsoft Outlook calendar integration setting.
            if (!events.some(ev => ev.calendarIntegrationSetting.type === 'microsoft')) {
              return null
            }

            if (createEventGroupAndEventsMutation.data?.type === 'error-cannot-access-microsoft-calendar') {
              return (
                <>
                  <MicrosoftCalendarError accessResult={createEventGroupAndEventsMutation.data.access} />
                  <Spacer size='4' />
                </>
              )
            }
          })}

          <View>
            <Button
              variant='secondary'
              onClick={() => {
                setShowMoreOptions(prev => !prev)
              }}
            >
              {t('dictionary.more-options')}
            </Button>

            <View marginLeft='auto'>
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Button
                onClick={() => createEventGroupAndEventsMutation.mutate()}
                loading={createEventGroupAndEventsMutation.isPending}
                disabledWithReason={iife(() => {
                  const reasons = []
                  if (createEventGroupAndEventsMutation.isPending) {
                    reasons.push(t('dictionary.loading'))
                  }
                  if (hasEventWithInvalidTime) {
                    reasons.push(t('event-groups.invalid-schedule'))
                  }
                  if (!isCalendarIntegrationAuthenticated) {
                    reasons.push(t('admin.author.create-session-panel.calendar-invites-auth'))
                  }

                  return reasons.length > 0 ? reasons.join('\n') : undefined
                })}
              >
                {t('dictionary.save')}
              </Button>
            </View>
          </View>
        </BottomContainer>
      </OuterContainer>
    )
  }
)
