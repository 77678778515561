import React, { useCallback, useEffect } from 'react'
import { VideoPlayer } from 'sierra-client/components/blocks/video'
import { PlayerOptions, VideoPlayerProps } from 'sierra-client/components/blocks/video/types'
import { useAddSubtitleTracks } from 'sierra-client/components/blocks/video/use-add-subtitle-tracks'
import { useDispatch } from 'sierra-client/state/hooks'
import { setKeyboardNavigationEnabled } from 'sierra-client/state/live/actions'
import { useSetCardProgress } from 'sierra-client/views/flexible-content/progress-tracking/set-progress-provider'
import { LiveFollowMeVideoPlayer } from 'sierra-client/views/v3-author/video/live-session-follow-me-video-player'
import { VideoData } from 'sierra-domain/flexible-content/types'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { maxWidth } from 'sierra-ui/utils/media-query-styles'
import styled from 'styled-components'

const VideoPlaceholder = styled(View).attrs({
  justifyContent: 'center',
  cursor: 'pointer',
  background: 'grey2',
})`
  width: 100%;
  height: 100%;
`

const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;
  &&& {
    display: unset; /* this unsets display: flex from SelfPacedCardRendererContainer, which otherwise breaks video play button placement in safari */
  }
  padding: 4rem;
  background: ${p => p.theme.home.backgroundColor};

  & > * {
    margin: auto;
  }

  ${maxWidth.phone} {
    padding: 0.5rem;
    padding-bottom: 4rem;
  }
`

const Player: React.FC<
  { video: VideoData['video']; controls: boolean; allowChangePlaybackRate?: boolean } & Omit<
    VideoPlayerProps,
    'options'
  >
> = ({ video, controls, allowChangePlaybackRate = true, ...rest }) => {
  const playbackRatesOptionOverride: Partial<PlayerOptions> = allowChangePlaybackRate
    ? {}
    : { playbackRates: [1] }

  const addSubtitles = useAddSubtitleTracks(video.url, video.disableSubtitles !== true)

  return video.url === undefined ? (
    <VideoPlaceholder>
      <Icon iconId='play--outline' color='grey35' />
      <Text size='regular' color='grey35'>
        No video set
      </Text>
    </VideoPlaceholder>
  ) : (
    <PlayerWrapper>
      <VideoPlayer
        {...rest}
        options={{
          autoplay: false,
          playsinline: true,
          controls,
          responsive: true,
          fill: true,
          preload: 'auto',
          controlBar: {
            liveDisplay: false,
            pictureInPictureToggle: false,
          },
          sources: [{ src: video.url, type: 'video/mp4' }],
          ...playbackRatesOptionOverride,
        }}
        disableSkipping={video.disableSkipping}
        onLoadedMetadata={player => addSubtitles(player)}
      />
    </PlayerWrapper>
  )
}

const StyledLiveFollowMeVideoPlayer = styled(LiveFollowMeVideoPlayer)`
  height: 100%;
`

const LiveFollowMeVideoPlayerCard: React.FC<{
  onPlay?: () => void
  video: VideoData['video']
  videoId: string
}> = ({ video, videoId, onPlay }) => {
  const dispatch = useDispatch()

  const handleOnPlay = useCallback(() => {
    dispatch(setKeyboardNavigationEnabled(false))
    onPlay?.()
  }, [dispatch, onPlay])

  const handleOnPaused = useCallback(() => {
    dispatch(setKeyboardNavigationEnabled(true))
  }, [dispatch])

  // Effect to ensure we always enable keyboard navigation when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(setKeyboardNavigationEnabled(true))
    }
  }, [dispatch])

  return video.url === undefined ? (
    <VideoPlaceholder>
      <Icon iconId='play--outline' color='grey35' />
      <Text size='regular' color='grey35'>
        No video set
      </Text>
    </VideoPlaceholder>
  ) : (
    <PlayerWrapper>
      <StyledLiveFollowMeVideoPlayer
        fluid={false}
        video={video}
        videoId={videoId}
        onPlay={handleOnPlay}
        onPause={handleOnPaused}
      />
    </PlayerWrapper>
  )
}

export const LiveVideoPlayerCard: React.FC<{
  video: VideoData['video']
  videoId: string
}> = ({ video, videoId }) => {
  const { setCardCompleted } = useSetCardProgress()
  return <LiveFollowMeVideoPlayerCard onPlay={setCardCompleted} video={video} videoId={videoId} />
}

export const SelfPacedVideoCard: React.FC<{ video: VideoData['video'] }> = ({ video }) => {
  const { setCardCompleted } = useSetCardProgress()

  if (video.url === undefined) {
    setCardCompleted()
  }

  return (
    <Player
      onPlay={video.disableSkipping !== true ? setCardCompleted : undefined}
      onEnded={video.disableSkipping === true ? setCardCompleted : undefined}
      video={video}
      controls={true}
    />
  )
}

export const VideoPlayerCard: React.FC<{
  video: VideoData['video']
}> = ({ video }) => {
  return <Player video={video} controls={true} />
}

export const VideoPlayerCardPreview: React.FC<{
  video: VideoData['video']
}> = ({ video }) => {
  return <Player video={video} controls={false} />
}
