import { useSetAtom } from 'jotai'
import { useAtomValue } from 'jotai/index'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrentUserId } from 'sierra-client/api/hooks/use-user'
import { OWNER_ONLY, ShortcutMenu } from 'sierra-client/components/shortcut-menu'
import { getFlag } from 'sierra-client/config/global-config'
import {
  AddCardComponent,
  HeaderItemTitle,
  collapsableSidebarStateAtom,
  sidebarHasNestedMenuOpenAtom,
} from 'sierra-client/features/collapsable-sidebar'
import {
  courseGenerationClickedSaveAllButton,
  createAnnotatePdfButtonClicked,
} from 'sierra-client/features/course-generation/logger'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useRequiredRouterEditorIds } from 'sierra-client/hooks/use-router-ids'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import * as flexSelectors from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import {
  useCreatePageContext,
  useCreatePageNodeIdContext,
  useCreatePageYDocContext,
  useCreatePageYDocContextSafe,
  useIsCreatePageContextLoading,
} from 'sierra-client/views/flexible-content/create-page-context'
import {
  CardLocation,
  useCardPopperContext,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/card-location-context'
import { ContentPreviewEnabledAtom } from 'sierra-client/views/flexible-content/editor/content-sidebar/content-preview-atom'
import {
  CreateCardPicker,
  CreateCardPickerShortcut,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/create-card-picker'
import { CreatePageSidebarItems } from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar-items'
import { CreatePageSidebarSkeleton } from 'sierra-client/views/flexible-content/editor/content-sidebar/create-page-sidebar-skeleton'
import { HiddenAddCardButton } from 'sierra-client/views/flexible-content/editor/content-sidebar/hidden-add-card-button'
import { moduleAddedLogger } from 'sierra-client/views/flexible-content/editor/content-sidebar/logger'
import { SetupMultiSelection } from 'sierra-client/views/flexible-content/editor/content-sidebar/multi-selection/setup/setup-multi-selection'
import { useSpeakerNoteContext } from 'sierra-client/views/flexible-content/editor/content-sidebar/speaker-notes/context'
import { useTemplateCards } from 'sierra-client/views/flexible-content/editor/content-sidebar/use-template-cards'
import { CreatePageTitle } from 'sierra-client/views/flexible-content/editor/topbar/create-page-title'
import { GenerateCardModal } from 'sierra-client/views/flexible-content/generate-from-file-modal/generate-from-file-modal'
import { ImportModal } from 'sierra-client/views/flexible-content/import-modal'
import { PDFAnnotatorModal } from 'sierra-client/views/flexible-content/index/pdf-annotator-modal'
import { createSanaEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import { ContentPermission } from 'sierra-domain/api/common'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization/types'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { apply, redo, undo } from 'sierra-domain/editor/operations/operation'
import { CreateOperation, CreateOperationState } from 'sierra-domain/editor/operations/types'
import { FileId, FolderId, LinkId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder, NodeMap, createFolder } from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { asNonNullable, assertNever, assertWith, guardWith, isDefined } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'
import { IconId, LabelMenuItem, MenuButton, MenuItem, Popover, Tooltip } from 'sierra-ui/components'
import { IconButton, ScrollView, Text, View } from 'sierra-ui/primitives'
import { zIndex } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { useGenerateDomId } from 'sierra-ui/utils/use-generate-dom-id'
import { Editor } from 'slate'
import styled from 'styled-components'

const AddCardButtonWrapper = styled(View)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.MENU};
  background: transparent;
  white-space: nowrap;
  width: 100%;
`

const emptyFunction = (): void => {}

const useCreateTemplateCards = ({
  createCardLocation,
  onCardPicked,
  createContentId,
  selectedFileId,
  openGenerateCourseModal,
}: {
  createCardLocation?: CardLocation
  onCardPicked?: () => void
  createContentId: CreateContentId
  selectedFileId: FileId | undefined
  openGenerateCourseModal: () => void
}): ReturnType<typeof useTemplateCards> => {
  const folderId =
    useSelector(state => flexSelectors.selectNodeParentFolderId(state, createContentId, selectedFileId)) ??
    'folder:root'

  return useTemplateCards({
    // If selectedNodeId is undefined the new card is inserted at the bottom
    selectedNodeId: createCardLocation === 'bottom' ? undefined : selectedFileId,
    currentFolderId: createCardLocation === 'bottom' ? undefined : createCardLocation?.folderId ?? folderId,
    onClick: onCardPicked ?? emptyFunction,
    openGenerateCourseModal: openGenerateCourseModal,
  })
}

const CardPicker: React.FC<{
  createCardLocation: CardLocation
  createContentId: CreateContentId
  selectedFileId: FileId | undefined
  onCardPicked: () => void
  openGenerateCourseModal: () => void
}> = ({ createCardLocation, createContentId, selectedFileId, onCardPicked, openGenerateCourseModal }) => {
  const templateCards = useCreateTemplateCards({
    createCardLocation,
    onCardPicked,
    createContentId,
    selectedFileId,
    openGenerateCourseModal,
  })
  return <CreateCardPicker cards={templateCards} onClose={onCardPicked} />
}

const CardPickerShortcut: React.FC<{
  createContentId: CreateContentId
  selectedFileId: FileId | undefined
  openGenerateCourseModal: () => void
}> = ({ createContentId, selectedFileId, openGenerateCourseModal }) => {
  const templateCards = useCreateTemplateCards({ createContentId, selectedFileId, openGenerateCourseModal })
  return <CreateCardPickerShortcut cards={templateCards} />
}

type AddCardButtonProps = {
  createContentId: CreateContentId
  scopedCreateContentId: ScopedCreateContentId
  operationState: CreateOperationState
  selectedFileId: FileId | undefined
  permission: ContentPermission
  cardPopperOpen: boolean
  setCardPopperOpen: (cardPopperOpen: boolean) => void
  setCreateCardLocation: (createCardLocation: CardLocation) => void
  generateCardModal: boolean
  doOpenGenerateCardModal: () => void
  closeGenerateCardModal: () => void
  id: string
  ariaExpanded: boolean
  ariaControls: string
  assetContext: AssetContext
}

type MenuItemKeys = 'module' | 'import' | 'annotate' | 'generate'
type Item = LabelMenuItem<MenuItemKeys> & {
  icon: IconId
}

const AddCardButtonContainer = styled(View)`
  height: 4rem;
  width: 100%;
`

const AddCardRefContainer = styled.div`
  width: 100%;
`

function normalize(slateDocument: SlateDocument): SlateDocument {
  const sanaEditor = createSanaEditor({ pasteFile: () => {}, copyPasteAssetOptions: { type: 'disabled' } })
  sanaEditor.children = slateDocument
  Editor.normalize(sanaEditor, { force: true })
  return SlateDocument.parse(replaceIdsInNodes(sanaEditor.children))
}

function generateNewNodeId(oldNodeId: NodeId): NodeId {
  if (guardWith(FileId, oldNodeId)) {
    return FileId.parse(`file:${nanoid12()}`)
  } else if (guardWith(FolderId, oldNodeId)) {
    return FolderId.parse(`folder:${nanoid12()}`)
  } else if (guardWith(LinkId, oldNodeId)) {
    return LinkId.parse(`link:${nanoid12()}`)
  } else {
    assertNever(oldNodeId)
  }
}

function replaceIds(
  oldNodeMap: NodeMap,
  oldSlateDocumentMap: SlateDocumentMap
): { nodeMap: NodeMap; slateDocumentMap: SlateDocumentMap; oldIdsToNewIds: Map<NodeId, NodeId> } {
  const oldIdsToNewIds = new Map<NodeId, NodeId>()
  const nodeIds = Object.keys(oldNodeMap)
  for (const nodeId of nodeIds) {
    assertWith(NodeId, nodeId)
    if (nodeId === 'folder:root') {
      // We cannot replace the 'folder:root' id
      oldIdsToNewIds.set(nodeId, 'folder:root')
      continue
    }
    const newNodeId = generateNewNodeId(nodeId)
    oldIdsToNewIds.set(nodeId, newNodeId)
  }

  const newNodeMap: NodeMap = {}
  const newSlateDocumentMap: SlateDocumentMap = {}

  for (const [oldNodeId, oldNode] of Object.entries(oldNodeMap)) {
    assertWith(Folder.or(File), oldNode)
    assertWith(NodeId, oldNodeId)
    const newNodeId = oldIdsToNewIds.get(oldNodeId)

    if (oldNode.type === 'file') {
      assertWith(FileId, newNodeId)
      const newNode = { ...oldNode, id: newNodeId }
      newNodeMap[newNode.id] = newNode

      const slateDocument = oldSlateDocumentMap[oldNode.id]
      if (slateDocument === undefined) {
        continue
      }
      newSlateDocumentMap[newNode.id] = {
        ...slateDocument,
        nodes: replaceIdsInNodes(slateDocument.nodes) as SlateDocument,
      }
    } else {
      assertWith(FolderId, newNodeId)

      const nodeIds = oldNode.nodeIds.map(id => oldIdsToNewIds.get(id)).filter(guardWith(FileId.or(FolderId)))

      const newNode = { ...oldNode, id: newNodeId, nodeIds }
      newNodeMap[newNode.id] = newNode
    }
  }

  return { nodeMap: newNodeMap, slateDocumentMap: newSlateDocumentMap, oldIdsToNewIds }
}

const AddCardButton = React.forwardRef<HTMLDivElement, AddCardButtonProps>(
  (
    {
      cardPopperOpen,
      setCardPopperOpen,
      setCreateCardLocation,
      createContentId,
      scopedCreateContentId,
      operationState,
      selectedFileId,
      permission,
      generateCardModal,
      doOpenGenerateCardModal,
      closeGenerateCardModal,
      id,
      ariaControls,
      ariaExpanded,
      assetContext,
    },
    ref
  ) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [importModalOpen, { toggle: toggleImportModal }] = useToggle()
    const [pdfAnnotatorOpen, { on: doOpenPdfAnnotatorModal, off: closePdfAnnotatorModal }] = useToggle()
    const [isAddCardsOpen, setIsAddCardsOpen] = useState(false)
    const courseAnnotatePdfFeatureEnabled = getFlag('course-annotate-pdf')

    const setSidebarHasNestedMenuOpen = useSetAtom(sidebarHasNestedMenuOpenAtom)

    const onOpenChange = useCallback(
      (newIsOpen: boolean) => {
        setSidebarHasNestedMenuOpen(newIsOpen)
        setIsAddCardsOpen(newIsOpen)
      },
      [setSidebarHasNestedMenuOpen]
    )

    const userId = useCurrentUserId().data

    const openPdfAnnotatorModal = useCallback(() => {
      doOpenPdfAnnotatorModal()
      void dispatch(createAnnotatePdfButtonClicked())
    }, [dispatch, doOpenPdfAnnotatorModal])

    const openGenerateCardModal = useCallback(() => {
      doOpenGenerateCardModal()
    }, [doOpenGenerateCardModal])

    const isSelfPaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)

    const folderId =
      useSelector(state => flexSelectors.selectNodeParentFolderId(state, createContentId, selectedFileId)) ??
      'folder:root'

    const miscMenuItems = useMemo<Item[]>(
      () =>
        [
          {
            id: 'module',
            type: 'label',
            label: t('create.home.add-module'),
            icon: 'module',
          },
          {
            id: 'import',
            type: 'label',
            label: t('create.home.import-pdf'),
            icon: 'document--import',
          },
          courseAnnotatePdfFeatureEnabled
            ? {
                id: 'annotate',
                type: 'label',
                label: t('create.home.annotate-pdf'),
                icon: 'document--import',
              }
            : undefined,
          {
            id: 'generate',
            type: 'label',
            label: t('create.home.generate-from-doc'),
            icon: 'generate--paragraph',
          },
        ].filter((i): i is Item => i !== undefined),
      [t, courseAnnotatePdfFeatureEnabled]
    )

    const handleSelectItem = (item: MenuItem<MenuItemKeys>): void => {
      switch (item.id) {
        case 'module': {
          const settings: Folder['settings'] | Record<never, unknown> =
            isSelfPaced === true ? { settings: { reviewEnabled: true } } : {}
          const folder = createFolder({ title: 'Module', ...settings })
          apply(operationState, {
            type: 'add-folder',
            folder: folder,
            destination: { type: 'next-to', nodeId: folderId },
          })

          void dispatch(
            moduleAddedLogger({
              moduleId: folder.id,
            })
          )
          return
        }
        case 'import':
          toggleImportModal()
          return
        case 'annotate':
          openPdfAnnotatorModal()
          return
        case 'generate':
          openGenerateCardModal()
          return
        default:
          assertNever(item.id)
      }
    }

    const [container, setContainer] = useState<HTMLElement | null>(null)

    return (
      <>
        <AddCardButtonContainer overflow='hidden' grow ref={setContainer}>
          <AddCardButtonWrapper id='tour-create-addcard' grow>
            <AddCardRefContainer ref={ref}>
              <MenuButton
                grow
                id={id}
                isOpen={isAddCardsOpen}
                onOpenChange={onOpenChange}
                aria-haspopup='dialog'
                aria-expanded={ariaExpanded}
                aria-controls={ariaControls}
                onSelect={handleSelectItem}
                disabled={permission !== 'edit'}
                onPrimaryClick={() => {
                  setCreateCardLocation({ folderId: folderId })
                  setCardPopperOpen(!cardPopperOpen)
                }}
                menuDirection='up'
                menuItems={miscMenuItems}
                menuLabel={t('dictionary.more-options')}
                container={container}
              >
                {t('create.home.add-card')}
              </MenuButton>

              {miscMenuItems.map(item => (
                <ShortcutMenu.Action
                  key={item.id}
                  label={{ type: 'untranslated', value: item.label }}
                  permission='ACCESS_EDITOR'
                  group='create'
                  iconId={item.icon}
                  run={() => handleSelectItem(item)}
                />
              ))}
            </AddCardRefContainer>
            <CardPickerShortcut
              createContentId={createContentId}
              selectedFileId={selectedFileId}
              openGenerateCourseModal={doOpenGenerateCardModal}
            />
          </AddCardButtonWrapper>
        </AddCardButtonContainer>
        {importModalOpen && (
          <ImportModal open={importModalOpen} onClose={toggleImportModal} assetContext={assetContext} />
        )}
        {pdfAnnotatorOpen && (
          <PDFAnnotatorModal
            open={pdfAnnotatorOpen}
            onClose={closePdfAnnotatorModal}
            assetContext={assetContext}
          />
        )}
        {generateCardModal && (
          <GenerateCardModal
            open={generateCardModal}
            onClose={closeGenerateCardModal}
            onSaveNodes={(_nodeIds, _nodeMap, _slateDocumentMap) => {
              const { nodeMap, slateDocumentMap, oldIdsToNewIds } = replaceIds(_nodeMap, _slateDocumentMap)
              const nodeIds = _nodeIds.map(id => oldIdsToNewIds.get(id)).filter(isDefined)

              if (userId === undefined) return

              const rootFolder = nodeMap['folder:root']
              assertWith(Folder, rootFolder)
              const nodeIdsInOrder = flexSelectors.getSubtreeIds(rootFolder, nodeMap)

              const nodesToAdd = nodeIdsInOrder
                .filter(id => nodeIds.includes(id))
                .map(id => nodeMap[id])
                .filter(isDefined)
                // We should never add the root folder to an existing course
                .filter(it => it.id !== 'folder:root')

              const visibleNodeIds = new Set(nodesToAdd.map(node => node.id))

              const folders = nodesToAdd.filter(guardWith(Folder))

              const addNodeOperations: CreateOperation[] = nodesToAdd.flatMap((node): CreateOperation[] => {
                if (node.type === 'folder') {
                  // Remove files which may not be visible
                  const nodeIds = node.nodeIds.filter(id => visibleNodeIds.has(id))
                  return [
                    { type: 'add-folder', folder: { ...node, nodeIds }, destination: { type: 'add-at-end' } },
                  ]
                }
                if (node.type === 'file') {
                  const folder = folders.find(folder => folder.nodeIds.includes(node.id))
                  const { backgroundImage, ...file } = node
                  return [
                    {
                      type: 'add-file',
                      file: { ...file },
                      folderId: folder?.id ?? 'folder:root',
                      slateDocument: normalize(asNonNullable(slateDocumentMap[node.id]?.nodes)),
                      destination: { type: 'add-at-end' },
                    },
                  ]
                }
                return []
              })

              apply(operationState, ...addNodeOperations)

              void dispatch(courseGenerationClickedSaveAllButton())

              void navigateToCreateContentId({
                scopedCreateContentId,
                nodeId: nodesToAdd.find(guardWith(File))?.id,
              })

              closeGenerateCardModal()
            }}
            assetContext={assetContext}
            scopedCreateContentId={scopedCreateContentId}
          />
        )}
      </>
    )
  }
)

type SecretAddCardButtonProps = {
  permission: ContentPermission
  setCardPopperOpen: (cardPopperOpen: boolean) => void
  setCreateCardLocation: (createCardLocation: CardLocation) => void
}

const SecretAddCardButton = React.forwardRef<HTMLDivElement, SecretAddCardButtonProps>(
  ({ permission, setCardPopperOpen, setCreateCardLocation }, ref) => {
    const { t } = useTranslation()

    return (
      <div ref={ref}>
        {permission === 'edit' && (
          <HiddenAddCardButton
            onPrimaryClick={() => {
              setCreateCardLocation('bottom')
              setCardPopperOpen(true)
            }}
          >
            {t('create.home.add-card')}
          </HiddenAddCardButton>
        )}
      </div>
    )
  }
)

const NoWrapScrollView = styled(ScrollView)`
  white-space: nowrap;
  padding-left: 28px;
  padding-right: 4px;
`

const LoadedCreatePageSidebar: React.FC<{
  withSpeakerTabs: boolean
  disableScroll: boolean
  rootFolder: Folder
}> = ({ withSpeakerTabs, disableScroll, rootFolder }) => {
  const { createContentId } = useCreatePageContext()
  const { permission } = useCreatePageYDocContext()

  const { setCreateCardLocation, setCardPopperOpen } = useCardPopperContext()

  const hasFiles = useSelector(state => flexSelectors.selectContentHasFiles(state, createContentId))
  useEffect(() => {
    // This timeout prevents the card picker from flashing shortly on the screen.
    const timeout = setTimeout(() => setCardPopperOpen(hasFiles === false), 200)
    return () => clearTimeout(timeout)
  }, [hasFiles, setCardPopperOpen])

  return (
    <ConditionalWrapper
      condition={!disableScroll}
      renderWrapper={children => (
        <NoWrapScrollView grow direction='column'>
          {children}
        </NoWrapScrollView>
      )}
    >
      <CreatePageSidebarItems withSpeakerTabs={withSpeakerTabs} rootFolder={rootFolder} />
      <SecretAddCardButton
        permission={permission}
        setCreateCardLocation={setCreateCardLocation}
        setCardPopperOpen={setCardPopperOpen}
      />
    </ConditionalWrapper>
  )
}

const SpeakerNotesToggle = styled(IconButton).attrs({ variant: 'transparent' })``

const SpeakerNotesText = styled(Text).attrs({ size: 'micro', color: 'foreground/muted', bold: true })`
  white-space: nowrap;
  margin-left: 4px;
`

export const CreatePageSidebarHeader: React.FC = () => {
  const { t } = useTranslation()
  const editorIds = useRequiredRouterEditorIds()
  const createContentId = editorIds.contentId
  const isLive = ScopedCreateContentId.isLiveContentId(editorIds.scopedContentId)
  const isDebugMode = useIsDebugMode()
  const setContentPreviewEnabled = useSetAtom(ContentPreviewEnabledAtom)
  const sidebarStatus = useAtomValue(collapsableSidebarStateAtom)

  const permission = useCreatePageYDocContextSafe()?.permission ?? ('none' as const)

  const { currentTab, setTab } = useSpeakerNoteContext()

  return (
    <>
      <View justifyContent='flex-end'>
        {isDebugMode && (
          <Tooltip title={'Debug Previews'}>
            <IconButton
              variant='transparent'
              iconId='card'
              onClick={() => setContentPreviewEnabled(v => !v)}
            />
          </Tooltip>
        )}
      </View>

      <View gap='none' marginBottom='10' paddingLeft='6'>
        {isLive ? (
          <HeaderItemTitle animate={sidebarStatus}>
            <SpeakerNotesText>
              {currentTab === 'Notes' ? t('create.speaker-notes.hide') : t('create.speaker-notes.add')}
            </SpeakerNotesText>
          </HeaderItemTitle>
        ) : null}
        <View gap='4' justifyContent='center' alignItems='flex-end'>
          {isLive && (
            <Tooltip title={currentTab === 'Notes' ? t('sidebar.close-notes') : t('sidebar.open-notes')}>
              <SpeakerNotesToggle
                size='small'
                iconId={currentTab === 'Notes' ? 'speaker--notes--off' : 'speaker--notes'}
                onClick={() => setTab(currentTab === 'Notes' ? 'Outline' : 'Notes')}
              />
            </Tooltip>
          )}
        </View>
      </View>
      <View paddingLeft='8' gap='8' marginBottom='10'>
        <CreatePageTitle createContentId={createContentId} editable={permission === 'edit'} />
      </View>
    </>
  )
}

const StyledPopover = styled(Popover)`
  border-radius: 20px;
  box-shadow:
    -24px 48px 128px 0px rgba(0, 0, 0, 0.16),
    0px 0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 16px 24px 0px rgba(0, 0, 0, 0.08);
`

const LoadedCreatePageBottomContainer: React.FC = () => {
  const { operationState, createContentId, scopedCreateContentId } = useCreatePageContext()
  const { nodeId: selectedFileId } = useCreatePageNodeIdContext()
  const { permission } = useCreatePageYDocContext()

  const [generateCardModal, { on: doOpenGenerateCardModal, off: closeGenerateCardModal }] = useToggle()

  const cardButtonDomId = useGenerateDomId()
  const cardMenuDomId = useGenerateDomId()

  const {
    createCardLocation,
    setCreateCardLocation,
    cardPopperOpen,
    setCardPopperOpen,
    ref: addCardMenuButtonRef,
  } = useCardPopperContext()

  const assetContext = { type: 'course' as const, courseId: createContentId }

  if (permission !== 'edit') return null

  return (
    <>
      <StyledPopover
        id={cardMenuDomId}
        isOpen={cardPopperOpen}
        onOpenChange={setCardPopperOpen}
        side='top'
        sideOffset={8}
        align='start'
        renderTrigger={() => (
          <AddCardComponent
            ref={addCardMenuButtonRef}
            addCardButton={
              <AddCardButton
                selectedFileId={selectedFileId}
                operationState={operationState}
                scopedCreateContentId={scopedCreateContentId}
                createContentId={createContentId}
                permission={permission}
                cardPopperOpen={cardPopperOpen}
                setCardPopperOpen={setCardPopperOpen}
                setCreateCardLocation={setCreateCardLocation}
                generateCardModal={generateCardModal}
                doOpenGenerateCardModal={doOpenGenerateCardModal}
                closeGenerateCardModal={closeGenerateCardModal}
                id={cardButtonDomId}
                ariaExpanded={cardPopperOpen}
                ariaControls={cardMenuDomId}
                assetContext={assetContext}
              />
            }
          />
        )}
      >
        <CardPicker
          selectedFileId={selectedFileId}
          createCardLocation={createCardLocation}
          onCardPicked={() => setCardPopperOpen(false)}
          createContentId={createContentId}
          openGenerateCourseModal={doOpenGenerateCardModal}
        />
      </StyledPopover>
      <ShortcutMenu.Action
        iconId='undo'
        group='create'
        label={{ type: 'untranslated', value: 'undo' }}
        run={() => undo(operationState)}
        permission={OWNER_ONLY}
      />
      <ShortcutMenu.Action
        iconId='redo'
        group='create'
        label={{ type: 'untranslated', value: 'redo' }}
        run={() => redo(operationState)}
        permission={OWNER_ONLY}
      />
    </>
  )
}

export const CreatePageBottomContainer: React.FC<{
  createContentId: CreateContentId
}> = ({ createContentId }) => {
  const rootFolder = useSelector(state =>
    flexSelectors.selectFlexibleContentFolder(state, createContentId, 'folder:root')
  )
  const contextLoading = useIsCreatePageContextLoading()
  const isLoading = !isDefined(rootFolder) || contextLoading
  if (isLoading) return null

  return <LoadedCreatePageBottomContainer />
}

export const CreatePageSidebar: React.FC<{
  disableScroll?: boolean
  createContentId: CreateContentId
}> = ({ disableScroll = false, createContentId }) => {
  const rootFolder = useSelector(state =>
    flexSelectors.selectFlexibleContentFolder(state, createContentId, 'folder:root')
  )
  const { nodeId: currentNodeId } = useCreatePageNodeIdContext()

  const contextLoading = useIsCreatePageContextLoading()
  const isLoading = !isDefined(rootFolder) || contextLoading
  if (isLoading) return <CreatePageSidebarSkeleton />

  return (
    <SetupMultiSelection createContentId={createContentId} currentCardId={currentNodeId}>
      <LoadedCreatePageSidebar
        withSpeakerTabs={false}
        disableScroll={disableScroll}
        rootFolder={rootFolder}
      />
    </SetupMultiSelection>
  )
}
