import React from 'react'
import { Link } from 'sierra-client/components/common/link'
import { getFlag } from 'sierra-client/config/global-config'
import { REQUIRED_ASSIGNMENTS_HELP_PAGE } from 'sierra-client/config/links'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AssignmentPriority } from 'sierra-domain/api/manage'
import { Icon, Popover, Tooltip } from 'sierra-ui/components'
import { Button, Checkbox, Switch, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const DescriptionWrapper = styled(View)`
  padding-left: 22px;
  max-width: calc(100% - 22px);
  white-space: pre-wrap;
`

const StyledLink = styled(Link)<{ disabled?: boolean }>`
  color: ${token('foreground/link')};

  &:hover {
    color: ${token('foreground/link')};
  }
  ${p =>
    p.disabled === true &&
    css`
      color: ${token('foreground/secondary')};
    `}
`

const RequiredWrapper = styled(View)`
  max-width: 250px;
`

type RequiredAssignmentSwitchProps = {
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
  disabledWithReason?: string
}

export const RequiredAssignmnentSwitch: React.FC<RequiredAssignmentSwitchProps> = ({
  assignmentPriority,
  setAssignmentPriority,
  disabledWithReason = undefined,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <View justifyContent='space-between'>
        <View gap='6'>
          <Icon
            iconId='arrow-up--filled'
            color={disabledWithReason !== undefined ? 'foreground/muted' : 'foreground/secondary'}
          />
          <Text color={disabledWithReason !== undefined ? 'foreground/muted' : undefined} bold>
            {t('author.course-settings.required-assignment')}
          </Text>
        </View>
        <Switch
          disabled={disabledWithReason !== undefined}
          checked={assignmentPriority === 'required'}
          onChange={val => {
            if (val) {
              setAssignmentPriority('required')
            } else {
              setAssignmentPriority('normal')
            }
          }}
          text=''
          size='small'
          textPos='left'
        />
      </View>
      <DescriptionWrapper>
        <Text size='micro' bold color='foreground/muted'>
          {t('assignment.high-priority-assignment.description')}{' '}
          <StyledLink
            disabled={disabledWithReason !== undefined}
            target='_blank'
            href={REQUIRED_ASSIGNMENTS_HELP_PAGE}
            size='micro'
            bold
          >
            {t('dictionary.learn-more')}
          </StyledLink>
        </Text>
      </DescriptionWrapper>
    </>
  )
}

export const RequiredAssignmentMenuItem: React.FC<{
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
  disabledWithReason?: string
}> = ({ assignmentPriority, setAssignmentPriority, disabledWithReason = undefined }) => {
  return (
    <Tooltip title={disabledWithReason} side='left'>
      <RequiredWrapper direction='column' gap='4'>
        <RequiredAssignmnentSwitch
          assignmentPriority={assignmentPriority}
          setAssignmentPriority={setAssignmentPriority}
          disabledWithReason={disabledWithReason}
        />
      </RequiredWrapper>
    </Tooltip>
  )
}

const Radiobutton = styled(Checkbox).attrs({ radio: true })``

const OptionList = styled(View).attrs({ direction: 'column', gap: '16' })`
  max-width: 100%;
`

const Option = styled(View).attrs({ direction: 'row', gap: '8' })`
  min-height: 42px;
`

const OptionsWrapper = styled(View)`
  overflow: hidden;
  white-space: pre-wrap;
`

const RequiredAssignmentWrapper = styled(View)`
  max-width: 332px;
`
export const RequiredAssignmnentDropdown: React.FC<{
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
}> = ({ assignmentPriority, setAssignmentPriority }) => {
  const { t } = useTranslation()

  return (
    <RequiredAssignmentWrapper direction='column'>
      <OptionsWrapper justifyContent='space-between' direction='column'>
        <Text bold color='foreground/muted'>
          {t('assignment.assignment-priority')}
        </Text>
        <OptionList>
          <Option>
            <Radiobutton
              checked={assignmentPriority === 'default'}
              onCheckedChange={() => setAssignmentPriority('default')}
            />
            <View direction='column' gap='none'>
              <Text bold>{t('assignment.default.title')}</Text>
              <Text size='micro' color='foreground/muted'>
                {t('assignment.default.description')}
              </Text>
            </View>
          </Option>
          <Option>
            <Radiobutton
              checked={assignmentPriority === 'normal'}
              onCheckedChange={() => setAssignmentPriority('normal')}
            />
            <View direction='column' gap='none'>
              <Text bold>{t('dictionary.assign')}</Text>
              <Text size='micro' color='foreground/muted'>
                {t('assignment.normal.description')}
              </Text>
            </View>
          </Option>
          <Option>
            <Radiobutton
              checked={assignmentPriority === 'required'}
              onCheckedChange={() => setAssignmentPriority('required')}
            />
            <View direction='column' gap='none'>
              <Text bold>{t('assignment.required.title')}</Text>
              <Text size='micro' color='foreground/muted'>
                {t('assignment.high-priority-assignment.description')}{' '}
                <StyledLink target='_blank' href={REQUIRED_ASSIGNMENTS_HELP_PAGE} size='micro' bold>
                  {t('dictionary.learn-more')}
                </StyledLink>
              </Text>
            </View>
          </Option>
        </OptionList>
      </OptionsWrapper>
    </RequiredAssignmentWrapper>
  )
}

export const RequiredProgramAssignmentSwitch: React.FC<{
  assignmentPriority: AssignmentPriority
  setAssignmentPriority: (value: AssignmentPriority) => void
}> = ({ assignmentPriority, setAssignmentPriority }) => {
  const { t } = useTranslation()

  if (getFlag('required-assignments') === false) {
    return null
  }

  return (
    <>
      <Popover
        align='start'
        sideOffset={8}
        renderTrigger={() => (
          <Button variant='secondary' customDecorator={<Icon iconId='overflow-menu--horizontal' />}>
            {t('dictionary.more-options')}
          </Button>
        )}
      >
        <RequiredAssignmentWrapper
          padding='16'
          direction='column'
          gap='4'
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Text size='micro' color='foreground/muted'>
            {t('dictionary.more-options')}
          </Text>
          <RequiredAssignmnentSwitch
            assignmentPriority={assignmentPriority}
            setAssignmentPriority={setAssignmentPriority}
          />
        </RequiredAssignmentWrapper>
      </Popover>
    </>
  )
}
