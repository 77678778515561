import { useQuery } from '@tanstack/react-query'
import { throttle, uniq } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FakeCdnPrefix } from 'sierra-client/api/content'
import { RouterLink } from 'sierra-client/components/common/link'
import { connectEditor, destroyEditor } from 'sierra-client/editor'
import {
  courseGenerationButtonClicked,
  courseGenerationClickedRegenerateFileButton,
  courseGenerationClickedSaveFileButton,
  courseGenerationContinueButtonClicked,
  courseGenerationPauseButtonClicked,
  courseGenerationRegenerateButtonClicked,
} from 'sierra-client/features/course-generation/logger'
import { useDeepEqualityMemo } from 'sierra-client/hooks/use-deep-equality-memo'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { typedPost } from 'sierra-client/state/api'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import { getSubtreeIds } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch } from 'sierra-client/state/hooks'
import { FocusEditorContext } from 'sierra-client/views/flexible-content/editor-focus-context'
import { EditorInner } from 'sierra-client/views/flexible-content/editor-inner'
import {
  DistanceBetweenSidebarFiles,
  FileBackgroundCss,
  FileBaseProps,
  FileContainerBaseStylings,
  MultiSelectionFileProps,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/file-base-stylings-css'
import { FileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { cleanupSlateDocument } from 'sierra-client/views/flexible-content/generate-from-file-modal/cleanup-slate-document'
import { CourseGenCloseButton } from 'sierra-client/views/flexible-content/generate-from-file-modal/course-gen-close-button'
import {
  generatePollCard,
  generateQuestionCard,
  generateReflectionCard,
  generateTitle,
} from 'sierra-client/views/flexible-content/generate-from-file-modal/generate-cards'
import {
  generateBulletCardHTMLSse,
  generateGeneralCardHTMLSse,
} from 'sierra-client/views/flexible-content/generate-from-file-modal/generate-course-sse'
import {
  getBulletCardPrompt,
  getGeneralCardPrompt,
} from 'sierra-client/views/flexible-content/generate-from-file-modal/generate-from-pdf-system-prompt'
import { OnSaveGeneratedNodes } from 'sierra-client/views/flexible-content/generate-from-file-modal/types'
import { getEditorLayoutForFileType } from 'sierra-client/views/flexible-content/get-editor-layout-for-file-type'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { MAX_PDF_SIZE_MB, usePdfData } from 'sierra-client/views/flexible-content/use-pdf-convert'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { SelfPacedCardCanvas } from 'sierra-client/views/self-paced/components/self-paced-card-canvas'
import {
  GenericCollaborationOptions,
  withCollaboration,
} from 'sierra-client/views/v3-author/collaboration/with-collaboration'
import { CourseGenPdfUploader } from 'sierra-client/views/v3-author/common/media-uploader/pdf-uploader'
import { PdfGenerationStartState } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { createSanaEditor } from 'sierra-client/views/v3-author/configuration/editor-configurations'
import { EditorContextProvider } from 'sierra-client/views/v3-author/editor-context/editor-context-provider'
import { parseAndSanitizeHTMLForGeneration } from 'sierra-client/views/v3-author/paste/with-paste-html'
import {
  ReflectionCardCreateContext,
  getAllowAnonymousResponses,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { renderLeaf } from 'sierra-client/views/v3-author/render-leaf'
import * as Renderer from 'sierra-client/views/v3-author/renderer'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import {
  CourseOutline,
  OutlinePdfData,
  OutputControls,
  PdfPageChunk,
  PdfPageData,
  PdfPageDataImage,
  defaultCourseLength,
} from 'sierra-domain/api/author-v2'
import { NanoId12, SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization/types'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { FileId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import {
  File,
  Folder,
  NodeMap,
  SlateFile,
  SlateFileType,
  assertIsSlateFile,
} from 'sierra-domain/flexible-content/types'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { patchEditor } from 'sierra-domain/patch-editor'
import { XRealtimeAuthorGenerateCourseOutlineFromPdf } from 'sierra-domain/routes'
import { SseEvent } from 'sierra-domain/routes-sse'
import { asNonNullable, assertNever, assertWith, guardWith, hash, iife, isDefined } from 'sierra-domain/utils'
import { BulletCardChild, SlateDocument, SlateRootElement } from 'sierra-domain/v3-author'
import { createBulletCard } from 'sierra-domain/v3-author/create-blocks'
import { allSlashMenuEntryIds } from 'sierra-domain/v3-author/slash-menu'
import { MenuItem, Modal, Tooltip } from 'sierra-ui/components'
import { Icon } from 'sierra-ui/components/icon'
import {
  Button,
  IconButton,
  LoaderAnimation,
  LoadingSpinner,
  ScrollView,
  Text,
  View,
} from 'sierra-ui/primitives'
import { MenuDropdownPrimitive } from 'sierra-ui/primitives/menu-dropdown'
import { token } from 'sierra-ui/theming'
import { ConditionalWrapper } from 'sierra-ui/utils'
import { Editor } from 'slate'
import styled from 'styled-components'
import { Awareness } from 'y-protocols/awareness'
import * as Y from 'yjs'

const supportedSlashMenuEntryIds = allSlashMenuEntryIds

const SelfPacedCardRendererContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-anchor: none;

  > * {
    &:first-child {
      display: flex;
      flex: 1 1 auto;
      height: 100%;
    }
  }
`

// This is a hack since FileContext requires a flexibleContentId
const scopedCreateContentId = ScopedSelfPacedContentId.fromId(SelfPacedContentId.parse('D-_XRWj2GsXh'))

const usePeerToPeerCollaboration = (): GenericCollaborationOptions => {
  const [yDoc] = useState(() => new Y.Doc())
  const awareness = useMemo(() => new Awareness(yDoc), [yDoc])
  useEffect(() => () => awareness.destroy(), [awareness])

  const yType: Y.XmlText = useMemo(() => {
    const map = yDoc.getMap()
    if (map.has('experimentalEditor')) return map.get('experimentalEditor') as Y.XmlText
    else {
      const document = new Y.XmlText()
      map.set('experimentalEditor', document)
      return document
    }
  }, [yDoc])

  const [editorId] = useState(() => nanoid12())
  return useMemo(
    () => ({
      awareness,
      editorId,
      yType,
      yDocId: scopedCreateContentId,
    }),
    [awareness, editorId, yType]
  )
}

const useEditor = (): { editor: Editor; editorId: NanoId12 } => {
  const pasteFile = (): void => {}
  const copyPasteAssetOptions = { type: 'disabled' as const }
  const options = usePeerToPeerCollaboration()
  const editorId = options.editorId
  const [editor] = useState(() => {
    const sanaEditor = createSanaEditor({ pasteFile, copyPasteAssetOptions })
    return withCollaboration(sanaEditor, options)
  })

  useEffect(() => {
    connectEditor(editor)
    return () => destroyEditor(editor)
  }, [editor])

  return { editor, editorId: NanoId12.parse(editorId) }
}

const ExperimentalEditor: React.FC<{
  mode: EditorMode
  editor: Editor
  editorId: string
  file: SlateFile
  assetContext: AssetContext
}> = ({ mode, editor, editorId, file, assetContext }) => {
  const readOnly = true
  const cursorsEnabled = false
  assertWith(SlateFileType, file.data.type)

  const renderElement = Renderer.useRenderElement({ file })

  return (
    <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
      <FocusEditorContext>
        <EditorContextProvider
          initialValue={editor.children}
          editor={editor}
          editorId={editorId}
          readOnly={readOnly}
          mode={mode}
          supportedSlashMenuEntryIds={supportedSlashMenuEntryIds}
          chatId={undefined}
          enableCommenting={false}
          assetContext={assetContext}
        >
          <EditorInner
            layout={getEditorLayoutForFileType(file.data.type)}
            cursorsEnabled={cursorsEnabled}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
          />
        </EditorContextProvider>
      </FocusEditorContext>
    </FileContext>
  )
}

function Card({
  file,
  slateDocument,
  assetContext,
}: {
  file: SlateFile
  slateDocument: SlateDocument
  assetContext: AssetContext
}): JSX.Element | null {
  const { editor, editorId } = useEditor()

  useEffect(() => patchEditor(editor, slateDocument, true), [slateDocument, editor])

  return (
    <ExperimentalEditor
      mode='create'
      editor={editor}
      editorId={editorId}
      file={file}
      assetContext={assetContext}
    />
  )
}

const SIDEBAR_PADDING_INNER = 16

const SidebarTopContainer = styled(View)`
  padding-block-start: 24px;
  padding-inline: ${SIDEBAR_PADDING_INNER + 10}px;
  padding-bottom: 8px;
`

const FullHeightView = styled(View)`
  height: 100%;
`

const LessonsScrollView = styled(ScrollView)`
  gap: 0;
  padding-inline: ${SIDEBAR_PADDING_INNER}px ${SIDEBAR_PADDING_INNER - 12}px;
`

const FileOverflowMenu: React.FC<{
  isOpen: boolean
  onSaveClick: () => void
  onGenerateClick: () => void
  onOpenChange: (isOpen: boolean) => void
}> = ({ isOpen, onSaveClick, onGenerateClick, onOpenChange }) => {
  const { t } = useTranslation()
  const menuItems: MenuItem[] = [
    {
      id: 'save',
      type: 'label',
      label: t('dictionary.save'),
    },
    {
      id: 'generate',
      type: 'label',
      label: t('author.generate-from-doc.re-generate'),
    },
  ]

  return (
    <MenuDropdownPrimitive
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      renderTrigger={() => <IconButton variant='transparent' iconId='overflow-menu--vertical' size='small' />}
      menuItems={menuItems}
      onSelect={item => {
        if (item.id === 'save') {
          onSaveClick()
        }
        if (item.id === 'generate') {
          onGenerateClick()
        }
      }}
      closeOnSelect
    />
  )
}

const FileNameWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  gap: 8px;
`

const OneLineText = styled(Text)`
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  align-items: center;
`

const SidebarBottomContainer = styled(View)`
  padding: ${SIDEBAR_PADDING_INNER + 10}px;
  padding-top: 8px;
  margin-top: auto;
`

const SidebarControlsContainer = styled(View)`
  border-block-start: 1px solid ${token('border/default')};
  padding-top: 20px;
  margin-top: 8px;

  button:first-child {
    flex: 1;
  }
`

const FileControlsContainerSpacer = styled.div`
  width: 28px;
`

const FileControlsContainer = styled.div`
  position: absolute;
  right: 6px;
`

const FileOverflowMenuContainer = styled.div<{ $visible: boolean }>`
  opacity: ${p => (p.$visible ? 1 : 0)};
`

const FileContainer = styled.div<FileBaseProps & MultiSelectionFileProps>`
  cursor: pointer;
  position: relative;
  transition: border-color 50ms cubic-bezier(0.25, 0.1, 0.25, 1);
  display: flex;

  justify-content: space-between;
  text-align: left;
  gap: 10px;

  white-space: nowrap;
  text-overflow: ellipsis;

  margin: ${DistanceBetweenSidebarFiles} 0 ${DistanceBetweenSidebarFiles} 0;

  align-items: center;

  ${FileContainerBaseStylings}
  &::before {
    content: '';
    width: calc(100% - 10px);
    height: 2px;
    position: absolute;
    background: transparent;
    border-radius: 10px;
    bottom: 0px; /* Needs to have a default positioning as it will otherwise mess with the click listeners */
  }

  ${FileBackgroundCss}
`

const FolderTitleRow = styled(View).attrs({
  radius: 'regular',
})`
  height: 36px;
  padding: 8px 12px 8px 10px;
  gap: 10px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const NestedItemsContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;

  margin-left: 22px;

  & > li {
    padding: 0;
  }
`

const AbsoluteText = styled(Text).attrs({ size: 'technical', color: 'foreground/secondary' })`
  position: absolute;
  top: 0;
  right: 0;
`

function SidebarFile({
  role,
  file,
  isSelected,
  isHighlighted = false,
  onItemClick,
  onSaveSingleCard,
  onRegenerateClick,
  isGenerating,
  slateDocumentMap,
  assetContext,
}: {
  role?: string
  file: File
  isSelected: boolean
  isHighlighted?: boolean
  onItemClick: () => void
  onSaveSingleCard: () => void
  onRegenerateClick: () => void
  isGenerating?: boolean
  slateDocumentMap: SlateDocumentMap
  assetContext: AssetContext
}): JSX.Element {
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const dispatch = useDispatch()

  const [isOverflowMenuOpen, setIsOverflowMenuOpen] = useState(false)
  const [isOverflowMenuMouseOver, { on, off }] = useToggle()

  useEffect(() => {
    const anchorCurrent = nodeRef.current

    if (anchorCurrent === null) return

    anchorCurrent.addEventListener('mouseenter', on)
    anchorCurrent.addEventListener('mouseleave', off)

    return () => {
      anchorCurrent.removeEventListener('mouseenter', on)
      anchorCurrent.removeEventListener('mouseleave', off)
    }
  }, [off, on])

  const getFileTitle = useGetFileTitle(slateDocumentMap)

  return (
    <FileContainer
      $isSelected={isSelected}
      onClick={onItemClick}
      $isHighlighted={isHighlighted}
      ref={nodeRef}
      role={role}
    >
      <FileNameWrapper role='button'>
        <FileIcon file={file} assetContext={assetContext} />

        <Debug>
          <AbsoluteText>{file.id}</AbsoluteText>
        </Debug>
        <Tooltip title={getFileTitle(file)}>
          <OneLineText>{getFileTitle(file)}</OneLineText>
        </Tooltip>
      </FileNameWrapper>
      <FileControlsContainerSpacer />
      {/* Move the FileOverflowMenu to an absolute position container to prevent it from increasing the size of the <FileContainer /> */}
      <FileControlsContainer>
        {isGenerating === true ? (
          <LoadingSpinner size='small' padding='none' />
        ) : (
          <FileOverflowMenuContainer $visible={isOverflowMenuMouseOver || isOverflowMenuOpen}>
            <FileOverflowMenu
              isOpen={isOverflowMenuOpen}
              onOpenChange={isOpen => {
                setIsOverflowMenuOpen(isOpen)
              }}
              onSaveClick={() => {
                void dispatch(courseGenerationClickedSaveFileButton())
                onSaveSingleCard()
              }}
              onGenerateClick={() => {
                void dispatch(courseGenerationClickedRegenerateFileButton())
                onRegenerateClick()
              }}
            />
          </FileOverflowMenuContainer>
        )}
      </FileControlsContainer>
    </FileContainer>
  )
}

function SidebarFolder({
  folder,
  nodeMap,
  slateDocumentMap,
  visibleNodeIds,
  selectedFileId,
  highlightedFileId,
  onItemClick,
  onSaveSingleCard,
  onRegenerateClick,
  currentGeneratingFileIds,
  assetContext,
}: {
  folder: Folder
  nodeMap: NodeMap
  slateDocumentMap: SlateDocumentMap
  visibleNodeIds: Set<NodeId>
  selectedFileId: FileId | undefined
  highlightedFileId: FileId | undefined
  onItemClick: (fileId: FileId) => void
  onSaveSingleCard: (nodeId: NodeId) => void
  onRegenerateClick: (fileId: FileId) => void
  currentGeneratingFileIds: FileId[]
  assetContext: AssetContext
}): JSX.Element {
  const nodes = folder.nodeIds.filter(id => visibleNodeIds.has(id)).map(id => asNonNullable(nodeMap[id]))
  const invisibleNodesInFolder = folder.nodeIds.filter(id => !visibleNodeIds.has(id))

  return (
    <>
      {folder.id !== 'folder:root' && (
        <FolderTitleRow>
          <Icon color='foreground/muted' iconId='module' />

          {folder.title === '' ? (
            <LoadingSpinner size='small' />
          ) : (
            <Tooltip title={folder.title}>
              <OneLineText>{folder.title}</OneLineText>
            </Tooltip>
          )}
        </FolderTitleRow>
      )}

      <ConditionalWrapper
        condition={folder.id !== 'folder:root'}
        renderWrapper={children => <NestedItemsContainer>{children}</NestedItemsContainer>}
      >
        <Debug>
          {folder.id === 'folder:root' && currentGeneratingFileIds.length > 0 && (
            <>
              <Text bold size='technical'>
                {
                  // eslint-disable-next-line react/jsx-no-literals
                  'Currently generating file ids:'
                }
              </Text>
              {currentGeneratingFileIds.map(id => (
                <Text key={id} size='technical'>
                  {id}
                </Text>
              ))}
            </>
          )}

          {invisibleNodesInFolder.length > 0 && (
            <>
              <Text bold size='technical'>
                {
                  // eslint-disable-next-line react/jsx-no-literals
                  'Nodes left to be generated:'
                }
              </Text>
              {invisibleNodesInFolder.map(id => (
                <Text key={id} size='technical'>
                  {id}
                  {iife(() => {
                    const node = nodeMap[id]
                    const type = node?.type === 'file' ? node.data.type : node?.type
                    return <>({type})</>
                  })}
                </Text>
              ))}
            </>
          )}
        </Debug>
        {nodes.map(node => {
          if (node.type === 'folder') {
            return (
              <SidebarFolder
                key={node.id}
                folder={node}
                nodeMap={nodeMap}
                slateDocumentMap={slateDocumentMap}
                visibleNodeIds={visibleNodeIds}
                selectedFileId={selectedFileId}
                highlightedFileId={highlightedFileId}
                onItemClick={onItemClick}
                onSaveSingleCard={onSaveSingleCard}
                onRegenerateClick={onRegenerateClick}
                currentGeneratingFileIds={currentGeneratingFileIds}
                assetContext={assetContext}
              />
            )
          }
          if (node.type === 'file')
            return (
              <SidebarFile
                key={node.id}
                file={node}
                role='listitem'
                slateDocumentMap={slateDocumentMap}
                isSelected={selectedFileId === node.id || highlightedFileId === node.id}
                onItemClick={() => onItemClick(node.id)}
                onSaveSingleCard={() => onSaveSingleCard(node.id)}
                onRegenerateClick={() => onRegenerateClick(node.id)}
                isGenerating={currentGeneratingFileIds.includes(node.id)}
                assetContext={assetContext}
              />
            )
          else return <React.Fragment key={node.id} />
        })}
      </ConditionalWrapper>
    </>
  )
}

function SidebarTitle({ status }: { status: 'idle' | 'generating' | 'paused' }): JSX.Element {
  const { t } = useTranslation()

  switch (status) {
    case 'generating':
      return (
        <Text bold size='large'>
          {t('author.generate-from-doc.generating')}
        </Text>
      )
    case 'idle':
      return (
        <View>
          <Icon color='foreground/muted' iconId='checkmark--filled' />
          <Text bold size='large'>
            {t('author.generate-from-doc.generation-completed')}
          </Text>
        </View>
      )

    case 'paused':
      return (
        <View>
          <Icon color='foreground/muted' iconId='pause--filled' />
          <Text bold size='large'>
            {t('author.generate-from-doc.generation-paused')}
          </Text>
        </View>
      )
  }
  status satisfies never
}

function Sidebar({
  nodeMap,
  onFileClick,
  status,
  filename,
  onGenerateClick,
  onContinueClick,
  onSaveNodes,
  selectedFileId,
  highlightedFileId,
  onClickPause,
  onRegenerateClick,
  currentGeneratingFileIds,
  slateDocumentMap,
  assetContext,
}: {
  nodeMap: NodeMap
  onFileClick: (fileId: FileId) => void
  status: 'idle' | 'generating' | 'paused'
  filename: string
  onGenerateClick: () => void
  onContinueClick: () => void
  onSaveNodes: OnSaveGeneratedNodes
  onClickPause: () => void
  selectedFileId?: FileId
  highlightedFileId?: FileId
  onRegenerateClick: (fileId: FileId) => void
  currentGeneratingFileIds: FileId[]
  slateDocumentMap: SlateDocumentMap
  assetContext: AssetContext
}): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleRegenerate = useCallback(() => {
    void dispatch(courseGenerationRegenerateButtonClicked())
    onGenerateClick()
  }, [dispatch, onGenerateClick])

  const handlePause = useCallback(() => {
    void dispatch(courseGenerationPauseButtonClicked())
    onClickPause()
  }, [dispatch, onClickPause])

  const handleContinue = useCallback(() => {
    void dispatch(courseGenerationContinueButtonClicked())
    onContinueClick()
  }, [dispatch, onContinueClick])

  const slateDocumentFileIds = useDeepEqualityMemo(Object.keys(slateDocumentMap).filter(guardWith(FileId)))
  const visibleNodeIds = useMemo(() => {
    // Show all files which have a slate document generated for it
    const visibleSlateFiles: Set<NodeId> = new Set(slateDocumentFileIds)
    // Show all folders which have at least one visible file
    const visibleFolders = Object.values(nodeMap)
      .filter(isDefined)
      .filter(node => node.type === 'folder' && node.nodeIds.some(id => visibleSlateFiles.has(id)))
      .map(it => it.id)

    return new Set([
      ...visibleFolders,
      ...visibleSlateFiles,
      // Show all currently generating files as well
      ...currentGeneratingFileIds,
    ])
  }, [currentGeneratingFileIds, nodeMap, slateDocumentFileIds])

  const onSaveSingleCard = useCallback(
    (nodeId: NodeId) => onSaveNodes([nodeId], nodeMap, slateDocumentMap),
    [nodeMap, onSaveNodes, slateDocumentMap]
  )

  const rootFolder = asNonNullable(nodeMap['folder:root'])
  assertWith(Folder, rootFolder)

  return (
    <FullHeightView direction='column' gap='none'>
      <SidebarTopContainer direction='column'>
        <SidebarTitle status={status} />
      </SidebarTopContainer>

      <LessonsScrollView role='list' paddingTop='8'>
        <SidebarFolder
          folder={rootFolder}
          nodeMap={nodeMap}
          slateDocumentMap={slateDocumentMap}
          visibleNodeIds={visibleNodeIds}
          selectedFileId={selectedFileId}
          highlightedFileId={highlightedFileId}
          onItemClick={onFileClick}
          onSaveSingleCard={onSaveSingleCard}
          onRegenerateClick={onRegenerateClick}
          currentGeneratingFileIds={currentGeneratingFileIds}
          assetContext={assetContext}
        />
      </LessonsScrollView>

      <SidebarBottomContainer direction='column'>
        <Text color='foreground/muted'>{filename}</Text>
        <SidebarControlsContainer>
          <Button
            variant='primary'
            disabled={status === 'generating'}
            onClick={() => {
              void dispatch(courseGenerationClickedSaveFileButton())
              onSaveNodes(Array.from(visibleNodeIds), nodeMap, slateDocumentMap)
            }}
          >
            {t('author.generate-from-doc.insert-cards')}
          </Button>

          {status === 'idle' && (
            <>
              <IconButton
                variant='secondary'
                iconId='restart'
                aria-label={t('author.generate-from-doc.restart')}
                tooltip={t('author.generate-from-doc.re-generate')}
                onClick={handleRegenerate}
              />
            </>
          )}

          {status === 'generating' && (
            <IconButton
              variant='secondary'
              iconId='pause--filled'
              aria-label={t('author.generate-from-doc.pause')}
              tooltip={t('dictionary.pause')}
              onClick={handlePause}
            />
          )}

          {status === 'paused' && (
            <>
              <IconButton
                variant='secondary'
                iconId='restart'
                aria-label={t('author.generate-from-doc.restart')}
                tooltip={t('author.generate-from-doc.re-generate')}
                onClick={handleRegenerate}
              />
              <IconButton
                variant='secondary'
                iconId='play--circle--filled'
                aria-label={t('author.generate-from-doc.continue')}
                tooltip={t('dictionary.continue')}
                onClick={handleContinue}
              />
            </>
          )}
        </SidebarControlsContainer>
      </SidebarBottomContainer>
    </FullHeightView>
  )
}

const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const SideBarContainer = styled.div`
  width: 344px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const CardButtonOverlayContainer = styled.div`
  position: absolute;
  bottom: ${SIDEBAR_PADDING_INNER}px;
  right: ${SIDEBAR_PADDING_INNER}px;
`

function isImageAcceptable(image: PdfPageDataImage): boolean {
  const aspectRatio = image.height / image.width
  // Remove images which are disproporationately tall
  if (aspectRatio > 3.5) return false

  // Remove images which are too small
  if (image.width < 50 || image.height < 50) return false

  return true
}

function imageIdsInPage(page: PdfPageData): string[] {
  return page.images?.filter(image => isImageAcceptable(image))?.map(image => image.path) ?? []
}

function imageTagsForPage(page: PdfPageData): string[] {
  // The FakeCdnPrefix is used to increase the likelyhood of the image being included in the LLM output
  return imageIdsInPage(page).map(image => `<img src="${FakeCdnPrefix}${image}" />`)
}

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoadingCourseOutline: React.FC = () => {
  return (
    <OuterContainer>
      <LoadingContainer>
        <LoaderAnimation size={24} />
        <Text color={'grey50'}>Generating course outline...</Text>
      </LoadingContainer>
    </OuterContainer>
  )
}

const CourseOutlineError: React.FC<{ restart: () => void }> = ({ restart }) => {
  return (
    <OuterContainer>
      <LoadingContainer>
        <View direction='column' alignItems='center' gap='12'>
          <Icon iconId='error--stop' size='size-24' />
          <Text color={'grey50'}>Failed to generate a course outline. Try again.</Text>
          <Button variant='ghost' icon='restart' onClick={restart}>
            Retry
          </Button>
        </View>
      </LoadingContainer>
    </OuterContainer>
  )
}

const GeneratePageEditor: FC<{
  onSaveNodes: OnSaveGeneratedNodes
  courseOutline: CourseOutline
  pdf: OutlinePdfData
  abortControllerRef: React.MutableRefObject<AbortController | undefined>
  outputControls: OutputControls | undefined
  onClose: () => void
  scopedCreateContentId: ScopedCreateContentId
}> = ({
  onClose,
  onSaveNodes,
  courseOutline,
  pdf,
  abortControllerRef,
  outputControls,
  scopedCreateContentId,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [selectedFileId, setSelectedFileId] = useState<FileId | undefined>(undefined)
  const [slateDocumentMap, setSlateDocumentMap] = useState<SlateDocumentMap>({})
  const [currentGeneratingFileIds, setCurrentGeneratingFileIds] = useState<FileId[]>([])
  const setFileIdIsGenerating = useCallback((fileId: FileId, isGenerating: boolean) => {
    setCurrentGeneratingFileIds(curr =>
      isGenerating ? uniq([...curr, fileId]) : curr.filter(it => it !== fileId)
    )
  }, [])

  const [generateStatus, setGenerateStatus] = useState<'idle' | 'generating'>('idle')

  const keepAllContent =
    outputControls?.courseLength === undefined || outputControls.courseLength === defaultCourseLength

  const assetContext: AssetContext = useMemo(() => ({ type: 'pdf-image' as const, pdfId: pdf.id }), [pdf.id])

  const generateFromPrompt = useStableFunction(
    async (
      chunks: PdfPageChunk[],
      targetCardType: 'general' | 'bullet',
      onIncomingResult: (gptHtml: string) => void
    ) => {
      let htmlDocument = ''

      const pdfImageIds = chunks.flatMap(chunk => chunk.pages.flatMap(page => imageIdsInPage(page)))

      const allValidImageUrls = new Set(pdfImageIds)

      const content = chunks
        .flatMap(chunk => {
          const imageHtmlTags =
            targetCardType === 'general' ? chunk.pages.flatMap(page => imageTagsForPage(page)) : []
          return [chunk.text, imageHtmlTags]
        })
        .join('\n')

      const onMessage = (event: SseEvent<any>): void => {
        const text = event.data.text
        htmlDocument = `${htmlDocument}${text}`.replaceAll('\n', '').replaceAll('  ', '')

        // We remove all src tags that are not valid image urls we intentionally added to the document
        const matches = [...htmlDocument.matchAll(/src="(?<url>[^"]+\.[a-zA-Z0-9]+)"/g)]
        const notValidUrls = matches
          .map(it => it.groups?.url)
          .filter(isDefined)
          .filter(src => !allValidImageUrls.has(src))

        const htmlDocumentImagesPruned = notValidUrls.reduce(
          (doc, url) => doc.replaceAll(new RegExp(`src="${url}"`, 'g'), ''),
          htmlDocument
        )

        onIncomingResult(htmlDocumentImagesPruned)
      }

      try {
        if (targetCardType === 'general') {
          await generateGeneralCardHTMLSse({
            outputControls,
            messages: [
              {
                role: 'system',
                content: [{ type: 'text', text: getGeneralCardPrompt({ keepAllContent: keepAllContent }) }],
              },
              { role: 'user', content: [{ type: 'text', text: content }] },
            ],
            pdfImageIds: pdfImageIds,
            pdfId: pdf.id,
            courseId: ScopedCreateContentId.extractId(scopedCreateContentId),
            onMessage,
            onError: e => console.error(e),
            signal: abortControllerRef.current?.signal,
          })
        } else {
          await generateBulletCardHTMLSse({
            outputControls,
            messages: [
              { role: 'system', content: [{ type: 'text', text: getBulletCardPrompt({ keepAllContent }) }] },
              { role: 'user', content: [{ type: 'text', text: content }] },
            ],
            onMessage,
            onError: e => console.error(e),
            signal: abortControllerRef.current?.signal,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  )

  const getChunkForFileId = useCallback(
    (fileId: FileId) => asNonNullable(courseOutline.pdfChunksByFileId[fileId]),
    [courseOutline.pdfChunksByFileId]
  )

  const getFile = useCallback(
    (fileId: FileId): SlateFile => {
      const file = asNonNullable(courseOutline.nodeMap[fileId])

      assertWith(File, file)
      assertIsSlateFile(file)

      return file
    },
    [courseOutline.nodeMap]
  )

  const getSlateDocument = useCallback(
    (fileId: FileId) => slateDocumentMap[fileId]?.nodes ?? [],
    [slateDocumentMap]
  )

  const allFileIds: FileId[] = useMemo(() => {
    const root = courseOutline.nodeMap['folder:root']
    assertWith(Folder, root)

    return getSubtreeIds(root, courseOutline.nodeMap).filter(guardWith(FileId))
  }, [courseOutline.nodeMap])

  const allFileIdsRef = useRef<FileId[]>([])
  allFileIdsRef.current = allFileIds

  const [debugData, setDebugData] = useState<
    Record<FileId, { html: string | undefined; chunks: PdfPageChunk[] } | undefined>
  >({})

  const generateFile = useStableFunction(async (fileId: FileId) => {
    // Since we are generating multiple files at once we want unique throttled function per file,
    // otherwise we risk issues with calls being lost when different files call the same function
    const setSlateDocument = throttle(
      (htmlDocument: string, wrapSlateDocWithBulletCard?: boolean) => {
        const parsedAndSanitized = parseAndSanitizeHTMLForGeneration(htmlDocument, {
          resolveImage: undefined,
        })
        const parsedAndSanitizedAndPruned = parsedAndSanitized.flatMap(slateNode => {
          try {
            SlateRootElement.parse(slateNode)
            return [slateNode]
          } catch (_) {
            return []
          }
        })
        const slateDocument = cleanupSlateDocument(SlateDocument.parse(parsedAndSanitizedAndPruned))

        const safeBulletCardChildren = slateDocument.filter(guardWith(BulletCardChild)) as BulletCardChild[]

        const file = courseOutline.nodeMap[fileId]

        const image = file?.type === 'file' ? file.backgroundImage : undefined

        const nodes =
          wrapSlateDocWithBulletCard === true
            ? [
                createBulletCard({
                  image,
                  children: safeBulletCardChildren,
                }),
              ]
            : slateDocument

        setSlateDocumentMap(slateDocumentMap => ({
          ...slateDocumentMap,
          [fileId]: { nodes, encoding: 'y-xml-text' },
        }))
      },
      250,
      { trailing: true, leading: false }
    )

    const chunks = getChunkForFileId(fileId)

    const currentGeneratedFile = courseOutline.nodeMap[fileId]
    if (currentGeneratedFile?.type !== 'file') return

    setFileIdIsGenerating(fileId, true)
    switch (currentGeneratedFile.data.type) {
      case 'general':
        await generateFromPrompt(chunks, 'general', html => {
          setDebugData(prev => ({ ...prev, [fileId]: { html, chunks } }))
          setSlateDocument(html)
        })
        break
      case 'bullet':
        await generateFromPrompt(chunks, 'bullet', html => {
          setDebugData(prev => ({ ...prev, [fileId]: { html, chunks } }))
          setSlateDocument(html, true)
        })
        break
      case 'slate-card': {
        const titleSlateDocument = await generateTitle(chunks, outputControls)
        setDebugData(prev => ({ ...prev, [fileId]: { chunks } }))
        setSlateDocumentMap(slateDocumentMap => ({
          ...slateDocumentMap,
          [fileId]: { nodes: titleSlateDocument, encoding: 'y-xml-text' },
        }))
        break
      }
      case 'question-card': {
        const titleSlateDocument = await generateQuestionCard(chunks)
        setDebugData(prev => ({ ...prev, [fileId]: { chunks } }))
        setSlateDocumentMap(slateDocumentMap => ({
          ...slateDocumentMap,
          [fileId]: { nodes: titleSlateDocument, encoding: 'y-xml-text' },
        }))
        break
      }
      case 'poll':
        await generatePollCard(
          chunks,
          pollSlateDocument => {
            setDebugData(prev => ({ ...prev, [fileId]: { chunks } }))
            setSlateDocumentMap(slateDocumentMap => ({
              ...slateDocumentMap,
              [fileId]: { nodes: pollSlateDocument, encoding: 'y-xml-text' },
            }))
          },
          outputControls,
          abortControllerRef.current
        )

        break
      case 'reflections':
        await generateReflectionCard(
          chunks,
          reflectionSlateDocument => {
            setDebugData(prev => ({ ...prev, [fileId]: { chunks } }))
            setSlateDocumentMap(slateDocumentMap => ({
              ...slateDocumentMap,
              [fileId]: { nodes: reflectionSlateDocument, encoding: 'y-xml-text' },
            }))
          },
          outputControls,
          abortControllerRef.current
        )
        break
      default:
        throw new Error('Unexpected file type: ' + currentGeneratedFile.data.type + '.')
    }

    setFileIdIsGenerating(fileId, false)
  })

  const generatedFileIds = useRef(new Set<FileId>())
  const continueGeneratingFiles = useStableFunction(async () => {
    setGenerateStatus('generating')
    abortControllerRef.current = new AbortController()

    const spawnWorker = (): Promise<void> => {
      const nextFileId = allFileIdsRef.current.find(id => !generatedFileIds.current.has(id))

      if (abortControllerRef.current?.signal.aborted === true) {
        return Promise.resolve()
      }

      if (nextFileId === undefined) {
        return Promise.resolve()
      }

      generatedFileIds.current.add(nextFileId)
      return (
        generateFile(nextFileId)
          // Once a file has been generated, spawn a new worker
          .then(() => spawnWorker())
      )
    }

    // Spawn a few workers that will generate the files concurrently
    // The number of workers here determines how many files are
    // generated concurrently
    await Promise.all([
      //
      spawnWorker(),
      spawnWorker(),
      spawnWorker(),
      spawnWorker(),
      spawnWorker(),
    ])

    setGenerateStatus('idle')
  })

  const generateNewCourse = useStableFunction(async () => {
    if (generateStatus === 'generating') return
    setSlateDocumentMap({})
    generatedFileIds.current = new Set()

    await continueGeneratingFiles()
  })

  const regenerateFile = useStableFunction(async (fileId: FileId) => {
    generatedFileIds.current.delete(fileId)
    setSlateDocumentMap(slateDocumentMap => ({
      ...slateDocumentMap,
      [fileId]: { nodes: [], encoding: 'y-xml-text' },
    }))
    await generateFile(fileId)
  })

  useEffect(() => {
    void generateNewCourse()
  }, [generateNewCourse])

  const hasGeneratedAllFiles = allFileIds.every(id => id in slateDocumentMap)
  const currentFileId = selectedFileId ?? allFileIds[0]

  const currentFile = currentFileId !== undefined ? getFile(currentFileId) : undefined

  return (
    <OuterContainer>
      <SideBarContainer>
        <Debug>
          <Button
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log(debugData)
            }}
          >
            {
              // eslint-disable-next-line react/jsx-no-literals
              'Debug'
            }
          </Button>
        </Debug>
        <Sidebar
          slateDocumentMap={slateDocumentMap}
          filename={pdf.title}
          nodeMap={courseOutline.nodeMap}
          onFileClick={setSelectedFileId}
          status={generateStatus === 'idle' ? (hasGeneratedAllFiles ? 'idle' : 'paused') : 'generating'}
          selectedFileId={selectedFileId}
          highlightedFileId={currentFileId}
          onGenerateClick={generateNewCourse}
          onContinueClick={() => {
            void continueGeneratingFiles()
          }}
          onSaveNodes={onSaveNodes}
          onClickPause={() => abortControllerRef.current?.abort()}
          onRegenerateClick={regenerateFile}
          currentGeneratingFileIds={currentGeneratingFileIds}
          assetContext={assetContext}
        />
      </SideBarContainer>

      {currentFile && (
        <PolarisCardTheme {...currentFile}>
          <ReflectionCardCreateContext
            context={{
              allowAnonymousResponses: getAllowAnonymousResponses(currentFile),
              setAllowAnonymousResponses: () => {},
            }}
          >
            <SelfPacedCardCanvas
              card={{ ...currentFile, backgroundImage: undefined }}
              assetContext={assetContext}
            >
              <SelfPacedCardRendererContainer>
                <Card
                  file={currentFile}
                  slateDocument={getSlateDocument(currentFile.id)}
                  assetContext={assetContext}
                />
              </SelfPacedCardRendererContainer>
            </SelfPacedCardCanvas>
            <CardButtonOverlayContainer>
              <Button
                variant='ghost'
                icon='restart'
                disabled={currentFileId !== undefined && currentGeneratingFileIds.includes(currentFileId)}
                onClick={() => {
                  void dispatch(courseGenerationClickedRegenerateFileButton())
                  void regenerateFile(currentFile.id)
                }}
              >
                {currentFileId !== undefined && currentGeneratingFileIds.includes(currentFileId)
                  ? 'Generating...'
                  : t('author.generate-from-doc.re-generate-card')}
              </Button>
            </CardButtonOverlayContainer>

            <CourseGenCloseButton onClick={onClose} />
          </ReflectionCardCreateContext>
        </PolarisCardTheme>
      )}
    </OuterContainer>
  )
}

type GenerateCardModalProps = {
  open: boolean
  onClose: () => void
  onSaveNodes: OnSaveGeneratedNodes
  assetContext: AssetContext
  scopedCreateContentId: ScopedCreateContentId
}

const HelpCenterLink = styled(RouterLink)`
  font-weight: 500;
`

type GenerateCardModalState =
  | { type: 'upload-pdf' }
  | { type: 'pdf-uploaded' }
  | { type: 'failed' }
  | { type: 'generating-outline' }
  | {
      type: 'preliminary-outline'
      courseOutline: CourseOutline
      pdf: OutlinePdfData
    }
  | {
      type: 'completed'
      courseOutline: CourseOutline
      pdf: OutlinePdfData
    }

export const GenerateCardModal: React.FC<GenerateCardModalProps> = ({
  open,
  onClose,
  onSaveNodes,
  assetContext,
  scopedCreateContentId,
}) => {
  const { t } = useTranslation()
  const abortControllerRef = useRef<AbortController>()
  const { uploadPdfFile } = usePdfData()
  const [generationStartState, _setGenerationStartState] = useState<PdfGenerationStartState | undefined>(
    undefined
  )

  const generateOutlineQuery = useQuery({
    queryKey: [
      XRealtimeAuthorGenerateCourseOutlineFromPdf.path,
      { hash: generationStartState ? hash(generationStartState) : undefined },
    ],
    queryFn: () => {
      if (generationStartState !== undefined)
        return typedPost(XRealtimeAuthorGenerateCourseOutlineFromPdf, {
          pdfId: generationStartState.pdfId,
          outputControls: generationStartState.controls,
          courseId: ScopedCreateContentId.extractId(scopedCreateContentId),
        })
      return undefined
    },
    refetchOnWindowFocus: false,
    enabled: generationStartState !== undefined,
    refetchInterval: query => {
      switch (query.state.data?.type) {
        case undefined:
        case 'completed':
        case 'error':
          return false
        // Poll quickly while the pdf is processing. We want to get to the preliminary outline as quickly as possible
        case 'processing':
          return 250
        // Once we have the preliminary outline it is not important to keep polling quickly
        case 'preliminary-outline':
          return 750
        default:
          assertNever(query.state.data)
      }
    },
  })

  const setGenerationStartState = useStableFunction((state: PdfGenerationStartState | undefined) => {
    _setGenerationStartState(state)
    if (state !== undefined) {
      void generateOutlineQuery.refetch()
    }
  })

  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(courseGenerationButtonClicked())
  }, [dispatch])

  const response = generateOutlineQuery.data

  const handleOnClose = useCallback(() => {
    abortControllerRef.current?.abort()
    onClose()
  }, [onClose])

  const restart = useStableFunction(() => {
    setGenerationStartState(undefined)
  })

  const state = useMemo((): GenerateCardModalState => {
    if (generationStartState === undefined) {
      return { type: 'upload-pdf' }
    }
    switch (response?.type) {
      case undefined:
      case 'processing':
        return { type: 'generating-outline' }
      case 'error':
        return { type: 'failed' }
      case 'completed':
        return { type: 'completed', courseOutline: response.courseOutline, pdf: response.pdf }
      case 'preliminary-outline':
        return {
          type: 'preliminary-outline',
          courseOutline: response.preliminaryCourseOutline,
          pdf: response.pdf,
        }
      default:
        assertNever(response)
    }
  }, [generationStartState, response])

  const showFullScreen = !(state.type === 'upload-pdf' || state.type === 'failed')

  return (
    <Modal
      size={showFullScreen ? 'full-screen' : { width: 476, height: 560 }}
      open={open}
      onClose={handleOnClose}
      disableScrollbarGutter
    >
      {state.type === 'generating-outline' && <LoadingCourseOutline />}
      {(state.type === 'completed' || state.type === 'preliminary-outline') && (
        <GeneratePageEditor
          abortControllerRef={abortControllerRef}
          courseOutline={state.courseOutline}
          outputControls={generationStartState?.controls}
          pdf={state.pdf}
          onSaveNodes={onSaveNodes}
          onClose={handleOnClose}
          scopedCreateContentId={scopedCreateContentId}
        />
      )}
      {state.type === 'failed' && <CourseOutlineError restart={restart} />}
      {state.type === 'upload-pdf' && (
        <>
          <CourseGenPdfUploader
            uploadFile={uploadPdfFile}
            maxFileSizeMb={MAX_PDF_SIZE_MB}
            startGenerating={payload => {
              setGenerationStartState(payload)
            }}
            title={t('author.generate-from-doc.upload-modal.title')}
            description={t('author.generate-from-doc.upload-modal.description')}
            disclaimer={
              <Trans
                i18nKey={'author.generate-from-doc.upload-modal.disclaimer' satisfies TranslationKey}
                components={{
                  bold: (
                    <HelpCenterLink href={'https://help.sana.ai/en/articles/104553-generate-from-file'} />
                  ),
                }}
              />
            }
            assetContext={assetContext}
          />
          <CourseGenCloseButton onClick={handleOnClose} />
        </>
      )}
    </Modal>
  )
}
