import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { Filter } from 'sierra-client/lib/filter'
import { dateTimesColumn, stringsColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { DataLoaderStateMachine, createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { FixedScrollTabular } from 'sierra-client/lib/tabular/provider/components/fixed-scroll'
import { useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { ManageHeader } from 'sierra-client/views/manage/components/manage-header'
import {
  EnrollmentStartTime,
  IdentityRef,
  PreviewEnrollmentRuleResponse,
  PreviewUser,
} from 'sierra-domain/api/manage'
import {
  XRealtimeAdminProgramsPreviewCreateEnrollmentRule,
  XRealtimeAdminProgramsPreviewEnrollUsers,
  XRealtimeAdminProgramsPreviewEnrollUsersCount,
  XRealtimeAdminUsersCountFilterUsers2,
} from 'sierra-domain/routes'

const getUserPreviewStatus = (previewUser: PreviewUser, t: TranslationLookup): string => {
  const { membership, enrollmentRuleAvailableAt } = previewUser

  if (membership?.started === true) {
    return t('manage.programs.user-preview.columns.already-started')
  }

  if (
    membership !== undefined &&
    enrollmentRuleAvailableAt !== undefined &&
    (membership.availableAt < enrollmentRuleAvailableAt || membership.enrolledBy === 'user')
  ) {
    return t('manage.programs.user-preview.columns.already-added')
  }

  if (enrollmentRuleAvailableAt !== undefined) {
    return t('manage.programs.user-preview.columns.enrolling')
  }

  return t('manage.programs.user-preview.columns.attribute-missing')
}

type UsersPreviewData = PreviewEnrollmentRuleResponse['users'][number]

type UserPreviewTableDefinition = TableDefinitionOf<
  UsersPreviewData,
  [
    { type: 'users'; ref: 'name' },
    { type: 'dateTimes'; ref: 'start-date' },
    { type: 'strings'; ref: 'status' },
  ]
>

type UserPreviewTableData = TableDataFromDefinition<UsersPreviewData, UserPreviewTableDefinition>

const userPreviewToTableData = (t: TranslationLookup): UserPreviewTableDefinition => ({
  nested: {},
  rows: { getId: u => u.userId },
  columns: [
    usersColumn({
      ref: 'name',
      sortable: false,
      header: translatedLabel('manage.programs.enrollment-rules.table.name'),
      hints: ['avatar', 'open-in-new-tab'],
      getData: u => ({
        firstName: u.firstName,
        lastName: u.lastName,
        avatarColor: u.avatar.type === 'color' ? u.avatar.color : undefined,
        avatar: getAvatarImage(
          u.userId,
          u.avatar.type === 'image' && u.avatar.image.type === 'file' ? u.avatar.image.file : undefined
        ),
        email: '', // TODO(seb): Add both fields to the API
        active: true, // TODO?
        status: 'active', // TODO(seb): Add both fields to the API
        id: u.userId,
        isRequiredAssignment: undefined,
      }),
    }),
    dateTimesColumn({
      ref: 'start-date',
      sortable: false,
      header: translatedLabel('manage.programs.enrollment-rules.table.start-date'),
      getData: u => (u.enrollmentRuleAvailableAt ? { date: u.enrollmentRuleAvailableAt } : undefined),
    }),
    stringsColumn({
      ref: 'status',
      sortable: false,
      header: translatedLabel('manage.programs.enrollment-rules.table.status'),
      getData: u => getUserPreviewStatus(u, t),
    }),
  ],
})

type NextKey = string | undefined

const fetchEnrollmentRuleUserPreview = ({
  cursor,
  limit = 10,
  query,
  ...previewProps
}: UserPreviewConfig & {
  cursor: NextKey
  limit?: number
  query?: string
}): Promise<PreviewEnrollmentRuleResponse> => {
  const { time, programGroupId, type } = previewProps

  if (type === 'rule') {
    const { filter } = previewProps
    return typedPost(XRealtimeAdminProgramsPreviewCreateEnrollmentRule, {
      filter,
      time,
      programGroupId,
      limit,
      cursor,
      query,
    })
  } else {
    const { identities } = previewProps
    return typedPost(XRealtimeAdminProgramsPreviewEnrollUsers, {
      identities,
      time,
      programGroupId,
      limit,
      cursor,
      query,
    })
  }
}

const fetchCount = async (
  props: UserPreviewConfig & {
    cursor: NextKey
    limit?: number
    query?: string
  }
): Promise<number> => {
  const { type } = props

  if (type === 'rule') {
    const { filter } = props
    const result = await typedPost(XRealtimeAdminUsersCountFilterUsers2, {
      filter,
    })

    return result.count
  } else {
    const result = await typedPost(XRealtimeAdminProgramsPreviewEnrollUsersCount, {
      identities: props.identities,
    })
    return result.count
  }
}

export const userPreviewDataLoader = (
  props: UserPreviewConfig,
  t: TranslationLookup
): DataLoaderStateMachine<UserPreviewTableData, { cursor: string | undefined }> =>
  createDataLoader({
    async fetchInit({ control, predicate }) {
      const query = predicate.query
      const cursor = undefined
      const limit = control.limit

      const count = await fetchCount({
        ...props,
        query,
        cursor,
        limit,
      })

      const res = await fetchEnrollmentRuleUserPreview({
        ...props,
        query,
        cursor,
        limit,
      })

      return {
        meta: { cursor: res.cursor },
        data: res.users,
        totalCount: count,
        done: res.cursor === undefined,
      }
    },

    async fetchMore({ control, predicate, meta, pagination }) {
      const res = await fetchEnrollmentRuleUserPreview({
        ...props,
        query: predicate.query,
        cursor: meta.cursor,
        limit: control.limit,
      })

      return {
        meta: { cursor: res.cursor },
        data: res.users,
        totalCount: pagination.total,
        done: res.cursor === undefined,
      }
    },

    transformResults(data) {
      return [definition2Data(userPreviewToTableData(t), data)]
    },
  })

type BaseConfig = {
  time: EnrollmentStartTime
  programGroupId: string
}

type OnceConfig = BaseConfig & {
  type: 'once'
  identities: IdentityRef[]
}

type RuleConfig = BaseConfig & {
  type: 'rule'
  filter: Filter
}

export type UserPreviewConfig = OnceConfig | RuleConfig

type UserPreviewProps = {
  previewConfig: UserPreviewConfig
}

const virtualColumns = {
  left: [],
  right: [],
}

export const UserPreviewTable: React.FC<UserPreviewProps> = ({ previewConfig }) => {
  const { t } = useTranslation()
  const dataLoader = useMemo(
    () => ({
      loader: userPreviewDataLoader(previewConfig, t),
      options: { queryKey: ['users-preview-tabular'] },
    }),
    [t, previewConfig]
  )

  const tableAPI = useTableAPI({
    dataLoader,
    virtualColumns,
    options: { limit: 50 },
  })

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <ManageHeader
        api={tableAPI.api}
        search={{ placeholder: 'manage.programs.enrollment-rules.search', initialOpen: true }}
      />
      <FixedScrollTabular />
    </TabularProviderFromTableAPI>
  )
}
