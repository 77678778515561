import fuzzysort from 'fuzzysort'
import { useCallback, useState } from 'react'
import { IdentitiesSelector } from 'sierra-client/components/common/identities-selector'
import { FetchIdentities, Identity } from 'sierra-client/components/common/identities-selector/types'
import { useNotif } from 'sierra-client/components/common/notifications'
import {
  BottomContainer,
  TabRootContainer,
} from 'sierra-client/components/sharing/tabs/components/containers'
import { CoursePermissionSection } from 'sierra-client/components/sharing/tabs/components/course-permission-section'
import { SharingModalMenuItemWithCollaborateControls } from 'sierra-client/components/sharing/tabs/components/sharing-modal-menu-item'
import { TabTop } from 'sierra-client/components/sharing/tabs/components/tab-top'
import {
  collaboratorsToIdentities,
  DropdownContainer,
  IdentitiesToCollaborators,
} from 'sierra-client/components/sharing/tabs/utils/autocomplete'
import { SharingModalContent } from 'sierra-client/components/sharing/types'
import { config } from 'sierra-client/config/global-config'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { removeSearchParams } from 'sierra-client/router/search-params'
import * as settingsActions from 'sierra-client/state/author-course-settings/actions'
import * as settingsState from 'sierra-client/state/author-course-settings/slice'
import { Collaborator } from 'sierra-client/state/author-course-settings/types'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { CourseRole } from 'sierra-domain/api/manage'
import { CourseId } from 'sierra-domain/api/nano-id'
import { LightUser } from 'sierra-domain/user'
import { isDefined } from 'sierra-domain/utils'
import { MenuItem } from 'sierra-ui/components'
import { Button, View } from 'sierra-ui/primitives'
import { SingleSelectDropdown } from 'sierra-ui/primitives/menu-dropdown'
import styled from 'styled-components'

const collaboratorSortValues: Record<CourseRole, number> = {
  owner: 3,
  editor: 2,
  commenter: 1,
}

const sortCollaboratorsFn = (a: Collaborator, b: Collaborator): number =>
  isDefined(a.role) && isDefined(b.role) ? collaboratorSortValues[b.role] - collaboratorSortValues[a.role] : 0

const ExistingCollaboratorsContainer = styled.div`
  margin-top: 8px;
  max-height: 260px;
  overflow-x: visible;
  overflow-y: hidden;

  /* Workaroud To align the sidebar with the share modal edge. */
  padding-right: 24px;
  margin-right: -24px;

  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`

const Container = styled(View)`
  max-width: 100%;
`

type CollaborateTabProps = {
  onClose: () => void
  content: SharingModalContent
  requestAccessUser?: LightUser
}

export const CollaborateTab: React.FC<CollaborateTabProps> = ({ content, onClose, requestAccessUser }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const notifications = useNotif()

  const [selectedIdentities, setSelectedIdentities] = useState<Identity[]>(() =>
    requestAccessUser !== undefined ? collaboratorsToIdentities([requestAccessUser]) : []
  )

  const [collaboratorRole, setCollaboratorRole] = useState<CourseRole>('editor')

  const availableCollaborators = useSelector(settingsState.selectors.selectAvailableCollaborators)
  const courseCollaborators = useSelector(settingsState.selectors.selectCollaborators)

  const fetchIdentities: FetchIdentities = useCallback(
    async (query: string) => {
      if (availableCollaborators === 'loading') return []
      const potentialIdentities = collaboratorsToIdentities(availableCollaborators)

      const result = fuzzysort
        .go(query, potentialIdentities, { keys: ['email', 'name'] })
        .map(({ obj }) => obj)
      return result
    },

    [availableCollaborators]
  )

  const userId = useSelector(selectUserId)

  const copyLink = new URL(window.location.href)
  const host = config.auth.host
  if (host !== undefined) copyLink.searchParams.set('x-host', host)

  const handleInviteCollaborators = (): void => {
    if (selectedIdentities.length === 0) return

    void dispatch(
      settingsActions.updateCollaborators({
        changedCollaborators: IdentitiesToCollaborators(selectedIdentities).map(u => ({
          ...u,
          role: collaboratorRole,
        })),
      })
    )

    setSelectedIdentities([])

    notifications.push({
      type: 'custom',
      level: 'info',
      icon: 'send--filled',
      body: 'Invite sent',
    })

    removeSearchParams(['request-access'])
  }

  const permissions = useContentKindPermissions(content.id)

  const courseRoleItems: MenuItem<CourseRole>[] = [
    { type: 'label', label: t('author.can-edit'), id: 'editor' },
    {
      type: 'label',
      label: t('share.label.can-comment'),
      id: 'commenter',
    },
  ]

  return (
    <TabRootContainer>
      <TabTop title={t('author.share-title', { title: content.title })} onClose={onClose} />
      <BottomContainer>
        <Container justifyContent='space-between' direction='column' gap={'32'}>
          <View direction='column'>
            {permissions.has('EDIT_COLLABORATORS') && (
              <View justifyContent='space-between'>
                <IdentitiesSelector
                  grow
                  fetchIdentities={fetchIdentities}
                  selectedIdentities={selectedIdentities}
                  onSelect={identity => setSelectedIdentities(previous => [...previous, identity])}
                  onUnselect={identity => {
                    setSelectedIdentities(previous => previous.filter(it => it.id !== identity.id))
                  }}
                  placeholder='manage.programs.enrollment-rules.add-users-or-groups'
                  trailingVisual={
                    <DropdownContainer>
                      <SingleSelectDropdown<CourseRole>
                        variant='ghost'
                        selectedItem={courseRoleItems.find(it => it.id === collaboratorRole)}
                        menuItems={courseRoleItems}
                        onSelect={item => setCollaboratorRole(item.id)}
                      />
                    </DropdownContainer>
                  }
                />
                <Button disabled={selectedIdentities.length === 0} onClick={handleInviteCollaborators}>
                  {t('dictionary.invite')}
                </Button>
              </View>
            )}

            <ExistingCollaboratorsContainer>
              {[...courseCollaborators].sort(sortCollaboratorsFn).map(u => (
                <SharingModalMenuItemWithCollaborateControls
                  key={u.uuid}
                  user={u}
                  contentId={CourseId.parse(content.id)}
                  showTransferOwnershipAction={permissions.has('TRANSFER_OWNERSHIP')}
                  readOnly={!permissions.has('EDIT_COLLABORATORS')}
                  onCollaboratorRoleChange={async collaborator => {
                    await dispatch(
                      settingsActions.updateCollaborators({
                        changedCollaborators: [collaborator],
                      })
                    )

                    if (collaborator.uuid === userId) {
                      void permissions.refetch()
                    }
                  }}
                />
              ))}
            </ExistingCollaboratorsContainer>
          </View>

          <CoursePermissionSection />
        </Container>
      </BottomContainer>
    </TabRootContainer>
  )
}
