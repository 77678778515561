import { DateTime } from 'luxon'
import { Dispatch, SetStateAction, useCallback } from 'react'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CollapsableEventForm } from 'sierra-client/views/manage/event-groups/components/collapsable-event-form'
import { EventForm } from 'sierra-client/views/manage/event-groups/components/event-form'
import {
  oneHourFromNowFloored,
  twoHoursFromNowFloored,
} from 'sierra-client/views/manage/event-groups/components/shared'
import {
  CalendarEventSchedule,
  CreateCalendarEventData,
  EventLocation,
} from 'sierra-client/views/manage/event-groups/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CalendarEventId } from 'sierra-domain/api/nano-id'
import { CalendarIntegrationSetting } from 'sierra-domain/content/session'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { Button, Spacer, Text } from 'sierra-ui/primitives'

export const EventList: React.FC<{
  events: CreateCalendarEventData[]
  setEvents: Dispatch<SetStateAction<CreateCalendarEventData[]>>
  canEditAssignments: boolean
}> = ({ events, setEvents, canEditAssignments }) => {
  const { t } = useTranslation()

  const firstEvent = events[0]

  const updateEventSchedule = useCallback(
    (id: string, newSchedule: CalendarEventSchedule): void =>
      setEvents(events.map(event => (event.id === id ? { ...event, schedule: newSchedule } : event))),
    [events, setEvents]
  )

  const updateEventLocation = useCallback(
    (id: string, newLocation: EventLocation | undefined): void =>
      setEvents(events.map(event => (event.id === id ? { ...event, location: newLocation } : event))),
    [events, setEvents]
  )

  const updateParticipantIdentities = useCallback(
    (id: string, newParticipantIdentities: IdentityWithMetadata[]): void =>
      setEvents(
        events.map(event =>
          event.id === id
            ? {
                ...event,
                participantIdentities: newParticipantIdentities,
              }
            : event
        )
      ),
    [events, setEvents]
  )

  const updateCalendarIntegration = useCallback(
    (id: CalendarEventId, calendarIntegrationSetting: CalendarIntegrationSetting): void =>
      setEvents(events.map(event => (event.id === id ? { ...event, calendarIntegrationSetting } : event))),
    [events, setEvents]
  )

  const updateFacilitatorIdentities = useCallback(
    (id: string, newFacilitatorIdentities: IdentityWithMetadata[]): void =>
      setEvents(
        events.map(event =>
          event.id === id
            ? {
                ...event,
                facilitatorIdentities: newFacilitatorIdentities,
              }
            : event
        )
      ),
    [events, setEvents]
  )

  const addEvent = (): void => {
    const lastEvent = events[events.length - 1]

    const newEvent: CreateCalendarEventData = {
      // NOTE! Ignored by the backend
      id: nanoid12() as CalendarEventId,
      schedule: lastEvent
        ? lastEvent.schedule
        : {
            type: 'with-time',
            startTime: oneHourFromNowFloored(),
            endTime: twoHoursFromNowFloored(),
            timeZone: DateTime.local().zoneName,
          },
      location: lastEvent ? lastEvent.location : undefined,
      participantIdentities: [],
      facilitatorIdentities: lastEvent?.facilitatorIdentities ?? [],
      calendarIntegrationSetting: config.organization.settings.calendarIntegrationSettings,
    }

    setEvents(previousEvents => [...previousEvents, newEvent])
  }

  const removeEvent = (eventId: string): void =>
    setEvents(previousEvents => previousEvents.filter(event => event.id !== eventId))

  return (
    <>
      {events.length > 1 && (
        <>
          <Text bold>{t('dictionary.sessions')}</Text>
          <Spacer size='16' />
          {events.map(event => (
            <>
              <CollapsableEventForm
                key={event.id}
                event={event}
                removeEvent={() => removeEvent(event.id)}
                updateSchedule={schedule => updateEventSchedule(event.id, schedule)}
                updateLocation={location => updateEventLocation(event.id, location)}
                updateParticipantIdentities={
                  canEditAssignments
                    ? newParticipantIdentities =>
                        updateParticipantIdentities(event.id, newParticipantIdentities)
                    : undefined
                }
                updateFacilitatorIdentities={newFacilitatorIdentities =>
                  updateFacilitatorIdentities(event.id, newFacilitatorIdentities)
                }
                updateCalendarIntegrationSetting={calendarIntegrationSetting => {
                  updateCalendarIntegration(event.id, calendarIntegrationSetting)
                }}
              />
              <Spacer size='16' />
            </>
          ))}
        </>
      )}
      {events.length === 1 && firstEvent !== undefined && (
        <EventForm
          event={firstEvent}
          updateSchedule={schedule => updateEventSchedule(firstEvent.id, schedule)}
          updateLocation={location => updateEventLocation(firstEvent.id, location)}
          updateParticipantIdentities={
            canEditAssignments
              ? newParticipantIdentities =>
                  updateParticipantIdentities(firstEvent.id, newParticipantIdentities)
              : undefined
          }
          updateFacilitatorIdentities={newFacilitatorIdentities =>
            updateFacilitatorIdentities(firstEvent.id, newFacilitatorIdentities)
          }
          updateCalendarIntegrationSetting={calendarIntegrationSetting => {
            updateCalendarIntegration(firstEvent.id, calendarIntegrationSetting)
          }}
        />
      )}
      <Spacer size='24' />
      <Button variant='ghost' grow onClick={addEvent}>
        {t('event-groups.add-another-date')}
      </Button>
    </>
  )
}
