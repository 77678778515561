import React, { FC, useMemo } from 'react'
import { getMediaLibraryImageUrl } from 'sierra-client/api/content'
import { mediaLibraryImageUsed } from 'sierra-client/core/logging/media-library/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useCachedQuery } from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import { createDataURlFromThumbhashBase64 } from 'sierra-client/utils/image-thumbhash'
import { ScrollContainer } from 'sierra-client/views/v3-author/images/scroll-container'
import { MediaLibraryGetAllMediaResponse } from 'sierra-domain/api/media-library'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { XRealtimeAuthorMediaLibraryGetAllMedia } from 'sierra-domain/routes'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const ScrollGridContainer = styled(ScrollContainer)`
  max-height: 100%;
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 24px;
`

const MediaMasonryContainer = styled.div`
  column-count: 3;
  break-inside: avoid;
  column-gap: 16px;
  height: 100%;
`

const MediaItemOuterContainer = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
`
const MediaItemInnerContainer = styled(View).attrs({ direction: 'column', gap: '4' })``

const MediaImageContainer = styled.div`
  background-color: ${token('surface/strong')};
  border-radius: 16px;
  width: 100%;
  transition: 100ms ease-in;
  outline: 0px solid ${p => p.theme.color.blueLight};
  cursor: pointer;

  &:hover {
    outline: 4px solid ${p => p.theme.color.blueLight};
  }
`
const MediaImage = styled.img<{ $placeholderBackground?: string }>`
  display: block;
  border-radius: 16px;
  width: 100%;
  height: auto;

  background: ${p =>
    p.$placeholderBackground !== undefined ? `center / cover url(${p.$placeholderBackground})` : undefined};
`

const TagLabel = styled(View)`
  border-radius: 50px;
  background-color: ${token('surface/soft')};
  padding: 3px 8px 3px 8px;
`

// TODO(z) clean this up
const EmptyLibraryContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`
type MediaFile = MediaLibraryGetAllMediaResponse['mediaLibraryFiles'][number]

const MediaLibraryImager: FC<{
  media: MediaFile
  onImageSelect: (_: ImageUnion) => void
}> = ({ media, onImageSelect }) => {
  const dispatch = useDispatch()
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)

  if (media.image.type !== 'media-library-image') {
    console.error('Found non-media-library image in media library: ', media)
    return null
  }

  const placeholder =
    media.image.thumbHashBase64 !== undefined && !isImageLoaded
      ? createDataURlFromThumbhashBase64(media.image.thumbHashBase64)
      : undefined

  return (
    <MediaItemOuterContainer key={media.id}>
      <MediaItemInnerContainer>
        <MediaImageContainer
          onClick={() => {
            onImageSelect(media.image)
            void dispatch(mediaLibraryImageUsed({}))
          }}
        >
          <MediaImage
            src={getMediaLibraryImageUrl(media.image, { width: 256 })}
            loading='lazy'
            width={media.image.width}
            height={media.image.height}
            $placeholderBackground={placeholder}
            onLoad={() => setIsImageLoaded(true)}
          />
        </MediaImageContainer>
        <View direction='column' gap='4'>
          <View wrap='wrap' gap='6'>
            <Text color='foreground/muted' size='micro'>
              {(media.size / 1024).toFixed(1)}KB
            </Text>

            <>
              <Icon iconId='radio-button--dot' size='size-12' />
              <Text color='foreground/muted' size='micro'>
                {media.image.width} x {media.image.height}
              </Text>
            </>
          </View>
          <View gap='4'>
            {media.tags.map(tag => (
              <TagLabel key={tag.id} alignItems='center' justifyContent='center'>
                <Text size='technical' bold color='grey90'>
                  {tag.name}
                </Text>
              </TagLabel>
            ))}
          </View>
        </View>
      </MediaItemInnerContainer>
    </MediaItemOuterContainer>
  )
}

export const MediaLibraryBrowser: React.FC<{
  onImageSelect: (_: ImageUnion) => void
  currentTab: { id: string; name: string }
}> = ({ onImageSelect, currentTab }) => {
  const { t } = useTranslation()
  const content = useCachedQuery(XRealtimeAuthorMediaLibraryGetAllMedia, {})
  const mediaLibraryContent = content.isSuccess ? content.data.mediaLibraryFiles : undefined
  const filteredContent = useMemo(() => {
    return currentTab.id === 'all-media'
      ? mediaLibraryContent
      : mediaLibraryContent?.filter(media => media.tags.some(tag => tag.id === currentTab.id))
  }, [mediaLibraryContent, currentTab])

  return (
    <ScrollGridContainer>
      {filteredContent?.length === 0 && (
        <EmptyLibraryContainer>
          <View direction='column'>
            <Text bold size='large'>
              {t('dictionary.media-library')}
            </Text>

            <Text bold color='foreground/muted'>
              {t('mycontent.nothing-here-yet')}
            </Text>
          </View>
        </EmptyLibraryContainer>
      )}
      <MediaMasonryContainer>
        {filteredContent?.map(media => (
          <MediaLibraryImager key={media.id} media={media} onImageSelect={onImageSelect} />
        ))}
      </MediaMasonryContainer>
    </ScrollGridContainer>
  )
}
