import { UseQueryResult } from '@tanstack/react-query'
import { graphql } from 'sierra-client/api/graphql/gql'
import { AuthenticatedUserIntegrationsQuery } from 'sierra-client/api/graphql/gql/graphql'
import { useGraphQuery, useInvalidateGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'

const authenticatedUserIntegrationsQuery = graphql(`
  query AuthenticatedUserIntegrations {
    viewer {
      integrations {
        microsoft {
          scope {
            type
            value
          }
          clientId
          createMeetingUrlScope {
            type
            value
          }
          createMicrosoftCalendarEventScope {
            type
            value
          }
        }
        google {
          scope {
            type
            value
          }
          clientId
          createMeetingUrlScope {
            type
            value
          }
          createGoogleCalendarEventScope {
            type
            value
          }
        }
        zoom {
          scope {
            type
            value
          }
          clientId
          createMeetingUrlScope {
            type
            value
          }
        }
      }
    }
  }
`)

export const useAuthenticatedUserIntegrationsQuery = (): UseQueryResult<
  AuthenticatedUserIntegrationsQuery,
  unknown
> => {
  return useGraphQuery(
    {
      document: authenticatedUserIntegrationsQuery,
      queryOptions: {
        staleTime: 1000 * 60 * 5,
      },
    },
    {}
  )
}

export const useInvalidateAuthenticatedUserIntegrationsQuery = (): (() => Promise<void>) => {
  return useInvalidateGraphQuery(authenticatedUserIntegrationsQuery)
}
