import { NanoId12 } from 'sierra-domain/api/nano-id'
import { UUID } from 'sierra-domain/api/uuid'
import { z } from 'zod'

export const SkillLevelSettingId = z.string().brand('SkillLevelSettingId')
export type SkillLevelSettingId = z.infer<typeof SkillLevelSettingId>

export const SkillLevelId = z.string().brand('SkillLevelId')
export type SkillLevelId = z.infer<typeof SkillLevelId>

export const SkillId = UUID.brand('SkillId')
export type SkillId = z.infer<typeof SkillId>

export const SkillRecommendationId = z.string().brand('SkillRecommendationId')
export type SkillRecommendationId = z.infer<typeof SkillRecommendationId>

export const ScenarioCardChatId = NanoId12.brand<'ScenarioCardChatId'>()
export type ScenarioCardChatId = z.infer<typeof ScenarioCardChatId>
