import React, { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { ShowCorrectAnswersContext } from 'sierra-client/hooks/use-show-correct-answers'
import { BreakoutRoomCard } from 'sierra-client/views/flexible-content/breakout-room-card'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { CreateImageCard } from 'sierra-client/views/flexible-content/image-card'
import { LobbyCard } from 'sierra-client/views/flexible-content/lobby-card'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { VideoCard } from 'sierra-client/views/flexible-content/video-card'
import { BottomContentContainer } from 'sierra-client/views/self-paced/bottom-content'
import { StaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { PDFExportContext } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-card'
import {
  ReflectionCardCreateContext,
  getAllowAnonymousResponses,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { CourseId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { FlexibleContentRecord, SlateDocumentMap } from 'sierra-domain/collaboration/serialization/types'
import { ContentType, ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder, assertIsSlateFile } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable, assertNever, guardWith } from 'sierra-domain/utils'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { Button, View } from 'sierra-ui/primitives'
import { min_height_100dvh } from 'sierra-ui/utils'
import styled from 'styled-components'

const ExportCardCanvas = styled(CardCanvas)`
  /* Every child should always take all of the available space */
  & > *:not(${BottomContentContainer}) {
    height: 100%;
  }
`
const Switch: React.FC<{
  file: File
  slateDocumentMap: SlateDocumentMap
  assetContext: AssetContext
}> = ({ file, slateDocumentMap, assetContext }) => {
  const readOnly = true
  const slateDoc = slateDocumentMap[file.id]

  switch (file.data.type) {
    case 'live-lobby':
      return <LobbyCard />
    case 'breakout-room':
      return <BreakoutRoomCard data={file.data} />
    case 'image':
      return (
        <CreateImageCard
          data={file.data}
          onUploadDone={() => {}}
          readOnly={readOnly}
          mode='create'
          assetContext={assetContext}
        />
      )
    case 'embed':
      return <EmbedCard data={file.data} onUploadDone={() => {}} readOnly={readOnly} />
    case 'stupid-questions':
      return <QACard fileId={file.id} data={file.data} readOnly={readOnly} />
    case 'video':
      return (
        <VideoCard
          readOnly={readOnly}
          video={file.data.video}
          setVideoDuration={() => {}}
          onUploadDone={() => {}}
          assetContext={assetContext}
          file={file}
        />
      )
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'notepad':
    case 'external-notepad':
    case 'question-card':
    case 'assessment-card':
    case 'sticky-notes':
    case 'project-card':
    case 'reflections':
    case 'homework': {
      assertIsNonNullable(slateDoc)
      assertIsSlateFile(file)

      return (
        <ReflectionCardCreateContext
          context={{
            allowAnonymousResponses: getAllowAnonymousResponses(file),
            setAllowAnonymousResponses: () => {},
          }}
        >
          <StaticEditor
            key={file.id}
            file={file}
            document={slateDoc.nodes as SlateRootElement[]}
            mode='template'
            assetContext={assetContext}
          />
        </ReflectionCardCreateContext>
      )
    }

    case 'drop-a-word':
    case 'scenario':
    case 'roleplay':
      return null

    default:
      assertNever(file.data)
  }
}

const PageBreakContainer = styled.div`
  page-break-before: always;
  height: 100%;
`

const CardRenderer: React.FC<{
  file: File
  slateDocumentMap: SlateDocumentMap
  scopedCreateContentId: ScopedCreateContentId
}> = props => {
  const { file, scopedCreateContentId } = props
  const flexibleContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  const assetContext = useMemo(
    () => ({ type: 'course' as const, courseId: flexibleContentId }),
    [flexibleContentId]
  )

  return (
    <PageBreakContainer>
      <PolarisCardTheme {...file}>
        <ExportCardCanvas card={file} assetContext={assetContext}>
          <FileContext file={file} scopedCreateContentId={scopedCreateContentId} liveSessionId={undefined}>
            <Switch {...props} assetContext={assetContext} />
          </FileContext>
        </ExportCardCanvas>
      </PolarisCardTheme>
    </PageBreakContainer>
  )
}

const FullWidth = styled(View).attrs({ direction: 'column', gap: 'none' })`
  border: none;
  width: 100%;
`

const FullHeight = styled(View).attrs({ direction: 'column' })`
  border: none;
  ${min_height_100dvh}
`
const PrintButton = styled(Button)`
  margin: 0.5rem auto;
  display: block;

  @media print {
    display: none !important;
  }
`

const getAllNodeIds = (record: FlexibleContentRecord, folder: Folder): NodeId[] => {
  return folder.nodeIds.flatMap(nodeId => {
    const node = record.jsonData.nodeMap[nodeId]
    if (node?.type === 'file') {
      return [node.id]
    }
    if (node?.type === 'folder') {
      return getAllNodeIds(record, node)
    }
    return []
  })
}

const SCALE_FACTOR = 3.77

const A4_HEIGHT = Math.floor(297 * SCALE_FACTOR)
const A4_WIDTH = Math.floor(210 * SCALE_FACTOR)

export const openPdfViewer = (contentType: ContentType, courseId: CourseId): void => {
  const prefix = ScopedCreateContentId.urlSubPath(contentType)

  const url = new URL(`${location.origin}/create/export/${prefix}/${courseId}`)
  const host = config.auth.host
  if (host !== undefined) url.searchParams.set('x-host', host)

  window.open(
    url,
    undefined,
    `menubar=no,location=no,resizable=no,scrollbars=no,status=no,width=${A4_WIDTH},height=${A4_HEIGHT}`
  )
}

export const ExportFlexibleContent = ({
  record,
  scopedCreateContentId,
}: {
  record: FlexibleContentRecord
  scopedCreateContentId: ScopedCreateContentId
}): JSX.Element | null => {
  const rootFolder = record.jsonData.nodeMap['folder:root']

  if (!guardWith(Folder, rootFolder)) return null

  return (
    <>
      <FullWidth direction='column'>
        <PrintButton onClick={window.print}>Print</PrintButton>
        {getAllNodeIds(record, rootFolder).map(nodeId => {
          const node = record.jsonData.nodeMap[nodeId]
          if (node !== undefined && node.type === 'file') {
            return (
              <FullHeight key={nodeId}>
                <ShowCorrectAnswersContext override={true}>
                  <PDFExportContext>
                    <CardRenderer
                      file={node}
                      slateDocumentMap={record.slateDocumentMap}
                      scopedCreateContentId={scopedCreateContentId}
                    />
                  </PDFExportContext>
                </ShowCorrectAnswersContext>
              </FullHeight>
            )
          }

          return <React.Fragment key={nodeId} />
        })}
      </FullWidth>
    </>
  )
}
