import React from 'react'
import { RequiredAssignmentIcon } from 'sierra-client/components/required-assignments/required-assignment-icon'
import { getFlag } from 'sierra-client/config/global-config'
import { ColorInheritingLink, User } from 'sierra-client/lib/tabular/components/cell-components'
import { UserRep } from 'sierra-client/lib/tabular/datatype/internal/reps/user-rep'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { UserStatusLabel } from 'sierra-client/views/manage/groups/components/status'
import { getUserName } from 'sierra-domain/utils'
import { UserDisplay } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const UserCell: React.FC<{ user: UserRep; hints: RenderHint[] }> = ({ user, hints }) => {
  return (
    <ColorInheritingLink
      href={`/manage/users/${user.id}`}
      next
      target={hints.includes('open-in-new-tab') ? '_blank' : undefined}
      preload={false}
    >
      {hints.includes('user-with-program-status') ? (
        <View direction='row' justifyContent='flex-start' alignItems='center' gap='4'>
          <UserDisplay
            primaryText={getUserName(user) ?? ''}
            avatar={{
              firstName: user.firstName,
              lastName: user.lastName,
              color: user.avatarColor,
              src: user.avatar,
            }}
            isRequiredAssignmentIcon={
              user.isRequiredAssignment === true && getFlag('required-assignments') ? (
                <RequiredAssignmentIcon />
              ) : undefined
            }
          />
          <UserStatusLabel status={user.status} />
        </View>
      ) : (
        <User
          disabled={user.status !== 'active'}
          hints={hints}
          email={user.email}
          firstName={user.firstName}
          lastName={user.lastName}
          color={user.avatarColor}
          src={user.avatar}
          underlineUserOnHover={hints.includes('underline-user-on-hover')}
          isRequiredAssignment={user.isRequiredAssignment ?? false}
        />
      )}
    </ColorInheritingLink>
  )
}
