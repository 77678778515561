import Ably from 'ably'
import { useAtomValue } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { logger } from 'sierra-client/logger/logger'
import { useRealTimeDataClient } from 'sierra-client/realtime-data/real-time-data-provider/use-realtime-data-client'
import { connectionStateAtom } from 'sierra-client/state/realtime-data'

type UseChannelResult = {
  connectionState: Ably.ConnectionState
  isReceivingData: boolean
}

type UseChannelBaseOptions = {
  channelName: string
  callback: (data: unknown, eventName: string | undefined) => void
  eventName?: string
}

const useChannelBase = (options: UseChannelBaseOptions): UseChannelResult => {
  const client = useRealTimeDataClient()
  const [attachedMap, setAttachedMap] = useState<Record<string, boolean>>({})
  const attached = attachedMap[options.channelName] ?? false
  const connectionState = useAtomValue(connectionStateAtom)

  // Make sure we are independent of the callback changing
  const stableCallback = useStableFunction(options.callback)

  useEffect(() => {
    const handleData = (message: Ably.InboundMessage): void => {
      const eventName = message.name
      stableCallback(message.data, eventName)
    }

    const handleUpdate = (newState: Ably.ChannelStateChange): void => {
      logger.debug('[Ably] channel state changed', { newState })
      setAttachedMap(curr => ({ ...curr, [options.channelName]: newState.current === 'attached' }))
    }

    const onMount = async (): Promise<void> => {
      try {
        client.onChannelStateChange(options.channelName, handleUpdate)
        await client.subscribeToChannel({
          channelName: options.channelName,
          eventName: options.eventName,
          callback: handleData,
        })
        setAttachedMap(curr => ({
          ...curr,
          [options.channelName]: client.getChannelState(options.channelName) === 'attached',
        }))
      } catch (error) {
        console.error('subscribe error', error)
      }
    }

    void onMount()

    return () => {
      client.unsubscribeToChannel({
        channelName: options.channelName,
        eventName: options.eventName,
        callback: handleData,
      })
      setAttachedMap(curr => ({
        ...curr,
        [options.channelName]: false,
      }))
      client.offChannelStateChange(options.channelName, handleUpdate)
    }
  }, [client, options.channelName, options.eventName, stableCallback])

  const result: UseChannelResult = useMemo(
    () => ({
      isReceivingData: attached && connectionState === 'connected',
      connectionState,
    }),
    [attached, connectionState]
  )

  return result
}

export const useChannel = (
  channelName: string,
  callback: (data: unknown, eventName: string | undefined) => void
): UseChannelResult => {
  return useChannelBase({ channelName, callback })
}

export const useChannelEvents = (
  channelName: string,
  eventName: string,
  callback: (data: unknown, eventName: string | undefined) => void
): UseChannelResult => {
  return useChannelBase({ channelName, callback, eventName })
}
