import { Logger } from 'sierra-client/core/logging/logger'
import { AssignmentPriority } from 'sierra-domain/api/manage'

export const defaultIsRequiredChangedLogger = Logger.trackLoggerWithExtra<
  { contentType: string; defaultIsRequired: boolean; contentId: string },
  { contentType: string; defaultIsRequired: boolean; contentId: string }
>(
  'default_is_required_changed',

  input => {
    return {
      ...input,
    }
  }
)

export const assignmentPriorityLogger = Logger.trackLoggerWithExtra<
  {
    contentType: string
    assignmentPriority: AssignmentPriority
    hasDueDate: boolean
    contentId: string
    userId: string
  },
  {
    contentType: string
    assignmentPriority: AssignmentPriority
    hasDueDate: boolean
    contentId: string
    userId: string
  }
>(
  'assignment_set_to_required',

  input => {
    return {
      ...input,
    }
  }
)
