import { useMemo } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { SanaLogo } from 'sierra-client/components/common/logos/sana-logo'
import { OrganizationLogo } from 'sierra-client/components/organization-logo'
import { config, getFlag } from 'sierra-client/config/global-config'
import { OrganizationLink, SquareLogo } from 'sierra-client/features/multi-tenancy/components'
import { useOrganizationCluster } from 'sierra-client/features/multi-tenancy/hooks/use-organization-cluster'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useCachedQuery } from 'sierra-client/state/api'
import { AccessibleOrganization } from 'sierra-domain/multi-tenancy'
import { XRealtimeGetAccessibleCommunityOrganizations } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Icon, MenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

/**
 * This hook constructs the menu items needed for the sidebar dropdown presentation.
 * It pulls in data via `useOrganizationCluster`.
 */
export const useOrganizationClusterMenuItems = (): Array<MenuItem> => {
  const orgCluster = useOrganizationCluster()
  const canAccessCommunityOrgs = useHasOrganizationPermission('ACCESS_LMS')

  const frontiersEnabled = getFlag('frontiers-community') && canAccessCommunityOrgs
  const communityOrganizationsQuery = useCachedQuery(
    XRealtimeGetAccessibleCommunityOrganizations,
    {},
    { enabled: frontiersEnabled }
  )

  const menuItems = useMemo(() => {
    if (orgCluster.loading || orgCluster.cluster.length === 0) {
      return []
    }

    return [
      {
        type: 'group',
        id: 'organizations-group',
        label: 'Organizations',
        menuItems: orgCluster.cluster.map(org => {
          return {
            type: 'canvas',
            id: org.domain,
            label: org.name,
            render() {
              const active = org.domain === config.organization.domain

              return (
                <OrganizationLink href={`//${org.domain}`} target='_blank'>
                  {isDefined(org.squareLogoUrl) ? (
                    <SquareLogo src={org.squareLogoUrl} />
                  ) : (
                    <OrganizationLogo orgName={org.name} brandSettings={undefined} />
                  )}

                  <Text bold={active}>{org.name}</Text>

                  {org.isClusterParent === true && <Icon iconId='building' color='foreground/muted' />}
                </OrganizationLink>
              )
            },
          }
        }),
      },
      {
        type: 'separator',
        id: 'organizations-separator',
      },
    ] satisfies MenuItem[]
  }, [orgCluster.cluster, orgCluster.loading])

  if (communityOrganizationsQuery.isSuccess) {
    const organizations = communityOrganizationsQuery.data.organizations

    if (organizations.length > 0) {
      return [
        ...menuItems,
        ...organizations.map(
          (org: AccessibleOrganization): MenuItem => ({
            type: 'canvas',
            id: org.namespaceId,
            render() {
              return (
                <View>
                  <RouterLink href={`https://${org.domain}`} target='_blank'>
                    <View>
                      <SanaLogo width={16} height={16} />
                      <Text bold>{org.name}</Text>
                    </View>
                  </RouterLink>
                </View>
              )
            },
          })
        ),
        {
          type: 'separator',
          id: 'frontiers-separator',
        },
      ]
    }
  }

  return menuItems
}
