import { groupBy } from 'lodash'
import { MeQueryQuery } from 'sierra-client/api/graphql/gql/graphql'
import { null2UndefinedDeep } from 'sierra-client/api/graphql/util/null'
import {
  CertificateStatus,
  checkCertificateStatus,
  getCertificateDate,
} from 'sierra-client/views/workspace/utils/certificates'
import { useGetIssuedCertificateSharingLinkedInURL } from 'sierra-client/views/workspace/utils/outgoing-urls'
import { isDefined } from 'sierra-domain/utils'

export type IssuedCertificateItem = {
  certificate: MeQueryQuery['me']['issuedCertificates'][number]['certificate']
  expiresAt?: string
  revokedAt?: string
  issuedAt: string
  pdfUrl: string
  snapshotImageUrl: string
  id: string
  supportingFileUrl?: string
}

export const meToCertificateItem = ({ me }: MeQueryQuery): Array<IssuedCertificateItem> => {
  return me.issuedCertificates.map(cert => {
    const nonNullCert = null2UndefinedDeep(cert)
    return {
      certificate: nonNullCert.certificate,
      expiresAt: nonNullCert.expiresAt,
      revokedAt: nonNullCert.revokedAt,
      issuedAt: nonNullCert.issuedAt,
      pdfUrl: nonNullCert.pdfUrl,
      snapshotImageUrl: nonNullCert.snapshotImageUrl,
      id: nonNullCert.id,
      supportingFileUrl: nonNullCert.supportingFileUrl,
    }
  })
}

/**
 * Helper to sort the certificate items according to design
 * */
export const sortCertificatesOnStatus = (items: IssuedCertificateItem[]): IssuedCertificateItem[] => {
  // Prep all the certificates by checking its status first
  const prepCerts = items.map(c => {
    const s = checkCertificateStatus(c)
    return { ...s, certificate: c, status: s.status }
  })

  const groupedByStatusItems = groupBy(prepCerts, c => c.status) as {
    // _.groupBy:s types arent well written so casting it here
    [K in CertificateStatus['status']]?: Array<
      { certificate: IssuedCertificateItem } & CertificateStatus & { status: K }
    >
  }

  // For expired certs, we also want to make sure if its just expired or expired long ago
  const expiredItems = groupBy(groupedByStatusItems.expired, i => {
    return i.since.months < 1 ? 'just-expired' : 'long-expired'
  })

  // grab all the soon to be expired
  const expiringSoonItems =
    groupedByStatusItems['expiring-soon']?.sort((a, b) => {
      if (isDefined(a.certificate.expiresAt) && isDefined(b.certificate.expiresAt)) {
        return a.certificate.expiresAt > b.certificate.expiresAt ? 1 : -1
      } else {
        return 0
      }
    }) ?? []

  // grab all the just expired
  const justExpiredItems = expiredItems['just-expired'] ?? []

  // grab all valid certificates. Do also sort them based on when it was issued
  const validItems =
    groupedByStatusItems.valid?.sort((a, b) => (a.certificate.issuedAt > b.certificate.issuedAt ? 1 : -1)) ??
    []

  // grab the long expired certificaes
  const longExpiredItems = expiredItems['long-expired'] ?? []

  return [
    // Merging them all again in a specific order
    ...expiringSoonItems,
    ...justExpiredItems,
    ...validItems,
    ...longExpiredItems,
    // Intentionally not adding the revoked items here
  ].map(({ certificate }) => certificate)
}

/**
 * This function helps with removing duplicates of issued certificates
 * It will try to find the issued certificate based on the following
 * - latest issuedAt
 * - does not have expiring date
 * - if it has expiring date, the latest one
 * - revoked ones
 * */
export const uniqueIssuedCertificates = (items: IssuedCertificateItem[]): IssuedCertificateItem[] => {
  const groups = groupBy(items, i => i.certificate.id)
  const uniqueCerts = Object.values(groups)
    .map(certificateGroups => {
      // Sort all issued certificate of the same id
      const sorted = [...certificateGroups]
        // Make sure we sort it first based on when it was issued first
        .sort((fst, snd) => (fst.issuedAt > snd.issuedAt ? 1 : -1))

        // This sorts based on validity and expiration date
        .sort((_fst, _snd) => {
          const fst = checkCertificateStatus(_fst)
          const snd = checkCertificateStatus(_snd)

          // If first item is valid or expiring-soon, use that
          if (fst.status === 'valid' || fst.status === 'expiring-soon') {
            return -1
          }

          // If second item is valid or expiring-soon, use that
          if (snd.status === 'valid' || snd.status === 'expiring-soon') {
            return 1
          }

          const fstDate = getCertificateDate(fst)
          const sndDate = getCertificateDate(snd)
          // Otherwise compare based on date
          if (isDefined(fstDate) && isDefined(sndDate)) {
            // "largest" date should be furthest up
            return fstDate > sndDate ? -1 : 1
          }

          return 0
        })

      // Only return the first item
      return sorted.at(0)
    })
    .filter(isDefined)

  return uniqueCerts
}

export const useGetLinkedInUrl = (c: IssuedCertificateItem): string => {
  const getLinkedInUrl = useGetIssuedCertificateSharingLinkedInURL()
  return getLinkedInUrl(c)
}
