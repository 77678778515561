import { useAtom, useAtomValue } from 'jotai'
import React, { Dispatch, SetStateAction } from 'react'
import { CSVLink } from 'react-csv'
import { joinFacilitators } from 'sierra-client/core/format'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { dateTimesColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import {
  getAttendanceTranslationKey,
  liveSessionToCsv,
} from 'sierra-client/views/manage/live-session/live-session-utils'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { LiveSessionAssignment } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { Button, Spacer, View } from 'sierra-ui/primitives'

type LiveSession = LiveSessionAssignment

type LiveSessionTableDefinition = TableDefinitionOf<
  LiveSession,
  [
    { type: 'dateTimes'; ref: 'startTime' },
    { type: 'strings'; ref: 'title' },
    { type: 'strings'; ref: 'facilitator' },
    { type: 'strings'; ref: 'attendance' },
  ]
>

type LiveSessionTableData = TableDataFromDefinition<LiveSession, LiveSessionTableDefinition>
type LiveSessionTableMeta = StaticDataLoaderMeta<LiveSessionTableData>

const tableDefinition = (t: TranslationLookup): LiveSessionTableDefinition => {
  return {
    columns: [
      dateTimesColumn({
        sortable: true,
        header: translatedLabel('dictionary.date'),
        ref: 'startTime',
        getData: r => {
          const startTime = scheduledOrNull(r.data)?.startTime
          return startTime !== undefined
            ? { date: new Date(startTime), format: 'LLL dd yyyy, HH:mm' }
            : undefined
        },
      }),
      stringsColumn({
        sortable: true,
        getData: r => r.data.title,
        header: translatedLabel('table.name'),
        ref: 'title',
        hints: ['bold'],
      }),
      stringsColumn({
        sortable: true,
        getData: r => joinFacilitators(r.facilitatorsInfo),
        header: translatedLabel('manage.live-session.facilitator'),
        ref: 'facilitator',
      }),
      stringsColumn({
        sortable: true,
        getData: r => (r.attendance === undefined ? '' : t(getAttendanceTranslationKey(r.attendance))),
        header: translatedLabel('table.attendance'),
        ref: 'attendance',
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.liveSessionId,
    },
  }
}

const searchKey: StaticLoaderSearchKeyBy<LiveSessionTableData> = (tableData, row) =>
  tableData.rows[row]?.data.facilitator.data ?? ''

const useLiveSessionTable = ({
  data,
  currentUserId,
  unassignLiveSessions,
}: {
  data: LiveSession[]
  currentUserId: UserId
  unassignLiveSessions: (ids: string[]) => void
}): UseTableAPI<LiveSessionTableData, LiveSessionTableMeta> => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')

  const loader = staticDataLoader(definition2Data(tableDefinition(t), data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const { facilitatorsInfo, liveSessionId } = row.rawData
            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  label: t('manage.view-details'),
                  onClick: () =>
                    getGlobalRouter().navigate({ to: `/manage/events/${row.rawData.liveSessionId}` }),
                  icon: 'calendar',
                },
                {
                  type: 'label',
                  id: 'unassign',
                  label: t('dictionary.unassign'),
                  color: 'destructive/background',
                  hidden:
                    facilitatorsInfo.some(facilitator => currentUserId === facilitator.userId) ||
                    !canEditAssignments,
                  onClick: () => unassignLiveSessions([liveSessionId]),
                  icon: 'user--remove',
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

type SearchSectionProps = {
  tableAPI: UseTableAPI<LiveSessionTableData, LiveSessionTableMeta>
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

const SearchSection: React.FC<SearchSectionProps> = ({ tableAPI, setUserAction }) => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  const query = useAtomValue(tableAPI.api.atoms.query)

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('dictionary.search')}
      />
      {canEditAssignments && (
        <View>
          <Button onClick={() => setUserAction({ modal: 'assign-session' })}>
            {t('manage.manage-sessions')}
          </Button>
        </View>
      )}
    </View>
  )
}

type ToolbarProps = {
  tableAPI: UseTableAPI<LiveSessionTableData, LiveSessionTableMeta>
  mapCsv: (ids: string[]) => Record<string, string>[]
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, mapCsv }) => {
  const { api } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.n-sessions',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('content.sessions'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('content.sessions')} />
    </View>
  )
}
export type UserLiveSessionsTableProps = {
  liveSessions: LiveSession[]
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  currentUserId: UserId
}

export const UserLiveSessionsTable: React.FC<UserLiveSessionsTableProps> = ({
  liveSessions,
  setUserAction,
  currentUserId,
}) => {
  const unassignLiveSessions = (ids: string[]): void => {
    setUserAction({
      modal: 'unassign',
      messageType: 'session',
      targets: ids.map(id => ({ id, type: 'session' })),
    })
  }

  const tableAPI = useLiveSessionTable({ data: liveSessions, unassignLiveSessions, currentUserId })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/events/${rawData.liveSessionId}` })
        },
      }}
    >
      <Spacer size='xsmall' />
      <SearchSection setUserAction={setUserAction} tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        mapCsv={(ids: string[]) =>
          liveSessions.filter(row => ids.includes(row.liveSessionId)).map(liveSessionToCsv)
        }
      />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(liveSessions.map(liveSessionToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
