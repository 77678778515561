import { useQuery } from '@tanstack/react-query'
import fuzzysort from 'fuzzysort'
import React, { useEffect, useMemo, useState } from 'react'
import { learnerTagsQuery } from 'sierra-client/api/queries/learner-tags'
import { useNotif } from 'sierra-client/components/common/notifications'
import { config } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { getAuthMethodTypeForEmail } from 'sierra-client/views/authentication/common'
import { useUserSettings } from 'sierra-client/views/user-settings/use-user-settings'
import {
  AccountSettingsFormConfig,
  HandleAccountSettingsFormChange,
  UserSettingsFormErrors,
  getDefaultAccountSettings,
  validateAccountSettingsForm,
} from 'sierra-client/views/user-settings/user-settings-utils'
import { AccountSettings } from 'sierra-domain/api/user'
import { CloseModalButton, Icon, Input, Modal } from 'sierra-ui/components'
import { Button, Text, View } from 'sierra-ui/primitives'
import { palette, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const TagsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 16px;
  margin-top: 16px;
`
const TagCard = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  width: 100%;
  height: 100%;
  aspect-ratio: 4 / 3;
  transition: all 100ms ease-in-out;
  background: ${p => (p.selected === true ? p.theme.color.blueVivid : palette.primitives.white)};
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.03);
  flex: none;
  order: 0;
  flex-grow: 0;
  user-select: none;
`

const Title = styled(Text)<{ color: string }>`
  transition: all 100ms ease-in-out;
  color: ${p => p.color};
`

const FloatingSave = styled.div`
  position: sticky;
  display: flex;
  background-color: ${token('surface/default')};
  bottom: 0;
  width: 100%;
  padding: 1rem;
  margin-right: 3rem;
  justify-content: flex-end;
`

const StyledInput = styled(Input)`
  input {
    color: ${token('foreground/primary')};

    &:hover {
      color: ${token('foreground/muted')};
    }
  }
`

export const UserManageTags: React.FC<{ onClose: () => void; refetch?: () => void }> = ({
  onClose,
  refetch,
}) => {
  const tagsWithAvailableContentQuery = useQuery(learnerTagsQuery)

  const [searchTerm, setSearchTerm] = useState('')

  const [userForm, setUserForm] = useState<AccountSettings>(getDefaultAccountSettings())
  const { currentUser, saveUserSettings } = useUserSettings()
  const [serverError, setServerError] = useState<I18nArgs | undefined>()
  const [error, setError] = useState<UserSettingsFormErrors>({
    userAccountSettings: {},
    notificationSettings: {},
  })
  const notif = useNotif()
  const orgConfig = config.organization

  const { t } = useTranslation()

  const tags = useMemo(() => {
    const res = tagsWithAvailableContentQuery.data
    if (res === undefined) return undefined

    const merged = res.rankedTags.map(tag => ({
      ...tag,

      // I want to check if tag.id is part of userForm.userAccountSettings.tags
      selected: userForm.userAccountSettings.tags.includes(tag.id),
    }))

    return merged
  }, [tagsWithAvailableContentQuery.data, userForm.userAccountSettings.tags])

  const [tagsState, setTagsState] = useState(tags)
  const [userLoaded, setUserLoaded] = useState(false)
  const [lock, setLock] = useState(false)

  const formConfig = useMemo<AccountSettingsFormConfig>(() => {
    const email = currentUser?.email ?? ''
    const authMethod = getAuthMethodTypeForEmail(orgConfig.auth, email)

    const disableName = email === '' || authMethod === 'saml'
    const disableEmail = true
    return {
      disableName,
      disableEmail,
    }
  }, [orgConfig.auth, currentUser?.email])

  useEffect(() => {
    if (currentUser === undefined) return

    setUserForm(getDefaultAccountSettings(currentUser))
    setUserLoaded(true)
  }, [currentUser])

  useEffect(() => {
    if (userLoaded === false || lock) return
    if (tags === undefined) return
    setTagsState(tags)
    setLock(true)
  }, [lock, tags, userLoaded])

  useEffect(() => {
    if (!lock) return
    if (tagsState === undefined) return

    const handleFormChange: HandleAccountSettingsFormChange = (rootField, field, value) => {
      setUserForm(f => ({
        ...f,
        [rootField]: {
          ...f[rootField],
          [field]: value,
        },
      }))
    }

    handleFormChange(
      'userAccountSettings',
      'tags',
      tagsState.filter(s => s.selected).map(s => s.id)
    )
  }, [tagsState, lock])

  useEffect(() => {
    return () => {
      void tagsWithAvailableContentQuery.refetch()
      refetch?.()
    }
  }, [tagsWithAvailableContentQuery, refetch])

  if (tagsState === undefined) return null

  const handleSave = async (): Promise<void> => {
    setError({ userAccountSettings: {}, notificationSettings: {} })
    setServerError(undefined)

    const filteredUserForm = {
      ...userForm,
      userAccountSettings: {
        ...userForm.userAccountSettings,
        tags: tagsState.map(({ selected, ...rest }) => rest).map(({ id }) => id),
      },
    }

    const result = validateAccountSettingsForm(filteredUserForm, formConfig)
    if (result.hasErrors) {
      setError(result.errors)
      return
    }

    const saveResult = await saveUserSettings(userForm)

    if (saveResult.error) {
      setServerError(saveResult.i18nArgs)
      notif.push({ type: 'error' })
      return
    }
    onClose()
    notif.push({ type: 'custom', level: 'success', body: t('notifications.settings-saved') })
    console.debug(serverError, error)
  }

  const fuzzySearch = fuzzysort.go(searchTerm, tagsState, {
    keys: ['data.name'],
  })

  const filteredTags = searchTerm === '' ? tagsState : fuzzySearch.map(result => result.obj)

  return (
    <Modal open size={'full-screen'}>
      <CloseModalButton ariaLabel={t('dictionary.close')} onClick={onClose} />
      <View
        padding='32 32'
        direction='column'
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <View grow>
          <View direction='column' gap='16' grow>
            <Text size='large' bold>
              {t('user-skill-settings.browse-categories')}
            </Text>
            <View direction='column'>
              <Text color='foreground/muted'>{t('user-categories-settings.keep-updated-message')}</Text>
              <StyledInput
                id='skills-search'
                placeholder={t('dictionary.search')}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </View>
            <View justifyContent='space-between'>
              <Text bold>{t('user-categories-settings.available-categories')}</Text>
            </View>
          </View>
        </View>
        {userLoaded && lock && (
          <TagsGrid>
            {filteredTags.map(tag => (
              <TagCard
                key={tag.id}
                selected={tag.selected}
                onClick={() => {
                  setTagsState(
                    tagsState.map(s => {
                      if (s.id === tag.id) return { ...s, selected: !s.selected }
                      return s
                    })
                  )
                }}
              >
                <View grow direction='column' justifyContent='space-between'>
                  <Title bold size='large' color={tag.selected === true ? 'white' : 'black'}>
                    {tag.data.name}
                  </Title>

                  <View hide={!tag.selected}>
                    <Icon iconId='checkmark--outline' color='white' />{' '}
                    <Text color='white'>{t('dictionary.selected')}</Text>
                  </View>
                </View>
              </TagCard>
            ))}
          </TagsGrid>
        )}
        <FloatingSave>
          <Button onClick={handleSave}>{t('dictionary.save')}</Button>
        </FloatingSave>
      </View>
    </Modal>
  )
}
