import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ScenarioChatState } from 'sierra-client/views/v3-author/scenario/chat/state'
import { isEmptyString } from 'sierra-domain/utils'
import { Button, IconButton, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import { fonts } from 'sierra-ui/theming/fonts'
import styled from 'styled-components'

const InputWrapper = styled(View).attrs({ grow: true })`
  background: white;
  color: black;
  border-radius: 20px;
  padding: 16px;
  padding-left: 20px;
  outline: 1px solid ${token('form/border/1')};
  box-shadow: 0px 2px 4px 0px #00000014;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const StyledInput = styled(TextAreaPrimitive)`
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  border-radius: 0;
  font-size: ${fonts.body.regular};
  background-color: white;
  color: black;
  max-height: 120px;

  &:hover {
    border: none;
    outline: none;
  }
`

const RoundIconButton = styled(IconButton)<{ $disabled: boolean }>`
  border-radius: 50%;
  align-self: flex-end;
  background-color: ${props => (props.$disabled ? token('form/disabled/foreground') : 'black')};
  color: white;

  ${props =>
    props.$disabled &&
    `
    cursor: not-allowed;
    pointer-events: none;
  `}
`

const EndButton = styled(Button)`
  position: absolute;
  left: 8px;
  bottom: 8px;
  color: ${token('foreground/muted')};
`

export const Input = React.forwardRef<
  HTMLDivElement,
  {
    onSubmit: (value: string) => void
    assistantName: string
    chatState: ScenarioChatState
    onConversationEnd: () => void
  }
>(({ onSubmit, assistantName, chatState, onConversationEnd }, ref) => {
  const { t } = useTranslation()
  const [input, setInput] = useState('')

  const onSubmitHandler = (): void => {
    onSubmit(input)
    setInput('')
  }

  const disabled = isEmptyString(input.trim()) || chatState.type !== 'waiting-for-user-message'

  return (
    <InputWrapper ref={ref}>
      <StyledForm
        onSubmit={event => {
          event.preventDefault()
          onSubmitHandler()
        }}
      >
        <StyledInput
          autoFocus
          value={input}
          autoExpand
          rows={3}
          placeholder={t('scenario-card.chat-input.placeholder', { name: assistantName })}
          onChange={v => setInput(v.currentTarget.value)}
          onKeyDown={event => {
            if (event.key === 'Enter' && event.shiftKey === false && !disabled) {
              event.preventDefault()
              onSubmitHandler()
            }
          }}
        />
        <RoundIconButton
          size='small'
          iconId='arrow--up'
          type='submit'
          $disabled={disabled}
          disabled={disabled}
        />
      </StyledForm>
      <AnimatePresence>
        {chatState.type === 'waiting-for-user-message' && isEmptyString(input) && (
          <motion.div
            style={{
              pointerEvents: 'none',
            }}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
              transition: { delay: 2, duration: 1 },
              transitionEnd: { pointerEvents: 'auto' },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.4 },
              transitionEnd: { pointerEvents: 'none' },
            }}
          >
            <EndButton icon='stop--recording' variant='transparent' onClick={onConversationEnd}>
              {t('scenario-card.chat-input.end-button.title')}
            </EndButton>
          </motion.div>
        )}
      </AnimatePresence>
    </InputWrapper>
  )
})
