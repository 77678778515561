import { useAtomValue } from 'jotai'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { IssuedCertificatesByUserQuery } from 'sierra-client/api/graphql/gql/graphql'
import { convertGQLAvatar } from 'sierra-client/api/graphql/util/convert-gql-avatar'
import { useHasCertPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  certificateIssuedByColumn,
  dateTimesColumn,
  expiryTimesColumn,
  issuedCertificatesColumn,
  issuesRevokedColumn,
  stringsColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import {
  TabularToolbar,
  TabularToolbarDownloadButton,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
  staticDataLoader,
} from 'sierra-client/lib/tabular/dataloader/static'
import {
  TableDataFromDefinition,
  TableDefinitionOf,
  definition2Data,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { RowRef } from 'sierra-client/lib/tabular/types'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { FCC } from 'sierra-client/types'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import { Avatar } from 'sierra-domain/api/manage'
import { isDefined, isNonNullable, isNotDefined } from 'sierra-domain/utils'
import { LabelMenuItem } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type IssuedCertificatesByUser = IssuedCertificatesByUserQuery['issuedCertificatesByUser']

type IssuedCertificate = {
  id: string
  issuedToUserEmail: string
  issuedToUserStatus: 'ACTIVE' | 'DEACTIVATED' | 'PENDING'
  issuedToUserId: string
  issuedToUserDisplayName: string
  issuedToUserAvatar: string | undefined
  hasSupportingFile: boolean
  supportingNote: string | undefined
  issuedAt: string
  issuedBy:
    | { type: 'user'; name: string; avatar: Avatar | undefined }
    | { type: 'content'; title: string }
    | { type: 'program'; title: string }
  expiresAt: string | undefined
  revokedAt: string | undefined
}

type IssuedCertificatesTableDefinition = TableDefinitionOf<
  IssuedCertificate,
  [
    { type: 'issuedCertificates'; ref: 'name' },
    { type: 'strings'; ref: 'email' },
    { type: 'strings'; ref: 'issuedCertificateId' },
    { type: 'issuesRevoked'; ref: 'lastAwardedAt' },
    { type: 'expiryTimes'; ref: 'expiryDate' },
    { type: 'dateTimes'; ref: 'revokedAt' },
    { type: 'certificateIssuedBy'; ref: 'issuedBy' },
    { type: 'strings'; ref: 'supportingNote' },
  ]
>

type IssuedCertificatesTableData = TableDataFromDefinition<
  IssuedCertificate,
  IssuedCertificatesTableDefinition
>

export type ManageIssuedCertificatesTabularActions = {
  onViewIssuedCertificate: (id: RowRef, api: TableAPI<IssuedCertificatesTableData>) => void
  onViewSupportingDoc: (id: RowRef, api: TableAPI<IssuedCertificatesTableData>) => void
  onRevokeCertificate: (id: RowRef, api: TableAPI<IssuedCertificatesTableData>) => void
}

const _issuedCertificatesToTableDefinition = ({
  nested,
}: {
  nested: Record<RowRef, IssuedCertificatesTableData>
}): IssuedCertificatesTableDefinition => ({
  nested,
  rows: {
    getId: c => c.id,
  },
  columns: [
    issuedCertificatesColumn({
      ref: 'name',
      header: { type: 'untranslated', label: 'Name' },
      sortable: true, // note: local sort
      hints: ['with-nested-row-toggle', 'underline-user-on-hover'],
      getData: c => ({
        id: c.id,
        issuedToUserId: c.issuedToUserId,
        issuedToUserEmail: c.issuedToUserEmail,
        issuedToUserStatus: c.issuedToUserStatus,
        issuedToUserDisplayName: c.issuedToUserDisplayName,
        issuedToUserAvatar: c.issuedToUserAvatar,
        hasSupportingFile: c.hasSupportingFile,
        supportingNote: c.supportingNote,
        issuedAt: new Date(c.issuedAt),
        expiresAt: c.expiresAt !== undefined ? new Date(c.expiresAt) : undefined,
        revokedAt: c.revokedAt !== undefined ? new Date(c.revokedAt) : undefined,
      }),
    }),
    stringsColumn({
      ref: 'email',
      enabled: false,
      header: { type: 'untranslated', label: 'Email' },
      sortable: true, // note: local sort,
      getData: c => c.issuedToUserEmail,
    }),
    stringsColumn({
      ref: 'issuedCertificateId',
      enabled: false,
      header: { type: 'untranslated', label: 'ID' },
      sortable: false,
      getData: c => c.id,
    }),
    issuesRevokedColumn({
      ref: 'lastAwardedAt',
      header: { type: 'untranslated', label: 'Awarded at' },
      sortable: true, // note: local sort
      getData: c => ({
        issued: new Date(c.issuedAt),
        revoked: c.revokedAt !== undefined ? new Date(c.revokedAt) : undefined,
      }),
    }),
    expiryTimesColumn({
      ref: 'expiryDate',
      header: { type: 'untranslated', label: 'Expiry date' },
      sortable: true, // note: local sort
      getData: c =>
        c.expiresAt !== undefined && c.revokedAt === undefined ? new Date(c.expiresAt) : undefined,
    }),
    dateTimesColumn({
      ref: 'revokedAt',
      enabled: false,
      header: { type: 'untranslated', label: 'Revoked at' },
      sortable: false,
      getData: c => (c.revokedAt !== undefined ? { date: new Date(c.revokedAt) } : undefined),
    }),
    certificateIssuedByColumn({
      ref: 'issuedBy',
      header: { type: 'untranslated', label: 'Issued by' },
      sortable: true, // note: local sort
      getData: c =>
        c.issuedBy.type === 'user'
          ? {
              id: c.id,
              type: 'user',
              displayName: c.issuedBy.name,
              avatar: c.issuedBy.avatar,
              hasSupportingFile: c.hasSupportingFile,
              supportingNote: c.supportingNote,
            }
          : {
              id: c.id,
              type: c.issuedBy.type,
              title: c.issuedBy.title,
            },
    }),
    stringsColumn({
      ref: 'supportingNote',
      enabled: false,
      header: { type: 'untranslated', label: 'Supporting note' },
      sortable: false,
      getData: c => c.supportingNote,
    }),
  ],
})

export const issuedCertificatesToTableDefinition = (
  issuedCertificatesByUser: IssuedCertificatesByUser,
  t: TranslationLookup
): IssuedCertificatesTableData => {
  const nested: Record<RowRef, IssuedCertificatesTableData> = {}
  const rows: IssuedCertificate[] = []
  for (const certificateUser of issuedCertificatesByUser) {
    const sortedIssuedForUser: IssuedCertificate[] = _.orderBy(
      certificateUser.issuedCertificates,
      [
        // Sort revoked certificates at the bottom
        cert => cert.revokedAt,
        // Sort by expiry date (undefined expiry on top)
        cert => cert.expiresAt,
        // Sort by issue date
        cert => cert.issuedAt,
      ],
      ['desc', 'desc', 'desc']
    ).map(cert => {
      let issuedBy: IssuedCertificate['issuedBy'] = {
        type: 'user',
        name: `[${t('dictionary.unknown')}]`,
        avatar: undefined,
      }

      if (isNonNullable(cert.issuedByUser)) {
        issuedBy = {
          type: 'user',
          name: cert.issuedByUser.displayName,
          avatar: convertGQLAvatar(cert.issuedByUser.avatar),
        }
      } else if (isNonNullable(cert.issuedByContent)) {
        issuedBy = { type: 'content', title: cert.issuedByContent.title }
      } else if (isNonNullable(cert.issuedByProgram)) {
        issuedBy = { type: 'content', title: cert.issuedByProgram.name }
      }

      return {
        id: cert.id,
        issuedToUserEmail: certificateUser.issuedToUser?.email ?? '',
        issuedToUserStatus: certificateUser.issuedToUser?.status ?? 'ACTIVE',
        issuedToUserId: certificateUser.userId,
        issuedToUserDisplayName: certificateUser.issuedToUser?.displayName ?? `[${t('dictionary.unknown')}]`,
        issuedToUserAvatar: certificateUser.issuedToUser?.avatar ?? undefined,
        supportingNote: cert.supportingNote ?? undefined,
        issuedAt: cert.issuedAt,
        issuedBy: issuedBy,
        expiresAt: cert.expiresAt ?? undefined,
        revokedAt: cert.revokedAt ?? undefined,
        hasSupportingFile: cert.hasSupportingFile,
      } satisfies IssuedCertificate
    })

    const first = sortedIssuedForUser[0]
    const rest = sortedIssuedForUser.slice(1)

    if (first === undefined) continue

    rows.push(first)

    if (rest.length > 0) {
      nested[first.id] = definition2Data(_issuedCertificatesToTableDefinition({ nested: {} }), rest)
    }
  }

  return definition2Data(_issuedCertificatesToTableDefinition({ nested }), rows)
}

const virtualColumns = ({
  actions,
  canRevoke,
  t,
}: {
  actions: ManageIssuedCertificatesTabularActions
  canRevoke: boolean
  t: TranslationLookup
}): VirtualColumns<IssuedCertificatesTableData> => ({
  left: [],
  right: [
    defaultMenuActionVirtualColumn<IssuedCertificatesTableData>({
      getProps: ({ api, pos }) => {
        const data = getRowDataFromTableAPI(api, pos.row)?.name.data
        if (isNotDefined(data)) return 'no-actions'

        const viewIssuedCertificate: LabelMenuItem = {
          id: pos.row + '-view-issued-certificate',
          type: 'label',
          label: t('manage.certificates.view-issued-certificate'),
          onClick: () => actions.onViewIssuedCertificate(pos.row, api),
        }

        const viewSupportingDoc: LabelMenuItem = {
          id: pos.row + '-view-supporting-doc',
          type: 'label',
          label: t('manage.certificates.view-supporting-document'),
          onClick: () => actions.onViewSupportingDoc(pos.row, api),
        }

        const revokeCertificate: LabelMenuItem = {
          id: pos.row + '-revoke-certificate',
          type: 'label',
          label: t('manage.certificates.revoke.label'),
          onClick: () => actions.onRevokeCertificate(pos.row, api),
        }

        const isExpired = data.expiresAt !== undefined && data.expiresAt.getTime() - Date.now() < 0
        const isRevoked = data.revokedAt !== undefined
        const showRevokeAction = canRevoke && !isExpired && !isRevoked

        const menuItems = [
          viewIssuedCertificate,
          data.hasSupportingFile ? viewSupportingDoc : undefined,
          showRevokeAction ? revokeCertificate : undefined,
        ].filter(isDefined)
        return {
          menuItems,
        }
      },
    }),
  ],
})

const searchKey: StaticLoaderSearchKeyBy<IssuedCertificatesTableData> = (tableData, row) =>
  tableData.rows[row]?.data.name.data.issuedToUserEmail ?? ''

export const useManageIssuedCertificatesTableAPI = ({
  certificateId,
  actions,
  issuedCertificatesByUser,
}: {
  certificateId: string
  actions: ManageIssuedCertificatesTabularActions
  issuedCertificatesByUser: IssuedCertificatesByUser | undefined
}): UseTableAPI<IssuedCertificatesTableData, StaticDataLoaderMeta<IssuedCertificatesTableData>> => {
  const { t } = useTranslation()

  const canRevoke = useHasCertPermission(certificateId, 'REVOKE')

  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({
        loader: staticDataLoader<IssuedCertificatesTableData>(
          issuedCertificatesToTableDefinition(issuedCertificatesByUser ?? [], t),
          searchKey
        ),
        options: { queryKey: ['manage-issued-certificates-tabular'] },
      }),
      [issuedCertificatesByUser, t]
    ),
    virtualColumns: React.useMemo(() => virtualColumns({ actions, canRevoke, t }), [actions, canRevoke, t]),
  })
}

const TableFallbackContainer = styled(View)`
  flex: 100;
`

const TableFallback: FCC<{ api: TableAPI<IssuedCertificatesTableData> }> = ({ children, api }) => {
  const pag = useAtomValue(api.atoms.pagination)
  const { t } = useTranslation()
  if (pag.done && pag.total === 0) {
    return (
      <TableFallbackContainer
        grow
        background='surface/soft'
        direction='column'
        justifyContent='center'
        padding='80'
        radius='size-8'
      >
        <Text align='center' color='foreground/muted' bold>
          {t('manage.certificates.issued-certificates-table.no-certificates')}
        </Text>
        <Text align='center' color='foreground/muted'>
          {t('manage.certificates.issued-certificates-table.no-certificates-helper')}
        </Text>
      </TableFallbackContainer>
    )
  }
  return <>{children}</>
}

const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.issued-certificates' }

export const ManageIssuedCertificatesTabular: React.FC<{
  certificateId: string
  certificateTitle: string
  actions: ManageIssuedCertificatesTabularActions
  issuedCertificatesByUser: IssuedCertificatesByUser | undefined
}> = React.memo(({ certificateId, certificateTitle, actions, issuedCertificatesByUser }) => {
  const { t } = useTranslation()

  const tableAPI = useManageIssuedCertificatesTableAPI({ certificateId, actions, issuedCertificatesByUser })

  const memoizedCallbacks = useMemo<TableCallbacks<IssuedCertificatesTableData>>(
    () => ({
      onRow: row => {
        const data = getRowDataFromTableAPI(tableAPI.api, row.ref)?.name.data
        if (isDefined(data)) {
          actions.onViewIssuedCertificate(data.id, tableAPI.api)
        }
      },
    }),
    [actions, tableAPI.api]
  )

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
      <ManageHeader api={tableAPI.api} search={searchBar} />
      <TabularToolbar
        // We'll hide the counts if we're still loading the users
        countsTranslationKeys={
          issuedCertificatesByUser === undefined
            ? undefined
            : {
                totalKey: 'manage.certificates.n-certificates-issued',
                filterKey: 'manage.certificates.n-issued-filtered',
                selectedKey: 'manage.certificates.n-issued-selected',
              }
        }
        api={tableAPI.api}
        clearFilters={false}
        actions={
          <>
            <TabularToolbarDownloadButton
              onDownloadCSV={() =>
                exportTableData({
                  api: tableAPI.api,
                  dataLoader: tableAPI.dataLoader,
                  t,
                  fileType: 'csv',
                  includeNested: true,
                  withName: certificateTitle.replace(/ /g, '_'),
                })
              }
              onDownloadXLSX={() =>
                exportTableData({
                  api: tableAPI.api,
                  dataLoader: tableAPI.dataLoader,
                  t,
                  fileType: 'xlsx',
                  includeNested: true,
                  withName: certificateTitle.replace(/ /g, '_'),
                })
              }
            />
          </>
        }
      />
      <TableFallback api={tableAPI.api}>
        <BasicTabular />
      </TableFallback>
    </TabularProviderFromTableAPI>
  )
})
