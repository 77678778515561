import { queryOptions } from '@tanstack/react-query'
import { apiQueryOptions } from 'sierra-client/state/api'
import { XRealtimeContentListTags } from 'sierra-domain/routes'

export const learnerTagsQuery = apiQueryOptions(
  XRealtimeContentListTags,
  {},
  {
    staleTime: 5 * 1000, // 5 seconds
  }
)

const placeholderData = { rankedTags: [], availableContentCount: {} }

export const learnerTagListQuery = queryOptions({
  ...learnerTagsQuery,
  placeholderData,
  select: data => data.rankedTags,
})

export const learnerTagsRecordQuery = queryOptions({
  ...learnerTagsQuery,
  placeholderData,
  select: data => Object.fromEntries(data.rankedTags.map(tag => [tag.id, tag])),
})
