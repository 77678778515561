import {
  XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates,
  XRealtimeAdminLiveSessionsAssignUsers,
  XRealtimeAdminUsersSetContentDueDates,
  XRealtimeAdminUsersSetContentIsRequiredAssignments,
} from 'sierra-domain/routes'

import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useState } from 'react'
import { Message } from 'sierra-client/components/common/message'
import { useNotif } from 'sierra-client/components/common/notifications'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { getUserErrorTranslationKey } from 'sierra-client/utils/translation-utils'
import {
  AssignMultipleWithDueDatesRequest,
  DueDateAbsolute,
  DueDateGroup,
  DueDateSource,
  SetUserContentAssignment,
  SetUserContentIsRequiredAssignment,
  UserDueDates,
  UserDueDatesEffective,
} from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { isLeft } from 'sierra-domain/either'
import { UserErrorCode } from 'sierra-domain/error'
import { assertNever } from 'sierra-domain/utils'
import { Icon, InputDatePicker, Label, Modal, Tooltip } from 'sierra-ui/components'
import { Button, InputPrimitive, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RemoveDueDateAction = styled(Text)<{ $clickable: boolean }>`
  ${p => p.$clickable && `cursor: pointer`};
`
const DATE_FORMAT = 'yyyy-MM-dd'
const DEFAULT_ABSOLUTE_VALUE: DueDateAbsolute = {
  type: 'absolute',
  date: DateTime.now().plus({ days: 7 }).toFormat(DATE_FORMAT),
}

type DueDateFieldProps = {
  type: 'group' | 'user'
  value?: DueDateGroup | DueDateAbsolute
  onChange: (value: DueDateGroup | DueDateAbsolute | undefined) => void
  forceDisableRemove?: boolean
}

const DueDateField: React.FC<DueDateFieldProps> = ({ value, type, onChange, forceDisableRemove }) => {
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState<DueDateGroup | DueDateAbsolute>(DEFAULT_ABSOLUTE_VALUE)

  useEffect(() => {
    let newValue = value ?? DEFAULT_ABSOLUTE_VALUE
    if (newValue.type === 'relative' && type === 'user') {
      newValue = DEFAULT_ABSOLUTE_VALUE // force absolute date for user
    }
    setCurrentValue(newValue)
  }, [value, type])

  const disableRemove = forceDisableRemove ?? value === undefined

  return (
    <View direction='column' margin='small' gap='none'>
      {currentValue.type === 'absolute' && (
        <InputDatePicker
          value={DateTime.fromISO(currentValue.date)}
          disablePastDates
          onChange={newDate => {
            if (newDate === undefined) return
            const date = newDate.toISODate()

            setCurrentValue({ type: 'absolute', date })
          }}
        />
      )}
      {currentValue.type === 'relative' && (
        <InputPrimitive
          type='number'
          value={`${currentValue.days}`}
          onChange={e => {
            let days = +e.target.value
            if (Number.isNaN(days)) return
            if (days <= 0) days = 1
            setCurrentValue({ type: 'relative', days })
          }}
        />
      )}
      <Spacer size='xxsmall' />
      <Message>
        <Text color='foreground/muted' size='small' align='center'>
          {t('due-date.modal.user-absolute-message')}
        </Text>
      </Message>
      <Spacer size='small' />
      <View margin='none' direction='row' gap='none' justifyContent='center' alignItems='center'>
        <Button
          onClick={() => {
            if (currentValue.type === 'relative') return
            onChange(currentValue)
          }}
        >
          {t('dictionary.save')}
        </Button>
        <Spacer />
        <RemoveDueDateAction
          size='small'
          onClick={() => {
            if (disableRemove) return
            onChange(undefined)
          }}
          $clickable={!disableRemove}
          color={disableRemove ? 'grey35' : 'redBright'}
        >
          {t('due-date.modal.remove')}
        </RemoveDueDateAction>
      </View>
    </View>
  )
}

type DueDateDialogProps = DueDateFieldProps & {
  open: boolean
  onClose: () => void
}

export const DueDateDialog: React.FC<DueDateDialogProps> = ({ open, onClose, ...fieldProps }) => {
  // const { t } = useTranslation()
  return (
    <Modal open={open} onClose={onClose} size={{ width: 444 }}>
      <DueDateField {...fieldProps} />
    </Modal>
  )
}

export const getDueDateStringForGroup = ({
  t,
  dueDate,
}: {
  t: TranslationLookup
  dueDate: DueDateGroup
}): string => {
  if (dueDate.type === 'absolute') return dueDate.date

  return t(`dictionary.n-days`, { count: dueDate.days })
}

export const getDueDateSourceString = ({
  t,
  dueDateSource,
}: {
  t: TranslationLookup
  dueDateSource: DueDateSource
}): string => {
  switch (dueDateSource) {
    case 'direct':
      return t('due-date.source.direct')
    case 'program':
      return t('due-date.source.program')
    default:
      assertNever(dueDateSource)
  }
}

type DueDateTextProps = {
  dueDatesEffective: UserDueDatesEffective | undefined
}

const DueDateText: React.FC<DueDateTextProps> = ({ dueDatesEffective }) => {
  const { t } = useTranslation()
  return (
    <Text>
      {dueDatesEffective
        ? getDueDateStringForGroup({
            t,
            dueDate: { type: 'absolute', date: dueDatesEffective.effectiveDueDate },
          })
        : '-'}
    </Text>
  )
}

type DueDateCellProps = {
  dueDatesEffective: UserDueDatesEffective | undefined
  expectedSource: DueDateSource | undefined
}

export const DueDateCell: React.FC<DueDateCellProps> = ({ dueDatesEffective, expectedSource }) => {
  const { t } = useTranslation()
  return (
    <View>
      {dueDatesEffective &&
      expectedSource &&
      expectedSource in dueDatesEffective.dueDates &&
      dueDatesEffective.effectiveSource !== expectedSource ? (
        // Show tooltip if there is a due date for the expected source but the effective one comes from a different one
        <Tooltip title={getDueDateSourceString({ t, dueDateSource: dueDatesEffective.effectiveSource })}>
          <View>
            <Icon iconId='warning' />
            <DueDateText dueDatesEffective={dueDatesEffective} />
          </View>
        </Tooltip>
      ) : (
        <Text>
          <DueDateText dueDatesEffective={dueDatesEffective} />
        </Text>
      )}
    </View>
  )
}

type DueDateLabelProps = {
  dueDatesEffective: UserDueDatesEffective
}

export const DueDateLabel: React.FC<DueDateLabelProps> = ({ dueDatesEffective }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={getDueDateSourceString({ t, dueDateSource: dueDatesEffective.effectiveSource })}>
      <Label $size='small' $bgColor='grey30' $color='white'>
        <b>
          {t('manage.user.due-on-date-label', {
            date: DateTime.fromISO(dueDatesEffective.effectiveDueDate).toFormat('MMM dd yyyy'),
          })}
        </b>
      </Label>
    </Tooltip>
  )
}

export const getAbsoluteDueDate = (dueDate?: string): DueDateAbsolute | undefined => {
  return dueDate === undefined ? undefined : { type: 'absolute', date: dueDate }
}

export const calculateUserDueDateMetadata = (
  dueDates?: UserDueDates
): { dueDate?: DueDateAbsolute; origin?: 'user' | 'group' } => {
  const date = dueDates?.dueDateGroup ?? dueDates?.dueDateDirect
  const dueDate: DueDateAbsolute | undefined =
    date === undefined
      ? undefined
      : {
          type: 'absolute',
          date,
        }
  const origin: 'group' | 'user' | undefined =
    dueDates?.dueDateGroup !== undefined
      ? 'group'
      : dueDates?.dueDateDirect !== undefined
        ? 'user'
        : undefined

  return { dueDate, origin }
}

export type AssignableCourse = { id: string; type: 'course'; isRequired: boolean }
export type AssignablePath = { id: string; type: 'path'; isRequired: boolean }

type UseDueDateData = {
  setUsersDueDate: (userIds: UserId[], assignments: SetUserContentAssignment['assignments']) => Promise<void>
  setUsersIsRequiredContent: (
    userIds: UserId[],
    assignments: SetUserContentIsRequiredAssignment['assignments']
  ) => Promise<void>
  assignWithDueDate: (
    assignments: AssignMultipleWithDueDatesRequest['assignments']
  ) => Promise<undefined | { error: UserErrorCode }>
}
export const useDueDate = (): UseDueDateData => {
  const { postWithUserErrorException, postWithUserErrorCode } = usePost()
  const { t } = useTranslation()
  const notif = useNotif()

  const assignWithDueDate = useCallback<UseDueDateData['assignWithDueDate']>(
    async assignments => {
      const [liveSessionAssignments, restAssignments] = _.partition(
        assignments,
        it => it.content.type === 'live-session'
      )
      if (liveSessionAssignments.length > 0) {
        const result = await postWithUserErrorCode(
          XRealtimeAdminLiveSessionsAssignUsers,
          liveSessionAssignments
        )

        if (isLeft(result)) {
          notif.push({ type: 'error', body: t(getUserErrorTranslationKey(result.left)) })
          return { error: result.left }
        }
      }

      if (restAssignments.length > 0)
        await postWithUserErrorException(XRealtimeAdminAssignmentsSetContentAssignmentsWithDueDates, {
          assignments: restAssignments,
        })

      return undefined
    },
    [postWithUserErrorException, postWithUserErrorCode, notif, t]
  )

  const setUsersDueDate = useCallback<UseDueDateData['setUsersDueDate']>(
    async (userIds, assignments) => {
      await postWithUserErrorException(XRealtimeAdminUsersSetContentDueDates, {
        userIds: userIds,
        assignments,
      })
    },
    [postWithUserErrorException]
  )

  const setUsersIsRequiredContent = useCallback<UseDueDateData['setUsersIsRequiredContent']>(
    async (userIds, assignments) => {
      await postWithUserErrorException(XRealtimeAdminUsersSetContentIsRequiredAssignments, {
        userIds: userIds,
        assignments,
      })
    },
    [postWithUserErrorException]
  )

  return {
    setUsersDueDate,
    setUsersIsRequiredContent,
    assignWithDueDate,
  }
}

export const getSortGroupDueDate = (dueDate?: DueDateGroup): string => {
  if (dueDate === undefined) return ''
  if (dueDate.type === 'relative') return `${dueDate.days}`
  return dueDate.date
}
