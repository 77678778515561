import { forwardRef } from 'react'
import ReactSimpleOauth2Login from 'react-simple-oauth2-login'
import {
  OauthComponentProps,
  ReactSimpleOauth2LoginRef,
  getHost,
} from 'sierra-client/components/sana-now-integration-oauth/shared'
import { config, getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { logger } from 'sierra-client/logger/logger'

// NOTE: This is used for both Microsoft Teams and for Microsoft Outlook OAuth.
export const MicrosoftTeamsOauth = forwardRef<ReactSimpleOauth2LoginRef, OauthComponentProps>(
  ({ clientId, onSuccess, scope }, ref): JSX.Element => {
    const { t } = useTranslation()
    const redirectUrl = `https://${getHost()}/x-realtime/auth/user-microsoft-teams-oauth`
    const orgId = config.organization.uuid

    const rsvpEnabled = getFlag('rsvp')

    return (
      <ReactSimpleOauth2Login
        ref={ref}
        css={'display: none'}
        authorizationUrl='https://login.microsoftonline.com/common/oauth2/v2.0/authorize'
        responseType='code'
        clientId={clientId}
        extraParams={{ access_type: 'offline' }}
        redirectUri={redirectUrl}
        state={orgId}
        onFailure={(error: unknown) => {
          logger.warn('Microsoft Teams oauth error', { error })
        }}
        onSuccess={onSuccess}
        scope={rsvpEnabled ? scope.join(' ') : 'User.Read OnlineMeetings.ReadWrite offline_access'}
      >
        {t('dictionary.microsoft-teams')}
      </ReactSimpleOauth2Login>
    )
  }
)
