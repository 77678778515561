import React from 'react'
import { MeetLogo } from 'sierra-client/components/common/logos/meet-logo'
import { OutlookLogo } from 'sierra-client/components/common/logos/outlook-logo'
import { TeamsLogo } from 'sierra-client/components/common/logos/teams-logo'
import { ZoomLogo } from 'sierra-client/components/common/logos/zoom-logo'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CalendarIntegrationSetting, VideoCallSetting } from 'sierra-domain/content/session'
import { assertNever, iife } from 'sierra-domain/utils'
import { color } from 'sierra-ui/color'
import { TokenOrColor, resolveTokenOrColor } from 'sierra-ui/color/token-or-color'
import { Button, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Box = styled.div<{ backgroundColor: TokenOrColor }>`
  display: flex;
  flex-direction: column;
  background: ${p => resolveTokenOrColor(p.backgroundColor, p.theme)};
  padding: 16px;
  border-radius: 12px;
  gap: 16px;
  align-items: center;
  margin: 0px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 24px;
  }
`

type AuthenticationInfoProps = {
  onClick: () => void
}

const TextContent = styled(View)`
  max-width: 100%;
  margin: 0px 10px;
  max-height: 50px;
  @media (min-width: 768px) {
    max-width: 280px;
    margin: 0px 10px;
    max-height: 100%;
  }
`

const AuthenticationInfo: React.FC<
  {
    title: string
    description: string
    logo: JSX.Element
    backgroundColor: TokenOrColor
  } & AuthenticationInfoProps
> = ({ backgroundColor, title, description, logo, onClick }) => {
  const { t } = useTranslation()

  return (
    <Box backgroundColor={backgroundColor}>
      <View direction='row' alignItems='flex-start' gap='8'>
        {logo}
        <TextContent direction='column' gap='4'>
          <Text bold size='small'>
            {title}
          </Text>
          <Text color='foreground/secondary'>{description}</Text>
        </TextContent>
      </View>
      <Button variant='secondary' onClick={onClick}>
        {t('dictionary.allow')}
      </Button>
    </Box>
  )
}

export const CalendarIntegrationAuthentication: React.FC<
  {
    calendarIntegrationSetting: CalendarIntegrationSetting['type']
  } & AuthenticationInfoProps
> = ({ calendarIntegrationSetting, onClick }) => {
  const { t } = useTranslation()

  if (calendarIntegrationSetting === 'sana') {
    return null
  }

  const backgroundColor = iife(() => {
    switch (calendarIntegrationSetting) {
      case 'google':
        return color('#E8F0FE')
      case 'microsoft':
        return color('#E9EAF6')
      default:
        assertNever(calendarIntegrationSetting)
    }
  })

  const title = iife(() => {
    switch (calendarIntegrationSetting) {
      case 'google':
        return t('calendar-integrations.google.allow-access')
      case 'microsoft':
        return t('calendar-integrations.microsoft.allow-access')
      default:
        assertNever(calendarIntegrationSetting)
    }
  })
  const description = iife(() => {
    switch (calendarIntegrationSetting) {
      case 'google':
        return t('calendar-integrations.google.allow-info')
      case 'microsoft':
        return t('calendar-integrations.microsoft.allow-info')
      default:
        assertNever(calendarIntegrationSetting)
    }
  })

  const logo = iife(() => {
    switch (calendarIntegrationSetting) {
      case 'google':
        return <View>Google logo</View> /* TODO: Add Google logo */
      case 'microsoft':
        return <OutlookLogo />
      default:
        assertNever(calendarIntegrationSetting)
    }
  })

  return (
    <AuthenticationInfo
      backgroundColor={backgroundColor}
      title={title}
      description={description}
      logo={logo}
      onClick={onClick}
    />
  )
}

export const MeetingToolAuthentication: React.FC<
  { meetingTool: VideoCallSetting['type'] } & AuthenticationInfoProps
> = ({ meetingTool, onClick }) => {
  const { t } = useTranslation()

  if (meetingTool === 'sana' || meetingTool === 'url' || meetingTool === 'none') {
    return null
  }

  const backgroundColor = iife(() => {
    switch (meetingTool) {
      case 'google-meet':
        return color('#E8F0FE')
      case 'zoom':
        return color('#E5F5FF')
      case 'microsoft-teams':
        return color('#E9EAF6')
      default:
        assertNever(meetingTool)
    }
  })

  const title = iife(() => {
    switch (meetingTool) {
      case 'google-meet':
        return t('author.schedule-sana-now.access-google-meet')
      case 'zoom':
        return t('author.schedule-sana-now.access-zoom')
      case 'microsoft-teams':
        return t('author.schedule-sana-now.access-ms-teams')
      default:
        assertNever(meetingTool)
    }
  })

  const description = iife(() => {
    switch (meetingTool) {
      case 'google-meet':
        return t('author.schedule-sana-now.access-google-meet-description')
      case 'zoom':
        return t('author.schedule-sana-now.access-zoom-description')
      case 'microsoft-teams':
        return t('author.schedule-sana-now.access-ms-teams-description')
      default:
        assertNever(meetingTool)
    }
  })

  const logo = iife(() => {
    switch (meetingTool) {
      case 'google-meet':
        return <MeetLogo />
      case 'zoom':
        return <ZoomLogo />
      case 'microsoft-teams':
        return <TeamsLogo />
      default:
        assertNever(meetingTool)
    }
  })

  return (
    <AuthenticationInfo
      backgroundColor={backgroundColor}
      title={title}
      description={description}
      logo={logo}
      onClick={onClick}
    />
  )
}
