import { useQueryClient } from '@tanstack/react-query'
import { produce } from 'immer'
import { useCallback, useEffect, useState } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { Filter } from 'sierra-client/lib/filter'
import {
  UseGroupAdminsState,
  useGroupAdmins,
} from 'sierra-client/views/manage/components/hooks/use-group-admins'
import { queryKeyTabularUserGroupMembers } from 'sierra-client/views/manage/user-groups/query-keys'
import { PotentialGroupAdmin, UnassignGroupRequest, UserGroupDetailResponse } from 'sierra-domain/api/manage'
import {
  XRealtimeAdminAssignmentsUnassignFromGroup,
  XRealtimeAdminGroupsDeleteGroups,
  XRealtimeAdminGroupsUpdateGroup,
  XRealtimeAdminGroupsUserGroupDetail,
} from 'sierra-domain/routes'

type UserGroupType = UserGroupDetailResponse['type']

const groupTypeEditability: Record<UserGroupType, boolean> = {
  'user-manual': true,
  'user-filter': true,
  'user-scim': false,
  'user-api': false,
}

export const isModifiableGroupType = (groupType: UserGroupType): boolean => groupTypeEditability[groupType]

export type UseUserGroupDetailsData = {
  isLoading: boolean
  group?: UserGroupDetailResponse
  unassignFromGroup: (
    groupId: string,
    unassigns: Partial<Omit<UnassignGroupRequest, 'groupId'>>
  ) => Promise<void>
  fetchGroup: (id: string) => Promise<void>
  deleteGroup: (id: string) => Promise<void>
  updateGroupDetails: (
    id: string,
    name: string,
    description: string,
    filter: Filter | undefined,
    invalidFilter: boolean
  ) => Promise<void>
  groupAdminState: UseGroupAdminsState
}

export const useUserGroupDetails = (groupId: string): UseUserGroupDetailsData => {
  const queryClient = useQueryClient()
  const { postWithUserErrorException } = usePost()

  const [group, setGroup] = useState<UserGroupDetailResponse | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const updateGroupAdmins = useCallback((newAdmins: PotentialGroupAdmin[]) => {
    setGroup(curr => (curr !== undefined ? { ...curr, admins: newAdmins } : undefined))
  }, [])

  const groupAdminState = useGroupAdmins({
    groupId,
    currentAdmins: group?.admins,
    updateGroupAdmins,
  })

  const fetchGroup = useCallback(
    async (groupId: string): Promise<void> => {
      const response = await postWithUserErrorException(XRealtimeAdminGroupsUserGroupDetail, {
        groupId,
      })
      setGroup(response)
    },
    [postWithUserErrorException]
  )

  const deleteGroup = useCallback(
    async (groupId: string): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminGroupsDeleteGroups, { groupIds: [groupId] })
    },
    [postWithUserErrorException]
  )

  const updateGroupDetails = useCallback<UseUserGroupDetailsData['updateGroupDetails']>(
    async (groupId, name, description, filter, invalidFilter) => {
      if (!name) return

      await postWithUserErrorException(XRealtimeAdminGroupsUpdateGroup, {
        groupId,
        name,
        description,
        filter,
      })
      setGroup(
        produce(g => {
          if (g === undefined) return

          g.name = name
          g.description = description

          if (g.type === 'user-filter' && filter !== undefined) {
            g.filter = filter
          }
        })
      )
      // If filter changed, re-fetch members
      if (filter !== undefined) {
        if (invalidFilter) {
          await fetchGroup(groupId)
        }
        await queryClient.invalidateQueries({ queryKey: queryKeyTabularUserGroupMembers(groupId) })
      }
    },
    [postWithUserErrorException, queryClient, fetchGroup]
  )

  const unassignFromGroup = useCallback<UseUserGroupDetailsData['unassignFromGroup']>(
    async (groupId, { userIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromGroup, {
        groupId,
        userIds,
      })

      await fetchGroup(groupId)
      if (userIds) {
        await queryClient.invalidateQueries({ queryKey: queryKeyTabularUserGroupMembers(groupId) })
      }
    },
    [postWithUserErrorException, fetchGroup, queryClient]
  )

  useEffect(() => {
    void (async () => {
      //Cleanup
      setGroup(undefined)
      setIsLoading(true)
      await fetchGroup(groupId)
      setIsLoading(false)
    })()
  }, [fetchGroup, groupId, postWithUserErrorException])

  return {
    isLoading,
    group,
    unassignFromGroup,
    deleteGroup,
    updateGroupDetails,
    fetchGroup,
    groupAdminState,
  }
}
