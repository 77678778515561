import { motion } from 'framer-motion'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { ContentAttribute } from 'sierra-client/views/manage/content-attributes/types'
import { hasKey } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Pill, Spacer, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

const Card = styled(View)`
  outline: 1px solid ${token('border/default')};
  padding: 24px 28px;
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px #00000014;

  width: 381px;
  height: 250px;
  flex-direction: column;

  > * {
    width: 100%;
  }
`

const ScaleOnHover = styled(motion.div)`
  -webkit-font-smoothing: antialiased;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  user-select: none;
`

const AttributePill = styled(Pill)`
  border-radius: 8px;
`

export const ContentAttributeCard: React.FC<{
  attribute: ContentAttribute
  onClick: (_: ContentAttribute) => void
}> = ({ attribute, onClick }) => {
  const { t } = useTranslation()
  return (
    <ScaleOnHover
      initial={{ scale: 1 }}
      exit={{ scale: 1 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 1.01 }}
      onClick={() => onClick(attribute)}
    >
      <Card justifyContent='space-between'>
        <View direction='column' gap='none'>
          <Text size='large' bold>
            {attribute.name}
          </Text>
          <Spacer size='2' />
          <Text size='small' bold color='foreground/muted'>
            {t(`manage.content-attributes.types.${attribute.type}`)}
          </Text>
          <Spacer size='12' />
          <Text size='small' color='foreground/secondary'>
            {attribute.description}
          </Text>
        </View>
        {hasKey(attribute, 'options') && (
          <View>
            {attribute.options.map(option => (
              <AttributePill key={option.title}>
                <Text size='micro' bold>
                  {option.title}
                </Text>
              </AttributePill>
            ))}
          </View>
        )}
      </Card>
    </ScaleOnHover>
  )
}

export const CreateAttributeCard: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation()
  return (
    <ScaleOnHover
      initial={{ scale: 1 }}
      exit={{ scale: 1 }}
      transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 1.01 }}
      onClick={onClick}
    >
      <Card alignItems='center' justifyContent='center'>
        <View direction='column' justifyContent='center' alignItems='center' grow>
          <Icon iconId='plus--circle' size='size-16' color='foreground/muted' />
          <Text bold color='foreground/muted' size='small'>
            {t('manage.content-attributes.cards.new.text')}
          </Text>
        </View>
      </Card>
    </ScaleOnHover>
  )
}
