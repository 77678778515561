import { useState } from 'react'
import { MessageType } from 'sierra-client/views/v3-author/scenario/chat/state'
import { ScenarioMessage } from 'sierra-domain/routes'
import { isNonEmptyString } from 'sierra-domain/utils'
import { Button, Text, TextAreaPrimitive, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import { z } from 'zod'

function copyToClipboard(text: string): void {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      window.alert('Conversation copied to clipboard')
    })
    .catch(err => {
      window.alert('Failed exporting of conversation: ' + err)
    })
}

export const DebugMenu: React.FC<{
  messageHistory: Array<MessageType>
  onImport: (_: Array<MessageType>) => void
}> = ({ messageHistory, onImport }) => {
  const [value, setImportValue] = useState('')
  return (
    <IconMenu
      variant='transparent'
      onSelect={item => {
        if (item.id === 'export') {
          const messages = JSON.stringify(messageHistory)
          copyToClipboard(messages)
        }
      }}
      iconId='settings'
      menuItems={[
        {
          id: 'export',
          label: 'Export conversation to clipboard',
          type: 'label',
        },
        {
          id: 'import',
          label: 'Import conversation',
          type: 'canvas',
          preventClose: true,
          render: () => (
            <View direction='column' grow>
              <Text>Import conversation</Text>
              <TextAreaPrimitive
                value={value}
                onChange={e => {
                  setImportValue(e.target.value)
                }}
                placeholder='Paste message history here'
                rows={10}
              />
              <Button
                onClick={() => {
                  if (isNonEmptyString(value)) {
                    try {
                      onImport(
                        z.array(ScenarioMessage.extend({ index: z.number() })).parse(JSON.parse(value))
                      )
                      window.alert('Importing successful')
                    } catch (e) {
                      window.alert(`Failed importing conversation`)
                    }
                    setImportValue('')
                  }
                }}
              >
                Import
              </Button>
            </View>
          ),
        },
      ]}
    />
  )
}
