import { atom, useAtomValue } from 'jotai'
import { selectAtom } from 'jotai/utils'
import * as React from 'react'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { TableAPI } from 'sierra-client/lib/tabular/api'
import {
  TabularToolbar,
  TabularToolbarButton,
  TabularToolbarDownloadButton,
} from 'sierra-client/lib/tabular/components/tabular-toolbar'
import { DataLoaderStateMachine } from 'sierra-client/lib/tabular/control/dataloader'
import { ProgramsTableData, programsDataLoader } from 'sierra-client/lib/tabular/dataloader/programs'
import { Cell } from 'sierra-client/lib/tabular/datatype/internal/cell'
import { Header } from 'sierra-client/lib/tabular/datatype/internal/header'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TableCallbacks, TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, temporaryComputeSelectedRows } from 'sierra-client/lib/tabular/utils'
import { selectedAtom } from 'sierra-client/lib/tabular/utils/atom-helpers'
import { VirtualColumns } from 'sierra-client/lib/tabular/virtual-columns'
import { getGlobalRouter } from 'sierra-client/router'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import { ManageHeader, ManageHeaderSearchConfig } from 'sierra-client/views/manage/components/manage-header'
import { PageHeader } from 'sierra-client/views/settings/components/page-header'
import { MenuItem } from 'sierra-ui/components'
import { Button } from 'sierra-ui/primitives'

export type ProgramActions = {
  onNewProgram: () => void
  onViewDetails: (id: string) => void
  onAddUsers: (ids: string[]) => void
  onDuplicateProgram: (id: string) => void
  onDelete: (id: string) => void
}

const createProgramsVirtualColumns = (
  actions: ProgramActions,
  hasFullProgramAccess: boolean,
  canEditAssignments: boolean,
  canDuplicateProgram: boolean,
  t: TranslationLookup
): VirtualColumns => ({
  left: [
    {
      ref: 'selection',
      header: api =>
        ({
          type: 'select',
          ref: 'selection',
          enabled: atom(() => true),
          sortable: atom(() => false),
          selected: selectAtom(api.atoms.selection, s => s.type === 'all'),
          hints: ['sticky', 'sticky-left'],
        }) satisfies Header,
      cell: ({ pos, api }) =>
        ({
          type: 'select',
          pos,
          hints: ['sticky', 'sticky-left'],
          enabled: atom(() => true),
          selected: selectedAtom(pos, api.atoms.selection),
        }) satisfies Cell,
    },
  ],
  right: [
    defaultMenuActionVirtualColumn({
      getProps: ({ pos }) => {
        const menuItems: Array<MenuItem> = [
          {
            id: pos.row + 'label1',
            type: 'label' as const,
            label: t('manage.view-details'),
            onClick: () => actions.onViewDetails(pos.row),
          },
        ]
        if (canEditAssignments) {
          menuItems.push({
            id: pos.row + 'label2',
            type: 'label' as const,
            label: t('admin.add-users'),
            onClick: () => actions.onAddUsers([pos.row]),
          })
        }

        if (canDuplicateProgram) {
          menuItems.push({
            id: pos.row + 'label3',
            type: 'label' as const,
            label: t('admin.organization.learners.duplicate-program'),
            tooltip: t('admin.organization.learners.duplicate-program-message'),
            onClick: () => actions.onDuplicateProgram(pos.row),
          })
        }

        if (hasFullProgramAccess) {
          menuItems.push({
            id: pos.row + 'label4',
            type: 'label' as const,
            label: t('admin.delete'),
            color: 'destructive/background',
            onClick: () => actions.onDelete(pos.row),
          })
        }

        return {
          menuItems: menuItems,
        }
      },
    }),
  ],
})

const BulkAddUsers: React.FC<{
  actions: ProgramActions
  api: TableAPI
}> = ({ actions, api }) => {
  const sel = useAtomValue(api.atoms.selection)
  const tableData = useAtomValue(api.atoms.tableData)
  const { t } = useTranslation()
  if (sel.type === 'none') return <></>
  return (
    <TabularToolbarButton
      onClick={() =>
        // These actions should be dispatched to the backend with the selection itself
        // This workaround is temporary
        actions.onAddUsers(temporaryComputeSelectedRows(tableData, sel))
      }
      icon={'user--add'}
    >
      {t('admin.add-users')}
    </TabularToolbarButton>
  )
}

export const useProgramsTableAPI = (actions: ProgramActions): UseTableAPI<ProgramsTableData, any> => {
  const hasFullProgramAccess = useHasOrganizationPermission('ACCESS_ALL_PROGRAMS')
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  const canDuplicateProgram = useHasOrganizationPermission('DUPLICATE_PROGRAM')
  const { t } = useTranslation()
  const programsVirtualColumns = React.useMemo(
    () =>
      createProgramsVirtualColumns(actions, hasFullProgramAccess, canEditAssignments, canDuplicateProgram, t),
    [actions, hasFullProgramAccess, canEditAssignments, canDuplicateProgram, t]
  )

  return useTableAPI({
    dataLoader: React.useMemo(
      () => ({ loader: programsDataLoader(), options: { queryKey: ['graphql', 'programs-tabular'] } }),
      []
    ),
    virtualColumns: programsVirtualColumns,
    options: {
      limit: 30,
      sorting: {
        initialSorting: React.useMemo(() => [{ column: 'CREATED_AT', direction: 'descending' }], []),
      },
    },
  })
}

const ProgramsToolbar: React.FC<{
  tableAPI: UseTableAPI<ProgramsTableData, null>
  actions: ProgramActions
  dataLoader: DataLoaderStateMachine<ProgramsTableData, null>
}> = ({ tableAPI, actions, dataLoader }) => {
  const { t } = useTranslation()
  const { api } = tableAPI
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')

  return (
    <TabularToolbar
      api={api}
      clearFilters={false}
      countsTranslationKeys={{
        totalKey: 'manage.programs.n-programs',
        filterKey: 'manage.programs.n-filtered',
        selectedKey: 'manage.tables.n-selected',
      }}
      actions={
        <>
          <TabularToolbarDownloadButton
            onDownloadCSV={() => exportTableData({ api, dataLoader, t, fileType: 'csv' })}
            onDownloadXLSX={() => exportTableData({ api, dataLoader, t, fileType: 'xlsx' })}
          />
          {canEditAssignments && <BulkAddUsers actions={actions} api={api} />}
        </>
      }
    />
  )
}

const CreateProgramButton: React.FC<{ actions: ProgramActions }> = ({ actions }) => {
  const { t } = useTranslation()

  const canCreateProgram = useHasOrganizationPermission('CREATE_PROGRAM')
  if (!canCreateProgram) return null

  return (
    <Button id='tour-programs-newprogram' variant='secondary' onClick={actions.onNewProgram}>
      {t('manage.programs.new-program')}
    </Button>
  )
}

export const ProgramsTable: React.FC<{
  actions: ProgramActions
  tableAPI: UseTableAPI<ProgramsTableData, null>
}> = ({ actions, tableAPI }) => {
  const memoizedCallbacks = React.useMemo<TableCallbacks<ProgramsTableData>>(
    () => ({ onRow: row => getGlobalRouter().navigate({ to: `/manage/programs/${row.ref}` }) }),
    []
  )

  const searchBar: ManageHeaderSearchConfig = { placeholder: 'manage.search.programs' }

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI} callbacks={memoizedCallbacks}>
      <ColumnLayout>
        <PageHeader
          title='admin.organization.tab.programs'
          withoutMargin
          helpLink='https://help.sana.ai/en/collections/133365-programs'
        />

        <ManageHeader
          api={tableAPI.api}
          search={searchBar}
          actionElement={<CreateProgramButton actions={actions} />}
        />
        <ProgramsToolbar tableAPI={tableAPI} actions={actions} dataLoader={tableAPI.dataLoader} />
        <BasicTabular />
      </ColumnLayout>
    </TabularProviderFromTableAPI>
  )
}
