import { useMutation, UseMutationResult } from '@tanstack/react-query'
import _ from 'lodash'
import { useCallback } from 'react'
import {
  NonExpiringNotif,
  Notif,
  useNonExpiringNotif,
  useNotif,
} from 'sierra-client/components/common/notifications'
import { cardAdded } from 'sierra-client/core/logging/authoring/logger'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import { typedPost } from 'sierra-client/state/api'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import { selectNodeParentFolderId } from 'sierra-client/state/flexible-content/selectors'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { AppDispatch } from 'sierra-client/state/store'
import { selectUserId } from 'sierra-client/state/user/user-selector'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { CreateContentId, LiveContentId, NanoId12 } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { copyFile, replaceIdsInFileData } from 'sierra-domain/editor/operations/copy-file'
import { copyFolder } from 'sierra-domain/editor/operations/copy-folder'
import { apply } from 'sierra-domain/editor/operations/operation'
import {
  AddFile,
  AddFolder,
  CopyFile,
  CopyFolder,
  CreateOperation,
  CreateOperationState,
} from 'sierra-domain/editor/operations/types'
import { getFolder, safeGetFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId, FolderId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { fileIsSupportedInContent } from 'sierra-domain/flexible-content/support'
import { FileType, generateFileId, generateFolderId } from 'sierra-domain/flexible-content/types'
import {
  XRealtimeAuthorGetExternalNotepads,
  XRealtimeImportAssetsFromZip,
  XRealtimePrepareExportAssetsAsZip,
} from 'sierra-domain/routes'
import { assertIsNonNullable, assertWith, guardWith, isNonNullable } from 'sierra-domain/utils'
import { SlateDocument } from 'sierra-domain/v3-author'

export function isCopyPasteSupported(): boolean {
  return (
    isNonNullable(navigator) &&
    isNonNullable(navigator.clipboard) &&
    typeof navigator.clipboard.writeText === 'function' &&
    typeof navigator.clipboard.readText === 'function'
  )
}

const canNotBePastedInSelfPaced = (cardType: FileType): boolean => {
  return !fileIsSupportedInContent(cardType, 'self-paced')
}

const canNotBePastedInLive = (cardType: FileType): boolean => {
  return !fileIsSupportedInContent(cardType, 'live')
}

export function parseSanaCardFragment(jsonString: string): CopyFile | undefined {
  try {
    const rawJson = JSON.parse(jsonString)
    return CopyFile.parse(rawJson)
  } catch {
    return undefined
  }
}

export const isSanaCardFragment = (jsonString: string): boolean => {
  return parseSanaCardFragment(jsonString) !== undefined
}

const CopyFileArray = CopyFile.array()

export function parseArrayOfSanaCardFragment(jsonString: string): CopyFile[] | undefined {
  try {
    const rawJson = JSON.parse(jsonString)
    return CopyFileArray.parse(rawJson)
  } catch {
    return undefined
  }
}

export const isArrayOfSanaCardFragment = (clipboard: string): boolean => {
  return parseArrayOfSanaCardFragment(clipboard) !== undefined
}

export function parseSanaModuleFragment(jsonString: string): CopyFolder | undefined {
  try {
    const rawJson = JSON.parse(jsonString)
    return CopyFolder.parse(rawJson)
  } catch {
    return undefined
  }
}

export const isSanaModuleFragment = (jsonString: string): boolean => {
  return parseSanaModuleFragment(jsonString) !== undefined
}

async function getExternalNotepads(
  operationState: CreateOperationState,
  contentId: CreateContentId,
  fileIds: FileId[],
  notif: Notif
): Promise<Record<FileId, SlateDocument>> {
  const relevantFileIds = fileIds.filter(
    fileId => safeGetFile(operationState.yDoc, fileId)?.data.type === 'external-notepad'
  )

  if (relevantFileIds.length === 0) {
    return {}
  }

  try {
    const { slateDocuments } = await typedPost(XRealtimeAuthorGetExternalNotepads, {
      fileIds: relevantFileIds,
      liveContentId: LiveContentId.parse(contentId),
    })

    const record: Record<FileId, SlateDocument> = {}
    for (const [fileId, slateDocument] of Object.entries(slateDocuments)) {
      assertWith(FileId, fileId)
      record[fileId] = slateDocument.nodes
    }

    return record
  } catch (e) {
    notif.push({ type: 'error' })
    throw e
  }
}

async function getSignedAssetsUrl(
  courseId: CreateContentId,
  fileIds: FileId[],
  notif: Notif
): Promise<string> {
  const strippedFileIds = fileIds.map(fileId => NanoId12.parse(fileId.replace('file:', '')))

  try {
    const { signedUrl } = await typedPost(XRealtimePrepareExportAssetsAsZip, {
      courseId,
      fileIds: strippedFileIds,
    })
    return signedUrl
  } catch (e) {
    notif.push({ type: 'error' })
    throw e
  }
}

async function pasteAssetsWithSignedURL(
  courseId: CreateContentId,
  signedUrl: string,
  notif: Notif
): Promise<void> {
  try {
    await typedPost(XRealtimeImportAssetsFromZip, {
      courseId,
      signedUrl,
    })
  } catch (e) {
    notif.push({ type: 'error' })
    throw e
  }
}

export function useCopyCard({
  operationState,
  contentId,
  onSuccess,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
  onSuccess?: (copiedCard: CopyFile) => void
}): UseMutationResult<CopyFile, Error, `file:${string}`, unknown> {
  const notif = useNotif()

  const mutation = useMutation({
    mutationFn: async (fileId: FileId): Promise<CopyFile> => {
      const [externalNotepads, copyAssetsSignedUrl] = await Promise.all([
        getExternalNotepads(operationState, contentId, [fileId], notif),
        getSignedAssetsUrl(contentId, [fileId], notif),
      ])

      const copiedCard = copyFile({
        state: operationState,
        externalNotepads,
        fileId,
      })

      return { ...copiedCard, contentId, copyAssetsSignedUrl }
    },
    onSuccess,
  })

  if (mutation.error) throw mutation.error
  return mutation
}

type SourceCardInfo = { fileId: FileId; parentFolderId: FolderId }
type BulkCopiedCard = { copyFile: CopyFile; sourceCardInfo: SourceCardInfo }

export function useBulkCopyCards({
  operationState,
  contentId,
  onSuccess,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
  onSuccess?: (copiedCards: BulkCopiedCard[]) => void
}): UseMutationResult<BulkCopiedCard[], Error, SourceCardInfo[], unknown> {
  const notif = useNotif()

  const mutation = useMutation({
    mutationFn: async (sourceFiles: SourceCardInfo[]): Promise<BulkCopiedCard[]> => {
      const [externalNotepads, copyAssetsSignedUrl] = await Promise.all([
        await getExternalNotepads(
          operationState,
          contentId,
          sourceFiles.map(it => it.fileId),
          notif
        ),
        await getSignedAssetsUrl(
          contentId,
          sourceFiles.map(it => it.fileId),
          notif
        ),
      ])

      const copiedCards: BulkCopiedCard[] = sourceFiles.map(({ fileId, parentFolderId }) => {
        const copiedCard = copyFile({
          state: operationState,
          externalNotepads,
          fileId,
        })
        return {
          copyFile: { ...copiedCard, contentId, copyAssetsSignedUrl },
          sourceCardInfo: { fileId, parentFolderId },
        }
      })

      return copiedCards
    },
    onSuccess,
  })

  if (mutation.error) throw mutation.error
  return mutation
}

export function useCopyCardToClipboard({
  operationState,
  contentId,
  onSuccess,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
  onSuccess?: (_: CopyFile) => void
}): UseMutationResult<CopyFile, Error, FileId, unknown> {
  return useCopyCard({
    operationState,
    contentId,
    onSuccess: copiedCard => {
      void navigator.clipboard.writeText(JSON.stringify(copiedCard))
      onSuccess?.(copiedCard)
    },
  })
}

export function useBulkCopyCardToClipboard({
  operationState,
  contentId,
  onSuccess,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
  onSuccess?: (_: BulkCopiedCard[]) => void
}): UseMutationResult<BulkCopiedCard[], Error, SourceCardInfo[], unknown> {
  return useBulkCopyCards({
    operationState,
    contentId,
    onSuccess: bulkCopiedCards => {
      const copiedCards = bulkCopiedCards.map(({ copyFile }) => copyFile)
      void navigator.clipboard.writeText(JSON.stringify(copiedCards))
      onSuccess?.(bulkCopiedCards)
    },
  })
}

export function useCopyModule({
  operationState,
  contentId,
  onSuccess,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
  onSuccess?: (copiedFolder: CopyFolder) => void
}): UseMutationResult<CopyFolder, Error, FolderId, unknown> {
  const notif = useNotif()

  const mutation = useMutation({
    mutationFn: async (folderId: FolderId): Promise<CopyFolder> => {
      const folder = getFolder(operationState.yDoc, folderId)
      const fileIds = folder.nodeIds.filter(guardWith(FileId))

      const [externalNotepads, copyAssetsSignedUrl] = await Promise.all([
        getExternalNotepads(operationState, contentId, fileIds, notif),
        getSignedAssetsUrl(contentId, fileIds, notif),
      ])

      return copyFolder({
        state: operationState,
        externalNotepads,
        folderId,
        contentId,
        copyAssetsSignedUrl,
      })
    },
    onSuccess,
  })

  if (mutation.error) throw mutation.error

  return mutation
}

export function useCopyModuleToClipboard({
  operationState,
  contentId,
}: {
  operationState: CreateOperationState
  contentId: CreateContentId
}): UseMutationResult<CopyFolder, Error, FolderId, unknown> {
  return useCopyModule({
    operationState,
    contentId,
    onSuccess: copiedFolder => {
      void navigator.clipboard.writeText(JSON.stringify(copiedFolder))
    },
  })
}

const pasteFile = async (
  operationState: CreateOperationState,
  userId: UserId,
  isSelfpacedContent: boolean,
  scopedCreateContentId: ScopedCreateContentId,
  createContentId: CreateContentId,
  card: string,
  notif: Notif,
  nonExpiringNotif: NonExpiringNotif,
  t: TranslationLookup,
  dispatch: AppDispatch,
  folderId: FolderId | undefined,
  nextTo: NodeId
): Promise<void> => {
  const copyFile = parseSanaCardFragment(card)
  if (copyFile === undefined) return

  const slateDocument = copyFile.slateDocument

  // Omit narrations if pasting in live content
  const filteredFile = !isSelfpacedContent ? _.omit(copyFile.file, 'narration') : copyFile.file
  const cardType = filteredFile.data.type

  if (!isSelfpacedContent && canNotBePastedInLive(cardType)) {
    notif.push({
      type: 'custom',
      level: 'info',
      icon: 'close--circle',
      title: t('content.copy-paste-card.not-allowed-live'),
      body: '',
    })
    return
  }
  if (isSelfpacedContent && canNotBePastedInSelfPaced(cardType)) {
    notif.push({
      type: 'custom',
      level: 'info',
      icon: 'play--circle--filled',
      title: t('content.copy-paste-card.not-allowed-course'),
      body: '',
    })
    return
  }

  let removeNotificationOnComplete: string | undefined = undefined

  // If copied from a different course, paste assets as well
  if (copyFile.contentId !== createContentId) {
    removeNotificationOnComplete = nonExpiringNotif.push({
      type: 'progress',
      progressDuration: { type: 'fake', duration: 5000 },
      level: 'info',
      title: t('content.copy-paste.copying'),
      body: '',
    })

    await pasteAssetsWithSignedURL(createContentId, copyFile.copyAssetsSignedUrl, notif)
  }

  const addFile: AddFile = {
    type: 'add-file',
    file: {
      ...filteredFile,
      data: replaceIdsInFileData(filteredFile.data),
      metadata: {
        createdFromAction: { type: 'paste' },
        createdAt: new Date().toISOString(),
        createdBy: userId,
      },
      id: generateFileId(),
    },
    folderId: folderId,
    destination: { type: 'next-to', nodeId: nextTo },
    slateDocument:
      slateDocument !== undefined ? (replaceIdsInNodes(slateDocument) as SlateDocument) : undefined,
  }

  apply(operationState, addFile)
  void navigateToCreateContentId({ scopedCreateContentId, nodeId: addFile.file.id })

  void dispatch(
    cardAdded({
      contentId: createContentId,
      contentType: isSelfpacedContent ? 'self-paced' : 'live',
      createdByAction: 'pasted',
      cardType: addFile.file.data.type,
    })
  )

  const element = document.getElementById(addFile.file.id)
  element?.focus()

  if (removeNotificationOnComplete !== undefined) {
    nonExpiringNotif.remove(removeNotificationOnComplete)

    // Show a notification that the copy is complete
    notif.push({
      type: 'custom',
      level: 'success',
      icon: 'checkmark--outline',
      title: t('content.copy-paste.copied'),
      body: '',
    })
  }
}

const pasteMultipleFiles = async ({
  operationState,
  userId,
  isSelfpacedContent,
  createContentId,
  cards,
  notif,
  nonExpiringNotif,
  t,
  folderId,
  nextTo,
}: {
  operationState: CreateOperationState
  userId: UserId
  isSelfpacedContent: boolean
  createContentId: CreateContentId
  cards: string
  notif: Notif
  nonExpiringNotif: NonExpiringNotif
  t: TranslationLookup
  folderId: FolderId | undefined
  nextTo: NodeId
}): Promise<void> => {
  const copyFiles = parseArrayOfSanaCardFragment(cards)

  if (copyFiles === undefined) {
    return
  }

  const removeNotificationOnComplete: string[] = []

  const operations: (CreateOperation | undefined)[] = copyFiles.map(
    (copyFile): CreateOperation | undefined => {
      const slateDocument = copyFile.slateDocument

      // Omit narrations if pasting in live content
      const filteredFile = !isSelfpacedContent ? _.omit(copyFile.file, 'narration') : copyFile.file
      const cardType = filteredFile.data.type

      if (!isSelfpacedContent && canNotBePastedInLive(cardType)) {
        notif.push({
          type: 'custom',
          level: 'info',
          icon: 'close--circle',
          title: t('content.copy-paste-card.not-allowed-live'),
          body: '',
        })
        return
      }
      if (isSelfpacedContent && canNotBePastedInSelfPaced(cardType)) {
        notif.push({
          type: 'custom',
          level: 'info',
          icon: 'play--circle--filled',
          title: t('content.copy-paste-card.not-allowed-course'),
          body: '',
        })
        return
      }

      const addFile: AddFile = {
        type: 'add-file',
        file: {
          ...filteredFile,
          data: replaceIdsInFileData(filteredFile.data),
          metadata: {
            createdFromAction: { type: 'paste' },
            createdAt: new Date().toISOString(),
            createdBy: userId,
          },
          id: generateFileId(),
        },
        folderId: folderId,
        destination: { type: 'next-to', nodeId: nextTo },
        slateDocument:
          slateDocument !== undefined ? (replaceIdsInNodes(slateDocument) as SlateDocument) : undefined,
      }
      return addFile
    }
  )

  // If copied from a different course, paste assets as well
  const potentialSignedUrl = copyFiles.find(card => card.contentId !== createContentId)?.copyAssetsSignedUrl
  if (potentialSignedUrl !== undefined) {
    removeNotificationOnComplete.push(
      nonExpiringNotif.push({
        type: 'progress',
        progressDuration: { type: 'fake', duration: 5000 },
        level: 'info',
        title: t('content.copy-paste.copying'),
        body: '',
      })
    )

    await pasteAssetsWithSignedURL(createContentId, potentialSignedUrl, notif)
  }

  const definedOperations = operations.filter(isNonNullable)
  apply(operationState, ...definedOperations)

  removeNotificationOnComplete.forEach(notification => nonExpiringNotif.remove(notification))

  // Show a notification that the copy is complete
  notif.push({
    type: 'custom',
    level: 'success',
    icon: 'checkmark--outline',
    title: t('content.copy-paste.copied'),
    body: '',
  })
}

export async function pasteFolder({
  operationState,
  userId,
  nextTo,
  isSelfpacedContent,
  copyFolder,
  createContentId,
  notif,
  nonExpiringNotif,
  t,
}: {
  operationState: CreateOperationState
  userId: UserId
  nextTo: NodeId
  isSelfpacedContent: boolean
  copyFolder: CopyFolder
  createContentId: CreateContentId
  notif: Notif
  nonExpiringNotif: NonExpiringNotif
  t: TranslationLookup
}): Promise<void> {
  const nodes = copyFolder.nodes as CopyFile[]

  let cards = nodes
  nodes.forEach(node => {
    if (!isSelfpacedContent && canNotBePastedInLive(node.file.data.type)) {
      cards = cards.filter(file => file.file.data.type !== node.file.data.type)
      notif.push({
        type: 'custom',
        level: 'info',
        icon: 'close--circle',
        title: t('content.copy-paste-module.not-allowed-live'),
        body: '',
      })
    }
    if (isSelfpacedContent && canNotBePastedInSelfPaced(node.file.data.type)) {
      cards = cards.filter(file => file.file.data.type !== node.file.data.type)
      notif.push({
        type: 'custom',
        level: 'info',
        icon: 'play--circle--filled',
        title: t('content.copy-paste-module.not-allowed-course'),
        body: '',
      })
    }
  })

  const addFolder: AddFolder = {
    type: 'add-folder',
    folder: {
      ...copyFolder.folder,
      nodeIds: [],
      id: generateFolderId(),
    },
    destination: {
      type: 'next-to',
      nodeId: nextTo,
    },
  }

  let removeNotificationOnComplete: string | undefined = undefined

  // If copied from a different course, paste assets as well
  if (copyFolder.contentId !== createContentId) {
    removeNotificationOnComplete = nonExpiringNotif.push({
      type: 'progress',
      progressDuration: { type: 'fake', duration: 5000 },
      level: 'info',
      title: t('content.copy-paste.copying'),
      body: '',
    })

    await pasteAssetsWithSignedURL(createContentId, copyFolder.copyAssetsSignedUrl, notif)
  }

  const addFiles = cards.map(node => {
    const addFile: AddFile = {
      type: 'add-file',
      file: {
        ...node.file,
        id: generateFileId(),
        metadata: {
          createdFromAction: { type: 'paste' },
          createdAt: new Date().toISOString(),
          createdBy: userId,
        },
      },
      destination: { type: 'add-at-end' },
      folderId: addFolder.folder.id,
      slateDocument: node.slateDocument
        ? SlateDocument.parse(replaceIdsInNodes(node.slateDocument))
        : undefined,
    }
    return addFile
  })

  apply(operationState, addFolder, ...addFiles)

  if (removeNotificationOnComplete !== undefined) {
    nonExpiringNotif.remove(removeNotificationOnComplete)

    // Show a notification that the copy is complete
    notif.push({
      type: 'custom',
      level: 'success',
      icon: 'checkmark--outline',
      title: t('content.copy-paste.copied'),
      body: '',
    })
  }
}

async function pasteFolderFromString({
  operationState,
  userId,
  nextTo,
  folder,
  isSelfpacedContent,
  createContentId,
  notif,
  nonExpiringNotif,
  t,
}: {
  operationState: CreateOperationState
  userId: UserId
  nextTo: FolderId
  folder: string
  isSelfpacedContent: boolean
  createContentId: CreateContentId
  notif: Notif
  nonExpiringNotif: NonExpiringNotif
  t: TranslationLookup
}): Promise<void> {
  const copyFolder = parseSanaModuleFragment(folder)
  if (copyFolder === undefined) return

  await pasteFolder({
    operationState,
    userId,
    nextTo,
    isSelfpacedContent,
    copyFolder,
    createContentId,
    notif,
    nonExpiringNotif,
    t,
  })
}

export const usePasteFile = ({ nextTo }: { nextTo: FileId | undefined }): ((sanaNode: string) => void) => {
  const { operationState, scopedCreateContentId, createContentId } = useCreatePageContext()
  const dispatch = useDispatch()
  const userId = useSelector(selectUserId)
  const isSelfpaced = ScopedCreateContentId.isSelfPacedId(scopedCreateContentId)
  const folderId = useSelector(state => selectNodeParentFolderId(state, createContentId, nextTo))
  const notif = useNotif()
  const nonExpiringNotif = useNonExpiringNotif()
  const { t } = useTranslation()
  const stableT = useStableFunction(t)

  return useCallback(
    (sanaNode: string) => {
      if (nextTo !== undefined) {
        assertIsNonNullable(userId)
        assertIsNonNullable(folderId)
        if (isSanaCardFragment(sanaNode)) {
          void pasteFile(
            operationState,
            userId,
            isSelfpaced,
            scopedCreateContentId,
            createContentId,
            sanaNode,
            notif,
            nonExpiringNotif,
            stableT,
            dispatch,
            folderId,
            nextTo
          )
        } else if (isArrayOfSanaCardFragment(sanaNode)) {
          void pasteMultipleFiles({
            operationState,
            userId,
            isSelfpacedContent: isSelfpaced,
            createContentId,
            cards: sanaNode,
            notif,
            nonExpiringNotif,
            t,
            folderId,
            nextTo,
          })
        }
      }
    },
    [
      nextTo,
      userId,
      folderId,
      operationState,
      isSelfpaced,
      scopedCreateContentId,
      createContentId,
      notif,
      nonExpiringNotif,
      stableT,
      dispatch,
      t,
    ]
  )
}

export const onPaste = async ({
  operationState,
  userId,
  isSelfpacedContent,
  notif,
  nonExpiringNotif,
  t,
  scopedCreateContentId,
  createContentId,
  dispatch,
  folderId,
  nextTo,
}: {
  operationState: CreateOperationState
  userId: UserId
  isSelfpacedContent: boolean
  notif: Notif
  nonExpiringNotif: NonExpiringNotif
  t: TranslationLookup
  scopedCreateContentId: ScopedCreateContentId
  createContentId: CreateContentId
  dispatch: AppDispatch
  folderId: FolderId
  nextTo: FileId | FolderId
}): Promise<void> => {
  try {
    const clipboard = await navigator.clipboard.readText()
    if (isSanaCardFragment(clipboard)) {
      void pasteFile(
        operationState,
        userId,
        isSelfpacedContent,
        scopedCreateContentId,
        createContentId,
        clipboard,
        notif,
        nonExpiringNotif,
        t,
        dispatch,
        folderId,
        nextTo
      )
    } else if (isArrayOfSanaCardFragment(clipboard)) {
      void pasteMultipleFiles({
        operationState,
        userId,
        isSelfpacedContent,
        createContentId,
        cards: clipboard,
        notif,
        nonExpiringNotif,
        t,
        folderId,
        nextTo,
      })
    } else if (isSanaModuleFragment(clipboard)) {
      void pasteFolderFromString({
        operationState,
        userId,
        nextTo: folderId,
        folder: clipboard,
        isSelfpacedContent,
        createContentId,
        notif,
        nonExpiringNotif,
        t,
      })
    } else {
      console.warn('Tried to paste but content could not be parsed')
    }
  } catch (e) {
    console.debug(e)
  }
}
