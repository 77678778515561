import { useAtomValue } from 'jotai'
import * as React from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import * as Row from 'sierra-client/lib/tabular/datatype/internal/row'
import { useTabularContext } from 'sierra-client/lib/tabular/provider'
import { RowWrap, TableHeader } from 'sierra-client/lib/tabular/provider/components/basic/components'
import { Td, Tr } from 'sierra-client/lib/tabular/provider/components/common'
import { IconButton, LoadingSpinner, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const Table = styled.table`
  width: 100%;
`
const PagerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-top: 4px;
`

const PaginationButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })`
  ${p => (p.disabled === true ? 'background-color: unset' : '')}
`

const Pager: React.FC = () => {
  const { t } = useTranslation()
  const { api } = useTabularContext()
  const pagination = useAtomValue(api.atoms.pagination)

  if (pagination.totalPages === 1) {
    return null
  }

  const hasNext =
    pagination.currentPage < pagination.loadedPages || pagination.loadedPages < pagination.totalPages

  const pageInfo = `${
    hasNext
      ? (pagination.currentPage - 1) * pagination.currentPageSize + 1
      : pagination.total - pagination.currentPageSize + 1
  }-${hasNext ? pagination.currentPage * pagination.currentPageSize : pagination.total} of ${
    pagination.total
  }`

  return (
    <PagerWrapper>
      <View>
        <PaginationButton
          aria-label={t('dictionary.previous')}
          variant='transparent'
          iconId='chevron--left--small'
          disabled={pagination.currentPage === 1}
          onClick={() => {
            void api.action.previousPage()
          }}
          size='small'
        />
        <Text>
          {pagination.currentPage} / {pagination.totalPages}
        </Text>
        <PaginationButton
          aria-label={t('dictionary.next')}
          variant='transparent'
          iconId='chevron--right--small'
          disabled={!hasNext}
          onClick={() => {
            void api.action.nextPage()
          }}
          size='small'
        />
      </View>
      <View>
        <Text color='foreground/muted'>{pageInfo}</Text>
      </View>
    </PagerWrapper>
  )
}

const RenderRowAtom = (): JSX.Element => {
  const { pages, api } = useTabularContext()
  const pagination = useAtomValue(api.atoms.pagination)
  const loadingState = useAtomValue(api.atoms.loaderState)
  const itemRef = React.useRef<HTMLTableRowElement>(null)
  const currentRows = useMemo(() => pages[pagination.currentPage - 1] ?? [], [pages, pagination.currentPage])

  if (loadingState === 'init') {
    return (
      <tr>
        <td colSpan={999}>
          <LoadingSpinner />
        </td>
      </tr>
    )
  }

  const nPaddedItems = pagination.requestedPageSize - pagination.currentPageSize
  const paddedItems: Array<Row.Row> = Array.from({ length: nPaddedItems }).map((_, index) => ({
    type: 'Row.Flat' as const,
    ref: 'empty-row-' + index,
    cells: [],
    rawData: null,
  }))

  const emptyPlaceholder = <span style={{ whiteSpace: 'pre' }}> </span>

  return (
    <>
      {currentRows.map(row => (
        <RowWrap ref={itemRef} row={row} key={row.ref} />
      ))}
      {pagination.totalPages > 1 &&
        paddedItems.map(row => (
          <Tr ref={itemRef} key={row.ref} style={{ height: itemRef.current?.clientHeight }}>
            <Td colSpan={999}>{emptyPlaceholder}</Td>
          </Tr>
        ))}
    </>
  )
}

export const PaginatedTabular: React.FC = () => {
  return React.useMemo(
    () => (
      <>
        <Table>
          <TableHeader />
          <tbody>
            <RenderRowAtom />
          </tbody>
        </Table>
        <Pager />
      </>
    ),
    []
  )
}
