import React from 'react'
import { ContentLabels } from 'sierra-client/components/common/content-elements'
import { SanaImage } from 'sierra-client/components/common/image'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useContentKindPermissions } from 'sierra-client/hooks/use-permissions'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  ManageDetailContainer,
  ManageDetailContent,
  ManageDetailGrid,
  ManageDetailSidebar,
} from 'sierra-client/views/manage/components/common'
import { ContentGroupsTable } from 'sierra-client/views/manage/components/content-groups-table'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  ActionsSection,
  DurationSection,
  LastEditedSection,
  OtherCourseDetailsSection,
  OverviewSection,
  QuickSettingsSection,
} from 'sierra-client/views/manage/courses/components/sections'
import { TeamspaceDropdown } from 'sierra-client/views/manage/courses/components/teamspace-dropdown'
import {
  CourseGroupActionModals,
  useCourseGroupActionModals,
} from 'sierra-client/views/manage/courses/course-group-action-modals'
import { CourseGroupUserProgressTable } from 'sierra-client/views/manage/courses/course-group-user-progess-table'
import { CourseGroupEditionsTable } from 'sierra-client/views/manage/courses/manage-course-group-editions-table'
import { useManageCourseGroupDetails } from 'sierra-client/views/manage/courses/use-manage-course-group-details'
import { Separator } from 'sierra-client/views/showcase/common'
import { CourseId } from 'sierra-domain/api/nano-id'
import { Heading, Spacer, Text, View } from 'sierra-ui/primitives'

interface CourseGroupDetailsProps {
  courseGroupId: CourseId
}

export const ManageCourseGroupDetails: React.FC<CourseGroupDetailsProps> = ({ courseGroupId }) => {
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)
  const { t } = useTranslation()

  const modal = useCourseGroupActionModals()

  const courseGroupDetails = useManageCourseGroupDetails(courseGroupId)
  const {
    courseEditionsData,
    courseGroupData,
    courseGroupProgramAssignments,
    isEditionsLoading,
    usersData,
    isUsersDataLoading,
    saveVisibility,
    fetchUsersData,
  } = courseGroupDetails

  const imageSrc = useResolveAsset({
    image: courseGroupData?.image,
    assetContext: { type: 'course', courseId: CourseId.parse(courseGroupId) },
    size: 'default',
  })

  if (courseGroupData === undefined || isEditionsLoading || isUsersDataLoading) return null

  return (
    <>
      <PageTitle title={courseGroupData.title} />

      <ColumnLayout>
        <DetailsHeader
          titleAddon={
            <ContentLabels contentType='course' courseKind={courseGroupData.kind} isCourseEdition={false} />
          }
          backlink={{
            href: '/manage/content',
            label: 'manage.backlinks--content',
          }}
        />

        <ManageDetailContainer>
          <ManageDetailGrid>
            <ManageDetailSidebar>
              <SanaImage src={imageSrc} ratio='16:9' rounded />
              <Spacer size='small' />
              <Heading size='h4' bold>
                {courseGroupData.title}
              </Heading>
              <Spacer size='xxsmall' />
              <Spacer size='xxsmall' />
              <DurationSection timeTotal={courseGroupData.timeTotal} />
              <Spacer size='xxsmall' />
              <LastEditedSection
                publishedAt={courseGroupData.publishedAt}
                lastEditedAt={courseGroupData.lastEditedAt}
              />
              <Spacer size='xsmall' />
              <ActionsSection
                courseId={courseGroupId}
                courseKind={courseGroupData.kind}
                canEdit={courseGroupData.canEdit}
                canEditMetadata={courseGroupPermissions.has('EDIT_METADATA')}
                canDelete={courseGroupPermissions.has('DELETE')}
                onClickDelete={() => modal.open({ modal: 'delete' })}
              />

              <QuickSettingsSection
                courseId={courseGroupId}
                courseKind={courseGroupData.kind}
                isVisible={courseGroupData.isVisible}
                saveVisibility={saveVisibility}
              />

              <OtherCourseDetailsSection
                description={courseGroupData.description}
                tags={courseGroupData.tags}
                includedInPaths={courseGroupData.includedInPaths}
              />
              <Separator />
              <TeamspaceDropdown courseId={courseGroupData.courseId} courseType={courseGroupData.kind} />
            </ManageDetailSidebar>
            <ManageDetailContent>
              <View direction='column' gap='none'>
                <OverviewSection
                  courseKind={courseGroupData.kind}
                  completionRate={courseGroupData.completionRate}
                  rating={courseGroupData.rating}
                  ratingCount={courseGroupData.ratingCount}
                  directAssignmentsCount={courseGroupData.enrollmentCount}
                  selfStartedUsersCount={
                    courseGroupData.assignedOrStartedUsersCount - courseGroupData.enrollmentCount
                  }
                />
                <Spacer size='xxlarge' />
                <Text size='large' bold>
                  {t('dictionary.user-plural')}
                </Text>
                <Spacer size='xsmall' />
                <CourseGroupUserProgressTable
                  refetch={fetchUsersData}
                  data={usersData}
                  courseGroupId={courseGroupId}
                  modal={modal}
                  isDefaultRequiredAssignment={courseGroupData.isDefaultRequiredAssignment}
                />
                <Spacer size='xxlarge' />
                <Text size='large' bold>
                  {t('dictionary.edition-plural')}
                </Text>
                <CourseGroupEditionsTable
                  courseGroupId={courseGroupId}
                  modal={modal}
                  data={courseEditionsData}
                />
                <Spacer size='small' />
                {courseGroupProgramAssignments.length > 0 && (
                  <>
                    <ContentGroupsTable assignedPrograms={courseGroupProgramAssignments} />
                    <Spacer size='xxlarge' />
                  </>
                )}
              </View>
            </ManageDetailContent>
          </ManageDetailGrid>
          <CourseGroupActionModals
            control={modal}
            courseGroupId={courseGroupId}
            courseGroupDetails={courseGroupDetails}
          />
        </ManageDetailContainer>
      </ColumnLayout>
    </>
  )
}
