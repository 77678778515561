/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useCallback } from 'react'
import {
  useInvalidateEditableContentCache,
  useInvalidateFoldersCache,
  useInvalidateTeamspaceCache,
} from 'sierra-client/api/hooks/queries-invalidation'
import { queryClient } from 'sierra-client/api/query-client'
import { useNotif } from 'sierra-client/components/common/notifications'
import { teamspaceJoinedLogger, teamspaceLeftLogger } from 'sierra-client/features/teamspace/logger'
import { useTeamspacePermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  apiQueryOptions,
  CachedQueryOptions,
  getCachedQueryKey,
  useCachedQuery,
  useTypedMutation,
} from 'sierra-client/state/api'
import { useDispatch } from 'sierra-client/state/hooks'
import {
  EditableContent,
  NonEditorTeamspaceCourse,
  NonEditorTeamspaceCourseType,
} from 'sierra-domain/api/editable-content'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import type {
  BrowseTeamspacesResponse,
  FolderRow,
  GetTeamspaceForCourseResponse,
  MovableContent,
  Teamspace,
  TeamspaceEditableContent,
  TeamspaceFolder,
  TeamspaceFolderWithContent,
  TeamspaceNonEditorCourse,
} from 'sierra-domain/api/teamspace'
import { AnyZodRoute } from 'sierra-domain/api/types'
import {
  XRealtimeTeamspaceAddFolder,
  XRealtimeTeamspaceAddTeamspaceMembers,
  XRealtimeTeamspaceBrowseTeamspaces,
  XRealtimeTeamspaceCreateTeamspace,
  XRealtimeTeamspaceDeleteFolder,
  XRealtimeTeamspaceDeleteTeamspace,
  XRealtimeTeamspaceGetFolderAncestors,
  XRealtimeTeamspaceGetFolderContent,
  XRealtimeTeamspaceGetPotentialIdentities,
  XRealtimeTeamspaceGetTeamspaceById,
  XRealtimeTeamspaceGetTeamspaceForCourse,
  XRealtimeTeamspaceJoinTeamspace,
  XRealtimeTeamspaceLeaveTeamspace,
  XRealtimeTeamspaceListMovableContent,
  XRealtimeTeamspaceListTeamspaces,
  XRealtimeTeamspaceMoveCourses,
  XRealtimeTeamspaceMoveFolder,
  XRealtimeTeamspaceRemoveTeamspaceMember,
  XRealtimeTeamspaceRequestAccess,
  XRealtimeTeamspaceTransferTeamspaceOwnership,
  XRealtimeTeamspaceUpdateFolderName,
  XRealtimeTeamspaceUpdateTeamspaceMember,
  XRealtimeTeamspaceUpdateTeamspaceMetadata,
  XRealtimeTeamspaceUpdateTeamspacePermissions,
} from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'

export const listTeamspacesQuery = apiQueryOptions(
  XRealtimeTeamspaceListTeamspaces,
  {},
  {
    retry: false,
    staleTime: 20_000,
  }
)

export const useFolderContent = <TSelectData = TeamspaceFolderWithContent>(
  teamspaceId: NanoId12,
  folderId: NanoId12,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeTeamspaceGetFolderContent,
    { teamspaceId, folderId },
    {
      retry: false,
      staleTime: 20_000,
      ...options,
    }
  )
}

export const useFolderAncestors = <TSelectData = TeamspaceFolder[]>(
  teamspaceId: NanoId12,
  folderId: NanoId12,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeTeamspaceGetFolderAncestors,
    { teamspaceId, folderId },
    {
      retry: false,
      staleTime: 20_000,
      ...options,
    }
  )
}

export const useBrowseTeamspaces = <TSelectData = BrowseTeamspacesResponse>(
  options: CachedQueryOptions<BrowseTeamspacesResponse, TSelectData> = {}
) => {
  return useCachedQuery(
    XRealtimeTeamspaceBrowseTeamspaces,
    {},
    {
      retry: false,
      ...options,
    }
  )
}

export const teamspaceByIdQuery = (teamspaceId: NanoId12) =>
  apiQueryOptions(XRealtimeTeamspaceGetTeamspaceById, { teamspaceId }, { retry: false })

export const useTeamspaceById = (teamspaceId: NanoId12) => {
  return useQuery(teamspaceByIdQuery(teamspaceId))
}

export const prefetchTeamspaceById = (teamspaceId: NanoId12) => {
  return queryClient.prefetchQuery(teamspaceByIdQuery(teamspaceId))
}

export const useTeamspaceByContentId = <TSelectData = GetTeamspaceForCourseResponse>(
  contentId: NanoId12,
  { enabled = true }: { enabled?: boolean } = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeTeamspaceGetTeamspaceForCourse,
    { courseId: contentId },
    { retry: false, enabled, staleTime: 10 * 1000 }
  )
}

export const isMyContent = (content: EditableContent | NonEditorTeamspaceCourse): boolean => {
  const isNonEditorCourse = NonEditorTeamspaceCourseType.safeParse(content.type).success
  if (isNonEditorCourse) return false
  return content.highestCollaboratorRole === 'owner' && content.teamspaceId === undefined
}

export const isContentInAnotherTeamspace = (
  content: EditableContent | NonEditorTeamspaceCourse,
  currentTeamspaceId: NanoId12 | undefined
): boolean => content.teamspaceId !== undefined && content.teamspaceId !== currentTeamspaceId

export const getRootFolderOfTeamspace = (teamspace: Teamspace | undefined): FolderRow | undefined => {
  if (teamspace === undefined) return undefined
  return teamspace.content.find(
    (it): it is FolderRow => it.type === 'folder' && it.parentFolderId === undefined
  )
}

export const isSubFolderOfCurrentFolder = (
  teamspace: Teamspace,
  currentFolderId: string,
  potentialSubFolder: FolderRow
): boolean => {
  let potentialSubFolderParentId = potentialSubFolder.parentFolderId

  while (potentialSubFolderParentId !== undefined) {
    if (potentialSubFolderParentId === currentFolderId) return true
    const newParentFolder = teamspace.content.find(
      (it): it is FolderRow => it.type === 'folder' && it.id === potentialSubFolderParentId
    )
    if (newParentFolder === undefined) return false
    potentialSubFolderParentId = newParentFolder.parentFolderId
  }

  return false
}

export const isParentFolderOfCurrentFolder = (
  teamspace: Teamspace,
  currentFolderId: string,
  potentialParentFolder: FolderRow | undefined
): boolean => {
  const currentFolderRow = teamspace.content.find(
    (it): it is FolderRow => it.type === 'folder' && it.id === currentFolderId
  )
  if (currentFolderRow === undefined) return false

  const rootFolder = getRootFolderOfTeamspace(teamspace)

  if (
    potentialParentFolder === undefined &&
    (rootFolder === undefined || currentFolderRow.parentFolderId === rootFolder.id)
  )
    return true

  return currentFolderRow.parentFolderId === potentialParentFolder?.id
}

export const isContentInFolder = (teamspace: Teamspace, folderId: string, courseId: string): boolean => {
  const editableTeamspaceContent = teamspace.content.find(
    (it): it is TeamspaceEditableContent =>
      it.type === 'editable' && it.content.id === courseId && it.parentFolderId === folderId
  )

  const nonEditorContent = teamspace.content.find(
    (it): it is TeamspaceNonEditorCourse =>
      it.type === 'non-editor-course' && it.content.id === courseId && it.parentFolderId === folderId
  )
  return editableTeamspaceContent !== undefined || nonEditorContent !== undefined
}

export const usePotentialIdentities = <TSelectData = IdentityWithMetadata[]>(
  teamspaceId?: NanoId12,
  options: CachedQueryOptions<IdentityWithMetadata[], TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeTeamspaceGetPotentialIdentities,
    { teamspaceId },
    {
      retry: false,
      staleTime: 300_000,
      ...options,
    }
  )
}

export const useListMovableContent = <TSelectData = MovableContent[]>(
  teamspaceId: NanoId12,
  options: CachedQueryOptions<TSelectData> = {}
): UseQueryResult<TSelectData> => {
  return useCachedQuery(
    XRealtimeTeamspaceListMovableContent,
    { teamspaceId },
    {
      retry: false,
      ...options,
    }
  )
}

const useMutationWithTeamspaceInvalidation = <Route extends AnyZodRoute>(route: Route) => {
  const invalidateCache = useInvalidateTeamspaceCache()
  const notification = useNotif()

  return useTypedMutation(route, {
    onSettled: () => {
      void invalidateCache()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
}

export const useCreateTeamspaceMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceCreateTeamspace)
}

export const useJoinTeamspaceMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceJoinTeamspace)
}

export const useLeaveTeamspaceMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceLeaveTeamspace)
}

export const useLeaveTeamspace = (teamspaceId: string): { leave: () => void } => {
  const { mutate: leaveMutation } = useLeaveTeamspaceMutation()
  const teamspacePermissions = useTeamspacePermissions(teamspaceId)

  const dispatch = useDispatch()

  const leave = useCallback(
    () =>
      !teamspacePermissions.has('LEAVE')
        ? undefined
        : leaveMutation(
            { teamspaceId },
            {
              onSuccess: async () => {
                await teamspacePermissions.refetch()
                void dispatch(
                  teamspaceLeftLogger({
                    teamspaceId,
                  })
                )
              },
            }
          ),
    [dispatch, leaveMutation, teamspaceId, teamspacePermissions]
  )

  return { leave }
}

export const useJoinTeamspace = (teamspaceId: string): { join: () => void } => {
  const { mutate: joinMutation } = useJoinTeamspaceMutation()
  const dispatch = useDispatch()
  const teamspacePermissions = useTeamspacePermissions(teamspaceId)

  const join = useCallback(
    () =>
      joinMutation(
        { teamspaceId },
        {
          onSuccess: async () => {
            await teamspacePermissions.refetch()
            void dispatch(
              teamspaceJoinedLogger({
                teamspaceId,
              })
            )
          },
        }
      ),
    [joinMutation, teamspaceId, teamspacePermissions, dispatch]
  )

  return { join }
}

export const useRequestTeamspaceAccess = () => {
  const notification = useNotif()

  return useTypedMutation(XRealtimeTeamspaceRequestAccess, {
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
}

export const useAddTeamspaceMembersMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceAddTeamspaceMembers)
}

export const useUpdateTeamspaceMemberMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceUpdateTeamspaceMember)
}

export const useRemoveTeamspaceMemberMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceRemoveTeamspaceMember)
}

export const useTransferTeamspaceOwnershipMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceTransferTeamspaceOwnership)
}

export const useMoveCoursesMutation = () => {
  const invalidateTeamspaceCache = useInvalidateTeamspaceCache()
  const invalidateFolders = useInvalidateFoldersCache()
  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const { t } = useTranslation()
  const notification = useNotif()

  return useTypedMutation(XRealtimeTeamspaceMoveCourses, {
    onSettled: () => {
      void invalidateTeamspaceCache()
      void invalidateEditableContentCache()
      void invalidateFolders()
    },
    onSuccess: d => {
      if (!d.success) {
        return Promise.reject(d)
      }
    },
    onError: _e => {
      // TODO: use the error message
      notification.push({
        type: 'custom',
        level: 'error',
        body: t('teamspace.move.failed'),
      })
    },
  })
}

export const useMoveFolderMutation = () => {
  const invalidateTeamspaceCache = useInvalidateTeamspaceCache()
  const invalidateFolders = useInvalidateFoldersCache()
  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const { t } = useTranslation()
  const notification = useNotif()

  return useTypedMutation(XRealtimeTeamspaceMoveFolder, {
    onSettled: () => {
      void invalidateTeamspaceCache()
      void invalidateEditableContentCache()
      void invalidateFolders()
    },
    onError: () => {
      notification.push({
        type: 'custom',
        level: 'error',
        body: t('teamspace.move.failed'),
      })
    },
  })
}

export const useUpdateTeamspaceMetadataMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceUpdateTeamspaceMetadata)
}

export const useUpdateTeamspacePermissionsMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceUpdateTeamspacePermissions)
}

export const useDeleteTeamspaceMutation = () => {
  return useMutationWithTeamspaceInvalidation(XRealtimeTeamspaceDeleteTeamspace)
}

const useMutationWithFolderInvalidation = <Route extends AnyZodRoute>(route: Route) => {
  const invalidateTeamspaceCache = useInvalidateTeamspaceCache()
  const invalidateFolders = useInvalidateFoldersCache()
  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const notification = useNotif()

  return useTypedMutation(route, {
    onSettled: () => {
      void invalidateTeamspaceCache()
      void invalidateEditableContentCache()
      void invalidateFolders()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
}

export const useAddTeamspaceFolderMutation = () => {
  return useMutationWithFolderInvalidation(XRealtimeTeamspaceAddFolder)
}

export const useUpdateTeamspaceFolderNameMutation = () => {
  return useMutationWithFolderInvalidation(XRealtimeTeamspaceUpdateFolderName)
}

export const useDeleteFolderMutation = () => {
  const invalidateTeamspaceCache = useInvalidateTeamspaceCache()
  const invalidateEditableContentCache = useInvalidateEditableContentCache()
  const invalidateFolders = useInvalidateFoldersCache()
  const notification = useNotif()

  const queryClient = useQueryClient()

  const getFolderContentCachedData = (
    teamspaceId: NanoId12,
    folderId: NanoId12
  ): TeamspaceFolderWithContent | undefined => {
    const queryKey = getCachedQueryKey(XRealtimeTeamspaceGetFolderContent, { teamspaceId, folderId })
    return queryClient.getQueryData(queryKey)
  }

  return useTypedMutation(XRealtimeTeamspaceDeleteFolder, {
    onMutate: ({ teamspaceId, folderId }) => {
      // Optimistic update to remove the folder from the `get-folder-content` cache.
      //
      // It helps with dealing with the flickering in the UI when
      // using `invalidateFolders()` in the `onSettled` function.
      // This is because when navigating back to the parent after deleting the folder,
      // React Query will use the cached version while fetching for an update.
      const folderToDelete = getFolderContentCachedData(teamspaceId, folderId)

      if (folderToDelete?.parentFolderId !== undefined) {
        const parentFolder = getFolderContentCachedData(teamspaceId, folderToDelete.parentFolderId)

        if (parentFolder) {
          const updatedContent = parentFolder.content.filter(
            folder => !(folder.type === 'folder' && folder.id === folderId)
          )

          const queryKey = getCachedQueryKey(XRealtimeTeamspaceGetFolderContent, {
            teamspaceId,
            folderId: folderToDelete.parentFolderId,
          })
          queryClient.setQueryData(queryKey, {
            ...parentFolder,
            content: updatedContent,
          })

          return { updated: true }
        }
      }

      return { updated: false }
    },
    onSettled: (data, error, variables, context) => {
      if (isDefined(context) && context.updated !== true) {
        void invalidateFolders()
      }
      void invalidateTeamspaceCache()
      void invalidateEditableContentCache()
    },
    onError: () => {
      notification.push({ type: 'error' })
    },
  })
}
