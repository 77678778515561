import { Heading } from 'sierra-ui/primitives/typography'

import { useTranslation } from 'sierra-client/hooks/use-translation'
import { View } from 'sierra-ui/primitives'

export const QAcardPlaceholderView = (): JSX.Element => {
  const { t } = useTranslation()

  return (
    <View alignItems='center' justifyContent='center' direction='column'>
      <Heading align='center' bold size='h4'>
        {t('question-answers.prompt')}
      </Heading>
      <Heading bold size='h4'>
        {t('question-answers.sub-prompt')}
      </Heading>
    </View>
  )
}
