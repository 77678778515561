import { useMemo } from 'react'
import { useAuthenticatedUserIntegrationsQuery } from 'sierra-client/hooks/use-authenticated-user-integrations-query'

type UseAvailableIntegrationActions = {
  microsoft: {
    createMeetingUrl: boolean
    createCalendarEvent: boolean
  }
  google: {
    createMeetingUrl: boolean
    createCalendarEvent: boolean
  }
  zoom: {
    createMeetingUrl: boolean
  }
}

export function useAvailableIntegrationActions(): UseAvailableIntegrationActions {
  const integrations = useAuthenticatedUserIntegrationsQuery().data?.viewer.integrations

  return useMemo(() => {
    if (integrations === undefined) {
      return {
        microsoft: {
          createMeetingUrl: false,
          createCalendarEvent: false,
        },
        google: {
          createMeetingUrl: false,
          createCalendarEvent: false,
        },
        zoom: {
          createMeetingUrl: false,
        },
      }
    }

    const userMicrosoftScopes = integrations.microsoft.scope.map(scope => scope.value)
    const userGoogleScopes = integrations.google.scope.map(scope => scope.value)
    const userZoomScopes = integrations.zoom.scope.map(scope => scope.value)

    return {
      microsoft: {
        createMeetingUrl: integrations.microsoft.createMeetingUrlScope
          // todo(rsvp): do we need to filter this out? (we had issues before with it not appearing)
          .filter(r => r.type !== 'OfflineAccess')
          .every(r => userMicrosoftScopes.includes(r.value)),

        createCalendarEvent: integrations.microsoft.createMicrosoftCalendarEventScope
          // todo(rsvp): do we need to filter this out? (we had issues before with it not appearing)
          .filter(r => r.type !== 'OfflineAccess')
          .every(r => userMicrosoftScopes.includes(r.value)),
      },
      google: {
        createMeetingUrl: integrations.google.createMeetingUrlScope.every(r =>
          userGoogleScopes.includes(r.value)
        ),
        createCalendarEvent: integrations.google.createGoogleCalendarEventScope.every(r =>
          userGoogleScopes.includes(r.value)
        ),
      },
      zoom: {
        createMeetingUrl: integrations.zoom.createMeetingUrlScope.every(r =>
          userZoomScopes.includes(r.value)
        ),
      },
    }
  }, [integrations])
}
