import { createSlice } from '@reduxjs/toolkit'
import { clearState } from 'sierra-client/state/actions'
import {
  coursesAdapter,
  linkCoursesAdapter,
  linkedinCoursesAdapter,
  pathsAdapter,
  programAdapter,
  scormCoursesAdapter,
} from 'sierra-client/state/content/adapters'
import { ContentState, PathsSliceState, ResultsState } from 'sierra-client/state/content/types'
import { updateStatus } from 'sierra-client/state/content/utils'
import {
  fetchCourseDataById,
  fetchCourseStatusById,
  updateLinkCourse,
  updateLinkedInCourse,
  updateScormCourse,
} from 'sierra-client/state/v2/courses-actions'
import { locationChanged } from 'sierra-client/state/v2/navigation-actions'
import { fetchPathById } from 'sierra-client/state/v2/paths-actions'
import { fetchProgramById } from 'sierra-client/state/v2/program-actions'
import { CourseId } from 'sierra-domain/api/nano-id'

export const pathsSlice = createSlice({
  name: 'paths',
  initialState: pathsAdapter.getInitialState() as PathsSliceState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => pathsAdapter.getInitialState())
    builder.addCase(fetchPathById.fulfilled, (state, { payload }) => {
      pathsAdapter.upsertOne(state, payload)
    })

    // TODO: read/write course status in one place
    builder.addCase(fetchCourseStatusById.fulfilled, (state, { payload, meta: { arg } }) => {
      const courseId = arg.courseId

      state.ids.forEach(id => {
        if (state.entities[id]!.courses.some(c => c.id === courseId)) {
          state.entities[id]!.courses = state.entities[id]!.courses.map(c =>
            c.id === courseId ? { ...c, status: payload } : c
          )
        }
      })
    })
  },
})

export const programSlice = createSlice({
  name: 'program',
  initialState: programAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => programAdapter.getInitialState())
    builder.addCase(fetchProgramById.fulfilled, (state, { payload }) => {
      programAdapter.upsertOne(state, payload)
    })

    // TODO: read/write course status in one place
    builder.addCase(fetchCourseStatusById.fulfilled, (state, { payload, meta: { arg } }) => {
      const courseId = arg.courseId

      state.ids.forEach(id => {
        if (state.entities[id]!.steps.some(s => s.id === courseId)) {
          state.entities[id]!.steps = state.entities[id]!.steps.map(s =>
            s.id === courseId
              ? {
                  ...s,
                  passedAt: payload.passedTimestamp,
                }
              : s
          )
        }
      })
    })
  },
})

export const courseSlice = createSlice({
  name: 'courses',
  initialState: coursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => coursesAdapter.getInitialState())
    builder.addCase(fetchCourseDataById.fulfilled, (state, { payload }) => {
      coursesAdapter.upsertOne(state, {
        // TODO: Parse can be avoided in the future when we have switched everything to zod, by making the api layer validate the CourseId type
        id: CourseId.parse(payload.id),
        settings: payload.courseSettings,
        type: payload.type,
        readingTimes: payload.type === 'native:self-paced' ? payload.readingTimes : undefined,
        selfEnrollSessions: payload.type === 'native:live' ? payload.selfEnrollSessions : undefined,
        upcomingAssignedSessions:
          payload.type === 'native:live' ? payload.upcomingAssignedSessions : undefined,
        courseGroupId:
          payload.type === 'native:self-paced' || payload.type === 'scorm'
            ? payload.courseGroupId
            : undefined,
      })
    })
  },
})

export const linkedinCourseSlice = createSlice({
  name: 'linkedin-courses',
  initialState: linkedinCoursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => linkedinCoursesAdapter.getInitialState())
    builder.addCase(updateLinkedInCourse, (state, { payload }) => {
      linkedinCoursesAdapter.upsertOne(state, {
        id: payload.id,
        ...payload.record,
      })
    })
  },
})

export const scormCourseSlice = createSlice({
  name: 'scorm-courses',
  initialState: scormCoursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => scormCoursesAdapter.getInitialState())
    builder.addCase(updateScormCourse, (state, { payload }) => {
      scormCoursesAdapter.upsertOne(state, {
        id: payload.id,
        ...payload.record,
      })
    })
  },
})

export const linkCourseSlice = createSlice({
  name: 'link-courses',
  initialState: linkCoursesAdapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => linkCoursesAdapter.getInitialState())
    builder.addCase(updateLinkCourse, (state, { payload }) => {
      linkCoursesAdapter.upsertOne(state, {
        id: payload.id,
        ...payload.record,
      })
    })
  },
})

const initialContentState: ContentState = {}

export const contentSlice = createSlice({
  name: 'content',
  initialState: initialContentState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialContentState)
    builder.addCase(locationChanged, (state, { payload }) => ({ ...state, ...payload }))
  },
})

const initialResultsState: ResultsState = {
  passedTimestamps: {},
  progressTimestamps: {},
  refreshingResults: false,
  timeLeft: {},
  timeTotal: {},
  dueDates: {},
  exercises: {},
}

export const resultsSlice = createSlice({
  name: 'results',
  initialState: initialResultsState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(clearState, () => initialResultsState)
    builder.addCase(fetchCourseStatusById.pending, state => {
      state.refreshingResults = true
    })

    builder.addCase(fetchCourseStatusById.rejected, state => {
      state.refreshingResults = false
    })

    builder.addCase(fetchCourseStatusById.fulfilled, (state, { payload, meta: { arg } }) => {
      updateStatus(state, arg.courseId, payload)
    })
  },
})
