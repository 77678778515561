import { useSearch } from '@tanstack/react-router'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { TemplatePickerModal } from 'sierra-client/components/templates/template-picker-modal'
import { UseTemplates, useTemplates } from 'sierra-client/components/templates/use-templates'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { getGlobalRouter } from 'sierra-client/router'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { FCC } from 'sierra-ui/types'

export type TemplatePickerTeamspaceState = {
  teamspaceId: NanoId12
  folderId: NanoId12 | undefined
}

type TemplatePickerContextType = {
  templatePickerOpen: boolean
  templatePickerOn: (_: { teamspaceState: TemplatePickerTeamspaceState | undefined }) => void
  templatePickerOff: () => void
  templates: UseTemplates
}

const initial = {
  templatePickerOpen: false,
  templatePickerOn: () => {},
  templatePickerOff: () => {},
  templates: {
    sanaLive: [],
    sanaSelfPaced: [],
    partnerAll: [],
    partnerLive: [],
    partnerSelfPaced: [],
    recommended: [],
    community: [],
  },
}

const TemplatePickerContext = createContext<TemplatePickerContextType>(initial)

export const TemplatePickerProvider: FCC = ({ children }) => {
  const [templatePickerOpen, { on: templatePickerOnFn, off: templatePickerOffFn }] = useToggle()

  const templatesTab = useSearch({
    strict: false,
    select: search => search['templates'],
  })

  const [teamspaceState, setTeamspaceState] = useState<TemplatePickerTeamspaceState | undefined>(undefined)

  const templatePickerOn = useStableFunction(
    ({ teamspaceState }: { teamspaceState: TemplatePickerTeamspaceState | undefined }): void => {
      setTeamspaceState(teamspaceState)
      templatePickerOnFn()
    }
  )

  const templatePickerOff = useCallback((): void => {
    setTeamspaceState(undefined)
    templatePickerOffFn()
  }, [templatePickerOffFn])

  useEffect(() => {
    if (templatesTab !== undefined) {
      templatePickerOn({ teamspaceState })
    }
    if (templatesTab === undefined) {
      templatePickerOff()
    }
  }, [teamspaceState, templatePickerOff, templatePickerOn, templatesTab])

  const templates = useTemplates()

  const value = useMemo<TemplatePickerContextType>(
    () => ({
      templatePickerOpen,
      templatePickerOn: ({ teamspaceState }) => {
        setTeamspaceState(teamspaceState)
        void getGlobalRouter().navigate({
          search: { templates: 'recommended' },
        })
      },
      templatePickerOff,
      templates,
    }),
    [templatePickerOpen, templatePickerOff, templates]
  )

  return (
    <TemplatePickerContext.Provider value={value}>
      {children}
      <TemplatePickerModal
        teamspaceId={teamspaceState?.teamspaceId}
        folderId={teamspaceState?.folderId}
        open={templatePickerOpen}
        onClose={templatePickerOff}
        templates={templates}
      />
    </TemplatePickerContext.Provider>
  )
}

export const useTemplatePicker = (): TemplatePickerContextType => useContext(TemplatePickerContext)
