import _ from 'lodash'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { ContentPreviewRenderer } from 'sierra-client/components/courses/preview/content-preview-renderer'
import { FileExplorer } from 'sierra-client/components/courses/preview/file-explorer'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AssetContext } from 'sierra-domain/asset-context'
import { FlexibleContentRecord } from 'sierra-domain/collaboration/serialization/types'
import { slateDocumentMapKey } from 'sierra-domain/collaboration/slate-document-map'
import { FileId, NodeId } from 'sierra-domain/flexible-content/identifiers'
import { File, Folder } from 'sierra-domain/flexible-content/types'
import { assertNever, guardWith, iife } from 'sierra-domain/utils'
import { Label } from 'sierra-ui/components'
import { Button, Text } from 'sierra-ui/primitives'
import { palette } from 'sierra-ui/theming'
import { narrowDotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

const SideBarContainer = styled.div`
  width: 344px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px 48px;
`

const SideBarInfoContainer = styled.div`
  border-bottom: 1px solid ${palette.grey[5]};
  padding-top: 88px;
  padding-bottom: 44px;
`

const BackButton = styled(Button).attrs({ variant: 'transparent' })`
  position: absolute;
  top: 18px;
  left: 30px;
`

const LabelContainer = styled.div`
  display: flex;
  margin-left: -8px;
  margin-bottom: 8px;
  align-items: center;
`

const FileExplorerTitle = styled(Text).attrs({ bold: true, size: 'large' })`
  width: 100%;
  margin-bottom: 8px;
`

const FileExplorerDescription = styled(Text).attrs({ faded: true, size: 'small' })`
  margin-bottom: 24px;
`

const FileExplorerContainer = styled.div`
  margin-right: -56px;
  padding-right: 30px;
  padding-top: 44px;
  height: 100%;

  overflow-y: hidden;
  scrollbar-gutter: stable;

  &:hover {
    overflow-y: auto;
  }
`

type ContentPreviewProps = {
  title: string
  description?: string
  type?: {
    contentType: 'native:live' | 'native:self-paced'
    label: string
  }
  onBackClick?: () => void

  record: FlexibleContentRecord

  actionButtons?: React.ReactNode
  assetContext: AssetContext
}

const getAllFileIds = (record: FlexibleContentRecord, folderId: NodeId = 'folder:root'): FileId[] => {
  const folder = record.jsonData.nodeMap[folderId]

  if (!guardWith(Folder, folder)) throw new Error(`Invalid folder ${folderId}`)

  return folder.nodeIds.flatMap(nodeId => {
    const node = record.jsonData.nodeMap[nodeId]
    if (node?.type === 'file') {
      return [node.id]
    }
    if (node?.type === 'folder') {
      return getAllFileIds(record, node.id)
    }
    return []
  })
}

export const ContentPreview: React.FC<ContentPreviewProps> = memo(
  ({ title, description, type, onBackClick, record, actionButtons, assetContext }) => {
    const { t } = useTranslation()
    const fileIds = getAllFileIds(record)
    const slateDocumentMap = record[slateDocumentMapKey]
    const [fileId, setFileId] = useState(fileIds[0])

    const goStep = useCallback(
      (steps: number) => {
        const fileIndex = fileIds.findIndex(id => id === fileId)
        const newFileId = fileIds[fileIndex + steps]

        if (newFileId === undefined) throw new Error(`Can't find new file id`)

        setFileId(newFileId)
      },
      [fileId, fileIds]
    )

    const goBack = useCallback(() => goStep(-1), [goStep])
    const goForward = useCallback(() => goStep(1), [goStep])

    const isFirst = fileId === fileIds[0]
    const isLast = fileId === _.last(fileIds)

    useEffect(() => {
      const handleKey = (keyboardEvent: KeyboardEvent): void => {
        switch (keyboardEvent.key) {
          case 'ArrowRight':
          case 'ArrowDown':
            if (!isLast) goForward()
            break
          case 'ArrowLeft':
          case 'ArrowUp':
            if (!isFirst) goBack()
            break
        }
      }

      window.addEventListener('keydown', handleKey)
      return () => {
        window.removeEventListener('keydown', handleKey)
      }
    }, [fileIds, fileId, isLast, goForward, isFirst, goBack])

    return (
      <OuterContainer>
        <SideBarContainer>
          <SideBarInfoContainer>
            {onBackClick !== undefined && (
              <BackButton icon='chevron--left--small' onClick={onBackClick}>
                {t('dictionary.templates')}
              </BackButton>
            )}
            {type !== undefined && (
              <LabelContainer>
                {iife(() => {
                  switch (type.contentType) {
                    case 'native:live':
                      return (
                        <Label
                          $size='small'
                          $bgColor='transparent'
                          $color='redVivid'
                          iconId='play--circle--filled'
                        >
                          Live
                        </Label>
                      )
                    case 'native:self-paced':
                      return (
                        <Label $size='small' $bgColor='transparent' $color='grey40' iconId='bookmark--filled'>
                          Course
                        </Label>
                      )
                    default:
                      assertNever(type.contentType)
                  }
                })}
                <Text size='micro' color='grey40'>
                  {narrowDotSeparator}&nbsp;&nbsp;{type.label}
                </Text>
              </LabelContainer>
            )}
            <FileExplorerTitle color='black'>{title}</FileExplorerTitle>
            {description !== undefined && (
              <FileExplorerDescription color='black'>{description}</FileExplorerDescription>
            )}
            {actionButtons !== undefined && actionButtons}
          </SideBarInfoContainer>
          <FileExplorerContainer>
            {fileId !== undefined && (
              <FileExplorer
                slateDocumentMap={slateDocumentMap}
                flexibleContent={record.jsonData}
                goToFile={setFileId}
                fileId={fileId}
                assetContext={assetContext}
              />
            )}
          </FileExplorerContainer>
        </SideBarContainer>
        {(() => {
          if (fileId === undefined) return

          const file = record.jsonData.nodeMap[fileId]
          if (!guardWith(File, file)) return

          return (
            <ContentPreviewRenderer
              file={file}
              slateDocumentMap={slateDocumentMap}
              assetContext={assetContext}
            />
          )
        })()}
      </OuterContainer>
    )
  }
)
