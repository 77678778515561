import { createChannel, createEvent } from 'sierra-client/realtime-data/channels/types'
import { MatrixId, NanoId12 } from 'sierra-domain/api/nano-id'
import {
  MatrixPosition,
  QuestionInteractionAnswer,
  ReflectionResponse,
  ReflectionResponseAnswer,
  ReflectionResponseResponse,
} from 'sierra-domain/api/strategy-v2'
import { MatrixResponseId, UUID, UserId } from 'sierra-domain/api/uuid'
import { VideoCallSetting } from 'sierra-domain/content/session'
import { AvatarColor } from 'sierra-domain/user/avatar-color'
import { DateFromISOString } from 'sierra-domain/zod-extensions/date-from-iso-string'
import { mapNull } from 'sierra-domain/zod-extensions/map-null'
import { z } from 'zod'

const SlidingScaleChangedData = z.object({
  position: z.number(),
  updatedAt: z.string(),
  userId: UserId.optional(),
  id: z.string(),
})
export type SlidingScaleChangedData = z.infer<typeof SlidingScaleChangedData>

const MatrixChangedData = z.object({
  matrixId: MatrixId,
  matrixResponseId: MatrixResponseId,
  userId: UserId.optional(),
  position: MatrixPosition,
  updatedAt: z.string(),
})
export type MatrixChangedData = z.infer<typeof MatrixChangedData>

const DropAWordChangedData = z.object({
  id: UUID,
  dropAWordId: NanoId12,
  word: z.string(),
  userId: UserId,
  updatedAt: DateFromISOString,
  droppedAt: DateFromISOString
    // [Added 2024-12-10] Versions of the backend after this point will send this value
    // This can safely be removed after a few days
    .optional()
    .default(new Date()),
  color: AvatarColor,
})

export type DropAWordChangedData = z.infer<typeof DropAWordChangedData>

const DropAWordDeletedData = z.object({
  id: UUID,
  dropAWordId: NanoId12,
  updatedAt: DateFromISOString,
})

export type DropAWordDeletedData = z.infer<typeof DropAWordDeletedData>

const PollUpdatedData = z.object({
  pollId: z.string(),
  optionResults: z.array(
    z.object({
      optionId: z.string(),
      chosenByUserIds: z.array(UserId),
      totalVotes: z.number(),
    })
  ),
  totalVotes: z.number(),
  updatedAt: z.string(),
})
export type PollUpdatedData = z.infer<typeof PollUpdatedData>

const ReflectionResponseUpdatedData = z.object({
  reflectionId: z.string(),
  responseResults: z.array(ReflectionResponseResponse),
  updatedAt: z.string(),
})
const ReflectionResponseCreatedData = z.object({
  newResponse: ReflectionResponse,
})
export type ReflectionResponseCreatedData = z.infer<typeof ReflectionResponseCreatedData>

const ReflectionResponseDeletedData = z.object({
  deletedResponseId: z.string(),
  deletedAt: z.string(),
})
export type ReflectionResponseDeletedData = z.infer<typeof ReflectionResponseDeletedData>

const ReflectionResponseEditedData = z.object({
  editedResponseId: z.string(),
  editedResponseNewText: z.string(),
  editedAt: z.string(),
})
export type ReflectionResponseEditedData = z.infer<typeof ReflectionResponseEditedData>

const ReflectionResponseAnswerEditedData = z.object({
  responseId: z.string(),
  editedAnswerId: z.string(),
  editedAnswerNewText: z.string(),
  editedAt: z.string(),
})
export type ReflectionResponseAnswerEditedData = z.infer<typeof ReflectionResponseAnswerEditedData>

const ReflectionResponseAnswerDeletedData = z.object({
  responseId: z.string(),
  deletedAnswerId: z.string(),
  deletedAt: z.string(),
})
export type ReflectionResponseAnswerDeletedData = z.infer<typeof ReflectionResponseAnswerDeletedData>

const ReflectionResponseAnswerCreatedData = z.object({
  newResponseAnswer: ReflectionResponseAnswer,
})
export type ReflectionResponseAnswerCreatedData = z.infer<typeof ReflectionResponseAnswerCreatedData>

const ReflectionReactionCreatedData = z.object({
  userId: UserId,
  reaction: z.string(),
  responseId: z.string(),
  answerId: mapNull(z.string().optional()),
  reactedAt: z.string(),
})
export type ReflectionReactionCreatedData = z.infer<typeof ReflectionReactionCreatedData>

const ReflectionReactionDeletedData = z.object({
  userId: UserId,
  reaction: z.string(),
  responseId: z.string(),
  answerId: mapNull(z.string().optional()),
  reactedAt: z.string(),
})
export type ReflectionReactionDeletedData = z.infer<typeof ReflectionReactionDeletedData>

const ReflectionResponseGrouped = z.object({
  buckets: z.array(z.object({ bucketName: z.string(), responseIds: z.array(z.string()) })).optional(),
})
export type ReflectionResponseGrouped = z.infer<typeof ReflectionResponseGrouped>

const QuestionAnswerInserted = z.object({
  questionId: z.string(),
  createdAt: z.string(),
  userId: UserId,
  correct: z.boolean(),
  answer: QuestionInteractionAnswer,
})
export type QuestionAnswerInserted = z.infer<typeof QuestionAnswerInserted>

const LiveSessionSettingsChanged = z.object({
  transcribeSession: z.boolean(),
  guestAccessEnabled: z.boolean(),
  updatedAt: z.string(),
  videoCallSetting: VideoCallSetting.default({ type: 'sana' }),
  startedAt: z.string().optional(),
  endedAt: z.string().optional(),
})

export const liveSessionDataChannel = createChannel('live-session-data', [
  createEvent('sliding-scale-changed', SlidingScaleChangedData),
  createEvent('drop-a-word-changed', DropAWordChangedData),
  createEvent('drop-a-word-deleted', DropAWordDeletedData),
  createEvent('poll-updated', PollUpdatedData),
  createEvent('reflection-response-updated', ReflectionResponseUpdatedData),
  createEvent('reflection-response-created', ReflectionResponseCreatedData),
  createEvent('reflection-response-deleted', ReflectionResponseDeletedData),
  createEvent('reflection-response-edited', ReflectionResponseEditedData),
  createEvent('reflection-response-answer-created', ReflectionResponseAnswerCreatedData),
  createEvent('reflection-response-answer-edited', ReflectionResponseAnswerEditedData),
  createEvent('reflection-response-answer-deleted', ReflectionResponseAnswerDeletedData),
  createEvent('reflection-reaction-created', ReflectionReactionCreatedData),
  createEvent('reflection-reaction-deleted', ReflectionReactionDeletedData),
  createEvent('reflection-response-grouped', ReflectionResponseGrouped),
  createEvent('question-answer-inserted', QuestionAnswerInserted),
  createEvent('live-session-settings-changed', LiveSessionSettingsChanged),
  createEvent(
    'facilitators-changed',
    z.object({ facilitators: z.string().array(), updatedAt: DateFromISOString })
  ),
  createEvent('matrix-changed', MatrixChangedData),
])
