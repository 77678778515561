import { WritableDraft } from 'immer'
import { ResultsState } from 'sierra-client/state/content/types'
import { ContentStatus } from 'sierra-client/state/v2/courses-actions'
import { StrategyCourseStatus } from 'sierra-domain/api/course-with-status'
import { CourseId } from 'sierra-domain/api/nano-id'

const toContentStatus = (courseId: CourseId, courseStatus: StrategyCourseStatus): ContentStatus => {
  return {
    courseId,
    passedTimestamp: courseStatus.passedTimestamp,
    progressTimestamp: courseStatus.progressTimestamp,
    timeLeft: courseStatus.timeLeft,
    timeTotal: courseStatus.timeTotal,
  }
}

export const updateStatus = (
  state: WritableDraft<ResultsState>,
  courseId: CourseId,
  courseStatus: StrategyCourseStatus
): void => {
  const contentStatus = toContentStatus(courseId, courseStatus)

  state.refreshingResults = false
  state.passedTimestamps = {
    ...state.passedTimestamps,
    [contentStatus.courseId]: contentStatus.passedTimestamp,
  }
  state.progressTimestamps = {
    ...state.progressTimestamps,
    [contentStatus.courseId]: contentStatus.progressTimestamp,
  }
  state.timeLeft = {
    ...state.timeLeft,
    [contentStatus.courseId]: courseStatus.timeLeft,
  }
  state.timeTotal = {
    ...state.timeTotal,
    [contentStatus.courseId]: courseStatus.timeTotal,
  }
  state.dueDates = { ...state.dueDates, [contentStatus.courseId]: courseStatus.dueDate }
  state.exercises = { ...state.exercises, [contentStatus.courseId]: courseStatus.exercises }
}
