import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'sierra-client/state/types'
import { UserState } from 'sierra-client/state/user/user-types'
import { isOwner } from 'sierra-domain/access-level'
import { assertNever } from 'sierra-domain/utils'

export const selectUserState = (state: RootState): UserState => state.user

export const selectUser = createSelector(selectUserState, ({ status }) => {
  if (status.type === 'user') {
    return status.user
  } else {
    return undefined
  }
})
export const selectIsInitializing = createSelector(selectUserState, state => {
  const status = state.status
  return status.type === 'loading' && !status.loaded
})

export const selectIsPendingCodeVerification = createSelector(selectUserState, state => {
  const status = state.status
  return status.type === 'code-verification'
})

export const selectIsPendingCodeVerificationOrLoggedIn = createSelector(selectUserState, state => {
  const status = state.status
  return status.type === 'code-verification' || status.type === 'user'
})

type GuestUserState = 'guest' | 'loading' | 'not-guest'
type ScormUserState = 'scorm-user' | 'loading' | 'not-scorm-user'

export const selectIsLoggedIn = createSelector(selectUser, user => !!user)
export const selectIsScormUser = createSelector(selectUser, (user): ScormUserState => {
  const isScormUser = user?.isScormUser

  switch (isScormUser) {
    case true:
      return 'scorm-user'
    case false:
      return 'not-scorm-user'
    case undefined:
      return 'loading'
    default:
      assertNever(isScormUser)
  }
})
export const selectIsGuestUser = createSelector(selectUser, (user): GuestUserState => {
  const isGuestUser = user?.isGuestUser

  switch (isGuestUser) {
    case true:
      return 'guest'
    case false:
      return 'not-guest'
    case undefined:
      return 'loading'
    default:
      assertNever(isGuestUser)
  }
})
export const selectIsRestrictedUser = createSelector(
  selectIsScormUser,
  selectIsGuestUser,
  selectIsLoggedIn,
  (isScormUser, isGuestUser, isLoggedIn) =>
    !isLoggedIn ||
    isScormUser === 'loading' ||
    isScormUser === 'scorm-user' ||
    isGuestUser === 'loading' ||
    isGuestUser === 'guest'
)

export const selectStaffUser = createSelector(selectUser, user =>
  user && isOwner(user.accessRole, user.accessLevel) ? user : undefined
)
export const selectIsOwnerUser = createSelector(selectStaffUser, user => !!user)

export const selectIsImpersonated = createSelector(selectUser, user => user?.impersonatorId !== undefined)

export const selectIsPendingEmailVerification = createSelector(
  selectUser,
  user => user?.isPendingEmailVerification ?? false
)

export const selectIsDisabled = createSelector(selectUser, user => user?.disabled ?? false)

export const selectIsRegistrationActive = createSelector(
  selectUser,
  user => user !== undefined && (user.registrationStep !== 'done' || user.completedAt === undefined)
)

export const selectRegistrationStep = createSelector(selectUser, user => user?.registrationStep)

export const selectAvatarColor = createSelector(selectUser, user => user?.avatarColor)
export const selectUserLanguage = createSelector(selectUser, user => user?.language)
export const selectUserId = createSelector(selectUser, user => user?.uuid)
