import _ from 'lodash'
import { getGlobalRouter } from 'sierra-client/router'
import { editUrlFromScopedId } from 'sierra-domain/api/editable-content'
import { UserId } from 'sierra-domain/api/uuid'
import { replaceIdsInNodes } from 'sierra-domain/collaboration/slate-document-map'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { apply, createOperationState } from 'sierra-domain/editor/operations/operation'
import { AddFile } from 'sierra-domain/editor/operations/types'
import { getFile } from 'sierra-domain/editor/operations/y-utilts'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { generateFileId } from 'sierra-domain/flexible-content/types'
import { SlateDocument } from 'sierra-domain/v3-author'
import { getSlateDocumentSafe } from 'sierra-domain/v3-author/slate-yjs-extension'
import * as Y from 'yjs'

export function copyHistoryFileIntoEditor({
  editorYdoc,
  historyYdoc,
  historyFileId,
  isSelfpacedContent,
  userId,
  scopedCreateContentId,
}: {
  editorYdoc: Y.Doc
  historyYdoc: Y.Doc
  historyFileId: FileId
  isSelfpacedContent: boolean
  userId: UserId
  scopedCreateContentId: ScopedCreateContentId
}): void {
  const copyFile = getFile(historyYdoc, historyFileId)
  const slateDocument = getSlateDocumentSafe(historyYdoc, historyFileId)
  const filteredFile = !isSelfpacedContent ? _.omit(copyFile, 'narration') : copyFile
  const newFileId = generateFileId()
  const addFile: AddFile = {
    type: 'add-file',
    file: {
      ...filteredFile,
      metadata: {
        createdFromAction: { type: 'duplicate-from-version-history', originalFileId: historyFileId },
        createdAt: new Date().toISOString(),
        createdBy: userId,
      },
      id: newFileId,
    },
    folderId: undefined,
    destination: { type: 'add-at-end' },
    slateDocument:
      slateDocument !== undefined ? (replaceIdsInNodes(slateDocument) as SlateDocument) : undefined,
  }

  const operationState = createOperationState(editorYdoc)
  apply(operationState, addFile)

  void getGlobalRouter().navigate({ to: editUrlFromScopedId(scopedCreateContentId, newFileId) })
}
