import { useId } from 'react'
import { getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { NotificationSettings, NotificationState } from 'sierra-domain/api/org-notification-settings'
import { isDefined } from 'sierra-domain/utils'
import { Icon, IconId } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled from 'styled-components'

export function checkState(checked: boolean): NotificationState {
  return checked ? NotificationState.Enabled : NotificationState.Disabled
}

export type SettingsItem = {
  id: string
  checked: boolean
  disabled: boolean
  title: TranslationKey
  subtitle?: TranslationKey
}

export const NotificationListTitles: React.FC<{ title: string; iconId: IconId }> = ({ title, iconId }) => {
  return (
    <View gap='8'>
      <Icon iconId={iconId} />
      <Text bold>{title}</Text>
    </View>
  )
}

const CategoryCard = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid ${token('border/default')};
  box-shadow: 0px 2px 4px 0px #00000014;
  padding: 24px 24px;
  background: ${token('elevated/background')};
`
const NotificationRow = styled(View).attrs({
  justifyContent: 'space-between',
})``

const FormLabel = styled.label`
  flex-grow: 1;
  cursor: pointer;
`

export const SettingsList = <Item extends SettingsItem>({
  settings,
  onClick,
}: {
  settings: Array<Item>
  onClick: (id: Item['id'], checked: boolean) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const formIdPrefix = useId()
  const formId = (id: string): string => `${formIdPrefix}${id}`

  return (
    <CategoryCard>
      {settings.map(setting => {
        // This wrapper function is to comply with the type of onCheckedChange
        // In practice, checked will always be a boolean
        const onCheckedChangeWrapper = (checked: boolean | 'indeterminate'): void => {
          if (typeof checked === 'boolean') {
            onClick(setting.id, checked)
          }
        }

        const title = t(setting.title)
        const subtitle = setting.subtitle ? t(setting.subtitle) : undefined

        return (
          <NotificationRow key={setting.id}>
            <FormLabel tabIndex={0} htmlFor={formId(setting.id)}>
              <Text>{title}</Text>
              {isDefined(subtitle) && (
                <Text size='micro' color='foreground/muted'>
                  {subtitle}
                </Text>
              )}
            </FormLabel>
            <View gap='8'>
              {setting.disabled && (
                <Text color='foreground/muted'>{t('settings.notifications-page.notification-disabled')}</Text>
              )}
              <Checkbox
                id={formId(setting.id)}
                checked={setting.checked}
                disabled={setting.disabled}
                onCheckedChange={onCheckedChangeWrapper}
                aria-label={title}
              />
            </View>
          </NotificationRow>
        )
      })}
    </CategoryCard>
  )
}

export type NotificationCategory =
  | 'accountNotifications'
  | 'assignmentsNotifications'
  | 'certificateNotifications'
  | 'courseNotifications'
  | 'creatorNotifications'
  | 'insightNotifications'
  | 'eventNotifications'
  | 'teamspaceNotifications'

type NotificationInfo = {
  id: NotificationKey
  title: TranslationKey
  subtitle?: TranslationKey
}

export type NotificationCategoryInfo = {
  title: TranslationKey
  iconId: IconId
  notifications: NotificationInfo[]
}

export type NotificationKey = keyof NotificationSettings

export const notificationCategories: Record<NotificationCategory, NotificationCategoryInfo> = {
  accountNotifications: {
    title: 'settings.notification-categories.account.title',
    iconId: 'portfolio',
    notifications: [
      {
        id: 'platformInvitation',
        title: 'settings.notification.platformInvitation.title',
      },
      {
        id: 'resetPassword',
        title: 'settings.notification.resetPassword.title',
      },
      {
        id: 'verifyEmail',
        title: 'settings.notification.verifyEmail.title',
      },
    ],
  },
  courseNotifications: {
    title: 'settings.notification-categories.course.title',
    iconId: 'course',
    notifications: [
      {
        id: 'assignedContent',
        title: 'settings.notification.assignedContent.title',
      },
      ...(getFlag('program-assigned-notification')
        ? [
            {
              id: 'programAssigned',
              title: 'settings.notification.programAssigned.title',
            } satisfies NotificationInfo,
          ]
        : []),
      {
        id: 'upcomingDueDate',
        title: 'settings.notification.upcomingDueDate.title',
        subtitle: 'settings.notification.upcomingDueDate.subtitle',
      },
      {
        id: 'passedDueDate',
        title: 'settings.notification.passedDueDate.title',
        subtitle: 'settings.notification.passedDueDate.subtitle',
      },
      {
        id: 'smartReview',
        title: 'settings.notification.smart-review.title',
        subtitle: 'settings.notification.smart-review.subtitle',
      },
    ],
  },
  certificateNotifications: {
    title: 'settings.notification-categories.certificate.title',
    iconId: 'medal',
    notifications: [
      {
        id: 'issuedCertificate',
        title: 'settings.notification.issuedCertificate.title',
      },
      {
        id: 'expiringCertificate',
        title: 'settings.notification.expiringCertificate.title',
        subtitle: 'settings.notification.expiringCertificate.subtitle',
      },
      {
        id: 'reportExpiringCertificate',
        title: 'settings.notification.reportExpiringCertificate.title',
        subtitle: 'settings.notification.reportExpiringCertificate.subtitle',
      },
    ],
  },
  eventNotifications: {
    title: 'settings.notification-categories.event.title',
    iconId: 'location',
    notifications: [
      {
        id: 'sessionAssigned',
        title: 'settings.notification.sessionAssigned.title',
      },
      {
        id: 'sessionUnassigned',
        title: 'settings.notification.sessionUnassigned.title',
      },
      {
        id: 'upcomingSession',
        title: 'settings.notification.upcomingSession.title',
        subtitle: 'settings.notification.upcomingSession.subtitle',
      },
      {
        id: 'calendarEventSelfEnrolled',
        title: 'settings.notification.calendarEventSelfEnrolled.title',
      },
      {
        id: 'sessionUpdated',
        title: 'settings.notification.sessionUpdated.title',
      },
      {
        id: 'recapReady',
        title: 'settings.notification.recapReady.title',
        subtitle: 'settings.notification.recapReady.subtitle',
      },
      {
        id: 'calendarEventEnrollmentRequestsPendingReview',
        title: 'settings.notification.calendarEventEnrollmentRequestsPendingReview.title',
      },
      {
        id: 'calendarEventEnrollmentRequestUpdate',
        title: 'settings.notification.calendarEventEnrollmentRequestUpdate.title',
      },
    ],
  },
  creatorNotifications: {
    title: 'settings.notification-categories.creator.title',
    iconId: 'edit',
    notifications: [
      {
        id: 'addedAsCollaborator',
        title: 'settings.notification.addedAsCollaborator.title',
      },
      {
        id: 'addedAsCommentator',
        title: 'settings.notification.addedAsCommentator.title',
      },
      {
        id: 'collaborationRequest',
        title: 'settings.notification.collaborationRequest.title',
      },
      {
        id: 'mentionedInComment',
        title: 'settings.notification.mentionedInComment.title',
      },
      {
        id: 'threadRespondedTo',
        title: 'settings.notification.threadRespondedTo.title',
      },
    ],
  },
  teamspaceNotifications: {
    title: 'settings.notification-categories.teamspace.title',
    iconId: 'teamspace',
    notifications: [
      {
        id: 'teamspaceRequestAccess',
        title: 'settings.notification.teamspaceRequestAccess.title',
      },
    ],
  },
  assignmentsNotifications: {
    title: 'settings.notification-categories.assignments.title',
    iconId: 'send--outline',
    notifications: [
      {
        id: 'homeworksAvailableForReview',
        title: 'settings.notification.homeworksAvailableForReview.title',
      },
      {
        id: 'receivedReview',
        title: 'settings.notification.receivedReview.title',
      },
    ],
  },
  insightNotifications: {
    title: 'settings.notification-categories.insights.title',
    iconId: 'chart--column',
    notifications: [
      {
        id: 'sharedDashboard',
        title: 'settings.notification.sharedDashboard.title',
      },
    ],
  },
}
