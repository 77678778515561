import { useMutation, UseQueryResult } from '@tanstack/react-query'
import { useCallback, useMemo, useRef, useState } from 'react'
import { ScenarioCardChatId } from 'sierra-client/api/graphql/branded-types'
import { graphql } from 'sierra-client/api/graphql/gql'
import {
  ScenarioCardChatFeedback,
  ScenarioCardChatFeedbackInput,
  ScenarioCardChatMessageInput,
} from 'sierra-client/api/graphql/gql/graphql'
import { graphQueryFn } from 'sierra-client/api/hooks/use-graphql-query'
import { useCachedQuery, useTypedMutation } from 'sierra-client/state/api'
import {
  MessageType,
  ScenarioChatState,
  ScenarioCompletedReason,
} from 'sierra-client/views/v3-author/scenario/chat/state'
import { ScenarioFile } from 'sierra-client/views/v3-author/scenario/utils'
import { CourseId, CreateContentId } from 'sierra-domain/api/nano-id'
import { ScopedFileId } from 'sierra-domain/collaboration/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import {
  ScenarioFeedbackV2RequestReason,
  XRealtimeSelfPacedScenarioComplianceCheck,
  XRealtimeSelfPacedScenarioGetAssistantMessage,
  XRealtimeSelfPacedScenarioGetScenarioFeedbackV2,
  XRealtimeSelfPacedScenarioTerminationCheck,
} from 'sierra-domain/routes'
import { isEmptyArray, isNotDefined, toUppercase } from 'sierra-domain/utils'
import { useOnChanged } from 'sierra-ui/utils'

const createMessageContext = (ms: MessageType['context']): string => ms.map(m => m.value).join('\n')

const messageTypeToChatMessageType = (m: MessageType): ScenarioCardChatMessageInput => ({
  content: m.content,
  context: createMessageContext(m.context),
  sender: toUppercase(m.sender),
  sentAt: m.timestamp,
  index: m.index,
})

export type ChatInterface = {
  chatId: ScenarioCardChatId | undefined
  onAddMessage: (msg: string) => void
  revertLastMessage: () => void
  onAskForFirstMessage: () => void
  onAskForNextMessage: (allMsgs: Array<MessageType>) => Promise<void>
  chatState: ScenarioChatState
  messages: Array<MessageType>
  resetChat: () => void
  completeChat: (reason: Exclude<ScenarioCompletedReason, 'compliance-fail'>) => void
  importChat: (messages: Array<MessageType>) => void
}

const SaveFeedbackMutation = graphql(`
  mutation SaveFeedback($chatId: ScenarioCardChatId!, $feedback: ScenarioCardChatFeedbackInput!) {
    upsertScenarioCardChatFeedback(chatId: $chatId, feedback: $feedback)
  }
`)

const SaveChatMessagesMutation = graphql(`
  mutation SaveChatMessages($chatId: ScenarioCardChatId!, $messages: [ScenarioCardChatMessageInput!]!) {
    upsertScenarioCardChatMessages(chatId: $chatId, messages: $messages)
  }
`)

const InitiateChatMutation = graphql(`
  mutation InitiateChat($courseFileId: CourseFileIdInput!, $messages: [ScenarioCardChatMessageInput!]!) {
    initiateScenarioCardChat(courseFileId: $courseFileId, messages: $messages)
  }
`)

export type InitialChat = {
  chatId: ScenarioCardChatId
  messages: Array<MessageType>
}

export const useChat = ({
  previewMode = false,
  file,
  courseId,
  startupState = { type: 'init' },
  startupChat,
}: {
  previewMode?: boolean
  file: ScenarioFile
  courseId: CreateContentId
  startupState?: ScenarioChatState
  startupChat?: InitialChat
}): ChatInterface => {
  const [messages, setMessages] = useState<Array<MessageType>>(startupChat?.messages ?? [])
  const [chatState, setChatState] = useState<ScenarioChatState>(startupState)

  // ChatId is to keep track of the current chat. Empty means that the chat session has not been properly started yet
  const chatId = useRef<ScenarioCardChatId | undefined>(startupChat?.chatId)

  const saveChatMutation = useMutation({
    mutationFn: async (messages: Array<ScenarioCardChatMessageInput>) => {
      // If not chat id is set, initiate a new chat before saving. If we never need to initiate the chat for saving other stuff, break this out to its own function
      if (chatId.current === undefined) {
        const newChatId = await graphQueryFn(InitiateChatMutation, {
          courseFileId: { courseId, fileId: ScopedFileId.extractId(file.id) },
          messages: messages,
        })
        chatId.current = newChatId.initiateScenarioCardChat
      }

      return graphQueryFn(SaveChatMessagesMutation, {
        chatId: chatId.current,
        messages: messages,
      })
    },
  })

  useOnChanged((prev, next) => {
    // Do not allow any saves if we are in preview mode
    if (previewMode) {
      return
    }
    // Only start to save the chat after a specific number of messages
    if (messages.length < 2) {
      return
    }
    const chatToSave = messages.map(messageTypeToChatMessageType)

    // As soon as the assistant has answered the message, save the chat
    if (prev === 'waiting-for-assistant' && next === 'waiting-for-user-message') {
      return saveChatMutation.mutate(chatToSave)
    }

    // As soon as the chat is completed, save the chat
    if (next === 'completed' && prev !== undefined) {
      return saveChatMutation.mutate(chatToSave)
    }
  }, chatState.type)

  const getAssistantMessageMutation = useTypedMutation(
    XRealtimeSelfPacedScenarioGetAssistantMessage({ preview: previewMode }),
    {}
  )

  const terminationReq = useTypedMutation(XRealtimeSelfPacedScenarioTerminationCheck)
  const complianceReq = useTypedMutation(XRealtimeSelfPacedScenarioComplianceCheck)

  const resetChat = useCallback((): void => {
    terminationReq.reset()
    complianceReq.reset()
    setMessages([])
    chatId.current = undefined
    setChatState({ type: 'init' })
  }, [complianceReq, terminationReq])

  const onAskForNextMessage = useCallback(
    async (fullHistory: Array<MessageType>): Promise<void> => {
      setChatState({ type: 'waiting-for-assistant' })
      const userMessage = fullHistory.at(-1)
      const messageHistory = fullHistory.slice(0, -1)

      if (isNotDefined(userMessage)) {
        return
      }

      await Promise.all([
        terminationReq.mutateAsync({
          fileId: file.id,
          courseId,
          messageHistory: messageHistory,
          newMessage: userMessage,
        }),
        complianceReq.mutateAsync({
          fileId: file.id,
          courseId,
          messageHistory: messageHistory,
          newMessage: userMessage,
        }),
        getAssistantMessageMutation.mutateAsync({
          courseId,
          fileId: file.id,
          messageHistory: messageHistory,
          newMessage: userMessage,
        }),
      ])
        .then(([termination, compliance, { message: assistantMessage }]) => {
          const newHistory = fullHistory.concat({ ...assistantMessage, index: fullHistory.length })
          setMessages(newHistory)

          if (compliance.compliant === true && termination.terminate === false) {
            setChatState({ type: 'waiting-for-user-message' })
            return
          }

          if (compliance.compliant !== true) {
            setChatState({ type: 'completed', reason: 'compliance-fail', failReason: compliance.reason })
            return
          }

          // Show last message on termination
          if (termination.terminate === true) {
            setChatState({
              type: 'completed',
              reason: isEmptyArray(termination.goalsReached)
                ? 'self-termination'
                : 'goal-reached-termination',
            })
            return
          }
        })
        .catch(_ => setChatState({ type: 'completed', reason: 'error' }))
    },
    [terminationReq, file.id, courseId, complianceReq, getAssistantMessageMutation]
  )

  const onAskForFirstMessage = useCallback(async (): Promise<void> => {
    setChatState({ type: 'waiting-for-assistant' })
    await getAssistantMessageMutation.mutateAsync(
      {
        courseId,
        fileId: file.id,
        messageHistory: [],
      },
      {
        onSuccess: ({ message: assistantMessage }) => {
          setMessages([{ ...assistantMessage, index: 0 }])
        },
      }
    )

    setChatState({ type: 'waiting-for-user-message' })
  }, [getAssistantMessageMutation, courseId, file.id])

  const onAddMessage = useCallback(
    (msg: string): void => {
      const allMsgs = messages.concat({
        sender: 'user',
        content: msg,
        context: [],
        timestamp: new Date().toISOString(),
        index: messages.length,
      })
      setMessages(allMsgs)
      void onAskForNextMessage(allMsgs)
    },
    [messages, onAskForNextMessage]
  )

  useOnChanged((p, n) => {
    if (p !== 'init' && n === 'init') {
      void onAskForFirstMessage()
    }
  }, chatState.type)

  const revertLastMessage = useCallback((): void => {
    const lastMessage = messages.at(-1)
    if (isNotDefined(lastMessage)) {
      throw new Error('Can not revert, no messages left')
    }

    setMessages(current => {
      // Find the index of the last user message
      const lastUserIndex = current.map(m => m.sender).lastIndexOf('user')

      // If there's no user message, return the entire array unchanged
      if (lastUserIndex === -1) {
        return current
      }

      // Drop everything from the last user message onward
      // (This removes the user message at lastUserIndex and all subsequent messages.)
      return current.slice(0, lastUserIndex)
    })

    setChatState({ type: 'waiting-for-user-message' })
    complianceReq.reset()
  }, [complianceReq, messages])

  const importChat = useCallback(
    (msgs: Array<MessageType>): void => {
      setMessages(msgs)
      const lastMsg = msgs.at(-1)
      if (lastMsg?.sender === 'assistant') {
        setChatState({ type: 'waiting-for-user-message' })
      }
      if (lastMsg?.sender === 'user') {
        void onAskForNextMessage(msgs)
      }
    },
    [onAskForNextMessage]
  )

  return useMemo(
    () => ({
      chatId: chatId.current,
      onAddMessage,
      revertLastMessage,
      onAskForFirstMessage,
      onAskForNextMessage,
      chatState,
      messages,
      resetChat,
      completeChat: reason => setChatState({ type: 'completed', reason }),
      importChat,
    }),
    [
      chatState,
      importChat,
      messages,
      onAddMessage,
      onAskForFirstMessage,
      onAskForNextMessage,
      resetChat,
      revertLastMessage,
    ]
  )
}

// This hook is used to get feedback from the chat and save that feedback instantly
export const useChatFeedback = ({
  chatId,
  fileId,
  courseId,
  messageHistory,
  reason,
}: {
  chatId?: ScenarioCardChatId
  fileId: FileId
  courseId: CourseId
  messageHistory: MessageType[]
  overrideFeedback?: ScenarioCardChatFeedback
  reason: ScenarioFeedbackV2RequestReason
}): UseQueryResult<ScenarioCardChatFeedback, Error> => {
  const feedback = useCachedQuery(
    XRealtimeSelfPacedScenarioGetScenarioFeedbackV2,
    {
      fileId,
      courseId,
      messageHistory,
      reason,
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const saveFeedbackMutation = useMutation({
    mutationFn: ({
      chatId,
      feedback,
    }: {
      chatId: ScenarioCardChatId
      feedback: ScenarioCardChatFeedbackInput
    }) => {
      return graphQueryFn(SaveFeedbackMutation, {
        chatId: chatId,
        feedback: feedback,
      })
    },
  })

  useOnChanged(() => {
    if (isNotDefined(chatId)) {
      return
    }
    if (feedback.isSuccess) {
      saveFeedbackMutation.mutate({ chatId, feedback: feedback.data })
    }
  }, feedback.isSuccess)

  return feedback
}
