import { AnimatePresence, motion } from 'framer-motion'
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useInvalidateTaggableContentCache,
  useTaggableContent,
} from 'sierra-client/api/hooks/use-taggable-content'
import { getAssetContextFromTaggableContent } from 'sierra-client/components/util/asset-contex'
import { usePost } from 'sierra-client/hooks/use-post'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { resolveThemeColor } from 'sierra-client/hooks/use-themes'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { navigateToCreateContentId } from 'sierra-client/state/flexible-content/navigate'
import { FCC } from 'sierra-client/types'
import { BlockDefinition } from 'sierra-client/views/block-types'
import { useCreatePageContextSafe } from 'sierra-client/views/flexible-content/create-page-context'
import {
  embedTypeToIconId,
  fileTypeToIconId,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/common'
import { FileIconProps } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { useThemeForFile } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { useSelfPacedFilesSafe } from 'sierra-client/views/self-paced/files-provider'
import { useIsUniquelySelected } from 'sierra-client/views/v3-author/hooks'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { Card, useTagsContextSafe } from 'sierra-client/views/v3-author/tags/tags-menu'
import { withTags } from 'sierra-client/views/v3-author/tags/with-tags'
import { Linkable, detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { editUrl } from 'sierra-domain/api/editable-content'
import { ContentKind } from 'sierra-domain/api/learn'
import { CourseId, NanoId12, PathId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Entity } from 'sierra-domain/entity'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { FileData } from 'sierra-domain/flexible-content/types'
import { XRealtimeCollaborationRequestAccess } from 'sierra-domain/routes'
import { Tag as TagElementType } from 'sierra-domain/v3-author'
import { color } from 'sierra-ui/color'
import { ColorName } from 'sierra-ui/color/types'
import { Icon, IconId } from 'sierra-ui/components'
import { MUIPopper } from 'sierra-ui/mui'
import { Button, Skeleton, Text, View } from 'sierra-ui/primitives'
import { LightTokenProvider, palette, zIndex } from 'sierra-ui/theming'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { Editor, Range, Transforms } from 'slate'
import { useFocused, useSelected, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const TagLink = styled.a<{ focused: boolean }>`
  position: relative;
  padding: 0 0.1em;
  margin: 0 -0.1em;
  border-radius: 4px;
  font-weight: 500;
  transition: 100ms;
  ${p =>
    p.focused &&
    css`
      background-color: ${color(palette.primitives.black).opacity(0.05).toString()};
      box-shadow: 0 0 0 2px #b4d5ff;
    `}
  cursor: pointer;
`

const ImageContainer = styled.span`
  display: inline-flex;
  position: relative;
  top: 0.15em;
  height: 1em;
  width: 1em;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 0.25em;

  & img {
    border-radius: 0.2em;
    width: 100%;
    object-fit: cover;
  }
`

const NodeIcon = styled(Icon)<{
  fileTheme?: Theme
  $background?: string | undefined
  color?: ColorName
  $componentSize?: string
}>`
  position: relative;
  bottom: 0.1em;
  height: 1em;
  width: 1em;
  margin-top: -10px;
  margin-bottom: -10px;
  border-radius: 0.2em;
  margin-right: 0.25em;
  border: 1px solid ${color(palette.primitives.black).opacity(0.1).toString()};

  ${p => {
    const theme: Theme = p.fileTheme ?? p.theme
    return css`
      background-color: ${resolveThemeColor(theme.home.backgroundColor)};
      color: ${resolveThemeColor(p.color ?? theme.home.textColor)};
    `
  }}
  svg {
    font-size: 0.5em;
  }

  ${p =>
    p.$background !== undefined &&
    css`
      background-image: url('${p.$background}');
      background-size: cover;
    `}
`

const CardImage = ({ file, size }: { file: FileIconProps; size: string }): JSX.Element => {
  const fileTheme = useThemeForFile(file)

  const fileBackground = useResolveAsset({
    image: file.backgroundImage,
    assetContext: { type: 'tag' },
    size: 'thumbnail',
  })

  const getFileIcon = (fileData: FileData): IconId => {
    if (fileData.type === 'embed' && fileData.urlType !== undefined)
      return embedTypeToIconId(fileData.urlType)
    return fileTypeToIconId(fileData.type)
  }

  return (
    <NodeIcon
      fileTheme={fileTheme}
      iconId={getFileIcon(file.data)}
      size={'font-size'}
      $background={fileBackground}
      $componentSize={size}
    />
  )
}

const ToolbarInner = styled(View)`
  border: 1px solid ${palette.grey[2]};
  border-top: none;
  border-radius: 0 0 12px 12px;
  width: 302px;
  background-color: ${palette.primitives.white};
`

const RaisedPopper = styled(MUIPopper)`
  z-index: ${zIndex.TOOLBAR};
  background-color: transparent;
  border-radius: 12px;
`

const GoToCardButton = styled.button`
  width: 100%;
  height: 2.25rem;
  background-color: ${palette.primitives.black};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const TitleImage = styled.div<{ url: string }>`
  height: 104px;
  width: 302px;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url(${p => p.url});
  background-position: center;
  background-size: cover;
`
const Header = styled.header`
  position: relative;
  display: flex;
`

const CourseTitle = styled(Text)`
  position: absolute;
  left: 24px;
  bottom: 12px;
  line-height: 1.2;
  width: 80%;
  letter-spacing: 0;
`

const StandardLetterSpacingText = styled(Text)`
  letter-spacing: 0;
`

const MotionColumnWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  margin-bottom: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`

const MarginText = styled(Text)<{ readOnly: boolean }>`
  ${p => !p.readOnly && 'margin-bottom: 10px;'}
`

const PrivateContentPopup: React.FC<{
  contentId: NanoId12
  readOnly: boolean
}> = ({ readOnly, contentId }) => {
  const [accessRequested, setAccessRequested] = useState(false)
  const editor = useSlateStatic()
  const { t } = useTranslation()
  const { postWithUserErrorException } = usePost()
  return (
    <AnimatePresence>
      <MotionColumnWrapper
        key='inline-toolbar'
        initial={{ y: 8, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
      >
        <ToolbarInner direction='column' padding='24'>
          <MarginText color='black' readOnly={readOnly} align='center'>
            {t('tags.no-access')}
          </MarginText>
          {!readOnly && (
            <Button
              grow
              onClick={e => {
                e.preventDefault()
                const { selection } = editor
                const beforeSelection = selection !== null ? Editor.before(editor, selection) : undefined
                if (selection && beforeSelection && Range.isCollapsed(selection)) {
                  Transforms.insertText(editor, '', {
                    at: beforeSelection,
                  })
                  Transforms.removeNodes(editor, { at: selection.anchor.path.slice(0, -1) })
                }
              }}
              variant='ghost'
              icon='unlink'
            >
              {t('tags.unlink')}
            </Button>
          )}
          <Button
            grow
            icon={accessRequested ? 'checkmark' : undefined}
            variant={accessRequested ? 'success' : 'primary'}
            onClick={async () => {
              if (accessRequested) return
              await postWithUserErrorException(XRealtimeCollaborationRequestAccess, { contentId })
              setAccessRequested(true)
            }}
          >
            {accessRequested ? t('tags.requested-access') : t('tags.request-access')}
          </Button>
        </ToolbarInner>
      </MotionColumnWrapper>
    </AnimatePresence>
  )
}

const ContentPreviewLink: React.FC<{
  readOnly: boolean
  content: {
    image?: ImageUnion
    title: string
    id: string
    type: ContentKind
    access: 'edit' | 'view'
  }
  assetContext: AssetContext
}> = ({ readOnly, content, assetContext }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()

  const imageSrc = useResolveAsset({
    assetContext,
    image: content.image,
    size: 'default',
  })

  return (
    <AnimatePresence>
      <LightTokenProvider>
        <MotionColumnWrapper
          key='inline-toolbar'
          initial={{ y: 8, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
        >
          <Header>
            <TitleImage url={imageSrc} />
            <CourseTitle bold color='white' size='large'>
              {content.title}
            </CourseTitle>
          </Header>
          <ToolbarInner direction='column' padding='24'>
            {!readOnly && (
              <Button
                grow
                onClick={e => {
                  e.preventDefault()
                  const { selection } = editor
                  const beforeSelection = selection !== null ? Editor.before(editor, selection) : undefined
                  if (selection && beforeSelection && Range.isCollapsed(selection)) {
                    Transforms.insertText(editor, content.title, {
                      at: beforeSelection,
                    })
                    Transforms.removeNodes(editor, { at: selection.anchor.path.slice(0, -1) })
                  }
                }}
                variant='ghost'
                icon='unlink'
              >
                {t('tags.unlink')}
              </Button>
            )}
            <Button
              target='_blank'
              rel='noopener noreferrer'
              icon='view'
              href={detailsUrl(content as Linkable)}
              variant='primary'
              grow
            >
              {t('dictionary.view')}
            </Button>

            {content.access === 'edit' && (
              <Button
                target='_blank'
                rel='noopener noreferrer'
                icon='edit'
                href={editUrl(
                  content.type as 'path' | 'native:self-paced' | 'native:live',
                  content.id as PathId | CourseId
                )}
                variant='secondary'
                grow
              >
                {t('dictionary.edit')}
              </Button>
            )}
          </ToolbarInner>
        </MotionColumnWrapper>
      </LightTokenProvider>
    </AnimatePresence>
  )
}

const CardPreviewLink: React.FC<{
  readOnly: boolean
  file: Card
  navigateToFile: () => void
}> = ({ readOnly, file, navigateToFile }) => {
  const editor = useSlateStatic()
  const { t } = useTranslation()

  return (
    <AnimatePresence>
      <MotionColumnWrapper
        key='inline-toolbar'
        initial={{ y: 8, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2, ease: [0.25, 0.1, 0.25, 1] }}
      >
        <ToolbarInner direction='column' padding='24'>
          {!readOnly && (
            <Button
              grow
              onClick={e => {
                e.preventDefault()
                const { selection } = editor
                const beforeSelection = selection !== null ? Editor.before(editor, selection) : undefined
                if (selection && beforeSelection && Range.isCollapsed(selection)) {
                  Transforms.insertText(editor, file.title ?? '', {
                    at: beforeSelection,
                  })
                  Transforms.removeNodes(editor, { at: selection.anchor.path.slice(0, -1) })
                }
              }}
              variant='ghost'
              icon='unlink'
            >
              {t('tags.unlink')}
            </Button>
          )}
          <GoToCardButton onClick={() => navigateToFile()}>
            <StandardLetterSpacingText size='regular' bold color='white'>
              {t('tags.go-to-card')}
            </StandardLetterSpacingText>
          </GoToCardButton>
        </ToolbarInner>
      </MotionColumnWrapper>
    </AnimatePresence>
  )
}

const PreviewLinkWrapper: FCC<{
  readOnly: boolean
  elementId: string
  anchorEl: HTMLAnchorElement
  previewOpen: boolean | undefined
}> = ({ readOnly, elementId, anchorEl, children, previewOpen }) => {
  const isOnlySelectedElement = useIsUniquelySelected({ nodeId: elementId })

  if (readOnly && previewOpen !== true) return null
  if (!isOnlySelectedElement && previewOpen !== true) return null
  return (
    <RaisedPopper anchorEl={anchorEl} placement='top' open>
      {children}
    </RaisedPopper>
  )
}

const RelativeSpan = styled.span`
  position: relative;
`

const InlineSkeleton = styled(Skeleton)`
  color: transparent;
  display: inline;
  width: 100px;
`

export const useNavigateToFile = (): ((fileId: FileId) => void) => {
  const flexibleContentFiles = useSelfPacedFilesSafe()
  const createPageContext = useCreatePageContextSafe()

  const navigate = useCallback(
    (fileId: FileId) => {
      if (flexibleContentFiles !== undefined) {
        flexibleContentFiles.goTo(fileId)
      } else if (createPageContext !== undefined) {
        void navigateToCreateContentId({
          scopedCreateContentId: createPageContext.scopedCreateContentId,
          nodeId: fileId,
        })
      }
    },
    [createPageContext, flexibleContentFiles]
  )
  return navigate
}

const CardTagInner: React.FC<{
  readOnly: boolean
  element: Entity<TagElementType>
  children?: React.ReactNode
  previewOpen: boolean | undefined
}> = ({ readOnly, children, element, previewOpen }) => {
  if (element.contentType !== 'card') throw Error('wrong')

  const { t } = useTranslation()

  const anchorRef = useRef<HTMLAnchorElement | null>(null)
  const selected = useSelected()
  const focused = useFocused()
  const navigateToFile = useNavigateToFile()

  const safeTagsContext = useTagsContextSafe()
  const allContent = safeTagsContext?.allContent
  const cards = allContent?.flatMap(it => (it.type === 'cards' ? it.content : []))
  const file = cards?.find(it => it.id === element.contentId)

  if (allContent === undefined)
    return (
      <>
        {
          <TagLink focused>
            {'Card'} {children}
          </TagLink>
        }
      </>
    )

  return (
    <>
      {file === undefined ? (
        <TagLink focused>
          {t('tags.inaccessible-card')} {children}
        </TagLink>
      ) : (
        <>
          {anchorRef.current !== null && !readOnly && (
            <PreviewLinkWrapper
              previewOpen={previewOpen}
              anchorEl={anchorRef.current}
              readOnly={readOnly}
              elementId={element.id}
            >
              <CardPreviewLink
                navigateToFile={() => navigateToFile(file.id)}
                readOnly={readOnly}
                file={file}
              />
            </PreviewLinkWrapper>
          )}

          <TagLink
            href={undefined}
            onClick={() => {
              if (readOnly) navigateToFile(file.id)
            }}
            focused={focused && selected}
            target='_blank'
            contentEditable={false}
            ref={anchorRef}
          >
            <CardImage size={'1em'} file={file} />
            {file.title}
            {children}
          </TagLink>
        </>
      )}
    </>
  )
}

const LockedContentIconWrapper = styled.span`
  position: relative;
  top: 0.15em;
  opacity: 0.5;
  margin-right: 4px;
`

const ContentTagInner: React.FC<{
  readOnly: boolean
  element: Entity<TagElementType>
  children?: React.ReactNode
  previewOpen: boolean | undefined
}> = ({ readOnly, children, element, previewOpen }) => {
  const allContent = useTaggableContent()
  const invalidateTaggableContentCache = useInvalidateTaggableContentCache()
  const { t } = useTranslation()

  const anchorRef = useRef<HTMLAnchorElement | null>(null)
  const selected = useSelected()
  const focused = useFocused()

  const content = useMemo(
    () =>
      allContent !== undefined
        ? allContent
            .map(({ image, title, id, type, access }) => ({ image, title, id, type, access }))
            .find(it => it.id === element.contentId)
        : undefined,
    [allContent, element]
  )

  // in case content is not found try invalidating cache
  useEffect(() => {
    if (content === undefined) {
      void invalidateTaggableContentCache()
    }
  }, [content, invalidateTaggableContentCache])

  const assetContext = useMemo(
    () =>
      content !== undefined ? getAssetContextFromTaggableContent(content) : { type: 'unknown' as const },
    [content]
  )

  const imageSrc = useResolveAsset({
    assetContext,
    image: content?.image,
    size: 'default',
  })

  if (allContent === undefined) {
    return (
      <InlineSkeleton $radius={'4px'}>
        <a>This content is loading...{children}</a>
      </InlineSkeleton>
    )
  }

  return (
    <>
      {content === undefined ? (
        <>
          {anchorRef.current !== null && (
            <PreviewLinkWrapper
              previewOpen={previewOpen}
              anchorEl={anchorRef.current}
              readOnly={readOnly}
              elementId={element.id}
            >
              <PrivateContentPopup contentId={element.contentId as NanoId12} readOnly={readOnly} />
            </PreviewLinkWrapper>
          )}
          <TagLink focused contentEditable={false} ref={anchorRef}>
            <LockedContentIconWrapper>
              <Icon size='font-size' iconId='locked' />
            </LockedContentIconWrapper>
            {t('tags.private-content')} {children}
          </TagLink>
        </>
      ) : (
        <>
          {anchorRef.current !== null && (
            <PreviewLinkWrapper
              previewOpen={previewOpen}
              anchorEl={anchorRef.current}
              readOnly={readOnly}
              elementId={element.id}
            >
              <ContentPreviewLink readOnly={readOnly} content={content} assetContext={assetContext} />
            </PreviewLinkWrapper>
          )}

          <TagLink
            href={detailsUrl({ id: content.id, type: content.type } as Linkable)}
            focused={focused && selected}
            target='_blank'
            contentEditable={false}
            ref={anchorRef}
          >
            <ImageContainer>
              <img src={imageSrc} />
            </ImageContainer>

            {content.title}
            {children}
          </TagLink>
        </>
      )}
    </>
  )
}

export const Tag: BlockDefinition = {
  Wrapper: forwardRef<HTMLAnchorElement, SlateWrapperProps>(
    ({ attributes, children, element, readOnly, ...props }, ref) => {
      assertElementType('tag', element)

      const [previewOpen, setPreviewOpen] = useState<boolean | undefined>(undefined)
      const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | undefined>(undefined)

      const setPreview = useCallback(
        (isOpen: boolean) => {
          if (!readOnly) return

          if (isOpen === true) setDelayHandler(setTimeout(() => setPreviewOpen(true), 250))

          if (isOpen === false) {
            clearTimeout(delayHandler)
            setPreviewOpen(false)
          }
        },
        [delayHandler, readOnly]
      )

      return (
        <RelativeSpan
          onMouseEnter={() => setPreview(true)}
          onMouseLeave={() => setPreview(false)}
          {...attributes}
          {...props}
          ref={ref}
        >
          {element.contentType === 'card' ? (
            <CardTagInner previewOpen={previewOpen} readOnly={readOnly} element={element}>
              {children}
            </CardTagInner>
          ) : (
            <ContentTagInner previewOpen={previewOpen} readOnly={readOnly} element={element}>
              {children}
            </ContentTagInner>
          )}
        </RelativeSpan>
      )
    }
  ),
  plugin: withTags,
}
