import React from 'react'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Empty } from 'sierra-client/views/manage/components/common'
import { SmallTableWrapper } from 'sierra-client/views/manage/components/small-table-wrapper'
import {
  ProgramGroupsTable,
  ProgramGroupsTableProps,
} from 'sierra-client/views/manage/users/components/program-group-table'
import {
  UserContentTable,
  UserContentTableProps,
} from 'sierra-client/views/manage/users/components/user-content-table'
import {
  UserGroupsTable,
  UserGroupsTableProps,
} from 'sierra-client/views/manage/users/components/user-group-table'
import {
  UserLiveSessionsTable,
  UserLiveSessionsTableProps,
} from 'sierra-client/views/manage/users/components/user-live-session-table'
import {
  UserSelfStartTable,
  UserSelfStartTableProps,
} from 'sierra-client/views/manage/users/components/user-self-started-table'

export const UserContentSection: React.FC<UserContentTableProps> = ({
  setUserAction,
  courses,
  paths,
  userId,
  liveSessions,
  userType,
  refetch,
}) => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  if (courses.length === 0 && paths.length === 0) {
    return canEditAssignments ? (
      <Empty
        title={t('manage.users.no-courses.title')}
        body={t('manage.users.no-courses.body')}
        actionLabel={t('admin.organization.paths.enroll')}
        onClick={() => setUserAction({ modal: 'assign-content' })}
      />
    ) : (
      <Empty title={t('manage.courses.no-results')} body={''} hideIcon />
    )
  }
  return (
    <UserContentTable
      courses={courses}
      paths={paths}
      userId={userId}
      liveSessions={liveSessions}
      setUserAction={setUserAction}
      userType={userType}
      refetch={refetch}
    />
  )
}

export const UserSelfStartSection: React.FC<UserSelfStartTableProps> = ({ coursesSelfStart }) => {
  const { t } = useTranslation()
  return coursesSelfStart.length === 0 ? (
    <Empty title={t('manage.users.no-self-started-courses.title')} body={''} hideIcon />
  ) : (
    <UserSelfStartTable coursesSelfStart={coursesSelfStart} />
  )
}

export const ProgramGroupsSection: React.FC<ProgramGroupsTableProps> = ({
  programMemberships: programMemberships,
  setUserAction,
  userId,
  refetch,
}) => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  if (programMemberships.length === 0) {
    return (
      <>
        {canEditAssignments ? (
          <Empty
            title={t('manage.users.no-programs.title')}
            body={t('manage.users.no-programs.body')}
            actionLabel={t('manage.users.no-programs.title')}
            onClick={() => setUserAction({ modal: 'assign-programs' })}
          />
        ) : (
          <Empty title={t('manage.programs.no-results')} body={''} hideIcon />
        )}
      </>
    )
  }
  return (
    <ProgramGroupsTable
      programMemberships={programMemberships}
      setUserAction={setUserAction}
      userId={userId}
      refetch={refetch}
    />
  )
}

export const UserGroupsSection: React.FC<UserGroupsTableProps> = ({
  userGroupMemberships: userGroupMemberships,
  setUserAction,
}) => {
  const { t } = useTranslation()
  return userGroupMemberships.length === 0 ? (
    <Empty
      iconId='user--group'
      title={t('manage.users.no-groups.title')}
      body={t('manage.users.no-groups.body')}
      actionLabel={t('admin.organization.users.add-to-group')}
      onClick={() => setUserAction({ modal: 'assign-groups' })}
    />
  ) : (
    <UserGroupsTable userGroupMemberships={userGroupMemberships} setUserAction={setUserAction} />
  )
}

export const UserLiveSessionSection: React.FC<UserLiveSessionsTableProps> = ({
  liveSessions,
  currentUserId,
  setUserAction,
}) => {
  const { t } = useTranslation()
  const hasEditAssignmentPermission = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  if (liveSessions.length === 0) {
    return (
      <SmallTableWrapper>
        {hasEditAssignmentPermission ? (
          <Empty
            title={t('manage.session.no-session.title')}
            body={t('manage.session.no-session.body')}
            actionLabel={t('manage.session.no-session.title')}
            onClick={() => setUserAction({ modal: 'assign-content' })}
          />
        ) : (
          <Empty title={t('manage.sessions.no-results')} body={''} hideIcon />
        )}
      </SmallTableWrapper>
    )
  }
  return (
    <UserLiveSessionsTable
      liveSessions={liveSessions}
      currentUserId={currentUserId}
      setUserAction={setUserAction}
    />
  )
}
