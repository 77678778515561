import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Timepicker } from 'sierra-client/components/common/pickers'
import { TimezonePicker } from 'sierra-client/components/common/pickers/timezone-picker'
import { useToggle } from 'sierra-client/hooks/use-toggle'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TextButton } from 'sierra-client/views/manage/event-groups/components/shared'
import {
  eventScheduleValid,
  getEventScheduleEnd,
  getEventScheduleStart,
  getEventScheduleTimeZone,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { CalendarEventSchedule } from 'sierra-client/views/manage/event-groups/types'
import { isDefined } from 'sierra-domain/utils'
import { FormElement, Icon, InputDatePicker } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'
import { dotSeparator } from 'sierra-ui/utils'
import styled from 'styled-components'

const DotContainer = styled(Text).attrs({
  color: 'foreground/muted',
  bold: true,
})`
  margin-inline: -4px;
  font-size: 0.75rem;
  align-self: flex-start;
`

const ErrorText = styled(Text).attrs({
  color: 'destructive/background',
  bold: true,
})`
  font-size: 0.75rem;
`

const DateTimeInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: stretch;
  gap: 8px;
`

const TimeZoneInputContainer = styled.div`
  width: 50%;
`

const scheduleInDifferentTimezone = (schedule: CalendarEventSchedule, userTimezone: string): boolean => {
  return schedule.type === 'with-time' && schedule.timeZone !== userTimezone
}

export const EventScheduleForm: React.FC<{
  schedule: CalendarEventSchedule
  updateSchedule: (newSchedule: CalendarEventSchedule) => void
  hideTimeZoneInput?: boolean
  disabled?: boolean
  timezone: string
}> = ({ schedule, updateSchedule, hideTimeZoneInput = false, disabled, timezone }) => {
  const { t } = useTranslation()

  const isMultiDayEvent = !getEventScheduleStart(schedule).hasSame(getEventScheduleEnd(schedule), 'day')
  const hasDifferentTimeZone = isDefined(timezone) ? scheduleInDifferentTimezone(schedule, timezone) : false
  const isAllDayEvent = schedule.type === 'all-day'

  // if the event already has advnaced options enabled, default to showing the advanced ui
  const [showAdvancedTimeInput, { on: enableAdvancedTimeInput }] = useToggle(
    isMultiDayEvent || hasDifferentTimeZone || isAllDayEvent
  )

  const [scheduleHasError, setScheduleHasError] = useState(!eventScheduleValid(schedule))

  useEffect(() => {
    setScheduleHasError(!eventScheduleValid(schedule))
  }, [schedule])

  const startTime = getEventScheduleStart(schedule)
  const endTime = getEventScheduleEnd(schedule)
  const scheduleTimezone = getEventScheduleTimeZone(schedule)

  const setStartAndEndDate = (date: DateTime): void => {
    if (schedule.type === 'all-day') {
      updateSchedule({
        ...schedule,
        startDate: date,
        endDate: date,
      })
    }
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        startTime: date.set({
          hour: schedule.startTime.hour,
          minute: schedule.startTime.minute,
        }),
        endTime: date.set({
          hour: schedule.endTime.hour,
          minute: schedule.endTime.minute,
        }),
      })
    }
  }

  const updateStartDate = (date: DateTime): void => {
    const shouldMatchStartAndEndDate = getEventScheduleEnd(schedule).startOf('day') < date.startOf('day')

    if (schedule.type === 'all-day') {
      updateSchedule({
        ...schedule,
        startDate: date,
        endDate: shouldMatchStartAndEndDate ? date : schedule.endDate,
      })
    }
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        startTime: date.set({
          hour: schedule.startTime.hour,
          minute: schedule.startTime.minute,
        }),
        endTime: shouldMatchStartAndEndDate
          ? date.set({
              hour: schedule.endTime.hour,
              minute: schedule.endTime.minute,
            })
          : schedule.endTime,
      })
    }
  }

  const updateEndDate = (date: DateTime): void => {
    const shouldMatchStartAndEndDate = date.startOf('day') < getEventScheduleStart(schedule).startOf('day')

    if (schedule.type === 'all-day') {
      updateSchedule({
        ...schedule,
        endDate: date,
        startDate: shouldMatchStartAndEndDate ? date : schedule.startDate,
      })
    }
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        endTime: date.set({
          hour: schedule.endTime.hour,
          minute: schedule.endTime.minute,
        }),
        startTime: shouldMatchStartAndEndDate
          ? date.set({
              hour: schedule.endTime.hour,
              minute: schedule.endTime.minute,
            })
          : schedule.startTime,
      })
    }
  }

  const updateStartTime = (dateTime: DateTime): void => {
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        startTime: schedule.startTime.set({
          hour: dateTime.hour,
          minute: dateTime.minute,
        }),
      })
    }
  }

  const updateEndTime = (dateTime: DateTime): void => {
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        endTime: schedule.endTime.set({
          hour: dateTime.hour,
          minute: dateTime.minute,
        }),
      })
    }
  }

  const updateTimeZone = (timeZone?: string): void => {
    if (schedule.type === 'with-time') {
      updateSchedule({
        ...schedule,
        timeZone: timeZone ?? DateTime.local().zoneName,
        startTime:
          timeZone === undefined
            ? schedule.startTime.setZone('local', { keepLocalTime: true })
            : schedule.startTime.setZone(timeZone, { keepLocalTime: true }),
        endTime:
          timeZone === undefined
            ? schedule.endTime.setZone('local', { keepLocalTime: true })
            : schedule.endTime.setZone(timeZone, { keepLocalTime: true }),
      })
    }
  }

  const toggleIsAllDay = (): void => {
    if (schedule.type === 'with-time') {
      updateSchedule({
        type: 'all-day',
        startDate: schedule.startTime.startOf('day'),
        endDate: schedule.endTime.startOf('day'),
      })
    }
    if (schedule.type === 'all-day') {
      updateSchedule({
        type: 'with-time',
        startTime: schedule.startDate,
        endTime: schedule.endDate,
        timeZone: timezone,
      })
    }
  }

  return (
    <FormElement grow={false} label={t('dictionary.date-and-time')}>
      <DateTimeInputContainer>
        <InputDatePicker
          disabled={disabled}
          disablePastDates
          value={startTime}
          onChange={dateTime => {
            if (!isDefined(dateTime)) return
            if (showAdvancedTimeInput) {
              updateStartDate(dateTime)
            } else {
              setStartAndEndDate(dateTime)
            }
          }}
        />
        {schedule.type === 'with-time' && (
          <View>
            <Timepicker
              id={'event-group-start-time'}
              value={startTime}
              onChange={dateTime => updateStartTime(dateTime)}
              disabled={disabled}
            />
            -
            <Timepicker
              id={'event-group-end-time'}
              value={endTime}
              onChange={dateTime => updateEndTime(dateTime)}
              error={scheduleHasError}
              disabled={disabled}
            />
            <Icon iconId='time' size='size-16' />
          </View>
        )}
        {showAdvancedTimeInput && (
          <InputDatePicker
            disablePastDates
            value={endTime}
            onChange={dateTime => isDefined(dateTime) && updateEndDate(dateTime)}
            disabled={disabled}
          />
        )}
      </DateTimeInputContainer>

      <View>
        {!showAdvancedTimeInput && (
          <>
            <TextButton
              onClick={() => {
                enableAdvancedTimeInput()
                toggleIsAllDay()
              }}
              $disabled={disabled}
            >
              {schedule.type === 'all-day' ? t('dictionary.with-time') : t('dictionary.all-day')}
            </TextButton>
            <DotContainer>{dotSeparator}</DotContainer>
            <TextButton onClick={enableAdvancedTimeInput} $disabled={disabled}>
              {isMultiDayEvent ? t('event-groups.same-end-date') : t('event-groups.different-end-date')}
            </TextButton>
            {schedule.type === 'with-time' && !hideTimeZoneInput && (
              <>
                <DotContainer>{dotSeparator}</DotContainer>
                <TextButton $disabled={disabled} onClick={enableAdvancedTimeInput}>
                  {t('event-groups.timezone')}
                </TextButton>
              </>
            )}
          </>
        )}
        {scheduleHasError && (
          <>
            <DotContainer>{dotSeparator}</DotContainer>
            <ErrorText>{t('event-groups.invalid-schedule')}</ErrorText>
          </>
        )}
      </View>
      {showAdvancedTimeInput && (
        <View direction='column'>
          <View>
            <Checkbox
              checked={isAllDayEvent}
              onCheckedChange={() => {
                toggleIsAllDay()
              }}
              disabled={disabled}
            />
            <Text size='small' bold>
              {t('dictionary.all-day')}
            </Text>
          </View>
          {!hideTimeZoneInput && (
            <TimeZoneInputContainer>
              <TimezonePicker
                disabled={isAllDayEvent}
                timezoneId={scheduleTimezone}
                onChange={updateTimeZone}
              />
            </TimeZoneInputContainer>
          )}
        </View>
      )}
    </FormElement>
  )
}
