import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  TaggableContent,
  useInvalidateTaggableContentCache,
  useTaggableContent,
} from 'sierra-client/api/hooks/use-taggable-content'
import { useLiveSessionContext } from 'sierra-client/components/liveV2/contexts/live-session-data'
import { resolveIndex } from 'sierra-client/components/shortcut-menu/resolve-index'
import { getAssetContextFromTaggableContent } from 'sierra-client/components/util/asset-contex'
import { useOnMount } from 'sierra-client/hooks/use-on-mount'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useStableFunction } from 'sierra-client/hooks/use-stable-function'
import { useGetFileTitle } from 'sierra-client/state/flexible-content/file-title'
import * as flexSelectors from 'sierra-client/state/flexible-content/selectors'
import { selectFlexibleContent } from 'sierra-client/state/flexible-content/selectors'
import { useSelector } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import {
  useCreatePageContextSafe,
  useCreatePageYDocContext,
} from 'sierra-client/views/flexible-content/create-page-context'
import { FileIcon } from 'sierra-client/views/flexible-content/editor/content-sidebar/icons'
import { useFlexibleContentYDoc } from 'sierra-client/views/flexible-content/polaris-editor-provider/use-flexible-content-ydoc'
import { useSelfPacedBigContext } from 'sierra-client/views/flexible-content/self-paced-big-context'
import { useSelfPacedFilesSafe } from 'sierra-client/views/self-paced/files-provider'
import { EditorMode } from 'sierra-client/views/v3-author/slate'
import { CreateContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { File } from 'sierra-domain/flexible-content/types'
import { isDefined } from 'sierra-domain/utils'
import { EditorKeyboardEvent } from 'sierra-domain/v3-author'
import { createTag } from 'sierra-domain/v3-author/create-blocks'
import { MUIPopper } from 'sierra-ui/mui'
import { Text, View } from 'sierra-ui/primitives'
import { palette, zIndex } from 'sierra-ui/theming'
import { BaseRange, Editor, Range, Transforms } from 'slate'
import { ReactEditor, useSlateStatic } from 'slate-react'
import styled from 'styled-components'

export type Card = { contentType: 'card'; icon: JSX.Element } & File

type ContentList = [
  { type: 'cards'; content: Card[] },
  {
    type: 'content'
    content: TaggableContent[] | undefined
  },
]

type TagsContext = {
  updateTags: () => void
  index: number
  setIndex: (index: number) => void
  search: string | undefined
  targetRect: DOMRect | undefined
  targetRange: BaseRange | undefined
  allContent: ContentList
  allContentFiltered: ContentList
  insertTagAtCurrentIndex: () => void
  tagsListOpen: boolean
  dismissTagsList: () => void
}

type TagsList = {
  updateTags: () => void
  index: number
  setIndex: (index: number) => void
  search: string | undefined
  targetRect: DOMRect | undefined
  targetRange: BaseRange | undefined
  tagsListDismissed: boolean
  dismissTagsList: () => void
}

export const TagsProvider = React.createContext<TagsContext | undefined>(undefined)

const LiveTagsProvider: FCC<{ value: TagsList }> = ({ value, children }) => {
  const [cardsInCourse, setCardsInCourse] = useState<Card[]>([])

  const liveSession = useLiveSessionContext()
  const contentId = liveSession.data.flexibleContentId
  const flexibleContent = useSelector(state => selectFlexibleContent(state, contentId))
  const flexibleContentNodeMap = flexibleContent?.nodeMap

  const { yDoc } = useFlexibleContentYDoc()
  const getFileTitle = useGetFileTitle(yDoc)

  const assetContext: AssetContext = useMemo(() => {
    return {
      type: 'course' as const,
      courseId: liveSession.data.flexibleContentId,
    }
  }, [liveSession.data.flexibleContentId])

  useEffect(() => {
    const files = Object.entries(flexibleContentNodeMap ?? {})
      .filter(it => it[1]?.type === 'file')
      .map(([fileId, data]) => ({ fileId, data }) as { fileId: string; data: File })
    const cards = files.map(file => {
      //live
      return {
        icon: <FileIcon size='small' file={file.data} assetContext={assetContext} />,
        contentType: 'card' as const,
        ...file.data,
        title: getFileTitle(file.data),
      }
    })

    setCardsInCourse(cards)
  }, [flexibleContentNodeMap, getFileTitle, value.search, yDoc, assetContext])

  const allCourseContent = useTaggableContent()

  const content = useMemo((): TagsContext => {
    const allContent: TagsContext['allContent'] = [
      { type: 'cards', content: cardsInCourse },
      { type: 'content', content: allCourseContent },
    ]
    return {
      ...value,
      setIndex: () => {},
      dismissTagsList: () => {},
      allContent,
      allContentFiltered: [
        { type: 'cards', content: [] },
        {
          type: 'content',
          content: undefined,
        },
      ],
      insertTagAtCurrentIndex: (): void => {},
      tagsListOpen: false,
    }
  }, [allCourseContent, cardsInCourse, value])

  return <TagsProvider.Provider value={content}>{children}</TagsProvider.Provider>
}

const LearnerTagsProvider: FCC<{ value: TagsList; flexibleContentId: CreateContentId }> = ({
  value,
  children,
  flexibleContentId,
}) => {
  const [cardsInCourse, setCardsInCourse] = useState<Card[]>([])

  const flexibleContentNodes = useSelector(state =>
    flexSelectors.selectFlexibleContentNodes(state, flexibleContentId)
  )

  const { slateDocumentMap } = useSelfPacedBigContext()
  const getFileTitle = useGetFileTitle(slateDocumentMap)

  const assetContext: AssetContext = useMemo(() => {
    return {
      type: 'course' as const,
      courseId: flexibleContentId,
    }
  }, [flexibleContentId])

  useEffect(() => {
    const files = Object.entries(flexibleContentNodes ?? {})
      .filter(it => it[1]?.type === 'file')
      .map(([fileId, data]) => ({ fileId, data }) as { fileId: string; data: File })

    const cards = files.map(file => {
      return {
        icon: <FileIcon size='small' file={file.data} assetContext={assetContext} />,
        contentType: 'card' as const,
        ...file.data,
        title: getFileTitle(file.data),
      }
    })

    setCardsInCourse(cards)
  }, [flexibleContentNodes, getFileTitle, slateDocumentMap, assetContext])

  const content: TagsContext = useMemo(() => {
    const allContent: TagsContext['allContent'] = [
      { type: 'cards', content: cardsInCourse },
      { type: 'content', content: [] },
    ]

    return {
      ...value,
      setIndex: () => {},
      allContent,
      allContentFiltered: [
        { type: 'cards', content: [] },
        {
          type: 'content',
          content: undefined,
        },
      ],
      insertTagAtCurrentIndex: (): void => {},
      tagsListOpen: false,
      dismissTagsList: () => {},
    }
  }, [cardsInCourse, value])

  return <TagsProvider.Provider value={content}>{children}</TagsProvider.Provider>
}

const EditorTagsProvider: FCC<{ value: TagsList; createContentId: CreateContentId }> = ({
  value,
  children,
  createContentId,
}) => {
  const flexibleContentNodes = useSelector(state =>
    flexSelectors.selectFlexibleContentNodes(state, createContentId)
  )

  const { yDoc } = useCreatePageYDocContext()
  const getFileTitle = useGetFileTitle(yDoc)

  const assetContext: AssetContext = useMemo(() => {
    return {
      type: 'course' as const,
      courseId: createContentId,
    }
  }, [createContentId])

  const cardsInCourse = useMemo(() => {
    const files = Object.entries(flexibleContentNodes ?? {})
      .filter(it => it[1]?.type === 'file')
      .map(([fileId, data]) => ({ fileId, data }) as { fileId: string; data: File })

    const cards = files.map(file => {
      return {
        icon: <FileIcon size='small' file={file.data} assetContext={assetContext} />,
        contentType: 'card' as const,
        ...file.data,
        title: getFileTitle(file.data),
      }
    })

    return cards
  }, [flexibleContentNodes, getFileTitle, assetContext])

  const editor = useSlateStatic()
  const allCourseContent = useTaggableContent()

  const content = useMemo(() => {
    const filteredCardsInCourse = cardsInCourse.filter(c =>
      c.title.toLowerCase().startsWith((value.search ?? '').toLowerCase())
    )

    const filteredContentList = allCourseContent
      ?.filter(c => c.title.toLowerCase().startsWith((value.search ?? '').toLowerCase()))
      .slice(0, 50)

    const allContent: TagsContext['allContent'] = [
      { type: 'cards', content: cardsInCourse },
      { type: 'content', content: allCourseContent },
    ]

    const allContentFiltered: TagsContext['allContent'] = [
      { type: 'cards', content: filteredCardsInCourse },
      { type: 'content', content: filteredContentList },
    ]

    const insertTagAtCurrentIndex = (): void => {
      const contents = allContentFiltered.flatMap(contents => [...(contents.content ?? [])])

      const content = contents[value.index]
      if (content === undefined) return
      const tag = createTag({
        contentId: content.id,
        contentType: content.contentType,
      })

      if (value.targetRange !== undefined) {
        Transforms.select(editor, value.targetRange)
        Transforms.insertNodes(editor, [tag, { text: '' }])
        Transforms.insertText(editor, ' ')
        ReactEditor.focus(editor)
      }
    }

    const tagsListOpen =
      !value.tagsListDismissed &&
      value.targetRect !== undefined &&
      allContentFiltered.some(it => (it.content ?? []).length > 0)

    const contentLength = allContentFiltered.flatMap(it => [...(it.content ?? [])]).length

    return {
      ...value,
      setIndex: (index: number) => {
        value.setIndex(resolveIndex(index, { length: contentLength }))
      },
      allContent,
      allContentFiltered,
      insertTagAtCurrentIndex,
      tagsListOpen,
    }
  }, [cardsInCourse, allCourseContent, value, editor])

  return <TagsProvider.Provider value={content}>{children}</TagsProvider.Provider>
}

export const TagsContext: FCC<{ mode: EditorMode; value: TagsList }> = ({ children, value, mode }) => {
  const createContentId = useCreatePageContextSafe()?.createContentId
  const flexibleContentId = useSelfPacedFilesSafe()?.flexibleContentId

  if (
    (mode === 'self-paced' || mode === 'placement-test' || mode === 'review') &&
    isDefined(flexibleContentId)
  )
    return (
      <LearnerTagsProvider flexibleContentId={flexibleContentId} value={value}>
        {children}
      </LearnerTagsProvider>
    )
  if (mode === 'create' && isDefined(createContentId))
    return (
      <EditorTagsProvider createContentId={createContentId} value={value}>
        {children}
      </EditorTagsProvider>
    )
  if (mode === 'live') return <LiveTagsProvider value={value}>{children}</LiveTagsProvider>
  return <>{children}</>
}

export const useTagsContextSafe = (): TagsContext | undefined => {
  return React.useContext(TagsProvider)
}

export const useTagsMenuOnKeyDown = (): ((event: EditorKeyboardEvent) => void) => {
  const tagContext = useTagsContextSafe()
  if (tagContext === undefined) return () => {}
  const { dismissTagsList, index, setIndex, insertTagAtCurrentIndex, tagsListOpen } = tagContext
  return event => {
    if (!tagsListOpen) return
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault()
        setIndex(index - 1)
        break
      case 'ArrowDown':
        event.preventDefault()
        setIndex(index + 1)
        break
      case 'Enter':
        event.preventDefault()
        insertTagAtCurrentIndex()
        break
      case 'Escape':
        event.preventDefault()
        event.stopPropagation()
        dismissTagsList()
        break
    }
  }
}

export const useGetTags = (editor: Editor): TagsList => {
  const [targetRange, setTargetRange] = useState<Range | undefined>()
  const [index, setIndex] = useState(0)
  const [search, setSearch] = useState<string | undefined>('')
  const [targetRect, setTargetRect] = useState<DOMRect | undefined>(undefined)
  const [tagsListDismissed, setTagsListDismissed] = useState(false)

  useEffect(() => {
    if (targetRange !== undefined) {
      const domRange = ReactEditor.toDOMRange(editor, targetRange)
      const rect = domRange.getBoundingClientRect()
      setTargetRect(rect)
    } else setTargetRect(undefined)
  }, [editor, targetRange])

  const updateTags = useStableFunction(() => {
    const { selection } = editor
    if (selection && Range.isCollapsed(selection)) {
      try {
        const [start] = Range.edges(selection)

        const blockBefore = Editor.before(editor, start, { unit: 'block' })

        const beforeBlockRange = blockBefore && Editor.range(editor, blockBefore, start)

        const beforeBlockText = beforeBlockRange && Editor.string(editor, beforeBlockRange)

        const beforeBlockMatch =
          beforeBlockText !== undefined && beforeBlockText.match(/(?:^|\s)@[a-zA-Z_0-9 ]*/)

        if (beforeBlockMatch !== null && beforeBlockMatch !== false) {
          const lengthOfSearchTerm = beforeBlockMatch[0].trimStart().length
          const atPoint = Editor.before(editor, start, { distance: lengthOfSearchTerm })

          setTargetRange(atPoint !== undefined ? { anchor: atPoint, focus: start } : undefined)
          setSearch(beforeBlockMatch[0].split('@')[1])
          setIndex(0)
          setTagsListDismissed(false)
          return { index, search, targetRect, targetRange }
        }
      } catch (e) {
        // Unfortunately the slate DOM tree can be behind the slate document
        // which can cause slate-react to throw an error when trying to resolve a node.
        console.error('Failed to update tags', e)
      }
    }
    setTargetRange(undefined)
  })

  return useMemo(
    () => ({
      updateTags,
      index,
      setIndex,
      search,
      targetRect,
      targetRange,
      tagsListDismissed,
      dismissTagsList: () => setTagsListDismissed(true),
    }),
    [index, search, targetRange, targetRect, updateTags, tagsListDismissed]
  )
}

const TaggableContentRow = styled.li<{ selected: boolean }>`
  height: 44px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  cursor: pointer;

  background-color: ${p => (p.selected ? palette.grey[2] : 'none')};
`
const InlineImage = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 6px;
  position: relative;
`

const TagsPopper = styled(MUIPopper)`
  padding: 1rem;
  padding-bottom: 0;

  margin: 0;
  list-style: none;

  border-radius: 8px;
  border: 1px solid ${p => p.theme.color.grey10};
  background-color: ${palette.primitives.white};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  width: 342px;
  max-height: 405px;
  z-index: ${zIndex.MODAL};
  overflow: auto;
  position: relative;
`

const EllipsisText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const FullWidthView = styled(View)`
  width: 100%;
`

const Spacer = styled.div`
  height: 1rem;
`

const SectionText = styled(Text)`
  margin: 8px 0;

  &:first-child {
    margin-top: 0;
  }
`

export const TagsList = ({
  targetRect,
  currentIndex,
}: {
  targetRect: DOMRect
  currentIndex: number
}): JSX.Element => {
  const itemRefs = useRef<(HTMLLIElement | null)[]>([])
  const tagsContext = useTagsContextSafe()
  const invalidateTaggableContentCache = useInvalidateTaggableContentCache()

  const stableInvalidateTaggableContentCache = useStableFunction(invalidateTaggableContentCache)
  useOnMount(() => {
    void stableInvalidateTaggableContentCache()
  })

  useEffect(() => {
    const currentItem = itemRefs.current[currentIndex]
    if (currentItem === null || currentItem === undefined) return

    currentItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [currentIndex])

  const assetResolver = useAssetResolver({ size: 'default' })

  if (!tagsContext) {
    return <></>
  }

  const { setIndex, allContentFiltered, tagsListOpen, insertTagAtCurrentIndex } = tagsContext

  if (!tagsListOpen || allContentFiltered.flatMap(it => it.content?.map(it => it.id)).length === 0) {
    return <></>
  }

  return (
    <TagsPopper
      placement='bottom-start'
      anchorEl={{
        clientHeight: targetRect.height,
        clientWidth: targetRect.height,
        getBoundingClientRect: () => targetRect,
      }}
      open
    >
      <ul>
        {allContentFiltered.map(({ type, content = [] }, allContentIndex, allContentArray) => {
          const previousContentLength = allContentArray
            .slice(0, allContentIndex)
            .flatMap(it => [...(it.content ?? [])]).length

          return (
            content.length > 0 && (
              <React.Fragment key={type}>
                <SectionText color='grey25' bold size='small'>
                  {type === 'cards' && 'In this document'}
                  {type === 'content' && 'Documents'}
                </SectionText>

                {content.map((taggableContent, i) => {
                  const image =
                    taggableContent.contentType === 'content' ? (
                      <InlineImage
                        src={assetResolver(
                          taggableContent.image,
                          getAssetContextFromTaggableContent(taggableContent)
                        )}
                      />
                    ) : (
                      taggableContent.icon
                    )

                  return (
                    <TaggableContentRow
                      selected={currentIndex === i + previousContentLength}
                      onMouseEnter={() => setIndex(i + previousContentLength)}
                      ref={element => {
                        itemRefs.current[i + previousContentLength] = element
                      }}
                      onClick={() => {
                        insertTagAtCurrentIndex()
                      }}
                      key={taggableContent.id}
                    >
                      <FullWidthView gap='8' direction='row'>
                        {image}
                        <EllipsisText size='small' color='black'>
                          {taggableContent.title}
                        </EllipsisText>
                      </FullWidthView>
                    </TaggableContentRow>
                  )
                })}
              </React.Fragment>
            )
          )
        })}

        <Spacer />
      </ul>
    </TagsPopper>
  )
}
