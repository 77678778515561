import Ably from 'ably'
import { useSetAtom } from 'jotai'
import { useEffect } from 'react'
import { logger } from 'sierra-client/logger/logger'
import { connectionStateAtom } from 'sierra-client/state/realtime-data'

export const RealTimeConnectionMonitor = (props: { client?: Ably.Realtime }): null => {
  const client = props.client
  const setConnectionState = useSetAtom(connectionStateAtom)

  useEffect(() => {
    if (client === undefined) return

    const handleUpdate: Ably.connectionEventCallback = newState => {
      logger.debug('[Ably] connection state changed', { newState })
      setConnectionState(newState.current)
    }

    client.connection.on(handleUpdate)

    return () => {
      client.connection.off(handleUpdate)
    }
  }, [client, setConnectionState])

  return null
}
