import React from 'react'
import { ColorInheritingLink } from 'sierra-client/lib/tabular/components/cell-components'
import { LiveSessionRep } from 'sierra-client/lib/tabular/datatype/internal/reps/live-session-rep'
import { TruncatedText } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const LiveSessionCell: React.FC<{ cell: LiveSessionRep }> = ({ cell }) => {
  return (
    <ColorInheritingLink href={`/manage/events/${cell.id}`} target='_blank'>
      <View>
        <TruncatedText lines={1}>{cell.title}</TruncatedText>
      </View>
    </ColorInheritingLink>
  )
}
