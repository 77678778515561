import React, { useState } from 'react'
import { usePreferredTimezone } from 'sierra-client/api/hooks/use-user'
import {
  useAdminIdentitiesFetcher,
  usePotentialFacilitatorsForCalendarEventsFetcher,
} from 'sierra-client/components/common/identities-selector/identity-fetchers'
import { config, getFlag } from 'sierra-client/config/global-config'
import { CalendarIntegrationPicker } from 'sierra-client/features/calendar-integrations'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import {
  CalendarEventUserSelector,
  EventParticipantSelectorProps,
} from 'sierra-client/views/manage/event-groups/components/calendar-event-user-selector'
import { EventScheduleForm } from 'sierra-client/views/manage/event-groups/components/event-schedule-form'
import { EventLocationInput } from 'sierra-client/views/manage/event-groups/components/location-input'
import {
  CalendarEventSchedule,
  CreateCalendarEventData,
  EventLocation,
} from 'sierra-client/views/manage/event-groups/types'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CalendarIntegrationSetting } from 'sierra-domain/content/session'
import { isDefined } from 'sierra-domain/utils'
import { FormElement } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const EventForm: React.FC<{
  event: CreateCalendarEventData
  updateSchedule: (newSchedule: CalendarEventSchedule) => void
  updateLocation: (newLocation: EventLocation | undefined) => void
  updateParticipantIdentities?: (newParticipantIdentites: IdentityWithMetadata[]) => void
  updateFacilitatorIdentities: (newFacilitatorIdentites: IdentityWithMetadata[]) => void
  updateCalendarIntegrationSetting: (calendarIntegrationSetting: CalendarIntegrationSetting) => void
  isEditing?: boolean
}> = ({
  event,
  updateSchedule,
  updateLocation,
  updateParticipantIdentities,
  updateFacilitatorIdentities,
  updateCalendarIntegrationSetting,
  isEditing,
}) => {
  const { t } = useTranslation()

  const [selectedParticipantIdentities, setSelectedParticipantIdentitiesState] = useState<
    IdentityWithMetadata[]
  >(event.participantIdentities)

  const setSelectedParticipantIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(selectedParticipantIdentities)
    setSelectedParticipantIdentitiesState(updatedIdentities)
    updateParticipantIdentities?.(updatedIdentities)
  }

  const [selectedFacilitatorIdentities, setSelectedFacilitatorIdentitiesState] = useState<
    IdentityWithMetadata[]
  >(event.facilitatorIdentities)

  const setSelectedFacilitatorIdentities: EventParticipantSelectorProps['setSelectedIdentities'] = update => {
    const updatedIdentities = update(selectedFacilitatorIdentities)
    setSelectedFacilitatorIdentitiesState(updatedIdentities)
    updateFacilitatorIdentities(updatedIdentities)
  }

  const fetchParticipantIdentities = useAdminIdentitiesFetcher({ withTypes: ['user', 'user-group'] })
  const fetchFacilitatorIdentities = usePotentialFacilitatorsForCalendarEventsFetcher()

  const rsvpEnabled = getFlag('rsvp')
  const hasExternalCalendarIntegrationEnabled =
    config.organization.settings.calendarIntegrationSettings.type !== 'sana'
  const timezoneQuery = usePreferredTimezone()
  const timezone = timezoneQuery.isSuccess === true ? timezoneQuery.data : null

  return (
    <View direction='column' position='relative' marginBottom={'4'} gap='24'>
      <FormElement grow={false} label={t('admin.author.sessions.location')}>
        <EventLocationInput updateLocation={updateLocation} initialLocation={event.location} />
      </FormElement>
      {isDefined(updateParticipantIdentities) && (
        <FormElement grow={false} label={t('admin.author.sessions.participants')}>
          <CalendarEventUserSelector
            selectedIdentities={selectedParticipantIdentities}
            setSelectedIdentities={setSelectedParticipantIdentities}
            fetchIdentities={fetchParticipantIdentities}
          />
          {rsvpEnabled && hasExternalCalendarIntegrationEnabled && (
            <CalendarIntegrationPicker
              onChange={updateCalendarIntegrationSetting}
              calendarIntegrationSetting={event.calendarIntegrationSetting}
              isValid={true}
              disabled={isEditing ?? false}
            />
          )}
        </FormElement>
      )}

      <FormElement grow={false} label={t('live.facilitator')}>
        <CalendarEventUserSelector
          selectedIdentities={selectedFacilitatorIdentities}
          setSelectedIdentities={setSelectedFacilitatorIdentities}
          fetchIdentities={fetchFacilitatorIdentities}
        />
      </FormElement>

      {isDefined(timezone) && (
        <EventScheduleForm schedule={event.schedule} updateSchedule={updateSchedule} timezone={timezone} />
      )}
    </View>
  )
}
