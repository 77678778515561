import React from 'react'
import { canvasColumn } from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader, StaticDataLoaderMeta } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabular } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { getGlobalRouter } from 'sierra-client/router'
import { HomeworkTableEmptyState } from 'sierra-client/views/manage/homeworks/homework-table-empty-state'
import { AvatarCell } from 'sierra-client/views/manage/homeworks/manage-homeworks/components/avatar-cell'
import { ContextCell } from 'sierra-client/views/manage/homeworks/manage-homeworks/components/context-cell'
import { ExerciseCell } from 'sierra-client/views/manage/homeworks/manage-homeworks/components/excercise-cell'
import { NumberOfSubmissionsCell } from 'sierra-client/views/manage/homeworks/manage-homeworks/components/number-of-submissions-cell'
import { ListExercisesResponse } from 'sierra-domain/homework'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

export type Homework = ListExercisesResponse['data'][number]

type HomeworkTableDefinition = TableDefinitionOf<
  Homework,
  [
    { type: 'canvas'; ref: 'homwork' },
    { type: 'canvas'; ref: 'submissions' },
    { type: 'canvas'; ref: 'context' },
    { type: 'canvas'; ref: 'reviewers' },
  ]
>

type HomeworkTableData = TableDataFromDefinition<Homework, HomeworkTableDefinition>
type HomeworkTableMeta = StaticDataLoaderMeta<HomeworkTableData>

const tableDefinition = (): HomeworkTableDefinition => {
  return {
    columns: [
      canvasColumn({
        getData: r => ({
          view: (
            <ExerciseCell
              courseData={r.courseData}
              reviewInstructions={r.reviewInstructions?.tiptapJSONData}
            />
          ),
          meta: {
            sorts: () => r.courseData.cardTitle ?? '',
            exports: () => r.courseData.cardTitle,
          },
        }),
        header: translatedLabel('dictionary.homework-singular'),
        ref: 'homwork',
        sortable: false,
      }),
      canvasColumn({
        getData: r => ({
          view: (
            <NumberOfSubmissionsCell count={r.numberOfSubmissions} total={r.expectedNumberOfSubmissions} />
          ),
          meta: {
            sorts: () => r.numberOfSubmissions.toString(),
            exports: () => r.numberOfSubmissions.toString(),
          },
        }),
        header: translatedLabel('manage.homework.submission-plural'),
        ref: 'submissions',
        sortable: false,
      }),
      canvasColumn({
        getData: r => ({
          view: <ContextCell course={r.courseData} path={r.path} />,
          meta: {
            sorts: () => '',
            exports: () => '',
          },
        }),
        header: translatedLabel('manage.homework.context'),
        ref: 'context',
        sortable: false,
      }),
      canvasColumn({
        getData: r => ({
          view: <AvatarCell reviewerIds={r.assignedReviewers?.map(({ userId }) => userId) ?? []} />,
          meta: {
            sorts: () => '',
            exports: () => '',
          },
        }),
        header: translatedLabel('manage.homework.reviewer-plural'),
        ref: 'reviewers',
        sortable: false,
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.id,
    },
  }
}

const useHomeworkTable = ({
  data,
}: {
  data: Homework[]
}): UseTableAPI<HomeworkTableData, HomeworkTableMeta> => {
  const loader = staticDataLoader(definition2Data(tableDefinition(), data))

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [],
      right: [],
    },
  })
}

const TableWrapper = styled(View)`
  height: calc(100vh - 20rem);
  width: 100%;
`

export type HomeworksTableProps = {
  data: Homework[]
}

export const HomeworksTable: React.FC<HomeworksTableProps> = ({ data }) => {
  const tableAPI = useHomeworkTable({ data })

  if (data.length === 0) {
    return <HomeworkTableEmptyState />
  }

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: `/manage/exercises/${rawData.id}` })
        },
      }}
    >
      <TableWrapper>
        <BasicTabular />
      </TableWrapper>
    </TabularProviderFromTableAPI>
  )
}
