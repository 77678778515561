import { useEffect } from 'react'
import { useDebouncedState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UnsplashImage, useUnsplashImages } from 'sierra-client/hooks/use-unsplash-images'
import { createImageThumbhashBase64 } from 'sierra-client/utils/image-thumbhash'
import { ScrollContainer } from 'sierra-client/views/v3-author/images/scroll-container'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Icon } from 'sierra-ui/components'
import { Skeleton } from 'sierra-ui/primitives'
import { palette, spacing } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import styled from 'styled-components'

const Container = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 4px;
`

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.primitives.white};
  background: ${palette.primitives.black};
  transition: 0.2s;
  opacity: 0;

  > span {
    margin-right: ${spacing['4']};
  }
`

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: 100ms ease-in;
  outline: 0px solid ${p => p.theme.color.blueLight};
  border-radius: 6px;

  &:hover {
    border-radius: 6px;
    outline: 4px solid ${p => p.theme.color.blueLight};
  }
`

const ContainedImage = styled.div<{ $url: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${p => p.$url}) center center;
  background-size: cover;
  border-radius: 6px;
`

export const toSanaImage = async (image: UnsplashImage): Promise<ImageUnion> => {
  const imageElement = new Image()
  imageElement.crossOrigin = 'anonymous'
  imageElement.src = image.urls.small
  await imageElement.decode()

  return {
    type: 'unsplash',
    url: image.urls.raw,
    width: image.width,
    height: image.height,
    thumbHashBase64: createImageThumbhashBase64(imageElement),
    user: {
      name: image.user.name,
      username: image.user.username,
    },
  }
}

const UnsplashImageGrid = styled.div`
  display: grid;
  grid-template: 250px / repeat(3, 1fr);
  grid-auto-rows: 250px;
  place-items: center;
  gap: 0.5rem;

  @media screen and (max-width: ${v2_breakpoint.phone}) {
    grid-template: 250px / repeat(1, 1fr);
  }
`

export const UnsplashBrowser: React.FC<{
  query: string | undefined
  onImageUploaded: (_: ImageUnion) => void
}> = ({ query: outerQuery, onImageUploaded }) => {
  const { t } = useTranslation()
  const [query, setQuery] = useDebouncedState<string | undefined>(outerQuery)
  const { images, isLoading, requestMore } = useUnsplashImages({ query })

  useEffect(() => {
    setQuery(outerQuery)
  }, [outerQuery, setQuery])

  const onImageSelected = async (image: UnsplashImage): Promise<void> => {
    onImageUploaded(await toSanaImage(image))
  }

  return (
    <Container onScrollToBottom={requestMore}>
      <UnsplashImageGrid>
        {images.map(image => (
          <ImageContainer key={image.id}>
            <ContainedImage $url={image.urls.small} onClick={() => onImageSelected(image)} />
            <ImageOverlay onClick={() => onImageSelected(image)}>
              <Icon iconId='add' size='size-24' />
              {t('author.upload-image-popup.select')}
            </ImageOverlay>
          </ImageContainer>
        ))}
        {images.length === 0 && isLoading && (
          <>
            <ImageContainer>
              <Skeleton $width={'100%'} $height={'100%'} $radius={'6px'} />
            </ImageContainer>
            <ImageContainer>
              <Skeleton $width={'100%'} $height={'100%'} $radius={'6px'} />
            </ImageContainer>
            <ImageContainer>
              x
              <Skeleton $width={'100%'} $height={'100%'} $radius={'6px'} />
            </ImageContainer>
          </>
        )}
      </UnsplashImageGrid>
    </Container>
  )
}
