import { t } from 'i18next'
import { ContentAttribute } from 'sierra-client/views/manage/content-attributes/types'
import { isDefined } from 'sierra-domain/utils'
import { ClosePanelButton, Panel } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const EditAttributeSidePanel: React.FC<{
  onClose: () => void
  attributeToEdit: ContentAttribute | null
}> = ({ onClose, attributeToEdit }) => {
  return (
    <Panel
      size={{ width: 650 }}
      animation='slide-right'
      padding='none'
      onClose={onClose}
      open={isDefined(attributeToEdit)}
      disableScrollbarGutter
    >
      <ClosePanelButton onClick={onClose} ariaLabel={t('dictionary.close')} />

      <View paddingLeft='40' paddingRight='40'>
        EDIT {attributeToEdit?.name}
      </View>
    </Panel>
  )
}
