import { useCallback } from 'react'
import { getFileContentVideo, getNamespacedImage } from 'sierra-client/api/content'
import { useNotif } from 'sierra-client/components/common/notifications'
import { config } from 'sierra-client/config/global-config'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { UploadVideo } from 'sierra-client/views/v3-author/common/media-uploader/types'
import { maxFileSizeBytes, maxFileSizeGb } from 'sierra-client/views/v3-author/plugins/with-file-paste'
import { XRealtimeAuthorSetVideoUploaded, XRealtimeAuthorUploadVideo } from 'sierra-domain/routes'

/**
 *
 * @deprecated
 * use videoUploadWithProgress instead
 */
export const useUploadVideo = (): { uploadVideo: UploadVideo } => {
  const { postWithUserErrorException } = usePost()
  const { t } = useTranslation()
  const notif = useNotif()

  const uploadVideo = useCallback<UploadVideo>(
    async (blob, assetContext) => {
      if (blob.size > maxFileSizeBytes) {
        notif.push({
          type: 'error',
          body: t('manage.media-library.file-too-large', { size: maxFileSizeGb }),
        })

        throw new Error(`File too large (max size ${maxFileSizeGb} GB)`)
      }

      const data = assetContext.type === 'course' ? { courseId: assetContext.courseId } : {}

      // Get a signed URL for upload
      const { videoId, url: uploadUrl } = await postWithUserErrorException(XRealtimeAuthorUploadVideo, data)

      // Upload the video to Google
      await fetch(uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: { 'Content-Type': 'video/mp4' },
      })

      // Mark the video as uploaded
      await postWithUserErrorException(XRealtimeAuthorSetVideoUploaded, {
        fileId: videoId,
        courseId: assetContext.type === 'course' ? assetContext.courseId : undefined,
      })

      if (assetContext.type === 'course') {
        return {
          url: getNamespacedImage(assetContext, videoId, 'video'),
        }
      }

      return {
        url: getFileContentVideo(videoId, config.organization.uuid),
      }
    },
    [postWithUserErrorException, notif, t]
  )

  return { uploadVideo }
}
