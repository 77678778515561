import { useTranslation } from 'sierra-client/hooks/use-translation'
import { GradeStatus } from 'sierra-domain/api/homework'
import { assertNever } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

export const GradeStatusCell: React.FC<{ status: GradeStatus }> = ({ status }) => {
  const { t } = useTranslation()
  switch (status) {
    case 'not-submitted':
      return (
        <View>
          <Text size='small' color='grey40'>
            -
          </Text>
        </View>
      )
    case 'not-graded':
      return (
        <View>
          <Text size='small' bold color='grey40'>
            {t('homework.ready-for-review')}
          </Text>
        </View>
      )
    case 'failed':
      return (
        <View>
          <Icon iconId='close--circle' color='redBright' />
          <Text size='small' bold color='redBright'>
            {t('homework.failed')}
          </Text>
        </View>
      )
    case 'failed-with-no-retries':
      return (
        <View>
          <Icon iconId='close--circle' color='redBright' />
          <Text size='small' bold color='redBright'>
            {t('homework.failed-with-no-retries')}
          </Text>
        </View>
      )
    case 'passed':
      return (
        <View>
          <Icon iconId='checkmark--outline' color='greenBright' />
          <Text size='small' bold color='greenBright'>
            {t('homework.passed')}
          </Text>
        </View>
      )
    case 'dismissed':
      return (
        <View>
          <Text size='small' bold color='grey40'>
            {t('homework.dismissed')}
          </Text>
        </View>
      )
    default:
      assertNever(status)
  }
}
