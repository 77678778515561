import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Tabs } from 'sierra-client/components/common/layout/tabs'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { Modal } from 'sierra-client/components/common/modals/modal'
import { defaultIsRequiredChangedLogger } from 'sierra-client/components/required-assignments/logger'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { CollaboratorGroupsTab } from 'sierra-client/views/manage/paths/path-settings/tabs/collaborator-groups-tab'
import { CollaboratorsTab } from 'sierra-client/views/manage/paths/path-settings/tabs/collaborators-tab'
import { PathSettingsTab } from 'sierra-client/views/manage/paths/path-settings/tabs/path-settings-tab'
import { TranslationsTab } from 'sierra-client/views/manage/paths/path-settings/tabs/translations-tab'
import {
  HandleFormChangeNoContent,
  PathDataNoContent,
  SettingsTabComponentProps,
  SettingsTabConfig,
  TabID,
  TranslationInfo,
} from 'sierra-client/views/manage/paths/path-settings/types'
import { UsePathDetailsData } from 'sierra-client/views/manage/paths/use-path-details'
import { UsePathTranslationData } from 'sierra-client/views/manage/paths/use-path-translations'
import { usePotentialPathCollaborators } from 'sierra-client/views/manage/paths/use-paths'
import { PathCollaborator, PathLocalizationRow } from 'sierra-domain/api/admin'
import { PathId } from 'sierra-domain/api/nano-id'
import { Button, LoadingSpinner } from 'sierra-ui/primitives'
import { Text } from 'sierra-ui/primitives/typography'
import styled from 'styled-components'

const TabWrapper = styled.div`
  padding: 1.5rem 0;
  min-height: 75vh;
`

const FooterContainer = styled.footer`
  flex: 1;
  display: grid;
  gap: 0.5rem;
  grid: repeat(2, 1fr) / 1fr;
  justify-items: center;
`

const settingsTabs: Record<TabID, SettingsTabConfig> = {
  'path-settings': {
    id: 'path-settings',
    labelKey: 'settings.settings',
    component: PathSettingsTab,
  },
  'collaborators': {
    id: 'collaborators',
    labelKey: 'dictionary.collaborator-plural',
    component: CollaboratorsTab,
  },
  'collaborator-groups': {
    id: 'collaborator-groups',
    labelKey: 'dictionary.collaborator-group-plural',
    component: CollaboratorGroupsTab,
  },
  'translations': {
    id: 'translations',
    labelKey: 'dictionary.translation-plural',
    component: TranslationsTab,
  },
}

const inOrderTabs = (
  ['path-settings', 'collaborators', 'collaborator-groups', 'translations'] as TabID[]
).map(id => settingsTabs[id])

type PathSettingsModalProps = {
  pathId: PathId
  localizationData: PathLocalizationRow[] | undefined
  fetchPathTranslations: UsePathTranslationData['fetchPathTranslations']
  savePathTranslations: UsePathTranslationData['savePathTranslations']
  fetchPath: UsePathDetailsData['fetchPath']
  updatePathData: (newPathData: PathDataNoContent) => void
  onSave: () => void
  onClose: () => void
  pathDataForm: PathDataNoContent
  collaborators: PathCollaborator[]
  updateCollaborators: UsePathDetailsData['updateCollaborators']
  canEditPathVisibility: boolean
  canEditCollaborators: boolean
}

export const PathSettingsModal: React.FC<PathSettingsModalProps> = ({
  pathId,
  localizationData,
  savePathTranslations,
  fetchPath,
  updatePathData,
  onSave,
  onClose,
  pathDataForm,
  collaborators,
  updateCollaborators,
  canEditPathVisibility,
  canEditCollaborators,
}) => {
  const { t } = useTranslation()

  const [currentTab, setCurrentTab] = useState<TabID>('path-settings')

  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const { potentialCollaborators, fetchPotentialCollaborators } = usePotentialPathCollaborators()
  const [draftPathData, setDraftPathData] = useState(pathDataForm)
  const [confirmClose, setConfirmClose] = useState(false)
  const dispatch = useDispatch()

  const defaultTranslation = useMemo<TranslationInfo>(
    () => ({
      title: draftPathData.title,
      description: draftPathData.description,
      language: '',
      isDefault: true,
      incomplete: true,
      key: 0,
    }),
    [draftPathData.title, draftPathData.description]
  )

  const translationsData = useMemo<TranslationInfo[] | undefined>(() => {
    return localizationData?.map((l, index) => ({
      title: l.title,
      description: l.description,
      language: l.language,
      isDefault: l.isDefault,
      incomplete: false,
      key: index,
    }))
  }, [localizationData])
  const [draftTranslations, setDraftTranslations] = useState<TranslationInfo[]>(
    translationsData ?? [defaultTranslation]
  )

  const handleFormChange: HandleFormChangeNoContent = useCallback((field, value) => {
    setDraftPathData(curr => ({
      ...curr,
      [field]: value,
    }))
  }, [])

  const allTranslationsValid = useMemo(
    () => draftTranslations.length === 1 || draftTranslations.every(it => !it.incomplete),
    [draftTranslations]
  )

  const addNewTranslation = useCallback(() => {
    const currentMaxKey = Math.max(...draftTranslations.map(tr => tr.key))
    const newTranslation = {
      title: '',
      description: '',
      courseId: '',
      language: '',
      isDefault: draftTranslations.length === 0,
      incomplete: true,
      key: currentMaxKey + 1,
    }
    setDraftTranslations(translations => [...translations, newTranslation])
  }, [draftTranslations])

  const removeTranslationClick = useCallback(
    (editionIndex: number) => {
      if (editionIndex < draftTranslations.length) {
        setDraftTranslations(translations => translations.filter((tr, index) => index !== editionIndex))
      }
    },
    [draftTranslations.length]
  )

  const updateTranslation = useCallback(
    (
      translationIndex: number,
      title: string,
      description: string | undefined,
      language: string,
      key: number | undefined
    ) => {
      const newTranslation = {
        title: title,
        description: description,
        language: language,
        isDefault: translationIndex === 0,
        incomplete: !title || !language,
        key: key ?? 1,
      }
      setDraftTranslations(translations =>
        translations.map((tr, index) => (index === translationIndex ? newTranslation : tr))
      )
      if (translationIndex === 0) {
        handleFormChange('title', title)
        handleFormChange('description', description)
      }
    },
    [handleFormChange]
  )

  useEffect(() => {
    const loadData = async (): Promise<void> => {
      setIsLoading(true)
      // Load current settings and path collaborators
      // Improvement: Only load collaborators when opening collaborators tab (but still keep the state here
      // so the user can go back and forth without us fetching it again)

      try {
        const [pathData] = await Promise.all([fetchPath(pathId), fetchPotentialCollaborators(pathId)])
        setDraftPathData(pathData)
      } finally {
        setIsLoading(false)
      }
    }

    void loadData()
    // Note: fetchPotentialCollaborators and fetchPath have stable identities and won't rerun this effect
  }, [fetchPotentialCollaborators, fetchPath, pathId])

  const hasUnsavedTranslationChanges = useMemo(
    () =>
      !_.isEqual(translationsData ?? [], draftTranslations) &&
      !(
        (translationsData?.length ?? 0) === 0 &&
        draftTranslations.length === 1 &&
        !(draftTranslations[0]?.language ?? '')
      ),
    [translationsData, draftTranslations]
  )

  const hasUnsavedSettingsChanges = useMemo(
    () => !_.isEqual(pathDataForm, draftPathData) || hasUnsavedTranslationChanges,
    [pathDataForm, draftPathData, hasUnsavedTranslationChanges]
  )

  const hasUnsavedIsRequiredChanges = useMemo(
    () =>
      !_.isEqual(
        pathDataForm.settings.defaultRequiredAssignments,
        draftPathData.settings.defaultRequiredAssignments
      ),
    [pathDataForm.settings.defaultRequiredAssignments, draftPathData.settings.defaultRequiredAssignments]
  )

  const handleSaveSettings = async (): Promise<void> => {
    if (!hasUnsavedSettingsChanges) return

    setIsSaving(true)

    try {
      if (hasUnsavedIsRequiredChanges) {
        void dispatch(
          defaultIsRequiredChangedLogger({
            contentType: 'path',
            defaultIsRequired: draftPathData.settings.defaultRequiredAssignments,
            contentId: pathId,
          })
        )
      }
      updatePathData(draftPathData)

      if (allTranslationsValid && hasUnsavedTranslationChanges) {
        await savePathTranslations(
          pathId,
          draftTranslations.map(it => ({
            title: it.title,
            description: it.description,
            language: it.language,
            isDefault: it.isDefault,
          }))
        )
      }
      onSave()
      onClose()
    } finally {
      setIsSaving(false)
    }
  }

  const handleModalClose = (): void => {
    if (hasUnsavedSettingsChanges) {
      setConfirmClose(true)
    } else {
      onClose()
    }
  }

  const switchToTranslationTab = useCallback(() => {
    setCurrentTab('translations')
  }, [])

  const componentProps: SettingsTabComponentProps = {
    pathId,
    onSave,
    onClose,
    switchToTranslationTab,
    handleFormChange,
    pathDataForm: draftPathData,
    collaborators,
    updateCollaborators,
    potentialCollaborators,
    translations: draftTranslations,
    addNewTranslation,
    removeTranslationClick,
    updateTranslation,
    canEditPathVisibility,
    canEditCollaborators,
  }

  return (
    <>
      <Modal
        open
        onClose={handleModalClose}
        title={pathDataForm.title}
        size='medium'
        footer={
          hasUnsavedSettingsChanges ? (
            <FooterContainer>
              <Button
                loading={isSaving}
                variant='primary'
                onClick={handleSaveSettings}
                disabled={!allTranslationsValid}
              >
                {t('author.save-settings')}
              </Button>
              <Text color='grey35' size='small'>
                {t('author.save-settings-description')}
              </Text>
            </FooterContainer>
          ) : undefined
        }
      >
        {isLoading ? (
          <LoadingSpinner padding='large' />
        ) : inOrderTabs.length === 1 && inOrderTabs[0] !== undefined ? (
          React.createElement(inOrderTabs[0].component, componentProps)
        ) : (
          <Tabs
            small
            onClick={(tab: TabID | null) => setCurrentTab(tab ?? 'path-settings')}
            current={currentTab}
            tabs={inOrderTabs.map(tab => ({
              id: tab.id,
              title: t(tab.labelKey),
              content: <TabWrapper>{React.createElement(tab.component, componentProps)}</TabWrapper>,
            }))}
          />
        )}
      </Modal>
      <ActionModal
        open={confirmClose}
        title={t('author.discard-changes')}
        primaryActionLabel={t('dictionary.discard')}
        onClose={() => {
          setConfirmClose(false)
        }}
        primaryAction={onClose}
        deleteAction
      >
        {t('author.about-to-discard-unsaved-changes')}
      </ActionModal>
    </>
  )
}
