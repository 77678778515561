import React, { useMemo } from 'react'
import { DisabledInputContext } from 'sierra-client/components/disabling'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'

/**
 * Allows you to disable inputs when the user is impersonating another user.
 * */
export const DisablingOnImpersonationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = useSelector(selectUser)
  const { t } = useTranslation()

  const value: DisabledInputContext = useMemo(() => {
    if (user?.impersonatorId !== undefined) {
      return { disabled: true, reason: t('settings.impersonator.disabled') }
    }
    return { disabled: false }
  }, [user, t])
  return <DisabledInputContext.Provider value={value}>{children}</DisabledInputContext.Provider>
}
