import { default as React, useContext } from 'react'
import { editorGridItem, interactiveCardGrid } from 'sierra-client/editor/layout'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useIsInPdfExport } from 'sierra-client/views/v3-author/export-pdf/use-is-pdf-export'
import { assertElementType } from 'sierra-client/views/v3-author/queries'
import { isInline } from 'sierra-client/views/v3-author/question-card/is-inline'
import { QuestionCardIconContainer } from 'sierra-client/views/v3-author/question-card/question-card-icon-bar'
import { SettingButtons } from 'sierra-client/views/v3-author/question-card/settings'
import { ReactQuestionVariationsContext } from 'sierra-client/views/v3-author/question-card/variations'
import { RenderingContext } from 'sierra-client/views/v3-author/rendering-context'
import { SlateFC, SlateWrapperProps } from 'sierra-client/views/v3-author/slate'
import { BlockWrapper } from 'sierra-client/views/v3-author/wrapper'
import { color } from 'sierra-ui/color'
import { Text } from 'sierra-ui/primitives'
import { useSelected, useSlateStatic } from 'slate-react'
import styled, { css } from 'styled-components'

const StyledBlockWrapper = styled(BlockWrapper)<{
  $isSelected: boolean
  $readOnly: boolean
  $isInline: boolean
  $isHidden: boolean
  $showAllVariations: boolean
}>`
  ${p =>
    p.$isHidden &&
    css`
      display: none;
    `}

  position: relative;
  padding: 2rem 0;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    right: -20px;
    left: -20px;
    z-index: -1;
    border-radius: ${p => p.theme.borderRadius['size-20']};
    ${editorGridItem}
    transition: background-color 0.15s;
  }

  ${p =>
    p.$isInline &&
    css`
      margin: auto 0;
      ${interactiveCardGrid}
    `}

  ${p =>
    !p.$readOnly &&
    !p.$isInline &&
    css`
      &:hover {
        &:before {
          background-color: ${p => color(p.theme.home.textColor).opacity(0.03).toString()};
        }
      }
    `}
      
      ${p =>
    p.$isSelected &&
    !p.$readOnly &&
    !p.$isInline &&
    css`
      &&:before {
        background-color: ${color(p.theme.home.textColor).opacity(0.1).toString()};
      }
    `}
      
      ${QuestionCardIconContainer} {
    grid-column: none;
    opacity: 0;
  }

  &:hover {
    ${QuestionCardIconContainer} {
      opacity: 1;
    }
  }

  ${p =>
    p.$showAllVariations &&
    css`
      &:last-of-type > :last-child {
        padding-bottom: 4em;
        border-bottom: 1px solid ${p => color(p.theme.home.textColor).opacity(0.2).toString()};
      }
    `}
`

const VariationText = styled(Text).attrs({ size: 'small', bold: true })`
  color: ${p => color(p.theme.home.textColor).opacity(0.4).toString()};
`
const VariationNumber: React.FC<{ element: { id: string } }> = ({ element }) => {
  const { t } = useTranslation()
  const variationsContext = useContext(ReactQuestionVariationsContext)
  const index = variationsContext?.variationIndex(element.id) ?? 0
  return (
    <VariationText color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>
      {t('author.slate.question-variation.variation')} {index + 1}
    </VariationText>
  )
}

const QuestionCardMenu = styled.div`
  position: relative;
  width: 100%;
`
export const QuestionCardContainer = React.forwardRef<HTMLDivElement, SlateWrapperProps>((props, ref) => {
  const { children, element, readOnly, attributes } = props
  assertElementType('question-card', element)
  const editor = useSlateStatic()
  const isInlineQuestion = isInline({ editor })
  const isSelected = useSelected()
  const variationsContext = useContext(ReactQuestionVariationsContext)
  const showAllVariations = useIsInPdfExport()
  const isHidden =
    variationsContext !== undefined &&
    variationsContext.currentQuestionId !== props.element.id &&
    !showAllVariations

  return (
    <>
      <RenderingContext
        preventDrag={true}
        allowBlockComments={true}
        disableMenu={false}
        disableMiniMenu={isInlineQuestion}
      >
        <StyledBlockWrapper
          $isSelected={isSelected}
          $readOnly={readOnly}
          $isInline={!isInlineQuestion}
          $isHidden={isHidden}
          $showAllVariations={showAllVariations}
          {...props}
          {...attributes}
          ref={ref}
        >
          <RenderingContext withGrid={false}>
            {!readOnly && (
              <QuestionCardMenu contentEditable={false}>
                <QuestionCardIconContainer $isInline={isInlineQuestion}>
                  <SettingButtons element={element} />
                </QuestionCardIconContainer>
              </QuestionCardMenu>
            )}
            {showAllVariations && <VariationNumber element={element} />}
            {children}
          </RenderingContext>
        </StyledBlockWrapper>
      </RenderingContext>
    </>
  )
})

export const QuestionCard: SlateFC = ({ element, children }) => {
  assertElementType('question-card', element)

  return <>{children}</>
}
