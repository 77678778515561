import { DateTime } from 'luxon'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'
import { RouterLink } from 'sierra-client/components/common/link'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getCreateEntityHref } from 'sierra-client/components/recommendations/utils'
import { useTemplatePicker } from 'sierra-client/components/templates'
import { getAssetContextFromLearnEntity } from 'sierra-client/components/util/asset-contex'
import { useDateTimeFormatter, useGetFormattedTime } from 'sierra-client/core/format'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useUser } from 'sierra-client/state/users/hooks'
import { favoritableContentType, useUserContentHandler } from 'sierra-client/views/favorites-utils'
import { useGetLearnerProgram } from 'sierra-client/views/learner/program/use-get-learner-program'
import { isEmailEnrollmentStep } from 'sierra-client/views/learner/program/utils/step-graphql'
import { getStepProgress } from 'sierra-client/views/learner/program/utils/utils'
import { getLearnEntityName } from 'sierra-client/views/manage/content/utils/content-utils'
import { getEntityIconId } from 'sierra-client/views/workspace/components'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { continueUrl, detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { CreateEntity, LearnEntity, LearnProgram } from 'sierra-domain/api/entities'
import { UpcomingSessions } from 'sierra-domain/api/learn'
import { CourseId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { iife, isDefined, isNotDefined } from 'sierra-domain/utils'
import { Icon, IconId, MenuItem, Tooltip } from 'sierra-ui/components'
import { ContentCard as ContentCardUI, PlayButton, ProgressLine } from 'sierra-ui/missions/foundation'
import { EditButton, HomeworkFeedbackButton } from 'sierra-ui/missions/foundation/home/content-card'
import { Text, View } from 'sierra-ui/primitives'
import { IconMenu } from 'sierra-ui/primitives/menu-dropdown'
import styled, { css } from 'styled-components'

const BasicLabelStyles = css`
  background: linear-gradient(0deg, rgba(128, 128, 128, 0.4) 0%, rgba(128, 128, 128, 0.4) 100%),
    rgba(255, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background-blend-mode: normal, saturation;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(34px);
  display: flex;
  justify-content: center;
  align-items: center;
`

const LockedContentTypeIcon = styled.div`
  ${BasicLabelStyles};
  height: 44px;
  width: 44px;
`

const ProgramProgressBar: React.FC<{
  entity: LearnProgram
}> = ({ entity }) => {
  const program = useGetLearnerProgram({ programId: entity.id })
  const enrollment = program.data?.me.enrollment

  if (enrollment === undefined || enrollment === null)
    return <ProgressLine $progress={entity.learnerContext.progress} />

  return (
    <View>
      {enrollment.steps.map((step, index) => {
        if (isEmailEnrollmentStep(step)) {
          return null
        }

        const progress = getStepProgress(step)

        return <ProgressLine key={index} $progress={progress} />
      })}
    </View>
  )
}

const EntityTimestamp: React.FC<{
  entity: LearnEntity
}> = ({ entity }) => {
  const { t } = useTranslation()
  const formattedTime = useGetFormattedTime(entity.timeEstimate, false)

  switch (entity.entityType) {
    case 'live-session':
      return (
        <View gap='8'>
          <Text color='foreground/muted' bold>
            {DateTime.fromJSDate(entity.start).toLocaleString({
              day: 'numeric',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit',
            })}
            &nbsp;-&nbsp;
            {DateTime.fromJSDate(entity.end).toLocaleString({ hour: '2-digit', minute: '2-digit' })}
          </Text>
          <Text color='foreground/muted' bold></Text>
        </View>
      )
    case 'native:event-group':
    case 'native:live':
      return (
        <Text color='foreground/muted' bold>
          {t('events-groups.multiple-sessions')}
        </Text>
      )
    default:
      return (
        isDefined(formattedTime) && (
          <Text color='foreground/muted' bold>
            {formattedTime}
          </Text>
        )
      )
  }
}

const CreateEntityTimestamp: React.FC<{
  entity: CreateEntity
}> = ({ entity }) => {
  const { t } = useTranslation()

  return (
    <Text color='foreground/muted' size='small'>
      {iife(() => {
        const daysSinceEdit = Math.floor(
          DateTime.now().diff(DateTime.fromJSDate(entity.creatorContext.editedAt), 'days').days
        )

        switch (daysSinceEdit) {
          case 0:
            return t('recommendation.recently-edited')
          case 1:
            return t('home.recently-edited.one-day-ago')
          default:
            return t('home.recently-edited.n-days-ago', { count: daysSinceEdit })
        }
      })}
    </Text>
  )
}

type SessionDateTimeInfo = {
  startTime: Date
  endTime: Date
  allDay: boolean
  includeDate: boolean
}

export function useFormatSessionTime(): (info: SessionDateTimeInfo) => string {
  const { t } = useTranslation()
  const dateFormatter = useDateTimeFormatter({ month: 'short', day: 'numeric' })
  const dateTimeFormatter = useDateTimeFormatter({
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })
  const timeFormatter = useDateTimeFormatter({ timeStyle: 'short' })

  return useCallback(
    ({ startTime, endTime, allDay, includeDate }) => {
      const isSameDay =
        startTime.getFullYear() === endTime.getFullYear() &&
        startTime.getMonth() === endTime.getMonth() &&
        startTime.getDate() === endTime.getDate()

      if (includeDate) {
        if (allDay) {
          return dateFormatter.formatRange(startTime, endTime)
        } else {
          return dateTimeFormatter.formatRange(startTime, endTime)
        }
      } else {
        if (allDay && isSameDay) {
          return t('dictionary.all-day')
        } else if (allDay && !isSameDay) {
          return dateFormatter.formatRange(startTime, endTime)
        } else if (!allDay && isSameDay) {
          return timeFormatter.formatRange(startTime, endTime)
        } else {
          return dateTimeFormatter.formatRange(startTime, endTime)
        }
      }
    },
    [dateFormatter, dateTimeFormatter, timeFormatter, t]
  )
}

export const UpcomingSessionContentCard: React.FC<{
  session: UpcomingSessions
  includeDate: boolean
  onCardClick?: () => void
}> = ({ session, includeDate, onCardClick }) => {
  const { t } = useTranslation()
  const contentLabel = t(getLearnEntityName(session.entity.entityType))
  const linkable = convertLearnEntityToLinkable(session.entity)
  const nodeRef = useRef<HTMLLIElement>(null)
  const timeFormatter = useFormatSessionTime()
  const formattedTime = timeFormatter({
    startTime: session.session.startTime,
    endTime: session.session.endTime,
    allDay: session.session.allDay,
    includeDate,
  })

  const assetContext: AssetContext = useMemo(
    () => ({ type: 'course' as const, courseId: CourseId.parse(session.entity.id) }),
    [session.entity.id]
  )
  const thumbnailSrc = useResolveAsset({
    image: session.entity.image,
    size: 'default',
    assetContext,
  })

  return (
    <>
      <ContentCardUI
        as='div'
        ref={nodeRef}
        thumbnailSrc={thumbnailSrc}
        metadata={
          <Text color='foreground/primary' bold>
            {formattedTime}
          </Text>
        }
        contentLabel={{
          iconId: getEntityIconId(session.entity.entityType, false),
          label: contentLabel,
        }}
        title={<RouterLink href={detailsUrl(linkable)}>{session.session.title}</RouterLink>}
        renderLink={({ children }) => (
          <RouterLink href={detailsUrl(linkable)} onClick={onCardClick}>
            {children}
          </RouterLink>
        )}
      />
    </>
  )
}

const HomeworkFeedback: React.FC<{ onClick: () => void; reviewerId: UserId | undefined }> = ({
  onClick,
  reviewerId,
}) => {
  const reviewer = useUser(reviewerId)

  if (isNotDefined(reviewer) || reviewer.status !== 'loaded') return null

  return <HomeworkFeedbackButton onClick={onClick} reviewer={reviewer} />
}

export const ContentCard: React.FC<{
  entity: LearnEntity
  withProgressLine: boolean
  metadata?: React.ReactNode | React.ReactNode[]
  onCardClick?: () => void
  isRequiredAssigment?: boolean
}> = ({ entity, metadata, withProgressLine, onCardClick, isRequiredAssigment }) => {
  const { t } = useTranslation()

  const failedExercise = useMemo(() => {
    const prioritizedHomework = getPrioritizedHomework(entity.learnerContext.exercises)
    switch (prioritizedHomework?.grade) {
      case 'failed':
      case 'failed-with-no-retries':
        return prioritizedHomework
      default:
        return undefined
    }
  }, [entity.learnerContext.exercises])

  const completedContent = entity.learnerContext.passedAt !== undefined
  const showFailedExercise = !completedContent && isDefined(failedExercise)

  const linkSource = entity.entityType === 'link' ? entity.linkSource : undefined

  const contentLabel = (
    showFailedExercise ? t('homework.not-passed') : linkSource ?? t(getLearnEntityName(entity.entityType))
  ) satisfies string
  const contentIconId = (
    showFailedExercise ? 'close--circle--filled' : getEntityIconId(entity.entityType, false)
  ) satisfies IconId

  const linkable = convertLearnEntityToLinkable(entity)
  const nodeRef = useRef<HTMLLIElement>(null)
  const notif = useNotif()

  const { isFavorite, addFavorite, removeFavorite, isRemovedFromTodo, removeToDo } = useUserContentHandler({
    initialFavorited: entity.learnerContext.isFavorite,
    initialArchived: entity.learnerContext.isArchived ?? false,
    initialRemoved: entity.learnerContext.isRemovedFromTodo ?? false,
  })

  const favoritableType = favoritableContentType(entity.entityType)
  const isRequiredAssingment = entity.learnerContext.assignment?.isRequiredAssignment === true

  const menuOptions: MenuItem[] = [
    ...(favoritableType !== undefined
      ? isFavorite
        ? [
            {
              id: 'remove-from-favorites',
              type: 'label',
              label: t('workspace.learn.remove-from-favorites'),
              icon: 'star--filled',
            } satisfies MenuItem,
          ]
        : [
            {
              id: 'add-to-favorites',
              type: 'label',
              icon: 'star',
              label: t('workspace.learn.add-to-favorites'),
            } satisfies MenuItem,
          ]
      : []),
    ...(favoritableType !== undefined
      ? !isRemovedFromTodo
        ? [
            {
              id: 'remove-from-todo',
              type: 'label',
              icon: 'trash-can',
              label: t('workspace.learn.remove-from-todo'),
              description: isRequiredAssingment
                ? 'Required assignments can not be removed'
                : t('workspace.learn.removed-todo-still-in-my-library'),
              disabled: isRequiredAssingment,
            } satisfies MenuItem,
          ]
        : []
      : []),
  ]

  const handleSelect = (item: MenuItem<string>): void => {
    if (!favoritableType) {
      console.error('favoritableType is undefined. Cannot perform action.')
      return
    }

    switch (item.id) {
      case 'remove-from-favorites':
        void removeFavorite(entity.id, favoritableType)
        break
      case 'add-to-favorites':
        void addFavorite(entity.id, favoritableType)
        break
      case 'remove-from-todo':
        void removeToDo(entity.id, favoritableType)
        notif.push({
          type: 'success',
          body: 'Removed from To-Do successfully!',
        })
        break
      default:
        console.warn('Unknown action:', item.id)
        break
    }
  }

  const CustomIconMenu: React.FC = () =>
    menuOptions.length > 0 && (
      <IconMenu
        iconId='overflow-menu--horizontal'
        variant='transparent'
        menuItems={menuOptions}
        onSelect={handleSelect}
        size='x-small'
      />
    )

  const assetContext = useMemo(() => getAssetContextFromLearnEntity(entity), [entity])

  const thumbnailSrc = useResolveAsset({
    image: entity.image,
    size: 'content-card',
    assetContext,
  })

  return !isRemovedFromTodo ? (
    <ContentCardUI
      ref={nodeRef}
      iconMenu={<CustomIconMenu />}
      thumbnailSrc={thumbnailSrc}
      metadata={metadata}
      progressBar={
        <>
          {withProgressLine && entity.entityType === 'program' ? (
            <ProgramProgressBar entity={entity} />
          ) : (
            withProgressLine && <ProgressLine $progress={entity.learnerContext.progress} />
          )}
        </>
      }
      contentLabel={{
        iconId: isRequiredAssigment === true ? 'arrow-up--filled' : contentIconId,
        label: isRequiredAssigment === true ? t('assignments.required') : contentLabel,
        highPriority: isRequiredAssigment === true,
      }}
      leadingVisualTitle={
        <>
          {entity.skillContext?.isLevelFastTrack === true && (
            <Tooltip title={t('admin.skills.level-fast-tack.label')}>
              <Icon size='size-24' iconId='racing--flag' color='foreground/primary' />
            </Tooltip>
          )}
        </>
      }
      title={<RouterLink href={detailsUrl(linkable)}>{entity.title}</RouterLink>}
      callToAction={
        <View gap='none'>
          {showFailedExercise ? (
            <HomeworkFeedback
              onClick={() => getGlobalRouter().navigate({ to: continueUrl(linkable) as string })}
              reviewerId={failedExercise.reviewerId}
            />
          ) : entity.entityType !== 'native:live' && entity.entityType !== 'native:event-group' ? (
            <PlayButton onClick={() => getGlobalRouter().navigate({ to: continueUrl(linkable) as string })} />
          ) : null}
        </View>
      }
      renderLink={({ children }) => (
        <RouterLink href={detailsUrl(linkable)} onClick={onCardClick}>
          {children}
        </RouterLink>
      )}
    />
  ) : null
}

export const ContentCardContent: React.FC<{
  entity: LearnEntity
  onCardClick?: () => void
  withProgressLine?: boolean
}> = ({ entity, onCardClick, withProgressLine = false }) => (
  <ContentCard
    entity={entity}
    withProgressLine={withProgressLine}
    onCardClick={onCardClick}
    metadata={<EntityTimestamp entity={entity} />}
  />
)

export const CreateContentCard: React.FC<{
  entity: CreateEntity
  onCardClick?: () => void
}> = ({ entity, onCardClick }) => {
  const { t } = useTranslation()

  const href = getCreateEntityHref(entity)

  const contentLabel = t(getLearnEntityName(entity.entityType))

  const thumbnailSrc = useResolveAsset({
    image: entity.image,
    assetContext: { type: 'course', courseId: CourseId.parse(entity.id) },
    size: 'content-card',
  })

  return (
    <ContentCardUI
      thumbnailSrc={thumbnailSrc}
      metadata={<CreateEntityTimestamp entity={entity} />}
      contentLabel={{
        iconId: getEntityIconId(entity.entityType, false),
        label: contentLabel,
      }}
      title={<RouterLink href={href}>{entity.title}</RouterLink>}
      callToAction={<EditButton />}
      renderLink={({ children }) => (
        <RouterLink href={href} onClick={onCardClick}>
          {children}
        </RouterLink>
      )}
    />
  )
}

const ClickableText = styled(Text)`
  cursor: pointer;
`

const ClickableDiv = styled.div.attrs({ role: 'button' })`
  cursor: pointer;
`

export const CreateContentCardCreateSuggestion: React.FC = () => {
  const { t } = useTranslation()
  const templatePicker = useTemplatePicker()

  const handleClick = useCallback(() => {
    templatePicker.templatePickerOn({ teamspaceState: undefined })
  }, [templatePicker])

  return (
    <ContentCardUI
      title={<ClickableText onClick={handleClick}>{t('helper.create-something-new')}</ClickableText>}
      centredContentIcon={<Icon iconId='add--alt' />}
      metadata={<Text color='foreground/muted'>{t('home.create-suggestion.text')}</Text>}
      renderLink={({ children }) => (
        <ClickableDiv style={{ cursor: 'pointer' }} onClick={handleClick}>
          {children}
        </ClickableDiv>
      )}
    />
  )
}

export const RestrictedCardContent: React.FC<{ onCardClick: () => void; withProgressLine: boolean }> = ({
  withProgressLine,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <ContentCardUI
      disabled
      metadata={
        <Text color='form/disabled/foreground' bold>
          {t('manage.skills.content.restricted.request_access')}
        </Text>
      }
      progressBar={<>{withProgressLine && <ProgressLine $progress={0} />}</>}
      title={
        <Text as='span' color='form/disabled/foreground'>
          {t('manage.skills.content.restricted.unavailable_course')}
        </Text>
      }
      centredContentIcon={<Icon iconId='bookmark--slash' size='size-24' color='form/disabled/foreground' />}
      {...props}
    />
  )
}

export const LockedCardContent = forwardRef<
  HTMLLIElement,
  {
    entity: LearnEntity
    withProgressLine: boolean
    onCardClick?: () => void
  }
>(({ entity, withProgressLine, onCardClick, ...props }, ref) => {
  const linkable = convertLearnEntityToLinkable(entity)

  const assetContext = useMemo(() => getAssetContextFromLearnEntity(entity), [entity])
  const thumbnailSrc = useResolveAsset({
    image: entity.image,
    size: 'default',
    assetContext,
  })

  return (
    <ContentCardUI
      ref={ref}
      blurred
      disabled
      thumbnailSrc={thumbnailSrc}
      metadata={<EntityTimestamp entity={entity} />}
      progressBar={
        <>
          {withProgressLine && entity.entityType === 'program' ? (
            <ProgramProgressBar entity={entity} />
          ) : (
            withProgressLine && <ProgressLine $progress={entity.learnerContext.progress} />
          )}
        </>
      }
      renderLink={({ children }) => (
        <RouterLink href={detailsUrl(linkable)} onClick={onCardClick}>
          {children}
        </RouterLink>
      )}
      centredContentIcon={
        <LockedContentTypeIcon>
          <Icon iconId='locked' size='size-24' color='white' />
        </LockedContentTypeIcon>
      }
      title={entity.title}
      {...props}
    />
  )
})
