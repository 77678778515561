import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { usePreferredTimezone } from 'sierra-client/api/hooks/use-user'
import { useConfirmationModalContext } from 'sierra-client/components/common/modals/confirmation-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToContentAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getRowDataFromTableAPI } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ColumnLayout } from 'sierra-client/views/manage/components/layout/column-layout'
import {
  FeaturedContentDialog,
  FeaturedContentDialogItem,
} from 'sierra-client/views/manage/content/components/featured-content-dialog'
import { useFeaturedContentIds } from 'sierra-client/views/manage/content/hooks/use-featured-content'
import { CreateEventGroupPanel } from 'sierra-client/views/manage/event-groups/create-event-group-panel'
import { getGqlEventScheduleEnd } from 'sierra-client/views/manage/event-groups/event-utils'
import {
  EventGroupsData,
  ManageEventGroupsTabular,
  ManageEventGroupsTabularActions,
  useManageEventGroupsTableAPI,
} from 'sierra-client/views/manage/event-groups/manage-event-groups-tabular'
import { assertIsNonNullable, isNotDefined } from 'sierra-domain/utils'

const filterForEventGroups = `{"type":"filter.and","filters":[{"type":"filter.filter","domain":{"type":"content.classification"},"operator":{"type":"operator.eq"},"predicate":{"type":"predicate.or","values":[{"type":"value.string","value":"event"}]}}]}`

const eventGroupQuery = graphql(`
  query eventGroupTable($filter: ContentFilter!, $limit: Int!, $sortBy: [ContentSortAttributeInput!]!) {
    content(limit: $limit, filter: $filter, sortBy: $sortBy, contentTypes: [COURSE]) {
      data {
        __typename
        title
        description
        image {
          ...ImageFragment
        }
        featured
        assignmentCount

        ... on NativeEventGroup {
          courseKind
          visibility
          courseId
          isDefaultRequiredAssignment

          calendarEventsWithApprovalRequests {
            ...EventGroupPageDetails_eventsWithApprovalRequests
          }

          calendarEvents {
            id
            assignedUsers {
              user {
                id
              }
            }
            facilitators {
              id
              firstName
              lastName
            }
            description
            schedule {
              ...CalendarEventScheduleFragment
            }
            location {
              ...CalendarEventLocationFragment
            }
          }
        }
      }
    }
  }
`)

export const ManageEventGroups: React.FC = () => {
  const { t } = useTranslation()
  const { assignWithDueDate } = useDueDate()
  const { featuredContentIds, refetch: refetchFeaturedContentIds } = useFeaturedContentIds()
  const confirmationModalContext = useConfirmationModalContext()
  const orgPermissions = useOrganizationPermissions()
  const canCreateEventGroups = orgPermissions.has('CREATE_EVENT_GROUP')
  const canEditNewCourseVisibleEverywhere = orgPermissions.has('CREATE_CONTENT_SET_VISIBLE_EVERYWHERE')
  const canEditAssignments = orgPermissions.has('EDIT_CONTENT_ASSIGNMENTS')
  const dispatch = useDispatch()

  const [action, setAction] = useState<
    | { modal: undefined; id?: never }
    | { modal: 'create-event-group'; id?: never }
    | { modal: 'assign-event-group'; id: string; isDefaultRequiredAssignment: boolean }
    | { modal: 'feature-event-group-on-home'; eventGroupItem: FeaturedContentDialogItem; id?: never }
  >({
    modal: undefined,
  })

  const queryRes = useGraphQuery(
    { document: eventGroupQuery },
    {
      filter: filterForEventGroups,
      limit: 500,
      sortBy: [
        { key: 'FEATURED', order: 'DESC' },
        { key: 'ADDED', order: 'ASC' },
      ],
    }
  )

  const handleOpenFeaturedContentModal = React.useCallback(
    (eventGroupItem: FeaturedContentDialogItem): void => {
      setAction({ modal: 'feature-event-group-on-home', eventGroupItem })
    },
    [setAction]
  )

  const tabularActions: ManageEventGroupsTabularActions = React.useMemo<ManageEventGroupsTabularActions>(
    () => ({
      onViewEventGroupDetails: id => {
        void getGlobalRouter().navigate({ to: `/manage/in-person-events/${id}` })
      },
      onAssignEventGroup: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.eventGroup.data
        assertIsNonNullable(data)

        setAction({
          modal: 'assign-event-group',
          id,
          isDefaultRequiredAssignment: data.isDefaultRequiredAssignment === true,
        })
      },
      onEditEventGroupFeatureOnHome: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.eventGroup.data
        if (isNotDefined(data)) return

        handleOpenFeaturedContentModal(data)
      },
      onAddEventGroupFeatureOnHome: (id, api) => {
        const data = getRowDataFromTableAPI(api, id)?.eventGroup.data

        if (isNotDefined(data)) return

        if (featuredContentIds.find(x => x === id) !== undefined) {
          confirmationModalContext.show({
            bodyText: t('manage.content.actions.feature-another-content-warning'),
            onConfirm: () => {
              handleOpenFeaturedContentModal(data)
            },
          })
        } else {
          handleOpenFeaturedContentModal(data)
        }
      },
    }),
    [confirmationModalContext, featuredContentIds, handleOpenFeaturedContentModal, t]
  )

  const data = useMemo(
    () =>
      queryRes.data?.content.data
        .filter(function (it): it is EventGroupsData {
          return it.__typename === 'NativeEventGroup'
        })
        // We want to bubble up event groups that have events scheduled in the future to make
        // the list easier to work with
        .sort((a, b) => {
          if (a.featured && !b.featured) {
            return -1
          } else if (!a.featured && b.featured) {
            return 1
          }

          const futureCalendarEventsA = a.calendarEvents.filter(
            ce => getGqlEventScheduleEnd(ce.schedule) > DateTime.now()
          )
          const futureCalendarEventsB = b.calendarEvents.filter(
            ce => getGqlEventScheduleEnd(ce.schedule) > DateTime.now()
          )

          return futureCalendarEventsB.length - futureCalendarEventsA.length
        }),
    [queryRes.data?.content.data]
  )

  const timezoneQuery = usePreferredTimezone()
  const timezone = timezoneQuery.data

  const tableAPI = useManageEventGroupsTableAPI(tabularActions, data)

  return (
    <ColumnLayout>
      <ManageEventGroupsTabular
        onCreateEventGroup={() => setAction({ modal: 'create-event-group' })}
        tableAPI={tableAPI}
        hideCounts={false}
      />
      {timezone !== undefined && (
        <CreateEventGroupPanel
          open={canCreateEventGroups && action.modal === 'create-event-group'}
          canEditVisibleEverywhere={canEditNewCourseVisibleEverywhere}
          canEditAssignments={canEditAssignments}
          onClose={() => setAction({ modal: undefined })}
          timezone={timezone}
        />
      )}
      {action.modal === 'assign-event-group' && (
        <AssignModal
          isOpen
          config={{
            subjectType: 'course',
            panes: 'user-and-user-group',
            activePane: 'user',
            showDueDates: false,
            subjectsSupportAssignmentSettings: true,
            autoAssignmentAvailable: true,
            onSave: async selections => {
              const result = await assignWithDueDate(
                parseModalToContentAssignment(
                  [
                    {
                      id: action.id,
                      type: 'course',
                      isDefaultRequiredAssignment: action.isDefaultRequiredAssignment,
                    },
                  ],
                  selections
                )
              )
              if (result?.error !== undefined) return

              selections.forEach(selection => {
                void dispatch(
                  assignmentPriorityLogger({
                    contentType: 'event-group',
                    assignmentPriority: selection.assignmentPriority,
                    hasDueDate: selection.dueDate !== undefined,
                    contentId: action.id,
                    userId: selection.id,
                  })
                )
              })

              setAction({ modal: undefined })
            },
          }}
          subjects={action.id}
          title={t('dictionary.enrollments')}
          onClose={() => {
            setAction({ modal: undefined })
          }}
        />
      )}
      {action.modal === 'feature-event-group-on-home' && (
        <FeaturedContentDialog
          isOpen
          onClose={() => {
            setAction({ modal: undefined })
            void refetchFeaturedContentIds()
            void queryRes.refetch()
          }}
          content={action.eventGroupItem}
        />
      )}
    </ColumnLayout>
  )
}
