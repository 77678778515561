import { resolveUserAvatar } from 'sierra-client/api/content'
import { useUsersLegacy } from 'sierra-client/state/users/hooks'
import { UserId } from 'sierra-domain/api/uuid'
import { isDefined } from 'sierra-domain/utils'
import { AvatarStack } from 'sierra-ui/components'
import { View } from 'sierra-ui/primitives'

export const AvatarCell: React.FC<{ reviewerIds: UserId[] }> = ({ reviewerIds }) => {
  const resolvedUsers = useUsersLegacy(reviewerIds).filter(isDefined).map(resolveUserAvatar)
  return (
    <View>
      {resolvedUsers.length > 0 && <AvatarStack size='small' max={4} withTooltips users={resolvedUsers} />}
    </View>
  )
}
