import React, { createContext } from 'react'

/**
 * Context to allow you to disable things based on certain conditions and reasons
 * */
export type DisabledInputContext = { disabled: true; reason: string } | { disabled: false }
export const DisabledInputContext = createContext<DisabledInputContext>({ disabled: false })

export const getDisabledReason = (context: DisabledInputContext): string | undefined => {
  if (context.disabled) {
    return context.reason
  }
  return undefined
}

export function useDisableInput(): DisabledInputContext {
  return React.useContext(DisabledInputContext)
}
