import { useQuery } from '@tanstack/react-query'
import { maxBy } from 'lodash'
import React, { useMemo } from 'react'
import { config } from 'sierra-client/config/global-config'
import { liveSessionSummaryChannel } from 'sierra-client/realtime-data/channels'
import { typedPost } from 'sierra-client/state/api'
import { GetLiveSessionSummaryResponse } from 'sierra-domain/api/live-session'
import { LiveSessionId } from 'sierra-domain/api/nano-id'
import { getLiveChannelName } from 'sierra-domain/live/get-live-channel-name'
import { XRealtimeStrategyLiveSessionGetSummary } from 'sierra-domain/routes'
import { z } from 'zod'

const CACHE_KEY = 'auto_summary' as const

export const NoteFormat = z.object({
  summary: z.string(),
  topics: z.array(
    z.object({
      topic: z.string(),
      notes: z.array(z.string()),
    })
  ),
  actions: z.array(z.string()).optional(),
})

export type NoteFormat = z.infer<typeof NoteFormat>

export const tryJsonFormat = (summary: string): NoteFormat | undefined => {
  try {
    const res: unknown = JSON.parse(summary)
    return NoteFormat.parse(res)
  } catch (e) {
    return undefined
  }
}

export type SummaryResult =
  | { format: 'note'; data: NoteFormat }
  | { format: 'string'; data: string }
  | { format: 'unavailable' }

export const useAutoSummary = (liveSessionId: LiveSessionId): SummaryResult | undefined => {
  const [latestRemoteSummary, setLatestRemoteSummary] = React.useState<
    GetLiveSessionSummaryResponse | undefined
  >(undefined)
  const tenantId = config.organization.tenantId
  const channelName = getLiveChannelName(tenantId, liveSessionId)

  liveSessionSummaryChannel.useChannelEvent({
    channelId: `${liveSessionId}:${liveSessionId}`,
    event: 'summary-updated',
    eventId: `${liveSessionId}:${liveSessionId}`,
    callback: data => {
      setLatestRemoteSummary(prev => maxBy([prev, data], value => new Date(value?.updatedAt ?? 0).getTime()))
    },
  })

  const queryResult = useQuery({
    queryKey: [CACHE_KEY, liveSessionId, channelName],
    queryFn: async () => {
      const fetchResult = await typedPost(XRealtimeStrategyLiveSessionGetSummary, {
        liveSessionId,
        channelName,
      })
      return fetchResult
    },
  })

  const summaryData = useMemo(
    () => maxBy([queryResult.data, latestRemoteSummary], value => new Date(value?.updatedAt ?? 0).getTime()),
    [latestRemoteSummary, queryResult.data]
  )

  const noteObj = useMemo((): SummaryResult | undefined => {
    if (summaryData?.status === 'unavailable') return { format: 'unavailable' }
    if (summaryData?.summary !== undefined) {
      const asNote = tryJsonFormat(summaryData.summary)
      if (asNote)
        return {
          format: 'note',
          data: asNote,
        }

      return {
        format: 'string',
        data: summaryData.summary,
      }
    }

    return undefined
  }, [summaryData])

  return noteObj
}
