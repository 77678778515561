import React from 'react'
import { useDisableInput } from 'sierra-client/components/disabling'
import { NotificationType, UserNotificationSetting } from 'sierra-domain/user'
import { Icon, IconId } from 'sierra-ui/components'
import { Checkbox, Text, View } from 'sierra-ui/primitives'

type NotificationHeaderProps = {
  label: React.ReactNode
  iconId?: IconId
}
export const NotificationHeader: React.FC<NotificationHeaderProps> = ({ label, iconId }) => {
  return (
    <View>
      {iconId && <Icon iconId={iconId} />}
      <Text size='regular' bold>
        {label}
      </Text>
    </View>
  )
}

type NotificationInputProps = {
  notificationType: NotificationType
  label: string
  value: UserNotificationSetting | undefined
  onChange: (value: UserNotificationSetting) => void
}

export const NotificationInput: React.FC<NotificationInputProps> = ({
  notificationType,
  label,
  value,
  onChange,
}) => {
  const disableInputContext = useDisableInput()
  if (value === undefined) return null

  return (
    <View alignItems='center' justifyContent='space-between'>
      <Text>{label}</Text>
      <Checkbox
        checked={value[notificationType] ?? true}
        disabled={disableInputContext.disabled}
        onCheckedChange={() => {
          const newValue = { ...value }
          newValue[notificationType] = !(newValue[notificationType] ?? false)
          onChange(newValue)
        }}
        aria-label={label}
      />
    </View>
  )
}
