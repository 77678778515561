import { FC } from 'react'
import { getGlobalRouter } from 'sierra-client/router'
import {
  HeadingContainer,
  ImageContainer,
  ProgressLineWithLayout,
  StepContentContainer,
  StepInfoContainer,
  StepInfoHeader,
  StepProgressBarWrapper,
  TextMaxLines,
} from 'sierra-client/views/learner/program/atoms'
import { StepMetaData } from 'sierra-client/views/learner/program/components/meta-data'
import { StepUpcomingLiveSession } from 'sierra-client/views/learner/program/types'
import {
  EnrollmentStepType,
  getContentIdFromStep,
  getResettableContentTypeFromStep,
  getStepAssetContext,
  getStepImage,
  getStepTitle,
} from 'sierra-client/views/learner/program/utils/step-graphql'
import {
  convertStepToLinkable,
  getContinueURLForStep,
  getExercisesFromStep,
  getHomeworkStates,
  getStepContentType,
  getStepProgress,
  isStepCompleted,
} from 'sierra-client/views/learner/program/utils/utils'

import { useQuery } from '@tanstack/react-query'
import { usePost } from 'sierra-client/hooks/use-post'
import { isEmailEnrollmentStep } from 'sierra-client/views/learner/program/utils/step-graphql'
import { XRealtimeUserResetCourse } from 'sierra-domain/routes'

import { ExerciseState } from 'sierra-client/api/graphql/gql/graphql'
import { SanaImage } from 'sierra-client/components/common/image'
import { LearnerStepBadge } from 'sierra-client/features/program'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useIsMobile } from 'sierra-client/state/browser/selectors'
import { useSortedEditions } from 'sierra-client/views/learner/course-group/hooks'
import { StepButton } from 'sierra-client/views/learner/program/components/step-button'
import { StepContentLabel } from 'sierra-client/views/learner/program/components/step-content-label'
import { detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import { getPrioritizedHomework } from 'sierra-domain/homework/utils'
import { XRealtimeUserCanResetCourse } from 'sierra-domain/routes'
import { isDefined, isNonEmptyArray, isNotDefined } from 'sierra-domain/utils'
import { InternalTruncatedText, View } from 'sierra-ui/primitives'

type SubContentControls = {
  togglePathSubContent: () => void
  collapseButton: boolean
}

type EnrolledStepContentProps = {
  step: EnrollmentStepType
  programId: string
  locked: boolean
  parentPathId: string | undefined
  upcomingSelfEnrollmentLiveSessions: StepUpcomingLiveSession[] | undefined
  subContentControls: SubContentControls
  isNextStep: boolean
  displayIndex?: number
}

const useStepTitle = (step: EnrollmentStepType): string => {
  const contentType = getStepContentType(step)
  const isCourseGroup = contentType === 'native:course-group' || contentType === 'scorm:course-group'
  const courseGroupId = isCourseGroup ? getContentIdFromStep(step) ?? undefined : undefined
  const editionsWithStatus = useSortedEditions({ courseGroupId })
  const selectedEdition = editionsWithStatus?.[0]
  const displayTitle = selectedEdition?.data.title ?? getStepTitle(step)
  return displayTitle
}

export const EnrolledStepContent: FC<EnrolledStepContentProps> = ({
  step,
  programId,
  locked,
  parentPathId,
  upcomingSelfEnrollmentLiveSessions,
  subContentControls,
  isNextStep,
  displayIndex,
}) => {
  const { postWithUserErrorException } = usePost()
  const isMobile = useIsMobile()

  const stepLinkable = convertStepToLinkable(step)
  const contentDetailUrl = stepLinkable === null || locked === true ? undefined : detailsUrl(stepLinkable)
  const continueUrl =
    (!locked &&
      getContinueURLForStep({ step, upcomingSelfEnrollmentLiveSessions, programId, parentPathId })) ||
    undefined

  const canResetResponse = useQuery({
    queryKey: [XRealtimeUserCanResetCourse.path],
    queryFn: async () => {
      if (isEmailEnrollmentStep(step)) {
        return false
      }

      const res = await postWithUserErrorException(XRealtimeUserCanResetCourse, {
        contentType: getResettableContentTypeFromStep(step),
        contentId: getContentIdFromStep(step),
      })
      return res.canReset
    },
  })

  const resetProgress = async (): Promise<void> => {
    const contentType = getResettableContentTypeFromStep(step)
    const contentId = getContentIdFromStep(step)

    if (contentType === null || contentId === null) {
      // TODO:
      return
    }

    await postWithUserErrorException(XRealtimeUserResetCourse, {
      contentType,
      contentId,
    })
  }

  const startOver = async (): Promise<void> => {
    await resetProgress()
    if (isDefined(stepLinkable)) {
      void getGlobalRouter().navigate({ to: contentDetailUrl as string })
    }
  }

  const hasHardFailedExercise = (exercises?: ExerciseState[]): boolean => {
    if (isNotDefined(exercises)) return false

    if (isNonEmptyArray(exercises)) {
      const homeworkStates = getHomeworkStates(exercises)
      const selectedHomework = getPrioritizedHomework(homeworkStates)
      if (selectedHomework?.grade === 'failed-with-no-retries') return true
    }

    return false
  }

  const stepTitle = useStepTitle(step)
  const courseProgress = getStepProgress(step)
  const completed = isStepCompleted(step)
  const oldImageType = getStepImage(step)
  const contentType = getStepContentType(step)
  const assetContext = getStepAssetContext(step)
  const exercises = getExercisesFromStep(step)

  const imageSrc = useResolveAsset({
    image: oldImageType ?? undefined,
    size: 'course-sm',
    assetContext,
  })

  return (
    <View alignItems='center' gap='16' grow>
      <StepContentContainer locked={locked} href={contentDetailUrl}>
        <ImageContainer>
          {contentType !== 'email' && !isMobile && <StepContentLabel contentType={contentType} />}
          <SanaImage src={imageSrc} ratio='16:9' rounded radius='size-20' />
          {courseProgress > 0 && (
            <StepProgressBarWrapper>
              <ProgressLineWithLayout $progress={courseProgress} />
            </StepProgressBarWrapper>
          )}
        </ImageContainer>
        <StepInfoContainer marginLeft={isMobile ? '12' : '24'} direction='column'>
          <StepInfoHeader>
            <View direction='column' gap='2' paddingTop={isMobile ? undefined : '8'}>
              <HeadingContainer>
                <View>
                  <TextMaxLines size={isMobile ? 'small' : 'regular'} bold lines={1} title={stepTitle}>
                    {isDefined(displayIndex) && `${displayIndex + 1}.`} {stepTitle}
                  </TextMaxLines>
                  <LearnerStepBadge step={step} locked={locked} />
                </View>
              </HeadingContainer>

              <StepMetaData
                step={step}
                upcomingSelfEnrollmentLiveSessions={upcomingSelfEnrollmentLiveSessions}
                {...subContentControls}
              />
            </View>
            {contentType !== 'email' && (
              <StepButton
                url={continueUrl}
                contentDetailUrl={contentDetailUrl}
                progress={courseProgress}
                completed={completed}
                exercises={exercises}
                locked={locked}
                contentType={contentType}
                disableStart={isNonEmptyArray(upcomingSelfEnrollmentLiveSessions)}
                isNextStep={isNextStep}
                disableReset={courseProgress === 0 || !(canResetResponse.data ?? false)}
                isHardFailed={!completed && hasHardFailedExercise(exercises)}
                onReset={startOver}
              />
            )}
          </StepInfoHeader>
          {isDefined(step.content) && !isMobile && (
            <InternalTruncatedText color='foreground/secondary' lines={3}>
              {step.content.description?.replace(/<[^>]*>?/gm, '')}
            </InternalTruncatedText>
          )}
        </StepInfoContainer>
      </StepContentContainer>
    </View>
  )
}
