import { useMemo } from 'react'
import { InView } from 'react-intersection-observer'
import { getCourseDefaultImage } from 'sierra-client/api/content'
import { CardContext } from 'sierra-client/components/recommendations/components/card-context'
import { CreateEntityActionButton } from 'sierra-client/components/recommendations/components/create-entity-action-button'
import { CreateEntityDetail } from 'sierra-client/components/recommendations/components/create-entity-detail'
import { CreateNewActionButton } from 'sierra-client/components/recommendations/components/create-new-action-button'
import { LearnEntityActionButton } from 'sierra-client/components/recommendations/components/learn-entity-actionbutton'
import { LearnEntityDetail } from 'sierra-client/components/recommendations/components/learn-entity-detail'
import { useCardAction } from 'sierra-client/components/recommendations/use-card-action'
import { Logging } from 'sierra-client/core/logging'
import { HomeRecommendationClickProps } from 'sierra-client/core/logging/recommendation/logger'
import { useAssetResolver } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { getGlobalRouter } from 'sierra-client/router'
import { useDispatch } from 'sierra-client/state/hooks'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { LearnEntity } from 'sierra-domain/api/entities'
import { InfoCard } from 'sierra-domain/api/infocard'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { Recommendation } from 'sierra-domain/api/recommendations'
import { ProgramId } from 'sierra-domain/api/uuid'
import { AssetContext } from 'sierra-domain/asset-context'
import { assertNever, iife, isDefined } from 'sierra-domain/utils'
import { Icon } from 'sierra-ui/components'
import { RecommendationCardBase } from 'sierra-ui/missions/search/recommendations'
import { Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const RelativeContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const CenterAbsoluteContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -65%);
`

const DefaultAboveTitle: React.FC<{ entity: LearnEntity | undefined }> = ({ entity }) => {
  const { t } = useTranslation()

  if (entity?.entityType !== 'program') {
    return null
  }

  return <Text bold>{t('dictionary.program-singular')}</Text>
}

const RecommendationCardComponent: React.FC<{ recommendation: Recommendation }> = ({ recommendation }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const card: InfoCard = recommendation.card

  const createLogOnClick =
    (clickProps: HomeRecommendationClickProps): (() => void) =>
    (): void => {
      void dispatch(Logging.recommendation.homeRecommendationClicked(clickProps))
    }

  const assetContext: AssetContext = useMemo(() => {
    return iife(() => {
      switch (card.type) {
        case 'popular':
        case 'assigned':
        case 'discover':
        case 'recently-assigned':
        case 'upcoming-due-date':
        case 'overdue':
        case 'self-enroll-to-session':
        case 'in-progress':
        case 'upcoming-session':
        case 'ongoing-session':
        case 'recently-edited':
        case 'continue-editing':
        case 'added-as-collaborator':
        case 'featured':
        case 'invited-to-collaborate':
        case 'others-are-learning':
        case 'new-comments':
        case 'recap':
          switch (card.entity.entityType) {
            case 'live-session':
              return { type: 'course' as const, courseId: CourseId.parse(card.entity.backingCourse) }

            case 'native:live':
            case 'native:self-paced':
            case 'native:course-group':
            case 'native:event-group':
            case 'scorm':
            case 'scorm:course-group':
            case 'link':
            case 'linkedin':
              return { type: 'course' as const, courseId: CourseId.parse(card.entity.id) }

            case 'path':
              return { type: 'path' as const, pathId: PathId.parse(card.entity.id) }
            case 'program':
              return { type: 'program' as const, programId: ProgramId.parse(card.entity.id) }

            default:
              assertNever(card.entity)
          }
          break

        case 'create-suggestion':
          return { type: 'unknown' }

        default:
          assertNever(card)
      }
    })
  }, [card])

  const assetResolver = useAssetResolver({ size: 'default' })

  switch (card.type) {
    case 'popular':
    case 'assigned':
    case 'discover':
    case 'recently-assigned':
    case 'upcoming-due-date':
      return (
        <RecommendationCardBase
          title={card.entity.title}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          theme={card.entity.theme}
          topDetail={<CardContext card={card} />}
          aboveTitle={<DefaultAboveTitle entity={card.entity} />}
          belowTitle={<LearnEntityDetail type={card.type} entity={card.entity} />}
          actionButtons={
            <LearnEntityActionButton
              onClick={createLogOnClick({ type: card.type })}
              type={card.type}
              entity={card.entity}
            />
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'overdue':
    case 'self-enroll-to-session':
    case 'in-progress':
      return (
        <RecommendationCardBase
          title={card.entity.title}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          theme={card.entity.theme}
          topDetail={<CardContext card={card} />}
          aboveTitle={<DefaultAboveTitle entity={card.entity} />}
          belowTitle={<LearnEntityDetail type={card.type} entity={card.entity} />}
          actionButtons={
            <LearnEntityActionButton
              onClick={createLogOnClick({ type: card.type })}
              type={card.type}
              entity={card.entity}
            />
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'upcoming-session':
      return (
        <RecommendationCardBase
          title={card.entity.title}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          theme={card.entity.theme}
          topDetail={<CardContext card={card} />}
          aboveTitle={<DefaultAboveTitle entity={card.entity} />}
          belowTitle={<LearnEntityDetail type={card.type} entity={card.entity} />}
          actionButtons={
            <LearnEntityActionButton
              onClick={createLogOnClick({ type: card.type })}
              type={card.type}
              entity={card.entity}
            />
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'ongoing-session':
      return (
        <RecommendationCardBase
          title={card.entity.title}
          theme={card.entity.theme}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          topDetail={<CardContext card={card} />}
          aboveTitle={<DefaultAboveTitle entity={card.entity} />}
          belowTitle={<LearnEntityDetail type={card.type} entity={card.entity} />}
          actionButtons={
            <LearnEntityActionButton
              onClick={createLogOnClick({ type: card.type })}
              type={card.type}
              entity={card.entity}
            />
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'recently-edited':
    case 'continue-editing':
    case 'added-as-collaborator':
      return (
        <RecommendationCardBase
          title={card.entity.title}
          theme={card.entity.theme}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          topDetail={<CardContext card={card} />}
          aboveTitle={undefined}
          belowTitle={<CreateEntityDetail recommendationType={card.type} entity={card.entity} />}
          actionButtons={
            <View>
              <CreateEntityActionButton
                onClick={createLogOnClick({ type: card.type })}
                entity={card.entity}
              />
            </View>
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'create-suggestion':
      return (
        <RelativeContainer>
          <RecommendationCardBase
            title={t('recommendation.create-course-suggestion')}
            theme={card.theme}
            topDetail={<CardContext card={card} />}
            aboveTitle={
              <CenterAbsoluteContainer>
                <Icon iconId='add' size='size-80' color='foreground/primary' />
              </CenterAbsoluteContainer>
            }
            belowTitle={undefined}
            actionButtons={
              <View>
                <CreateNewActionButton onClick={createLogOnClick({ type: card.type })} />
              </View>
            }
            getFallbackBackgroundUrl={getCourseDefaultImage}
          />
        </RelativeContainer>
      )
    case 'featured':
      return (
        <RecommendationCardBase
          title={card.entity.feature.title}
          backgroundUrl={
            isDefined(card.entity.image) ? assetResolver(card.entity.image, assetContext) : undefined
          }
          theme={card.entity.theme}
          topDetail={<CardContext card={card} />}
          aboveTitle={<DefaultAboveTitle entity={card.entity} />}
          belowTitle={
            <View direction='column'>
              {card.entity.feature.description !== undefined ? (
                <View marginTop={'xxsmall'}>
                  <Text color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP' size='regular'>
                    {card.entity.feature.description}
                  </Text>
                </View>
              ) : null}
              {card.entity.feature.showMetadata && (
                <View>
                  <LearnEntityDetail type={card.type} entity={card.entity} />
                </View>
              )}
            </View>
          }
          actionButtons={
            <LearnEntityActionButton
              onClick={createLogOnClick({ type: card.type })}
              type={card.type}
              entity={card.entity}
              buttonText={card.entity.feature.buttonText}
            />
          }
          getFallbackBackgroundUrl={getCourseDefaultImage}
        />
      )
    case 'invited-to-collaborate':
    case 'new-comments':
    case 'others-are-learning':
    case 'recap': {
      console.error('Recommendation type not implemented', card.type)
      return <div />
    }
    default:
      assertNever(card)
  }
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
`

const ClickHandler: React.FC<React.PropsWithChildren<{ recommendation: Recommendation }>> = ({
  recommendation,
  children,
}) => {
  const action = useCardAction(recommendation.card)

  if (!isDefined(action))
    return <Debug>Unable to resolve action for card type {recommendation.card.type}</Debug>

  if ('onClick' in action) {
    return <Wrapper onClick={action.onClick}>{children}</Wrapper>
  }

  if ('href' in action) {
    return <Wrapper onClick={() => getGlobalRouter().navigate({ to: action.href })}>{children}</Wrapper>
  }

  console.error('Could not find matching action for recommendation:', recommendation)
  return <Wrapper>{children}</Wrapper>
}

const CardInViewObserver = styled(InView)`
  width: 100%;
  height: 100%;
`

export const RecommendationCard: typeof RecommendationCardComponent = props => {
  const dispatch = useDispatch()

  const createViewLog = (inView: boolean): void => {
    if (inView)
      void dispatch(
        Logging.recommendation.homeRecommendationImpression({
          type: props.recommendation.card.type,
        })
      )
  }

  return (
    <CardInViewObserver threshold={1} triggerOnce onChange={createViewLog}>
      <ClickHandler recommendation={props.recommendation}>
        <RecommendationCardComponent {...props} />
      </ClickHandler>
    </CardInViewObserver>
  )
}
