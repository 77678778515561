import { useAtom, useAtomValue } from 'jotai'
import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { CSVLink } from 'react-csv'
import { RequiredAssignmentMenuItem } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { getFlag } from 'sierra-client/config/global-config'
import { useHasOrganizationPermission } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { assignedProgramColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton, getCsvFileName } from 'sierra-client/views/manage/components/export-csv'
import { RoundedSearchBar } from 'sierra-client/views/manage/components/rounded-search-bar'
import { UserDetailsTabularTable } from 'sierra-client/views/manage/users/components/user-details-tabular-table'
import { UserModalActionsProps } from 'sierra-client/views/manage/users/components/user-modal-actions'
import { useGetManageProgramURL } from 'sierra-client/views/manage/utils/use-manage-program-url'
import { ProgramGroupMembership } from 'sierra-domain/api/manage'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminUsersSetProgramIsRequiredAssignments } from 'sierra-domain/routes'
import { Button, Spacer, View } from 'sierra-ui/primitives'

type ProgramGroup = ProgramGroupMembership

type ProgramGroupTableDefinition = TableDefinitionOf<
  ProgramGroup,
  [{ type: 'assignedProgram'; ref: 'groupName' }, { type: 'strings'; ref: 'admin' }]
>

type ProgramGroupTableData = TableDataFromDefinition<ProgramGroup, ProgramGroupTableDefinition>
type ProgramGroupTableMeta = StaticDataLoaderMeta<ProgramGroupTableData>

const searchKey: StaticLoaderSearchKeyBy<ProgramGroupTableData> = (tableData, row) => {
  const data = tableData.rows[row]?.data
  return [data?.groupName.data, data?.admin.data].join('')
}

const tableDefinition = (): ProgramGroupTableDefinition => {
  return {
    columns: [
      assignedProgramColumn({
        sortable: true,
        getData: r =>
          r.groupInfo.groupName !== undefined
            ? {
                name: r.groupInfo.groupName,
                isRequired: r.isRequired,
              }
            : undefined,
        header: translatedLabel('table.name'),
        ref: 'groupName',
        hints: ['bold'],
      }),
      stringsColumn({
        sortable: true,
        header: translatedLabel('table.admin'),
        ref: 'admin',
        getData: r => r.groupInfo.adminName,
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.groupInfo.groupId,
    },
  }
}

const useProgramGroupTable = ({
  data,
  unassignProgramGroups,
  setUsersIsRequiredProgram,
}: {
  data: ProgramGroup[]
  unassignProgramGroups: (ids: string[]) => void
  setUsersIsRequiredProgram: (assignments: { programId: string; isRequired: boolean }[]) => void
}): UseTableAPI<ProgramGroupTableData, ProgramGroupTableMeta> => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  const loader = staticDataLoader(definition2Data(tableDefinition(), data), searchKey)
  const getManageProgramURL = useGetManageProgramURL()

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const disabledRequiredAssignmentSwitch = row.rawData.isDirectlyAssigned === false
            return {
              menuItems: [
                {
                  type: 'label',
                  id: 'view-details',
                  label: t('manage.view-course-details'),
                  onClick: () =>
                    getGlobalRouter().navigate({ to: getManageProgramURL(row.rawData.groupInfo.groupId) }),
                  icon: 'user--group',
                },
                {
                  type: 'canvas',
                  id: 'set-is-required',
                  hidden: getFlag('required-assignments') === false,
                  disabled: disabledRequiredAssignmentSwitch,
                  render() {
                    return (
                      <RequiredAssignmentMenuItem
                        disabledWithReason={
                          disabledRequiredAssignmentSwitch
                            ? 'This program is self-enrolled by the user'
                            : undefined
                        } // TODO(required-content): translations and copy
                        assignmentPriority={row.rawData.isRequired ? 'required' : 'normal'}
                        setAssignmentPriority={val => {
                          setUsersIsRequiredProgram([
                            { programId: row.rawData.groupInfo.groupId, isRequired: val === 'required' },
                          ])
                        }}
                      />
                    )
                  },
                },
                {
                  type: 'label',
                  id: 'remove-assignment',
                  label: t('admin.remove'),
                  hidden: !canEditAssignments,
                  onClick: () => unassignProgramGroups([row.rawData.groupInfo.groupId]),
                  icon: 'user--remove',
                  color: 'destructive/background',
                },
              ],
            }
          },
        }),
      ],
    },
  })
}

type SearchSectionProps = {
  tableAPI: UseTableAPI<ProgramGroupTableData, ProgramGroupTableMeta>
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
}

const SearchSection: React.FC<SearchSectionProps> = ({ tableAPI, setUserAction }) => {
  const { t } = useTranslation()
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  const query = useAtomValue(tableAPI.api.atoms.query)

  return (
    <View justifyContent='space-between'>
      <RoundedSearchBar
        value={query}
        onChange={query => tableAPI.api.action.setQuery({ query })}
        placeholder={t('manage.search.programs')}
      />
      {canEditAssignments && (
        <View>
          <Button onClick={() => setUserAction({ modal: 'assign-programs' })}>
            {t('manage.manage-programs')}
          </Button>
        </View>
      )}
    </View>
  )
}

const programGroupToCsv = (group: ProgramGroupMembership): Record<string, string> => ({
  groupId: group.groupInfo.groupId,
  groupName: group.groupInfo.groupName ?? '',
  groupAdmin: group.groupInfo.adminName ?? '',
  assignedAt: new Date(group.assignedAt).toISOString(),
})

type ToolbarProps = {
  tableAPI: UseTableAPI<ProgramGroupTableData, ProgramGroupTableMeta>
  mapCsv: (ids: string[]) => Record<string, string>[]
  unassignProgramGroups: (ids: string[]) => void
}

const Toolbar: React.FC<ToolbarProps> = ({ tableAPI, mapCsv, unassignProgramGroups }) => {
  const { api } = tableAPI
  const canEditAssignments = useHasOrganizationPermission('EDIT_CONTENT_ASSIGNMENTS')
  const { t } = useTranslation()
  const csvButtonText = `${t('manage.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        totalKey: 'manage.n-programs',
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type === 'manual' ? (
          <>
            <CSVLink
              data={mapCsv(Array.from(selection.rows))}
              filename={getCsvFileName(t('admin.analytics.programs'))}
            >
              <ActionButton color='blueBright'>{csvButtonText}</ActionButton>
            </CSVLink>
            {canEditAssignments && (
              <ActionButton
                color='redBright'
                onClick={() => unassignProgramGroups(Array.from(selection.rows))}
              >
                {t('dictionary.unassign')}
              </ActionButton>
            )}
            <ActionButton color='redBright' onClick={() => api.action.setSelection({ type: 'none' })}>
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const Footer: React.FC<{ fetchCsv: () => Promise<Record<string, string>[]> }> = ({ fetchCsv }) => {
  const { t } = useTranslation()
  return (
    <View marginBottom='32' marginTop='32' justifyContent='flex-end'>
      <ExportCSVIconButton fetchCsvData={fetchCsv} filename={t('admin.analytics.programs')} />
    </View>
  )
}
export type ProgramGroupsTableProps = {
  programMemberships: ProgramGroup[]
  setUserAction: Dispatch<SetStateAction<UserModalActionsProps['action']>>
  userId: UserId
  refetch: () => void
}

export const ProgramGroupsTable: React.FC<ProgramGroupsTableProps> = ({
  programMemberships,
  setUserAction,
  userId,
  refetch,
}) => {
  const getManageProgramURL = useGetManageProgramURL()
  const { postWithUserErrorException } = usePost()

  const unassignProgramGroups = (ids: string[]): void => {
    setUserAction({
      modal: 'unassign',
      messageType: 'group',
      targets: ids.map(id => ({ id, type: 'group' })),
    })
  }

  const setUsersIsRequiredProgram = useCallback<
    (assignments: { programId: string; isRequired: boolean }[]) => Promise<void>
  >(
    async assignments => {
      await postWithUserErrorException(XRealtimeAdminUsersSetProgramIsRequiredAssignments, {
        userIds: [userId],
        assignments,
      })

      refetch()
    },
    [postWithUserErrorException, userId, refetch]
  )

  const tableAPI = useProgramGroupTable({
    data: programMemberships,
    unassignProgramGroups,
    setUsersIsRequiredProgram,
  })

  return (
    <TabularProviderFromTableAPI
      tableAPI={tableAPI}
      callbacks={{
        onRow: ({ rawData }) => {
          void getGlobalRouter().navigate({ to: getManageProgramURL(rawData.groupInfo.groupId) })
        },
      }}
    >
      <Spacer size='xsmall' />
      <SearchSection setUserAction={setUserAction} tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Toolbar
        tableAPI={tableAPI}
        mapCsv={(ids: string[]) =>
          programMemberships.filter(row => ids.includes(row.groupInfo.groupId)).map(programGroupToCsv)
        }
        unassignProgramGroups={unassignProgramGroups}
      />
      <UserDetailsTabularTable />
      <Footer fetchCsv={() => Promise.resolve(programMemberships.map(programGroupToCsv))} />
    </TabularProviderFromTableAPI>
  )
}
