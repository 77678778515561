import React from 'react'
import * as format from 'sierra-client/core/format'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CellProgress } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { getLiveSessionTimes } from 'sierra-client/views/manage/courses/utils/live-session-utils'
import { scheduledOrNull } from 'sierra-domain/content/session'
import { Icon, Tooltip } from 'sierra-ui/components'
import { Text, View } from 'sierra-ui/primitives'

export const ProgressCell: React.FC<{ cell: CellProgress }> = ({ cell }) => {
  const { courseKind, isSelfStarted = false, liveSessions = [], progress } = cell.data
  const { t } = useTranslation()

  const progressClassification =
    !isSelfStarted && (progress === undefined || progress === 0)
      ? 'not-started'
      : progress === 1
        ? 'completed'
        : 'started'

  const progressText =
    progressClassification === 'not-started'
      ? t('manage.users.status.not-started')
      : t('manage.users.status.n-completed', { n: format.percentage(progress) })

  const liveSessionTooltipIcon =
    courseKind === 'native:live' && liveSessions.length > 0 ? (
      <Tooltip
        title={
          <View direction='column'>
            {liveSessions.map(ls => {
              const { startTime, endTime } = scheduledOrNull(ls.data) ?? {}
              const result = getLiveSessionTimes(startTime, endTime)
              if (result === undefined) return null
              const dateText = `${result.date}, ${result.time}`
              return (
                <View key={ls.liveSessionId} justifyContent='space-between'>
                  <Text color={'surface/default'} size='micro' title={dateText}>
                    {dateText}
                  </Text>
                </View>
              )
            })}
          </View>
        }
      >
        <Icon iconId='information' color='foreground/secondary' />
      </Tooltip>
    ) : null

  if (courseKind === 'native:live') {
    if (progressClassification !== 'completed' && liveSessions.length === 0) {
      return (
        <Text size='small' color='redBright'>
          {t('manage.live-session.filter.not-assigned')}
        </Text>
      )
    }
    return (
      <View>
        <Text size='small' color='foreground/secondary'>
          {progressText}
        </Text>
        {liveSessionTooltipIcon}
      </View>
    )
  }

  return (
    <Text size='small' color='foreground/secondary'>
      {progressText}
    </Text>
  )
}
