import { atom, useAtomValue } from 'jotai'
import { DateTime } from 'luxon'
import React, { useRef } from 'react'
import { MANAGE_HOMEWORK_SUBMISSONS_TABULAR_QUERY_KEYS } from 'sierra-client/api/hooks/use-homework'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { canvasColumn, dateTimesColumn, usersColumn } from 'sierra-client/lib/tabular/column-definitions'
import { createDataLoader } from 'sierra-client/lib/tabular/control/dataloader'
import { CellCommon } from 'sierra-client/lib/tabular/datatype/internal/cell-with-data'
import { DateTimeRep } from 'sierra-client/lib/tabular/datatype/internal/reps/date-rep'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { TabularProviderFromTableAPI, useTabularContext } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { typedPost } from 'sierra-client/state/api'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { HomeworkTableEmptyState } from 'sierra-client/views/manage/homeworks/homework-table-empty-state'
import { GradeStatusCell } from 'sierra-client/views/manage/homeworks/manage-homework/components/grade-status-cell'
import { SubmissionCell } from 'sierra-client/views/manage/homeworks/manage-homework/components/submission-cell'
import { HomeworkDetailsResponse } from 'sierra-domain/homework'
import { XRealtimeAdminHomeworkHomeworkDetails } from 'sierra-domain/routes'
import { isDefined } from 'sierra-domain/utils'
import { Button, scrollViewStyles, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type HomeworkSubmission = HomeworkDetailsResponse['data'][number]

type HomeworkSubmissionTableDefinition = TableDefinitionOf<
  HomeworkSubmission,
  [
    { type: 'users'; ref: 'user' },
    { type: 'canvas'; ref: 'submission' },
    { type: 'dateTimes'; ref: 'submittedDate' },
    { type: 'dateTimes'; ref: 'dueDate' },
    { type: 'canvas'; ref: 'status' },
  ]
>

type HomeworkSubmissionTableData = TableDataFromDefinition<
  HomeworkSubmission,
  HomeworkSubmissionTableDefinition
>
type HomeworkSubmissionTableMeta = { cursor: string | undefined }

const toDateRep = (date?: string): DateTimeRep | undefined =>
  isDefined(date)
    ? { date: new Date(date), tooltip: DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED) }
    : undefined

const PAGE_SIZE = 50

const tableDefinition = (): HomeworkSubmissionTableDefinition => {
  return {
    columns: [
      usersColumn({
        getData: r => ({
          active: r.user.status === 'active',
          email: r.user.email,
          id: r.user.userId,
          status: r.user.status,
          isRequiredAssignment: false,
          avatarColor: r.user.avatarColor,
          firstName: r.user.firstName,
          lastName: r.user.lastName,
          avatar: getAvatarImage(r.user.userId, r.user.avatar),
        }),
        header: translatedLabel('dictionary.user-singular'),
        ref: 'user',
        sortable: false,
      }),
      canvasColumn({
        getData: r => ({
          view: <SubmissionCell submissionFileInfo={r.submissionData} onClick={() => undefined} />, //TODO
          meta: {
            exports: () => r.submissionData?.submissionId,
            sorts: () => r.submissionData?.submissionId ?? '',
          },
        }),
        header: translatedLabel('manage.homework.submission-singular'),
        ref: 'submission',
        sortable: false,
      }),
      dateTimesColumn({
        sortable: false,
        getData: r => toDateRep(r.submissionData?.createdAt),
        header: translatedLabel('dictionary.submitted'),
        ref: 'submittedDate',
      }),
      dateTimesColumn({
        sortable: false,
        getData: r => toDateRep(r.dueAt),
        header: translatedLabel('dictionary.due'),
        ref: 'dueDate',
      }),
      canvasColumn({
        getData: r => {
          return {
            view: <GradeStatusCell status={r.statusData.gradeStatus} />,
            meta: {
              exports: () => r.statusData.gradeStatus,
              sorts: () => r.statusData.gradeStatus,
            },
          }
        },
        header: translatedLabel('dictionary.status'),
        ref: 'status',
        sortable: false,
      }),
    ],
    nested: {},
    rows: {
      getId: r => r.user.userId,
    },
  }
}

const useUserGroupTable = ({
  homeworkId,
  setReviewSubmissionId,
}: HomeWorkSubmissionsTableProps): UseTableAPI<HomeworkSubmissionTableData, HomeworkSubmissionTableMeta> => {
  const { t } = useTranslation()
  const loader = createDataLoader({
    fetchInit: async ({ control }) => {
      const response = await typedPost(XRealtimeAdminHomeworkHomeworkDetails, {
        homeworkId,
        limit: control.limit ?? PAGE_SIZE,
      })

      return {
        data: response.data,
        meta: {
          cursor: response.nextCursor,
        },
        done: !Boolean(response.nextCursor),
        totalCount: response.totalCount,
      }
    },
    fetchMore: async ({ meta, control }) => {
      const response = await typedPost(XRealtimeAdminHomeworkHomeworkDetails, {
        homeworkId,
        limit: control.limit ?? PAGE_SIZE,
        cursor: meta.cursor,
      })

      return {
        data: response.data,
        meta: {
          cursor: response.nextCursor,
        },
        done: !Boolean(response.nextCursor),
        totalCount: response.totalCount,
      }
    },
    transformResults(data) {
      return [definition2Data(tableDefinition(), data)]
    },
  })

  return useTableAPI({
    options: {
      limit: PAGE_SIZE,
    },
    dataLoader: {
      loader,
      options: {
        queryKey: MANAGE_HOMEWORK_SUBMISSONS_TABULAR_QUERY_KEYS,
      },
    },
    virtualColumns: {
      left: [],
      right: [
        {
          ref: 'action',
          header: () => ({
            type: 'action',
            ref: 'action',
            enabled: atom(() => true),
            sortable: atom(() => false),
            hints: [],
          }),
          cell: ({ pos, row }) => {
            const submissionId = row.rawData.submissionData?.submissionId
            const cellbase: CellCommon = {
              hints: [],
              pos,
              enabled: atom(() => true),
              selected: atom(() => false),
            }
            if (!isDefined(submissionId)) {
              return {
                ...cellbase,
                hideDecorator: true,
                type: 'empty',
              }
            }
            return {
              ...cellbase,
              type: 'canvas',
              data: {
                view: (
                  <View>
                    <Button variant='secondary' onClick={() => setReviewSubmissionId(submissionId)}>
                      {t('manage.homework.review')}
                    </Button>
                  </View>
                ),
              },
            }
          },
        },
      ],
    },
  })
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 30rem;
  max-height: calc(100vh - 10rem);
  width: 100%;
`

const Table: React.FC = () => {
  const { api } = useTabularContext()
  const loaderState = useAtomValue(api.atoms.loaderState)
  const tableData = useAtomValue(api.atoms.tableData)
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      {loaderState === 'done' && tableData[0]?.rows.length === 0 ? (
        <View alignSelf='center'>
          <HomeworkTableEmptyState />
        </View>
      ) : (
        <BasicTabularSimpleSize scrollRef={scrollRef.current} />
      )}
    </TableWrapper>
  )
}

export type HomeWorkSubmissionsTableProps = {
  homeworkId: string
  setReviewSubmissionId: (id: string) => void
}

export const HomeWorkSubmissionsTable: React.FC<HomeWorkSubmissionsTableProps> = ({
  homeworkId,
  setReviewSubmissionId,
}) => {
  const tableAPI = useUserGroupTable({ homeworkId, setReviewSubmissionId })

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Table />
    </TabularProviderFromTableAPI>
  )
}
