import { useQuery } from '@tanstack/react-query'
import { Duration } from 'luxon'
import { useCallback, useEffect } from 'react'
import { usePost } from 'sierra-client/hooks/use-post'
import { typedPost } from 'sierra-client/state/api'
import {
  UseProgramAdminsState,
  useProgramAdmins,
} from 'sierra-client/views/manage/components/hooks/use-program-admins'
import { useTracking } from 'sierra-client/views/manage/programs/hooks/use-tracking'
import { ProgramDetailResponse, UnassignGroupRequest } from 'sierra-domain/api/manage'
import { ZodRouteOutput } from 'sierra-domain/api/types'
import { ProgramId } from 'sierra-domain/api/uuid'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import {
  XRealtimeAdminAssignmentsUnassignFromGroup,
  XRealtimeAdminProgramsDeleteProgram,
  XRealtimeAdminProgramsDeleteProgramImage,
  XRealtimeAdminProgramsProgramAverageTimeToComplete,
  XRealtimeAdminProgramsProgramDetail,
  XRealtimeAdminProgramsUpdateProgram,
} from 'sierra-domain/routes'

type ProgramData = Omit<ProgramDetailResponse, 'admins'>

export type UseProgramDetailsData = {
  isLoading: boolean
  program: ProgramData | undefined
  unassignFromProgram: (
    groupId: string,
    unassigns: Partial<Omit<UnassignGroupRequest, 'groupId'>>
  ) => Promise<void>
  deleteProgram: (id: string) => Promise<void>
  deleteProgramImage: (id: string) => Promise<void>
  updateProgram: (
    id: string,
    updates: {
      name?: string
      description?: string
      image?: ImageUnion
      selfEnrolment?: boolean
    }
  ) => Promise<void>
  programAdminState: UseProgramAdminsState
}
export const PROGRAM_QUERY_KEY_PREFIX = ['fetch-program']

export const useProgramDetails = (programId: string): UseProgramDetailsData => {
  const { postWithUserErrorException } = usePost()
  const tracking = useTracking()

  const programAdminState = useProgramAdmins({ programId })
  const { setCurrentAdmins } = programAdminState

  const { isLoading, error, data } = useQuery({
    queryKey: [...PROGRAM_QUERY_KEY_PREFIX, programId],
    queryFn: () => typedPost(XRealtimeAdminProgramsProgramDetail, { programId }),
  })

  useEffect(() => {
    if (!error && !isLoading && data !== undefined) {
      setCurrentAdmins(data.admins)
    }
  }, [data, error, isLoading, setCurrentAdmins])

  const updateProgram = useCallback<UseProgramDetailsData['updateProgram']>(
    async (programId, updates) => {
      await postWithUserErrorException(XRealtimeAdminProgramsUpdateProgram, {
        programId: programId,
        name: updates.name,
        description: updates.description,
        image: updates.image,
        allowsSelfEnroll: updates.selfEnrolment,
      })
    },
    [postWithUserErrorException]
  )

  const deleteProgramImage: UseProgramDetailsData['deleteProgramImage'] = useCallback(
    async (programId: string): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminProgramsDeleteProgramImage, {
        programId: programId,
      })
    },
    [postWithUserErrorException]
  )

  const deleteProgram = useCallback(
    async (programId: string): Promise<void> => {
      await postWithUserErrorException(XRealtimeAdminProgramsDeleteProgram, { programId: programId })
      tracking.program.deleted(programId)
    },
    [postWithUserErrorException, tracking.program]
  )

  const unassignFromProgram = useCallback<UseProgramDetailsData['unassignFromProgram']>(
    async (groupId, { userIds }) => {
      await postWithUserErrorException(XRealtimeAdminAssignmentsUnassignFromGroup, {
        groupId,
        userIds,
      })
    },
    [postWithUserErrorException]
  )

  return {
    isLoading,
    program: data,
    unassignFromProgram,
    deleteProgram,
    deleteProgramImage,
    updateProgram,
    programAdminState,
  }
}

function selectAverageTimeToComplete({
  averageTimeToComplete,
}: ZodRouteOutput<typeof XRealtimeAdminProgramsProgramAverageTimeToComplete>): Duration | undefined {
  if (averageTimeToComplete === undefined) return undefined

  return Duration.fromISO(averageTimeToComplete)
}

export function useProgramAverageTimeToComplete(programId: ProgramId): Duration | undefined {
  const query = useQuery({
    queryKey: [...PROGRAM_QUERY_KEY_PREFIX, programId, 'average-time-to-complete'],
    queryFn: () => typedPost(XRealtimeAdminProgramsProgramAverageTimeToComplete, { programId }),
    select: selectAverageTimeToComplete,
  })

  return query.data
}
