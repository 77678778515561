import React, { useCallback, useContext, useMemo } from 'react'
import { FCC } from 'sierra-client/types'
import { QuestionResponsesDataLoader } from 'sierra-client/views/v3-author/question-card/question-responses-data-loader'
import { CreateContentId, LiveSessionId } from 'sierra-domain/api/nano-id'
import { ScopedCreateContentId } from 'sierra-domain/collaboration/types'
import { File } from 'sierra-domain/flexible-content/types'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { ThemeProvider } from 'styled-components'

export type FileContext = {
  file: File
  scopedCreateContentId: ScopedCreateContentId
  flexibleContentId: CreateContentId
  questionResponsesDataLoader: QuestionResponsesDataLoader
}

const ReactFileContext = React.createContext<FileContext | undefined>(undefined)

export const FileContext: FCC<{
  file: File
  scopedCreateContentId: ScopedCreateContentId
  liveSessionId: LiveSessionId | undefined
}> = ({ children, file, scopedCreateContentId, liveSessionId }) => {
  const flexibleContentId = ScopedCreateContentId.extractId(scopedCreateContentId)
  const value = useMemo(
    () => ({
      file,
      flexibleContentId,
      scopedCreateContentId,
      questionResponsesDataLoader: new QuestionResponsesDataLoader(flexibleContentId, liveSessionId),
    }),
    [file, flexibleContentId, liveSessionId, scopedCreateContentId]
  )
  const isWideLayout = file.layout === 'wide'

  const patchTheme = useCallback(
    (theme: Theme): Theme => ({
      ...theme,
      editor: { ...theme.editor, isWideLayout },
    }),
    [isWideLayout]
  )

  return (
    <ReactFileContext.Provider value={value}>
      <ThemeProvider theme={patchTheme}>{children}</ThemeProvider>
    </ReactFileContext.Provider>
  )
}

export function useFileContext(): FileContext {
  const context = useContext(ReactFileContext)
  if (context === undefined) throw new Error('Expected component to be wrapped in a FileContext')
  return context
}

export function useSafeFileContext(): FileContext | undefined {
  return useContext(ReactFileContext)
}
