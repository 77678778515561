export const LIVE_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7483-sana-live-help'
export const EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card'
export const YOUTUBE_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_0dab382e2c'
export const GOOGLE_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_740f96260f'
export const FIGMA_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_c2b253c190'
export const AIRTABLE_EMBED_HELP_PAGE_URL =
  'https://help.sana.ai/en/articles/7417-the-embed-card#h_b35e00e3a8'
export const MIRO_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_a1c9f1d45c'
export const PITCH_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_a04daffa82'
export const TRELLO_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_34b1a6d86f'
export const LOOM_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_c6df09b064'
export const VIMEO_EMBED_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/7417-the-embed-card#h_8e0f0b0683'
export const SHARING_CONTENT_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/74946-sharing-content'
export const LIVE_SPEAKER_HELP = 'https://help.sana.ai/articles/85579-audio-on-sana-live#h_ec6974a510'
export const EXERCISES_HELP_PAGE_URL = 'https://help.sana.ai/en/articles/32545-exercises'

export const LIVE_PRE_LOBBY_PERMISSIONS_DENIED_HELP_PAGE =
  'https://help.sana.ai/en/articles/85579-audio-on-sana-live#h_a9153ecd41'
export const LIVE_MICROPHONE_PERMISSION_HELP_PAGE =
  'https://help.sana.ai/en/articles/85579-audio-on-sana-live#h_3af1626b37'
export const LIVE_MICROPHONE_NOT_READABLE_HELP_PAGE =
  'https://help.sana.ai/en/articles/85579-audio-on-sana-live#h_0246bf4a6c'
export const LIVE_MICROPHONE_CANNOT_CONNECT_HELP_PAGE =
  'https://help.sana.ai/en/articles/85579-audio-on-sana-live#h_9fed91edb6'
export const LIVE_MICROPHONE_NO_AUDIO_HELP_PAGE =
  'https://help.sana.ai/en/articles/85579-audio-on-sana-live#h_5907f80b20'

export const LIVE_CAMERA_HELP_PAGE = 'https://help.sana.ai/en/articles/8747-video-on-sana-live'
export const REQUIRED_ASSIGNMENTS_HELP_PAGE = 'https://help.sana.ai/' //TODO: update this link to the correct one
