import _ from 'lodash'
import React, { useEffect } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { parseModalToAssignment } from 'sierra-client/components/common/modals/multi-assign-modal/utils'
import { useNotif } from 'sierra-client/components/common/notifications'
import { assignmentPriorityLogger } from 'sierra-client/components/required-assignments/logger'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { useDispatch } from 'sierra-client/state/hooks'
import { DueDateDialog, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { ContentType } from 'sierra-client/views/manage/content/utils/content-utils'
import { useUserDetails } from 'sierra-client/views/manage/users/manage-user-details/hooks/use-user-details'
import { DueDateAbsolute, DueDateSource } from 'sierra-domain/api/manage'
import { CourseId, PathId } from 'sierra-domain/api/nano-id'
import { UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminPathsPathDetail } from 'sierra-domain/routes'
import { assertNever, iife } from 'sierra-domain/utils'

export type UserModalActionsProps = {
  action:
    | { modal: undefined }
    | {
        modal:
          | 'reset-progress'
          | 'complete-progress'
          | 'assign-content'
          | 'assign-programs'
          | 'assign-groups'
          | 'assign-session'
          | 'sandbox-owner-confirm-resend-invite'
          | 'sandbox-email-sending-disabled'
      }
    | {
        modal: 'due-date'
        contentId: string
        contentType: ContentType
        currentDueDate?: DueDateAbsolute
        currentDueDateOrigin?: DueDateSource
      }
    | {
        modal: 'unassign'
        messageType: 'content' | 'group' | 'session'
        targets: {
          id: string
          type: ContentType | 'group' | 'session'
        }[]
      }
    /**
     * Immediately calls onDone
     */
    | { modal: 'reload' }
    | {
        modal: 'reset-course'
        contentType: string
        contentId: string
      }
    | {
        modal: 'complete-content'
        contentId: string
        contentType: ContentType | 'course-group'
      }
    | {
        modal: 'user-settings'
      }
  targetUserId: UserId
  onClose: () => void
  onDone: () => void
}

export const UserModalActions: React.FC<UserModalActionsProps> = ({
  action,
  targetUserId,
  onClose,
  onDone,
}) => {
  const { t } = useTranslation()
  const notifications = useNotif()
  const { setUsersDueDate, assignWithDueDate } = useDueDate()
  const dispatch = useDispatch()
  // Used only for action utils. Doesn't send the ID so we don't re-fetch user details
  const {
    handleEnrollGroupsSave,
    handleEnrollProgramsSave,
    unassignFromUser,
    resetProgress,
    completeCourses,
    resendInvite,
    resetCourse,
  } = useUserDetails()

  const { postWithUserErrorException } = usePost()

  useEffect(() => {
    if (action.modal === 'reload') {
      onDone()
    }
  }, [action.modal, onDone])

  if (['assign-content', 'assign-session'].includes(action.modal ?? '')) {
    return (
      <AssignModal
        isOpen
        config={{
          subjectType: 'user',
          panes: 'course-and-path-and-live-session',
          activePane: action.modal === 'assign-content' ? 'course' : 'live-session',
          showDueDates: true,
          onSave: async selections => {
            const result = await assignWithDueDate(parseModalToAssignment([targetUserId], selections))
            if (result?.error !== undefined) return

            selections.forEach(selection => {
              void dispatch(
                assignmentPriorityLogger({
                  contentType: selection.type,
                  assignmentPriority: selection.assignmentPriority,
                  hasDueDate: selection.dueDate !== undefined,
                  contentId: selection.id,
                  userId: targetUserId,
                })
              )
            })
            notifications.push({ type: 'assigned' })
            onDone()
          },
        }}
        subjects={targetUserId}
        title={t('manage.groups.assign-content')}
        onClose={onClose}
      />
    )
  }

  if (action.modal === 'assign-programs') {
    return (
      <AssignModal
        isOpen
        config={{
          subjectType: 'user',
          panes: 'program',
          activePane: 'program',
          onSave: async selections => {
            await handleEnrollProgramsSave(targetUserId, selections)

            notifications.push({ type: 'success' })
            selections.forEach(selection => {
              void dispatch(
                assignmentPriorityLogger({
                  contentType: 'program',
                  assignmentPriority: selection.assignmentPriority,
                  hasDueDate: selection.dueDate !== undefined,
                  contentId: selection.id,
                  userId: targetUserId,
                })
              )
            })
            onDone()
          },
        }}
        subjects={targetUserId}
        title={t('admin.organization.groups.add-to-program')}
        onClose={onClose}
      />
    )
  }

  if (action.modal === 'assign-groups') {
    return (
      <AssignModal
        isOpen
        config={{
          subjectType: 'user',
          panes: 'user-group',
          activePane: 'user-group',
          onSave: async selections => {
            await handleEnrollGroupsSave(targetUserId, selections)

            notifications.push({ type: 'success' })

            onDone()
          },
        }}
        subjects={targetUserId}
        title={t('admin.organization.groups.add-to-group')}
        onClose={onClose}
      />
    )
  }

  if (action.modal === 'unassign') {
    const groupedIds = _.groupBy(action.targets, i => i.type)

    const courseIds = (groupedIds.course ?? []).map(i => i.id)
    const pathIds = (groupedIds.path ?? []).map(i => i.id)
    const groupIds = (groupedIds.group ?? []).map(i => i.id)
    const liveSessionIds = (groupedIds.session ?? []).map(i => i.id)

    const { title, body } = iife(() => {
      switch (action.messageType) {
        case 'content':
          return {
            title: t('manage.unassign-content.title'),
            body: t('manage.users.unassign-content.message'),
          }
        case 'session':
          return {
            title: t('manage.remove-user-from-session.title', { count: liveSessionIds.length }),
            body: t('manage.remove-user-from-session.message', { count: liveSessionIds.length }),
          }
        case 'group':
          return {
            title: t('manage.remove-user-from-group.title', { count: groupIds.length }),
            body: t('manage.remove-user-from-group.message', { count: groupIds.length }),
          }
        default:
          assertNever(action.messageType)
      }
    })

    return (
      <ActionModal
        open
        onClose={onClose}
        primaryAction={async (): Promise<void> => {
          await unassignFromUser(targetUserId, { courseIds, pathIds, groupIds, liveSessionIds })
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.done'),
          })
          onDone()
        }}
        primaryActionLabel={t('dictionary.unassign')}
        title={title}
        size={{ width: 400, height: 240 }}
        deleteAction
      >
        {body}
      </ActionModal>
    )
  }

  if (action.modal === 'reset-progress') {
    return (
      <ActionModal
        open
        onClose={onClose}
        primaryAction={async (): Promise<void> => {
          await resetProgress(targetUserId)
          notifications.push({ type: 'user-progress-reset' })
          onDone()
        }}
        primaryActionLabel={t('admin.organization.users.reset-progress')}
        deleteAction
      >
        {t('manage.users.reset-progress-message')}
      </ActionModal>
    )
  }
  if (action.modal === 'complete-content') {
    return (
      <ActionModal
        open
        onClose={onClose}
        primaryAction={async (): Promise<void> => {
          if (action.contentType === 'course') {
            await completeCourses(targetUserId, [action.contentId])
          } else if (action.contentType === 'path') {
            let courseIds: CourseId[] = []
            await (async () => {
              const response = await postWithUserErrorException(XRealtimeAdminPathsPathDetail, {
                pathId: action.contentId as PathId,
              })
              courseIds = response.data.content.map(course => course.id)
            })()
            await completeCourses(targetUserId, courseIds)
          }
          notifications.push({ type: 'user-progress-complete' })
          onDone()
        }}
        primaryActionLabel={t('admin.organization.users.complete-progress')}
      >
        {t('manage.users.complete-progress-message')}
      </ActionModal>
    )
  }
  if (action.modal === 'sandbox-owner-confirm-resend-invite') {
    return (
      <ActionModal
        open
        onClose={onClose}
        primaryAction={async () => {
          const forceEmail = true
          const success = await resendInvite(targetUserId, forceEmail)
          notifications.push({ type: success ? 'resend-invite' : 'resend-invite-not-sent' })
          onClose()
        }}
        title={t('admin.sandbox.resend-invite.title')}
        primaryActionLabel={t('admin.sandbox.resend-invite.confirm')}
        deleteAction
      >
        {t('admin.sandbox.resend-invite.body')}
      </ActionModal>
    )
  }

  if (action.modal === 'sandbox-email-sending-disabled') {
    return (
      <ActionModal open onClose={onClose} title={t('admin.sandbox.email-sending-disabled.title')}>
        {t('admin.sandbox.email-sending-disabled.description')}
      </ActionModal>
    )
  }

  if (action.modal === 'due-date') {
    return (
      <DueDateDialog
        open
        type='user'
        onClose={onClose}
        value={action.currentDueDate}
        forceDisableRemove={action.currentDueDateOrigin !== 'direct'}
        onChange={async value => {
          if (value?.type === 'relative') return
          if (action.contentType === 'program') return
          await setUsersDueDate(
            [targetUserId],
            [
              {
                dueDate: value?.date,
                content: { type: action.contentType, id: action.contentId },
              },
            ]
          )
          onDone()
        }}
      />
    )
  }
  if (action.modal === 'reset-course') {
    return (
      <ActionModal
        open
        onClose={onClose}
        primaryAction={async (): Promise<void> => {
          await resetCourse(targetUserId, action.contentType, action.contentId)
          onDone()
        }}
        primaryActionLabel={t('manage.reset-progress')}
        deleteAction
      >
        {t('manage.users.reset-course-message')}
      </ActionModal>
    )
  }
  return null
}
