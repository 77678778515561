import { FC } from 'react'
import { Thumbnail } from 'sierra-client/components/common/thumbnail'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { ContentIcon } from 'sierra-client/views/workspace/components'
import { LearnerContent } from 'sierra-domain/api/learn'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { TruncatedText } from 'sierra-ui/components'
import { Skeleton, Text, View } from 'sierra-ui/primitives'

export const PreviewSkeleton: FC = () => (
  <View grow>
    <Skeleton $height={32} $width={44} $radius={4} />
    <View direction='column'>
      <Skeleton $height={12} $width={150} $radius={4} />
      <Skeleton $height={12} $width={120} $radius={4} />
    </View>
  </View>
)

export const CoursePreview: FC<{
  image?: ImageUnion
  title: string
  courseKind: LearnerContent['type']
  description?: string
  assetContext: AssetContext
}> = ({ image, title, courseKind, description, assetContext }) => {
  const imgSrc = useResolveAsset({ image, assetContext, size: 'default' })

  return (
    <View direction='row' grow gap='12'>
      <Thumbnail image={imgSrc} height={2} width={2.75} radius={0.5} />
      <View direction='column' grow padding={'6 none'} gap='none'>
        <View>
          <ContentIcon type={courseKind} color='foreground/secondary' />
          <TruncatedText lines={1} bold>
            {title}
          </TruncatedText>
        </View>
        {description !== undefined && <Text color='foreground/muted'>{description}</Text>}
      </View>
    </View>
  )
}

export const ProgramPreview: FC<{
  image?: ImageUnion
  name: string
  description?: string
  assetContext: AssetContext
}> = ({ image, name, description, assetContext }) => {
  const imgSrc = useResolveAsset({ image, assetContext, size: 'default' })

  return (
    <View direction='row' grow gap='12'>
      <Thumbnail image={imgSrc} height={2} width={2.75} radius={0.5} />
      <View direction='column' grow padding={'6 none'} gap='none'>
        <View>
          <ContentIcon type='program' color='foreground/secondary' />
          <TruncatedText lines={1} bold>
            {name}
          </TruncatedText>
        </View>
        {description !== undefined && <Text color='foreground/muted'>{description}</Text>}
      </View>
    </View>
  )
}
