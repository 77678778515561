import { motion } from 'framer-motion'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNotif } from 'sierra-client/components/common/notifications'
import { getDisabledReason, useDisableInput } from 'sierra-client/components/disabling'
import { config, getFlag } from 'sierra-client/config/global-config'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { I18nArgs } from 'sierra-client/hooks/use-translation/types'
import { getAuthMethodForEmail } from 'sierra-client/views/authentication/common'
import { useMSTeamsInfo } from 'sierra-client/views/manage/settings/components/microsoft-teams'
import { useSlackIntegrationInfo } from 'sierra-client/views/manage/settings/components/slack'
import { useOrgNotificationSettings } from 'sierra-client/views/settings/emails-and-notifications/use-org-notification-settings'
import { NotificationSettingsForm } from 'sierra-client/views/user-settings/components/notification-settings-form'
import { UserIntegrationSettingsForm } from 'sierra-client/views/user-settings/components/user-integrations-form'
import { UserSettingsForm } from 'sierra-client/views/user-settings/components/user-settings-form'
import { useUserSettings } from 'sierra-client/views/user-settings/use-user-settings'
import {
  AccountSettingsFormConfig,
  HandleAccountSettingsFormChange,
  UserSettingsFormErrors,
  getDefaultAccountSettings,
  validateAccountSettingsForm,
} from 'sierra-client/views/user-settings/user-settings-utils'
import { NotificationState } from 'sierra-domain/api/org-notification-settings'
import { AccountSettings } from 'sierra-domain/api/user'
import { assertNever, iife } from 'sierra-domain/utils'
import { VerticalTabs } from 'sierra-ui/components/vertical-tabs'
import { Button, Heading, IconButton, Text, View } from 'sierra-ui/primitives'
import { token } from 'sierra-ui/theming'
import styled, { css } from 'styled-components'

const SAVE_CONTAINER_HEIGHT = '84px'

const Container = styled.div`
  position: relative;
  height: 100%;
  padding-left: 32px;
`

const CloseButton = styled(IconButton).attrs({ variant: 'transparent', iconId: 'close' })`
  position: absolute;
  right: 12px;
  top: 8px;
`

const TabContainer = styled.div<{ extraBottomPadding: boolean }>`
  height: 100%;
  padding-top: 48px;
  padding-right: 32px;

  ${p =>
    p.extraBottomPadding &&
    css`
      padding-bottom: ${SAVE_CONTAINER_HEIGHT};
    `}

  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
`

const SaveButtonContainer = styled(motion.div)`
  background: ${token('surface/default')};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 48px;
  height: 84px;
  border-top: 1px solid ${token('border/default')};
`

type SettingsTab =
  | 'settings'
  | 'notifications'
  | 'slack-notifications'
  | 'user-integrations'
  | 'teams-notifications'

export const UserSettings: React.FC<{
  initialTab?: SettingsTab
  close?: () => void
}> = ({ initialTab, close }) => {
  const { t } = useTranslation()
  const sanaNowEnabled = getFlag('sana-now')
  const rsvpEnabled = getFlag('rsvp')

  const anyMeetingToolEnabled = iife(() => {
    const meetingToolsSettings = config.organization.settings.meetingToolsSettings

    const meetEnabled = meetingToolsSettings.meetSettings.enabled
    const zoomEnabled = meetingToolsSettings.zoomSettings.enabled
    const teamsEnabled = meetingToolsSettings.teamsSettings.enabled

    return meetEnabled || teamsEnabled || zoomEnabled
  })

  const anyCalendarIntegrationEnabled = iife(() => {
    const calendarIntegrationSettingType = config.organization.settings.calendarIntegrationSettings.type
    switch (calendarIntegrationSettingType) {
      case 'sana':
        return false
      case 'google':
      case 'microsoft':
        return true
      default:
        assertNever(config.organization.settings.calendarIntegrationSettings)
    }
  })

  const orgConfig = config.organization
  const { currentUser, saveUserSettings } = useUserSettings()
  const disableInputContext = useDisableInput()

  const notif = useNotif()

  const [userForm, setUserForm] = useState<AccountSettings>(getDefaultAccountSettings())
  const [error, setError] = useState<UserSettingsFormErrors>({
    userAccountSettings: {},
    notificationSettings: {},
  })
  const [serverError, setServerError] = useState<I18nArgs | undefined>()
  const [currentTab, setCurrentTab] = useState<SettingsTab>(initialTab ?? 'settings')

  const hasChanges = useMemo(() => {
    if (currentUser === undefined) return false
    return !_.isEqual(userForm, getDefaultAccountSettings(currentUser))
  }, [currentUser, userForm])

  useEffect(() => {
    if (currentUser === undefined) return

    setUserForm(getDefaultAccountSettings(currentUser))
  }, [currentUser])

  // Logic for disabling inputs
  const formConfig = useMemo<AccountSettingsFormConfig>(() => {
    const email = currentUser?.email ?? ''
    const authMethod = getAuthMethodForEmail(orgConfig.auth, email)

    const isSamlAndProvisioning =
      authMethod !== undefined && authMethod.type === 'saml' && authMethod.provisioning

    const disableName = email === '' || isSamlAndProvisioning
    const disableEmail = true
    return {
      disableName,
      disableEmail,
    }
  }, [orgConfig.auth, currentUser?.email])

  // Form change
  const handleFormChange: HandleAccountSettingsFormChange = (rootField, field, value) => {
    setUserForm(f => ({
      ...f,
      [rootField]: {
        ...f[rootField],
        [field]: value,
      },
    }))
  }

  const handleSave = async (): Promise<void> => {
    setError({ userAccountSettings: {}, notificationSettings: {} })
    setServerError(undefined)

    const result = validateAccountSettingsForm(userForm, formConfig)
    if (result.hasErrors) {
      setError(result.errors)
      return
    }

    const saveResult = await saveUserSettings(userForm)

    if (saveResult.error) {
      setServerError(saveResult.i18nArgs)
      notif.push({ type: 'error' })
      return
    }

    notif.push({ type: 'custom', level: 'success', body: t('notifications.settings-saved') })
    if (close !== undefined) close()
  }

  const slackConnection = useSlackIntegrationInfo()
  const { config: msTeamsConfig } = useMSTeamsInfo()
  const { result: orgNotificationSettings } = useOrgNotificationSettings()

  return (
    <Container>
      <CloseButton onClick={close} />
      <VerticalTabs
        tabBarPreComponent={
          <View paddingTop='32'>
            <Heading size='h4' bold>
              {t('settings.my-settings')}
            </Heading>
          </View>
        }
        value={currentTab}
        onChange={(newTab: SettingsTab) => setCurrentTab(newTab)}
        items={_.compact([
          {
            type: 'tab',
            id: 'settings',
            label: t('dictionary.account'),
            content: (
              <TabContainer extraBottomPadding={hasChanges}>
                <UserSettingsForm
                  values={userForm}
                  formConfig={formConfig}
                  errors={error}
                  email={currentUser?.email}
                  onChange={handleFormChange}
                />
              </TabContainer>
            ),
          },
          orgNotificationSettings.data?.emailSettings.state === NotificationState.Enabled && {
            type: 'tab',
            id: 'notifications',
            label: t('settings.email-notifications'),
            content: (
              <TabContainer extraBottomPadding={hasChanges}>
                <NotificationSettingsForm
                  values={userForm}
                  formConfig={formConfig}
                  notificationType={'email'}
                  errors={error}
                  onChange={handleFormChange}
                />
              </TabContainer>
            ),
          },
          orgNotificationSettings.data?.slackSettings.state === NotificationState.Enabled &&
            slackConnection.connection !== undefined && {
              type: 'tab',
              id: 'slack-notifications',
              label: t('settings.slack-notifications'),
              content: (
                <TabContainer extraBottomPadding={hasChanges}>
                  <NotificationSettingsForm
                    values={userForm}
                    formConfig={formConfig}
                    notificationType={'slack'}
                    errors={error}
                    onChange={handleFormChange}
                  />
                </TabContainer>
              ),
            },
          orgNotificationSettings.data?.msTeamsSettings.state === NotificationState.Enabled &&
            msTeamsConfig.enabled && {
              type: 'tab',
              id: 'teams-notifications',
              label: t('manage.settings.msTeams.notifications-title'),
              content: (
                <TabContainer extraBottomPadding={hasChanges}>
                  <NotificationSettingsForm
                    values={userForm}
                    formConfig={formConfig}
                    notificationType={'msTeams'}
                    errors={error}
                    onChange={handleFormChange}
                  />
                </TabContainer>
              ),
            },
          ((sanaNowEnabled && anyMeetingToolEnabled) || (rsvpEnabled && anyCalendarIntegrationEnabled)) && {
            type: 'tab',
            id: 'user-integrations',
            label: t('settings.live-meeting-tools-tab-title'),
            content: (
              <TabContainer extraBottomPadding={hasChanges}>
                <UserIntegrationSettingsForm />
              </TabContainer>
            ),
          },
        ])}
      />
      {hasChanges && (
        <SaveButtonContainer
          variants={{
            hidden: { y: 84, opacity: 0 },
            visible: { y: 0, opacity: 1, transition: { duration: 0.15, ease: [0.25, 0.1, 0.25, 1] } },
          }}
          initial='hidden'
          animate='visible'
          exit='hidden'
        >
          {serverError !== undefined && (
            <Text size='small' color='redMedium'>
              {t(...serverError)}
            </Text>
          )}
          <Button variant='secondary' onClick={close}>
            {t('dictionary.cancel')}
          </Button>
          <Button
            variant='primary'
            onClick={handleSave}
            disabledWithReason={getDisabledReason(disableInputContext)}
          >
            {t('dictionary.save')}
          </Button>
        </SaveButtonContainer>
      )}
    </Container>
  )
}
