import { DateTime } from 'luxon'
import React from 'react'
import { date as formatDate, useLocalizedFormatters } from 'sierra-client/core/format'
import { EmptyCell } from 'sierra-client/lib/tabular/components/cells/empty'
import { DateTimeRep } from 'sierra-client/lib/tabular/datatype/internal/reps/date-rep'
import { RenderHint } from 'sierra-client/lib/tabular/hints'
import { iife } from 'sierra-domain/utils'
import { Tooltip, TruncatedText } from 'sierra-ui/components'

export const DateTimeCell: React.FC<{ cellData?: DateTimeRep; hints: RenderHint[] }> = ({ cellData }) => {
  const { formatTimeAgo } = useLocalizedFormatters()

  if (!cellData) {
    return <EmptyCell />
  }

  const { date, format } = cellData

  const dateString = iife((): string => {
    switch (format) {
      case 'LLL dd yyyy, HH:mm':
        return DateTime.fromJSDate(date).toFormat('LLL dd yyyy, HH:mm')
      case 'time-ago':
        return formatTimeAgo(date)
      default:
        return formatDate(date)
    }
  })

  if (Boolean(cellData.tooltip)) {
    return (
      <Tooltip title={cellData.tooltip}>
        <div>
          <TruncatedText lines={1} size='small'>
            {dateString}
          </TruncatedText>
        </div>
      </Tooltip>
    )
  }

  return (
    <TruncatedText lines={1} size='small'>
      {dateString}
    </TruncatedText>
  )
}
