import React from 'react'
import { RequiredAssignmentMenuItem } from 'sierra-client/components/required-assignments/required-assignment-switch'
import { getFlag } from 'sierra-client/config/global-config'
import { useContentKindPermissions, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { TranslationLookup } from 'sierra-client/hooks/use-translation/types'
import {
  canvasColumn,
  dateTimesColumn,
  pillsColumn,
  stringsColumn,
  usersColumn,
} from 'sierra-client/lib/tabular/column-definitions'
import { staticDataLoader, StaticLoaderSearchKeyBy } from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { UseTableAPI, useTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultMenuActionVirtualColumn, defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { getGlobalRouter } from 'sierra-client/router'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { DueDateCell, getAbsoluteDueDate, useDueDate } from 'sierra-client/views/manage/components/due-date'
import { CourseGroupActionModalControl } from 'sierra-client/views/manage/courses/course-group-action-modals'
import { isSelfStarted } from 'sierra-client/views/manage/courses/utils/course-group-utils'
import { getProgressPillRep } from 'sierra-client/views/manage/utils/progress-pill-tabular-rep'
import { CourseGroupUserProgressRow } from 'sierra-domain/api/manage'

export type CourseGroupUserProgressTableDefinition = TableDefinitionOf<
  CourseGroupUserProgressRow,
  [
    { type: 'users'; ref: 'user' },
    { type: 'pills'; ref: 'progress' },
    { type: 'strings'; ref: 'edition' },
    { type: 'dateTimes'; ref: 'last-activity' },
    { type: 'strings'; ref: 'assignment-type' },
    { type: 'canvas'; ref: 'due-date' },
    { type: 'dateTimes'; ref: 'assign-date' },
    { type: 'dateTimes'; ref: 'start-date' },
  ]
>

export type CourseGroupUserProgressTableData = TableDataFromDefinition<
  CourseGroupUserProgressRow,
  CourseGroupUserProgressTableDefinition
>

export type CourseGroupUserProgressTableMeta = {
  subset: CourseGroupUserProgressTableData
}

const tableDefinition = (t: TranslationLookup): CourseGroupUserProgressTableDefinition => ({
  columns: [
    usersColumn({
      sortable: true,
      header: translatedLabel('dictionary.name'),
      ref: 'user',
      getData: r => {
        return {
          id: r.userId,
          email: r.email ?? '-',
          active: true,
          firstName: r.firstName,
          lastName: r.lastName,
          status: 'active',
          avatarColor: r.avatarColor,
          avatar: getAvatarImage(r.userId, r.avatar),
          isRequiredAssignment: r.isRequiredAssignment,
        }
      },
    }),
    pillsColumn({
      ref: 'progress',
      header: translatedLabel('admin.analytics.table.progress'),
      getData: r => {
        return getProgressPillRep({
          homeworks: r.homeworks,
          t,
          progress: r.completionRate,
        })
      },
      tooltip: translatedLabel('manage.groups.tooltip.group-completion'),
    }),
    stringsColumn({
      getData: r => r.editionTitleUsedForProgress,
      header: translatedLabel('manage.course.groups.active.edition'),
      ref: 'edition',
    }),
    dateTimesColumn({
      getData: r => (r.lastActivity ? { date: r.lastActivity, format: 'time-ago' } : undefined),
      header: translatedLabel('table.last-activity'),
      ref: 'last-activity',
    }),
    stringsColumn({
      getData: r =>
        isSelfStarted(r) ? t('manage.users.self-started') : t('manage.users.assignment-type.individual'),
      header: translatedLabel('table.assigned-via'),
      ref: 'assignment-type',
    }),
    canvasColumn({
      getData: r => {
        return {
          view: <DueDateCell dueDatesEffective={r.dueDatesEffective} expectedSource={'direct'} />,
          meta: {
            sorts: () => r.dueDatesEffective?.effectiveDueDate ?? '',
            exports: () => r.dueDatesEffective?.effectiveDueDate ?? '',
          },
        }
      },
      header: translatedLabel('due-date.due-date'),
      ref: 'due-date',
    }),
    dateTimesColumn({
      getData: r => (r.createdAt ? { date: r.createdAt } : undefined),
      header: translatedLabel('table.assigned'),
      ref: 'assign-date',
      enabled: false,
    }),
    dateTimesColumn({
      getData: r => (r.startedAt ? { date: r.startedAt } : undefined),
      header: translatedLabel('admin.analytics.table.started'),
      ref: 'start-date',
      enabled: false,
    }),
  ],
  nested: {},
  rows: {
    getId: r => r.userId,
  },
})

const searchKey: StaticLoaderSearchKeyBy<CourseGroupUserProgressTableData> = (tableData, row) => {
  const userData = tableData.rows[row]?.data.user.data
  if (!userData) return ''
  const { firstName, lastName, email } = userData
  return `${firstName} ${lastName} ${email}`
}

function isIndividuallyAssigned(row: CourseGroupUserProgressRow): boolean {
  return row.programs.length === 0 && row.paths.length === 0 && row.assignedViaCourseGroup !== false
}

export const useCourseGroupUserProgressTableAPI = ({
  refetch,
  data,
  courseGroupId,
  modal,
}: {
  refetch: () => void
  data: CourseGroupUserProgressRow[]
  courseGroupId: string
  modal: CourseGroupActionModalControl
}): UseTableAPI<CourseGroupUserProgressTableData, CourseGroupUserProgressTableMeta> => {
  const { t } = useTranslation()
  const orgPermissions = useOrganizationPermissions()
  const courseGroupPermissions = useContentKindPermissions(courseGroupId)
  const canResetProgress = orgPermissions.has('RESET_LEARNER_PROGRESS')
  const canSetContentCompletion = orgPermissions.has('SET_CONTENT_COMPLETION')
  const canSetContentDueDates = orgPermissions.has('SET_CONTENT_DUE_DATES')
  const { setUsersIsRequiredContent } = useDueDate()

  const loader = staticDataLoader(definition2Data(tableDefinition(t), data), searchKey)

  return useTableAPI({
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [
        defaultMenuActionVirtualColumn({
          getProps: ({ row }) => {
            const { rawData } = row

            const shouldShowDetails =
              (rawData.completionRate ?? 0) !== 0 || !(rawData.assignedViaCourseGroup === true)

            const disableAssignmentPriorityWithReason = !isIndividuallyAssigned(rawData)
              ? 'Unable to change the assignment priority because this user is not directly assigned to the content, but rather assigned through for example a program.'
              : undefined //TODO(required-content)

            return {
              menuItems: [
                {
                  id: 'view-user',
                  type: 'label',
                  label: t('manage.view-details'),
                  onClick: () => getGlobalRouter().navigate({ to: `/manage/users/${rawData.userId}` }),
                  icon: 'user',
                },
                {
                  id: 'view-active-edition',
                  type: 'label',
                  label: t('manage.course.groups.view.active.edition'),
                  hidden: !shouldShowDetails,
                  onClick: () => {
                    void getGlobalRouter().navigate({
                      to: `/manage/courses/${rawData.editionIdUsedForProgress}`,
                    })
                  },
                  icon: 'information',
                },
                {
                  id: 'course-group-due-date',
                  type: 'label',
                  label: t('due-date.set-due-date'),
                  hidden: !canSetContentDueDates,
                  onClick: () => {
                    const { effectiveDueDate, effectiveSource } = rawData.dueDatesEffective || {}
                    modal.open({
                      modal: 'set-due-dates',
                      userId: rawData.userId,
                      origin: effectiveSource,
                      currentDueDate: getAbsoluteDueDate(effectiveDueDate),
                    })
                  },
                  icon: 'time',
                },
                {
                  type: 'canvas',
                  id: 'set-is-required',
                  hidden: getFlag('required-assignments') === false,
                  disabled: disableAssignmentPriorityWithReason !== undefined,
                  render() {
                    return (
                      <RequiredAssignmentMenuItem
                        disabledWithReason={disableAssignmentPriorityWithReason}
                        assignmentPriority={row.rawData.isRequiredAssignment ? 'required' : 'normal'}
                        setAssignmentPriority={async val => {
                          await setUsersIsRequiredContent(
                            [rawData.userId],
                            [
                              {
                                isRequired: val === 'required',
                                content: { id: courseGroupId, type: 'course' },
                              },
                            ]
                          )
                          refetch()
                        }}
                      />
                    )
                  },
                },
                {
                  type: 'label',
                  label: t('manage.complete-progress'),
                  id: 'complete-content',
                  onClick: () => {
                    modal.open({
                      modal: 'complete-content',
                      targetUserId: rawData.userId,
                      contentId: courseGroupId,
                      contentType: 'course',
                    })
                  },
                  icon: 'checkmark--outline',
                  disabled: rawData.completionRate === 1,
                  hidden: !canSetContentCompletion,
                },
                {
                  id: 'reset-content',
                  type: 'label',
                  label: t('manage.reset-progress'),
                  onClick: () => {
                    modal.open({
                      targetUserId: rawData.userId,
                      modal: 'reset-course',
                      contentId: courseGroupId,
                      contentType: 'course-group',
                    })
                  },
                  icon: 'reset',
                  disabled: rawData.completionRate === 0 || rawData.completionRate === undefined,
                  hidden: !canResetProgress,
                  color: 'destructive/background',
                },
                {
                  id: 'unassign',
                  type: 'label',
                  color: 'destructive/background',
                  label: t('dictionary.unassign'),
                  hidden: !courseGroupPermissions.has('EDIT_ASSIGNMENTS'),
                  disabled: rawData.unassignable,
                  onClick: () => {
                    modal.open({
                      modal: 'unassign-users',
                      userIds: [rawData.userId],
                    })
                  },
                  icon: 'user--remove',
                },
              ],
            }
          },
        }),
      ],
    },
    dataLoader: {
      loader,
    },
    options: {
      sorting: {
        initialSorting: React.useMemo(() => [{ column: 'user', direction: 'ascending' }], []),
      },
    },
  })
}
