import { useQueryClient } from '@tanstack/react-query'
import { useBlocker } from '@tanstack/react-router'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionModal } from 'sierra-client/components/common/modals/action-modal'
import { AssignModal } from 'sierra-client/components/common/modals/multi-assign-modal'
import { AssignModalSelection } from 'sierra-client/components/common/modals/multi-assign-modal/types'
import { useNotif } from 'sierra-client/components/common/notifications'
import { PageTitle } from 'sierra-client/components/common/page-title'
import { useGroupPermissions, useOrganizationPermissions } from 'sierra-client/hooks/use-permissions'
import { usePost } from 'sierra-client/hooks/use-post'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Contains, Filter, Or, Value, andAll, createFilter } from 'sierra-client/lib/filter'
import { ReadOnlyFilter, ReadOnlyFilterGroup } from 'sierra-client/lib/filter/components/read-only-filter'
import { getGlobalRouter } from 'sierra-client/router'
import { AdminModal, AdminSection } from 'sierra-client/views/manage/components/admin-section'
import { DetailsHeader } from 'sierra-client/views/manage/components/details-header'
import { ManageUserGroupHeader } from 'sierra-client/views/manage/components/layout/manage-user-group-header'
import { ConfirmDeleteGroupModal } from 'sierra-client/views/manage/programs/components/modals'
import { UserFilterHeatmap } from 'sierra-client/views/manage/reports/components/heatmap/user-filter-heatmap'
import { UserGroupMembersTable } from 'sierra-client/views/manage/user-groups/components/user-table'
import { queryKeyTabularUserGroupMembers } from 'sierra-client/views/manage/user-groups/query-keys'
import {
  isModifiableGroupType,
  useUserGroupDetails,
} from 'sierra-client/views/manage/user-groups/use-user-group-details'
import { EditCreateUserGroupModal } from 'sierra-client/views/manage/users/components/edit-create-user-group-modal'
import { useFilterUsersDomainReps } from 'sierra-client/views/manage/users/use-filter-users'
import { useTracking } from 'sierra-client/views/manage/users/utils/use-tracking'
import { GroupId, UserId } from 'sierra-domain/api/uuid'
import { XRealtimeAdminAssignmentsSetUserGroupMemberships } from 'sierra-domain/routes'
import { Icon } from 'sierra-ui/components'
import { Spacer, Text, View } from 'sierra-ui/primitives'
import { useOnChanged } from 'sierra-ui/utils'
import styled from 'styled-components'

const UUID = (value: string): Value => ({ type: 'value.uuid', value })

type GroupDetailProps = {
  groupId: GroupId
}

const Wrapper = styled(View).attrs({ direction: 'column', gap: 'none' })`
  width: 100%;
`

type UserGroupDraft = {
  name: string
  description: string
  filter?: Filter
}

export const ManageUserGroupDetails: React.FC<GroupDetailProps> = ({ groupId }) => {
  const { t } = useTranslation()
  const tracking = useTracking()
  const { postWithUserErrorException } = usePost()
  const notifications = useNotif()
  const orgPermissions = useOrganizationPermissions()
  const groupPermissions = useGroupPermissions(groupId)

  const [action, setAction] = useState<
    | { modal: undefined | 'users' | 'delete' | 'edit' | 'group-admins' }
    | { modal: 'unassign-users'; targets: UserId[] }
  >({ modal: undefined })

  const onRemoveUsers = useCallback((ids: UserId[]) => {
    setAction({ modal: 'unassign-users', targets: ids })
  }, [])

  const onAddUsers = useCallback(() => {
    setAction({ modal: 'users' })
  }, [])

  const {
    group,
    isLoading,
    fetchGroup,
    deleteGroup,
    updateGroupDetails,
    unassignFromGroup,
    groupAdminState,
  } = useUserGroupDetails(groupId)

  const queryClient = useQueryClient()

  const domainReps = useFilterUsersDomainReps()

  const blocker = useBlocker({ condition: group?.name?.length === 0 })

  const [draft, setDraft] = useState<UserGroupDraft>(() => ({ name: '', description: '', filter: undefined }))
  const [showHeatmap, setShowHeatmap] = useState(false)

  const handleSaveEnrolledUsers = useCallback(
    async (selections: AssignModalSelection[]): Promise<void> => {
      const userIds = selections.flatMap(item => (item.type === 'user' ? [item.id] : []))

      if (userIds.length > 0) {
        await postWithUserErrorException(XRealtimeAdminAssignmentsSetUserGroupMemberships, {
          userIds,
          groupIds: [groupId],
        })

        await fetchGroup(groupId)
        await queryClient.invalidateQueries({ queryKey: queryKeyTabularUserGroupMembers(groupId) })

        setAction({ modal: undefined })
      }
    },
    [postWithUserErrorException, groupId, fetchGroup, queryClient]
  )

  const useOnChangedParams = useMemo(() => ({ groupId, ...draft }), [groupId, draft])
  useOnChanged((prev, curr) => {
    if (group === undefined) return
    if (!curr.name) return

    if (!isModifiableGroupType(group.type)) return

    const shouldUpdate = [
      group.name !== curr.name,
      group.description !== curr.description,
      group.type === 'user-filter' && curr.filter !== undefined && !_.isEqual(group.filter, curr.filter),
    ].some(Boolean)

    if (!shouldUpdate) return

    void updateGroupDetails(
      groupId,
      curr.name,
      curr.description,
      curr.filter,
      group.type === 'user-filter' ? group.invalidFilter : false
    )
  }, useOnChangedParams)

  useEffect(() => {
    if (group === undefined) return

    // Reset draft when group changes (is this correct?)
    setDraft({
      name: group.name ?? '',
      description: group.description ?? '',
      filter: group.type === 'user-filter' ? group.filter : undefined,
    })
  }, [group])

  const heatmapUserFilter = useMemo<Filter | undefined>(() => {
    if (group === undefined) return undefined

    if (group.type === 'user-filter') {
      return group.filter
    } else {
      return andAll([
        // Should there be more filters here?
        createFilter({ type: 'user.groups' }, Contains, Or([UUID(groupId)])),
      ])
    }
  }, [group, groupId])

  if (group === undefined || isLoading) return null

  const isExternalGroup = group.type === 'user-scim' || group.type === 'user-api'

  return (
    <>
      <PageTitle title={group.name} />

      <Wrapper>
        <DetailsHeader
          hasTopMargin={false}
          backlink={{ href: '/manage/user-groups', label: 'manage.backlinks--groups' }}
        />

        <ManageUserGroupHeader
          type={group.type}
          title={group.name}
          description={group.description}
          canDelete={!isExternalGroup && groupPermissions.has('DELETE')}
          onDelete={() => setAction({ modal: 'delete' })}
          canEdit={!isExternalGroup && groupPermissions.has('EDIT_METADATA')}
          onEdit={() => setAction({ modal: 'edit' })}
        />
        {group.type === 'user-filter' && domainReps !== undefined && (
          <>
            <Text color={group.invalidFilter ? 'warning/background' : 'foreground/primary'} size='small' bold>
              {group.invalidFilter && (
                <>
                  <Icon iconId={'warning--filled'} color={'warning/background'} />
                  &nbsp;&nbsp;
                </>
              )}
              {t('smart-group-membership-criteria')}
            </Text>
            <Spacer size='6' />
            <ReadOnlyFilterGroup>
              <ReadOnlyFilter domainReps={domainReps} filter={group.filter} />
            </ReadOnlyFilterGroup>
            <Spacer size='medium' />
          </>
        )}
        {group.type === 'user-manual' && (
          <EditCreateUserGroupModal
            open={action.modal === 'edit'}
            type='user-manual'
            mode='edit'
            name={group.name ?? ''}
            description={group.description ?? ''}
            onEdit={draft => {
              setDraft(draft)
              setAction({ modal: undefined })
            }}
            onCancel={() => setAction({ modal: undefined })}
          />
        )}
        {group.type === 'user-filter' && domainReps !== undefined && (
          <EditCreateUserGroupModal
            open={action.modal === 'edit'}
            type='user-filter'
            mode='edit'
            name={group.name ?? ''}
            filter={group.filter}
            readOnlyFilter={!groupPermissions.has('EDIT_MEMBERS')}
            domainReps={domainReps}
            description={group.description ?? ''}
            onEdit={draft => {
              setDraft(draft)
              setAction({ modal: undefined })
            }}
            onCancel={() => setAction({ modal: undefined })}
          />
        )}
        <AssignModal
          isOpen={action.modal === 'users'}
          config={{
            subjectType: 'user-group',
            panes: 'user',
            activePane: 'user',
            onSave: handleSaveEnrolledUsers,
          }}
          subjects={groupId}
          title={t('manage.manage-users')}
          onClose={() => {
            setAction({ modal: undefined })
          }}
        />
        <ConfirmDeleteGroupModal
          open={action.modal === 'delete'}
          onClose={() => setAction({ modal: undefined })}
          onConfirm={async (): Promise<void> => {
            await deleteGroup(groupId)

            if (group.type === 'user-manual') {
              tracking.static.delete(groupId)
            } else if (group.type === 'user-filter') {
              tracking.smart.delete(groupId)
            }

            await getGlobalRouter().navigate({ to: '/manage/user-groups' })
          }}
        />

        <UserGroupMembersTable
          groupId={groupId}
          onRemove={onRemoveUsers}
          onAddUsers={onAddUsers}
          onShowHeatmap={() => setShowHeatmap(true)}
          canEditMembers={group.type === 'user-manual' && groupPermissions.has('EDIT_MEMBERS')}
        />
        <Spacer size='40' />
        <AdminSection
          canEdit={groupPermissions.has('EDIT_ADMINS')}
          currentAdmins={groupAdminState.currentAdmins}
          onClickViewAll={() => setAction({ modal: 'group-admins' })}
        />
      </Wrapper>
      <ActionModal
        open={action.modal === 'unassign-users'}
        isLoading={isLoading}
        onClose={() => setAction({ modal: undefined })}
        primaryAction={async (): Promise<void> => {
          if (action.modal !== 'unassign-users') return
          await unassignFromGroup(groupId, { userIds: action.targets })
          setAction({ modal: undefined })
          notifications.push({
            type: 'custom',
            level: 'success',
            body: t('notifications.done'),
          })
        }}
        primaryActionLabel={t('admin.remove')}
        title={
          action.modal === 'unassign-users' && action.targets.length === 1
            ? t('manage.group.remove-user-singular.title')
            : t('manage.group.remove-user-plural.title')
        }
        deleteAction
      >
        {action.modal === 'unassign-users' && action.targets.length === 1
          ? t('manage.group.remove-user-singular.message')
          : t('manage.group.remove-user-plural.message')}
      </ActionModal>
      <ActionModal
        open={blocker.status === 'blocked'}
        title={t('manage.empty-group')}
        primaryActionLabel={t('manage.delete-group')}
        onClose={blocker.reset}
        primaryAction={async () => {
          blocker.proceed()
          await deleteGroup(groupId)
        }}
        deleteAction
      >
        {t('manage.group-name-or-delete')}
      </ActionModal>
      {showHeatmap && (
        <UserFilterHeatmap
          domainReps={domainReps}
          onClose={() => setShowHeatmap(false)}
          filter={heatmapUserFilter}
        />
      )}
      <AdminModal
        open={action.modal === 'group-admins'}
        hasFullAccess={orgPermissions.has('ACCESS_ALL_GROUPS')}
        onClose={() => setAction({ modal: undefined })}
        canEdit={groupPermissions.has('EDIT_ADMINS')}
        adminState={groupAdminState}
      />
    </>
  )
}
