import React, { useCallback, useContext, useMemo } from 'react'
import { FCC } from 'sierra-client/types'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { apply } from 'sierra-domain/editor/operations/operation'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { assertWith } from 'sierra-domain/utils'

export type ReflectionCardCreateContext = {
  allowAnonymousResponses: boolean
  setAllowAnonymousResponses: (_: boolean) => void
}

const ReactReflectionCardCreateContext = React.createContext<ReflectionCardCreateContext | undefined>(
  undefined
)

export const ReflectionCardCreateContext: FCC<{ context: ReflectionCardCreateContext }> = ({
  children,
  context,
}) => {
  return (
    <ReactReflectionCardCreateContext.Provider value={context}>
      {children}
    </ReactReflectionCardCreateContext.Provider>
  )
}

export function useReflectionCardCreateContextSafe(): ReflectionCardCreateContext | undefined {
  return useContext(ReactReflectionCardCreateContext)
}

export function getAllowAnonymousResponses(file: File): boolean {
  if (file.data.type === 'reflections') return file.data.settings?.allowAnonymousResponses ?? true
  return false
}

export function getIsSummaryEnabledFromEditor(file: File): boolean {
  if (file.data.type === 'reflections') return file.data.settings?.enabledSummary ?? true
  return false
}

export const useAllowAnonymousResponses = (
  file: File
): {
  allowAnonymousResponses: boolean
  setAllowAnonymousResponses: (_: boolean) => void
} => {
  const { operationState } = useCreatePageContext()
  const nodeId = file.id
  assertWith(FileId, nodeId)

  const allowAnonymousResponses = getAllowAnonymousResponses(file)

  const setAllowAnonymousResponses = useCallback(
    (allow: boolean): void => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [nodeId],
        update: file => {
          if (file.data.type !== 'reflections') throw Error(`File ${nodeId} is not a reflections card`)

          const settings = file.data.settings ?? { allowAnonymousResponses: true }

          settings.allowAnonymousResponses = allow

          file.data.settings = settings
        },
      })
    },
    [nodeId, operationState]
  )

  return useMemo(
    () => ({
      allowAnonymousResponses,
      setAllowAnonymousResponses,
    }),
    [allowAnonymousResponses, setAllowAnonymousResponses]
  )
}
