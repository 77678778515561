import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { parseStringToJsonContent } from 'sierra-client/views/v3-author/homework/homework-atoms'
import { CourseId } from 'sierra-domain/api/nano-id'
import { ListExercisesResponse } from 'sierra-domain/homework'
import { TruncatedText } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const StyledImg = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: ${p => p.theme.borderRadius['size-10']};
  object-fit: cover;
`

const ReviewInstructionsContainer = styled.div`
  max-height: 80px;
  overflow: hidden;
  opacity: 0.4;
`

export const ExerciseCell: React.FC<{
  courseData: ListExercisesResponse['data'][0]['courseData']
  reviewInstructions?: string
}> = ({ courseData, reviewInstructions }) => {
  const { t } = useTranslation()

  const assetContext = { type: 'course' as const, courseId: CourseId.parse(courseData.courseId) }
  const imgSrc = useResolveAsset({
    image: courseData.image,
    size: 'course-sm',
    assetContext,
  })

  const title = courseData.cardTitle ?? t('dictionary.homework-singular')

  return (
    <View alignItems={reviewInstructions !== undefined ? 'flex-start' : 'center'}>
      <View>
        <StyledImg src={imgSrc} />
      </View>
      <View direction='column'>
        <TruncatedText lines={1} bold size='small'>
          {title}
        </TruncatedText>
        {reviewInstructions !== undefined && (
          <ReviewInstructionsContainer>
            <FreeTextEditor content={parseStringToJsonContent(reviewInstructions)} editable={false} />
          </ReviewInstructionsContainer>
        )}
      </View>
    </View>
  )
}
