import { useEffect, useState } from 'react'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { Rgba } from 'sierra-ui/color'
import { rgbaToThumbHash, thumbHashToAverageRGBA, thumbHashToDataURL } from 'thumbhash'

const binaryToBase64 = (binary: Uint8Array): string => btoa(String.fromCharCode(...binary))
const base64ToBinary = (base64: string): Uint8Array =>
  new Uint8Array(
    atob(base64)
      .split('')
      .map(x => x.charCodeAt(0))
  )

export const createImageThumbhashBase64 = (image: HTMLImageElement): string | undefined => {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return undefined

  // The image width and height must be smaller than 100px
  const scale = 100 / Math.max(image.width, image.height)
  canvas.width = Math.round(image.width * scale)
  canvas.height = Math.round(image.height * scale)
  context.drawImage(image, 0, 0, canvas.width, canvas.height)

  const pixels = context.getImageData(0, 0, canvas.width, canvas.height)
  const hash = rgbaToThumbHash(pixels.width, pixels.height, pixels.data)
  const hashBase64 = binaryToBase64(hash)

  return hashBase64
}

export const createDataURlFromThumbhashBase64 = (thumbhashBase64: string): string => {
  const thumbhash = base64ToBinary(thumbhashBase64)
  return thumbHashToDataURL(thumbhash)
}

export const thumbhashAverageColor = (thumbhashBase64: string): Rgba => {
  const thumbhash = base64ToBinary(thumbhashBase64)
  const res = thumbHashToAverageRGBA(thumbhash)

  return {
    r: res.r * 255,
    g: res.g * 255,
    b: res.b * 255,
    a: res.a,
  }
}

export const thumbhashHasAlhpa = (thumbhashBase64: string): boolean => {
  const hash = base64ToBinary(thumbhashBase64)
  // taken from thumbhash implementation
  // the last bit in the header is a hasAlhpa flag
  const first = hash[0]
  const second = hash[1]
  const third = hash[2]
  if (first === undefined || second === undefined || third === undefined) return false
  const header = first | (second << 8) | (third << 16)
  const hasAlpha = header >> 23
  return !!hasAlpha
}

export const createThumbHashUrlFromImage = (image: ImageUnion | undefined): string | undefined => {
  if (image === undefined) return undefined
  if (image.type === 'url') return undefined
  const thumbHashBase64 = image.thumbHashBase64
  if (thumbHashBase64 === undefined) return undefined
  return createDataURlFromThumbhashBase64(thumbHashBase64)
}

export const useThumbHashPlaceholderIfImageNotLoaded = (
  url: string | undefined,
  image: ImageUnion | undefined
): string | undefined => {
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  useEffect(() => {
    if (url === undefined) return
    const image = new Image()
    image.onload = () => {
      setShowPlaceholder(false)
    }
    image.src = url
    return () => {
      image.onload = null
    }
  }, [url])

  return showPlaceholder ? createThumbHashUrlFromImage(image) : undefined
}
