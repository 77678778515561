import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { color } from 'sierra-ui/color'
import { Button, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ImageBackground = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-end',
  radius: 'size-8',
})<{ url: string }>`
  position: relative;
  background: center / cover no-repeat url(${p => p.url});
  overflow: hidden;
`

const Test = styled(View).attrs({
  direction: 'column',
  justifyContent: 'flex-end',
  padding: '16',
})`
  position: relative;
`

const Info = styled(View).attrs({
  direction: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})``

const Meta = styled(View).attrs({ direction: 'column', gap: '2' })``

const Headline = styled(Text).attrs({ color: 'white', bold: true, size: 'micro' })``

const Subline = styled(Text).attrs({ size: 'micro' })`
  color: ${color('white').opacity(0.5).toString()};
`

const Actions = styled(View).attrs({ direction: 'row', gap: '8' })``

export const CoverImageOverlay = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${color('black').opacity(0.3).toString()};
`

export const CoverImageInModal: React.FC<{
  image: ImageUnion
  onDelete: () => void
  assetContext: AssetContext
}> = ({ image, onDelete, assetContext }) => {
  const { t } = useTranslation()

  const imageUrl = useResolveAsset({
    image,
    assetContext,
    size: 'default',
  })

  const meta = (() => {
    if (image.type === 'file') {
      return {
        title: image.file,
        size: `${image.width}px / ${image.height}px`,
      }
    }

    return undefined
  })()

  return (
    <ImageBackground url={imageUrl}>
      <CoverImageOverlay />
      <Test>
        <Spacer size='80' />
        <Info>
          {meta !== undefined && (
            <Meta>
              <Headline>{meta.title}</Headline>
              <Subline color='LEGACY_DEFAULT_TEXT_COLOR_REPLACE_ASAP'>{meta.size}</Subline>
            </Meta>
          )}
          <Actions>
            <Button icon='trash-can' variant='ghost' onClick={onDelete}>
              {t('manage.programs.remove-cover-image')}
            </Button>
          </Actions>
        </Info>
      </Test>
    </ImageBackground>
  )
}
