import { useEffect, useState } from 'react'
import { cardViewed } from 'sierra-client/core/logging/authoring/logger'
import { useDispatch } from 'sierra-client/state/hooks'
import { FCC } from 'sierra-client/types'
import { BreakoutRoomCard } from 'sierra-client/views/flexible-content/breakout-room-card'
import { EmbedCard } from 'sierra-client/views/flexible-content/embed-card'
import { FileContext } from 'sierra-client/views/flexible-content/file-context'
import { CreateImageCard } from 'sierra-client/views/flexible-content/image-card'
import { LobbyCard } from 'sierra-client/views/flexible-content/lobby-card'
import { PolarisCardTheme } from 'sierra-client/views/flexible-content/polaris-card-theme'
import { VideoCard } from 'sierra-client/views/flexible-content/video-card'
import { BottomContentContainer } from 'sierra-client/views/self-paced/bottom-content'
import { StaticEditor } from 'sierra-client/views/self-paced/review/static-editor'
import { CardCanvas } from 'sierra-client/views/shared/card-canvas'
import { PreviewDropAWord } from 'sierra-client/views/v3-author/drop-a-word/create-drop-a-word-card'
import { QACard } from 'sierra-client/views/v3-author/qa-card/QA-card'
import {
  getAllowAnonymousResponses,
  ReflectionCardCreateContext,
} from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { SelfPacedContentId } from 'sierra-domain/api/nano-id'
import { AssetContext } from 'sierra-domain/asset-context'
import { SlateDocumentMap } from 'sierra-domain/collaboration/serialization/types'
import { slateDocumentMapKey } from 'sierra-domain/collaboration/slate-document-map'
import { ScopedCreateContentId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { assertIsSlateFile, File } from 'sierra-domain/flexible-content/types'
import { assertIsNonNullable, assertNever } from 'sierra-domain/utils'
import { SlateRootElement } from 'sierra-domain/v3-author'
import { createParagraph } from 'sierra-domain/v3-author/create-blocks'
import { View } from 'sierra-ui/primitives'
import styled from 'styled-components'

const ScrollableCardCanvas = styled(CardCanvas)`
  overflow: hidden;
  border-radius: 6px;
  margin: 8px;
  height: auto;
  && > *:not(${BottomContentContainer}) {
    min-width: calc(100% / 0.5);
    height: calc(100% / 0.5);

    @media screen and (min-width: 1200px) {
      min-width: calc(100% / 0.67);
      height: calc(100% / 0.67);
    }

    @media screen and (min-width: 1600px) {
      min-width: calc(100% / 0.8);
      height: calc(100% / 0.8);
    }
  }
`

const ScaleContainer = styled.div`
  overflow: auto;
  transform: scale(0.5);
  height: calc(100% / 0.5);
  min-width: calc(100% / 0.5);
  transform-origin: left center;
  && > * {
    height: 100%;
  }

  @media screen and (min-width: 1200px) {
    transform: scale(0.67);
    min-width: calc(100% / 0.67);
    height: calc(100% / 0.67);
  }

  @media screen and (min-width: 1600px) {
    transform: scale(0.8);
    min-width: calc(100% / 0.8);
    height: calc(100% / 0.8);
  }
`

const flattenQuestionVariationNodes = (nodes: SlateRootElement[]): SlateRootElement[] =>
  nodes.flatMap(node => {
    if (node.type === 'question-variations')
      return [
        createParagraph({
          children: [
            { text: `======== Question variation ${node.id} (${node.children.length} questions) ========` },
          ],
        }),
        ...node.children,
        createParagraph({ children: [{ text: `======== End of question variation ${node.id} ========` }] }),
      ]
    return [node]
  })

type ContentPreviewRendererProps = {
  file: File
  assetContext: AssetContext
  [slateDocumentMapKey]: SlateDocumentMap
}

const Switch: React.FC<ContentPreviewRendererProps> = ({ file, slateDocumentMap, assetContext }) => {
  const readOnly = true
  const slateDoc = slateDocumentMap[file.id]

  switch (file.data.type) {
    case 'live-lobby':
      return <LobbyCard />
    case 'breakout-room':
      return <BreakoutRoomCard data={file.data} />
    case 'image':
      return (
        <CreateImageCard
          data={file.data}
          onUploadDone={() => {}}
          readOnly={readOnly}
          mode='create'
          assetContext={assetContext}
        />
      )
    case 'embed':
      return <EmbedCard data={file.data} onUploadDone={() => {}} readOnly={readOnly} />
    case 'video':
      return (
        <VideoCard
          readOnly={readOnly}
          video={file.data.video}
          setVideoDuration={() => {}}
          onUploadDone={() => {}}
          assetContext={assetContext}
          file={file}
        />
      )
    case 'stupid-questions':
      return <QACard data={file.data} fileId={file.id} readOnly={readOnly} />
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'general':
    case 'notepad':
    case 'external-notepad':
    case 'question-card':
    case 'assessment-card':
    case 'sticky-notes':
    case 'project-card':
    case 'reflections':
    case 'homework': {
      assertIsNonNullable(slateDoc)
      assertIsSlateFile(file)

      return (
        <ReflectionCardCreateContext
          context={{
            allowAnonymousResponses: getAllowAnonymousResponses(file),
            setAllowAnonymousResponses: () => {},
          }}
        >
          <StaticEditor
            key={file.id}
            document={flattenQuestionVariationNodes(slateDoc.nodes as SlateRootElement[])}
            mode='template'
            assetContext={assetContext}
            file={file}
          />
        </ReflectionCardCreateContext>
      )
    }
    case 'drop-a-word':
      return <PreviewDropAWord />
    case 'scenario':
    case 'roleplay':
      return null

    default:
      assertNever(file.data)
  }
}

const SwitchWrapper: FCC<{ file: File }> = ({ file, children }) => {
  switch (file.data.type) {
    case 'live-lobby':
    case 'breakout-room':
    case 'image':
    case 'embed':
    case 'video':
    case 'slate-card':
    case 'poll':
    case 'sliding-scale':
    case 'flip-cards':
    case 'bullet':
    case 'question-card':
    case 'assessment-card':
    case 'sticky-notes':
    case 'project-card':
    case 'stupid-questions':
    case 'reflections':
    case 'drop-a-word':
    case 'scenario':
    case 'homework': {
      return <>{children}</>
    }
    case 'notepad':
    case 'external-notepad':
    case 'general': {
      return (
        <View paddingTop={'xxlarge'} paddingBottom={'xxlarge'} justifyContent='center'>
          {children}
        </View>
      )
    }
    // @deprecated
    case 'roleplay':
      return null

    default:
      assertNever(file.data)
  }
}

export const ContentPreviewRenderer: React.FC<ContentPreviewRendererProps> = props => {
  const { file, assetContext } = props
  // Templates do not have a flexible content id but we create a fake one for the sake of uniformity
  const [fakeId] = useState(ScopedSelfPacedContentId.fromId(SelfPacedContentId.parse('_template_id')))

  const dispatch = useDispatch()

  useEffect(() => {
    void dispatch(
      cardViewed({
        contentId: ScopedCreateContentId.extractId(fakeId),
        fileId: file.id,
        cardType: file.data.type,
        mode: 'template',
      })
    )
  }, [dispatch, fakeId, file.data.type, file.id])

  return (
    <PolarisCardTheme {...file}>
      <ScrollableCardCanvas card={file} assetContext={assetContext}>
        <ScaleContainer>
          <FileContext file={file} scopedCreateContentId={fakeId} liveSessionId={undefined}>
            <SwitchWrapper file={file}>
              <Switch {...props} assetContext={assetContext} />
            </SwitchWrapper>
          </FileContext>
        </ScaleContainer>
      </ScrollableCardCanvas>
    </PolarisCardTheme>
  )
}
