import { PrimitiveAtom } from 'jotai'
import React, { useMemo, useState } from 'react'
import {
  AblyYDocWithAwarenessState,
  useAblyYDocWithAwareness,
} from 'sierra-client/collaboration/use-ably-ydoc'
import { ConfirmationModalProvider } from 'sierra-client/components/common/modals/confirmation-modal'
import { useIsDebugMode } from 'sierra-client/hooks/use-is-debug-mode'
import { useDebug } from 'sierra-client/lib/use-debug/use-debug'
import {
  AINarrationControlsState,
  AiNarrationsDebugControls,
  calculateQuotaExceeded,
  NarrationTab,
  ScriptState,
} from 'sierra-client/views/flexible-content/ai-narrations/ai-narrations'
import { useAvatarId } from 'sierra-client/views/flexible-content/ai-narrations/use-avatar-id'
import { useNarrationEditor } from 'sierra-client/views/flexible-content/ai-narrations/use-narration-editor'
import { Debug } from 'sierra-client/views/learner/components/debug'
import { NarrationMetadata, NarrationSettings } from 'sierra-domain/api/author-v2'
import { AssetContext } from 'sierra-domain/asset-context'
import { getNarrationsYMap, NarrationsYMap } from 'sierra-domain/collaboration/narrations'
import { createScopedNarrationId, ScopedSelfPacedContentId } from 'sierra-domain/collaboration/types'
import { CreateOperationState } from 'sierra-domain/editor/operations/types'
import { FileId } from 'sierra-domain/flexible-content/identifiers'
import { iife } from 'sierra-domain/utils'

const _AINarrationsInfra: React.FC<{
  fileId: FileId
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
  narrationsYMap: NarrationsYMap
  yDocContext: AblyYDocWithAwarenessState
  debugControls: AiNarrationsDebugControls
  errorGeneratingNarration: boolean
}> = ({
  fileId,
  scopedCreateContentId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
  narrationsYMap,
  yDocContext,
  debugControls,
  errorGeneratingNarration,
}) => {
  const { showDebugData, synthesiaUnavailableOverride } = debugControls

  const isDebugMode = useIsDebugMode()
  const { minutesUsed, quotaExceeded } = calculateQuotaExceeded(debugControls, isDebugMode, narrationSettings)

  const contentId = ScopedSelfPacedContentId.extractId(scopedCreateContentId)

  const { characterLimit, quota, avatars, defaultAvatarId } = narrationSettings
  const [script, setScript] = useState<string>('')
  const [avatarId, setAvatarId] = useAvatarId(narrationsYMap, defaultAvatarId)

  const editor = useNarrationEditor({
    narrationsYMap,
    awareness: yDocContext.awareness,
    onScriptChanged: setScript,
  })

  const scriptState: ScriptState = {
    type: 'tiptap',
    editor: editor,
    script: script,
  }

  return (
    <ConfirmationModalProvider>
      <NarrationTab
        contentId={contentId}
        fileId={fileId}
        operationState={operationState}
        aiNarrationControlStateAtom={aiNarrationControlStateAtom}
        legacyNarrationStateAtom={legacyNarrationStateAtom}
        narrationMetadata={narrationMetadata}
        quotaExceeded={quotaExceeded}
        characterLimit={characterLimit}
        minutesUsed={minutesUsed}
        quota={quota}
        avatars={avatars}
        showDebugData={showDebugData}
        errorGeneratingNarration={errorGeneratingNarration}
        forceShowSynthesiaUnavailable={synthesiaUnavailableOverride === 'unavailable'}
        assetContext={assetContext}
        avatarId={avatarId}
        setAvatarId={setAvatarId}
        scriptState={scriptState}
      />
    </ConfirmationModalProvider>
  )
}

export const AINarrationsInfra: React.FC<{
  fileId: FileId
  scopedCreateContentId: ScopedSelfPacedContentId
  operationState: CreateOperationState
  aiNarrationControlStateAtom: PrimitiveAtom<AINarrationControlsState>
  legacyNarrationStateAtom: PrimitiveAtom<boolean>
  narrationSettings: NarrationSettings
  narrationMetadata: NarrationMetadata | undefined
  assetContext: AssetContext
}> = ({
  fileId,
  scopedCreateContentId,
  operationState,
  aiNarrationControlStateAtom,
  legacyNarrationStateAtom,
  narrationSettings,
  narrationMetadata,
  assetContext,
}) => {
  const yDocId = createScopedNarrationId({ scopedFileId: fileId, scopedCreateContentId })
  const yDocContext = useAblyYDocWithAwareness(yDocId)

  const narrationsYMap = useMemo(() => {
    const yDoc = yDocContext?.yDoc
    if (yDoc === undefined) {
      return undefined
    }
    return getNarrationsYMap(yDoc)
  }, [yDocContext])

  const debugControls = useDebug('ai-narrations', {
    quotaExceededOverride: ['no-override', 'exceeded'] as const,
    errorGeneratingNarrationOverride: ['no-override', 'error'] as const,
    synthesiaUnavailableOverride: ['no-override', 'unavailable'] as const,
    showDebugData: false,
    minutesUsedOverride: ['no-override', '5%', '50%', '95%', '100%', '150%'] as const,
  })
  const isDebugMode = useIsDebugMode()

  const errorGeneratingNarration = iife(() => {
    const actualValue = narrationMetadata?.type === 'error'
    if (isDebugMode && debugControls.errorGeneratingNarrationOverride === 'error') {
      return true
    } else {
      return actualValue
    }
  })

  if (yDocContext?.yDoc === undefined) {
    return <Debug>Narrations yDoc was undefined</Debug>
  }

  if (narrationsYMap === undefined) {
    return <Debug>narrationsYMap is undefined</Debug>
  }

  return (
    <_AINarrationsInfra
      debugControls={debugControls}
      fileId={fileId}
      scopedCreateContentId={scopedCreateContentId}
      operationState={operationState}
      aiNarrationControlStateAtom={aiNarrationControlStateAtom}
      legacyNarrationStateAtom={legacyNarrationStateAtom}
      narrationSettings={narrationSettings}
      narrationMetadata={narrationMetadata}
      assetContext={assetContext}
      narrationsYMap={narrationsYMap}
      yDocContext={yDocContext}
      errorGeneratingNarration={errorGeneratingNarration}
    />
  )
}
