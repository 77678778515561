import { useAtom } from 'jotai'
import { useEffect, useRef } from 'react'
import { date } from 'sierra-client/core/format'
import { useDebouncedAndLiveState } from 'sierra-client/hooks/use-debounced-state'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { dateTimesColumn, stringsColumn } from 'sierra-client/lib/tabular/column-definitions'
import { TabularToolbar } from 'sierra-client/lib/tabular/components/tabular-toolbar'
import {
  staticDataLoader,
  StaticDataLoaderMeta,
  StaticLoaderSearchKeyBy,
} from 'sierra-client/lib/tabular/dataloader/static'
import { translatedLabel } from 'sierra-client/lib/tabular/datatype/label'
import {
  definition2Data,
  TableDataFromDefinition,
  TableDefinitionOf,
} from 'sierra-client/lib/tabular/datatype/tabledefinition'
import { exportTableData } from 'sierra-client/lib/tabular/export'
import { TabularProviderFromTableAPI } from 'sierra-client/lib/tabular/provider'
import { BasicTabularSimpleSize } from 'sierra-client/lib/tabular/provider/components/basic'
import { useTableAPI, UseTableAPI } from 'sierra-client/lib/tabular/use-table-api'
import { defaultSelectVirtualColumn } from 'sierra-client/lib/tabular/utils'
import { AnimatedSearch } from 'sierra-client/views/manage/components/animated-search'
import { ActionButton } from 'sierra-client/views/manage/components/common'
import { ExportCSVIconButton } from 'sierra-client/views/manage/components/export-csv'
import { CourseRating } from 'sierra-domain/api/manage'
import { scrollViewStyles, Spacer, Text, View } from 'sierra-ui/primitives'
import styled from 'styled-components'

type CourseFeedbackData = CourseRating

type CourseFeedbackTableDefinition = TableDefinitionOf<
  CourseFeedbackData,
  [
    { type: 'strings'; ref: 'comment' },
    { type: 'strings'; ref: 'rating' },
    { type: 'dateTimes'; ref: 'createdAt' },
  ]
>

type CourseFeedbackTableData = TableDataFromDefinition<CourseFeedbackData, CourseFeedbackTableDefinition>

const tableDefinition: CourseFeedbackTableDefinition = {
  columns: [
    stringsColumn({
      ref: 'comment',
      sortable: true,
      header: translatedLabel('dictionary.comment-singular'),
      getData: row => row.comment,
    }),
    stringsColumn({
      ref: 'rating',
      sortable: true,
      header: translatedLabel('table.rating'),
      getData: row => `${row.rating.toFixed(1)} / 5`,
    }),
    dateTimesColumn({
      ref: 'createdAt',
      sortable: true,
      header: translatedLabel('dictionary.date'),
      getData: row => ({ date: new Date(row.createdAt) }),
    }),
  ],
  rows: {
    getId: row => `${row.comment.slice(-30)}-${row.rating}-${row.createdAt.valueOf()}`,
  },
  nested: {},
}

const searchKey: StaticLoaderSearchKeyBy<CourseFeedbackTableData> = (tableData, row) => {
  const rowData = tableData.rows[row]?.rawData ?? {}
  return Object.values(rowData).join(' ')
}

const useCourseFeedbackTable = ({
  data,
}: {
  data: CourseFeedbackData[]
}): UseTableAPI<CourseFeedbackTableData, StaticDataLoaderMeta<CourseFeedbackTableData>> => {
  const loader = staticDataLoader(definition2Data(tableDefinition, data), searchKey)

  return useTableAPI({
    dataLoader: {
      loader,
    },
    virtualColumns: {
      left: [defaultSelectVirtualColumn()],
      right: [],
    },
  })
}

type SearchProps = {
  tableAPI: UseTableAPI<CourseFeedbackTableData, StaticDataLoaderMeta<CourseFeedbackTableData>>
}

const Search: React.FC<SearchProps> = ({ tableAPI }) => {
  const { t } = useTranslation()

  const [debouncedQuery, liveQuery, setQuery] = useDebouncedAndLiveState(
    tableAPI.api.query.query().query ?? ''
  )

  useEffect(() => {
    tableAPI.api.action.setQuery({ query: debouncedQuery })
  }, [debouncedQuery, tableAPI.api.action])

  return <AnimatedSearch value={liveQuery} placeholder={t('manage.search.users')} onChange={setQuery} />
}

type ToolbarProps = {
  tableAPI: UseTableAPI<CourseFeedbackTableData, StaticDataLoaderMeta<CourseFeedbackTableData>>
}
const Toolbar: React.FC<ToolbarProps> = ({ tableAPI }) => {
  const { api, dataLoader } = tableAPI
  const { t } = useTranslation()
  const csvButtonText = `${t('dictionary.export')} .csv`
  const [selection] = useAtom(tableAPI.selectionAtom)

  return (
    <TabularToolbar
      countsTranslationKeys={{
        selectedKey: 'manage.tables.n-selected',
      }}
      api={api}
      clearFilters={false}
      actions={
        selection.type !== 'none' ? (
          <>
            <ActionButton
              onClick={() =>
                exportTableData({
                  api,
                  fileType: 'csv',
                  dataLoader,
                  t,
                  withName: t('dictionary.feedback'),
                })
              }
              color='blueBright'
            >
              {csvButtonText}
            </ActionButton>
            <ActionButton
              color='redBright'
              onClick={() => tableAPI.api.action.setSelection({ type: 'none' })}
            >
              {t('cancel')}
            </ActionButton>
          </>
        ) : undefined
      }
      enableAllSelection={false}
    />
  )
}

const TableWrapper = styled(View)`
  ${scrollViewStyles};
  min-height: 1rem;
  max-height: 27rem;
  width: 100%;
`

const Table: React.FC = () => {
  const scrollRef = useRef(null)

  return (
    <TableWrapper ref={scrollRef} alignItems='flex-start' direction='column'>
      <BasicTabularSimpleSize scrollRef={scrollRef.current} />
    </TableWrapper>
  )
}

const mapFeedbackToCsv = (feedback: CourseRating): Record<string, string> => ({
  comment: feedback.comment,
  createdAt: date(feedback.createdAt),
  rating: feedback.rating.toFixed(1),
})

type FooterProps = {
  data: CourseFeedbackData[]
}

const Footer: React.FC<FooterProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <View marginBottom='32' marginTop='32' justifyContent={'flex-end'}>
      <ExportCSVIconButton
        fetchCsvData={() => data.map(mapFeedbackToCsv)}
        filename={t('dictionary.feedback')}
      />
    </View>
  )
}

export type CourseFeedbackTableProps = {
  data: CourseFeedbackData[]
}

export const CourseFeedbackTable: React.FC<CourseFeedbackTableProps> = ({ data }) => {
  const { t } = useTranslation()
  const tableAPI = useCourseFeedbackTable({
    data,
  })

  return (
    <TabularProviderFromTableAPI tableAPI={tableAPI}>
      <Text size='large' bold>
        {t('dictionary.feedback')}
      </Text>
      <Spacer size='xsmall' />
      <Search tableAPI={tableAPI} />
      <Spacer size='xsmall' />
      <Toolbar tableAPI={tableAPI} />
      <Table />
      <Footer data={data} />
    </TabularProviderFromTableAPI>
  )
}
