import { EditableContent, NonEditorTeamspaceCourseType } from 'sierra-domain/api/editable-content'
import { NanoId12 } from 'sierra-domain/api/nano-id'
import { TeamspaceRoleWithOwner } from 'sierra-domain/api/teamspace'
import { AssetContext } from 'sierra-domain/asset-context'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { FileId, FolderId } from 'sierra-domain/flexible-content/identifiers'
import { File } from 'sierra-domain/flexible-content/types'
import { IconId } from 'sierra-ui/components'
import { Theme } from 'sierra-ui/theming/legacy-theme'
import { Path } from 'slate'
import { DefaultTheme } from 'styled-components'

export const DragItemTypes = {
  ContentTableContent: 'content-table',
  EditorSidebar: 'editor-sidebar',
  VerticalStack: 'vertical-stack',
  FlipCard: 'flip-card',
  MatchThePairsOption: 'match-the-pairs-option',
  PollAlternative: 'poll-alternative',
} as const

export type DragItemTypes = typeof DragItemTypes

export type ContentTableDragItem = {
  type: DragItemTypes['ContentTableContent']
  id: string
  title: string
  currentTeamspaceId: string | undefined
  highestCollaboratorRole: TeamspaceRoleWithOwner | undefined
  itemType: EditableContent['type'] | 'folder' | NonEditorTeamspaceCourseType
} & ({ iconId: IconId } | { image: ImageUnion; assetContext: AssetContext })

export type EditorSidebarDragItem =
  | {
      type: DragItemTypes['EditorSidebar']
      id: FileId
      itemType: 'file'
      parentFolderId: FolderId
      title: string
      file: File
      assetContext: AssetContext
    }
  | {
      type: DragItemTypes['EditorSidebar']
      id: FolderId
      itemType: 'folder'
      parentFolderId: FolderId
      title: string
      iconId: IconId
    }

export type VerticalStackDragItem = {
  type: DragItemTypes['VerticalStack']
  id: string
  parentId?: string
  path: Path
  width?: number
  theme: Theme
}

export type FlipCardDragItem = {
  type: DragItemTypes['FlipCard']
  currentPath: Path | undefined
  firstTextNode: string | undefined
  theme: DefaultTheme
  parentId: NanoId12 | undefined
}

export type MatchThePairsQuestionItem = {
  type: DragItemTypes['MatchThePairsOption']
  id: string
}

export type PollAlternativeDragItem = {
  type: DragItemTypes['PollAlternative']
  id: string
  parentId?: string
  path: Path
  theme: Theme
  firstTextNode: string | undefined
  image: ImageUnion | undefined
  styling: { direction: 'row' | 'column'; width: number | undefined; height: number | undefined }
  assetContext: AssetContext
}

export type DragItem =
  | ContentTableDragItem
  | EditorSidebarDragItem
  | VerticalStackDragItem
  | FlipCardDragItem
  | MatchThePairsQuestionItem
  | PollAlternativeDragItem
