import { useQuery } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React, { useMemo, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { useGraphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { homeContentQuery, useLiveContentMutation } from 'sierra-client/api/hooks/use-home-content'
import { useIsDarkTheme } from 'sierra-client/components/common/color-theme-preference'
import { Link, RouterLink } from 'sierra-client/components/common/link'
import {
  ContentCard,
  ContentCardContent,
  CreateContentCard,
  CreateContentCardCreateSuggestion,
  useFormatSessionTime,
} from 'sierra-client/components/content-card/content-card'
import { getAssetContextFromLearnEntity } from 'sierra-client/components/util/asset-contex'
import { getFlag } from 'sierra-client/config/global-config'
import { getDaysDiff, useGetDaysLeft, useGetFormattedTime } from 'sierra-client/core/format'
import { useMobileTopbarShown } from 'sierra-client/features/collapsable-sidebar'
import {
  SkillsHomepageGallery,
  getSkillsCountAndLevelsQuery,
  skillSettingsQuery,
} from 'sierra-client/features/skills'
import { useIsCreateAccessible } from 'sierra-client/hooks/use-create-enabled'
import { useResolveAsset } from 'sierra-client/hooks/use-resolve-asset'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { Trans } from 'sierra-client/hooks/use-translation/trans'
import { TranslationKey } from 'sierra-client/hooks/use-translation/types'
import { PageIdentifier, SanaPage } from 'sierra-client/layout/sana-page'
import { useDispatch, useSelector } from 'sierra-client/state/hooks'
import { selectUser } from 'sierra-client/state/user/user-selector'
import { FCC } from 'sierra-client/types'
import { getAvatarImage } from 'sierra-client/utils/avatar-img'
import { contentCardWidthPx } from 'sierra-client/views/learner/home/components/content-card-grid'
import { OverdueLabel } from 'sierra-client/views/learner/home/components/overdue-label'
import { UserManageTags } from 'sierra-client/views/learner/home/components/user-skill-settings'
import {
  homePageContentCardClickedLogger,
  homePageViewAllClickedLogger,
} from 'sierra-client/views/learner/home/logger'
import { ProgramLeaderboard } from 'sierra-client/views/learner/home/program-leaderboard'
import { HomePadding } from 'sierra-client/views/learner/home/types'
import { UpcomingSessionsSection } from 'sierra-client/views/learner/home/upcoming-sessions'
import { useRecentlyEditedQuery } from 'sierra-client/views/learner/home/use-recently-edited-query'
import {
  formatGqlEventSchedule,
  getGqlEventScheduleStart,
  gqlEventScheduleIsUpcoming,
} from 'sierra-client/views/manage/event-groups/event-utils'
import { PageHeaderRightSection } from 'sierra-client/views/settings/components/page-header'
import { ContentCardUL } from 'sierra-client/views/showcase/content-card-ul'
import { convertLearnEntityToLinkable } from 'sierra-client/views/workspace/utils/entity-to-linkable'
import { Linkable, continueUrl, detailsUrl } from 'sierra-client/views/workspace/utils/urls'
import {
  CreateEntity,
  LearnEntity,
  LiveSession,
  NativeEventGroup,
  NativeLive,
} from 'sierra-domain/api/entities'
import { ProgramLeaderboardResponse } from 'sierra-domain/api/insights'
import { FeaturedContent, HomeContentResponse } from 'sierra-domain/api/learn'
import { RecommendationsResponse } from 'sierra-domain/api/recommendations'
import { Tag } from 'sierra-domain/content/tag'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { getUserName, guardWith, isDefined, isNonNullable } from 'sierra-domain/utils'
import { Color, color } from 'sierra-ui/color'
import { Icon, RoundAvatar, TruncatedText } from 'sierra-ui/components'
import { HideScrollbarUnlessHovered } from 'sierra-ui/components/layout-kit'
import { Button, HeadingPrimitive, Skeleton, Spacer, Text, View } from 'sierra-ui/primitives'
import { token, useTokenValue } from 'sierra-ui/theming'
import { v2_breakpoint } from 'sierra-ui/theming/breakpoints'
import { TokenOverrideProvider } from 'sierra-ui/theming/providers'
import { ConditionalWrapper, dotSeparator } from 'sierra-ui/utils'
import { useOnWindowResize } from 'sierra-ui/utils/use-on-window-resize'
import styled, { css } from 'styled-components'

const HomeView = styled(View)<{ $homeInlinePadding?: HomePadding }>`
  ${p =>
    p.$homeInlinePadding &&
    css`
      padding-inline-start: ${p.$homeInlinePadding.start}px;
      padding-inline-end: ${p.$homeInlinePadding.end}px;
    `}
`

const ViewAllButton: React.FC<{ href: string; section: string }> = ({ href, section }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const logViewAllButtonClick = (): void => {
    void dispatch(
      homePageViewAllClickedLogger({
        section: section,
      })
    )
  }

  return (
    <View marginBottom='8' marginTop='16'>
      <Button
        href={href}
        icon='arrow--right'
        decoratorPosition='right'
        variant='secondary'
        onClick={logViewAllButtonClick}
      >
        {t('dictionary.view-all')}
      </Button>
    </View>
  )
}

const UserRow: React.FC = () => {
  const user = useSelector(selectUser)

  return (
    <View alignItems='center' justifyContent='space-between'>
      {user && (
        <View>
          <RoundAvatar
            size='tiny'
            firstName={user.firstName}
            lastName={user.lastName}
            src={getAvatarImage(user.uuid, user.avatar)}
            color={user.avatarColor}
          />
          <Text size='small' bold>
            {getUserName(user)}
          </Text>
        </View>
      )}
      <PageHeaderRightSection helpLink='https://help.sana.ai/' />
    </View>
  )
}

const LiveSessionMetadata: React.FC<{ entity: LiveSession }> = ({ entity }) => {
  const host = entity.facilitators[0]?.firstName
  const timeFormatter = useFormatSessionTime()
  const formattedTime = timeFormatter({
    startTime: entity.start,
    endTime: entity.end,
    allDay: entity.allDay,
    includeDate: true,
  })
  return (
    <View wrap='wrap' gap='2'>
      <Text color='foreground/secondary'>{formattedTime}</Text>
      {isDefined(host) && (
        <>
          <Icon color='foreground/secondary' iconId='radio-button--dot' />
          <Text color='foreground/secondary'>
            <Trans
              i18nKey={'home.live-session.hosted-by' satisfies TranslationKey}
              values={{ host: host }}
              components={{
                bold: <strong />,
              }}
            />
          </Text>
        </>
      )}
    </View>
  )
}

const LiveContentMetadata: React.FC<{ entity: NativeLive; dueDate: string | undefined }> = ({
  entity,
  dueDate,
}) => {
  const { t } = useTranslation()
  const query = useLiveContentMutation(entity.id)

  if (query.isPending || query.data === undefined || query.data.type !== 'native:live') {
    return <></>
  }

  const liveContent = query.data

  const futureSessions =
    liveContent.selfEnrollSessions?.filter(ce => DateTime.fromISO(ce.endTime) > DateTime.now()) ?? []
  const enrolledCalendarEvents = futureSessions.filter(it => it.isEnrolled)

  // No future scheduled sessions for live content
  if (futureSessions.length === 0)
    return <Text color='foreground/secondary'>{t('home.event.metadata.no-sessions')}</Text>

  // Live content has sessions and user is enrolled to one or more
  if (enrolledCalendarEvents.length > 0)
    return _.sortBy(enrolledCalendarEvents, it => DateTime.fromISO(it.startTime)).map(it => (
      <Text color='foreground/secondary' key={it.sessionId}>
        {DateTime.fromISO(it.startTime).toLocaleString({
          day: '2-digit',
          month: 'short',
          hour: '2-digit',
          minute: '2-digit',
        })}
        &nbsp;-&nbsp;
        {DateTime.fromISO(it.endTime).toLocaleString({
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Text>
    ))

  // Live content has sessions but user is not enrolled to any
  return (
    <View gap='2'>
      <Text color='foreground/secondary'>{t('home.assigned-content.choose-session')}</Text>
      {enrolledCalendarEvents.length === 0 && isDefined(dueDate) && (
        <>
          <Icon color='foreground/secondary' iconId='radio-button--dot' />
          <Text color='foreground/secondary'>{DateTime.fromISO(dueDate).toISODate()}</Text>
        </>
      )}
    </View>
  )
}

const getEventGroupQuery = graphql(`
  query getCalendarEventDataForHoverCard($id: CourseId!) {
    course(id: $id) {
      description
      __typename
      ... on NativeEventGroup {
        calendarEvents {
          id
          schedule {
            ...CalendarEventScheduleFragment
          }
          location {
            ...CalendarEventLocationFragment
          }
          seatsRemaining
          userAssignment {
            __typename
          }
        }
      }
    }
  }
`)

const EventGroupMetadata: React.FC<{ entity: NativeEventGroup; dueDate: string | undefined }> = ({
  entity,
  dueDate,
}) => {
  const { t } = useTranslation()
  const query = useGraphQuery(
    {
      document: getEventGroupQuery,
      queryOptions: {
        gcTime: 0,
        refetchOnWindowFocus: false,
      },
    },
    {
      id: entity.id,
    }
  )

  const eventGroup = query.data?.course
  if (
    query.isPending ||
    eventGroup === undefined ||
    eventGroup === null ||
    eventGroup.__typename !== 'NativeEventGroup'
  ) {
    return <></>
  }

  const futureSessions = eventGroup.calendarEvents.filter(ce => gqlEventScheduleIsUpcoming(ce.schedule))
  const enrolledCalendarEvents = futureSessions.filter(it => isNonNullable(it.userAssignment))

  // No future scheduled calendar events for event group
  if (futureSessions.length === 0)
    return <Text color='foreground/secondary'>{t('home.event.metadata.no-sessions')}</Text>

  // Event group has events and user is enrolled to one or more
  if (enrolledCalendarEvents.length > 0)
    return _.sortBy(enrolledCalendarEvents, it => getGqlEventScheduleStart(it.schedule)).map(it => (
      <Text color='foreground/secondary' key={it.id}>
        {formatGqlEventSchedule(it.schedule)}
      </Text>
    ))

  // Event group has events but user is not enrolled to any
  return (
    <View gap='2'>
      <Text color='foreground/secondary'>{t('home.assigned-content.choose-session')}</Text>
      {enrolledCalendarEvents.length === 0 && isDefined(dueDate) && (
        <>
          <Icon color='foreground/secondary' iconId='radio-button--dot' />
          <Text color='foreground/secondary'>{DateTime.fromISO(dueDate).toISODate()}</Text>
        </>
      )}
    </View>
  )
}

export const AssignedContentMetadata: React.FC<{ entity: LearnEntity }> = ({ entity }) => {
  const { getFormattedDaysLeft } = useGetDaysLeft()
  const totalTime = entity.timeEstimate
  const progress = entity.learnerContext.progress
  const hasStarted = progress > 0
  const timeLeft = hasStarted ? Math.round((1 - progress) * totalTime) : totalTime
  const dueDate = entity.learnerContext.assignment?.dueDate
  const pendingActions = entity.learnerContext.pendingActions

  const formattedTimeLeft = useGetFormattedTime(timeLeft, hasStarted)
  const formattedDueDate = getFormattedDaysLeft(dueDate)
  const isOverdue = isDefined(dueDate) && new Date(dueDate) < new Date()

  const isDueToday =
    isDefined(dueDate) && new Date(dueDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
  const daysLeft = isDefined(dueDate) ? getDaysDiff(DateTime.fromISO(dueDate)) : null
  const isDueWithinFiveDays = daysLeft !== null && daysLeft > 0 && daysLeft <= 5
  const hasPendingActions = isDefined(pendingActions) ? pendingActions > 0 : true

  const getLabelContent = (): JSX.Element | null => {
    if (!isDefined(formattedDueDate)) return null
    if (!hasPendingActions) return null

    if (isDueToday || isOverdue) {
      return <OverdueLabel $bgColor='redVivid'>{formattedDueDate}</OverdueLabel>
    }

    if (isDueWithinFiveDays) {
      return <OverdueLabel $bgColor='orangeVivid'>{formattedDueDate}</OverdueLabel>
    }

    return <Text color='foreground/secondary'>{formattedDueDate}</Text>
  }

  const labelContent = getLabelContent()

  switch (entity.entityType) {
    case 'native:event-group': {
      return <EventGroupMetadata entity={entity} dueDate={dueDate} />
    }
    case 'native:live': {
      return <LiveContentMetadata entity={entity} dueDate={dueDate} />
    }
    case 'live-session': {
      return <LiveSessionMetadata entity={entity} />
    }
    case 'path': {
      return (
        <View gap='2'>
          {labelContent}
          {isDefined(labelContent) && isDefined(formattedTimeLeft) && (
            <Icon color='foreground/secondary' iconId='radio-button--dot' />
          )}
          <Text color='foreground/secondary'>{formattedTimeLeft}</Text>
        </View>
      )
    }
    case 'program': {
      return (
        <View gap='2'>
          {labelContent}
          {isDefined(labelContent) && isDefined(formattedTimeLeft) && (
            <Icon color='foreground/secondary' iconId='radio-button--dot' />
          )}
          <Text color='foreground/secondary'>{formattedTimeLeft}</Text>
          <Text color='foreground/secondary'>
            <Trans
              i18nKey={'recommendation.program-step-completed-of-total' satisfies TranslationKey}
              values={{ completedCount: entity.completedStepCount, totalCount: entity.totalStepCount }}
              components={{
                bold: <strong />,
              }}
            />
          </Text>
        </View>
      )
    }
    default: {
      return (
        <View gap='2'>
          {labelContent}
          {isDefined(labelContent) && isDefined(formattedTimeLeft) && (
            <Icon color='foreground/secondary' iconId='radio-button--dot' />
          )}
          <Text color='foreground/secondary'>{formattedTimeLeft}</Text>
        </View>
      )
    }
  }
}

const NumberPill = styled(View).attrs({ alignItems: 'center', justifyContent: 'center' })`
  min-width: 16px;
  height: 16px;

  padding: 4px;
  border-radius: 100%;
  background-color: ${token('form/background/3')};
`

const VerticalDivider = styled(View)`
  margin-left: 8px;
  width: 1px;
  height: 100%;
  background-color: ${token('border/strong')};
`

const AssignedContent: React.FC<{
  entity: LearnEntity
  title?: string
  number?: number
  showDivider?: boolean
}> = ({ entity, title, number, showDivider }) => {
  const dispatch = useDispatch()
  const requiredAssignmentsEnabled = getFlag('required-assignments')

  return (
    <ConditionalWrapper
      condition={requiredAssignmentsEnabled}
      renderWrapper={children => (
        <View>
          <View direction='column' gap='12'>
            {title !== undefined && number !== undefined && (
              <View alignItems='center' gap='8'>
                <Text bold>{title}</Text>
                {number > 0 && (
                  <NumberPill>
                    <Text size='micro'>{number}</Text>
                  </NumberPill>
                )}
              </View>
            )}
            {children}
          </View>
          {showDivider === true && <VerticalDivider />}
        </View>
      )}
    >
      <ContentCard
        entity={entity}
        withProgressLine={true}
        onCardClick={() => {
          void dispatch(
            homePageContentCardClickedLogger({
              contentTitle: entity.title,
              contentType: entity.entityType,
              section: 'Assigned content',
            })
          )
        }}
        metadata={<AssignedContentMetadata entity={entity} />}
        isRequiredAssigment={
          entity.learnerContext.assignment?.isRequiredAssignment === true && requiredAssignmentsEnabled
        }
      />
    </ConditionalWrapper>
  )
}

const TimeBasedGreeting: React.FC = () => {
  const { t } = useTranslation()
  const user = useSelector(selectUser)

  const greeting = (() => {
    const hour = new Date().getHours()

    if (hour < 12) {
      return t('greeting.morning')
    } else if (hour >= 12 && hour < 17) {
      return hour === 12 ? t('greeting.day') : t('greeting.afternoon')
    } else {
      return t('greeting.evening')
    }
  })()

  return (
    <HeadingPrimitive size='h4' bold={true}>
      {greeting}
      {user ? `, ${user.firstName}` : null}
    </HeadingPrimitive>
  )
}

function getHomePaddingByWindowWidth(width: number): HomePadding {
  // start padding: Allow sufficient space for the global sidebar toggle button.
  // end padding: Take into consideration the size of the scroll bar in the main container of the app.
  if (width < 600) {
    return {
      start: 16,
      end: 8,
    }
  } else {
    return {
      start: 48,
      end: 48,
    }
  }
}

function useHomeInlinePadding(): HomePadding {
  const [padding, setPadding] = useState<HomePadding>(() => getHomePaddingByWindowWidth(window.innerWidth))

  useOnWindowResize(({ width }) => {
    const newPadding = getHomePaddingByWindowWidth(width)

    setPadding(prevPadding => (_.isEqual(prevPadding, newPadding) ? prevPadding : newPadding))
  })

  return padding
}

const HomeSection: FCC<{ id?: string }> = ({ children, id }) => <section id={id}>{children}</section>

const YourTasks = styled(View)`
  background-color: ${token('surface/default')};
`

const useLightPrimaryColor = (): Color => {
  const isDarkMode = useIsDarkTheme()
  const getToken = useTokenValue()
  const colorPrimaryAsHsla = color(getToken('org/primary')).toHsla()

  const lightPrimaryColor = color({
    h: colorPrimaryAsHsla.h,
    s: colorPrimaryAsHsla.s,
    l: isDarkMode ? 30 : 93,
    a: colorPrimaryAsHsla.a,
  })

  return lightPrimaryColor
}

const ShowAllLink = styled(Link).attrs({
  color: 'foreground/secondary',
  size: 'small',
})`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  line-height: inherit;
`

const AssignmentsSection: React.FC<{ assigned: LearnEntity[] }> = ({ assigned }) => {
  const lightPrimaryColor = useLightPrimaryColor()
  const homeInlinePadding = useHomeInlinePadding()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const isMobileTopbarShown = useMobileTopbarShown()
  const requiredAssignmentsEnabled = getFlag('required-assignments')

  const nonRemovedAssigned = assigned.filter(it => it.learnerContext.isRemovedFromTodo !== true)

  const requiredAssignments = nonRemovedAssigned.filter(
    it => it.learnerContext.assignment?.isRequiredAssignment === true
  )

  const nonRequiredAssignments = nonRemovedAssigned.filter(
    it => it.learnerContext.assignment?.isRequiredAssignment === false
  )

  const logViewAllButtonClick = (): void => {
    void dispatch(
      homePageViewAllClickedLogger({
        section: 'Assigned content',
      })
    )
  }

  return (
    <TokenOverrideProvider
      overrides={[
        ['surface/default', lightPrimaryColor], // TODO: Tomas, this doesn't work with darkmode :happy:
      ]}
    >
      <HomeSection>
        <HomeView
          direction='column'
          gap='24'
          paddingTop={!isMobileTopbarShown ? '24' : 'none'}
          $homeInlinePadding={homeInlinePadding}
        >
          {!isMobileTopbarShown && <UserRow />}

          <YourTasks
            radius='size-20'
            direction='column'
            paddingTop='24'
            paddingLeft='32'
            paddingRight='32'
            paddingBottom='24'
            gap='8'
          >
            {nonRemovedAssigned.length > 0 ? (
              <>
                <TimeBasedGreeting />
                <View alignItems='center' gap='4'>
                  <Text bold>{t('dictionary.todo-with-count', { count: assigned.length })}</Text>
                  <Text color='foreground/muted'>{dotSeparator}</Text>
                  <ShowAllLink onClick={logViewAllButtonClick} href='/learn?a=assigned-to-you' next>
                    {t('dictionary.view-all')}
                    <Icon iconId='chevron--right' size='size-10' color='currentColor' />
                  </ShowAllLink>
                </View>
                <div style={{ marginLeft: -32, marginRight: -32, marginTop: -8, marginBottom: -8 }}>
                  {requiredAssignmentsEnabled && requiredAssignments.length > 0 ? (
                    <ContentCardUL padding={{ start: 32, end: 32, top: 32, bottom: 8 }}>
                      {requiredAssignments.map((content, index) => (
                        <AssignedContent
                          key={content.id}
                          entity={content}
                          title={index === 0 ? t('assignments.required') : '\b'}
                          number={index === 0 ? requiredAssignments.length : 0}
                          showDivider={
                            index === requiredAssignments.length - 1 && nonRequiredAssignments.length > 0
                          }
                        />
                      ))}

                      {nonRequiredAssignments.map((content, index) => (
                        <AssignedContent
                          key={content.id}
                          entity={content}
                          title={index === 0 ? t('home.other-assignments') : '\b'}
                          number={index === 0 ? nonRequiredAssignments.length : 0}
                        />
                      ))}
                    </ContentCardUL>
                  ) : (
                    <ContentCardUL padding={{ start: 32, end: 32, top: 8, bottom: 8 }}>
                      {nonRemovedAssigned.map(content => (
                        <AssignedContent key={content.id} entity={content} />
                      ))}
                    </ContentCardUL>
                  )}
                </div>
              </>
            ) : (
              <>
                <TimeBasedGreeting />
                <Text>
                  <Trans
                    i18nKey={'home.welcome.subtitle--empty' satisfies TranslationKey}
                    components={{
                      bold: <strong />,
                    }}
                  />
                </Text>
              </>
            )}
          </YourTasks>
        </HomeView>
      </HomeSection>
    </TokenOverrideProvider>
  )
}

const SectionHeader = styled(View)<{ $homeInlinePadding: HomePadding }>`
  padding-inline: ${p => p.$homeInlinePadding.start}px ${p => p.$homeInlinePadding.end}px;
  padding-block-end: 8px;
`

export const SectionHeaderTitle = styled(Text).attrs({ bold: true, as: 'h2' })`
  color: ${token('foreground/secondary')};
`

const ProgramLeaderboardSection: React.FC<{ data: ProgramLeaderboardResponse }> = ({ data }) => {
  const { t } = useTranslation()
  const homeInlinePadding = useHomeInlinePadding()

  return (
    <HomeSection>
      <SectionHeader $homeInlinePadding={homeInlinePadding}>
        <Icon color='foreground/secondary' iconId='trophy' />
        <SectionHeaderTitle>{t('home.program-leaderboard.title')}</SectionHeaderTitle>
      </SectionHeader>

      <HomeView direction='column' gap='24' $homeInlinePadding={homeInlinePadding}>
        <ProgramLeaderboard data={data} />
      </HomeView>
    </HomeSection>
  )
}

const cardText = css`
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 45ch;
`

const FeaturedBottomContainer = styled(View)`
  width: 100%;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
`

const FeaturedDescription = styled(TruncatedText)`
  ${cardText};
  display: none;

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
  }
`

const FeaturedButtonGroup = styled(View)``

const FeaturedItemContainer = styled(View)<{ backgroundImage: string }>`
  flex-shrink: 0;
  height: 320px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.16)),
    url(${p => p.backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 28px 32px;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    height: 412px;

    ${FeaturedBottomContainer} {
      align-items: flex-end;
      flex-direction: row;
    }

    ${FeaturedButtonGroup} {
      margin-left: auto;
    }
  }
`

const FeaturedPrimaryButton = styled(Button)`
  background-color: #ffffff;
  color: #000000;
`

const FeaturedMutedButton = styled(Button).attrs({ variant: 'ghost' })`
  color: #ffffff;
`

const FeaturedContentTitle = styled(HeadingPrimitive)`
  ${cardText};
  padding-bottom: 4px;
  max-width: 20ch;
`

const FeaturedContentCard: React.FC<{
  image: ImageUnion | undefined
  title: string
  description: string | undefined
  linkable: Linkable
  buttonText: string | undefined
  entity: LearnEntity
}> = ({ image, title, description, linkable, buttonText, entity }) => {
  const { t } = useTranslation()

  const assetContext = useMemo(() => getAssetContextFromLearnEntity(entity), [entity])

  const backgroundImage = useResolveAsset({
    image,
    size: 'default',
    assetContext,
  })

  return (
    <FeaturedItemContainer backgroundImage={backgroundImage}>
      <FeaturedContentTitle size='h3' bold color={'white'} lines={3}>
        {title}
      </FeaturedContentTitle>
      <FeaturedBottomContainer>
        {isDefined(description) && (
          <FeaturedDescription color={'white'} lines={3}>
            {description}
          </FeaturedDescription>
        )}

        {isDefined(linkable) && (
          <FeaturedButtonGroup>
            {isDefined(buttonText) && (
              <RouterLink href={continueUrl(linkable)}>
                <FeaturedPrimaryButton>{buttonText}</FeaturedPrimaryButton>
              </RouterLink>
            )}
            <RouterLink href={detailsUrl(linkable)}>
              <FeaturedMutedButton>{t('manage.view-details')}</FeaturedMutedButton>
            </RouterLink>
          </FeaturedButtonGroup>
        )}
      </FeaturedBottomContainer>
    </FeaturedItemContainer>
  )
}

const FeaturedContentGrid = styled.div<{ numCards: number; homeInlinePadding: HomePadding }>`
  display: grid;
  grid-template-columns: repeat(${p => p.numCards}, minmax(${contentCardWidthPx}px, 1fr));
  gap: 16px;
  padding-inline: ${p => p.homeInlinePadding.start}px ${p => p.homeInlinePadding.end}px;
  overflow: auto;
  ${HideScrollbarUnlessHovered};

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);

    ${FeaturedItemContainer}:only-child {
      grid-column: span 2;
    }

    ${p =>
      p.numCards === 3 &&
      css`
        ${FeaturedItemContainer}:nth-child(1) {
          grid-column: span 2;
        }
      `};
  }
`

const FeaturedContentSection: React.FC<{ featured: FeaturedContent[] }> = ({ featured }) => {
  const { t } = useTranslation()
  const homeInlinePadding = useHomeInlinePadding()

  return (
    <HomeSection>
      <HomeView direction='column'>
        <SectionHeader $homeInlinePadding={homeInlinePadding}>
          <Icon color='foreground/secondary' iconId='pin' />
          <SectionHeaderTitle>{t('recommendation.featured')}</SectionHeaderTitle>
        </SectionHeader>

        <FeaturedContentGrid numCards={featured.length} homeInlinePadding={homeInlinePadding}>
          {featured.map(content => {
            return (
              <FeaturedContentCard
                key={content.entity.id}
                image={content.entity.image}
                entity={content.entity}
                title={content.title}
                description={content.description}
                linkable={convertLearnEntityToLinkable(content.entity)}
                buttonText={content.buttonText}
              />
            )
          })}
        </FeaturedContentGrid>
      </HomeView>
    </HomeSection>
  )
}

const RecentlyEditedContentSection: React.FC<{ recentlyEdited: RecommendationsResponse }> = ({
  recentlyEdited,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const homeInlinePadding = useHomeInlinePadding()

  return (
    <HomeSection>
      <HomeView direction='column' marginTop='4' gap='none'>
        <SectionHeader $homeInlinePadding={homeInlinePadding}>
          <Icon color='foreground/secondary' iconId='idea' />
          <SectionHeaderTitle>{t('recommendation.recently-edited')}</SectionHeaderTitle>
        </SectionHeader>

        <ContentCardUL padding={{ ...homeInlinePadding, bottom: 8, top: 8 }}>
          <CreateContentCardCreateSuggestion />

          {recentlyEdited.recommendations.map(({ card }) => {
            const entity = 'entity' in card ? card.entity : undefined

            if (!guardWith(CreateEntity, entity)) {
              return null
            }

            return (
              <CreateContentCard
                key={entity.id}
                entity={entity}
                onCardClick={() => {
                  void dispatch(
                    homePageContentCardClickedLogger({
                      contentTitle: entity.title,
                      contentType: entity.entityType,
                      section: 'recently_edited',
                    })
                  )
                }}
              />
            )
          })}
        </ContentCardUL>
      </HomeView>
    </HomeSection>
  )
}

const PopularContentSection: React.FC<{ popular: LearnEntity[] }> = ({ popular }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const homeInlinePadding = useHomeInlinePadding()

  return (
    <HomeSection>
      <HomeView direction='column' marginTop='4' gap='none'>
        <SectionHeader $homeInlinePadding={homeInlinePadding}>
          <Icon color='foreground/secondary' iconId='idea' />
          <SectionHeaderTitle>{t('home.popular.title')}</SectionHeaderTitle>
        </SectionHeader>

        <ContentCardUL padding={{ ...homeInlinePadding, bottom: 8, top: 8 }}>
          {popular.map(entity => (
            <ContentCardContent
              key={entity.id}
              entity={entity}
              onCardClick={() => {
                void dispatch(
                  homePageContentCardClickedLogger({
                    contentTitle: entity.title,
                    contentType: entity.entityType,
                    section: 'recommended',
                  })
                )
              }}
            />
          ))}
        </ContentCardUL>
        <View paddingLeft='48' paddingRight='32'>
          <ViewAllButton href='/browse?s=popular&c=any-type' section='recommended' />
        </View>
      </HomeView>
    </HomeSection>
  )
}

const LearnerboardContentSection: React.FC = () => {
  const { t } = useTranslation()
  const homeInlinePadding = useHomeInlinePadding()
  const skillsRequest = useGraphQuery({ document: getSkillsCountAndLevelsQuery }, {})
  const hideSkills = useGraphQuery({ document: skillSettingsQuery }).data?.skillSettings.hideSkills ?? true

  if (hideSkills) {
    return null
  }

  if (!skillsRequest.data) {
    return null
  }

  if (skillsRequest.data.skills.totalCount === 0) {
    return null
  }

  return (
    <HomeSection>
      <HomeView direction='column' paddingLeft='none' paddingRight='none'>
        <SectionHeader $homeInlinePadding={homeInlinePadding}>
          <Icon color='foreground/secondary' iconId='skill' />
          <SectionHeaderTitle>{_.capitalize(t('dictionary.skills'))}</SectionHeaderTitle>
        </SectionHeader>
        <SkillsHomepageGallery homeInlinePadding={homeInlinePadding} />
      </HomeView>
    </HomeSection>
  )
}

const _Category = styled.div<{ $background: string | undefined }>`
  width: 100%;
  height: 100%;
  aspect-ratio: 4/5;
  max-height: 320px;
  flex-shrink: 0;
  border-radius: 18px;
  padding: 24px;
  background-color: ${token('surface/strong')};
  color: ${token('foreground/primary')};

  ${p =>
    p.$background !== undefined &&
    css`
      background-image: linear-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.4) 80%),
        url('${p.$background}');
      color: white;
    `}

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: flex-end;
`

const CategoryTitle = styled(TruncatedText)`
  line-height: 1.35;
`

const ScaleOnHover = styled(motion.div)`
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  will-change: transform, scale;
  transform: translate3d(0, 0, 0);
`

const Category: React.FC<{ category: Tag }> = ({ category }) => {
  return (
    <RouterLink href={`/browse?t=${category.id}`}>
      <ScaleOnHover
        initial={{ scale: 1 }}
        exit={{ scale: 1 }}
        transition={{ ease: [0.25, 0.1, 0.25, 1], duration: 0.18 }}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 1.01 }}
      >
        <_Category
          $background={
            category.data.image !== undefined
              ? `https://res.cloudinary.com/sana-ai/image/fetch/q_auto:eco/w_720/${category.data.image}`
              : undefined
          }
        >
          <View direction='column' justifyContent='flex-end' gap='4' alignItems='center' grow>
            <CategoryTitle lines={2} size='large' align='center' bold color='currentColor'>
              {category.data.name}
            </CategoryTitle>
            {/*


          Will figure out what to do with this /Kalle


          {category.data.description?.length !== 0 && (
            <TruncatedText size='small' lines={2} align='center' color='currentColor'>
              {category.data.description}
            </TruncatedText>
          )}*/}
          </View>
        </_Category>
      </ScaleOnHover>
    </RouterLink>
  )
}

const SkillGrid = styled.div<{ padding: HomePadding }>`
  padding-inline: ${p => p.padding.start}px ${p => p.padding.end}px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  margin-bottom: 16px;

  @media screen and (min-width: ${v2_breakpoint.phone}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
  }

  @media screen and (min-width: ${v2_breakpoint.tablet}) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
  }

  @media screen and (min-width: ${v2_breakpoint.desktop_small}) {
    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: 1200px) {
    grid-gap: 1rem;
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (min-width: ${v2_breakpoint.desktop}) {
    grid-gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }
`

const BrowseCategories: React.FC<{
  toggleBrowseCategories: () => void
}> = ({ toggleBrowseCategories }) => {
  const { t } = useTranslation()
  return (
    <Button
      onClick={toggleBrowseCategories}
      icon='arrow--right'
      decoratorPosition='right'
      variant='secondary'
    >
      {t('home.browse-categories')}
    </Button>
  )
}

const EmptyCategoriesSection: React.FC<{
  toggleBrowseCategories: () => void
}> = ({ toggleBrowseCategories }) => {
  const { t } = useTranslation()
  return (
    <View
      radius='size-20'
      background='surface/soft'
      padding='40'
      direction='column'
      alignItems='center'
      marginLeft='40'
      marginRight='32'
    >
      <Text bold color='foreground/secondary'>
        {t('learner-home.no-categories.title')}
      </Text>
      <Text color='foreground/secondary'>{t('learner-home.no-categories.body')}</Text>
      <View marginTop='16'>
        <BrowseCategories toggleBrowseCategories={toggleBrowseCategories} />
      </View>
    </View>
  )
}

const CategoriesSection: React.FC<{
  categories: Tag[]
  refetch: () => void
  includeBrowseCategories: boolean
}> = ({ categories, refetch, includeBrowseCategories }) => {
  const { t } = useTranslation()
  const homeInlinePadding = useHomeInlinePadding()
  const [showManageTags, setShowManageTags] = useState(false)

  return (
    <HomeSection>
      <HomeView direction='column' paddingLeft={'none'} paddingRight={'none'}>
        <SectionHeader $homeInlinePadding={homeInlinePadding}>
          <Icon color='foreground/secondary' iconId='browse' />
          <SectionHeaderTitle>{t('learner-home.browse')}</SectionHeaderTitle>
        </SectionHeader>
        {includeBrowseCategories && categories.length === 0 ? (
          <EmptyCategoriesSection toggleBrowseCategories={() => setShowManageTags(!showManageTags)} />
        ) : (
          <SkillGrid padding={homeInlinePadding}>
            {categories.map(category => (
              <Category category={category} key={category.id} />
            ))}
          </SkillGrid>
        )}
      </HomeView>
      {includeBrowseCategories && categories.length !== 0 && (
        <View marginBottom='16' marginTop='8' paddingLeft='48' paddingBottom='16'>
          <BrowseCategories toggleBrowseCategories={() => setShowManageTags(!showManageTags)} />
        </View>
      )}
      {showManageTags && <UserManageTags onClose={() => setShowManageTags(false)} refetch={refetch} />}
    </HomeSection>
  )
}

type PoweredBySanaProps = {
  padding: HomePadding
}
const PoweredBySana = styled(Text).attrs({ children: <>Powered by Sana™</> })<PoweredBySanaProps>`
  margin-inline: ${p => p.padding.start}px ${p => p.padding.end}px;
  color: ${token('foreground/secondary')};
`

const Footer: React.FC = () => {
  const homeInlinePadding = useHomeInlinePadding()

  return (
    <HomeSection>
      <HomeView
        direction='column'
        alignItems='flex-end'
        paddingLeft='none'
        paddingRight='none'
        paddingBottom='32'
      >
        <PoweredBySana padding={homeInlinePadding} />
      </HomeView>
    </HomeSection>
  )
}

type HomeDividerProps = {
  padding: HomePadding
}
const HomeDivider = styled.div.attrs({
  role: 'separator',
})<HomeDividerProps>`
  background: ${token('border/default')};
  height: 1px;
  margin-inline: ${p => p.padding.start}px ${p => p.padding.end}px;
`

const Loading: React.FC = () => {
  const homeInlinePadding = useHomeInlinePadding()

  return (
    <>
      <HomeSection>
        <HomeView direction='column' gap='24' paddingTop='24' $homeInlinePadding={homeInlinePadding}>
          <UserRow />
          <View direction='column' grow alignItems='stretch'>
            <Skeleton $radius={20} $height={440} />
          </View>
          <View direction='column' grow alignItems='stretch' gap='16'>
            <Spacer size='16' />
            <Skeleton $radius={8} $width={150} $height={25} />
            <View grow gap='16'>
              <View direction='column' grow alignItems='stretch'>
                <Skeleton $radius={20} $height={440} />
              </View>
              <View direction='column' grow alignItems='stretch'>
                <Skeleton $radius={20} $height={440} />
              </View>
            </View>
          </View>
          <Spacer size='16' />
        </HomeView>
      </HomeSection>
    </>
  )
}

const HomeV3Content: React.FC<{
  content: HomeContentResponse
  recentlyEdited: RecommendationsResponse
  refetch: () => void
}> = ({ content, refetch, recentlyEdited }) => {
  const homeInlinePadding = useHomeInlinePadding()
  const showAllSections = getFlag('new-home-page-show-all-sections')

  const hasCreateAccess = useIsCreateAccessible()
  const showRecentlyEditedSection = getFlag('updated-home-reels')

  const includeBrowseCategories = getFlag('onboarding/mode') === 'skills-and-content'

  return (
    <>
      <AssignmentsSection assigned={content.assigned} />
      {hasCreateAccess && showRecentlyEditedSection && recentlyEdited.recommendations.length > 0 && (
        <RecentlyEditedContentSection recentlyEdited={recentlyEdited} />
      )}
      {content.featured.length > 0 && <FeaturedContentSection featured={content.featured} />}
      {content.programLeaderboard && <ProgramLeaderboardSection data={content.programLeaderboard} />}
      <LearnerboardContentSection />
      {content.popular.length > 0 && showAllSections && <PopularContentSection popular={content.popular} />}
      {content.upcomingSessions.length > 0 && (
        <UpcomingSessionsSection upcomingSessions={content.upcomingSessions} />
      )}
      {(content.categories.length > 0 || includeBrowseCategories) && (
        <>
          <CategoriesSection
            categories={content.categories}
            refetch={refetch}
            includeBrowseCategories={includeBrowseCategories}
          />
          <HomeDivider padding={homeInlinePadding} />{' '}
        </>
      )}
      {showAllSections && <Footer />}
    </>
  )
}

export const HomeV3 = (): JSX.Element => {
  const homeQuery = useQuery(homeContentQuery)
  const recentlyEditedQuery = useRecentlyEditedQuery()
  const refetch = homeQuery.refetch
  const content = homeQuery.data
  const recentlyEdited = recentlyEditedQuery.data

  return (
    <SanaPage showIntercomLauncher={true} page={PageIdentifier.LearnerNewHome()}>
      <View direction='column' gap='32' marginBottom='none'>
        {content === undefined || recentlyEdited === undefined ? (
          <Loading />
        ) : (
          <HomeV3Content content={content} refetch={refetch} recentlyEdited={recentlyEdited} />
        )}
      </View>
    </SanaPage>
  )
}
