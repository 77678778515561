import React, { useCallback } from 'react'
import { IconMenu } from 'sierra-client/components/common/icon-menu'
import {
  HeaderButtonColorCSS,
  HeaderButtonGroupWrapper,
} from 'sierra-client/components/liveV2/header-buttons'
import { FileBackgroundPicker } from 'sierra-client/views/flexible-content/card-background/file-background-picker'
import { useCreatePageContext } from 'sierra-client/views/flexible-content/create-page-context'
import { FileThemePicker } from 'sierra-client/views/flexible-content/file-theme-picker'
import { getIsSummaryEnabledFromEditor } from 'sierra-client/views/v3-author/reflection-card/reflection-card-create-context'
import { useIsReflectionsSummaryEnabled } from 'sierra-client/views/v3-author/reflection-card/use-is-reflections-summary-enabled'
import type { AssetContext } from 'sierra-domain/asset-context'
import { apply } from 'sierra-domain/editor/operations/operation'
import { File } from 'sierra-domain/flexible-content/types'
import { MenuItem } from 'sierra-ui/components'
import { CustomThemeName, PresetThemeName } from 'sierra-ui/theming/legacy-theme'
import styled from 'styled-components'

const StyledIconMenu = styled(IconMenu)`
  cursor: pointer;
  padding: 0 0.375rem;

  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0.625rem;

  ${HeaderButtonColorCSS}
`

export const ReflectionCardToolbar: React.FC<{
  file: File
  previewThemes?: (theme: undefined | CustomThemeName | PresetThemeName) => void
  assetContext: AssetContext
  isLive: boolean
}> = ({ file, previewThemes, assetContext, isLive }) => {
  const { operationState } = useCreatePageContext()
  const summaryEnabled = getIsSummaryEnabledFromEditor(file)

  const isReflectionsSummaryFlagEnabled = useIsReflectionsSummaryEnabled()

  const setSummaryEnabled = useCallback(
    (enable: boolean): void => {
      apply(operationState, {
        type: 'update-files',
        fileIds: [file.id],
        update: file => {
          if (file.data.type !== 'reflections') throw Error(`File ${file.id} is not a reflections card`)

          const settings = file.data.settings ?? { allowAnonymousResponses: true }

          settings.enabledSummary = enable

          file.data.settings = settings
        },
      })
    },
    [file, operationState]
  )

  const menuItems: MenuItem[] = [
    {
      type: 'switch',
      id: 'reflection-summary',
      //TODO (Norah): Add translation and change icon
      label: 'Enable reflection summary',
      icon: 'rating--3',
      onToggleChange: () => {
        setSummaryEnabled(!summaryEnabled)
      },
      checked: summaryEnabled,
    },
  ]

  return (
    <HeaderButtonGroupWrapper gap='none'>
      <FileThemePicker previewThemes={previewThemes} file={file} />
      <FileBackgroundPicker file={file} assetContext={assetContext} />
      {/*Todo (Norah): Update icon*/}
      {isReflectionsSummaryFlagEnabled && isLive && (
        <StyledIconMenu withChevron={true} iconId='settings' items={menuItems} />
      )}
    </HeaderButtonGroupWrapper>
  )
}
