import { apiQueryOptions } from 'sierra-client/state/api'
import { XRealtimeContentCreate } from 'sierra-domain/routes'

export const createPageQuery = apiQueryOptions(
  XRealtimeContentCreate,
  {},
  {
    retry: false,
    staleTime: 5 * 1000, // 5 seconds
  }
)
