import DOMPurify from 'dompurify'
import _ from 'lodash'
import { fileIdAsUUID } from 'sierra-client/api/content'
import {
  isArrayOfSanaCardFragment,
  isSanaCardFragment,
  isSanaModuleFragment,
} from 'sierra-client/views/flexible-content/editor/content-sidebar/copy-paste-utils'
import { isInElement } from 'sierra-client/views/v3-author/command'
import { CopyPasteWithAssetsOptions } from 'sierra-client/views/v3-author/configuration/copy-paste-with-assets-options'
import {
  CopyPasteClipboardKey,
  CopyPasteContext,
} from 'sierra-client/views/v3-author/paste/copy-paste-context'
import { getCurrentlySelectedLeaf, isElementType } from 'sierra-client/views/v3-author/queries'
import { ImageUnion } from 'sierra-domain/content/v2/image-union'
import { redactSlateDocument } from 'sierra-domain/editor/redact-slate-document'
import { isUrlRegex, urlParseText } from 'sierra-domain/editor/url-parse-text'
import { Entity } from 'sierra-domain/entity'
import { nanoid12 } from 'sierra-domain/nanoid-extensions'
import { hasOnlyEmptyTextInNodes } from 'sierra-domain/slate-util'
import { getUrlFromText, iife } from 'sierra-domain/utils'
import {
  BlockQuote,
  CustomElement,
  CustomText,
  Heading,
  HorizontalStack,
  Image,
  Link,
  ListItem,
  Marks,
  OrderedList,
  Paragraph,
  ParagraphChild,
  SanaEditor,
  Separator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  UnorderedList,
} from 'sierra-domain/v3-author'
import {
  createHorizontalStack,
  createImage,
  createLink,
  createParagraph,
} from 'sierra-domain/v3-author/create-blocks'
import { dynamicColor } from 'sierra-ui/color'
import { Descendant, Editor, EditorMarks, Element as SlateElement, Text, Transforms } from 'slate'

export type ResolveImage = (src: string) => { image: ImageUnion } | undefined

function removeTabs(text: string): string {
  return text.replace(/\t/g, ' ')
}

const debug = (...messages: unknown[]): void => console.debug('[withPasteHtml]', ...messages)
type Base<T> = Omit<T, 'children'>

type CreatorResult<T> = {
  element: T
  imageUrl?: string
}

function createBaseElementCreators(
  resolveImage: ResolveImage | undefined
): Record<string, (childNode: ChildNode) => CreatorResult<Base<Omit<CustomElement, 'id'>>> | undefined> {
  return {
    P: (): CreatorResult<Base<Paragraph>> => ({ element: { type: 'paragraph' } }),
    A: (el): CreatorResult<Base<Link>> => ({
      element: {
        type: 'link',
        url: (el as HTMLElement).getAttribute('href') ?? '',
      },
    }),
    H1: (): CreatorResult<Base<Heading>> => ({ element: { type: 'heading', level: 1 } }),
    H2: (): CreatorResult<Base<Heading>> => ({ element: { type: 'heading', level: 2 } }),
    H3: (): CreatorResult<Base<Heading>> => ({ element: { type: 'heading', level: 3 } }),
    H4: (): CreatorResult<Base<Paragraph>> => ({ element: { type: 'paragraph', level: 0 } }),
    H5: (): CreatorResult<Base<Paragraph>> => ({ element: { type: 'paragraph', level: 0 } }),
    H6: (): CreatorResult<Base<Paragraph>> => ({ element: { type: 'paragraph', level: 0 } }),
    LI: (el): CreatorResult<Base<ListItem>> => {
      const indent = Math.max(
        0,
        ...[
          // Slack
          (el as HTMLElement).getAttribute('data-stringify-indent') ?? '0',
          // Google Drive
          (el as HTMLElement).getAttribute('aria-level') ?? '0',
        ]
          .map(it => parseInt(it))
          .filter(it => !Number.isNaN(it))
      )

      return { element: { type: 'list-item', ordinal: undefined, indent: indent } }
    },
    OL: (): CreatorResult<Base<OrderedList>> => ({ element: { type: 'numbered-list' } }),
    UL: (): CreatorResult<Base<UnorderedList>> => ({ element: { type: 'bulleted-list' } }),
    HR: (): CreatorResult<Base<Separator>> => ({ element: { type: 'separator', variant: 'solid' } }),
    TABLE: (): CreatorResult<Base<Table>> => ({
      element: { type: 'table', options: { withHeaders: true } },
    }),
    THEAD: (): CreatorResult<Base<TableHead>> => ({ element: { type: 'table-head' } }),
    TBODY: (): CreatorResult<Base<TableBody>> => ({ element: { type: 'table-body' } }),
    TR: (): CreatorResult<Base<TableRow>> => ({ element: { type: 'table-row', options: {} } }),
    TH: (): CreatorResult<Base<TableHeaderCell>> => ({
      element: { type: 'table-header-cell', options: {} },
    }),
    TD: (): CreatorResult<Base<TableCell>> => ({ element: { type: 'table-cell', options: {} } }),
    IMG: (el): CreatorResult<Base<Image>> | undefined => {
      const src = (el as HTMLElement).getAttribute('src')
      const image = src === null ? undefined : ({ type: 'file', file: fileIdAsUUID(src) } as const)
      const altText = (el as HTMLElement).getAttribute('alt') ?? undefined
      const size = iife((): Partial<Image> | undefined => {
        const width = (el as HTMLElement).getAttribute('data-width')
        if (width === null) return undefined
        const floatWidth = parseFloat(width)
        if (floatWidth > 0 && floatWidth < 1) return { customSize: floatWidth }
        if (floatWidth === 1) return { variant: 'full-width' }
        else return undefined
      }) ?? { variant: 'narrow' }

      if (altText === undefined && src === null) {
        return
      }
      const resolvedImage = src !== null && resolveImage !== undefined ? resolveImage(src) : undefined
      if (resolvedImage !== undefined) {
        return {
          element: createImage({ image: resolvedImage.image, altText, ...size }),
        }
      }

      if (src !== null && !isUrlRegex.test(src)) {
        return
      }
      return {
        element: {
          type: 'image',
          image,
          credit: undefined,
          altText,
          hotspots: {},
          hotspotsMandatory: false,
          ...size,
        },
        imageUrl: src !== null ? src : undefined,
      }
    },
    BLOCKQUOTE: (): CreatorResult<Base<BlockQuote>> => ({ element: { type: 'block-quote' } }),
    DIV: (childNode: ChildNode): CreatorResult<Base<Omit<CustomElement, 'id'>>> | undefined => {
      if (!(childNode instanceof HTMLDivElement)) return undefined
      if (childNode.className === 'question') {
        return { element: { type: 'question-card' } }
      }
      if (childNode.className === 'question-body') {
        return { element: { type: 'question-card-select-all-that-apply-body' } }
      }
      if (childNode.className === 'choice') {
        return {
          element: { type: 'question-card-multiple-choice-alternative', status: 'correct' },
        } as CreatorResult<
          Base<
            //Type system kinda fails here, we need 'status' but it doesnt recongnize it.
            Omit<CustomElement, 'id'>
          >
        >
      }
      if (childNode.className === 'quote') {
        return { element: { type: 'block-quote' } }
      }
      if (childNode.className === 'block-quote-subtitle') {
        return { element: { type: 'block-quote-subtitle' } }
      }
      if (childNode.className === 'preamble') {
        return { element: { type: 'preamble' } }
      }
      if (childNode.className === 'row') {
        return { element: { type: 'horizontal-stack' } }
      }
      if (childNode.className === 'column') {
        return { element: { type: 'vertical-stack' } }
      }

      // We don't know how else to handle it, so treat it as a paragraph
      return { element: { type: 'paragraph' } }
    },
  }
}

function getMarks(el: ChildNode): Partial<Marks> {
  const marks: Record<string, Partial<Marks>> = {
    I: { italic: true },
    U: { underline: true },
    EM: { italic: true },
    B: iife(() => {
      const tag = el as HTMLElement
      const isStyleReverted = tag.getAttribute('style') === 'font-weight:normal;'
      const isGoogleDocGuid = (tag.getAttribute('style') ?? '').startsWith('docs-internal-guid-')

      // Google docs uses <b> tags incorrectly and they revert the boldness with a style attribute.
      if (isStyleReverted || isGoogleDocGuid) return {}
      else return { bold: true }
    }),
    STRONG: { bold: true },
    S: { strikethrough: true },
    SUP: { supscript: true },
    SUB: { subscript: true },
  }

  const color = iife(() => {
    if (el instanceof HTMLElement) {
      const styleColor = el.style.color
      const normalizedColor = Boolean(styleColor) ? dynamicColor(styleColor) : undefined

      if (normalizedColor) {
        return {
          color: normalizedColor.toRgbaString().toString(),
        }
      }
    }
    return {}
  })

  return {
    ...color,
    ...marks[el.nodeName.toUpperCase()],
  }
}

const extractParagraphChildren = (elements: Descendant[]): ParagraphChild[] =>
  elements.flatMap(it =>
    SlateElement.isElement(it) && it.type !== 'link' ? extractParagraphChildren(it.children) : [it]
  )

function addMarks<D extends Descendant>(node: D, marks: Omit<EditorMarks, 'color'>): D {
  if (Text.isText(node)) return { ...node, ...marks }
  else return { ...node, children: node.children.map(node => addMarks(node, marks)) }
}

function unwrapNodeToTextNodes(node: Descendant): Descendant[] {
  if (SlateElement.isElement(node)) {
    return node.children.flatMap(unwrapNodeToTextNodes)
  } else {
    return [node]
  }
}

const deserialize = (
  el: ChildNode,
  editor: SanaEditor | undefined,
  { resolveImage }: { resolveImage: ResolveImage | undefined }
): Descendant[] => {
  if (el.nodeType === 3) {
    return [{ text: el.textContent ?? '' }]
  } else if (el.nodeType !== 1) {
    return []
  } else if (el.nodeName === 'BR') {
    return [{ text: '\n' }]
  } else if (el.nodeName === 'COLGROUP') {
    return []
  }

  const deserializedChildren = Array.from(el.childNodes).flatMap(el =>
    deserialize(el, editor, { resolveImage: resolveImage })
  )

  const marks = getMarks(el)
  const children = (deserializedChildren.length === 0 ? [{ text: '' }] : deserializedChildren).map(child =>
    addMarks(child, marks)
  )

  const { nodeName } = el
  if (el.nodeName === 'BODY') {
    return children
  }

  const createBaseElement = createBaseElementCreators(resolveImage)[nodeName.toUpperCase()]
  const baseResult = createBaseElement?.(el)

  if (baseResult !== undefined) {
    const { element: baseElement, imageUrl } = baseResult

    const childElements =
      baseElement.type === 'list-item'
        ? // We don't allow list items to contain arbitrary content.
          // So we need to filter out illegal nodes here:
          extractParagraphChildren(children)
        : baseElement.type === 'bulleted-list' || baseElement.type === 'numbered-list'
          ? //If a child is a bulleted or numbered list, unwrap the list, if its a list item keep it
            children.flatMap(child =>
              isElementType('bulleted-list', child) || isElementType('numbered-list', child)
                ? child.children
                : isElementType('list-item', child)
                  ? child
                  : []
            )
          : children

    const unsafeElement = {
      ...baseElement,
      id: nanoid12(),
      children: childElements.length === 0 ? [{ text: '' }] : childElements,
    }

    const saferElement =
      unsafeElement.type === 'question-card-multiple-choice-alternative'
        ? {
            ...unsafeElement,
            children: children.flatMap((node): Descendant[] => {
              if (SlateElement.isElement(node)) {
                return [node]
              } else {
                // Text node
                return [
                  {
                    type: 'question-card-multiple-choice-alternative-option',
                    id: nanoid12(),
                    children: [node],
                  },
                  {
                    type: 'question-card-multiple-choice-alternative-explanation',
                    id: nanoid12(),
                    children: [{ text: '' }],
                  },
                ]
              }
            }),
          }
        : unsafeElement

    if (imageUrl !== undefined && editor !== undefined) editor.initialImageUrls[saferElement.id] = imageUrl

    const customElementResult = CustomElement.safeParse(saferElement)
    if (customElementResult.success) {
      return [customElementResult.data]
    }

    // Check if the element can be parsed if we remove all its empty text node children
    // (this happens often with html formatted with lots of whitespace)
    const customElementResultWithoutEmptyTexts = CustomElement.safeParse({
      ...saferElement,
      children: saferElement.children.flatMap(it => {
        if (Text.isText(it) && it.text.trim() === '') {
          return []
        } else {
          return [it]
        }
      }),
    })

    if (customElementResultWithoutEmptyTexts.success) {
      return [customElementResultWithoutEmptyTexts.data]
    }

    // Check if the element can be parsed if we unwrap all its text nodes
    const customElementResultWithUnwrappedChildren = CustomElement.safeParse({
      ...saferElement,
      children: saferElement.children.flatMap(unwrapNodeToTextNodes),
    })

    if (customElementResultWithUnwrappedChildren.success) {
      return [customElementResultWithUnwrappedChildren.data]
    }

    // Check if the element can be parsed if we wrap all its text nodes in paragraphs
    const customElementResultWithWrappedTextNodes = CustomElement.safeParse({
      ...saferElement,
      children: saferElement.children.flatMap(child => {
        if (Text.isText(child)) {
          return createParagraph({ children: [{ ...child }] })
        } else {
          return child
        }
      }),
    })

    if (customElementResultWithWrappedTextNodes.success) {
      return [customElementResultWithWrappedTextNodes.data]
    }

    return children
  }

  return children
}

/**
 * Slate fragments can start with leaf nodes and end with leaf nodes,
 * but text nodes cannot occur between elements.
 * This wraps the intermediate leaves in paragraphs.
 */
function toFragment(nodes: Descendant[]): Descendant[] {
  function isTextOrLink(node: Descendant | undefined): node is CustomText | Entity<Link> {
    return Text.isText(node) || isElementType('link', node)
  }

  const firstNonTextNode = nodes.findIndex(it => !isTextOrLink(it))
  if (firstNonTextNode === -1) return nodes

  const lastNonTextNode = nodes.findLastIndex(it => !isTextOrLink(it))
  if (lastNonTextNode === -1) return nodes

  if (firstNonTextNode === lastNonTextNode) return nodes

  const middle: Descendant[] = []
  let acc: ParagraphChild[] = []
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node === undefined) break

    if (i < firstNonTextNode || i > lastNonTextNode) {
      middle.push(node)
      continue
    }

    if (!isTextOrLink(node)) {
      if (acc.length > 0) {
        middle.push(createParagraph({ children: acc }))
        acc = []
      }
      middle.push(node)
    } else {
      acc.push(node)
    }
  }
  if (acc.length > 0) {
    middle.push(createParagraph({ children: acc }))
  }
  return middle
}

function redactString(text: string): string {
  return Array.from(text)
    .map(char => {
      if (char === '\n') return '\\n'
      if (/\s/.exec(char)) return char
      return '█'
    })
    .join('')
}

function redactHtml(html: string): string {
  function redactNode(node: Element): void {
    if (node.nodeType === 3) {
      const text = node.textContent ?? ''
      node.textContent = redactString(text)
    }

    node.childNodes.forEach(child => {
      redactNode(child as Element)
    })
  }

  try {
    const parsed = new DOMParser().parseFromString(html, 'text/html')
    redactNode(parsed.body)
    return new XMLSerializer().serializeToString(parsed)
  } catch (e) {
    return 'unable to redact html'
  }
}

export const parsePastedHtml = (
  html: string,
  {
    editor,
    resolveImage,
  }: {
    editor: SanaEditor | undefined
    resolveImage: ResolveImage | undefined
  }
): Descendant[] => {
  const parsed = new DOMParser().parseFromString(html, 'text/html')
  const deserialized = deserialize(parsed.body, editor, { resolveImage }).filter(
    node => !Text.isText(node) || node.text.trim() !== ''
  )

  const flattened = toFragment(deserialized)
  debug(
    'PASTED HTML:',
    redactHtml(html),
    '\n\nRESULT',
    JSON.stringify(redactSlateDocument(flattened, { redactString }))
  )
  return flattened
}

type TextFormat = {
  bold: boolean
  italic: boolean
  underline: boolean
}

type FormatChecker = (node: Element) => Partial<TextFormat>

const interpolateTextFormat = (node: Element, rules: FormatChecker[]): TextFormat => {
  const defaultFormat: TextFormat = {
    bold: false,
    italic: false,
    underline: false,
  }

  return rules.reduce((format, rule) => {
    const partialFormat = _.pickBy(rule(node), v => v === true)

    return {
      ...format,
      ...partialFormat,
    }
  }, defaultFormat)
}

const checkers: FormatChecker[] = [
  // CSS underline styling
  node => ({
    underline: node.getAttribute('style')?.includes('text-decoration:underline'),
  }),

  // Notion specific underline
  node => ({
    underline: node.getAttribute('style')?.includes('border-bottom:0.05em'),
  }),

  // CSS italic styling
  node => ({
    italic: node.getAttribute('style')?.includes('font-style:italic'),
  }),

  // Bold font weights
  node => {
    return {
      bold: ['font-weight:600', 'font-weight:700', 'font-weight: 700', 'font-weight: 600'].some(
        weight => node.getAttribute('style')?.includes(weight)
      ),
    }
  },
]

const sanitizedHTML = (html: string): string => {
  // Add a hook to sanitize html content
  DOMPurify.addHook('uponSanitizeElement', function (node) {
    if (!node.tagName) {
      return
    }

    // Convert span tags to correct formatting tag
    if (node.tagName.toLowerCase() === 'span') {
      const format = interpolateTextFormat(node, checkers)
      let migratedHtml = node.innerHTML

      if (format.italic) {
        migratedHtml = `<em>${migratedHtml}</em>`
      }
      if (format.bold) {
        migratedHtml = `<strong>${migratedHtml}</strong>`
      }
      if (format.underline) {
        migratedHtml = `<u>${migratedHtml}</u>`
      }

      node.innerHTML = migratedHtml
    }
  })

  return DOMPurify.sanitize(removeTabs(html), { FORBID_TAGS: ['div', 'span'] })
}

const sanitizedHTMLAllowDivs = (html: string): string => {
  // Add a hook to sanitize html content
  DOMPurify.addHook('uponSanitizeElement', function (node) {
    if (!node.tagName) {
      return
    }

    // Convert span tags to correct formatting tag
    if (node.tagName.toLowerCase() === 'span') {
      const format = interpolateTextFormat(node, checkers)
      let migratedHtml = node.innerHTML

      if (format.italic) {
        migratedHtml = `<em>${migratedHtml}</em>`
      }
      if (format.bold) {
        migratedHtml = `<strong>${migratedHtml}</strong>`
      }
      if (format.underline) {
        migratedHtml = `<u>${migratedHtml}</u>`
      }

      node.innerHTML = migratedHtml
    }
  })

  return DOMPurify.sanitize(removeTabs(html), { FORBID_TAGS: ['span'] })
}

function parseLinksInTextNodes(node: Descendant): Descendant[] {
  if (Text.isText(node)) {
    if (node.text.length === 0) return [node]
    else
      return urlParseText(node.text).map(t => {
        if (t.type === 'url') return createLink({ url: t.value, children: [{ ...node, text: t.value }] })
        else return { ...node, text: t.value }
      })
  } else {
    if (node.type === 'link') return [node]
    return [{ ...node, children: node.children.flatMap(parseLinksInTextNodes) } as Descendant]
  }
}

function parseMultilineInTextNodes(node: Descendant): Descendant[] {
  if (!Text.isText(node)) return [node]

  if (node.text.trim().length === 0) {
    return [node]
  } else {
    return node.text
      .split(/\r\n\r\n|\n\n|\r\r/)
      .map((line, i) => (i === 0 ? { text: line } : createParagraph({ children: [{ ...node, text: line }] })))
  }
}

// Get x-slate-fragment attribute from data-slate-fragment
// Source: https://github.com/ianstormtaylor/slate/blob/main/packages/slate-react/src/utils/dom.ts
const catchSlateFragment = /data-slate-fragment="(.+?)"/m
const getSlateFragmentAttribute = (dataTransfer: DataTransfer): string | undefined => {
  const htmlData = dataTransfer.getData('text/html')
  const [, fragment] = htmlData.match(catchSlateFragment) || []
  return fragment
}

function dataTransferSize(data: DataTransfer): number {
  const htmlTransfer = data.getData('text/html')
  const textTransfer = data.getData('text/plain')
  const slateTransfer = data.getData('application/x-slate-fragment')
  const transfer = slateTransfer === '' ? (htmlTransfer === '' ? textTransfer : htmlTransfer) : slateTransfer
  return transfer.length
}

export function parseAndSanitizeHTML(editor: SanaEditor, rawText: string): Descendant[] {
  const sanitized = sanitizedHTML(rawText)
  const parsed = parsePastedHtml(sanitized, { editor, resolveImage: undefined })

  if (parsed.length === 0) return [{ text: '' }]
  return parsed
}

function normalizeNumberOfColumns(node: HorizontalStack): Descendant[] {
  const chunkedChildren = _.chunk(node.children, 3)
  return chunkedChildren.map(children => createHorizontalStack({ children: children }))
}

function insertSpacingBeforeNode(node: Descendant, index: number): Descendant[] {
  if (index === 0) {
    return [node]
  }
  return [createParagraph(), node]
}

function insertSpacingAroundNode(node: Descendant, index: number): Descendant[] {
  if (index === 0) {
    return [node]
  }
  return [createParagraph(), node, createParagraph()]
}

function boldChildren(element: Entity<Heading | Paragraph>): Descendant {
  return { ...element, children: element.children.map(it => ({ ...it, bold: true })) }
}

function normalizeNodes(nodes: Descendant[]): Descendant[] {
  return nodes.flatMap((node, index): Descendant[] => {
    if (isElementType('heading', node)) {
      const boldedHeading = boldChildren(node)
      const spacedNode = insertSpacingBeforeNode(boldedHeading, index)
      const nextNode = nodes[index + 1]
      if (
        (node.level === 1 || node.level === 2) &&
        isElementType('paragraph', nextNode) &&
        !hasOnlyEmptyTextInNodes([nextNode])
      ) {
        return [...spacedNode, createParagraph()]
      }
      return spacedNode
    }

    if (isElementType('horizontal-stack', node)) {
      return normalizeNumberOfColumns(node)
    }

    if (isElementType('block-quote', node)) {
      return insertSpacingAroundNode(node, index)
    }

    if (isElementType('image', node)) {
      return insertSpacingBeforeNode(node, index)
    }

    if (isElementType('separator', node)) {
      return insertSpacingBeforeNode(node, index)
    }

    if (isElementType('paragraph', node) && node.level === 0) {
      return [boldChildren(node)]
    }

    return [node]
  })
}

export function parseAndSanitizeHTMLForGeneration(
  rawText: string,
  { resolveImage }: { resolveImage: ResolveImage | undefined }
): Descendant[] {
  const sanitized = sanitizedHTMLAllowDivs(rawText)
  const parsed = parsePastedHtml(sanitized, { editor: undefined, resolveImage })
  const normalized = normalizeNodes(parsed)

  return normalized
}

export const extractAssetFileIds = (decendants: Descendant[]): Set<string> => {
  const assetFileIds = new Set<string>()
  decendants.forEach(node => {
    if ('image' in node && node.image?.type === 'file' && node.image.file) {
      assetFileIds.add(node.image.file)
    }

    if (isElementType('file-attachment', node) && node.urlId) {
      assetFileIds.add(node.urlId)
    }

    if ('children' in node) {
      extractAssetFileIds(node.children).forEach(id => assetFileIds.add(id))
    }
  })

  return assetFileIds
}

type PasteFile = (card: string) => void
export const withPasteHtml = (
  editor: SanaEditor,
  pasteFile: PasteFile,
  copyPasteWithAssetsOptions: CopyPasteWithAssetsOptions
): SanaEditor => {
  const { insertData } = editor

  editor.insertData = data => {
    const transferSize = dataTransferSize(data)
    debug('pasting with payload transfer size', transferSize)
    if (transferSize >= 1000000) {
      debug('Payload too large. Size: ', transferSize)
      alert(
        'Payload too large, try splitting up the content into multiple cards. If you are pasting images, try uploading them in the image picker instead'
      )
      return
    }

    if (
      isSanaCardFragment(data.getData('text/plain')) ||
      isSanaModuleFragment(data.getData('text/plain')) ||
      isArrayOfSanaCardFragment(data.getData('text/plain'))
    ) {
      const sanaNode = data.getData('text/plain')
      pasteFile(sanaNode)
      return
    }

    // When marking text and pasting a link, keep the marking as display text and create a link
    const potentialUrl = getUrlFromText(data.getData('text/plain'))
    const { selection } = editor

    if (potentialUrl !== undefined && selection !== null && Editor.string(editor, selection) !== '') {
      const link: Entity<Link> = { id: nanoid12(), type: 'link', url: potentialUrl, children: [] }
      Transforms.wrapNodes(editor, link, { split: true })
      return
    }

    /**
     * Slate's default behavior of using insertFragment in these
     * contexts unfortunately does not work very well for these
     * blocks, so we fallback to pasting as plain text.
     */
    if (
      isInElement(editor, [
        'code',
        'markdown',
        'poll-card-alternative',
        'question-card-match-the-pairs-alternative-option',
        'question-card-match-the-pairs-alternative',
        'question-card-multiple-choice-alternative-explanation',
      ])
    ) {
      const text = data.getData('text/plain') // Convert to plain text, don't add any nodes
      debug('Inserting pasted data as plain text into block.')
      Transforms.insertText(editor, removeTabs(text))
      return
    }

    // Slate fragments should not be treated as html content!
    const slateFragments = data.getData('application/x-slate-fragment')
    // Slate fragments are always '' in Safari this extra check is needed
    const slateFragmentsSafari = getSlateFragmentAttribute(data)

    if (slateFragments !== '' || slateFragmentsSafari !== undefined) {
      // Find signed asset url from application/x-sierra-copy-paste
      // and assign it to importingAssetsFileUrls if we're copying from a different course
      if (copyPasteWithAssetsOptions.type === 'enabled') {
        const copyPasteContextData = data.getData(CopyPasteClipboardKey)
        if (copyPasteContextData !== '') {
          const copyPasteContext = CopyPasteContext.safeParse(JSON.parse(copyPasteContextData))
          if (
            copyPasteContext.success &&
            copyPasteContext.data.createContentId !== copyPasteWithAssetsOptions.createContentId
          ) {
            const slateFragment = slateFragmentsSafari !== undefined ? slateFragmentsSafari : slateFragments
            const parsed = JSON.parse(decodeURIComponent(atob(slateFragment))) as Descendant[]
            const assetFileIds = extractAssetFileIds(parsed)
            for (const assetFileId of assetFileIds) {
              editor.importingAssetsFileUrls[assetFileId] = copyPasteContext.data.signedAssetsUrl
            }
          }
        }
      }

      // Continue with insert
      editor.pushActionsLogEntry('paste-slate')
      return insertData(data)
    }

    const htmlContent = data.getData('text/html').replaceAll('\n', ' ')
    const plainTextContent = data.getData('text/plain')

    if (htmlContent === '' && plainTextContent === '') {
      editor.pushActionsLogEntry('paste-text')
      return insertData(data)
    }

    const parsedNodes =
      htmlContent !== ''
        ? parseAndSanitizeHTML(editor, htmlContent)
        : [{ text: plainTextContent }].flatMap(parseMultilineInTextNodes)

    const nodes = parsedNodes.flatMap(parseLinksInTextNodes)

    const leaf = getCurrentlySelectedLeaf(editor)
    if (leaf === undefined) {
      return insertData(data)
    }

    debug('Appending nodes parsed from HTMl after current node')
    editor.pushActionsLogEntry('paste-html')
    editor.insertFragment(nodes, { voids: true })
  }

  return editor
}
