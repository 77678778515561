import { UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { MOCK_DATA } from 'sierra-client/views/manage/content-attributes/tmp.mocks'
import { ContentAttribute } from 'sierra-client/views/manage/content-attributes/types'

const QUERY_KEY = ['content-attributes']

const mockData = (): Promise<Array<ContentAttribute>> => Promise.resolve(MOCK_DATA)

// TODO: Is this correct?
export type UpsertContentAttributeInput = ContentAttribute

export const useContentAttributes = (): {
  query: UseQueryResult<Array<ContentAttribute>, Error>
  createContentAttribute: (_: UpsertContentAttributeInput) => void
} => {
  const queryClient = useQueryClient()

  const query = useQuery({
    queryKey: QUERY_KEY,
    queryFn: mockData,
  })

  const mutation = useMutation({
    mutationFn: (data: UpsertContentAttributeInput) => Promise.resolve(data),
    onSuccess: data => {
      // We optimistically update the cache with the new content attribute
      queryClient.setQueryData(QUERY_KEY, (oldData?: Array<ContentAttribute>) =>
        oldData ? [...oldData, data] : [data]
      )
    },
  })

  const createContentAttribute = useCallback(
    (ca: UpsertContentAttributeInput) => {
      mutation.mutate(ca)
    },
    [mutation]
  )

  return useMemo(
    () => ({
      query,
      createContentAttribute,
    }),
    [createContentAttribute, query]
  )
}
