import { useQuery } from '@tanstack/react-query'
import { learnerTagsRecordQuery } from 'sierra-client/api/queries/learner-tags'
import { Pill, PillRow } from 'sierra-client/components/common/pill'
import { TagId } from 'sierra-domain/api/nano-id'

export const TagsBasic: React.FC<{ tagIds: (TagId | undefined)[]; onClick?: (id: string) => void }> = ({
  tagIds,
  onClick,
}) => {
  const tags = useQuery(learnerTagsRecordQuery).data

  return (
    <PillRow $multiline>
      {tagIds.flatMap(id => {
        if (id === undefined || tags === undefined) return []
        const tag = tags[id]

        if (tag === undefined) return []

        return [
          <Pill key={id} onClick={onClick !== undefined ? () => onClick(id) : undefined}>
            {tag.data.name}
          </Pill>,
        ]
      })}
    </PillRow>
  )
}
