import { createFileRoute } from '@tanstack/react-router'

import { PageIdentifier } from 'sierra-client/layout/sana-page'
import { ManageUserGroups } from 'sierra-client/views/manage/user-groups/manage-user-groups'
import { ManagePageWrapper } from 'sierra-client/views/manage/utils/manage-page-wrapper'
import { z } from 'zod'

// Note: ManagePageWrapper handles the role check.
function ManageUserGroupsPage(): JSX.Element | null {
  return (
    <ManagePageWrapper pageIdentifier={PageIdentifier.ManageUserGroups()}>
      <ManageUserGroups />
    </ManagePageWrapper>
  )
}

const RouteComponent = ManageUserGroupsPage

export const Route = createFileRoute('/manage/user-groups/')({
  component: RouteComponent as React.FC,
  validateSearch: z.object({
    sortBy: z.string().optional(),
    sortOrder: z.string().optional(),
  }),
})
