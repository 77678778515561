import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { ChangeEvent, useState } from 'react'
import { graphql } from 'sierra-client/api/graphql/gql'
import { ApproverSetting, EditCalendarEventInput } from 'sierra-client/api/graphql/gql/graphql'
import { graphQuery } from 'sierra-client/api/hooks/use-graphql-query'
import { useTranslation } from 'sierra-client/hooks/use-translation'
import { CalendarEventBottomOptions } from 'sierra-client/views/manage/event-groups/components/calendar-event-bottom-options'
import { EventForm } from 'sierra-client/views/manage/event-groups/components/event-form'
import { TextButton } from 'sierra-client/views/manage/event-groups/components/shared'
import {
  eventScheduleToGqlInput,
  eventScheduleValid,
} from 'sierra-client/views/manage/event-groups/event-utils'
import {
  CalendarEventSchedule,
  CreateCalendarEventData,
  EventLocation,
} from 'sierra-client/views/manage/event-groups/types'
import { withPanel } from 'sierra-client/views/manage/utils/with-modal'
import { IdentityWithMetadata } from 'sierra-domain/api/manage'
import { CalendarIntegrationSetting } from 'sierra-domain/content/session'
import { FormElement } from 'sierra-ui/components'
import { FreeTextEditor } from 'sierra-ui/missions/workflows/free-text-editor'
import { Button, Heading, IconButton, InputPrimitive, Spacer, View } from 'sierra-ui/primitives'
import { spacing, token } from 'sierra-ui/theming'
import styled from 'styled-components'

const OuterContainer = styled(View)`
  position: relative;
  min-height: 100%;
`

const TopContainer = styled(View)`
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-gutter: stable;
`

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin: unset;
  background-color: ${token('border/default')};
`

const BottomContainer = styled.div`
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${token('surface/default')};
  padding: ${spacing['medium']};
  padding-top: 0;
`

const CloseModalIconContainer = styled.div`
  position: absolute;
  right: 8px;
  top: 16px;
`

const StyledIconButton = styled(IconButton).attrs({ variant: 'transparent', size: 'small' })`
  color: ${token('foreground/muted')};

  &:hover {
    background: unset;
    color: ${token('foreground/primary')};
  }
`

type CreateCalendarEventPanelProps = {
  afterSubmit?: () => void
  event: EditCalendarEventData
}

const editCalendarEventMutation = graphql(`
  mutation editCalendarEvent($input: EditCalendarEventInput!) {
    editCalendarEvent(input: $input) {
      __typename
    }
  }
`)

type EditCalendarEventData = CreateCalendarEventData & {
  title: string
  description: string
  participantLimit: number | undefined
  selfReportAttendance: boolean
  approverSetting: ApproverSetting | undefined
  specificReviewers: IdentityWithMetadata[]
}

export const EditCalendarEventPanel = withPanel<CreateCalendarEventPanelProps>(
  {
    size: { width: 656 },
    disableScrollbarGutter: true,
    overlayVariant: 'light',
  },
  ({ onClose, event: eventProp, afterSubmit }) => {
    const { t } = useTranslation()
    const [showDescription, setShowDescription] = useState(eventProp.description.length > 0)
    const [showMoreOptions, setShowMoreOptions] = useState(false)

    // We clone the event because we want to compare assigned users and added facilitators when submitting
    const [event, setEvent] = useState<EditCalendarEventData>(() => _.cloneDeep(eventProp)) // pass a function to not clone on every render

    const setEventTitle = (newTitle: string): void => setEvent(e => ({ ...e, title: newTitle }))

    const setEventDescription = (newDescription: string): void =>
      setEvent(e => ({ ...e, description: newDescription }))

    const setEventSchedule = (newSchedule: CalendarEventSchedule): void =>
      setEvent(e => ({ ...e, schedule: newSchedule }))

    const setEventLocation = (newLocation: EventLocation | undefined): void =>
      setEvent(e => ({ ...e, location: newLocation }))

    const setParticipantLimit = (newLimit: number | undefined): void =>
      setEvent(e => ({
        ...e,
        participantLimit: newLimit,
      }))

    const setSelfReportAttendance = (allow: boolean): void =>
      setEvent(e => ({
        ...e,
        selfReportAttendance: allow,
      }))

    const setApproverSetting = (approverSetting?: ApproverSetting): void =>
      setEvent(e => ({
        ...e,
        approverSetting,
      }))

    const setFacilitatorIdentities = (facilitatorIdentities: IdentityWithMetadata[]): void =>
      setEvent(e => ({
        ...e,
        facilitatorIdentities,
      }))

    const setSpecificReviewersIdentities = (specificReviewers: IdentityWithMetadata[]): void =>
      setEvent(e => ({
        ...e,
        specificReviewers,
      }))

    const setCalendarIntegrationSetting = (calendarIntegrationSetting: CalendarIntegrationSetting): void =>
      setEvent(e => ({
        ...e,
        calendarIntegrationSetting,
      }))

    const editCalendarEvent = useMutation({
      mutationFn: async (input: EditCalendarEventInput) => {
        return graphQuery(editCalendarEventMutation, { input })
      },
    })

    const handleSubmit = (): void => {
      const userGroupIdsToAssign = _.difference(
        event.participantIdentities.flatMap(id => (id.identity.type === 'userGroup' ? [id.identity.id] : [])),
        eventProp.participantIdentities.flatMap(id =>
          id.identity.type === 'userGroup' ? [id.identity.id] : []
        )
      )
      const userIdsToAssign = _.difference(
        event.participantIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : [])),
        eventProp.participantIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : []))
      )
      const userIdsToUnassign = _.difference(
        eventProp.participantIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : [])),
        event.participantIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : []))
      )

      const facilitatorIdsToAdd = _.difference(
        event.facilitatorIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : [])),
        eventProp.facilitatorIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : []))
      )
      const facilitatorIdsToRemove = _.difference(
        eventProp.facilitatorIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : [])),
        event.facilitatorIdentities.flatMap(id => (id.identity.type === 'user' ? [id.identity.id] : []))
      )

      const reviewerIdsToAdd = _.difference(
        event.specificReviewers.flatMap(i => (i.identity.type === 'user' ? [i.identity.id] : [])),
        eventProp.specificReviewers.flatMap(i => (i.identity.type === 'user' ? [i.identity.id] : []))
      )
      const reviewerIdsToRemove = _.difference(
        eventProp.specificReviewers.flatMap(i => (i.identity.type === 'user' ? [i.identity.id] : [])),
        event.specificReviewers.flatMap(i => (i.identity.type === 'user' ? [i.identity.id] : []))
      )

      editCalendarEvent.mutate(
        {
          eventId: event.id,
          eventData: {
            title: event.title,
            description: event.description,
            schedule: eventScheduleToGqlInput(event.schedule),
            location: JSON.stringify(event.location),
            participantLimit: event.participantLimit,
            selfReportAttendance: event.selfReportAttendance,
            approverSetting: event.approverSetting,
          },
          participantChanges: {
            userGroupIdsToAssign,
            participantUserIdsForAssignment: userIdsToAssign,
            participantUserIdsForUnassignment: userIdsToUnassign,
            facilitatorUserIdsToAdd: facilitatorIdsToAdd,
            facilitatorUserIdsToRemove: facilitatorIdsToRemove,
            specificReviewerUserIdsToAdd: reviewerIdsToAdd,
            specificReviewerUserIdsToRemove: reviewerIdsToRemove,
          },
        },
        {
          onSuccess: () => {
            afterSubmit?.()
            onClose()
          },
        }
      )
    }

    return (
      <OuterContainer direction='column'>
        <CloseModalIconContainer>
          <StyledIconButton iconId='close' onClick={onClose} />
        </CloseModalIconContainer>
        <TopContainer
          gap='none'
          padding='none medium'
          paddingTop='small'
          justifyContent='flex-start'
          direction='column'
        >
          <Heading bold size='h5'>
            {t('admin.author.sessions.edit')}
          </Heading>
          <Spacer size='24' />
          <FormElement grow={false} label={t('dictionary.title')}>
            <InputPrimitive
              placeholder={t('manage.events.session-name')}
              id='session-title'
              value={event.title}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEventTitle(e.target.value)}
            />
          </FormElement>
          {showDescription ? (
            <>
              <Spacer size='24' />

              <View alignItems='flex-start' position='relative'>
                <FormElement label={t('dictionary.description')}>
                  <FreeTextEditor
                    inputId='session-description'
                    content={event.description}
                    editable
                    useHtml
                    textOptionsEnabled={false}
                    listOptionsEnabled={false}
                    alignmentOptionsEnabled={false}
                    strikethroughEnabled={false}
                    underlineEnabled={false}
                    onChange={e => {
                      setEventDescription(e)
                    }}
                    menuTranslations={{
                      list: t('dictionary.list'),
                      alignment: t('create.toolbar.text-alignment'),
                      text: t('dictionary.text'),
                      heading: t('font.heading'),
                    }}
                  />
                </FormElement>
              </View>
            </>
          ) : (
            <>
              <Spacer size='4' />
              <TextButton onClick={() => setShowDescription(true)}>
                {t('dictionary.custom-description')}
              </TextButton>
            </>
          )}
          <Spacer size='24' />
          <EventForm
            event={event}
            updateLocation={setEventLocation}
            updateSchedule={setEventSchedule}
            updateParticipantIdentities={undefined}
            updateFacilitatorIdentities={setFacilitatorIdentities}
            updateCalendarIntegrationSetting={setCalendarIntegrationSetting}
            isEditing
          />
          <Spacer size='24' />
        </TopContainer>

        <BottomContainer>
          {showMoreOptions && (
            <>
              <Divider />

              <Spacer size={'40'} />
              <CalendarEventBottomOptions
                participantLimit={event.participantLimit}
                setParticipantLimit={setParticipantLimit}
                selfReportAttendance={event.selfReportAttendance}
                setSelfReportAttendance={setSelfReportAttendance}
                approverSetting={event.approverSetting}
                setApproverSetting={setApproverSetting}
                specificReviewerIdentities={event.specificReviewers}
                setSpecificReviewerIdentities={setSpecificReviewersIdentities}
              />
              <Spacer size={'48'} />
            </>
          )}
          <View>
            <Button
              variant='secondary'
              onClick={() => {
                setShowMoreOptions(prev => !prev)
              }}
            >
              {t('dictionary.more-options')}
            </Button>

            <View marginLeft='auto'>
              <Button variant='secondary' onClick={onClose}>
                {t('dictionary.cancel')}
              </Button>
              <Button
                onClick={handleSubmit}
                loading={editCalendarEvent.isPending}
                disabled={editCalendarEvent.isPending || !eventScheduleValid(event.schedule)}
              >
                {t('dictionary.save')}
              </Button>
            </View>
          </View>
        </BottomContainer>
      </OuterContainer>
    )
  }
)
